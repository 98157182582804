const questions = [
  {
    id: 'product',
    question: 'I primarily sell',
    answers: [
      { text: 'products', value: 'products' },
      { text: 'services', value: 'services' },
      { text: 'products and services', value: 'products-services' }
    ],
    subtitle: 'Tell us about your business',
    type: 'box'
  },
  {
    id: 'industry',
    question: 'I primarily work in',
    answers: [
      {
        text: 'Health and Wellness',
        value: 'Health and Wellness',
        emoji: {
          symbol: '💪🏻',
          label: 'flexed biceps'
        }
      },
      {
        text: 'Education or Course Creation',
        value: 'Education or Course Creation',
        emoji: {
          symbol: '🎓',
          label: 'graduation cap'
        }
      },
      {
        text: 'Fashion, Jewelry, or Accessories',
        value: 'Fashion, Jewelry, or Accessories',
        emoji: {
          symbol: '💅',
          label: 'nail polish'
        }
      },
      {
        text: 'Hair and Beauty',
        value: 'Hair and Beauty',
        emoji: {
          symbol: '💇‍♂️',
          label: 'haircut'
        }
      },
      {
        text: 'Restaurant or Food Service',
        value: 'Restaurant or Food Service',
        emoji: {
          symbol: '🍛',
          label: 'curry rice'
        }
      },
      {
        text: 'Wedding Photography or Videography',
        value: 'Wedding Photography or Videography',
        emoji: {
          symbol: '💐',
          label: 'bouquet'
        }
      },
      {
        text: 'Lifestyle Photography or Videography',
        value: 'Lifestyle Photography or Videography',
        emoji: {
          symbol: '📷',
          label: 'camera'
        }
      },
      {
        text: 'Life and Relationship Coaching',
        value: 'Life and Relationship Coaching',
        emoji: {
          symbol: '👩🏻‍🏫',
          label: 'teacher'
        }
      },
      {
        text: 'Business and Marketing Coaching',
        value: 'Business and Marketing Coaching',
        emoji: {
          symbol: '🏫',
          label: 'school'
        }
      },
      {
        text: 'Wedding and Event Planning',
        value: 'Wedding and Event Planning',
        emoji: {
          symbol: '💍',
          label: 'wedding ring'
        }
      },
      {
        text: 'Real Estate',
        value: 'Real Estate',
        emoji: {
          symbol: '🏡',
          label: 'house'
        }
      },
      {
        text: 'Travel Planning',
        value: 'Travel Planning',
        emoji: {
          symbol: '✈️',
          label: 'plane'
        }
      },
      {
        text: 'Finance or Bookkeeping',
        value: 'Finance or Bookkeeping',
        emoji: {
          symbol: '💰',
          label: 'money bag'
        }
      },
      {
        text: 'Virtual Assistant',
        value: 'Virtual Assistant',
        emoji: {
          symbol: '🤳🏼',
          label: 'selfie'
        }
      },
      {
        text: 'Graphic and Web Design',
        value: 'Graphic and Web Design',
        emoji: {
          symbol: '🖥',
          label: 'computer monitor'
        }
      },
      {
        text: 'Social Media Management and Copywriting',
        value: 'Social Media Management and Copywriting',
        emoji: {
          symbol: '📱',
          label: 'phone'
        }
      },
      {
        text: 'Interior Design',
        value: 'Interior Design',
        emoji: {
          symbol: '🛋',
          label: 'couch'
        }
      },
      {
        text: 'Musician or Artist',
        value: 'Musician or Artist',
        emoji: {
          symbol: '🎼',
          label: 'music notes'
        }
      },
      {
        text: 'Influencing and Blogging',
        value: 'Influencing and Blogging',
        emoji: {
          symbol: '💁‍♀️',
          label: 'person tipping hand'
        }
      },
      {
        text: 'Author or Speaker',
        value: 'Author or Speaker',
        emoji: {
          symbol: '🎤',
          label: 'microphone'
        }
      },
      {
        text: 'Medical or Dental Care',
        value: 'Medical or Dental Care',
        emoji: {
          symbol: '🏥',
          label: 'hospital'
        }
      },
      {
        text: 'Animal Care',
        value: 'Animal Care',
        emoji: {
          symbol: '🐋',
          label: 'whale'
        }
      },
      {
        text: 'Other',
        value: 'Other',
        emoji: null
      }
    ],
    subtitle: 'Tell us about your industry',
    type: 'bubble'
  },
  {
    id: 'postFrequency',
    question: 'I consistently post',
    questionEnd: 'per week on social media',
    answers: [
      { text: 'less than 1-2 times', value: 'less', score: 1 },
      { text: '3-5 times', value: 'more', score: 2 },
      { text: '6 or more times', value: 'most', score: 3 }
    ],
    subtitle: 'Help us get to know you better',
    type: 'box'
  },
  {
    id: 'customerFrequency',
    question: 'I get customers through social media',
    answers: [
      { text: 'rarely', value: 'rarely', score: 1 },
      { text: 'occasionally', value: 'occasionally', score: 6 },
      { text: 'regularly', value: 'regularly', score: 9 }
    ],
    subtitle: 'Help us get to know you better',
    type: 'box'
  },
  {
    id: 'confidenceLevel',
    question: 'When it comes to using social media to market my business,',
    answers: [
      { text: 'I don’t even know where to begin', value: 'novice', score: 1 },
      { text: 'I understand the basics', value: 'basics', score: 2 },
      { text: 'I’m very confident', value: 'confident', score: 3 }
    ],
    subtitle: 'Help us get to know you better',
    type: 'box'
  },
  {
    id: 'primaryPlatform',
    question: 'I plan on using',
    questionEnd: 'the most for my business',
    answers: [
      { text: 'Facebook', value: 'facebook' },
      { text: 'Instagram', value: 'instagram' },
      { text: 'YouTube', value: 'youtube' },
      { text: 'Pinterest', value: 'pinterest' },
      { text: 'LinkedIn', value: 'linkedin' },
      { text: 'TikTok', value: 'tiktok' },
      { text: 'Twitter', value: 'twitter' }
    ],
    subtitle: 'Help us get to know you better',
    type: 'box'
  },
  {
    id: 'preferredFeature',
    question: 'I am most excited to',
    answers: [
      { text: 'write captions in under 5 minutes', value: 'captions' },
      { text: 'have a step-by-step plan to follow each day', value: 'plan' },
      {
        text: 'dive deep into understanding social media marketing strategies',
        value: 'strategies'
      }
    ],
    subtitle: 'Help us get to know you better',
    type: 'box'
  }
]

export default questions
