import styled from 'styled-components'

const StyledIssueFooter = styled.div`
  margin-top: 10rem;

  .width-controller-container {
    margin: 0 auto;
    width: 100%;
  }

  .issue-footer {
    min-height: 400px;
    padding: 7rem 0 3rem 0;
    position: relative;
    display: flex;
    align-items: center;
  }

  .issue-footer-grid {
    max-width: 1144px;
    margin: 0 auto;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .issue-footer-decor {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    height: 100%;
    pointer-events: none;

    img {
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      height: 100%;
      object-fit: cover;
      object-position: top;
      bottom: 0;
    }
  }

  .issue-footer-title {
    color: ${({ theme }) => theme.colors.gray[700]};
    font-weight: 700;
    font-size: 40px;
  }

  .issue-footer-body {
    margin-top: 1rem;
    display: block;
  }

  .issue-footer-grid-item {
    display: grid;
    align-items: center;

    p {
      margin: 0;
      font-size: 1.1rem;
      line-height: 26px;
      color: ${({ theme }) => theme.colors.gray[700]};
    }

    a {
      margin: 0;
      font-size: 1.1rem;
      line-height: 26px;
      color: ${({ theme }) => theme.colors.foreground};
    }

    .issue-footer-link {
      background: ${({ theme }) => theme.colors.foreground};
      box-shadow: 5px 9px 18px 3px rgba(97, 97, 97, 0.2);
      border-radius: 50rem;
      padding: 0 2.5rem;
      color: #faf5ed;
      margin-right: auto;
      margin-left: 0;
      height: 66px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1rem;
      font-size: 21px;
      font-weight: 500;

      svg {
        margin-right: 13px;
        margin-left: -6px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.size.sm}px) {
    .issue-footer-grid {
      padding: 0 1rem;
      grid-gap: 2rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    .issue-footer-grid-item .issue-footer-link {
      margin-left: auto;
      margin-right: 0;
    }
  }
`
export default StyledIssueFooter
