import {
  NOTIFICATION_ADD,
  NOTIFICATION_REMOVE,
  NOTIFICATION_REMOVE_ALL,
  NotificationActions,
  NotificationState
} from './types'


const initialState: NotificationState = {
  data: [],
  zIndex: 1000,
}

export const notificationReducer = (state = initialState, action: NotificationActions): NotificationState => {
  switch (action.type) {
    case NOTIFICATION_ADD:
      return {
        ...state,
        data: [
          ...state.data,
          action.payload,
        ],
        zIndex: state.zIndex + 1,
      }
    case NOTIFICATION_REMOVE:
      return {
        ...state,
        data: state.data.filter((item) => item.key !== action.payload)
      }
    case NOTIFICATION_REMOVE_ALL:
      return {
        ...state,
        data: []
      }
    default:
      return state
  }
}
