import React, { FC, useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { StyledRecentDraftsModal } from '.'
import { ResponsiveDrawer, Spinner } from '../../System'
import { RecentDraftListItem } from './RecentDraftListItem'
import useDraft from '../../../redux/draft-drawer/useDraft'
import { EmptyState } from '../../EmptyState'
import { searchUserDraftsQuery } from '../../../graphql/UserDraft/searchUserDrafts'
import {
  SearchUserDrafts,
  SearchUserDrafts_searchUserDrafts_docs
} from '../../../graphql/UserDraft/__generated__/SearchUserDrafts'

type Props = {
  isOpen: boolean;
  onClose: () => void;
}

const RecentDraftsModal: FC<Props> = ({ isOpen, onClose }) => {
  const apollo = useApolloClient()
  const { _id } = useDraft()
  const [drafts, setDrafts] = useState<SearchUserDrafts_searchUserDrafts_docs[]>([])
  const [loading, setLoading] = useState(true)

  const loadDrafts = async () => {
    try {
      setLoading(true)

      const { data } = await apollo.query<SearchUserDrafts>({
        query: searchUserDraftsQuery,
        fetchPolicy: 'no-cache',
        variables: {
          page: 0,
          search: '',
          categories: [],
          sort: 'modified',
          items: 30
        }
      })

      setDrafts(data?.searchUserDrafts?.docs || [])
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setDrafts([])

    if (isOpen) {
      loadDrafts()
    }
  }, [isOpen])

  useEffect(() => {
    onClose()
  }, [_id])

  return (
    <ResponsiveDrawer isOpen={isOpen} onClose={() => onClose()}>
      <StyledRecentDraftsModal>
        <div className="recent-drafts-header">
          <span>Recent Drafts</span>
        </div>
        {loading && <Spinner isBlock />}
        {!loading && !!drafts.length && (
          <ul>
            {drafts.map((draft) => (
              <RecentDraftListItem draft={draft} key={draft._id} />
            ))}
          </ul>
        )}
        {!loading && !drafts.length && (
          <EmptyState title="You have no drafts" suggestion="Save your drafts to see them here" />
        )}
      </StyledRecentDraftsModal>
    </ResponsiveDrawer>
  )
}

export default RecentDraftsModal
