import styled from 'styled-components'

const StyledGalleryCollectionItemPreview = styled.div`
  .reorder-prompt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 0.01rem;
    color: ${({ theme }) => theme.colors.background};
    justify-items: center;
    grid-gap: 12px;
    text-shadow: 0 0 2px #00000052;
  }
`

export default StyledGalleryCollectionItemPreview
