import styled from 'styled-components'

const StyledDateInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  .input-icon {
    position: absolute;
    height: 20px;
    width: 20px;
    left: 9px;
    color: ${({ theme }) => theme.colors.gray[300]};
    pointer-events: none;
    z-index: 1;

    svg {
      height: 20px;
    }
  }

  .SingleDatePicker__block {
    width: 100%;

    .DateInput_input {
      border: 1px solid ${({ theme }) => theme.colors.gray[300]};
      border-radius: 3px;
      height: 40px;
      padding: 0 15px 0 40px;
      color: ${({ theme }) => theme.colors.gray[800]};
      font-weight: 500;
      font-size: 14px;
      outline: none;
      transition: border-color 0.2s;

      &:hover, &:focus {
        border-color: ${({ theme }) => theme.colors.gray[400]};
      }
    }
  }

  .DayPicker_transitionContainer {
    width: 305px !important;
    padding-bottom: 62px;
    box-sizing: content-box;
  }

  .DayPicker__withBorder {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.gray[200]}, 0px 2px 2px rgb(0 0 0 / 5%);
    border-radius: 3px;
}

  .CalendarMonthGrid__horizontal, .DayPicker__withBorder, .DayPicker > div > div {
    width: fit-content !important;
  }

.DateInput_fang {display: none;}

.CalendarMonth_caption {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.gray[600]};
    letter-spacing: -0.02rem;
    font-weight: 400;
    padding: 0;
    position: absolute;
    top: 19px;
    left: 50%;
    transform: translateX(-50%);
}

.DayPickerNavigation_button__horizontalDefault {
    position: relative;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: unset;
    line-height: unset;
    border: 1px solid ${({ theme }) => theme.colors.gray[100]};
    border-radius: 3px;
    padding: 0;
    left: 0;
}

.DayPickerNavigation__horizontal {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: 1rem;
    padding: 0 1rem;
}

.CalendarMonth {
    padding: 1rem !important;
    position: relative;
}

.CalendarMonthGrid__horizontal {
    left: 0;
}

.CalendarMonthGrid_month__hidden {
    display: none;
}

.DayPicker_weekHeader {
    padding: 0 !important;
    position: relative;
    display: flex;
    justify-content: center;
    top: unset;
    text-align: center;
}

.DayPicker_weekHeader_ul {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.gray[400]};
}

.CalendarMonth_table {
  margin-top: 4.5rem;
}

.CalendarDay__default {
  border: 0;
  color: ${({ theme }) => theme.colors.gray[500]};
  border-radius: 50%;
  font-weight: 500;
  background: transparent;

  &:hover {
    border: 0;
    background: ${({ theme }) => theme.colors.gray[100]};
  }

  &.CalendarDay__selected, .CalendarDay__selected:hover {
    color: white;
    font-weight: 600;
    background: ${({ theme }) => theme.colors.green[500]};
    box-shadow: 0px 0px 0px 3px #FFE2E2;
  }
}

.DayPicker_weekHeaders__horizontal {
    margin: 0;
    position: absolute;
    width: 100%;
    top: 3.5rem;
}
`

export default StyledDateInput
