import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ShoppingCartIcon, MenuIcon } from '@heroicons/react/outline'
import ROUTES from '../../utils/routes'
import { AppState } from '../../../redux/root-reducer'
import { CartState } from '../../../redux/shopping-cart/types'
import { Button } from '../../System'
import { FlexGroup } from '../../Grid'
import { IconIntercomOutline } from '../../Icon/Brands'
import { StyledHeader, Logo } from '.'

type Props = {
  toggleMobileNav: () => void;
}

const Header: FC<Props> = ({ toggleMobileNav }) => {
  const { cartItems } = useSelector<AppState, CartState>((state) => state.shoppingCart)

  return (
    <StyledHeader id="app-header" className="header">
      <div className="header-logo">
        <Logo />
      </div>
      <FlexGroup className="header-actions">
        <Link className="button" to={ROUTES.checkout}>
          <ShoppingCartIcon />
          {cartItems && cartItems.length > 0 && (
            <div className="cart-notification">{cartItems.length}</div>
          )}
        </Link>
        <a
          className="button"
          href={ROUTES.external.supportMailing}
          id="intercom_btn"
          target="_blank"
          rel="noreferrer"
        >
          <IconIntercomOutline size={24} />
        </a>
        <Button id="mobile-menu-btn" onClick={() => toggleMobileNav()} theme="ghost" isIcon>
          <MenuIcon />
        </Button>
      </FlexGroup>
    </StyledHeader>
  )
}

export default Header
