import React, { FC } from 'react'
import { StyledIcon } from './index'

type Props = {
  color?: string;
  size: number;
  className?: string;
}

const IconFacebookSolid: FC<Props> = ({ color, size, className }) => {
  return (
    <StyledIcon color={color} size={size} className={className}>
      <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M25 12.5C25 5.5957 19.4043 0 12.5 0C5.5957 0 0 5.5957 0 12.5C0 19.4043 5.5957 25 12.5 25C12.5732 25 12.6465 25 12.7197 24.9951V15.2686H10.0342V12.1387H12.7197V9.83398C12.7197 7.16309 14.3506 5.70801 16.7334 5.70801C17.876 5.70801 18.8574 5.79102 19.1406 5.83008V8.62305H17.5C16.2061 8.62305 15.9521 9.23828 15.9521 10.1416V12.1338H19.0527L18.6475 15.2637H15.9521V24.5166C21.1768 23.0176 25 18.208 25 12.5Z"
          fill="currentcolor"
        />
      </svg>
    </StyledIcon>
  )
}

export default IconFacebookSolid
