import styled from 'styled-components'

const StyledSwitch = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.gray[100]};
  height: 40px;
  border-radius: 3px;
  padding: 5px;
  grid-gap: 5px;
`

export default StyledSwitch
