import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import ROUTES from '../../utils/routes'
import { linkToSizedImage, SizeData } from '../../IssueEntityImage/helpers'
import { StyledIssueExtra } from '.'
import { GetIssueBySlug_issueFindOne } from '../../../graphql/Issue/__generated__/GetIssueBySlug'
import { GetIssueAssetsQuery_extraSetFindMany } from '../../../graphql/Issue/__generated__/GetIssueAssetsQuery'

type Props = {
  extra: GetIssueAssetsQuery_extraSetFindMany;
  issue: GetIssueBySlug_issueFindOne;
}

const IssueExtra: FC<Props> = ({ extra, issue }) => {
  const THUMBNAIL_SIZE: SizeData = { height: 350 }

  return (
    <StyledIssueExtra>
      <Link to={ROUTES.issues.buildRecordPage(extra._id, issue!.slug as string, 'extra')}>
        <div className="image">
          {extra.contentType === 'image' && (
            <img
              src={linkToSizedImage(extra.image?.fileHttpLink || '', THUMBNAIL_SIZE)}
              alt={extra.title || ''}
            />
          )}
          {(extra.contentType === 'video' || extra.contentType === 'file') && (
            <img
              src={linkToSizedImage(extra.thumbnail?.fileHttpLink || '', THUMBNAIL_SIZE)}
              alt={extra.title || ''}
            />
          )}
        </div>
      </Link>
    </StyledIssueExtra>
  )
}

export default IssueExtra
