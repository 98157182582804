import React, { FC } from 'react'
import classNames from 'classnames'
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/outline'
import { StyledPaginationButton } from '.'

type Props = {
  direction: 'left' | 'right';
  className?: string;
  inModal?: boolean;
  inlineArrow?: boolean;
  onClick: () => void;
}

const PaginationButton: FC<Props> = ({
  direction, className, inModal, inlineArrow, onClick
}) => {
  return (
    <StyledPaginationButton
      className={classNames('pagination-btn', className)}
      onClick={() => onClick()}
      direction={direction}
      inModal={inModal}
      inlineArrow={inlineArrow}
    >
      {direction === 'right' && <ChevronRightIcon />}
      {direction === 'left' && <ChevronLeftIcon />}
    </StyledPaginationButton>
  )
}

export default PaginationButton
