import styled from 'styled-components'
import React from 'react'
import useWindowSize from './useWindowSize'

type ColumnProps = {
  span?: {
    [size: string]: number;
  };
  children: React.ReactNode;
  padding?: number;
  paddingLeft?: number;
  paddingRight?: number;
  className?: string;
  width?: string;
  [x: string]: any;
}

const Column = styled.div`
    ${(props: ColumnProps) => (props.paddingLeft ? `padding-left: ${props.paddingLeft}px;` : ``)};
    ${(props: ColumnProps) => (props.paddingRight ? `padding-right: ${props.paddingRight}px;` : ``)};
    padding: ${(props: ColumnProps) => props.padding}px;
    width: ${(props: ColumnProps) => props.width}%;
    display: flex;
`

const Col = (props: ColumnProps) => {
  const windowSize = useWindowSize()
  const defaultPadding = windowSize === 'sm' || windowSize === 'xs' ? 0 : 20
  const {
    children,
    span,
    padding = defaultPadding,
    paddingLeft = defaultPadding,
    paddingRight = defaultPadding,
    ...passProps
  } = props
  const spanNumber = span && span[windowSize] ? span[windowSize] : 12
  const width = String((100 / 12) * spanNumber)

  return (
    <Column
      padding={padding}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      width={width}
      {...passProps}
    >
      {children}
    </Column>
  )
}

export default Col
