import styled from 'styled-components'

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 60px;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.background};
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.fixed};
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  .header-logo {
    width: 250px;
    color: rgba(10, 13, 23, 0.85);
  }

  .header-actions {
    margin-left: 1rem;

    .button,
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.colors.gray[600]};
      border: transparent;
      width: 40px;
      height: 40px;
      border-radius: 3px;
      transition: 0.2s;
      position: relative;

      .cart-notification {
        position: absolute;
        right: 0;
        top: 0;
        height: 16px;
        background: ${({ theme }) => theme.colors.green[500]};
        min-width: 16px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 3px;
        padding-top: 2px;
        font-size: 10px;
        color: ${({ theme }) => theme.colors.background};
        font-weight: 600;

        border-radius: 50%;
      }

      svg {
        height: 27px;
        width: 27px;
      }

      &:hover {
        background-color: #f7f7f7;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.s}px) {
    display: none;
  }
`

export default StyledHeader
