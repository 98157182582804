import React, { FC } from 'react'
import { OnboardingQuestion, OnboardingState, StyledAnswerView } from '.'

type Props = {
  question: OnboardingQuestion;
  formState: OnboardingState;
  setFormState: (key: string, val: string) => void;
  textInput: boolean;
}

const AnswerView: FC<Props> = ({
  question, formState, textInput, setFormState
}) => {
  const answerInMiddle = question.questionEnd

  // helper function for finding the chosen answer's text value
  const handleAnswerText = () => {
    const { answers } = question
    const currentQuestionValue = formState[question.id]
    const currentAnswerData = answers.find((ans) => ans.value === currentQuestionValue)

    return currentAnswerData?.text
  }

  const customInputChange = (e) => {
    // set character limit for custom industry to 50
    const charCount = e.target.value.length
    if (charCount <= 50) {
      setFormState('customIndustry', e.target.value)
    }
  }

  return (
    <StyledAnswerView>
      <h3 className="answer-text">
        {answerInMiddle && (
          <>
            {question.question}
            <span className={`user-input ${formState[question.id] ? 'active' : ''}`}>
              {handleAnswerText()}
            </span>
            {question.questionEnd}.
          </>
        )}
        {!answerInMiddle && (
          <>
            {question.question}
            {textInput ? (
              <input
                type="text"
                value={formState.customIndustry}
                autoFocus
                onChange={customInputChange}
              />
            ) : (
              <span className={`user-input ${formState[question.id] ? 'active' : ''}`}>
                {handleAnswerText()}
              </span>
            )}
            .
          </>
        )}
      </h3>
    </StyledAnswerView>
  )
}

export default AnswerView
