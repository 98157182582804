import React, { FC, useEffect, useState } from 'react'
import query from 'query-string'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useLocation } from 'react-router'
import { PlusIcon } from '@heroicons/react/outline'
import { useApolloClient } from '@apollo/client'
import { SortEndHandler } from 'react-sortable-hoc'
import { Spinner } from '../../System'
import { SkeletonLoader } from '../../SkeletonLoader'
import { DraggingDimensionsType } from '../../../types'
import {
  StyledGalleryCollectionsFeed,
  GalleryCollectionItem,
  SortableCollections,
  shouldCancelStart
} from '.'
import { searchGalleryCollectionsQuery } from '../../../graphql/GalleryCollection/searchGalleryCollections'
import { SearchGalleryCollections } from '../../../graphql/GalleryCollection/__generated__/SearchGalleryCollections'
import { useGalleryFeed } from '../GalleryFeed'
import { NewCollectionModal } from '../../Modals'
import { updateSortPosition } from '../../utils/sortingHelpers'
import { reorderGalleryCollectionMutation } from '../../../graphql/GalleryCollection/reorderCollection'

const GalleryCollectionsFeed: FC = () => {
  const {
    collections,
    editMode,
    search,
    actions: { addCollections, changeSearch }
  } = useGalleryFeed()

  const location = useLocation()
  const apollo = useApolloClient()
  const [dragging, setDragging] = useState<DraggingDimensionsType | null>(null)
  const [page, setPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [showNewCollectionModal, setShowNewCollectionModal] = useState(false)

  const endDragging: SortEndHandler = (indexData) => {
    const updatedArray = updateSortPosition(
      indexData,
      collections,
      reorderGalleryCollectionMutation
    )

    addCollections(updatedArray)
    setDragging(null)
  }

  const helperDimensions = ({ node }) => {
    const dimensions = {
      width: node.offsetWidth,
      height: node.offsetHeight
    }
    setDragging(dimensions)
    return dimensions
  }

  const handleInfiniteLoad = async () => {
    setLoading(true)
    const { data } = await apollo.query<SearchGalleryCollections>({
      query: searchGalleryCollectionsQuery,
      fetchPolicy: 'no-cache',
      variables: { page, items: 15, search }
    })

    const newDocs = data.searchGalleryCollections?.docs || []
    const appendedCollections = [...collections, ...newDocs]

    setTotalCount(data.searchGalleryCollections?.totalDocs || 0)
    addCollections(page === 0 ? newDocs : appendedCollections)
    setLoading(false)
  }

  useEffect(() => {
    handleInfiniteLoad()
  }, [page, search])

  // clear search on collection page load
  useEffect(() => {
    changeSearch('')
  }, [])

  // for when the dashboard redirects to the
  // collection page to create a collection
  useEffect(() => {
    const urlQuery = query.parse(location.search)
    const openModal = !!urlQuery.showModal
    if (openModal) {
      setShowNewCollectionModal(true)
    }
  }, [location])

  return (
    <>
      <NewCollectionModal
        isOpen={showNewCollectionModal}
        handleClose={() => setShowNewCollectionModal(false)}
      />
      <StyledGalleryCollectionsFeed>
        {loading && collections.length === 0 && (
          <div className="collections-grid">
            <SkeletonLoader quantity={6} type="square-list-item" />
          </div>
        )}

        {collections.length > 0 && (
          <InfiniteScroll
            scrollThreshold={0.7}
            dataLength={collections.length || 0}
            next={() => setPage(page + 1)}
            hasMore={collections.length < totalCount}
            loader={<Spinner isBlock />}
          >
            <SortableCollections
              lockOffset="0%"
              lockToContainerEdges
              getHelperDimensions={helperDimensions}
              shouldCancelStart={shouldCancelStart}
              collections={collections}
              onSortEnd={endDragging}
              dragging={dragging}
              distance={2}
              helperClass="collection-dragging"
              axis="xy"
              lockAxis="xy"
            />
          </InfiniteScroll>
        )}
      </StyledGalleryCollectionsFeed>
    </>
  )
}

export default GalleryCollectionsFeed
