import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useLoginHandler } from '../Auth'
import { StyledSignInView } from '.'
import { WidthController } from '../Grid'
import {
  Heading, Form, Grid, LabelWrapper, LineInput, Button
} from '../System'
import { Controller } from '../System/Form'
import ROUTES from '../utils/routes'
import { Marketing } from '../Auth/Marketing'

const SignInView: FC = () => {
  const {
    form,
    validation,
    formError,
    loading,
    actions: { handleLogin }
  } = useLoginHandler()
  const { control, formState, getError } = form

  return (
    <StyledSignInView>
      <Marketing />
      <div className="auth-information">
        <WidthController noHeight noPadding maxWidth={420}>
          <Heading>
            Continue To
            <br />
            Social Curator
          </Heading>
          <Form
            message={{
              message: formError,
              type: 'error'
            }}
          >
            <Grid inLayout gap={12}>
              <LabelWrapper label="Email Address" errorMessage={getError('emailAddress')}>
                <Controller
                  name="emailAddress"
                  control={control}
                  rules={validation.emailAddress}
                  component={<LineInput type="email" placeholder="jasmine@star.com" />}
                />
              </LabelWrapper>

              <LabelWrapper label="Password" errorMessage={getError('password')}>
                <Controller
                  name="password"
                  control={control}
                  rules={validation.password}
                  component={<LineInput type="password" />}
                />
              </LabelWrapper>
            </Grid>
            <Button
              isLoading={loading}
              onClick={() => handleLogin()}
              isDisabled={!formState.isDirty}
              type="large"
              className="continue-btn"
            >
              SIGN IN
            </Button>
            <span className="auth-prompt">
              <Link to={ROUTES.user.resetPassword}>Forgot Password?</Link>
            </span>
            <span className="auth-prompt">
              Don't have an account? <Link to={ROUTES.user.signup}>Sign Up</Link>
            </span>
          </Form>
        </WidthController>
      </div>
    </StyledSignInView>
  )
}

export default SignInView
