import React, { FC } from 'react'
import { useQuery } from '@apollo/client'
import { StyledIssueHighlights, IssueHighlight } from '.'
import { GetIssueBySlug_issueFindOne } from '../../graphql/Issue/__generated__/GetIssueBySlug'
import { highlightFindMany } from '../../graphql/Highlight/highlightFindMany'
import { HighlightFindMany } from '../../graphql/Highlight/__generated__/HighlightFindMany'

type Props = {
  issue: GetIssueBySlug_issueFindOne;
  switchVideo: (videoUrl: string) => void;
}

const IssueHighlights: FC<Props> = ({ issue, switchVideo }) => {
  const { data } = useQuery<HighlightFindMany>(highlightFindMany, {
    fetchPolicy: 'no-cache',
    variables: { issue: issue._id }
  })

  if (!data?.highlightFindMany.length) {
    return null
  }

  return (
    <StyledIssueHighlights>
      <span className="issue-highlights-title">In case you missed out...</span>
      <div className="issue-highlights-grid">
        {data.highlightFindMany.map((data, index) => (
          <IssueHighlight
            switchVideo={switchVideo}
            id={data._id}
            key={index}
            title={data.title || ''}
            video={data.video || ''}
            isActive={!!data.isActive}
          />
        ))}
      </div>
    </StyledIssueHighlights>
  )
}

export default IssueHighlights
