import styled from 'styled-components'

type StyledProps = {
  top: number;
  left: number;
  width: number;
  height: number;
}

const StyledGalleryPhotoItem = styled.div<StyledProps>`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.gray[100]};
  color: ${({ theme }) => theme.colors.gray[300]};
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  .photo-preview-badge {
    height: 24px;
    background: ${({ theme }) => theme.colors.green[500]};
    border-radius: 3px;
    padding: 0 7px;
    display: flex;
    align-items: center;
    font-size: 14px;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.colors.background};
    position: absolute;
    top: 20px;
    right: 20px;
    pointer-events: none;
    z-index: 1;
  }

  .photo-item-link {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .reorder-prompt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 0.01rem;
    color: ${({ theme }) => theme.colors.background};
    justify-items: center;
    grid-gap: 12px;
    text-shadow: 0 0 2px #00000052;
  }

  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
  }

  &::after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    content: '';
    border: 1px solid #0000000f;
    border-radius: 3px;
    pointer-events: none;
  }

  &:hover {
    .btn {
      opacity: 1;
    }
  }

  @keyframes pulse {
    0% {
      background: ${({ theme }) => theme.colors.gray[100]};
    }
    50% {
      background: ${({ theme }) => theme.colors.gray[200]};
    }
    100% {
      background: ${({ theme }) => theme.colors.gray[100]};
    }
  }

  &:hover {
    .delete-btn {
      opacity: 1;
    }
  }
`

export default StyledGalleryPhotoItem
