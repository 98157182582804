import styled from 'styled-components'

type StyledProps = {
  direction: 'left' | 'right';
  inModal?: boolean;
  inlineArrow?: boolean;
}

const StyledPaginationButton = styled.button<StyledProps>`
  width: ${({ inModal }) => (inModal ? '50px' : '40px')};
  height: ${({ inModal }) => (inModal ? '50px' : '40px')};
  box-shadow: 0px 2px 4px 0px rgb(97 97 97 / 10%), 0px 0 0px 1px rgb(97 97 97 / 10%);
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.background};
  backdrop-filter: blur(1px);
  padding: 0;
  border: 0;
  position: absolute;
  top: 50%;
  ${({ direction }) => direction}: ${({ inModal }) => (inModal ? 'calc(-56px - 40px)' : '-20px')};
  ${({ inlineArrow, direction }) => inlineArrow && `${direction}: 0px;`}
  transform: translateY(-50%);
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray[500]};
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: 0.2s;
  display: none;

  svg {
    height: ${({ inModal }) => (inModal ? '24px' : '20px')};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.gray[100]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    display: flex;
  }
`

export default StyledPaginationButton
