import styled from 'styled-components'

const StyledDraftDrawerSelectors = styled.div`
  padding: 20px;
  height: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.gray[300]};
  background-color: ${({ theme }) => theme.colors.gray[100]};
  display: grid;
  grid-gap: 10px;
  align-content: start;

  .draft-drawer-category-dropdown {
    height: 45px;
    border: 1px solid ${({ theme }) => theme.colors.gray[300]};
    background-color: ${({ theme }) => theme.colors.background};
    width: 100%;
    display: flex;
    transition: 0.2s;
    border-radius: 6px;
    position: relative;
    cursor: pointer;

    select {
      width: 100%;
      border: 0;
      color: ${({ theme }) => theme.colors.gray[500]};
      background-color: transparent;
      font-size: 1rem;
      padding: 0 14px;
      outline: none;
      -webkit-appearance: none;

      &:hover {
        cursor: pointer;
      }
    }

    svg {
      position: absolute;
      right: 10px;
      top: 50%;
      pointer-events: none;
      transform: translateY(-50%);
      height: 24px;
      color: ${({ theme }) => theme.colors.gray[300]};

      path {
        stroke-width: 1.5px;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .input-heading {
    height: 30px;

    .input-label {
      font-size: 15px;
      color: ${({ theme }) => theme.colors.gray[600]};
      letter-spacing: -0.01rem;
    }
  }
`

export default StyledDraftDrawerSelectors
