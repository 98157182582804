import { gql } from '@apollo/client'

export const archiveFindOneQuery = gql`
  query ArchiveFindOneQuery($recordId: String!, $type: String!) {
    archiveFindOne(filter: { recordId: $recordId, type: $type }) {
      _id
      fileHttpLink
      fullPath
    }
  }
`
