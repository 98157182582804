import styled from 'styled-components'

type StyledProps = {
  inlineArrows?: boolean;
  showFadeLeft: boolean;
  showFadeRight: boolean;
}

const StyledFadeRow = styled.div<StyledProps>`
  position: relative;

  .fade {
    position: absolute;
    bottom: 0;
    top: 0;
    width: 100px;
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    transition: 0.2s;

    &.l {
      left: 0;
      opacity: ${({ showFadeLeft }) => showFadeLeft ? '1' : '0'};
      background: linear-gradient(-90deg, #ffffff00, ${({ theme }) => theme.colors.background});
    }

    &.r {
      right: 0;
      opacity: ${({ showFadeRight }) => showFadeRight ? '1' : '0'};
      background: linear-gradient(90deg, #ffffff00, ${({ theme }) => theme.colors.background});
    }
  }

  .scroll-row {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    column-gap: 10px;

    button {
      display: flex;
      margin: 0;
    }

    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }
  }
`

export default StyledFadeRow
