import {
  GALLERY_IMAGE_DATA,
  GALLERY_COLLECTION_DATA,
  GALLERY_SET_ACTIVE_COLLECTION,
  GALLERY_SET_ACTIVE_TAB,
  GALLERY_SET_COUNT_DOCS,
  GALLERY_SET_EDIT_MODE,
  GALLERY_CATEGORY_CHANGE,
  GALLERY_CLEAR_FILTERS,
  GALLERY_COLOR_CHANGE,
  GALLERY_ORIENTATION_CHANGE,
  GALLERY_SEARCH_CHANGE,
  GALLERY_SORT_BY_CHANGE,
  GALLERY_STYLE_CHANGE,
  GALLERY_LOAD_SEARCH,
  GalleryActions,
  GalleryState,
  GALLERY_SET_PAGE
} from './types'

export type CollectionWriteType = {
  type: 'add' | 'delete';
  payload: {
    collection: string;
    image: string;
  };
}

export const gallerySetEditModeAction = (payload: boolean): GalleryActions => ({
  type: GALLERY_SET_EDIT_MODE,
  payload
})

export const gallerySetCountDocsAction = (payload: number): GalleryActions => ({
  type: GALLERY_SET_COUNT_DOCS,
  payload
})

export const gallerySetPageAction = (payload: number): GalleryActions => ({
  type: GALLERY_SET_PAGE,
  payload
})

export const gallerySetActiveTabAction = (payload: string): GalleryActions => ({
  type: GALLERY_SET_ACTIVE_TAB,
  payload
})

export const gallerySetActiveCollectionAction = (
  payload: GalleryState['activeCollection']
): GalleryActions => ({
  type: GALLERY_SET_ACTIVE_COLLECTION,
  payload
})

export const galleryImageDataAction = (payload: GalleryState['data']): GalleryActions => ({
  type: GALLERY_IMAGE_DATA,
  payload
})

export const galleryCollectionDataAction = (
  payload: GalleryState['collections']
): GalleryActions => ({
  type: GALLERY_COLLECTION_DATA,
  payload
})

export const gallerySearchChangeAction = (payload: GalleryState['search']): GalleryActions => ({
  type: GALLERY_SEARCH_CHANGE,
  payload
})

export const galleryClearFiltersAction = () => ({ type: GALLERY_CLEAR_FILTERS })

export const gallerySortByChangeAction = (payload: GalleryState['sortBy']): GalleryActions => ({
  type: GALLERY_SORT_BY_CHANGE,
  payload
})

export const galleryColorChangeAction = (payload: GalleryState['color']): GalleryActions => ({
  type: GALLERY_COLOR_CHANGE,
  payload
})

export const galleryOrientationChangeAction = (
  payload: GalleryState['orientation']
): GalleryActions => ({
  type: GALLERY_ORIENTATION_CHANGE,
  payload
})

export const galleryCategoryChangeAction = (payload: GalleryState['category']): GalleryActions => ({
  type: GALLERY_CATEGORY_CHANGE,
  payload
})

export const galleryStyleChangeAction = (payload: GalleryState['style']): GalleryActions => ({
  type: GALLERY_STYLE_CHANGE,
  payload
})

export const galleryLoadSearchAction = (payload: any): GalleryActions => ({
  type: GALLERY_LOAD_SEARCH,
  payload
})
