import styled from 'styled-components'

type StyledProps = {
  height?: number;
}

const StyledEmptyState = styled.div<StyledProps>`
  margin: 0 auto;
  width: 100%;
  height: ${({ height }) => height ? `${height}px` : 'fit-content'};
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray[500]};
  border-radius: 6px;
  display: grid;
  align-content: center;

  img {
    pointer-events: none;
    width: 100%;
    max-width: 250px;
    display: block;
    margin: 0 auto 2rem auto;
    filter: grayscale(1) opacity(0.5);
  }

  b {
    display: block;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
  }

  span {
    display: block;
    margin: 0 auto;
    line-height: 22px;
    font-size: 1rem;

    span {
      max-width: 289px;
    }
  }

  .btn {
    margin: 0 auto;
    margin-top: 2rem;
  }

  a {
    color: ${({ theme }) => theme.colors.green[500]};
    font-weight: 500;
    cursor: pointer;
    text-decoration: underline;
  }
`

export default StyledEmptyState
