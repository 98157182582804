import React, { ReactNode } from 'react'
import { SortableElement } from 'react-sortable-hoc'
import { MenuIcon } from '@heroicons/react/outline'
import { StyledSortableRow } from '.'

type Props = {
  columnLength: number;
  columnSpacing?: string;
  children?: ReactNode;
}

const SortableRow = SortableElement(({ columnLength, columnSpacing, children }: Props) => {
  return (
    <StyledSortableRow columnLength={columnLength} columnSpacing={columnSpacing}>
      <MenuIcon className="drag-icon"/>
      {children}
    </StyledSortableRow>
  )
})

export default SortableRow
