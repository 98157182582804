import React, { FC, useMemo, useState, useCallback } from 'react'
import firebase from 'firebase/app'
import { random } from 'lodash'
import { useDropzone } from 'react-dropzone'
import { CheckCircleIcon } from '@heroicons/react/solid'
import { TrashIcon } from '@heroicons/react/outline'
import { linkToSizedImage, SizeData } from '../../IssueEntityImage/helpers'
import { FileObjectType } from '../../../types'
import { Button, Spinner } from '..'
import { StyledImageInput } from '.'

type Props = {
  dir: 'images' | 'audio' | 'action-plan' | 'extra';
  file?: FileObjectType | null;
  isMulti?: boolean;
  onUpdate: (value: FileObjectType | null) => any;
  bufferDelete?: (filename: string) => void;
}

const ImageInput: FC<Props> = ({
  dir, file, isMulti, onUpdate, bufferDelete
}) => {
  const acceptTypes = useMemo(() => {
    if (dir === 'images') {
      return 'image/jpeg, image/png, image/jpg'
    }
    return '.pdf, .mp3'
  }, [dir])

  const storage = firebase.storage()
  const THUMBNAIL_SIZE: SizeData = { height: 100 }
  const [uploadState, setUploadState] = useState({
    uploading: false,
    percentage: 0,
    filename: '',
    countFiles: 0,
    uploadedFiles: 0
  })

  const handleFileName = (original: string) => {
    const rnd = random(0, 9999999)
    return `${rnd}-${original}`
  }

  const next = (snapShot) => {
    setUploadState((prev) => ({
      ...prev,
      uploading: true,
      percentage: snapShot.bytesTransferred / snapShot.totalBytes
    }))
  }

  const error = (err) => {
    console.error(err)
    setUploadState((prev) => ({
      ...prev,
      uploading: false,
      percentage: 0
    }))
  }

  const getImageResolution = (imgObj: any): Promise<any> => {
    const images = ['image/png', 'image/jpeg', 'image/jpg']

    return new Promise((resolve, reject) => {
      if (!images.includes(imgObj.type)) {
        resolve(null)
      }
      else {
        let image: any = new Image()
        image.onload = () => {
          resolve({ width: image.width, height: image.height })
          image = null // clean up
        }
        image.onerror = reject
        image.src = URL.createObjectURL(imgObj)
      }
    })
  }

  const handleFireBaseUpload = (fileObj: any, fileName: string) => {
    return new Promise((resolve, reject) => {
      const uploadTask = storage.ref(`/${dir}/${fileName}`).put(fileObj)
      const subscribe = uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED)
      subscribe({
        next,
        error,
        complete: () => {
          const fileRef = storage.ref(dir).child(fileName)
          const imageSizePr = getImageResolution(fileObj)
          Promise.all([fileRef.getMetadata(), fileRef.getDownloadURL(), imageSizePr])
            .then(([{ fullPath }, fileHttpLink, imgSize]) => {
              onUpdate({ fullPath, fileHttpLink, imgSize })
              setUploadState((prev) => ({
                ...prev,
                uploading: false,
                percentage: 100
              }))
              resolve()
            })
            .catch(reject)
        }
      })
    })
  }

  const deleteFile = (update = false) => {
    if (bufferDelete && file?.fullPath) {
      bufferDelete(file.fullPath) // if function is provided, do not delete immediately
    }
    if (!bufferDelete && file?.fullPath) {
      storage
        .ref(file?.fullPath)
        .delete()
        .catch(console.error)
    }
    if (update) {
      onUpdate(null)
    }
  }

  const onDrop = useCallback(async (files) => {
    deleteFile(false) // when reloading - delete existing file
    setUploadState((prev) => ({ ...prev, countFiles: files.length, uploadedFiles: 0 }))
    for (let i = 0; i < files.length; i++) {
      const tFile = files[i]
      const changedName = handleFileName(tFile.name)
      setUploadState((prev) => ({
        ...prev,
        filename: changedName,
        uploading: true,
        uploadedFiles: i + 1
      }))
      // eslint-disable-next-line no-await-in-loop
      await handleFireBaseUpload(tFile, changedName)
    }
  }, [])

  const {
    getRootProps, getInputProps, isDragActive, open: reloadFn
  } = useDropzone({
    onDrop,
    accept: acceptTypes,
    multiple: isMulti
  })

  return (
    <StyledImageInput>
      <div
        {...getRootProps()}
        className={`upload-drop${
          !file?.fileHttpLink && !uploadState?.uploading ? ' no-file-yet' : ' '
        }`}
        style={{ display: file?.fileHttpLink || uploadState?.uploading ? 'none' : '' }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <span>Drop the file{isMulti && 's'} here</span>
        ) : (
          <span>Drag & drop file{isMulti && 's'} or click here</span>
        )}
      </div>
      {uploadState?.uploading && <Spinner isBlock />}
      {file?.fileHttpLink && !uploadState?.uploading && (
        <div className="upload-info-container">
          <div className="upload-container">
            <div className="upload-preview">
              {dir === 'images' && (
                <a href={file.fileHttpLink} target="_blank" rel="noopener noreferrer">
                  <img src={linkToSizedImage(file.fileHttpLink, THUMBNAIL_SIZE)} alt="Preview" />
                </a>
              )}
              {dir !== 'images' && <CheckCircleIcon />}
            </div>
            <div className="upload-info">
              <span className="upload-message">Uploaded successfully</span>
              {file.fullPath && <span className="upload-filename">{file.fullPath}</span>}
            </div>
          </div>
          <div className="upload-actions">
            <Button type="large" theme="outline" isIcon onClick={() => deleteFile(true)}>
              <TrashIcon />
            </Button>
            <Button theme="outline" onClick={() => reloadFn()}>
              Replace
            </Button>
          </div>
        </div>
      )}
    </StyledImageInput>
  )
}

export default ImageInput
