import React, { FC, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ActionDialog } from '../../System'
import ROUTES from '../../utils/routes'
import { userStore } from '../../utils/UserContext'

const MissingInfoModal: FC = () => {
  const history = useHistory()
  const { user, loaded } = useContext(userStore)
  const [showModal, setShowModal] = useState(false)

  const isInvalidName = (name: string) => {
    if (!name || name.includes('@')) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (loaded && user && isInvalidName(user.displayName)) {
      setShowModal(true)
    }
  }, [user, loaded])

  if (!showModal) {
    return null
  }

  return (
    <ActionDialog
      title="Updated Information"
      body="It seems that some of your information may be outdated. Please fill in the needed details by clicking the button below."
      isOpen={showModal}
      confirmButtonText="Update Profile"
      cancelButtonText="Not Now"
      onClose={() => setShowModal(false)}
      onConfirm={() => {
        setShowModal(false)
        history.push(ROUTES.user.settings.profile)
      }}
    />
  )
}

export default MissingInfoModal
