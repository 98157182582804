import React, { FC } from 'react'
import { StyledUserBubble, TestimonialType } from '.'

type Props = {
  user: TestimonialType['user'];
  isActive?: boolean;
  onClick: () => void;
}

const UserBubble: FC<Props> = ({ user, isActive, onClick }) => {
  return (
    <StyledUserBubble isActive={isActive} onClick={() => onClick()}>
      <img src={user.image} alt={`${user.name}'s face`}/>
    </StyledUserBubble>
  )
}

export default UserBubble
