import styled from 'styled-components'
import { TooltipType } from '.'

type StyledProps = {
  tooltip: TooltipType;
}

const StyledToolTip = styled.div<StyledProps>`
  opacity: 0;
  animation: ${({ tooltip }) => tooltip.isShown ? '0.1s fade-in ease-in forwards' : 'none'};
  animation-delay: 0.5s;
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.tooltip};
  pointer-events: none;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .tooltip {
    position: fixed;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.1px;
    color: ${({ theme }) => theme.colors.gray[50]};
    background: ${({ theme }) => theme.colors.gray[900]};
    padding: 5px 16px;
    border-radius: 2px;
    z-index: ${({ theme }) => theme.zIndex.tooltip};

   

    ${({ tooltip }) => tooltip.tooltip.pos === 'top' && `
      top: ${tooltip.child.top - tooltip.tooltip.height - tooltip.tooltip.offset - window.scrollY}px;
      left: ${(tooltip.child.width / 2) + tooltip.child.left - (tooltip.tooltip.width / 2)}px;
      
      ::after {
        transform: translateX(-50%) rotate(45deg);
        left: 50%;
        bottom: -4px;
      }
    `}

    ${({ tooltip }) => tooltip.tooltip.pos === 'left' && `
      top: ${(tooltip.child.height / 2) + tooltip.child.top - (tooltip.tooltip.height / 2)}px;
      left: ${tooltip.child.left - tooltip.tooltip.width - tooltip.tooltip.offset}px;
      
      ::after {
        transform: translateY(-50%) rotate(45deg);
        top: 50%;
        right: -4px;
      }
    `}

    ${({ tooltip }) => tooltip.tooltip.pos === 'bottom' && `
      top: ${tooltip.child.top + tooltip.child.height + tooltip.tooltip.offset}px;
      left: ${(tooltip.child.width / 2) + tooltip.child.left - (tooltip.tooltip.width / 2)}px;  

      ::after {
        transform: translateX(-50%) rotate(45deg);
        left: 50%;
        top: -4px;
      }
    `}

    ${({ tooltip }) => tooltip.tooltip.pos === 'right' && `
      top: ${(tooltip.child.height / 2) + tooltip.child.top - (tooltip.tooltip.height / 2)}px;
      left: ${tooltip.child.left - tooltip.child.width - tooltip.tooltip.offset}px;

      ::after {
        transform: translateY(-50%) rotate(45deg);
        top: 50%;
        left: -4px;
      }
    `}
    
    ::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      background: ${({ theme }) => theme.colors.gray[900]};
      border-radius: 2px;
      z-index: -1;
    }
  }
`

export default StyledToolTip
