import { GalleryItemForm } from '../../components/Admin/AdminGallery'
import { GalleryImageFindOneQuery_galleryImageFindOne } from '../../graphql/GalleryImage/__generated__/GalleryImageFindOneQuery'

export type GalleryErrorType = {
  recordId: string;
  error: string;
}

export type GalleryFormState = {
  images: GalleryItemForm[];
  loading: boolean;
  success: boolean;
  errors: GalleryErrorType[];
}

export const GALLERY_FORM_HIDE_ON_SUCCESS = 'GALLERY_FORM_HIDE_ON_SUCCESS'
export type GalleryFormHideOnSuccessAction = {
  type: typeof GALLERY_FORM_HIDE_ON_SUCCESS;
  payload: {
    entryId: string;
  };
}

export const GALLERY_FORM_STOP_LOADING = 'GALLERY_FORM_STOP_LOADING'
export type GalleryFormStopLoadingAction = {
  type: typeof GALLERY_FORM_STOP_LOADING;
}

export const GALLERY_FORM_SUCCESS = 'GALLERY_FORM_SUCCESS'
export type GalleryFormSuccessAction = {
  type: typeof GALLERY_FORM_SUCCESS;
}

export const GALLERY_FORM_LOADING = 'GALLERY_FORM_LOADING'
export type GalleryFormLoadingAction = {
  type: typeof GALLERY_FORM_LOADING;
}

export const GALLERY_FORM_SET_UPDATE = 'GALLERY_FORM_SET_UPDATE'
export type GalleryFormSetUpdateAction = {
  type: typeof GALLERY_FORM_SET_UPDATE;
  payload: GalleryImageFindOneQuery_galleryImageFindOne;
}

export const GALLERY_FORM_RESET = 'GALLERY_FORM_RESET_FORM'
export type GalleryFormResetAction = {
  type: typeof GALLERY_FORM_RESET;
}

export const GALLERY_FORM_ERROR_BY_ID = 'GALLERY_FORM_ERROR_BY_ID'
export type GalleryFormErrorByIdAction = {
  type: typeof GALLERY_FORM_ERROR_BY_ID;
  payload: string;
  meta: {
    entryId: string;
  };
}

export const GALLERY_FORM_ADD_IMAGE = 'GALLERY_FORM_ADD_IMAGE'
export type GalleryFormAddImageAction = {
  type: typeof GALLERY_FORM_ADD_IMAGE;
  payload: GalleryItemForm;
}

export const GALLERY_FORM_REMOVE_IMAGE_BY_ID = 'GALLERY_FORM_REMOVE_IMAGE_BY_ID'
export type GalleryFormRemoveImageByIdAction = {
  type: typeof GALLERY_FORM_REMOVE_IMAGE_BY_ID;
  payload: string;
}

export const GALLERY_FORM_UPDATE_BY_ID = 'GALLERY_FORM_UPDATE_BY_ID'
export type GalleryFormUpdateByIdAction = {
  type: typeof GALLERY_FORM_UPDATE_BY_ID;
  payload: Partial<GalleryImageFindOneQuery_galleryImageFindOne>;
  meta: {
    entryId: string;
  };
}

export type GalleryFormActions =
  | GalleryFormAddImageAction
  | GalleryFormRemoveImageByIdAction
  | GalleryFormUpdateByIdAction
  | GalleryFormErrorByIdAction
  | GalleryFormResetAction
  | GalleryFormSetUpdateAction
  | GalleryFormSuccessAction
  | GalleryFormLoadingAction
  | GalleryFormHideOnSuccessAction
  | GalleryFormStopLoadingAction
