import React, { FC, useEffect, useState } from 'react'
import { StyledMilk } from '.'


const Milk: FC = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if(e.key === 'X' && e.shiftKey && (e.metaKey || e.ctrlKey)) {
        setShow((prev) => !prev)
      }
    })
  }, [])

  if(!show) {
    return null
  }

  return (
    <StyledMilk>
      <div className="wave"/>
      <div className="wave wave-2"/>
    </StyledMilk>
  )
}

export default Milk
