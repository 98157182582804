import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { xor } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { AppState } from '../root-reducer'
import { DraftState, DraftCharacterCount, DraftCategoryType } from './types'
import {
  draftSetDraft,
  draftClearDraft,
  draftSetImage,
  draftClearImage,
  draftSetCaption,
  draftSetSaved,
  draftSetOpened,
  draftSetTitle,
  draftSetCharacterCountType,
  draftSetCategory,
  draftSetDate,
  draftClearDate,
  draftSetSocialProfiles,
  draftSetImageDimensions,
  draftClearImageDimensions,
  draftSetShowShareModal
} from './actions'
import { UserDraftFindById } from '../../graphql/UserDraft/__generated__/UserDraftFindById'
import { userDraftFindByIdQuery } from '../../graphql/UserDraft/userDraftFindById'

const useDraft = () => {
  const {
    _id,
    title,
    socialProfiles,
    imageDimensions,
    captionText,
    imageUrl,
    userUpload,
    imageId,
    fullPath,
    category,
    isLiked,
    isUsed,
    challenge,
    template,
    postDate,
    characterCountType,
    createdAt,
    isSaved,
    isOpened,
    shareModal,
    isNew,
    index
  } = useSelector<AppState, DraftState>((state) => state.draft)

  const dispatch = useDispatch()
  const apollo = useApolloClient()
  const characterCountTypes: DraftCharacterCount[] = [
    'instagram',
    'facebook',
    'linkedin',
    'youtube',
    'pinterest'
  ]

  // Replaces the current draft with the designated draft
  const setDraft = async (_id: string) => {
    const { data } = await apollo.query<UserDraftFindById>({
      query: userDraftFindByIdQuery,
      variables: { _id },
      fetchPolicy: 'no-cache'
    })

    if (!data.userDraftFindById) {
      return
    }

    dispatch(draftSetDraft(data.userDraftFindById))
  }

  // Clears the current draft
  const clearDraft = () => {
    dispatch(draftClearDraft('new'))
  }

  // Sets the draft's title
  const setDraftTitle = (title: string) => {
    dispatch(draftSetTitle(title))
  }

  // Sets the draft's caption
  const setDraftCaption = (caption: string) => {
    dispatch(draftSetCaption(caption))
  }

  // Clears the draft's image dimensions
  const clearImageDimensions = () => {
    dispatch(draftClearImageDimensions())
  }

  // Sets the draft's image dimensions
  const setDraftImageDimensions = (x: number, y: number) => {
    dispatch(draftSetImageDimensions({ x, y }))
  }

  // Clears the draft's image
  const clearDraftImage = () => {
    dispatch(draftClearImage())
  }

  // Toggles the draft's social profile
  const toggleDraftSocialProfile = (profile: string) => {
    dispatch(draftSetSocialProfiles(xor(socialProfiles, [profile])))
  }

  // Sets the draft's social profiles
  const setDraftSocialProfiles = (profiles?: string[]) => {
    dispatch(draftSetSocialProfiles(profiles || []))
  }

  // Sets the draft's image
  const setDraftImage = (imageUrl: string, imageId?: string, fullPath?: string) => {
    dispatch(
      draftSetImage({
        imageUrl,
        userUpload: !!fullPath,
        imageId: imageId || undefined,
        fullPath: fullPath || undefined
      })
    )
  }

  // Sets the draft's category
  const setDraftCategory = (category: DraftCategoryType) => {
    dispatch(draftSetCategory(category))
  }

  // Sets the draft's post date
  const setDraftDate = (postDate: Date) => {
    dispatch(draftSetDate(postDate))
  }

  // Clears the draft's post date
  const clearDraftDate = () => {
    dispatch(draftClearDate())
  }

  // Sets the draft's saved status
  const setDraftSaved = (status: boolean) => {
    dispatch(draftSetSaved(status))
  }

  // Closes the draft
  const closeDraft = () => {
    dispatch(draftSetOpened(false))
  }

  // Shows or hides the share modal
  const setShowShareModal = (show: boolean, isRescheduling?: boolean) => {
    dispatch(
      draftSetShowShareModal({
        show,
        isRescheduling
      })
    )
  }

  const clearAndCloseDraft = () => {
    closeDraft()
    clearDraft()
  }

  // Opens the draft
  const openDraft = () => {
    dispatch(draftSetOpened(true))
  }

  const changeCharacterCountType = () => {
    if (!characterCountType) {
      return dispatch(draftSetCharacterCountType(characterCountTypes[0]))
    }

    const typeIndex = characterCountTypes.indexOf(characterCountType)

    if (typeIndex === characterCountTypes.length - 1) {
      return dispatch(draftSetCharacterCountType(characterCountTypes[0]))
    }

    dispatch(draftSetCharacterCountType(characterCountTypes[typeIndex + 1]))
  }

  useEffect(() => {
    if (!_id) {
      clearDraft()
    }
  }, [_id])

  return {
    _id,
    title,
    captionText,
    imageUrl,
    userUpload,
    imageId,
    fullPath,
    category,
    isLiked,
    isUsed,
    challenge,
    template,
    postDate,
    index,
    socialProfiles,
    imageDimensions,
    characterCountType,
    createdAt,
    isSaved,
    isNew,
    isClear: !captionText && !imageUrl && (!category || category === 'Uncategorized') && !postDate,
    isOpened,
    shareModal,
    actions: {
      setDraft,
      clearDraft,
      closeDraft,
      clearAndCloseDraft,
      openDraft,
      changeCharacterCountType,
      setDraftTitle,
      setDraftCaption,
      setDraftCategory,
      setDraftDate,
      clearDraftDate,
      setDraftImage,
      clearDraftImage,
      setDraftSaved,
      toggleDraftSocialProfile,
      setDraftSocialProfiles,
      setDraftImageDimensions,
      clearImageDimensions,
      setShowShareModal
    }
  }
}

export default useDraft
