import styled from 'styled-components'

type StyledProps = {
  isError?: boolean;
}

const StyledStripeInput = styled.div<StyledProps>`
  position: relative;
  color: ${({ theme }) => theme.colors.gray[800]};
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  letter-spacing: 0.02rem;
  border: 1px solid ${({ theme }) => theme.colors.gray[300]};
  height: 40px;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 3px;
  outline: none;
  display: flex;
  align-items: center;
  transition: border-color 0.2s;

  .StripeElement {
    width: 100%;
    padding: 11px 15px;
    cursor: text;
  }

  &:hover, &:focus-within {
    border-color: ${({ theme }) => theme.colors.gray[500]};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray[300]};
  }

  &:disabled {
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.gray[100]};
    color: ${({ theme }) => theme.colors.gray[300]};
    border-color: transparent;
  }

  ${({ isError }) => isError && `
    border: 1px solid #B93619 !important;
  `}
`

export default StyledStripeInput
