import styled from 'styled-components'
import { ModalProps } from '.'

type StyledProps = Pick<ModalProps, 'modalType' | 'windowHeight'>

const StyledIssueModalContent = styled.div<StyledProps>`
  max-width: 1144px;
  width: 100vw;

  .title-block {
    padding: 36px;
  }

  h4 {
    font-weight: 500;
    font-style: normal;
    &.user {
      color: ${({ theme }) => theme.colors.gray[500]};
      text-transform: uppercase;
      background: ${({ theme }) => theme.colors.gray[200]};
    }
    &.admin {
      color: ${({ theme }) => theme.colors.foreground};
      text-transform: capitalize;
      margin: 0;
    }
    &.admin-page {
      color: ${({ theme }) => theme.colors.foreground};
      text-transform: capitalize;
      margin: 0;
      font-size: 0.96em;
    }
  }
  h3 {
    text-transform: uppercase;
  }
  .title-block {
    padding: 25px;
    &.user {
      background: ${({ theme }) => theme.colors.gray[200]};
    }
    &.admin {
      border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
      background: ${({ theme }) => theme.colors.background};
    }
    &.admin-page {
      background: ${({ theme }) => theme.colors.background};
      padding: 15px 25px;
    }
  }
  .footer-actions {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 20px 25px 20px;
    &.user {
      border-top: none;
    }
    &.admin {
      border-top: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
    }
    &.admin-page {
      border-top: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
    }
  }
  .actions {
    position: absolute;
    &.user {
      right: -40px;
      top: 0;
    }
    &.admin {
      right: 25px;
      top: 25px;
    }
    &.admin-page {
      right: 25px;
      top: 12.5px;
    }
    &.user,
    &.admin {
      @media (max-width: ${({ theme }) => `${theme.size.sm}px`}) {
        position: static;
        background: ${({ theme }) => theme.colors.gray[500]};
        padding: 10px;
        text-align: right;
      }
    }
    svg {
      cursor: pointer;
      width: ${({ modalType }) => (modalType === 'admin-page' ? '20px' : '26px')};
      height: ${({ modalType }) => (modalType === 'admin-page' ? '20px' : '26px')};
      &:hover {
        opacity: 0.6;
        cursor: pointer;
      }
    }
  }
  .content {
    overflow-y: auto;
    max-height: calc(100vh - 121px);
    &.user {
      max-height: 80vh;
      padding: 30px 20px;
    }
    &.admin {
      max-height: 70vh;
      padding: 30px 25px;
    }
    &.admin-page {
      max-height: 80vh;
      padding: 10px 0 20px;
      @media (max-width: ${({ theme }) => `${theme.size.sm}px`}) {
        max-height: 80vh;
        overflow: auto;
      }
    }
  }
  .icons-actions {
    svg:first-child {
      margin-right: 16px;
    }
  }

  .issue-modal-heading {
    display: flex;
    justify-content: space-between;
  }

  .issue-modal-sub-title {
    font-weight: 600;
    display: block;
    font-size: 14px;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.colors.gray[500]};
    margin-bottom: 6px;
    text-transform: uppercase;
  }

  .issue-modal-title {
    display: block;
    font-size: 24px;
    font-weight: 700;
  }

  .close-modal-button {
    display: none;
  }

  .issue-pagination-button {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
  }

  .issue-pagination-button.prev {
    left: -100px;
  }

  .issue-pagination-button.next {
    right: -100px;
  }

  @media (max-width: ${({ theme }) => theme.size.sm}px) {
    .close-modal-button {
      display: flex;
      margin: 0;
    }

    .title-block {
      padding: 20px;
    }

    .content {
      max-height: ${({ windowHeight }) => windowHeight! - 89}px;
    }
  }
`

export default StyledIssueModalContent
