import styled from 'styled-components'

const StyledDraftsHeader = styled.div`
  .draft-library-header-top {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 10px;

    .draft-library-query-input {
      width: 100%;
    }

    .draft-library-input-container {
      display: flex;
      width: 100%;
      grid-column: 1/3;

      .input-container {
        .input-icon {
          left: 11px;
          height: 23px;
          width: 23px;

          svg {
            path {
              stroke-width: 1.75px;
            }
          }
        }

        .input-container-border-group {
          .input-container-border {
            &.left {
              border-top-left-radius: 6px;
              border-bottom-left-radius: 6px;
            }

            &.right {
              border-top-right-radius: 6px;
              border-bottom-right-radius: 6px;
            }
          }
        }

        input {
          height: 48px;
          font-size: 1rem;
          padding: 0 20px 0 45px;
        }
      }
    }

    .draft-library-header-action {
      height: 48px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      transition: 0.2s;
      background-color: ${({ theme }) => theme.colors.background};
      border: 1px solid ${({ theme }) => theme.colors.gray[300]};

      * {
        font-size: 1rem;
        font-family: ${({ theme }) => theme.fonts.nunito};
      }

      &.view-select {
        width: fit-content;
        margin-left: 10px;
        background-color: transparent;
        border: 1px solid ${({ theme }) => theme.colors.gray[100]};

        & > div {
          height: 100%;
          border-radius: 5px;
        }
      }

      &.dropdown {
        select {
          height: 48px;
          border: 0;
          width: 100%;
          outline: none;
          background-color: transparent;
          color: ${({ theme }) => theme.colors.gray[600]};
          padding: 0 60px 0 14px;
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          text-overflow: '';

          &:hover {
            cursor: pointer;
          }
        }

        svg {
          top: 50%;
          right: 12px;
          position: absolute;
          transform: translateY(-50%);
          height: 20px;
          pointer-events: none;
          color: ${({ theme }) => theme.colors.gray[300]};
        }
      }

      .checkbox {
        padding: 12px;
        cursor: auto;
        width: 100%;

        label {
          flex-shrink: 0;
        }

        &:hover {
          cursor: pointer;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.gray[100]};
      }
    }

    .upload-btn {
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      user-select: none;
      border: none;
      transition: 0.2s;
      padding: 0 14px;
      background-color: ${({ theme }) => theme.colors.green[50]};
      color: ${({ theme }) => theme.colors.green[500]};

      span {
        position: relative;
        top: 1px;
      }

      svg {
        height: 18px;
        margin-right: 10px;
      }

      &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.green[100]}a4;
      }
    }
  }

  .draft-library-category-select {
    margin-top: 10px;
    display: grid;
    align-items: center;
    grid-gap: 8px;
    grid-template-columns: auto auto minmax(0, 1fr);
  }

  @media (min-width: ${({ theme }) => `${theme.breakpoints.m}px`}) {
    .draft-library-header-top {
      display: grid;
      grid-template-columns: minmax(0, 1fr) auto auto;
      grid-gap: 10px;

      .view-select {
        display: none;
      }

      .draft-library-input-container {
        grid-column: unset;
      }

      .draft-library-header-action {
        &.dropdown {
          select {
            width: unset;
          }
        ]
      }
    }
  }
`

export default StyledDraftsHeader
