import { createGlobalStyle } from 'styled-components'
import { Theme } from './theme'

export const GlobalStyles = createGlobalStyle`
  *, *::after, *::before {
    box-sizing: border-box;
  }

  html, body {
    margin: 0;
    padding: 0;
  }
  
  body {
    color: ${({ theme }: { theme: Theme }) => theme.colors.gray[900]};
    font-size: 1rem;
    font-family: ${({ theme }: { theme: Theme }) => theme.fonts.nunito};
    min-height: 100vh;
    position: relative;
    text-rendering: optimizeLegibility;
    
    @media (max-width: ${({ theme }) => `${theme.size.sm}px`}) {
      font-size: ${({ theme }) => theme.fontSize.sm};
    }
  }

  body.hideOverflow {
    overflow:hidden;
  }

  a, button {
    text-decoration:none;
    color: ${({ theme }: { theme: Theme }) => theme.colors.gray[800]};
    font-weight: normal;
  }

  a {
    font-family: ${({ theme }: { theme: Theme }) => theme.fonts.nunito};
  }

  button {
    font-family: ${({ theme }: { theme: Theme }) => theme.fonts.lexend};
  }

  h1, h2, h3, h4, h5, ul, li, p {
    margin: 0;
  }

  label {
    cursor: auto;
  }

  .collection-dragging {
    .preview-block {
      box-shadow: ${({ theme }) => theme.colors.green[500]} 2px 2px 4px -1px;
    }
    .details {
      display: none;
    }
  }

  .has-tooltip {
    position: relative;
  }

  .tooltip-wrapper {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
`
