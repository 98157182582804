import styled from 'styled-components'

type StyledProps = {
  isRevealed: boolean;
  draftOpened: boolean;
}

const StyledToTopButton = styled.div<StyledProps>`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1rem;
  z-index: ${({ theme }) => theme.elevations.fixed};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[900]};
  border: 0;
  outline: none;
  color: ${({ theme }) => theme.colors.background};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: ${({ isRevealed }) => (isRevealed ? '1' : '0')};
  visibility: ${({ isRevealed }) => (isRevealed ? 'visible' : 'hidden')};
  transition: opacity 0.2s, left 0.2s, visibility 0.3s, background-color 0.2s;

  svg {
    height: 26px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[700]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.s}px) {
    left: calc(50% + (240px / 2) - ${({ draftOpened }) => draftOpened ? '(400px / 2)' : '0px'});
  }

  @media (max-width: ${({ theme }) => theme.size.sm}px) {
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.gray[800]};
    background-color: ${({ theme }) => theme.colors.gray[200]};
    box-shadow: 0px 4px 8px rgba(97, 97, 97, 0.18);
    border-radius: 10px;
    transform: translate(-50%, 0);
    width: unset;
    padding: 0 14px;
    height: 32px;

    svg {
      display: none;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.gray[300]};
    }

    ::after {
      content: 'BACK TO TOP';
    }
  }
`

export default StyledToTopButton
