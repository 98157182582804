import React, { FC, RefObject } from 'react'
import RenderSlate from '../Slate/RenderSlate'
import { StyledIssueAbout } from '.'

type Props = {
  id?: string;
  reference: RefObject<HTMLDivElement>;
  content: string;
  height?: number;
  isRevealed?: boolean;
}

const IssueAbout: FC<Props> = ({
  id, content, height, isRevealed, reference
}) => {
  return (
    <StyledIssueAbout height={height} isRevealed={isRevealed}>
      <div ref={reference}>
        <RenderSlate id={id} html={content} />
      </div>
    </StyledIssueAbout>
  )
}

export default IssueAbout
