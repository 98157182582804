import { useApolloClient } from '@apollo/client'
import { useEffect, useContext, useState } from 'react'
import { search as searchQuery } from '../../graphql/CaptionSet/captionSearchQuery'
import { userStore } from '../utils/UserContext'
import { UsageDataType } from '../../types'
import { SearchCaptions } from '../../graphql/CaptionSet/__generated__/SearchCaptions'
import { SearchGalleryQuery } from '../../graphql/GalleryImage/__generated__/SearchGalleryQuery'
import { searchGalleryQuery } from '../../graphql/GalleryImage/searchGallery'
import { SearchUserDrafts } from '../../graphql/UserDraft/__generated__/SearchUserDrafts'
import { searchUserDraftsQuery } from '../../graphql/UserDraft/searchUserDrafts'

const useUsageData = () => {
  const { user } = useContext(userStore)
  const apollo = useApolloClient()

  const [usageData, setUsageData] = useState<UsageDataType | null>(null)
  const [completedTasks, setCompletedTasks] = useState(false)

  // get number of drafts
  const getDrafts = async () => {
    const { data } = await apollo.query<SearchUserDrafts>({
      query: searchUserDraftsQuery,
      fetchPolicy: 'no-cache',
      variables: {
        page: 0,
        search: '',
        categories: [],
        sort: 'custom',
        items: 1
      }
    })

    return data?.searchUserDrafts?.totalDocs || 0
  }

  // get number of favorited images from Algolia
  const getFavImages = async () => {
    const { data } = await apollo.query<SearchGalleryQuery>({
      query: searchGalleryQuery,
      fetchPolicy: 'no-cache',
      variables: {
        search: '',
        categories: [],
        colors: [],
        style: [],
        items: 1,
        liked: true,
        page: 0,
        sort: 'custom'
      }
    })

    return data?.searchGallery?.totalDocs || 0
  }

  // get number of favorited captionSets from GraphQL
  const getFavCaptions = async () => {
    const { data } = await apollo.query<SearchCaptions>({
      fetchPolicy: 'no-cache',
      query: searchQuery,
      variables: {
        page: 0,
        search: '',
        liked: true,
        categories: [],
        sort: 'suggested'
      }
    })
    return data?.captionSetSearch?.totalDocs || 0
  }

  const getAllUsageData = async () => {
    const [numDrafts, numImages, numCaptions] = await Promise.all([
      getDrafts(),
      getFavImages(),
      getFavCaptions()
    ])

    setUsageData({
      draftCount: numDrafts,
      imageCount: numImages,
      captionCount: numCaptions
    })

    // true if completed at least 2 out of 3: drafts, fav images, collections
    setCompletedTasks(
      (numDrafts > 0 && numImages > 0) ||
        (numDrafts > 0 && numCaptions > 0) ||
        (numImages > 0 && numCaptions > 0)
    )
  }

  // if user is inactive or currently trialing, proceed, if not, eject
  useEffect(() => {
    if (user?.stripeSubscriptionStatus === 'Inactive' || user?.trial) {
      getAllUsageData()
    }
  }, [])

  return {
    usageData,
    completedTasks,
    getAllUsageData
  }
}

export default useUsageData
