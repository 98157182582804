import React from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { GalleryCollectionItem } from '.'

export const shouldCancelStart = (e) => {
  const { classList } = e.target
  return classList.contains('cancel-start') || classList.contains('tooltip-wrapper')
}

export const SortableCollectionFeedItem = SortableElement(({ data, dragging }) => {
  return <GalleryCollectionItem collection={data} key={data._id} dragging={dragging} />
})

export const SortableCollections = SortableContainer(({ collections, dragging }) => (
  <div className="collections-grid">
    {collections.map((item, index) => (
      <SortableCollectionFeedItem data={item} dragging={dragging} key={item._id} index={index} />
    ))}
  </div>
))
