import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { isProduction } from './firebase'

const beforeSendingSentryEvent = (event: Sentry.Event, hint: Sentry.EventHint) => {
  const errorsArray = (event?.exception?.values || []) as any[]
  const isVimeoError = errorsArray[0]?.value?.detail?.name === 'PrivacyError'

  if (!isProduction) {
    console.log('Sentry caught this error:')
    console.error(hint.originalException || hint.syntheticException)
  }

  if (isVimeoError) {
    return null
  }

  return event
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  integrations: [
    new Integrations.BrowserTracing(),
    new Sentry.Integrations.Breadcrumbs({ console: isProduction })
  ],
  environment: isProduction ? 'production' : 'staging',
  tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_SAMPE_RATE as string),
  beforeSend: beforeSendingSentryEvent,
  ignoreErrors: [
    /FIRESTORE/gi,
    'Error: ResizeObserver loop limit exceeded',
    'IDBFactory',
    'IndexedDB',
    'code_, message_, name_, serverResponse_',
    'InvalidStateError: Failed to execute \'transaction\' on \'IDBDatabase\': The database connection is closing.',
    'SecurityError: Failed to read the \'localStorage\' property from \'Window\': Access is denied for this document.',
    'FirebaseError: Messaging: We are unable to register the default service worker.'
  ]
})
