import { gql } from '@apollo/client'

export const highlightFindMany = gql`
  query HighlightFindMany($issue: String!) {
    highlightFindMany(filter: { issue: $issue }, sort: INDEX_ASC) {
      _id
      index
      title
      video
      isActive
      thumbnail {
        fileHttpLink
        fullPath
      }
    }
  }
`
