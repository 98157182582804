import styled from 'styled-components'

type StyledProps = {
  isDragging: boolean;
}

const StyledDraftsUploadPhotos = styled.button<StyledProps>`
  min-width: 165px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  user-select: none;
  border: none;
  transition: 0.2s;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.green[50]};
  color: ${({ theme }) => theme.colors.green[500]};
  position: relative;

  span {
    position: relative;
    top: 1px;
  }

  svg {
    height: 18px;
    margin-right: 10px;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    font-size: 0;

    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.green[100]}a4;
  }
`

export default StyledDraftsUploadPhotos
