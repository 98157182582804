// Returns true if the inputted date is today

export const isToday = (date: firebase.firestore.Timestamp) => {
  const inputDate = new Date(date.seconds * 1000)
  const today = new Date()

  if (
    inputDate.getDate() === today.getDate() &&
    inputDate.getMonth() === today.getMonth() &&
    inputDate.getFullYear() === today.getFullYear()
  ) {
    return true
  }

  return false
}
