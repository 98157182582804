import styled, { keyframes, css } from 'styled-components'
import { theme } from '../utils/theme'

type StyledProps = {
  canReveal: boolean;
  revealed: boolean;
  liked: boolean;
  used: boolean;
  isPreview?: boolean;
}

const checkAnim = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const likeAnim = keyframes`
  0% {
    transform: scale(0.3);
    fill: ${theme.colors.red[600]};
    color: ${theme.colors.red[600]};
  }
  60% {
    transform: scale(1.2);
    fill: ${theme.colors.red[600]};
    color: ${theme.colors.red[600]};
  }
  80% {
    transform: scale(0.9);
    fill: ${theme.colors.red[600]};
    color: ${theme.colors.red[600]};
  }
  100% {
    transform: scale(1);
    fill: ${theme.colors.red[600]};
    color: ${theme.colors.red[600]};
  }
`

const StyledLibraryCaption = styled.div<StyledProps>`
  overflow: hidden;
  position: relative;
  padding: 16px 16px 16px 16px;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  box-sizing: border-box;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.background};
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;

  .caption-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    .caption-title {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.gray[800]};
    }

    .caption-header-group {
      display: flex;
      align-items: center;

      .caption-category {
        font-weight: 600;
        align-items: center;
        font-size: 14px;
        line-height: 24px;
        flex-shrink: 0;
        height: 28px;
        padding: 0 8px;
        user-select: none;
        pointer-events: none;
        border-radius: 6px;
        margin-left: 1rem;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        background-color: ${({ theme }) => theme.colors.gray[100]};
        color: ${({ theme }) => theme.colors.gray[500]};

        svg {
          height: 18px;
          margin-right: 4px;
          position: relative;
          top: 4px;
        }
      }

      button {
        height: 28px;
        width: 28px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        border: 0;
        padding: 0;
        position: relative;
        margin-left: 6px;
        transition: background-color 0.2s;
        background-color: ${({ theme }) => theme.colors.gray[100]};
        color: ${({ theme }) => theme.colors.gray[500]};

        svg {
          height: 20px;
        }

        &:hover {
          cursor: pointer;
          background-color: ${({ theme }) => theme.colors.gray[200]};
        }
      }

      .used-btn {
        svg {
          pointer-events: none;
          top: 50%;
          left: 50%;
          position: absolute;
          transform: translate(-50%, -50%);
          transition: opacity 0.1s;
        }

        .check-icon {
          path {
            ${({ used }) =>
    used &&
              css`
                stroke-dasharray: 1000;
                stroke-dashoffset: 1000;
                animation: ${checkAnim} 15s linear forwards;
              `}
          }
        }

        .x-icon {
          opacity: 0;
        }

        &:hover {
          .x-icon {
            opacity: 1;
          }

          .check-icon {
            opacity: 0;
          }
        }
      }
    }
  }

  .caption-content {
    font-size: 15px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.gray[800]};

    ${({ isPreview }) =>
    isPreview &&
      `
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
  }

  .caption-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;

    button {
      height: 34px;
      display: flex;
      align-items: center;
      border: none;
      background-color: transparent;
      padding: 0;
      transition: 0.2s;
      border-radius: 6px;

      &:hover {
        cursor: pointer;
      }
    }

    .use-btn {
      padding: 0 10px;
      font-weight: 600;
      user-select: none;
      background-color: ${({ theme }) => theme.colors.gray[100]};
      color: ${({ theme }) => theme.colors.gray[600]};

      span {
        position: relative;
        top: 1px;
      }

      svg {
        height: 18px;
        margin-right: 8px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[200]};
      }
    }

    .fav-btn {
      width: 34px;
      justify-content: center;
      color: ${({ theme }) => theme.colors.gray[300]};

      svg {
        height: 26px;

        ${({ liked }) =>
    liked &&
          css`
            animation: ${likeAnim} forwards 0.2s ease-in-out;
          `}

        path {
          stroke-width: 1.7px;
        }
      }

      &:hover {
        background-color: ${({ liked, theme }) =>
    (liked ? theme.colors.red[100] : theme.colors.gray[100])};
      }
    }
  }
`
export default StyledLibraryCaption
