import React, { FC, useState, createRef, useEffect } from 'react'
import classNames from 'classnames'
import { throttle } from 'lodash'
import useClickOutsideComponent from '../../utils/useClickOutsideComponent'
import { Button } from '../Button'
import { StyledIconPicker, ICONS } from '.'

type Props = {
  value: string;
  onChange: (icon: string) => void;
}

const IconPicker: FC<Props> = ({ value, onChange }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [iconPickerMenuPos, setIconPickerMenuPos] = useState({ top: 0, left: 0 })
  const iconPickerRef = createRef<HTMLButtonElement>()
  const iconPickerMenuRef = createRef<HTMLDivElement>()

  const selectIcon = (icon: string) => {
    setMenuOpen(false)
    onChange(icon)
  }

  useClickOutsideComponent(iconPickerMenuRef, () => {
    setMenuOpen(false)
  })

  useEffect(() => {
    if (!iconPickerRef.current) {
      return
    }

    const iconPicker = iconPickerRef.current

    setIconPickerMenuPos({
      top: iconPicker.offsetTop,
      left: iconPicker.offsetLeft
    })

    const handleResize = throttle(() => {
      setIconPickerMenuPos({
        top: iconPicker.offsetTop,
        left: iconPicker.offsetLeft
      })
    }, 333)

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <StyledIconPicker iconPickerMenuPos={iconPickerMenuPos}>
      <Button
        theme="outline"
        isIcon
        onClick={() => setMenuOpen(true)}
        ref={iconPickerRef}
        className="icon-picker"
      >
        {ICONS.find((icon) => icon.name === value)?.icon}
      </Button>
      {menuOpen && (
        <div ref={iconPickerMenuRef} className="icon-picker-menu">
          {ICONS.map((icon, index) => {
            return (
              <Button
                onClick={() => selectIcon(icon.name)}
                className={classNames('icon-pick-btn', value === icon.name && 'active')}
                theme="ghost"
                isIcon
                key={index}
              >
                {icon.icon}
              </Button>
            )
          })}
        </div>
      )}
    </StyledIconPicker>
  )
}

export default IconPicker
