import styled from 'styled-components'

type StyledProps = {
  type: 'default' | 'warning' | 'error';
}

const StyledActionDialog = styled.div<StyledProps>`
  max-width: 375px;
  width: 100vw;
  padding: 28px;

  .dialog-header {
    display: flex;
    align-items: center;

    .dialog-message-icon {
      flex-shrink: 0;
      margin-right: 12px;
      height: 28px;
      width: 28px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ type, theme }) => `
        ${type === 'default' ? theme.colors.gray[200] : ''}
        ${type === 'error' ? theme.colors.red[200] : ''}
        ${type === 'warning' ? theme.colors.amber[200] : ''}
      `};
      color: ${({ type, theme }) => `
        ${type === 'default' ? theme.colors.gray[600] : ''}
        ${type === 'error' ? theme.colors.red[900] : ''}
        ${type === 'warning' ? theme.colors.amber[900] : ''}
      `};

      svg {
        height: 22px;
      }
    }

    .dialog-title {
      font-weight: 700;
      font-size: 17px;
      color: ${({ type, theme }) => `
        ${type === 'default' ? theme.colors.gray[700] : ''}
        ${type === 'error' ? theme.colors.red[900] : ''}
        ${type === 'warning' ? theme.colors.amber[900] : ''}
      `};
    }
  }

  .dialog-message-container {
    margin-top: 12px;

    .dialog-body {
      line-height: 22px;
      margin-top: 3px;
      color: ${({ theme }) => theme.colors.gray[600]};

      a {
        font-weight: 600;
        text-decoration: underline;
        border-radius: 2px;
        transition: 0.2s;
        color: ${({ theme }) => theme.colors.blue[700]};

        &:hover {
          box-shadow: 0 0 0 3px #e0f2fe;
          background-color: ${({ theme }) => theme.colors.blue[100]};
        }
      }
    }
  }

  .dialog-actions {
    margin-top: 28px;
    display: flex;

    button {
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: transparent;
      padding: 0 18px;
      transition: 0.2s;
      border-radius: 6px;
      user-select: none;
      font-weight: 600;
      background-color: ${({ theme }) => theme.colors.gray[100]};
      color: ${({ theme }) => theme.colors.gray[600]};

      span {
        position: relative;
        top: 1px;
      }

      &:not(:last-child) {
        margin-right: 16px;
        flex-shrink: 0;
      }

      &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.gray[200]};
      }
    }

    .confirm-btn {
      width: 100%;
      background-color: ${({ type, theme }) => `
        ${type === 'default' ? theme.colors.green[500] : ''}
        ${type === 'error' ? theme.colors.red[700] : ''}
        ${type === 'warning' ? theme.colors.amber[700] : ''}
      `};
      color: ${({ theme }) => theme.colors.background};

      span {
        position: relative;
        top: 1px;
      }

      svg {
        height: 18px;
        margin-right: 8px;
      }

      &:hover {
        background-color: ${({ type, theme }) => `
          ${type === 'default' ? theme.colors.green[600] : ''}
          ${type === 'error' ? theme.colors.red[800] : ''}
          ${type === 'warning' ? theme.colors.amber[800] : ''}
        `};
      }
    }
  }
`

export default StyledActionDialog
