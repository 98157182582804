import React, { FC, ReactNode } from 'react'
import classNames from 'classnames'
import { StyledGrid } from '.'

type Props = {
  gap?: number;
  columns?: number;
  children?: ReactNode;
  className?: string;
  inLayout?: boolean; // Is set to true if the grid has something before and after it (applies the needed spacing)
}

const Grid: FC<Props> = ({
  gap = 10, columns, children, inLayout, className
}) => {
  return (
    <StyledGrid
      gap={gap}
      columns={columns}
      inLayout={inLayout}
      className={classNames('grid', className)}
    >
      {children}
    </StyledGrid>
  )
}

export default Grid
