import styled from 'styled-components'

const StyledDraftDrawerErrorBanner = styled.div`
  padding: 10px 20px;
  user-select: none;
  background-color: ${({ theme }) => theme.colors.red[100]};
  z-index: 1;
  display: grid;
  grid-gap: 10px;
  position: sticky;
  top: 0;

  .banner-header {
    display: flex;
    font-weight: 700;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.red[600]};

    .banner-header-icon {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }) => theme.colors.red[600]};
      color: ${({ theme }) => theme.colors.background};
      border-radius: 6px;
      margin-right: 12px;
      flex-shrink: 0;

      svg {
        height: 20px;
      }
    }

    span {
      min-height: 24px;
      display: flex;
      align-items: center;
    }
  }
`

export default StyledDraftDrawerErrorBanner
