import React, { FC, useEffect, useContext } from 'react'
import { useApolloClient } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { userStore } from '../utils/UserContext'
import { GetUserChallenges } from '../../graphql/Challenge/__generated__/GetUserChallenges'
import { getUserChallengesQuery } from '../../graphql/Challenge/getUserChallenges'
import { ChallengeSetDataAction } from '../../redux/challenge/actions'

const Challenge: FC = () => {
  const apollo = useApolloClient()
  const { user } = useContext(userStore)
  const dispatch = useDispatch()

  const getChallenge = async () => {
    const challengeQuery = await apollo.query<GetUserChallenges>({
      query: getUserChallengesQuery,
      fetchPolicy: 'no-cache'
    })

    const challenges = challengeQuery?.data?.getUserChallenges
    if (!challenges?.length) {
      dispatch(ChallengeSetDataAction({ currentChallenge: null, userChallenge: null }))
      return
    }

    const { userChallenge, ...currentChallenge } = challenges[0]
    dispatch(ChallengeSetDataAction({ currentChallenge, userChallenge }))
  }

  useEffect(() => {
    getChallenge()
  }, [user])

  return null
}

export default Challenge
