//default index file created by jakeCLI 1.0 12:28:38 GMT-0800 (Pacific Standard Time)
export { default as OnboardingView } from './OnboardingView'
export { default as StyledOnboardingView } from './OnboardingView.Styled'
export { default as QuestionBox } from './QuestionBox'
export { default as StyledQuestionBox } from './QuestionBox.Styled'
export { default as ProgressBar } from './ProgressBar'
export { default as StyledProgressBar } from './ProgressBar.Styled'
export { default as AnswerView } from './AnswerView'
export { default as StyledAnswerView } from './AnswerView.Styled'

// Onboarding Types

export const onboardingInitialState = {
  product: '',
  industry: '',
  postFrequency: '',
  customerFrequency: '',
  confidenceLevel: '',
  primaryPlatform: '',
  preferredFeature: ''
}

export type OnboardingState = typeof onboardingInitialState & { customIndustry?: string }

export type OnboardingAnswer = {
  text: string;
  value: string;
  score?: number;
  emoji?: {
    label: string;
    symbol: string;
  } | null;
}

export type OnboardingQuestion = {
  id: string;
  question: string;
  questionEnd?: string;
  answers: OnboardingAnswer[];
  subtitle: string;
  type: string;
}
