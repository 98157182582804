import React, {
  FC, ReactNode, createRef, useEffect, useState
} from 'react'
import { throttle } from 'lodash'
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/outline'
import { StyledTabBar } from '.'

type Props = {
  children?: ReactNode;
}

const TabBar: FC<Props> = ({ children }) => {
  const scrollRef = createRef<HTMLDivElement>()
  const [scrollOffset, setScrollOffset] = useState(0)
  const [scrollWidth, setScrollWidth] = useState(0)

  const handleSize = throttle(() => {
    if (!scrollRef.current) {
      return
    }

    const scrollContainer = scrollRef.current
    setScrollWidth(scrollContainer.scrollWidth - scrollContainer.clientWidth)
  }, 333)

  const handleScroll = throttle(() => {
    if (scrollRef.current) {
      setScrollOffset(scrollRef.current.scrollLeft)
    }
  }, 333)

  // Handles left or right pagination
  const scrollDirection = (direction: 'left' | 'right') => {
    const scrollAmount = 400
    const scrollDistance = direction === 'left' ? -scrollAmount : scrollAmount

    return scrollRef?.current?.scroll({
      left: scrollOffset + scrollDistance,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    handleSize()
    window.addEventListener('resize', handleSize)

    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', handleScroll)
      }
      window.removeEventListener('resize', handleSize)
    }
  }, [scrollRef])

  return (
    <StyledTabBar
      showFadeLeft={scrollOffset > 0}
      showFadeRight={scrollOffset !== scrollWidth}
      className="tab-bar-container"
    >
      <div className="scroll-indicator left" onClick={() => scrollDirection('left')}>
        <ChevronLeftIcon />
      </div>
      <div ref={scrollRef} className="tab-bar">
        {children}
      </div>
      <div className="scroll-indicator right" onClick={() => scrollDirection('right')}>
        <ChevronRightIcon />
      </div>
    </StyledTabBar>
  )
}

export default TabBar
