import styled from 'styled-components'

const StyledAnswerView = styled.div`
  .answer-text {
    color: ${({ theme }) => theme.colors.gray[700]};
    margin-top: 8px;
  }

  span.user-input {
    display: inline-block;
    min-width: 80px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[500]};
    background-color: ${({ theme }) => theme.colors.gray[200]};
  }

  span.user-input.active {
    display: inline;
  }

  input,
  span.user-input {
    height: 27px;
    margin: 0 5px;
  }

  input,
  span.user-input.active {
    border-bottom: 1px solid ${({ theme }) => theme.colors.green[300]};
    background-color: ${({ theme }) => theme.colors.green[50]};
    padding: 0 3px;
  }

  input {
    color: ${({ theme }) => theme.colors.gray[700]};
    border: 0;
    width: 45%;

    :focus {
      outline: none;
    }
  }
`

export default StyledAnswerView
