import styled, { keyframes } from 'styled-components'
import { theme } from '../utils/theme'

const pulse = keyframes`
  0% {
    background: ${theme.colors.gray[100]};
  }
  50% {
    background: ${theme.colors.gray[200]};
  }
  100% {
    background: ${theme.colors.gray[100]};
  }
`

const StyledDummyAction = styled.div`
  display: contents;

  .dummy-action-right {
    display: flex;

    .dummy-action-icon-group {
      display: grid;
      justify-items: center;
      align-content: start;
      grid-gap: 8px;
      margin-right: 1rem;
      flex-shrink: 0;

      .dummy-action-icon {
        height: 38px;
        width: 38px;
        border-radius: 50%;
        animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
      }

      .dummy-action-step {
        display: flex;

        div {
          height: 6px;
          width: 6px;
          border-radius: 50%;
          animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
          background-color: ${({ theme }) => theme.colors.gray[200]};

          &:not(:last-child) {
            margin-right: 5px;
          }
        }
      }
    }

    .dummy-action-info-container {
      display: grid;
      align-self: center;

      .dummy-action-info {
        display: flex;
        align-items: center;

        .dummy-action-category {
          height: 20px;
          width: 120px;
          border-radius: 6px;
          animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
          margin-right: 8px;
        }

        .dummy-action-pagination-btn {
          width: 20px;
          height: 20px;
          padding: 0;
          border-radius: 6px;
          display: flex;
          margin-right: 4px;
          animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
          align-items: center;
          background-color: transparent;
          justify-content: center;
        }
      }

      .dummy-action-text {
        height: 26px;
        margin-top: 5px;
        width: 400px;
        border-radius: 6px;
        animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
      }
    }
  }

  .dummy-complete-btn {
    margin-left: 1rem;
    height: 52px;
    width: 52px;
    border-radius: 50%;
    flex-shrink: 0;
    animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
`

export default StyledDummyAction
