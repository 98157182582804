import { useContext, useState } from 'react'
import firebase from 'firebase/app'
import { userStore } from '../utils/UserContext'
import { DailyActionType } from '../../types'

type UserAction = {
  userId: string;
  actionId: string;
  completedAt?: firebase.firestore.Timestamp;
  loadedAt: firebase.firestore.Timestamp;
  completedIndex: number;
  category: string;
}

const useGenerateDailyAction = () => {
  const [generatedActions, setGeneratedActions] = useState<DailyActionType[]>([])

  const { user } = useContext(userStore)
  const actionsDB = firebase.firestore().collection('daily_actions')
  const userActionsDB = firebase.firestore().collection('user_daily_actions')

  // Generates and returns three filtered daily actions
  const generateActions = async () => {
    const recentActions = await getRecentCompletedActions()
    const categories = await generateFilter(recentActions)
    const mostRecentAction = recentActions[0]

    // Generate first action
    const firstAction = await loadFilteredAction(1, categories, mostRecentAction)
    const firstActionIsPost = firstAction.category === 'post'

    // Update filter if new category
    if (!categories.includes(firstAction.category)) {
      categories.push(firstAction.category)
    }

    // Generate second action
    const secondAction = await loadFilteredAction(2, categories, firstAction)
    // Update filter if new category
    if (!categories.includes(secondAction.category)) {
      categories.push(secondAction.category)
    }

    // Generate third action
    const thirdAction = await loadFilteredAction(3, categories, secondAction, firstActionIsPost)

    const generatedActions: DailyActionType[] = [firstAction, secondAction, thirdAction]
    setGeneratedActions(generatedActions)
    return generatedActions
  }

  // Loads the user's 10 most recent completed actions
  const getRecentCompletedActions = async () => {
    const { docs } = await userActionsDB
      .where('userId', '==', user?.id)
      .where('completedAt', '!=', null)
      .orderBy('completedAt', 'desc')
      .limit(10)
      .get()

    // Filters where the loaded date is greater than today
    // minus the designated time interval
    const timeInterval = 60 * 60 * 24 * 3 // Three days
    const timeFrame = new Date().getTime() / 1000 - timeInterval

    const tempUserActions = docs.map((action) => ({ ...action.data() })) as UserAction[]
    const userActions = tempUserActions.filter((action) => action.loadedAt?.seconds! > timeFrame)

    return userActions
  }

  // Generates filters for the random action query based off of
  // what the user has already completed within the time interval
  const generateFilter = async (recentActions: UserAction[]) => {
    const categories: string[] = ['']

    for (const userAction of recentActions) {
      if (!categories.includes(userAction.category)) {
        categories.push(userAction.category)
      }
    }

    return categories
  }

  // Loads a random daily action based on the filters
  const loadFilteredAction = async (
    actionNum: number,
    categories: string[],
    lastAction: UserAction | DailyActionType,
    firstActionIsPost?: boolean
  ) => {
    // Check if a post action should be loaded
    const loadPostAction = shouldLoadPostAction(actionNum, lastAction, firstActionIsPost)

    // If not loading a post action...
    if (!loadPostAction) {
      const { docs } = await actionsDB
        .where('category', 'not-in', categories)
        .where('isActive', '==', true)
        .get()

      const notFiveMinuteActions = docs.map((action) => ({
        ...action.data(),
        id: action.id
      })) as DailyActionType[]

      // Makes sure it removes post actions from list
      const filteredActions = notFiveMinuteActions.filter((action) => action.category !== 'post')

      const randomIndex = Math.floor(Math.random() * filteredActions.length)
      const selectedAction = filteredActions[randomIndex]

      return selectedAction
    }

    // If loading a post action...

    // Selects the five minute actions
    const { docs } = await actionsDB
      .where('category', '==', 'post')
      .where('isActive', '==', true)
      .get()

    const fiveMinuteActions = docs.map((action) => ({
      ...action.data(),
      id: action.id
    })) as DailyActionType[]

    const randomIndex = Math.floor(Math.random() * fiveMinuteActions.length)
    const selectedAction = fiveMinuteActions[randomIndex]

    return selectedAction
  }

  // Runs a series of checks on previous action to see if we should load a post action
  const shouldLoadPostAction = (
    actionNum: number,
    lastAction: UserAction | DailyActionType,
    firstActionIsPost?: boolean
  ) => {
    const randomNum = Math.floor(Math.random() * 3)

    if (!lastAction) {
      return false
    }

    // Last action was a post action
    if (lastAction.category === 'post') {
      return false
    }

    if (actionNum !== 3 && randomNum !== 1) {
      // 33% chance that action #1 or #2 are post actions
      return false
    }

    if (actionNum === 3 && firstActionIsPost) {
      // If second action wasn't a post action but the first action was,
      // don't load a post action for action #3
      return false
    }

    return true
  }

  return {
    generatedActions,
    actions: {
      loadFilteredAction,
      generateActions
    }
  }
}

export default useGenerateDailyAction
