import React, { FC, useState, useEffect, useMemo } from 'react'
import * as Sentry from '@sentry/react'
import { useHistory, useParams } from 'react-router-dom'
import firebase from 'firebase/app'
import { useApolloClient } from '@apollo/client'
import { linkToSizedImage } from '../../IssueEntityImage/helpers'
import ROUTES from '../../utils/routes'
import { IssueModalPhoto, IssueModalContent, IssueModalStory, IssueModalExtra } from '.'
import { PaginationButton } from '../../System'
import { GetIssueBySlug_issueFindOne } from '../../../graphql/Issue/__generated__/GetIssueBySlug'
import { archiveFindOneQuery } from '../../../graphql/Archive/archiveFindOne'
import { ArchiveFindOneQuery } from '../../../graphql/Archive/__generated__/ArchiveFindOneQuery'
import { storySetFindMany } from '../../../graphql/StorySet/storySetFindMany'
import {
  StorySetFindMany,
  StorySetFindMany_storySetFindMany
} from '../../../graphql/StorySet/__generated__/StorySetFindMany'
import { captionSetFindMany } from '../../../graphql/CaptionSet/captionSetFindMany'
import {
  CaptionSetFindMany,
  CaptionSetFindMany_captionSetFindMany
} from '../../../graphql/CaptionSet/__generated__/CaptionSetFindMany'
import { extraSetFindMany } from '../../../graphql/ExtraSet/extraSetFindMany'
import {
  ExtraSetFindMany,
  ExtraSetFindMany_extraSetFindMany
} from '../../../graphql/ExtraSet/__generated__/ExtraSetFindMany'
import { useNotifications } from '../../Notifications'

type RecordSets =
  | StorySetFindMany_storySetFindMany
  | CaptionSetFindMany_captionSetFindMany
  | ExtraSetFindMany_extraSetFindMany

type Props = {
  isOpen: boolean;
  issue: GetIssueBySlug_issueFindOne;
}

const IssueModal: FC<Props> = ({ isOpen, issue }) => {
  const { recordType, recordId }: any = useParams()

  const history = useHistory()
  const apollo = useApolloClient()
  const { addNotification } = useNotifications()
  const storage = firebase.storage()

  const [isFullScreen, setFullScreen] = useState(false)
  const [downloadUrl, setDownloadUrl] = useState('')
  const [viewData, setViewData] = useState<RecordSets[]>([])
  const [viewIndex, setViewIndex] = useState(0)
  const currentViewData = useMemo(() => viewData[viewIndex], [viewData, viewIndex])

  // Returns the collection association with the current record type
  const getCollectionQuery = async () => {
    try {
      switch (recordType) {
        case 'story':
          const storyDocs = await apollo.query<StorySetFindMany>({
            query: storySetFindMany,
            variables: { issue: issue._id }
          })
          return storyDocs.data.storySetFindMany || []
        case 'photo':
          const captionDocs = await apollo.query<CaptionSetFindMany>({
            query: captionSetFindMany,
            variables: { issue: issue._id }
          })
          return captionDocs.data.captionSetFindMany || []
        default:
          const extraDocs = await apollo.query<ExtraSetFindMany>({
            query: extraSetFindMany,
            variables: { issue: issue._id }
          })
          return extraDocs.data.extraSetFindMany || []
      }
    }
    catch (err) {
      addNotification({ message: err.message, type: 'error', interval: 10000 })
      Sentry.captureException(err)
      return []
    }
  }

  const pushUrl = (dataId: string, action = 'open') => {
    if (action === 'open') {
      history.push({ pathname: ROUTES.issues.buildRecordPage(dataId, issue!.slug as string, recordType) })
    }
    else {
      history.push({ pathname: ROUTES.issues.viewBySlug(issue!.slug) })
    }
  }

  // Function to paginate through the modal
  const changePage = (direction: string) => {
    if (direction == 'next' && viewIndex < viewData.length - 1) {
      setViewIndex(viewIndex + 1)
      pushUrl(viewData[viewIndex + 1]._id)
    }
    else if (direction == 'prev' && viewIndex > 0) {
      setViewIndex(viewIndex - 1)
      pushUrl(viewData[viewIndex - 1]._id)
    }
  }

  // Function that listens for keystrokes, and allows them to
  // be used to paginate through the modal
  const handleKeyDown = (event) => {
    if (event.key === 'ArrowRight') {
      changePage('next')
    }
    if (event.key === 'ArrowLeft') {
      changePage('prev')
    }
    if (event.key === 'Escape') {
      pushUrl('', 'close')
    }
  }

  // Returns the modal title based on the modal / record type
  const getTitle = () => {
    switch (recordType) {
      case 'story':
        return `STORY SET # ${viewIndex + 1}`
      case 'extra' &&
        (currentViewData as ExtraSetFindMany_extraSetFindMany)?.contentType === 'video':
        return 'Watch Video'
      case 'extra' &&
        (currentViewData as ExtraSetFindMany_extraSetFindMany)?.contentType === 'file':
        return 'View Pdf'
      default:
        return (currentViewData as CaptionSetFindMany_captionSetFindMany)?.title
    }
  }

  // Mounts the handleKeyDown function
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [viewIndex, viewData])

  const getUrl = async () => {
    const { data } = await apollo.query<ArchiveFindOneQuery>({
      query: archiveFindOneQuery,
      variables: { recordId, type: 'storyset' }
    })

    const zipPath = data.archiveFindOne?.fullPath
    if (zipPath) {
      const fileRef = storage.ref().child(zipPath)
      const zipUrl = await fileRef.getDownloadURL()
      setDownloadUrl(zipUrl)
    }
  }

  // sets the zip (download URL for the entire set within the modal)
  useEffect(() => {
    if (recordId && recordType === 'story') {
      getUrl()
    }
    else if (currentViewData) {
      if (
        recordType !== 'extra' ||
        (recordType === 'extra' &&
          (currentViewData as ExtraSetFindMany_extraSetFindMany).contentType === 'image')
      ) {
        setDownloadUrl(
          linkToSizedImage(
            (currentViewData as ExtraSetFindMany_extraSetFindMany).image?.fileHttpLink || '',
            {}
          )
        )
      }
    }
  }, [recordId, viewData, viewIndex])

  const handleRecordsFetch = async () => {
    const viewingData: RecordSets[] = await getCollectionQuery()

    viewingData.map((item, index) => {
      if (item._id == recordId) {
        setViewIndex(index)
      }
    })

    setViewData(viewingData)
  }

  useEffect(() => {
    if (!issue._id) {
      return
    }

    handleRecordsFetch()
  }, [issue._id])

  return (
    <IssueModalContent
      show={isOpen && viewData.length > 0}
      title={getTitle()}
      subTitle={
        (currentViewData as CaptionSetFindMany_captionSetFindMany)?.category ||
        currentViewData?.title
      }
      recordType={recordType}
      downloadUrl={downloadUrl}
      onClose={() => pushUrl('', 'close')}
      modalType="issue"
      extraType={(currentViewData as ExtraSetFindMany_extraSetFindMany)?.contentType}
    >
      {!isFullScreen && viewIndex > 0 ? (
        <PaginationButton
          onClick={() => changePage('prev')}
          className="issue-pagination-button prev"
          direction="left"
        />
      ) : (
        <span />
      )}
      {!isFullScreen && viewIndex < viewData.length - 1 && (
        <PaginationButton
          onClick={() => changePage('next')}
          className="issue-pagination-button next"
          direction="right"
        />
      )}

      {recordType === 'photo' && (
        <IssueModalPhoto
          issue={issue}
          setFullScreen={(fullscreen) => setFullScreen(fullscreen)}
          isFullScreen={isFullScreen}
          photo={currentViewData as CaptionSetFindMany_captionSetFindMany}
        />
      )}
      {recordType === 'story' && (
        <IssueModalStory
          issue={issue}
          setFullScreen={(fullscreen) => setFullScreen(fullscreen)}
          isFullScreen={isFullScreen}
          story={currentViewData as StorySetFindMany_storySetFindMany}
        />
      )}
      {recordType === 'extra' && (
        <IssueModalExtra
          issue={issue}
          setFullScreen={(fullscreen) => setFullScreen(fullscreen)}
          isFullScreen={isFullScreen}
          extra={currentViewData as ExtraSetFindMany_extraSetFindMany}
        />
      )}
    </IssueModalContent>
  )
}

export default IssueModal
