import React, { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { StyledDashboardTile } from '.'

type Props = {
  title: string;
  url?: {
    external?: boolean;
    path: string;
  };
  children?: ReactNode;
}

const DashboardTile: FC<Props> = ({ title, url, children }) => {
  return (
    <StyledDashboardTile>
      <div className="dashboard-tile-heading">
        <span className="dashboard-tile-title">{title}</span>
        {url?.path && url?.external && (
          <a href={url.path} rel="noopener noreferrer" target="_blank">
            See All
          </a>
        )}
        {url?.path && !url?.external && <Link to={url.path}>See All</Link>}
      </div>
      <div className="dashboard-tile-content">{children}</div>
    </StyledDashboardTile>
  )
}

export default DashboardTile
