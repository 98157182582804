import styled from 'styled-components'

const StyledGalleryView = styled.div`
  .fake-gallery-feed {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 1rem;
  }
`

export default StyledGalleryView
