import styled from 'styled-components'

const StyledInputDialog = styled.form`
  max-width: 375px;
  width: 100vw;
  padding: 28px;

  .dialog-header {
    display: flex;
    align-items: center;

    .dialog-title {
      font-weight: 700;
      font-size: 17px;
      color: ${({ theme }) => theme.colors.gray[700]};
    }
  }

  .dialog-input-area {
    margin-top: 20px;

    input {
      width: 100%;
      height: 50px;
      border-radius: 6px;
      transition: 0.2s;
      padding: 0 14px;
      font-size: 1rem;
      outline: none;
      color: ${({ theme }) => theme.colors.gray[700]};
      border: 1px solid ${({ theme }) => theme.colors.gray[300]};

      &::placeholder {
        color: ${({ theme }) => theme.colors.gray[400]};
      }

      &:focus {
        border: 1px solid ${({ theme }) => theme.colors.blue[700]};
        box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.blue[100]};
      }
    }
  }

  .dialog-actions {
    margin-top: 28px;
    display: flex;

    button {
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: transparent;
      padding: 0 18px;
      transition: 0.2s;
      border-radius: 6px;
      user-select: none;
      font-weight: 600;
      background-color: ${({ theme }) => theme.colors.gray[100]};
      color: ${({ theme }) => theme.colors.gray[600]};

      &:not(:last-child) {
        margin-right: 16px;
        flex-shrink: 0;
      }

      span {
        position: relative;
        top: 1px;
      }

      &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.gray[200]};
      }
    }

    .confirm-btn {
      width: 100%;
      background-color: ${({ theme }) => theme.colors.green[500]};
      color: ${({ theme }) => theme.colors.background};

      span {
        position: relative;
        top: 1px;
      }

      svg {
        height: 18px;
        margin-right: 8px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.green[600]};
      }

      &:disabled {
        background-color: ${({ theme }) => theme.colors.gray[100]};
        color: ${({ theme }) => theme.colors.gray[400]};

        &:hover {
          background-color: ${({ theme }) => theme.colors.gray[100]};
          cursor: not-allowed;
        }
      }
    }
  }
`

export default StyledInputDialog
