import React, { FC, Dispatch, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { FlexGroup } from '../Grid'
import { Tab, TabBar, Button, ActionDialog } from '../System'
import { StyledIssueTabs } from '.'
import { GetIssueBySlug_issueFindOne } from '../../graphql/Issue/__generated__/GetIssueBySlug'
import useSegment from '../utils/useSegment'
import ROUTES from '../utils/routes'
import { userStore } from '../utils/UserContext'

type Props = {
  setCurrTab: Dispatch<React.SetStateAction<string>>;
  currTab: string;
  issue: GetIssueBySlug_issueFindOne;
  missing?: any;
  scrollTo?: (location) => void;
  preview?: boolean;
}

const IssueTabs: FC<Props> = ({
  setCurrTab, currTab, issue, missing, scrollTo, preview
}) => {
  const { track } = useSegment()
  const history = useHistory()
  const { user } = useContext(userStore)
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false)

  const noContent = missing.noExtras && missing.noCaptions && missing.noStories

  const handleActionPlanDownload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (user?.trial) {
      e.preventDefault()
      setOpenUpgradeModal(true)
      return
    }

    track('Downloaded Action Plan PDF')
  }

  return (
    <StyledIssueTabs>
      <TabBar>
        {issue.actionPlanPdf && (
          <Tab
            label="Action Plan"
            onClick={() => setCurrTab('action-plan')}
            isActive={currTab === 'action-plan'}
          />
        )}

        {!noContent && (
          <Tab
            label="Content"
            onClick={() => setCurrTab('content')}
            isActive={currTab === 'content'}
          />
        )}
      </TabBar>
      {!preview && scrollTo && (
        <FlexGroup className="right-bar">
          <FlexGroup className={`tab-list ${currTab !== 'content' && 'hidden'}`}>
            {!missing.noStories && (
              <Button theme="ghost" className="scroll-button" onClick={() => scrollTo('stories')}>
                STORIES
              </Button>
            )}
            {!missing.noCaptions && (
              <Button theme="ghost" className="scroll-button" onClick={() => scrollTo('photos')}>
                PHOTOS
              </Button>
            )}
            {!missing.noCaptions && (
              <Button theme="ghost" className="scroll-button" onClick={() => scrollTo('captions')}>
                CAPTIONS
              </Button>
            )}
            {!missing.noExtras && (
              <Button theme="ghost" className="scroll-button" onClick={() => scrollTo('extras')}>
                EXTRAS
              </Button>
            )}
          </FlexGroup>

          {currTab === 'action-plan' && (
            <Button
              externalURL={user?.trial ? null : issue.actionPlanPdf?.fileHttpLink}
              onClick={handleActionPlanDownload}
              target="_blank"
              theme="ghost"
              type="small"
            >
              Download Action Plan
            </Button>
          )}
        </FlexGroup>
      )}

      <ActionDialog
        type="default"
        isOpen={openUpgradeModal}
        title="Upgrade your account to download an Action Plan"
        confirmButtonText="Upgrade"
        onClose={() => setOpenUpgradeModal(false)}
        onConfirm={() => history.push(ROUTES.user.settings.plan)}
      />
    </StyledIssueTabs>
  )
}

export default IssueTabs
