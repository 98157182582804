import React, { FC } from 'react'
import { StyledProgressBar } from '.'

type Props = {
  progress: number;
  bgColor?: string;
  height?: number;
}

const ProgressBar: FC<Props> = ({ progress, height, bgColor }) => {
  return (
    <StyledProgressBar progress={progress} height={height} bgColor={bgColor}>
      <div className="progress-bar" />
    </StyledProgressBar>
  )
}

export default ProgressBar
