export { default as ToolTip } from './ToolTip'
export { default as StyledToolTip } from './ToolTip.Styled'

export { default as ToolTipWrapper } from './ToolTipWrapper'

export type TooltipType = {
  tooltip: {
    width: number;
    height: number;
    pos: 'top' | 'right' | 'bottom' | 'left';
    offset: number;
    label: string;
  },
  child: {
    width: number;
    height: number;
    left: number;
    top: number;
  },
  isShown?: boolean;
}