import styled from 'styled-components'

const StyledOnboardingView = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;

  z-index: 2147483004;
  height: 100%;
  width: 100%;
  background: #fafafa;
  padding: 0 22px;

  h1 {
    font-size: 40px;
    line-height: 54px;
    color: ${({ theme }) => theme.colors.gray[700]};
    font-weight: 400;

    width: 300px;
    margin: 0 10px;
    margin-top: 114px;
  }

  h3 {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: ${({ theme }) => theme.colors.gray[500]};
    letter-spacing: 0.25px;
  }

  .onboarding-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    font-family: ${({ theme }) => theme.fonts.lexend};
    min-height: 100vh;
  }

  .question-wrap {
    width: 100%;
  }

  .question-header {
    margin-top: 58px;
    width: 100%;
  }

  .subheader {
    margin: 54px 10px 0px 10px;
  }

  .back-button {
    color: ${({ theme }) => theme.colors.gray[500]};
    background: transparent;
    border: 0;
    margin-bottom: 22px;
    padding: 0;
    cursor: pointer;

    svg {
      height: 20px;
    }
  }

  .thank-you {
    color: ${({ theme }) => theme.colors.gray[700]};
    margin-top: 77px;
  }

  .onboarding-button {
    font-weight: 300;
    font-size: 16px;
    width: 100%;
    margin-bottom: 36px;

    svg {
      margin-top: 3px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.s}px) {
    h1 {
      margin-top: 25vh;
    }

    .onboarding-wrap {
      align-items: center;
    }

    .question-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .question-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 10vh;
    }

    .question-header,
    .onboarding-button {
      width: 385px;
    }

    .subheader {
      font-size: 17px;
      color: ${({ theme }) => theme.colors.gray[700]};
      font-family: ${({ theme }) => theme.fonts.nunito};
      letter-spacing: 0;
      line-height: 27px;

      margin-top: 25px;
    }

    .thank-you {
      width: 385px;
    }

    .onboarding-button {
      margin-bottom: 10vh;
    }
  }
`

export default StyledOnboardingView
