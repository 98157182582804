import React from 'react'
import {
  AnnotationIcon,
  PhoneIcon,
  UsersIcon,
  CalendarIcon,
  VideoCameraIcon,
  MicrophoneIcon,
  SparklesIcon
} from '@heroicons/react/outline'

// Returns the daily action's category label & icon

export const getCategoryInfo = (category: string) => {
  switch (category) {
    case 'post':
      return {
        icon: <AnnotationIcon />,
        label: 'Edit Caption'
      }
    case 'stories-no-poll':
    case 'stories-poll':
      return {
        icon: <PhoneIcon />,
        label: 'Engage with Stories'
      }
    case 'comment':
    case 'dms':
    case 'connection-content':
    case 'hashtags':
    case 'miscellaneous':
    case 'supporting-resource':
      return {
        icon: <UsersIcon />,
        label: 'Connect & Engage with Others'
      }
    case 'content-ideas':
      return {
        icon: <CalendarIcon />,
        label: 'Planning Ahead'
      }
    case 'reels':
      return {
        icon: <VideoCameraIcon />,
        label: 'Create & Post Reels'
      }
    case 'live':
      return {
        icon: <MicrophoneIcon />,
        label: 'Go Live'
      }
    default:
      return {
        icon: <SparklesIcon />,
        label: 'Daily Action'
      }
  }
}
