import React, { FC, Suspense, useContext } from 'react'
import { Redirect, Route, useLocation, matchPath } from 'react-router-dom'
import { userStore } from '../utils/UserContext'
import ROUTES from '../utils/routes'
import LazyLoadComponent from './LazyLoadComponent'
import { allowedPages } from '.'
// TODO user role
// TODO redirect page on error

type UserRole = 'admin' | 'basic' | 'course'
type Props = {
  component: any;
  isLazyLoaded?: boolean;
  userRole: UserRole[];
  [x: string]: any;
}

const PrivateRoute: FC<Props> = ({
  component: Component,
  isLazyLoaded,
  userRole,
  ...rest
}: Props) => {
  const { user, loaded } = useContext(userStore)
  const location = useLocation()

  // Returns true if the user can access the page
  const canAccessWithoutSubscription = () => {
    return allowedPages.find((page) => matchPath(location.pathname, page.toString()))
  }

  const userCanAccess = () => {
    if (!user) {
      return false
    }

    if (user.course && !userRole.includes('course') && user.stripeSubscriptionStatus !== 'Active') {
      return false
    }

    return userRole.includes(user.role as UserRole)
  }

  if (!loaded) {
    return <div />
  }

  if (!!location.pathname.includes('/admin') && !userCanAccess()) {
    return <Redirect to={{ pathname: "/admin/login" }} />
  }

  //assuming this wrapper never gets called from a login route
  if (!user) {
    const routeToDashboard = location.pathname === ROUTES.dashboard.home
    return (
      <Redirect
        to={{
          pathname: routeToDashboard ? ROUTES.user.login : ROUTES.errors.notLoggedIn,
          state: { backUrl: location.pathname }
        }}
      />
    )
  }

  if (
    user.role === 'basic' &&
    !user.course &&
    user.stripeSubscriptionStatus !== 'Active' &&
    !canAccessWithoutSubscription()
  ) {
    return (
      <Redirect
        to={{
          pathname: ROUTES.user.settings.billing,
          state: { from: location.pathname }
        }}
      />
    )
  }

  if (user.course && !userCanAccess()) {
    return <Redirect to={{ pathname: ROUTES.dashboard.courses }} />
  }

  const allowAccessRoute = userCanAccess()
  const routeData = allowAccessRoute ? (
    <Route component={Component} {...rest} />
  ) : (
    <Redirect
      to={{
        pathname: ROUTES.errors.fiveHundred,
        state: { from: rest.location }
      }}
    />
  )

  if (isLazyLoaded) {
    return <Suspense fallback={<LazyLoadComponent />}>{routeData}</Suspense>
  }
  return routeData
}

export default PrivateRoute
