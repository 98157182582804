import styled from 'styled-components'

const StyledTestimonials = styled.div`
  font-family: ${({ theme }) => theme.fonts.nunito};
  margin-top: 60px;

  .testimonial-body {
    .testimonial-review {
      font-size: 14px;
      line-height: 21px;
      color: ${({ theme }) => theme.colors.gray[800]};
      opacity: 0.7;
      margin: 15px 0 10px 0;
    }

    .testimonial-user {
      font-weight: 400;
      font-family: ${({ theme }) => theme.fonts.lexend};
      color: ${({ theme }) => theme.colors.green[400]};

      b {
        font-weight: 500;
        color: ${({ theme }) => theme.colors.green[500]};
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    .testimonial-body {
      .testimonial-review {
        font-size: 19px;
        line-height: 26px;
        margin: 30px 0 20px 0;
      }
    }
  }
`

export default StyledTestimonials
