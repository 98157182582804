import firebase from 'firebase/app'
import moment from 'moment'
import { useEffect, useContext } from 'react'
import { isEqual } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../root-reducer'
import {
  PricingState, PricingType, StripePriceType, StripeCouponType, SaleUserType
} from './types'
import { pricingAddAction } from './actions'
import { CartItemsType } from '../../types'
import { appPrices } from './appPrices'
import { userStore } from '../../components/utils/UserContext'
import { ChallengeState } from '../challenge/types'

const usePricing = () => {
  const dispatch = useDispatch()
  const { user, loaded } = useContext(userStore)
  const loadPrices = firebase.functions().httpsCallable('loadPrices')
  const pricing = useSelector<AppState, PricingState>((state) => state.pricing)
  const { userChallenge } = useSelector<AppState, ChallengeState>((state) => state.challenge)

  // Returns the price of the item (in US dollars)
  const getPrice = (id?: string) => {
    const price = pricing.find((price) => price.id === id)

    return price?.price || 0
  }

  // Retuns all associated price information of the item
  const getPriceData = (itemType: CartItemsType, itemId?: string) => {
    const price = pricing.find((price) => price.id === itemId)

    if (!price) {
      return
    }

    return price
  }

  // Returns the item's associated Stripe price ID
  const getPriceId = (id?: string) => {
    const itemPrice = pricing.find((price) => price.id === id)

    if (!itemPrice) {
      return
    }

    const { sale } = itemPrice

    if (!sale) {
      return appPrices.find((price) => price.id == id)?.id
    }

    return sale.id
  }

  // Returns the item's sale price (or the item's price, if no sale)
  const getPriceDiscounted = (id?: string) => {
    const itemPrice = pricing.find((price) => price.id === id)

    if (!itemPrice) {
      return 0
    }

    if (!itemPrice.sale) {
      return itemPrice.price
    }

    return itemPrice.sale.valueAfterDiscount
  }

  const getPricingDataById = (prices: StripePriceType[], id: string) => {
    const price = prices.find((price) => price.id === id)

    if (!price) {
      return 0
    }

    return price.unit_amount / 100
  }

  // Returns a item's sale (if it exists and it is active)
  const getPricingSale = (prices: StripePriceType[], coupons: StripeCouponType[], id: string) => {
    const price = prices.find((price) => price.id === id)

    if (!price) {
      return
    }

    const saleId = price.metadata.sale as string

    if (!saleId) {
      return
    }

    const prodPrice = price.unit_amount
    const salePrice = prices.find((price) => price.id === saleId)
    const saleCoupon = coupons.find((coupon) => coupon.id === saleId)
    const sale = salePrice || saleCoupon

    if (!sale) {
      return
    }

    const saleUnformattedValue = (sale as any).amount_off || prodPrice - (sale as any).unit_amount
    const saleValue = saleUnformattedValue / 100

    const saleObject = {
      id: saleId,
      value: saleValue,
      valueAfterDiscount: Math.max(prodPrice / 100 - saleValue, 0),
      users: getSaleAllowedUsers(sale) as SaleUserType[],
      startDate: getSaleStartDate(sale),
      endDate: getSaleEndDate(sale)
    }
    const saleStatus = checkIfSaleIsActive(saleObject)
    const userCanAccessSale = checkIfUserHasSale(saleObject)

    // If the sale isn't active, don't return the sale
    if (!saleStatus || !userCanAccessSale) {
      return
    }

    return saleObject
  }

  const getSaleStartDate = (sale: StripePriceType | StripeCouponType) => {
    if (!sale.metadata.start_date) {
      return
    }

    const startDateRaw = sale.metadata.start_date as number
    const startDate = moment.unix(startDateRaw)

    return startDate
  }

  const getSaleEndDate = (sale: StripePriceType | StripeCouponType) => {
    if (!sale.metadata.end_date) {
      return
    }

    const endDateRaw = sale.metadata.end_date as number
    const endDate = moment.unix(endDateRaw)

    return endDate
  }

  const getSaleAllowedUsers = (sale: StripePriceType | StripeCouponType) => {
    if (!sale.metadata.users) {
      return []
    }

    const saleAllowedUsers = sale.metadata.users as string
    return saleAllowedUsers.split(',')
  }

  const checkIfUserHasSale = (sale: Partial<PricingType['sale']>) => {
    if (!sale) {
      return
    }

    // Allows admins to list users as Word, Word, Word
    const allowedUsers = sale.users?.map((user) => user.trim())

    if (!user && allowedUsers?.includes('Unregistered')) {
      return true
    }

    if (!user) {
      return false
    }

    const { subscriptionInterval, stripeSubscriptionStatus } = user

    if (stripeSubscriptionStatus === 'Inactive' && allowedUsers?.includes('Inactive')) {
      return true
    }

    if (stripeSubscriptionStatus === 'Inactive') {
      return false
    }

    if (!allowedUsers?.length) {
      return true
    }

    if (user.role === 'admin') {
      return true
    }

    if (!!userChallenge && allowedUsers?.includes('Challenge')) {
      return true
    }

    if (user.trial && allowedUsers.includes('Trial')) {
      return true
    }

    if (!subscriptionInterval || user.trial) {
      return false
    }

    if (allowedUsers.includes(subscriptionInterval)) {
      return true
    }

    if (allowedUsers.includes('Course') && user.course) {
      return true
    }

    return false
  }

  const checkIfSaleIsActive = (sale: Partial<PricingType['sale']>) => {
    if (!sale) {
      return
    }

    if (!sale.endDate && !sale.startDate) {
      return true
    }

    if (sale.startDate && !sale.endDate) {
      return sale.startDate.isSameOrBefore()
    }

    if (sale.endDate && !sale.startDate) {
      return sale.endDate.isSameOrAfter()
    }

    if (sale.startDate && sale.endDate) {
      return sale.startDate.isSameOrBefore() && sale.endDate.isSameOrAfter()
    }
  }

  // Formats & returns the price data we want (detailed in appPrices.ts)
  const screenPriceData = (priceData: PricingType[]) => {
    const formattedData = priceData.filter((price) => {
      return appPrices.find((appPrice) => appPrice.id === price.id)
    })

    return formattedData.map((price) => {
      return {
        ...appPrices.find((appPrice) => appPrice.id === price.id),
        ...price
      }
    })
  }

  const getPricingData = async () => {
    try {
      const pricingDataQuery = await loadPrices()
      const { prices, coupons } = pricingDataQuery.data

      const priceData = prices.map((price: PricingType) => ({
        id: price.id,
        price: getPricingDataById(prices, price.id),
        sale: getPricingSale(prices, coupons, price.id)
      }))

      const formattedData = screenPriceData(priceData)

      // Ensures that we only update the stored prices
      // if they are out of data
      if (!isEqual(pricing, formattedData)) {
        dispatch(pricingAddAction(formattedData))
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (loaded) {
      getPricingData()
    }
  }, [loaded, !!userChallenge])

  return {
    pricing,
    actions: {
      getPrice,
      getPriceData,
      getPriceId,
      getPriceDiscounted,
      checkIfSaleIsActive
    }
  }
}

export default usePricing
