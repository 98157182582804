import firebase from 'firebase'
import { DailyActionType } from '../../types'

// Type for user action in Firebase
export type UserDailyActionType = {
  actionId: string;
  category?: string;
  completedAt: firebase.firestore.Timestamp | null;
  index: number;
  loadedAt: firebase.firestore.Timestamp;
  userId?: string;
  id?: string;
}

// Type for active user action in state
export type ActiveActionType = {
  id?: string;
  description: string;
  category: string;
  caption: string;
  url: string;
  label: string;
  length: string;
  index: number;
  isUrl: boolean;
  isCompleted: boolean;
  loadedAt?: firebase.firestore.Timestamp;
}

export type ActionsState = {
  actionsList: UserDailyActionType[];
  activeAction: ActiveActionType;
  currentIndex: number;
  isCompletedAll: boolean;
}

export const ACTION_SET_ACTION = 'ACTION_SET_ACTION'
export type ActionSetAction = {
  type: typeof ACTION_SET_ACTION;
  payload: DailyActionType;
}

export const ACTION_TOGGLE_COMPLETE = 'ACTION_TOGGLE_COMPLETE'
export type ActionToggleComplete = {
  type: typeof ACTION_TOGGLE_COMPLETE;
}

export const ACTION_CLEAR_ACTION = 'ACTION_CLEAR_ACTION'
export type ActionClearAction = {
  type: typeof ACTION_CLEAR_ACTION;
}

export const ACTION_RESET_INDEX = 'ACTION_RESET_INDEX'
export type ActionResetIndex = {
  type: typeof ACTION_RESET_INDEX;
}

export const ACTIONS_SET_ACTIONS = 'ACTIONS_SET_ACTIONS'
export type ActionsSetActions = {
  type: typeof ACTIONS_SET_ACTIONS;
  payload: Partial<ActionsState>;
}

export const ACTIONS_SET_INDEX = 'ACTIONS_SET_INDEX'
export type ActionsSetCurrentIndex = {
  type: typeof ACTIONS_SET_INDEX;
  payload: number;
}

export const ACTIONS_TOGGLE_COMPLETED = 'ACTIONS_TOGGLE_COMPLETED'
export type ActionsToggleCompleted = {
  type: typeof ACTIONS_TOGGLE_COMPLETED;
  payload: number;
}

export const ACTIONS_SET_COMPLETED_ALL = 'ACTIONS_SET_COMPLETED_ALL'
export type ActionsSetCompletedAll = {
  type: typeof ACTIONS_SET_COMPLETED_ALL;
  payload: boolean;
}

export type ActionsActions =
  | ActionSetAction
  | ActionToggleComplete
  | ActionClearAction
  | ActionResetIndex
  | ActionsSetActions
  | ActionsSetCurrentIndex
  | ActionsToggleCompleted
  | ActionsSetCompletedAll
