import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { useApolloClient } from '@apollo/client'
import { AppState } from '../../../redux/root-reducer'
import { GalleryState } from '../../../redux/gallery/types'
import ROUTES from '../../utils/routes'
import { galleryImageFindOneQuery } from '../../../graphql/GalleryImage/galleryImageFindOne'
import {
  GalleryImageFindOneQuery,
  GalleryImageFindOneQuery_galleryImageFindOne
} from '../../../graphql/GalleryImage/__generated__/GalleryImageFindOneQuery'

const useGalleryPhotoModal = () => {
  const history = useHistory()
  const apollo = useApolloClient()
  const { data: galleryData } = useSelector<AppState, GalleryState>((state) => state.gallery)

  const { entryId: showPhotoId }: any = useParams()
  const [loadingPhoto, setLoadingPhoto] = useState(false)
  const [
    openedPhoto,
    setOpenedPhoto
  ] = useState<GalleryImageFindOneQuery_galleryImageFindOne | null>(null)

  const getImageById = async (entryId: string) => {
    const { data: imageDoc } = await apollo.query<GalleryImageFindOneQuery>({
      query: galleryImageFindOneQuery,
      fetchPolicy: 'no-cache',
      variables: { _id: entryId }
    })

    if (!imageDoc) {
      history.push(ROUTES.errors.notFound)
      throw new Error(`${entryId} image is not found`)
    }
    return imageDoc.galleryImageFindOne
  }

  const loadPhotoData = async (
    photo: GalleryImageFindOneQuery_galleryImageFindOne | null | undefined,
    entryId: string
  ) => {
    try {
      // we may have preloaded state of photo (from react-router state)
      if (!photo?._id) {
        // get all the object
        setLoadingPhoto(true)
        const imageData = await getImageById(entryId)
        setOpenedPhoto(imageData)
        setLoadingPhoto(false)
      }
      else {
        setOpenedPhoto({ ...photo })
      }
    }
    catch (err) {
      console.error(err)
      Sentry.captureException(err)
    }
  }

  const nextPhotoClick = () => {
    for (let i = 0; i < galleryData.length; i++) {
      const current = galleryData[i]
      if (current._id === showPhotoId) {
        if (galleryData[i + 1]?._id) {
          return galleryData[i + 1] // has next
        }
        return galleryData[0]
      }
    }
    return galleryData[0]
  }

  const prevPhotoClick = () => {
    for (let i = 0; i < galleryData.length; i++) {
      const current = galleryData[i]
      if (current._id === showPhotoId) {
        if (galleryData[i - 1]?._id) {
          return galleryData[i - 1] // has prev
        }
        return galleryData[galleryData.length - 1] // go to last
      }
    }
    return galleryData[galleryData.length - 1]
  }

  const cleanUp = () => {
    setOpenedPhoto(null)
  }

  return {
    cleanUp,
    showPhotoId,
    nextPhotoClick,
    prevPhotoClick,
    loadingPhoto,
    loadPhotoData,
    openedPhoto
  }
}

export default useGalleryPhotoModal
export type TypeGalleryPhotoPage = ReturnType<typeof useGalleryPhotoModal>
