import React, { FC, useEffect, useMemo, useState } from 'react'
import { useRouteMatch, useLocation, Link, useHistory } from 'react-router-dom'
import {
  HeartIcon,
  PlusIcon,
  SearchIcon,
  ChevronDownIcon,
  XIcon,
  ChevronLeftIcon,
  PencilIcon
} from '@heroicons/react/outline'
import { xor } from 'lodash'
import ROUTES from '../../utils/routes'
import { LineInput, Bubble, FilterGroup } from '../../System'
import { GALLERY_SORT_BY, GALLERY_CUSTOM_SORT_BY } from '..'
import { StyledGalleryFilters } from '.'
import { EditCollectionModal, NewCollectionModal } from '../../Modals'
import { useGalleryFeed } from '../GalleryFeed'
import {
  CATEGORY_VALUES,
  ORIENTATION_VALUES,
  GALLERY_COLORS,
  STYLE_VALUES
} from '../../Admin/AdminGallery/AdminGalleryConstants'
import { formatQuery } from '../../utils'

type Props = {
  isCollectionsPage?: boolean;
}

const GalleryFilters: FC<Props> = ({ isCollectionsPage }) => {
  const {
    actions: {
      changeColor,
      changeStyle,
      changeSearch,
      changeSortBy,
      changeCategory,
      changeOrientation,
      changeEditMode,
      changeActiveTab
    },
    activeTab,
    search,
    sortBy,
    color,
    style,
    editMode,
    orientation,
    category,
    activeCollection
  } = useGalleryFeed()

  const isIndividualCollection = useRouteMatch(ROUTES.gallery.collectionPage)
  const isCollections = useRouteMatch(ROUTES.gallery.collections) || isIndividualCollection
  const isFavoritesPage = activeTab === 'favorites'
  const hasCustomReorder = isIndividualCollection || isFavoritesPage

  const gallerySortOptions = hasCustomReorder ? GALLERY_CUSTOM_SORT_BY : GALLERY_SORT_BY

  const location = useLocation()
  const history = useHistory()
  const [openEdit, setOpenEdit] = useState(false)
  const [showCreateCollectionModal, setShowCreateCollectionModal] = useState(false)

  const sortByValue = useMemo(() => {
    const hasCustomSortBy = GALLERY_CUSTOM_SORT_BY.some((item) => item.value === sortBy)
    const hasSortBy = GALLERY_SORT_BY.some((item) => item.value === sortBy)
    if (hasCustomReorder && !hasCustomSortBy) {
      // default value for sortBy field when custom reordering is present
      return 'custom'
    }
    if (!hasCustomReorder && !hasSortBy) {
      // default value for sortBy field when custom reordering is absent
      return 'popular'
    }
    return sortBy
  }, [hasCustomReorder, sortBy])

  const getSearchPlaceholder = () => {
    if (activeCollection) {
      return activeCollection.name
    }

    if (isCollections) {
      return 'Collections'
    }

    return 'Gallery'
  }

  useEffect(() => {
    changeSortBy(sortByValue)
  }, [sortByValue])

  useEffect(() => {
    if (editMode) {
      changeEditMode(false)
    }
  }, [location])

  return (
    <>
      <StyledGalleryFilters isCollections={!!isCollections}>
        <div className="page-header-top">
          {isCollections && (
            <Link
              to={isIndividualCollection ? ROUTES.gallery.collections : ROUTES.gallery.main}
              className="page-header-btn icon-btn"
            >
              <ChevronLeftIcon />
            </Link>
          )}
          <LineInput
            icon={<SearchIcon />}
            value={search}
            placeholder={`Search ${getSearchPlaceholder()}`}
            clearInput={() => changeSearch('')}
            onChange={(value) => changeSearch(formatQuery(value))}
            debounce={500}
            className="page-header-search-input"
          />
          {!isCollections && (
            <>
              <label className="page-header-action dropdown">
                <select
                  value={orientation || ''}
                  onChange={(e) => changeOrientation(e.target.value)}
                >
                  <option defaultChecked value="">
                    All Orientations
                  </option>
                  {ORIENTATION_VALUES.map((option) => {
                    return <option value={option.value}>{option.label}</option>
                  })}
                </select>
                {!orientation ? (
                  <ChevronDownIcon />
                ) : (
                  <XIcon className="clear-icon" onClick={() => changeOrientation('')} />
                )}
              </label>
              <label className="page-header-action dropdown">
                <select value={color[0] || ''} onChange={(e) => changeColor([e.target.value])}>
                  <option defaultChecked value="">
                    All Colors
                  </option>
                  {GALLERY_COLORS.map((option) => {
                    return <option value={option.value}>{option.label}</option>
                  })}
                </select>
                {!color.length ? (
                  <ChevronDownIcon />
                ) : (
                  <XIcon className="clear-icon" onClick={() => changeColor([])} />
                )}
              </label>
              <label className="page-header-action dropdown">
                <select value={style[0] || ''} onChange={(e) => changeStyle([e.target.value])}>
                  <option defaultChecked value="">
                    All Styles
                  </option>
                  {STYLE_VALUES.map((option) => {
                    return <option value={option.value}>{option.label}</option>
                  })}
                </select>
                {!style.length ? (
                  <ChevronDownIcon />
                ) : (
                  <XIcon className="clear-icon" onClick={() => changeStyle([])} />
                )}
              </label>
              <label className="page-header-action dropdown">
                <select value={sortByValue || ''} onChange={(e) => changeSortBy(e.target.value)}>
                  {gallerySortOptions.map((option) => {
                    return <option value={option.value}>{option.label}</option>
                  })}
                </select>
                <ChevronDownIcon />
              </label>
            </>
          )}
          {activeCollection && (
            <button className="page-header-btn" onClick={() => setOpenEdit(true)}>
              <PencilIcon /> Edit Title
            </button>
          )}
          {isCollections && (
            <button
              className="page-header-btn new-btn"
              onClick={() => setShowCreateCollectionModal(true)}
            >
              <PlusIcon /> New Collection
            </button>
          )}
        </div>
        {!isCollections && (
          <div className="page-header-categories">
            <Bubble
              isActive={!!isFavoritesPage}
              noMargin
              onClick={() => changeActiveTab(isFavoritesPage ? '' : 'favorites')}
            >
              <HeartIcon />
            </Bubble>
            <FilterGroup
              filters={CATEGORY_VALUES.map((option) => option.value)}
              activeFilters={category}
              onFilter={(filter) => changeCategory(xor(category, [filter]))}
            />
          </div>
        )}
      </StyledGalleryFilters>
      {activeCollection && (
        <EditCollectionModal
          isOpen={openEdit}
          collectionId={activeCollection._id}
          collectionName={activeCollection.name}
          handleClose={() => setOpenEdit(false)}
        />
      )}
      <NewCollectionModal
        isOpen={showCreateCollectionModal}
        handleClose={() => setShowCreateCollectionModal(false)}
      />
    </>
  )
}

export default GalleryFilters
