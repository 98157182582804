import ROUTES from '../utils/routes'

export { default as PrivateRoute } from './PrivateRoute'

export const allowedPages = [
  ...Object.values(ROUTES.user.settings),
  ...Object.values(ROUTES.courses),
  ...Object.values(ROUTES.affiliates),
  ROUTES.checkout,
  ROUTES.receipt,
  ROUTES.dashboard.courses
].filter((page) => typeof page === 'string')
