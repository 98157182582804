import { gql } from '@apollo/client'

export const userDraftFindManyQuery = gql`
  query UserDraftFindMany($filter: FilterFindManyUserDraftInput) {
    userDraftFindMany(filter: $filter) {
      _id
      title
      captionText
      imageUrl
      userUpload
      socialProfiles
      imageId
      fullPath
      template
      challenge
      user
      postStatus
      imageDimensions {
        x
        y
      }
      category
      postDate
      isLiked
      isUsed
      index
      lastModified
      createdAt
    }
  }
`
