import styled from 'styled-components'

const StyledIssueContentList = styled.div`
  margin: 3rem 0;

  h2 {
    font-size: 24px;
    text-transform: capitalize;
    font-weight: 600;
  }

  .content-list-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 59px;
  }

  .instructions-btn {
    padding: 0;
    display: flex;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: none;
    color: #c4c4c4;
    background: none;
    margin-right: -8px;
    cursor: help;
  }

  .instructions-btn i {
    font-size: 21px;
  }

  .content-list-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-gap: 2rem;
  }

  @media (max-width: ${({ theme }) => theme.size.sm}px) {
    .content-list-grid {
      grid-gap: 2rem 1rem;
      grid-template-columns: 1fr 1fr;
    }
  }
`
export default StyledIssueContentList
