import styled from 'styled-components'

const StyledSortableRow = styled.div`
  &.dragging > * {
    box-shadow: 0 0 0 1px #00000012, 0 0 15px 0px #00000017;
    transform: scale(1.02) rotate(0.5deg);
    z-index: ${({ theme }) => theme.elevations.sticky};
  }

  &.dragging {
    .hide-on-hover {
      display: none;
    }

    & > * > *:first-child .drag-icon {
      opacity: 1 !important;
      display: flex !important;
    }
  }
`

export default StyledSortableRow
