import {
  DraftActions,
  DraftImage,
  DRAFT_SET_DRAFT,
  DRAFT_CLEAR_DRAFT,
  DRAFT_SET_TITLE,
  DRAFT_SET_IMAGE,
  DRAFT_CLEAR_IMAGE,
  DRAFT_SET_CAPTION,
  DRAFT_SET_SAVED,
  DRAFT_SET_OPENED,
  DraftCharacterCount,
  DRAFT_SET_CHARACTER_COUNT_TYPE,
  DRAFT_SET_CATEGORY,
  DRAFT_SET_DATE,
  DRAFT_CLEAR_DATE,
  DraftCategoryType,
  DRAFT_SET_SOCIAL_PROFILES,
  DRAFT_SET_IMAGE_DIMENSIONS,
  DRAFT_CLEAR_IMAGE_DIMENSIONS,
  DRAFT_SET_SHOW_SHARE_MODAL,
  DraftShareModalType
} from './types'
import { UserDraftFindById_userDraftFindById } from '../../graphql/UserDraft/__generated__/UserDraftFindById'

export const draftSetDraft = (payload: UserDraftFindById_userDraftFindById): DraftActions => ({
  type: DRAFT_SET_DRAFT,
  payload
})

export const draftClearDraft = (payload: string) => ({
  type: DRAFT_CLEAR_DRAFT,
  payload
})

export const draftSetTitle = (payload: string): DraftActions => ({
  type: DRAFT_SET_TITLE,
  payload
})

export const draftSetImage = (payload: DraftImage): DraftActions => ({
  type: DRAFT_SET_IMAGE,
  payload
})

export const draftClearImage = (): DraftActions => ({ type: DRAFT_CLEAR_IMAGE })

export const draftSetCaption = (payload: string): DraftActions => ({
  type: DRAFT_SET_CAPTION,
  payload
})

export const draftSetCategory = (payload: DraftCategoryType): DraftActions => ({
  type: DRAFT_SET_CATEGORY,
  payload
})

export const draftSetDate = (payload: Date): DraftActions => ({
  type: DRAFT_SET_DATE,
  payload
})

export const draftClearDate = (): DraftActions => ({ type: DRAFT_CLEAR_DATE })

export const draftSetCharacterCountType = (payload: DraftCharacterCount): DraftActions => ({
  type: DRAFT_SET_CHARACTER_COUNT_TYPE,
  payload
})

export const draftSetSaved = (payload: boolean): DraftActions => ({
  type: DRAFT_SET_SAVED,
  payload
})

export const draftSetOpened = (payload: boolean): DraftActions => ({
  type: DRAFT_SET_OPENED,
  payload
})

export const draftSetSocialProfiles = (payload: string[]): DraftActions => ({
  type: DRAFT_SET_SOCIAL_PROFILES,
  payload
})

export const draftSetImageDimensions = (payload: { x: number; y: number }): DraftActions => ({
  type: DRAFT_SET_IMAGE_DIMENSIONS,
  payload
})
export const draftSetShowShareModal = (payload: DraftShareModalType): DraftActions => ({
  type: DRAFT_SET_SHOW_SHARE_MODAL,
  payload
})

export const draftClearImageDimensions = () => ({ type: DRAFT_CLEAR_IMAGE_DIMENSIONS })
