import styled from 'styled-components'

type StyledProps = {
  itemType: 'story' | 'photo' | 'extra';
}

const StyledIssueAbout = styled.div<StyledProps>`
  .issue-modal-grid {
    display: grid;
    grid-template-columns: ${({ itemType }) => (itemType !== 'extra' ? 'auto 430px' : '100%')};
    grid-gap: 2rem;
    margin: 0px 36px 36px 36px;
  }

  .issue-modal-grid-item {
    display: grid;
    grid-gap: 2rem;
    align-items: start;
    align-self: start;
    display: grid;
    overflow-y: auto;
  }

  .issue-modal-grid-item:first-child {
    display: block;
    height: 100%;
  }

  .grid-box {
    border: 1px solid ${({ theme }) => theme.colors.gray[200]};
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
  }

  .grid-box-header {
    padding: 0 30px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 75px;

    button {
      height: 34px;
      display: flex;
      align-items: center;
      border: none;
      background-color: transparent;
      padding: 0;
      transition: 0.2s;
      border-radius: 6px;

      &:hover {
        cursor: pointer;
      }
    }

    .use-btn {
      padding: 0 10px;
      font-weight: 600;
      user-select: none;
      background-color: ${({ theme }) => theme.colors.gray[100]};
      color: ${({ theme }) => theme.colors.gray[600]};

      span {
        position: relative;
        top: 1px;
      }

      svg {
        height: 18px;
        margin-right: 8px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[200]};
      }
    }
  }

  .grid-box-header-title {
    font-weight: 700;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.gray[800]};
  }

  .grid-box-content {
    padding: 30px;
    max-height: 250px;
    box-sizing: border-box;
    overflow-y: auto;
  }

  .grid-box-content * {
    margin: 0;
    line-height: 23px;
    overflow-wrap: break-word;
  }

  .grid-box-content p:not(:last-child) {
    margin-bottom: 1rem;
  }

  .grid-box-content::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray[500]};
    border-radius: 50rem;
    border: 2px solid #ebebeb;
  }

  .grid-box-content::-webkit-scrollbar {
    width: 10px;
    background: #ebebeb;
    border-radius: 50rem;
  }

  .issue-modal-player .close-player-btn {
    display: none;
  }

  .issue-modal-iframe {
    position: relative;
    padding-top: 56.25%;
  }

  .issue-modal-iframe iframe {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border: 0;
    background: #d1d1d1;
  }

  @media (max-width: ${({ theme }) => theme.size.sm}px) {
    .issue-modal-grid {
      grid-template-columns: 100%;
      margin: 0px 20px 20px 20px;
    }

    .grid-box-content {
      max-height: unset;
      padding: 13px;
    }

    .grid-box-header {
      padding: 0 13px;
      height: 50px;
    }
  }
`
export default StyledIssueAbout
