import styled from 'styled-components'
import React, { ReactNode } from 'react'

type JustifyContent = 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'left' | 'right'

type Props = {
  children: ReactNode;
  justifyContent?: JustifyContent;
  [x: string]: any;
}

const Row = ({ children, justifyContent = 'flex-start', ...props }: Props) => (
  <RowStyled justifyContent={justifyContent} {...props}>
    {children}
  </RowStyled>
)

type RowType = {
  justifyContent: JustifyContent;
}
const RowStyled = styled.div<RowType>`
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  // justify-content: ${({ justifyContent }) => 'center'}; // TODO
`

export default Row
