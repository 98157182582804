import styled from 'styled-components'
import React, { FC } from 'react'

type ColumnProps = {
  top?: number;
  className?: string;
  [rest: string]: any;
}

const SpacerStyled = styled.div<ColumnProps>`
  height: ${({ top }) => top}px;
`

const Spacer: FC<ColumnProps> = ({ top = 20, ...rest }: ColumnProps) => (
  <SpacerStyled top={top} {...rest} />
)

export default Spacer
