import React, { ReactNode } from 'react'
import { SortableElement } from 'react-sortable-hoc'
import { StyledSortableRow } from '.'

type Props = {
  children: ReactNode;
}

const SortableRow = SortableElement(({ children }: Props) => {
  return <StyledSortableRow>{children}</StyledSortableRow>
})

export default SortableRow
