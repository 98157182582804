import { useSlate } from 'slate-react'
import React, { useCallback, useContext, useState } from 'react'
import firebase from 'firebase/app'
import { useDropzone } from 'react-dropzone'
import { Button, Icon } from './slate.components'
import { Range, Transforms } from 'slate'
import { isMarkActive, isBlockActive } from './slate.toolbar'

const insertImage = (editor, url) => {
  const text = { text: '' }
  const image = { type: 'image', url, children: [text] }
  Transforms.insertNodes(editor, image)
}

export const ImageUploadButton = () => {
  const editor = useSlate()
  const [loading, setLoading] = useState(false)
  
  const storage = firebase.storage()
  const onDrop = useCallback(async ([fileObj]) => {
    if (!fileObj) return
    setLoading(true)
    const fileName = fileObj.name
    const uploadTask = storage.ref(`/images/${fileName}`).put(fileObj)
    const subscribe = uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED)
    subscribe({
      complete: () => {
        const fileRef = storage.ref('images').child(fileName)
        fileRef.getDownloadURL().then((imgUrl) => {
          insertImage(editor, imgUrl)
          setLoading(false)
        })
      }
    })
  }, [])
  const {
    getRootProps,
    getInputProps,
  } = useDropzone({ onDrop, accept: 'image/*', multiple: false })

  return (
    <Button
      active={loading}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {loading ? 'loading' : <Icon>image</Icon>}
    </Button>
  )
}

const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, { match: n => n.type === 'link' })
}

const wrapLink = (editor, url) => {
  if (isBlockActive(editor, 'link')) {
    unwrapLink(editor)
  }
  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

export const LinkButton = () => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(editor, 'link')}
      onMouseDown={event => {
        event.preventDefault()
        const url = window.prompt('Enter the URL of the link:')
        if (!url) return
        insertLink(editor, url)
      }}
    >
      <Icon>link</Icon>
    </Button>
  )
}
