import React, { FC, createRef } from 'react'
import 'emoji-mart/css/emoji-mart.css'
import { Picker, EmojiData } from 'emoji-mart'
import missingGif from './missing.gif'
import useClickOutsideComponent from '../../utils/useClickOutsideComponent'
import { theme } from '../../utils/theme'
import { StyledEmojiPicker } from '.'

type Props = {
  onPick(emoji: EmojiData & { native?: string }): void;
  onClose(): void;
}

const EmojiPicker: FC<Props> = ({ onPick, onClose }) => {
  const pickerRef = createRef<HTMLDivElement>()

  useClickOutsideComponent(pickerRef, () => onClose())

  const notFound = () => {
    return (
      <div className="emoji-mart-no-results">
        <span aria-label="😢, cry" className="emoji-mart-emoji">
          <img height={75} width={75} src={missingGif} className="emoji-mart-no-result-icon" />
        </span>
        <div className="emoji-mart-no-results-label">Whoops, we can't find that one.</div>
      </div>
    ) as any
  }

  return (
    <StyledEmojiPicker className="emoji-picker" ref={pickerRef}>
      <Picker
        color={theme.colors.blue[400]}
        notFoundEmoji="cry"
        emoji=""
        title=""
        notFound={notFound}
        onClick={(emoji) => onPick(emoji)}
      />
    </StyledEmojiPicker>
  )
}

export default EmojiPicker
