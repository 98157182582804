import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { AppState } from '../../redux/root-reducer'
import { NotificationState, Notification } from '../../redux/notifications/types'
import { notificationAddAction, notificationRemoveAction, notificationRemoveAllAction } from '../../redux/notifications/actions'


const useNotifications = () => {
  const { data, zIndex } = useSelector<AppState, NotificationState>((state) => state.notification)
  const dispatch = useDispatch()
  const intervalDefault = 6000
  const addNotification = ({ message, type, interval = intervalDefault }: Omit<Notification, 'key' | 'expire'>) => {
    dispatch(notificationAddAction({
      type: type || 'default',
      message,
      interval,
      expire: Date.now() + interval,
      key: Math.random().toString(36).substring(7)
    }))
  }

  const removeAll = () => {
    dispatch(notificationRemoveAllAction())
  }

  const removeNotification = (id) => {
    dispatch(notificationRemoveAction(id))
  }

  useEffect(() => {
    const int = setInterval(() => {
      const now = Date.now()
      data.forEach((item) => {
        if (item.expire && now > item.expire) {
          removeNotification(item.key)
        }
      })
    }, 100)
    return () => {
      if (int) clearInterval(int)
    }
  }, [data])

  return {
    data,
    zIndex,
    addNotification,
    removeNotification,
    removeAll,
  }
}

export default useNotifications
