import { useContext } from 'react'
import { userStore } from './UserContext'

const FLAG_NAMES = [
  'adminLeadFormsTab',
  'resetDailyAction',
  'issueDownload',
  'trialOption',
  'onboarding',
  'userUploadPhoto',
  'gtmTag',
  'socialProfiles',
  'stories',
  'showDashboardChallenge',
  'dashboardPhotos',
  'dashboardCaptions',
  'community',
  'tourSystem',
  'newPlatformAccess'
]

const useSplit = () => {
  const { user } = useContext(userStore)

  const CONFIG = {
    core: {
      authorizationKey: process.env.REACT_APP_SPLIT_KEY as string,
      key: user?.id || 'anonymous'
    }
  }

  const attributes: SplitIO.Attributes = {
    uid: user?.id as string,
    role: user?.role as string,
    subscriptionInterval: user?.subscriptionInterval as string,
    email: user?.email as string,
    free: user?.free || false,
    beta: user?.beta || false
  }

  return {
    CONFIG,
    attributes,
    FLAG_NAMES
  }
}

export default useSplit
