import styled from 'styled-components'
import { DraggingDimensionsType } from '../../../types'

type StyledProps = {
  dragging: DraggingDimensionsType | null;
}

const StyledGalleryCollectionItem = styled.div<StyledProps>`
  position: relative;

  .dragging-block {
    position: absolute;
    z-index: 5;
    cursor: pointer;
    top: 0;
    left: 0;
    width: ${({ dragging }) => (dragging ? `${dragging.width}px` : '100%')};
    height: ${({ dragging }) => (dragging ? `${dragging.height}px` : '100%')};
  }

  a {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .collection-preview {
    padding-top: 100%;
    position: relative;
    border-radius: 3px;
    background-color: ${({ theme }) => theme.colors.gray[100]};
    color: ${({ theme }) => theme.colors.gray[300]};
    pointer-events: ${({ dragging }) => (dragging ? 'none' : 'auto')};

    .no-image-icon {
      height: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 3px;
    }

    &::after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      content: '';
      border: 1px solid #0000000f;
      border-radius: 3px;
      pointer-events: none;
    }
  }

  .collection-details {
    margin-top: 1rem;
    display: grid;
    justify-content: center;
    text-align: center;
    grid-gap: 0.5rem;

    .collection-name {
      font-family: ${({ theme }) => theme.fonts.lexend};
      font-weight: 600;
      font-size: 15px;
    }

    .collection-photo-count {
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 0.01rem;
      color: ${({ theme }) => theme.colors.gray[600]};
    }
  }

  &:hover {
    .delete-btn {
      opacity: 1;
    }
  }

  .delete-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 50px;
    border-radius: 0 0 3px 3px;
    background: #0a0d17de;
    opacity: 0;
    z-index: 3;
    transition: 0.2s opacity;
  }
`

export default StyledGalleryCollectionItem
