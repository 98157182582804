import styled from 'styled-components'
import { NotificationType } from '../../redux/notifications/types'

type StyledProps = {
  type: NotificationType;
  zIndex?: number;
  index?: number;
}

const StyledNotification = styled.div<StyledProps>`
  height: 40px;
  background: ${({ theme }) => theme.colors.background};
  box-sizing: border-box;
  box-shadow: 0px 0 0 1px #00000012, 0px 1px 1px #00000036;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0px 7px;
  width: fit-content;

  .notification-symbol {
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 10px;
    border-radius: 3px;
    background: 
      ${({ type }) => type === 'default' && '#F4F4F4'}
      ${({ type }) => type === 'success' && '#E5F6F3'}
      ${({ type }) => type === 'error' && '#FFE2E2'};
    color: 
      ${({ type }) => type === 'default' && '#9CA3AF'}
      ${({ type }) => type === 'success' && '#5CAD9A'}
      ${({ type }) => type === 'error' && '#ED6B6B'};

    svg {
      height: 20px;
    }
  }  

  .notification-message {
    font-weight: 600;
    color: #2F3039;
    font-size: 15px;
  }

  .notification-close-btn {
    margin-left: 1rem;
    background: #FAFAFA;
    border: 0;
    color: #C4C4C4;
  }
`

export default StyledNotification
