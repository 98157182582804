import React, { FC } from 'react'
import { times } from 'lodash'
import { StyledSkeletonLoader } from '.'

type Props = {
  type: 'issue-list-item' | 'caption-list-item' | 'square-list-item' | 'lesson' | 'story-list-item' | 'collection-list-item';
  quantity?: number;
  size?: number;
}

const SkeletonLoader: FC<Props> = ({ type, quantity = 10, size }: Props) => {
  return (
    <>
      {type === 'issue-list-item' &&
        times(quantity, (i) => {
          return (
            <StyledSkeletonLoader key={i} type={type}>
              <div>
                <span />
                <span />
                <span />
              </div>
              <div />
            </StyledSkeletonLoader>
          )
        })}
      {(type === 'caption-list-item' ||
        type === 'story-list-item' ||
        type === 'collection-list-item' ||
        type === 'square-list-item') &&
        times(quantity, (i) => {
          return <StyledSkeletonLoader className="skeleton-item" key={i} type={type} size={size} />
        })}
      {type === 'lesson' && (
        <StyledSkeletonLoader type={type}>
          <div />
          <div>
            <div />
            <div />
          </div>
          <div />
          <div>
            <div />
            <div />
          </div>
        </StyledSkeletonLoader>
      )}
    </>
  )
}

export default SkeletonLoader
