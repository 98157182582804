import React, { FC, useMemo } from 'react'
import { StyledVideoEmbed } from '.'
import PlaceholderBlock from '../Grid/PlaceholderBlock'

type Props = {
  url: string;
  title?: string;
}
const VideoEmbed: FC<Props> = ({ url, title }: Props) => {
  const videoUrlOk = useMemo(
    () =>
      !url || /^(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/.test(url),
    [url]
  )

  const VIDEO_SRC = url || 'https://player.vimeo.com/video/381275049'

  return (
    <StyledVideoEmbed>
      {videoUrlOk ? (
        <iframe src={VIDEO_SRC} title={title ?? 'Video'} frameBorder="0" />
      ) : (
        <PlaceholderBlock ratio={16 / 9} text="Error loading video" />
      )}
    </StyledVideoEmbed>
  )
}

export default VideoEmbed
