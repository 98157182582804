import React, { FC, createRef, useState } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/solid'

import StyledDeleteUserModal from './DeleteUserModal.Styled'
import { Dialog, LineInput } from '../../System'
import { CombinedUser } from '../../User'

type Props = {
  isOpen: boolean;
  user: CombinedUser;
  onClose: () => void;
  onConfirm: () => Promise<void>;
}

const DeleteUserModal: FC<Props> = ({ onConfirm, user, ...rest }) => {
  const dialogRef = createRef<HTMLDivElement>()
  const [email, setEmail] = useState('')
  const [processing, setProcessing] = useState(false)

  const handleConfirm = async () => {
    setProcessing(true)
    await onConfirm()
    setProcessing(false)
  }

  return (
    <Dialog ref={dialogRef} {...rest}>
      <StyledDeleteUserModal>
        <div className="dialog-header">
          <div className="dialog-message-icon">
            <ExclamationCircleIcon />
          </div>
          <span className="dialog-title">Delete {user.displayName}?</span>
        </div>
        <div className="dialog-message-container">
          <span className="dialog-body">
            <p>
              Are you sure you want to delete this user? This is a permanent action, the user's data
              will not be recoverable.
            </p>
            <p>To continue, please confirm the user's email:</p>
            <LineInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="user@deleted.com"
            />
          </span>
        </div>
        <div className="dialog-actions">
          <button className="cancel-btn" onClick={() => (dialogRef.current as any)?.close()}>
            <span>HELL NO</span>
          </button>
          <button
            className="confirm-btn"
            disabled={email !== user.email || processing}
            onClick={() => handleConfirm()}
          >
            <span>{processing ? 'DESTROYING...' : 'ANNIHILATE'}</span>
          </button>
        </div>
      </StyledDeleteUserModal>
    </Dialog>
  )
}

export default DeleteUserModal
