import React, { FC } from 'react'
import { PlayIcon } from '@heroicons/react/outline'
import { FlexGroup } from '../Grid'
import { Button } from '../System'
import { StyledIssueHighlight } from '.'

type Props = {
  id: string;
  title: string;
  video: string;
  isActive: boolean;
  switchVideo: (videoUrl: string) => void;
}

const IssueHighlight: FC<Props> = ({
  title, video, isActive, switchVideo
}) => {
  return (
    <StyledIssueHighlight isActive={isActive}>
      <span>{title}</span>
      <FlexGroup>
        <Button onClick={() => switchVideo(video)} theme="ghost" isIcon>
          <PlayIcon />
        </Button>
      </FlexGroup>
    </StyledIssueHighlight>
  )
}

export default IssueHighlight
