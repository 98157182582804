import React, { FC, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { SearchIcon, PlusIcon } from '@heroicons/react/outline'
import { useApolloClient } from '@apollo/client'
import { LineInput, Spinner, Dialog } from '../../System'
import { StyledAddToCollectionModal, CollectionListItem } from '.'
import { NewCollectionModal } from '../NewCollectionModal'
import {
  SearchGalleryCollections,
  SearchGalleryCollections_searchGalleryCollections_docs
} from '../../../graphql/GalleryCollection/__generated__/SearchGalleryCollections'
import { GalleryFeedPhotoType } from '../../../types'
import { searchGalleryCollectionsQuery } from '../../../graphql/GalleryCollection/searchGalleryCollections'
import { galleryCollectionImageBulkWriteMutation } from '../../../graphql/GalleryCollectionsImage/galleryCollectionImageBulkWrite'
import { useNotifications } from '../../Notifications'
import { parseImageData } from '../../../redux/gallery/trackingData'
import useSegment from '../../utils/useSegment'
import { useGalleryFeed } from '../../GalleryView/GalleryFeed'

type Props = {
  isOpen: boolean;
  photo: GalleryFeedPhotoType;
  handleClose: Function;
}

const AddToCollectionModal: FC<Props> = ({ isOpen, photo, handleClose }) => {
  const apollo = useApolloClient()
  const {
    activeCollection,
    activeTab,
    sortBy,
    style,
    color,
    category,
    orientation,
    search: feedSearch
  } = useGalleryFeed()
  const { track } = useSegment()
  const { addNotification } = useNotifications()

  const [selectedCollections, setSelectedCollections] = useState<{ [x: string]: boolean }>({})
  const [isProcessing, setIsProcessing] = useState(false) // for when submitting
  const [showNewCollectionModal, setShowNewCollectionModal] = useState(false)

  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [totalDocs, setTotalDocs] = useState(0)
  const [collections, setCollections] = useState<
    SearchGalleryCollections_searchGalleryCollections_docs[]
  >([])

  // bulk update the collections by adding or removing images
  const handleWrite = async () => {
    setIsProcessing(true)

    try {
      const data: any[] = []

      for (const collectionId in selectedCollections) {
        const doCreate = selectedCollections[collectionId]
        data.push({
          method: doCreate ? 'CREATE' : 'DELETE',
          fields: {
            image: photo._id,
            collectionId,
            index: collections.find((col) => col._id === collectionId)?.countImages || 0
          }
        })

        if (doCreate) {
          track('Added Image to Collection', {
            added_from: activeCollection ? 'collection' : activeTab,
            original_photo_url: `${window.location.host}/gallery/${photo._id}`,
            sorted_by: sortBy,
            style_filter: style,
            color_filter: color,
            category_filter: category,
            orientation_filter: orientation,
            search: feedSearch,
            ...parseImageData(photo._id, [photo])
          })
        }
        else {
          track('Removed Image from Collection', {
            removed_from: activeCollection ? 'collection' : activeTab,
            original_photo_url: `${window.location.host}/gallery/${photo._id}`,
            ...parseImageData(photo._id, [photo])
          })
        }
      }

      await apollo.mutate({
        mutation: galleryCollectionImageBulkWriteMutation,
        fetchPolicy: 'no-cache',
        variables: { data }
      })

      handleClose()
    }
    catch (err) {
      console.error(err)
      addNotification({
        message: 'There was an issue saving this image to your collection.',
        type: 'error',
        interval: 4000
      })
    }

    setIsProcessing(false)
  }

  const getCollections = async () => {
    setLoading(true)
    const { data } = await apollo.query<SearchGalleryCollections>({
      query: searchGalleryCollectionsQuery,
      fetchPolicy: 'no-cache',
      variables: {
        page,
        items: 20,
        search,
        containsImageId: photo._id
      }
    })

    const newDocs = data.searchGalleryCollections?.docs || []
    const appendedCollections = [...collections, ...newDocs]

    setCollections(page === 0 ? newDocs : appendedCollections)
    setTotalDocs(data.searchGalleryCollections?.totalDocs || 0)
    setLoading(false)
  }

  const toggleCollection = (collectionId: string, isSelected: boolean) => {
    setSelectedCollections({
      ...selectedCollections,
      [collectionId]: isSelected
    })
  }

  const determineIfSelected = (
    collection: SearchGalleryCollections_searchGalleryCollections_docs
  ) => {
    const hasBeenSelected = selectedCollections[collection._id] || !!collection.containsGivenImage
    const hasNotBeenDeselected = selectedCollections[collection._id] !== false

    return hasBeenSelected && hasNotBeenDeselected
  }

  useEffect(() => {
    setSelectedCollections({})

    if (!isOpen) {
      return
    }

    getCollections()
  }, [isOpen, page, search, showNewCollectionModal])

  useEffect(() => {
    setSearch('')
  }, [isOpen])

  return (
    <>
      <NewCollectionModal
        isOpen={showNewCollectionModal}
        dontRedirect
        handleClose={(newId) => {
          if (newId) {
            toggleCollection(newId, true)
          }

          setSearch('')
          setPage(0)
          setShowNewCollectionModal(false)
        }}
      />
      {!showNewCollectionModal && (
        <Dialog isOpen={isOpen} onClose={() => handleClose()}>
          <StyledAddToCollectionModal>
            <div className="image-preview">
              <img src={photo?.file?.fileHttpLink || ''} />
            </div>

            <div className="input-container">
              <LineInput
                icon={<SearchIcon />}
                value={search}
                placeholder="Search for collections"
                onChange={(e) => {
                  setSearch(e)
                  setPage(0)
                }}
                debounce={300}
              />
              <button className="new-btn" onClick={() => setShowNewCollectionModal(true)}>
                <PlusIcon />
                <span>New</span>
              </button>
            </div>

            <div className="collections-list">
              {!collections.length && !loading && (
                <span className="empty-state">No Collections Found</span>
              )}

              <InfiniteScroll
                scrollThreshold={0.7}
                dataLength={collections.length}
                next={() => setPage(page + 1)}
                hasMore={totalDocs > collections.length}
                loader={<Spinner isBlock />}
                scrollableTarget="modal-content"
              >
                {collections.map((collection) => (
                  <CollectionListItem
                    isSelected={determineIfSelected(collection)}
                    toggleCollectionFn={toggleCollection}
                    entryId={photo?._id || ''}
                    collection={collection}
                    key={collection._id}
                  />
                ))}
              </InfiniteScroll>

              {loading && collections.length > 0 && <Spinner isBlock />}
            </div>
            <div className="collection-actions">
              <button onClick={() => handleClose()}>
                <span>Cancel</span>
              </button>
              <button
                disabled={isProcessing || !Object.keys(selectedCollections).length}
                className="confirm-btn"
                onClick={() => handleWrite()}
              >
                <span>Add Photo</span>
              </button>
            </div>
          </StyledAddToCollectionModal>
        </Dialog>
      )}
    </>
  )
}

export default AddToCollectionModal
