import { SearchCaptions_captionSetSearch_docs } from '../../graphql/CaptionSet/__generated__/SearchCaptions'

export type CaptionLibraryState = {
  captions: SearchCaptions_captionSetSearch_docs[];
  loading: boolean;
  search: string;
  categories: string[];
  sort: string;
  onlyFavorites: boolean;
  hideUsed: boolean;
  page: number;
}

export const CAPTIONS_SEARCH_CHANGE = 'CAPTIONS_SEARCH_CHANGE'
export type CaptionsSearchChangeAction = {
  type: typeof CAPTIONS_SEARCH_CHANGE;
  payload: string;
}

export const CAPTIONS_CATEGORY_CHANGE = 'CAPTIONS_CATEGORY_CHANGE'
export type CaptionsCategoryChangeAction = {
  type: typeof CAPTIONS_CATEGORY_CHANGE;
  payload: string;
}

export const CAPTIONS_SORT_CHANGE = 'CAPTIONS_SORT_CHANGE'
export type CaptionsSortChangeAction = {
  type: typeof CAPTIONS_SORT_CHANGE;
  payload: string;
}

export const CAPTIONS_FAVORITE_CHANGE = 'CAPTIONS_FAVORITE_CHANGE'
export type CaptionsFavoriteChange = {
  type: typeof CAPTIONS_FAVORITE_CHANGE;
  payload: boolean;
}

export const CAPTIONS_USED_CHANGE = 'CAPTIONS_USED_CHANGE'
export type CaptionsUsedChanged = {
  type: typeof CAPTIONS_USED_CHANGE;
  payload: boolean;
}

export const CAPTIONS_PAGE_CHANGE = 'CAPTIONS_PAGE_CHANGE'
export type CaptionsPageChanged = {
  type: typeof CAPTIONS_PAGE_CHANGE;
  payload: number;
}

export const SET_CAPTIONS = 'SET_CAPTIONS'
export type SetCaptions = {
  type: typeof SET_CAPTIONS;
  payload: SearchCaptions_captionSetSearch_docs[];
}

export const CAPTIONS_LOAD_SEARCH = 'CAPTIONS_LOAD_SEARCH'
export type CaptionsLoadSearch = {
  type: typeof CAPTIONS_LOAD_SEARCH;
  payload: any;
}

export type CaptionActions =
  | CaptionsSearchChangeAction
  | CaptionsCategoryChangeAction
  | CaptionsSortChangeAction
  | CaptionsFavoriteChange
  | CaptionsUsedChanged
  | CaptionsPageChanged
  | SetCaptions
  | CaptionsLoadSearch
