import lodash from 'lodash'
import {
  DraftsState,
  DraftsActions,
  DRAFTS_ADD_DRAFTS,
  DRAFTS_SET_DRAFTS,
  DRAFTS_DELETE_DRAFT,
  DRAFTS_HIDE_USED,
  DRAFTS_ONLY_LIKED,
  DRAFTS_SET_PAGE,
  DRAFTS_SET_SEARCH,
  DRAFTS_SET_SORT,
  DRAFTS_SET_CATEGORIES,
  DRAFTS_CLEAR_CATEGORIES,
  DRAFTS_LOAD_SEARCH
} from './types'

const initialState: DraftsState = {
  drafts: [],
  search: '',
  categories: [],
  onlyLiked: false,
  hideUsed: false,
  sort: 'custom',
  page: 0,
  loading: false
}

export const draftsReducer = (state = initialState, action: DraftsActions): DraftsState => {
  switch (action.type) {
    case DRAFTS_SET_DRAFTS:
      return { ...state, drafts: [...action.payload], loading: false }
    case DRAFTS_ADD_DRAFTS:
      return {
        ...state,
        drafts: [...action.payload, ...state.drafts],
        loading: false
      }
    case DRAFTS_DELETE_DRAFT:
      return {
        ...state,
        drafts: state.drafts.filter((draft) => draft._id !== action.payload)
      }
    case DRAFTS_HIDE_USED:
      return {
        ...state,
        drafts: [],
        loading: true,
        page: 0,
        hideUsed: action.payload
      }
    case DRAFTS_ONLY_LIKED:
      return {
        ...state,
        drafts: [],
        loading: true,
        page: 0,
        onlyLiked: action.payload
      }
    case DRAFTS_SET_PAGE:
      return {
        ...state,
        page: action.payload,
        loading: true
      }
    case DRAFTS_SET_SEARCH:
      return {
        ...state,
        drafts: [],
        loading: true,
        page: 0,
        search: action.payload
      }
    case DRAFTS_SET_SORT:
      return {
        ...state,
        drafts: [],
        loading: true,
        page: 0,
        sort: action.payload
      }
    case DRAFTS_SET_CATEGORIES:
      return {
        ...state,
        drafts: [],
        loading: true,
        page: 0,
        categories: lodash.xor(state.categories, [action.payload])
      }
    case DRAFTS_CLEAR_CATEGORIES:
      return {
        ...state,
        drafts: [],
        loading: true,
        page: 0,
        categories: []
      }
    case DRAFTS_LOAD_SEARCH:
      return {
        drafts: [],
        loading: true,
        page: 0,
        ...action.payload
      }
    default:
      return state
  }
}
