import { gql } from '@apollo/client'

export const getIssueBySlugQuery = gql`
  query GetIssueBySlug($slug: String!) {
    issueFindOne(filter: { slug: $slug }) {
      _id
      title
      number
      about
      status
      headerImg {
        fileHttpLink
      }
      actionPlanAudio {
        fileHttpLink
      }
      actionPlanCover {
        fileHttpLink
      }
      actionPlanPdf {
        fileHttpLink
      }
      actionPlanVideo
      tags
      slug
      type
      facebookText
      date
      annualOnly
    }
  }
`
