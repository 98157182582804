import styled from 'styled-components'

const StyledAdminPageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[300]};
  height: 80px;
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.elevations.sticky};
  background: ${({ theme }) => theme.colors.background};

  .page-info {
    display: grid;
    grid-gap: 7px;

    h1 {
      font-size: 20px;
      font-weight: 800;
      margin: 0;
    }
  }

  .page-actions {
    display: flex;
    align-items: center;
    grid-gap: 1rem;

    a {
      font-weight: 600;
      font-size: 16px;
      color: ${({ theme }) => theme.colors.gray[600]};
      cursor: pointer;
    }
  }
`

export default StyledAdminPageHeader
