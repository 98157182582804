import * as Sentry from '@sentry/react'
import { SearchClient } from 'algoliasearch/lite'
import { isProduction } from '../utils/firebase'

export { algoliaStore, AlgoliaProvider } from './AlgoliaContext'

export const ALGOLIA_PREFIX = isProduction ? '4thofjuly' : 'stage'

export const allIndicesForUsers = ['dailyActions', 'events']

export const algoliaInitialState = {
  /**
   * General. Access to 'galleryImages';
   * This key is used to access tables without special filters
   */
  general: null,
  /**
   * Access to 'issues';
   * for basic users restricts issues within user_issues table
   */
  issues: null,
  /**
   * Access to 'users'.
   * Only for admins
   */
  users: null,
  /**
   * Access to 'galleryImagesLikes', 'galleryCollections', 'galleryCollectionsImages'
   * restricts data with {user: userId} filter
   */
  gallery: null
}

type AlgoliaStoreKeys = {
  [P in keyof typeof algoliaInitialState]: string | null
}
export type AlgoliaStoreState = {
  keys: AlgoliaStoreKeys;
  validUntil: number | null;
  isValid: boolean;
}
export type AlgoliaKeys = keyof AlgoliaStoreKeys
export type AlgoliaInstances = {
  [P in AlgoliaKeys]: SearchClient | null
}

export type ContextInitialState = {
  isAlgoliaReady: boolean;
  getAlgoliaInstance: (arg: AlgoliaKeys) => SearchClient;
  cleanUp: any;
  forceUpdate: any;
}

export const ALGOLIA_CHECK_KEYS_INTERVAL = 1000 * 30
// check if need to update keys every 30 seconds [in ms]
export const ALGOLIA_NEEDS_UPDATE = 60 * 10
// update keys if they expire in less than 10 minutes [in seconds]

export const algoliaEmptyValue = {
  keys: { ...algoliaInitialState },
  isValid: false,
  validUntil: null
}

export const LS_KEY_ALGOLIA = 'algolia_keys'
export const getFromLocalStorage = (key: string) => {
  try {
    const data = localStorage.getItem(key)
    if (data) {
      return JSON.parse(data)
    }
    return null
  }
  catch (err) {
    Sentry.captureException(err)
    console.error(err)
  }
}

export const saveToLocalStorage = (key: string, value: any) => {
  try {
    if (typeof value === 'string') {
      localStorage.setItem(key, value)
    }
    else {
      const asStr = JSON.stringify(value)
      localStorage.setItem(key, asStr)
    }
  }
  catch (err) {
    Sentry.captureException(err)
    console.error(err)
  }
}
