import { gql } from '@apollo/client'

export const getIssueByIdQuery = gql`
  query GetIssueById($id: String!) {
    issueFindById(_id: $id) {
      _id
      title
      number
      about
      listDescription
      headerImg {
        fileHttpLink
        fullPath
      }
      actionPlanVideo
      actionPlanAudio {
        fileHttpLink
        fullPath
      }
      actionPlanCover {
        fileHttpLink
        fullPath
      }
      actionPlanPdf {
        fileHttpLink
        fullPath
      }
      actionPlanVideo
      tags
      status
      slug
      type
      facebookText
      date
      dateLastUpdate
      hidden
      category
      platform
      annualOnly
      legacy {
        wordpressId
        link
      }
    }
  }
`
