import React, { FC, useEffect } from 'react'
import { IssueModalGallery, StyledIssueModalItem } from '.'
import { StorySetFindMany_storySetFindMany } from '../../../graphql/StorySet/__generated__/StorySetFindMany'
import RenderSlate from '../../Slate/RenderSlate'
import useSegment from '../../utils/useSegment'
import { GetIssueBySlug_issueFindOne } from '../../../graphql/Issue/__generated__/GetIssueBySlug'

type Props = {
  story: StorySetFindMany_storySetFindMany;
  issue: GetIssueBySlug_issueFindOne;
  setFullScreen: (isFullScreen: boolean) => void;
  isFullScreen: boolean;
}

const IssueModalPhoto: FC<Props> = ({
  issue, story, setFullScreen, isFullScreen
}) => {
  const { track } = useSegment()

  useEffect(() => {
    track('Viewed Story Set', {
      id: story._id,
      title: story.title,
      index: story.index,
      issue_id: issue._id,
      issue_title: issue.title,
      from: 'issue'
    })
  }, [])

  return (
    <StyledIssueModalItem itemType="story">
      <div className="issue-modal-grid">
        <div className="issue-modal-grid-item">
          <IssueModalGallery
            setFullScreen={setFullScreen}
            isFullScreen={isFullScreen}
            modalType="story"
            data={story}
            issue={issue}
            canvaLink={story?.canvaLink}
          />
        </div>
        <div className="issue-modal-grid-item">
          <div className="grid-box">
            <div className="grid-box-header">
              <span className="grid-box-header-title">Description</span>
            </div>
            <div className="grid-box-content">
              <RenderSlate html={story.description} />
            </div>
          </div>
        </div>
      </div>
    </StyledIssueModalItem>
  )
}

export default IssueModalPhoto
