import React, {
  FC, createRef, useRef, useEffect, cloneElement, useContext
} from 'react'
import { tooltipStore } from './TooltipContext'

type Props = {
  label: string;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  children: JSX.Element;
}

const ToolTipWrapper: FC<Props> = ({ label, placement = 'top', children }) => {
  const { tooltip, setTooltip, hideTooltip } = useContext(tooltipStore)

  const containerRef = useRef(createRef<HTMLDivElement>())
  const childrenDetails = useRef({
    width: 0, height: 0, left: 0, top: 0
  })

  const triggerTooltip = () => {
    if (!containerRef.current.current) {
      return
    }

    const child = containerRef.current.current

    // Passes the relevant information to the tooltip provider
    setTooltip({
      tooltip: {
        ...tooltip,
        label,
        pos: placement
      },
      child: {
        width: childrenDetails.current.width,
        height: childrenDetails.current.height,
        left: child.getBoundingClientRect().x + window.scrollX,
        top: child.getBoundingClientRect().y + window.scrollY
      }
    })
  }

  // Retrieve the details of the child element we
  // need to calculate the position of the tooltip
  useEffect(() => {
    if (!containerRef.current.current) {
      return
    }

    const child = containerRef.current.current.parentElement!

    childrenDetails.current = {
      width: child.getBoundingClientRect().width,
      height: child.getBoundingClientRect().height,
      left: child.getBoundingClientRect().x + window.scrollX,
      top: child.getBoundingClientRect().y + window.scrollY
    }

    // Add mouse enter and leave event listeners for
    // tooltip handling
    window.addEventListener('scroll', hideTooltip)
    child.addEventListener('mouseenter', triggerTooltip)
    child.addEventListener('mouseleave', hideTooltip)

    return () => {
      child.removeEventListener('mouseenter', triggerTooltip)
      child.removeEventListener('mouseleave', hideTooltip)
      window.removeEventListener('scroll', hideTooltip)
    }
  }, [containerRef.current.current])

  // Clones the element within the wrapper, adding the 'has-tooltip' class
  // and appending a 'tooltip-wrapper' child for sizing reference
  return cloneElement(
    children,
    { ...children.props, className: `${children.props.className || ''} has-tooltip` },
    children.props.children,
    <div className="tooltip-wrapper" ref={containerRef.current} />
  )
}

export default ToolTipWrapper
