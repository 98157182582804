import React, { FC, useMemo } from 'react'
import { StyledAvatar } from '.'

type Props = {
  name?: string;
  imageUrl?: string;
  type?: 'light' | 'dark' | 'gray' | 'fade' | 'brand';
  size?: 'large' | 'small';
}

const Avatar: FC<Props> = ({
  name, imageUrl, type = 'fade', size = 'small'
}) => {
  const initials = useMemo(
    () =>
      (name || '')
        .split(' ')
        .map((item) => item.substr(0, 1).toUpperCase())
        .slice(0, 2)
        .join('') || '',
    [name]
  )

  return (
    <StyledAvatar className="avatar" type={type} size={size}>
      {!imageUrl && initials}
      {imageUrl && <img src={imageUrl} alt={name} />}
    </StyledAvatar>
  )
}

export default Avatar
