import styled from 'styled-components'

const StyledExpiredCTAModal = styled.div`
  .main-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3rem 2rem;
  }

  .modal-header-main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .modal-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.5px;
    font-family: ${({ theme }) => theme.fonts.lexend};
    color: ${({ theme }) => theme.colors.gray[700]};
    text-transform: uppercase;
  }

  .modal-content {
    overflow-y: auto;
    max-height: 100%;
    box-sizing: border-box;
    height: fit-content;
    margin: 35px 0;
  }

  .modal-text {
    font-weight: 300;
    line-height: 24px;
    font-family: ${({ theme }) => theme.fonts.nunito};
    font-size: 17px;
    color: ${({ theme }) => theme.colors.gray[700]};
  }

  .modal-list {
    margin-top: 30px;

    h4 {
      font-family: ${({ theme }) => theme.fonts.nunito};
      font-weight: 500;
      color: ${({ theme }) => theme.colors.gray[500]};
      font-size: 16px;
    }

    ul {
      padding-left: 0;
      list-style: none;
      margin-top: 10px;
    }

    svg {
      width: 20px;
      height: 20px;
      flex: none;
      margin-right: 10px;
    }

    li {
      font-family: ${({ theme }) => theme.fonts.lexend};
      font-weight: 400;
      color: ${({ theme }) => theme.colors.gray[700]};
      font-size: 20px;
      padding-bottom: 10px;

      display: flex;
      align-items: flex-start;
    }

    li.features {
      font-family: ${({ theme }) => theme.fonts.nunito};
      font-size: 15px;
      line-height: 22px;
    }
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .btn {
      &:not(:first-child) {
        margin-left: 1rem;
      }
    }
  }
`

export default StyledExpiredCTAModal
