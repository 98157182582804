import styled from 'styled-components'

const StyledAdminUserPage = styled.div`
  width: 100%;
  .btn-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  .justify-right {
    margin: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`

export const StyledBodyForm = styled.div`
  margin-top: 20px;
  background: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 40px;
  .header {
    width: 100%;
    margin: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};
    background: ${({ theme }) => theme.colors.background};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .content {
    margin: 20px 20px 0;
  }
`

export const StyledDangerLink = styled.div`
  color: ${({ theme }) => theme.colors.red[700]};
  font-weight: 500;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export default StyledAdminUserPage
