import React, { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { SwitchHorizontalIcon } from '@heroicons/react/outline'
import { GalleryFeedPhotoType } from '../../types'
import { StyledGalleryPhotoItem, GalleryPhotoItemActions } from '.'
import { Button } from '../System'

type Props = {
  key: any;
  isDragging: boolean;
  isEditMode?: boolean;
  photo: GalleryFeedPhotoType;
  left: number;
  top: number;
  [rest: string]: any;
}

const GalleryPhotoItem: FC<Props> = ({
  left, top, photo, key, isEditMode = false
}) => {
  const { pathname: currentPath } = useLocation()

  return (
    <StyledGalleryPhotoItem
      top={top}
      left={left}
      key={key}
      width={photo.width}
      height={photo.height}
      className="gallery-photo"
      data-tour-id="photo"
    >
      <Link
        className="photo-item-link"
        to={{
          pathname: `${currentPath}/${photo._id}`,
          state: { photo }
        }}
      />
      <img
        src={photo.src}
        srcSet={photo.srcset}
        sizes={photo.sizes}
        alt="Gallery item"
        loading="lazy"
      />
      <GalleryPhotoItemActions photo={photo} isEditMode={isEditMode} />
    </StyledGalleryPhotoItem>
  )
}

export default GalleryPhotoItem
