import {
  DraftsActions,
  DRAFTS_ADD_DRAFTS,
  DRAFTS_SET_DRAFTS,
  DRAFTS_DELETE_DRAFT,
  DRAFTS_HIDE_USED,
  DRAFTS_ONLY_LIKED,
  DRAFTS_SET_PAGE,
  DRAFTS_SET_SEARCH,
  DRAFTS_SET_SORT,
  DRAFTS_SET_CATEGORIES,
  DRAFTS_CLEAR_CATEGORIES,
  DRAFTS_LOAD_SEARCH,
  DraftSort
} from './types'
import { SearchUserDrafts_searchUserDrafts_docs } from '../../graphql/UserDraft/__generated__/SearchUserDrafts'
import { CAPTION_CATEGORY_LIST } from '../../components/IssueView'

export const draftsSetDrafts = (
  payload: SearchUserDrafts_searchUserDrafts_docs[]
): DraftsActions => ({
  type: DRAFTS_SET_DRAFTS,
  payload
})

export const draftsAddDrafts = (
  payload: SearchUserDrafts_searchUserDrafts_docs[]
): DraftsActions => ({
  type: DRAFTS_ADD_DRAFTS,
  payload
})

export const draftsDeleteDraft = (payload: string): DraftsActions => ({
  type: DRAFTS_DELETE_DRAFT,
  payload
})

export const draftsHideUsedDraft = (payload: boolean): DraftsActions => ({
  type: DRAFTS_HIDE_USED,
  payload
})

export const draftsOnlyLiked = (payload: boolean): DraftsActions => ({
  type: DRAFTS_ONLY_LIKED,
  payload
})

export const draftsSetPageDraft = (payload: number): DraftsActions => ({
  type: DRAFTS_SET_PAGE,
  payload
})

export const draftsSetSearchDraft = (payload: string): DraftsActions => ({
  type: DRAFTS_SET_SEARCH,
  payload
})

export const draftsSetSortDraft = (payload: DraftSort): DraftsActions => ({
  type: DRAFTS_SET_SORT,
  payload
})

export const draftsSetCategory = (
  payload: typeof CAPTION_CATEGORY_LIST[number]
): DraftsActions => ({
  type: DRAFTS_SET_CATEGORIES,
  payload
})

export const draftsClearCategories = (): DraftsActions => ({ type: DRAFTS_CLEAR_CATEGORIES })

export const draftsLoadSearch = (payload: any): DraftsActions => ({
  type: DRAFTS_LOAD_SEARCH,
  payload
})
