import styled from 'styled-components'
import React from 'react'


type Props = {
  text: string;
  ratio: number;
}

const PlaceholderBlock = ({ text, ratio }: Props) => (
  <StyledPlaceholder ratio={ratio}>
    <div>{text}</div>
  </StyledPlaceholder>
)

const StyledPlaceholder = styled.div<{
  ratio: number;
}>`
  width: 100%;
  padding-bottom: ${({ ratio }) => `${(1 / ratio) * 100}%`};
  position: relative;
  background: rgba(0, 0, 0, 0.13);
  div {
    position: absolute;
    top: 45%; bottom: 0; left: 0; right: 0;
    color: rgba(255, 255, 255, 0.75);
    font-size: 13px;
    text-align: center;
  }
`

export default PlaceholderBlock
