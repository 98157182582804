import {
  DAILY_ACTION_FORM_UPDATE_FIELD,
  DAILY_ACTION_FORM_UPDATE_STATE,
  DailyActionFormActions,
  DailyActionFormState,
  DAILY_ACTION_FORM_START_LOADING,
  DAILY_ACTION_FORM_SUCCESS,
  DAILY_ACTION_FORM_ERROR
} from './types'
import { DailyActionType } from '../../types'

const initialState: DailyActionFormState = {
  form: {} as DailyActionType,
  loading: true,
  success: false,
  error: false,
  errorMessage: null,
  successMessage: null
}

export const dailyActionFormReducer = (
  state = initialState,
  action: DailyActionFormActions
): DailyActionFormState => {
  switch (action.type) {
    case DAILY_ACTION_FORM_UPDATE_FIELD:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.field]: action.payload.value,
          id: action.meta.actionId!
        }
      }
    case DAILY_ACTION_FORM_UPDATE_STATE:
      if (state.form.id && action.payload.id && state.form.id !== action.payload.id) {
        return {
          ...state,
          loading: false,
          form: {
            ...initialState.form,
            ...action.payload
          }
        }
      }
      return {
        ...state,
        loading: false,
        form: {
          ...state.form,
          ...action.payload
        }
      }
    case DAILY_ACTION_FORM_START_LOADING:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
        error: false,
        success: false,
        loading: true
      }
    case DAILY_ACTION_FORM_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        successMessage: action.payload,
        error: false,
        success: true,
        loading: false
      }
    case DAILY_ACTION_FORM_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: null,
        error: true,
        success: false,
        loading: false
      }
    default:
      const x: never = action
  }
  return state
}
