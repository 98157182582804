import styled from 'styled-components'

const StyledNotifications = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 1px;
  width: fit-content;
  max-width: 60vw;
  z-index: ${({ theme }) => theme.elevations.override}99;

  @media (max-width: ${({ theme }) => theme.size.sm}px) {
    max-width: 95vw;
  }

  .body {
    max-width: 100%;
    margin-top: 15px;
    display: grid;
    grid-gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`

export default StyledNotifications
