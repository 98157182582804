import styled from 'styled-components'

type StyledProps = {
  hasActiveFilters: boolean;
  showFadeLeft: boolean;
  showFadeRight: boolean;
}

const StyledFilterGroup = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  position: relative;

  .scroll-row {
    display: flex;
    align-items: center;
    white-space: normal;
    overflow-x: auto;
    ${({ hasActiveFilters }) => hasActiveFilters && 'margin-right: calc(40px + 8px);'}

    .filter-btn:not(:last-child) {
      margin-right: 8px;
    }

    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }
  }

  .scroll-actions {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 1;

    .btn {
      width: 40px;
      height: 40px;
      padding: 0;
      border-radius: 50%;
      pointer-events: all;
    }

    &:first-child {
      padding-right: 5rem;
      background: linear-gradient(90deg, white 30%, rgba(255, 255, 255, 0));
      left: 0;
    }

    &:last-child {
      padding-left: 5rem;
      background: ${({ showFadeRight }) => showFadeRight ? 'linear-gradient(-90deg, white 30%, rgba(255, 255, 255, 0))' : 'transparent'};
      right: 0;
    }
  }
`

export default StyledFilterGroup
