import styled from 'styled-components'

type StyledProps = {
  height?: number;
  isRevealed?: boolean;
}

const StyledIssueAbout = styled.div<StyledProps>`
  max-height: ${({ height }) => height}px;
  overflow: hidden;
  position: relative;
  ${({ isRevealed }) => isRevealed && `margin-bottom: 1rem`};

  * {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
  }

  p:first-child {
    margin-top: 7px;
  }

  p:not(:first-child) {
    margin-top: 21px;
  }

  ::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px;
    pointer-events: none;
    ${({ isRevealed }) =>
    !isRevealed && `background: linear-gradient(0deg, rgba(255,255,255,1), rgba(255,255,255,0));`}
  }
`
export default StyledIssueAbout
