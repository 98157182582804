import React, { FC, ReactNode } from 'react'
import { BreadcrumbType } from '../../../../types'
import { AdminBreadcrumbs } from '../../AdminBreadcrumbs'
import { StyledAdminPageHeader } from '.'

type Props = {
  pageTitle?: string;
  breadcrumbs?: BreadcrumbType[];
  children?: ReactNode;
}

const AdminPageHeader: FC<Props> = ({ pageTitle, breadcrumbs, children }) => {
  return (
    <StyledAdminPageHeader>
      <div className="page-info">
        <h1>{pageTitle}</h1>
        {breadcrumbs && <AdminBreadcrumbs links={breadcrumbs} />}
        {!breadcrumbs && <AdminBreadcrumbs />}
      </div>
      <div className="page-actions">{children}</div>
    </StyledAdminPageHeader>
  )
}

export default AdminPageHeader
