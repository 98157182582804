import Gallery from 'react-photo-gallery'
import React from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { GalleryPhotoItem } from '..'

const SortableFeedPhoto = SortableElement(({ ...rest }) => (
  <GalleryPhotoItem isEditMode isFavorites={false} {...(rest as any)} />
))

export const GalleryFeedSortable = SortableContainer(({ data, isDragging, columns }) => (
  <Gallery
    direction="column"
    photos={data}
    margin={8}
    columns={columns}
    renderImage={(props) => <SortableFeedPhoto isDragging={isDragging} {...props} />}
  />
))
