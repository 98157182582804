import React, { FC, useContext, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { HeartIcon, TrashIcon, PlusIcon, CogIcon } from '@heroicons/react/outline'
import { useApolloClient } from '@apollo/client'
import useGallery from '../../redux/gallery/useGallery'
import useDraft from '../../redux/draft-drawer/useDraft'
import { userStore } from '../utils/UserContext'
import { GalleryFeedPhotoType } from '../../types'
import { StyledGalleryPhotoItemActions } from '.'
import ROUTES from '../utils/routes'
import useSegment from '../utils/useSegment'
import { AddToCollectionModal } from '../Modals'
import { useGalleryFeed } from './GalleryFeed'
import { DeletionConfirmation } from '../System'
import { galleryCollectionsImageRemoveManyMutation } from '../../graphql/GalleryCollectionsImage/galleryCollectionsImageRemoveMany'
import { parseImageData } from '../../redux/gallery/trackingData'
import { useNotifications } from '../Notifications'

type Props = {
  photo: GalleryFeedPhotoType;
  isEditMode?: boolean;
}

const GalleryPhotoItemActions: FC<Props> = ({ photo, isEditMode }) => {
  const isIndividualCollection = useRouteMatch(ROUTES.gallery.collectionPage)
  const isCollections = useRouteMatch(ROUTES.gallery.collections) || isIndividualCollection
  const apollo = useApolloClient()
  const { addNotification } = useNotifications()
  const { actions: { handleToggleLike } } = useGallery()
  const {
    data,
    activeCollection,
    activeTab,
    actions: { addImages }
  } = useGalleryFeed()
  const { actions: { setDraftImage } } = useDraft()
  const { user } = useContext(userStore)
  const history = useHistory()
  const { track } = useSegment()

  const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false)
  const [isLiked, setIsLiked] = useState(!!photo.liked)
  const [showAddToCollectionModal, setShowAddToCollectionModal] = useState(false)
  const photoId = photo._id

  const adminEditPhoto = () => {
    history.push(ROUTES.admin.gallery.entryById(photoId))
  }

  const handleAddToDraft = () => {
    setDraftImage(photo.file?.fileHttpLink || '', photoId)
    track('Added Photo to Quick Draft', {
      id: photoId,
      url: photo.file?.fileHttpLink,
      from: 'gallery'
    })
  }

  const handleDelete = async () => {
    try {
      await apollo.mutate({
        mutation: galleryCollectionsImageRemoveManyMutation,
        variables: { collectionId: activeCollection?._id, image: photo._id, user: user?.id }
      })

      addImages(data.filter((image) => image._id !== photo._id))
      track('Removed Image from Collection', {
        removed_from: activeCollection ? 'collection' : activeTab,
        original_photo_url: `${window.location.host}/gallery/${photo._id}`,
        ...parseImageData(photo._id, data)
      })
    }
    catch (err) {
      Sentry.captureException(err)
      addNotification({
        message: 'An error occurred. Please try again later.',
        type: 'error'
      })
    }
  }

  const likeImage = async () => {
    setIsLiked(!isLiked)
    await handleToggleLike(photo._id, !!photo.liked)
  }

  return (
    <>
      <DeletionConfirmation
        isOpen={showDeletionConfirmation}
        itemToDelete="photo"
        onConfirm={() => handleDelete()}
        hideDeletionConfirmation={() => setShowDeletionConfirmation(false)}
      />
      <AddToCollectionModal
        isOpen={showAddToCollectionModal}
        photo={photo}
        handleClose={() => setShowAddToCollectionModal(false)}
      />

      <StyledGalleryPhotoItemActions isLiked={isLiked}>
        <button className="use-btn" data-tour-id="photo-use-btn" onClick={() => handleAddToDraft()}>
          <PlusIcon />
          <span>USE</span>
        </button>
        <button className="fav-btn" onClick={() => likeImage()}>
          <HeartIcon />
        </button>
        <div className="actions-row">
          {photo.status === 'new' && <span className="new-label">NEW</span>}
        </div>
        {isCollections && (
          <button className="delete-btn" onClick={() => setShowDeletionConfirmation(true)}>
            <TrashIcon />
          </button>
        )}
        {user?.role === 'admin' && (
          <button className="admin-btn" onClick={() => adminEditPhoto()}>
            <CogIcon />
          </button>
        )}
      </StyledGalleryPhotoItemActions>
    </>
  )
}

export default GalleryPhotoItemActions
