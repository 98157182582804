import styled from 'styled-components'

const StyledAdminPageNav = styled.div`
  .nav-header {
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 5px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[300]};
  }

  .nav-links {
    display: grid;
    max-height: 500px;
    overflow-y: auto;

    a {
      display: flex;
      align-items: center;
      padding: 10px 1rem;
      border-radius: 3px;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      background: transparent;
      cursor: pointer;

      &:hover {
        background: ${({ theme }) => theme.colors.gray[100]};
      }

      &.active {
        background: ${({ theme }) => theme.colors.background};

        &:hover {
          background: ${({ theme }) => theme.colors.background};
        }
      }
    }
  }
`

export default StyledAdminPageNav
