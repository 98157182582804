import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppState } from '../root-reducer'
import { GalleryState } from './types'
import {
  gallerySortByChangeAction,
  gallerySearchChangeAction,
  galleryOrientationChangeAction,
  galleryCategoryChangeAction,
  galleryStyleChangeAction,
  galleryColorChangeAction,
  galleryClearFiltersAction,
  galleryLoadSearchAction,
  gallerySetPageAction,
  gallerySetCountDocsAction,
  galleryImageDataAction,
  galleryCollectionDataAction,
  gallerySetEditModeAction,
  gallerySetActiveTabAction
} from './actions'

const useGalleryFeed = () => {
  const { entryId: showPhotoId }: any = useParams()

  const dispatch = useDispatch()
  const {
    editMode,
    collections,
    activeCollection,
    loading,
    data,
    activeTab,
    search,
    sortBy,
    color,
    orientation,
    category,
    style,
    page,
    countDocs
  } = useSelector<AppState, GalleryState>((state) => state.gallery)

  const addImages = (payload: GalleryState['data']) => {
    dispatch(galleryImageDataAction(payload))
  }

  const addCollections = (payload: GalleryState['collections']) => {
    dispatch(galleryCollectionDataAction(payload))
  }

  const changeCount = (payload: GalleryState['countDocs']) => {
    dispatch(gallerySetCountDocsAction(payload))
  }

  const changePage = (payload: GalleryState['page']) => {
    dispatch(gallerySetPageAction(payload))
  }

  const changeSortBy = (payload: GalleryState['sortBy']) => {
    dispatch(gallerySortByChangeAction(payload))
  }

  const changeOrientation = (payload: GalleryState['orientation']) => {
    dispatch(galleryOrientationChangeAction(payload || null))
  }

  const changeColor = (payload: GalleryState['color']) => {
    dispatch(galleryColorChangeAction(payload))
  }

  const changeCategory = (payload: GalleryState['category']) => {
    dispatch(galleryCategoryChangeAction(payload))
  }

  const changeStyle = (payload: GalleryState['style']) => {
    dispatch(galleryStyleChangeAction(payload))
  }

  const changeSearch = (payload: GalleryState['search']) => {
    dispatch(gallerySearchChangeAction(payload))
  }

  const loadSearch = (payload: any) => {
    dispatch(galleryLoadSearchAction(payload))
  }

  const clearFilters = () => {
    dispatch(galleryClearFiltersAction())
  }

  const changeEditMode = (payload: GalleryState['editMode']) => {
    dispatch(gallerySetEditModeAction(payload))
  }

  const changeActiveTab = (payload: string) => {
    dispatch(gallerySetActiveTabAction(payload))
  }

  const filtersNumber = useMemo(() => {
    const appliedFilters = [color.length, orientation, category.length, style.length].filter(
      Boolean
    )
    return appliedFilters.length
  }, [color, orientation, category, style])

  return {
    data,
    collections,
    editMode,
    loading,
    showPhotoId,
    noImagesFound: !data.length && !loading,
    page,
    activeTab,
    activeCollection,
    search,
    sortBy,
    color,
    orientation,
    category,
    style,
    filtersNumber,
    countDocs,
    actions: {
      addImages,
      addCollections,
      changeCount,
      changePage,
      changeSortBy,
      changeSearch,
      changeOrientation,
      changeCategory,
      changeStyle,
      changeColor,
      changeEditMode,
      changeActiveTab,
      clearFilters,
      loadSearch
    }
  }
}

export default useGalleryFeed
