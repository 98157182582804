import styled, { keyframes } from 'styled-components'

const popInAnim = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`

const rotateAnim = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
`

const StyledDraftDrawerCaption = styled.label`
  display: grid;
  align-content: space-between;

  .textarea-container {
    position: relative;

    textarea {
      width: 100%;
      padding: 1rem 20px;
      border: 0;
      resize: none;
      min-height: 250px;
      outline: none;
      line-height: 25px;
      font-size: 17px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.gray[700]};

      &:focus + .textarea-placeholder {
        opacity: 0;

        a {
          pointer-events: none;
        }
      }
    }

    .textarea-placeholder {
      position: absolute;
      top: 20px;
      left: 20px;
      user-select: none;
      font-size: 17px;
      pointer-events: none;
      letter-spacing: -0.01rem;
      transition: 0.2s;
      color: ${({ theme }) => theme.colors.gray[400]};

      a {
        font-weight: 600;
        pointer-events: all;
        padding: 2px 4px;
        border-radius: 3px;
        transition: 0.2s;
        text-decoration: underline;
        color: ${({ theme }) => theme.colors.gray[500]};
        margin: 0 -3px;

        &:hover {
          background-color: ${({ theme }) => theme.colors.gray[100]};
        }
      }
    }
  }

  .textarea-actions {
    padding: 10px 0;
    margin: 0 20px;
    display: flex;
    align-items: center;
    position: sticky;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.background};
    border-top: 1px solid ${({ theme }) => theme.colors.gray[200]};
    justify-content: space-between;

    .draft-caption-character-count-container {
      display: flex;
      align-items: center;
      margin: -8px 0;

      .draft-caption-character-count {
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.colors.gray[500]};
        font-weight: 600;
        font-size: 14px;
        letter-spacing: -0.01rem;
        user-select: none;

        svg {
          color: ${({ theme }) => theme.colors.gray[400]};
          margin-right: 8px;
          transform: scale(0);
          opacity: 0;
          animation: ${popInAnim} 0.3s forwards ease-in-out;
        }
      }

      .switch-character-count-btn {
        margin-left: 12px;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        color: ${({ theme }) => theme.colors.gray[500]};
        background-color: ${({ theme }) => theme.colors.gray[100]};

        svg {
          height: 20px;
        }

        &:hover {
          color: ${({ theme }) => theme.colors.gray[600]};
          background-color: ${({ theme }) => theme.colors.gray[200]};
        }

        &.animate {
          animation: ${rotateAnim} 0.5s forwards ease-in-out;
        }
      }
    }

    .draft-caption-actions {
      display: flex;
      align-items: center;

      .draft-caption-action-btn {
        border-radius: 50%;
        height: 40px;
        width: 40px;
        transition: 0.2s;
        background-color: ${({ theme }) => theme.colors.background};
        color: ${({ theme }) => theme.colors.gray[500]};
        border: 1px solid ${({ theme }) => theme.colors.gray[300]};

        &.emoji-picker-btn {
          display: none;
        }

        &:hover {
          background-color: ${({ theme }) => theme.colors.gray[100]};
          color: ${({ theme }) => theme.colors.gray[600]};
          border: 1px solid ${({ theme }) => theme.colors.gray[400]};
        }

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => `${theme.breakpoints.s}px`}) {
    .textarea-container {
      position: relative;

      textarea {
        font-size: 1rem;
      }

      .textarea-placeholder {
        font-size: 1rem;
      }
    }

    .textarea-actions {
      .draft-caption-actions {
        .draft-caption-action-btn {
          &.emoji-picker-btn {
            display: flex;
          }
        }
      }
    }
  }
`

export default StyledDraftDrawerCaption
