import styled from 'styled-components'

type StyledProps = {
  isActive?: boolean;
}

const StyledSwitchOption = styled.div<StyledProps>`
  font-weight: 600;
  font-size: 14px;
  padding: 0 10px;
  color: ${({ theme, isActive }) => isActive ? theme.colors.gray[900] : theme.colors.gray[500]};
  background: ${({ theme, isActive }) => isActive ? theme.colors.background : 'transparent'};
  box-shadow: ${({ isActive }) => isActive ? '0px 3px 2px rgba(97, 97, 97, 0.03)' : 'none'};
  height: 100%;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    height: 20px;
  }
`

export default StyledSwitchOption
