import { gql } from '@apollo/client'

export const JoinChallengeMutation = gql`
  mutation JoinChallenge($challenge: String!) {
    joinChallenge(challenge: $challenge) {
      _id
      user
      challenge
      completed
      completedExtraDrafts
      grantedExtraDrafts
      updatedAt
      joined
    }
  }
`
