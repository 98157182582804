import firebase from 'firebase/app'
import * as Sentry from '@sentry/react'
import { useMemo, useState, useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import parser from 'query-string'
import { useForm } from '../utils'
import { loginValidation } from './Validation/loginValidation'
import { LoginMigrationResponse } from '../User'
import { userStore } from '../utils/UserContext'
import ROUTES from '../utils/routes'

const useLoginHandler = () => {
  const form = useForm({ shouldUnregister: false })
  const validation = useMemo(() => loginValidation, [])
  const history = useHistory()
  const { search, pathname } = useLocation()
  const { redirect } = parser.parse(search)

  const { user } = useContext(userStore)
  const isExpiredTrialUser = user?.hasTrialed && user?.stripeSubscriptionStatus === 'Inactive'
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState('')

  const loginMigration = firebase.functions().httpsCallable('loginMigration')

  const tryLoginMigration = async () => {
    try {
      const response: LoginMigrationResponse = await loginMigration({
        email: form.getValues('emailAddress'),
        password: form.getValues('password')
      })

      if (response.data.passwordFailed) {
        throw new Error('Password is incorrect')
      }
    }
    catch (err) {
      Sentry.setUser({ email: form.getValues('emailAddress') })
      Sentry.captureException(err)
    }
  }

  const handleLogin = async () => {
    const isValid = await form.trigger()

    if (!isValid) {
      return
    }

    try {
      setLoading(true)
      await tryLoginMigration()
      await firebase
        .auth()
        .signInWithEmailAndPassword(form.getValues('emailAddress'), form.getValues('password'))
    }
    catch (err) {
      if (err.code === 'auth/user-not-found') {
        return setFormError('No user was found with that email address.')
      }

      if (err.code === 'auth/wrong-password') {
        return setFormError('Incorrect password.')
      }

      setFormError(err.message)
    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    // if expired trial user
    // set subscription select page as landing page
    // set state for expired CTA modal to display
    if (user?.id && isExpiredTrialUser) {
      history.push(ROUTES.user.settings.plan, {
        from: pathname,
        expiredTrialUser: isExpiredTrialUser
      })
      return
    }

    if (!user?.id) {
      return
    }

    if (redirect) {
      window.location.replace(redirect.toString())
      return
    }

    history.push(ROUTES.admin.users.list)
  }, [user])

  return {
    form,
    validation,
    formError,
    loading,
    actions: { handleLogin }
  }
}

export default useLoginHandler
