import styled from 'styled-components'

const StyledGalleryCollectionsFeed = styled.div`
  .collections-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 1rem;
    overflow: hidden;
  }

  .add-collection-container {
    background: ${({ theme }) => theme.colors.gray[100]};
    position: relative;
    padding-top: 100%;
    border-radius: 3px;
    height: fit-content;
    user-selecton: none;
    transition: 0.2s;

    &:hover {
      background: ${({ theme }) => theme.colors.gray[200]};
      cursor: pointer;
    }

    .add-collection {
      display: grid;
      justify-content: center;
      align-items: center;
      align-content: center;
      grid-gap: 1rem;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

      .icon {
        height: 40px;
        width: 40px;
        background: ${({ theme }) => theme.colors.background};
        box-shadow: 0px 8px 16px 2px rgba(97, 97, 97, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin: 0 auto 1rem auto;

        svg {
          height: 20px;
          color: ${({ theme }) => theme.colors.gray[600]};
        }
      }

      .title {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.01rem;
        color: ${({ theme }) => theme.colors.gray[600]};
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    .collections-grid {
      grid-gap: 2rem 1rem;
    }
  }
`

export default StyledGalleryCollectionsFeed
