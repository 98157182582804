import { ReactNode } from 'react'

export { default as IssueModal } from './IssueModal'

export { default as IssueModalGallery } from './IssueModalGallery'
export { default as StyledIssueModalGallery } from './IssueModalGallery.Styled'

export { default as IssueModalContent } from './IssueModalContent'
export { default as StyledIssueModalContent } from './IssueModalContent.Styled'

export { default as StyledIssueModalItem } from './IssueModalItem.Styled'

export { default as IssueModalPhoto } from './IssueModalPhoto'
export { default as IssueModalStory } from './IssueModalStory'
export { default as IssueModalExtra } from './IssueModalExtra'

export type ThemeModal = 'user' | 'admin' | 'admin-page' | 'issue'

export type ModalProps = {
  onClose: (e: any) => void;
  show: boolean;
  title: string | ReactNode;
  subTitle?: string | null;
  downloadUrl?: string;
  recordType?: string;
  footerButtons?: ReactNode[];
  titleButtons?: ReactNode;
  children: ReactNode;
  modalType?: ThemeModal;
  extraType?: string | null;
  windowHeight?: number;
}
