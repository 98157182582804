import React, { FC } from 'react'
import Helmet from 'react-helmet'

const MetaHelmet: FC = () => {
  return (
    <div>
      <Helmet titleTemplate="%s | Social Curator" defaultTitle="Social Curator">
        <meta charSet="utf-8" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="pinterest" content="nopin" />
        <meta
          name="description"
          content="The place to get inspired and plan your social media content."
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css"
          integrity="sha256-l85OmPOjvil/SOvVt3HnSSjzF1TUMyT9eV0c2BzEGzU="
          crossOrigin="anonymous"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap&family=Manrope:wght@200;300;400;500;600;700;800"
          rel="stylesheet"
        />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      </Helmet>
    </div>
  )
}

export default MetaHelmet
