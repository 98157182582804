import React, { FC } from 'react'
import { StyledDummyAction } from '.'

const DummyAction: FC = () => {
  return (
    <StyledDummyAction>
      <div className="dummy-action-right">
        <div className="dummy-action-icon-group">
          <div className="dummy-action-icon" />
          <div className="dummy-action-step">
            <div />
            <div />
            <div />
          </div>
        </div>
        <div className="dummy-action-info-container">
          <div className="dummy-action-info">
            <span className="dummy-action-category" />
            <div className="dummy-action-pagination-btn" />
            <div className="dummy-action-pagination-btn" />
          </div>
          <div className="dummy-action-text" />
        </div>
      </div>
      <div className="dummy-complete-btn" />
    </StyledDummyAction>
  )
}

export default DummyAction
