import styled from 'styled-components'

const StyledGalleryCollectionsModal = styled.div`
  max-width: 450px;
  width: 100vw;
  padding: 32px;
  max-height: 600px;

  .image-preview {
    position: relative;
    background: ${({ theme }) => theme.colors.gray[100]};
    margin: 0 auto;
    width: auto;
    height: 200px;
    margin-bottom: 2rem;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      position: absolute;
      left: 1rem;
      right: 0;
      top: 1rem;
      bottom: 0;
      width: calc(100% - 2rem);
      height: calc(100% - 2rem);
      object-fit: contain;
    }
  }

  .input-container {
    display: flex;

    .input {
      width: 100%;

      .input-container input {
        height: 42px;
      }
    }

    .new-btn {
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: transparent;
      padding: 0 18px;
      transition: 0.2s;
      border-radius: 6px;
      user-select: none;
      font-weight: 600;
      background-color: ${({ theme }) => theme.colors.gray[100]};
      color: ${({ theme }) => theme.colors.gray[600]};

      span {
        position: relative;
        top: 1px;
      }

      margin-left: 12px;
      flex-shrink: 0;

      svg {
        height: 18px;
        margin-right: 8px;
      }

      &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.gray[200]};
      }
    }
  }

  .empty-state {
    display: block;
    margin: 2rem auto 0 auto;
    font-weight: 500;
    font-size: 14px;
    width: fit-content;
    color: ${({ theme }) => theme.colors.gray[500]};
    line-height: 22px;
    font-size: 1rem;
  }

  .collection-actions {
    display: flex;
    position: sticky;
    bottom: 0;
    padding: 32px;
    margin: 0 -32px -32px -32px;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.background};
    left: 0;
    right: 0;

    button {
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: transparent;
      padding: 0 18px;
      transition: 0.2s;
      border-radius: 6px;
      user-select: none;
      font-weight: 600;
      background-color: ${({ theme }) => theme.colors.gray[100]};
      color: ${({ theme }) => theme.colors.gray[600]};

      span {
        position: relative;
        top: 1px;
      }

      &:not(:last-child) {
        margin-right: 16px;
        flex-shrink: 0;
      }

      &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.gray[200]};
      }
    }

    .confirm-btn {
      width: 100%;
      background-color: ${({ theme }) => theme.colors.green[500]};
      color: ${({ theme }) => theme.colors.background};

      span {
        position: relative;
        top: 1px;
      }

      svg {
        height: 18px;
        margin-right: 8px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.green[600]};
      }

      &:disabled {
        background-color: ${({ theme }) => theme.colors.gray[100]};
        color: ${({ theme }) => theme.colors.gray[400]};

        &:hover {
          background-color: ${({ theme }) => theme.colors.gray[100]};
          cursor: not-allowed;
        }
      }
    }
  }

  .back-btn {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    .back-btn {
      display: flex;
    }
  }
`

export default StyledGalleryCollectionsModal
