import React, { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import firebase from 'firebase/app'
import EditUserRole from './EditUserRole'
import { CombinedUser } from '../../User'
import { BreadcrumbType } from '../../../types'
import { useNotifications } from '../../Notifications'
import { Button } from '../../System'
import { WidthController } from '../../Grid'
import { AdminPageHeader, AdminPageLayout, AdminPageForm } from '../AdminComponents'

const AdminUserPage: FC = () => {
  const { userId }: any = useParams()
  const [updating, setUpdating] = useState(false)
  const [user, setUser] = useState<CombinedUser | undefined>()
  const fnGetUserById = firebase.functions().httpsCallable('getUserById')
  const fnUpdateUser = firebase.functions().httpsCallable('updateUser')
  const { addNotification } = useNotifications()

  useEffect(() => {
    fnGetUserById({ userId })
      .then(({ data }) => {
        const { userData } = data
        setUser(userData)
      })
      .catch((e) => {
        addNotification({ message: `Cannot load User: ${e.toString()}`, type: 'error' })
        console.error(e)
      })
  }, [userId])

  const handleChangeField = ({ fieldName, value }) => {
    setUser(
      (prev) =>
        ({
          ...prev,
          [fieldName]: value
        } as CombinedUser)
    )
  }

  const handleSave = () => {
    setUpdating(true)
    fnUpdateUser({ userId, form: user })
      .then(() => {
        addNotification({ message: `User is updated`, type: 'success' })
        setUpdating(false)
      })
      .catch((e) => {
        addNotification({ message: `Cannot update a user: ${e.toString()}`, type: 'error' })
        console.error(e)
        setUpdating(false)
      })
  }

  const breadcrumbs = useMemo<BreadcrumbType[]>(
    () => [{ dynamic: { type: 'user', entryId: userId as string } }],
    [userId]
  )

  return (
    <AdminPageLayout>
      <AdminPageHeader
        pageTitle={user?.role === 'admin' ? 'Edit Profile' : user?.displayName ?? 'Loading...'}
        breadcrumbs={breadcrumbs}
      >
        <Button isDisabled={updating} onClick={() => handleSave()}>
          {updating ? 'Loading...' : 'Save Changes'}
        </Button>
      </AdminPageHeader>
      <WidthController noHeight>
        {user && <EditUserRole user={user} change={handleChangeField} />}
      </WidthController>
    </AdminPageLayout>
  )
}

export default AdminUserPage
