import React, { FC, useEffect } from 'react'
import { useRouteMatch, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { useApolloClient } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import ROUTES from '../utils/routes'
import { PageLayout } from '../System'
import { GalleryPhotoModal } from '../Modals'
import { SkeletonLoader } from '../SkeletonLoader'
import { GalleryFilters } from './GalleryFilters'
import { GalleryCollectionsFeed } from './GalleryCollections'
import { GalleryFeed, useGalleryFeed } from './GalleryFeed'
import { StyledGalleryView, transformData, parseUrlPathToTab } from '.'
import { SearchGalleryQuery } from '../../graphql/GalleryImage/__generated__/SearchGalleryQuery'
import { searchGalleryQuery } from '../../graphql/GalleryImage/searchGallery'
import { useNotifications } from '../Notifications'
import { gallerySetActiveTabAction } from '../../redux/gallery/actions'
import useSearchParams from '../utils/useSearchParams'
import useSegment from '../utils/useSegment'
import useGalleryCollectionInfo from './GalleryCollections/useGalleryCollectionInfo'
import useLoading from '../utils/useLoading'

const GalleryView: FC = () => {
  useGalleryCollectionInfo()
  const apollo = useApolloClient()
  const { track } = useSegment()
  const dispatch = useDispatch()
  const { addNotification } = useNotifications()
  const { pathname: currentPath } = useLocation()
  const isCollectionsPage = useRouteMatch(ROUTES.gallery.collections)?.isExact

  const {
    data,
    page,
    showPhotoId,
    loading,
    search,
    sortBy,
    category,
    orientation,
    style,
    color,
    activeCollection,
    activeTab,
    actions: { addImages, changeCount, loadSearch }
  } = useGalleryFeed()

  const { loading: delayedLoad, setLoading: setDelayedLoading } = useLoading()

  const { getSearchFromURL } = useSearchParams({
    search,
    orientation,
    sortBy,
    category,
    style,
    color
  })

  // Sends the search event to Segment
  const handleSearchTrack = debounce((resultsCount: number) => {
    track('Searched Gallery', {
      search_results_count: resultsCount,
      search: search || '',
      sorted_by: sortBy,
      style_filter: style,
      color_filter: color,
      category_fitler: category,
      orientation_filter: orientation,
      gallery_tab: activeCollection ? 'collection' : activeTab
    })
  }, 3000)

  useEffect(() => {
    loadSearch(getSearchFromURL())
  }, [])

  useEffect(() => {
    let isSubscribed = true

    const handleImages = async () => {
      try {
        setDelayedLoading(true)
        const { data: res } = await apollo.query<SearchGalleryQuery>({
          query: searchGalleryQuery,
          fetchPolicy: 'no-cache',
          variables: {
            search,
            orientation,
            sort: sortBy,
            categories: category,
            style,
            colors: color,
            items: 14,
            collection: !['main', 'favorites'].includes(activeTab || '') ? activeTab : null,
            liked: activeTab === 'favorites' ? true : null,
            page
          }
        })

        handleSearchTrack(res?.searchGallery?.totalDocs || 0)

        const parsedHits = res.searchGallery?.docs?.map(transformData) || []

        // prevent displaying of stale results from resolved query in previous render
        if (!isSubscribed) {
          return
        }

        addImages([...data, ...parsedHits])
        changeCount(res?.searchGallery?.totalDocs || 0)
        setDelayedLoading(false)
      }
      catch (err) {
        addNotification({ message: err.message, type: 'error', interval: 10000 })
        Sentry.captureException(err)
      }
    }

    if (loading && isSubscribed) {
      handleImages()
    }

    return () => {
      isSubscribed = false
    }
  }, [search, orientation, sortBy, category, style, color, activeTab, loading])

  useEffect(() => {
    /**
     * Change currentTab only when it actually changes. If image is opened
     * (causing the change of currentPath), currentTab should still be unchanged,
     * this helps to avoid useless re-renders of the feed
     */
    const newTab = parseUrlPathToTab(currentPath, 'tab')
    if (newTab != activeTab) {
      dispatch(gallerySetActiveTabAction(newTab))
    }
  }, [currentPath])

  return (
    <PageLayout pageTitle="Gallery">
      <StyledGalleryView>
        <GalleryFilters isCollectionsPage={isCollectionsPage} />
        <div className="gallery-feed">
          {!isCollectionsPage && <GalleryFeed />}
          {!isCollectionsPage && delayedLoad && data.length === 0 && (
            <div className="fake-gallery-feed">
              <SkeletonLoader type="square-list-item" />
            </div>
          )}
          {isCollectionsPage && <GalleryCollectionsFeed />}
        </div>
        <GalleryPhotoModal isOpen={!!showPhotoId} />
      </StyledGalleryView>
    </PageLayout>
  )
}

export default GalleryView
