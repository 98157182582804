import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import moment, { Moment } from 'moment'
import React, { forwardRef, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { Button } from '..'
import { StyledDateInput } from '.'

type Props = {
  value?: Moment;
  name?: string;
  placeholder?: string;
  isError?: boolean;
  onChange?: (date?: Moment | null) => void;
}

const DateInput = forwardRef<any, Props>((refProps, ref) => {
  const {
    value, name, placeholder, isError, onChange = () => {}, ...props
  } = refProps
  const [isFocused, setIsFocused] = useState(false)

  // This allows any date to be picked
  const outsideRange = () => false

  return (
    <StyledDateInput className="date-input">
      <div className="input-icon">
        <CalendarIcon />
      </div>
      <SingleDatePicker
        id={name || ''}
        placeholder={placeholder}
        date={value || null}
        focused={isFocused}
        navNext={(
          <Button theme="outline" type="small" isIcon>
            <ChevronRightIcon />
          </Button>
        )}
        navPrev={(
          <Button theme="outline" type="small" isIcon>
            <ChevronLeftIcon />
          </Button>
        )}
        onFocusChange={() => setIsFocused(!isFocused)}
        onDateChange={(e) => onChange(e)}
        numberOfMonths={1}
        transitionDuration={0}
        isOutsideRange={outsideRange}
        block
        noBorder
        hideKeyboardShortcutsPanel
        ref={ref}
        {...props}
      />
    </StyledDateInput>
  )
})

export default DateInput
