import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { arrayMoveImmutable } from 'array-move'
import { useApolloClient } from '@apollo/client'
import { AppState } from '../root-reducer'
import { DraftsState, DraftSort } from './types'
import {
  draftsAddDrafts,
  draftsSetDrafts,
  draftsDeleteDraft,
  draftsHideUsedDraft,
  draftsSetCategory,
  draftsSetSearchDraft,
  draftsSetSortDraft,
  draftsLoadSearch,
  draftsOnlyLiked,
  draftsClearCategories,
  draftsSetPageDraft
} from './actions'
import useSegment from '../../components/utils/useSegment'
import { SearchUserDrafts_searchUserDrafts_docs } from '../../graphql/UserDraft/__generated__/SearchUserDrafts'
import { CAPTION_CATEGORY_LIST } from '../../types'
import { reorderUserDrafts } from '../../graphql/UserDraft/reorderUserDraft'

const useDraftsList = () => {
  const { track } = useSegment()
  const {
    drafts, categories, hideUsed, search, sort, onlyLiked, loading, page
  } = useSelector<
    AppState,
    DraftsState
  >((state) => state.draftsList)
  const dispatch = useDispatch()
  const apollo = useApolloClient()

  const [view, setView] = useState('list')

  // Set drafts that are being rendered into the Drafts View
  const setDrafts = (drafts: SearchUserDrafts_searchUserDrafts_docs[]) => {
    dispatch(draftsSetDrafts(drafts))
  }

  // Add draft
  const addDrafts = (draft: SearchUserDrafts_searchUserDrafts_docs[]) => {
    dispatch(draftsAddDrafts(draft))
  }

  // Delete draft
  const removeDraft = (id: string) => {
    dispatch(draftsDeleteDraft(id))
  }

  // Update draft
  const updateDraft = (updatedDraft: SearchUserDrafts_searchUserDrafts_docs) => {
    const updatedDrafts = drafts.map((draft) => {
      if (draft._id === updatedDraft._id) {
        return {
          ...draft,
          ...updatedDraft
        }
      }
      return draft
    })

    setDrafts(updatedDrafts)
  }

  // Like a draft
  const likeDraft = (_id: string) => {
    const updatedDrafts = drafts.map((draft) => {
      if (draft._id === _id) {
        return { ...draft, isLiked: !draft.isLiked }
      }
      return draft
    })

    setDrafts(updatedDrafts)
  }

  // Mark draft as used
  const draftUsed = (_id: string) => {
    const updatedDrafts = drafts.map((draft) => {
      if (draft._id === _id) {
        return { ...draft, isUsed: !draft.isUsed }
      }
      return draft
    })

    setDrafts(updatedDrafts)
  }

  // Load search & sort parameters from the URL
  const loadParams = (params: any) => {
    const {
      search, categories, sort, onlyLiked, hideUsed
    } = params

    dispatch(
      draftsLoadSearch({
        search: search || '',
        categories: categories || [],
        sort: sort || 'custom',
        onlyLiked,
        hideUsed
      })
    )
  }

  // Handles custom sorting of drafts by dragging
  const handleDrag = async (oldIndex: number, newIndex: number) => {
    const draftsMoved = arrayMoveImmutable(drafts, oldIndex, newIndex)
    const newDraftOrder = draftsMoved.map((draft, index) => {
      return {
        ...draft,
        index: draftsMoved.length - index
      }
    })

    setDrafts(newDraftOrder)
    draftsSetSortDraft('custom')

    const reorderDraftDetails = newDraftOrder.map(({ _id, index }) => ({ _id, index }))
    await apollo.mutate({
      mutation: reorderUserDrafts,
      fetchPolicy: 'no-cache',
      variables: { userDrafts: reorderDraftDetails }
    })

    setDrafts(newDraftOrder)
    track('Reordered Draft Library')
  }

  const toggleLiked = () => {
    dispatch(draftsOnlyLiked(!onlyLiked))
  }

  const toggleUsed = () => {
    dispatch(draftsHideUsedDraft(!hideUsed))
  }

  const clearCategories = () => {
    dispatch(draftsClearCategories())
  }

  const setSort = (sort: DraftSort) => {
    dispatch(draftsSetSortDraft(sort))
  }

  const setSearch = (search: string) => {
    dispatch(draftsSetSearchDraft(search))
  }

  const changePage = (page: number) => {
    dispatch(draftsSetPageDraft(page))
  }

  const toggleCategory = (category: typeof CAPTION_CATEGORY_LIST[number]) => {
    dispatch(draftsSetCategory(category))
  }

  return {
    loading,
    page,
    drafts,
    categories,
    onlyLiked,
    hideUsed,
    search,
    sort,
    view,
    actions: {
      loadParams,
      setDrafts,
      addDrafts,
      removeDraft,
      likeDraft,
      updateDraft,
      draftUsed,
      changePage,
      setSort,
      setView,
      setSearch,
      toggleCategory,
      clearCategories,
      toggleLiked,
      toggleUsed,
      handleDrag
    }
  }
}

export default useDraftsList
