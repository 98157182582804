import React, { FC, ReactNode } from 'react'
import { StyledEmptyState } from '.'
import { Button } from '../System'

type Props = {
  image?: string;
  title: string;
  suggestion?: string | ReactNode[];
  height?: number;
  action?: {
    label: string;
    function: () => void;
  };
}

const EmptyState: FC<Props> = ({
  image, title, suggestion, height, action
}) => {
  // If the suggestion is an array, get a random element
  // from the array, if not, use the suggestion string
  const determineSuggestion = Array.isArray(suggestion)
    ? suggestion[Math.floor(Math.random() * suggestion.length)]
    : suggestion

  return (
    <StyledEmptyState className="empty-state" height={height}>
      {image && <img src={image} alt={title} />}
      <span>
        <b>{title}</b>
        {determineSuggestion}
      </span>
      {action && (
        <Button onClick={() => action.function()} type="small">
          {action.label}
        </Button>
      )}
    </StyledEmptyState>
  )
}

export default EmptyState
