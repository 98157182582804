import styled from 'styled-components'

type Props = {
  progress: number;
  height?: number;
  bgColor?: string;
}

const StyledProgressBar = styled.div<Props>`
  height: ${({ height }) => `${height || 5}px`};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray[200]};
  border-radius: 40px;

  .progress-bar {
    height: 100%;
    width: ${({ progress }) => `${progress}%`};
    background-color: ${({ theme, bgColor }) => bgColor || theme.colors.gray[700]};
    border-radius: 40px;
    text-align: right;
  }
`

export default StyledProgressBar
