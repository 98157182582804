import styled from 'styled-components'

const StyledLabelWrapper = styled.div`
  .input-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;

    .input-label {
      font-family: ${({ theme }) => theme.fonts.lexend};
      font-weight: 400;
      color: ${({ theme }) => theme.colors.gray[400]};
      font-size: 16px;
    }
  }

  .input-tip {
    margin-top: 5px;

    span {
      font-size: 14px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.gray[400]};
    }
  }

  .input-message {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;

    &.input-error {
      color: #b93619;

      svg {
        margin-right: 7px;
      }
    }

    &.input-valid {
      color: #3fae9a;
    }

    &.lower {
      margin-top: 6px;
    }

    svg {
      height: 20px;
    }
  }
`

export default StyledLabelWrapper
