import React, { createContext } from 'react'
import * as Sentry from '@sentry/react'
import { SplitFactory, SplitTreatments, SplitClient } from '@splitsoftware/splitio-react'
import useSplit from './useSplit'

const isEnabled = (flag: SplitIO.TreatmentWithConfig) => flag.treatment === 'on'

type InitialState = {
  isReady: boolean;
  treatments: SplitIO.TreatmentsWithConfig;
  isEnabled: (flag: SplitIO.TreatmentWithConfig) => boolean;
}

const initialState: InitialState = {
  isReady: false,
  treatments: {},
  isEnabled
}

const SplitContext = createContext(initialState)
const { Provider } = SplitContext

const SplitProvider = ({ children }: { children: React.ReactNode }) => {
  const { CONFIG, FLAG_NAMES, attributes } = useSplit()
  const userId = CONFIG.core.key

  return (
    <SplitFactory config={CONFIG}>
      <SplitClient splitKey={userId}>
        <SplitTreatments names={FLAG_NAMES} attributes={attributes}>
          {({ treatments, isReady, isTimedout }) => {
            // if timed-out and Split is not ready, send the current config
            if (isTimedout) {
              Sentry.captureMessage('Split failed to initialize.', (scope) =>
                scope.setContext('Split Config', { userId, attributes }))
            }

            return (
              <Provider
                value={{
                  isReady,
                  treatments,
                  isEnabled
                }}
              >
                {children}
              </Provider>
            )
          }}
        </SplitTreatments>
      </SplitClient>
    </SplitFactory>
  )
}

export { SplitProvider, SplitContext }
