import { gql } from '@apollo/client'

export const socialProfileFindMany = gql`
  query SocialProfileFindMany($user: String!, $type: String) {
    socialProfileFindMany(filter: { user: $user, type: $type }) {
      _id
      type
      username
      avatarUrl
      profileId
      pageId
      accessToken
      pageAccessToken
      status
    }
  }
`
