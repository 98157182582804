import { GalleryFeedPhotoType } from '../../types'

export const parseImageData = (imageId: string, data: GalleryFeedPhotoType[]) => {
  // find image in feed by ID
  const imageData = data.find((image) => image._id === imageId)

  return {
    category: imageData?.category,
    colors: imageData?.colors,
    orientation: imageData?.orientation,
    season: imageData?.season,
    style: imageData?.style,
    tags: imageData?.tags?.map((tag) => tag.label)
  }
}
