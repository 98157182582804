const chooseDomain = (domain: string) => {
  return process.env.NODE_ENV === 'development' ? 'localhost' : domain
}

export const setCookie = (
  name: string,
  value: string,
  path = '/',
  domain = '.socialcurator.com'
) => {
  const chosenDomain = chooseDomain(domain)
  const expires = new Date(2038, 1, 1).toString()
  document.cookie = `${name}=${value};path=${path};domain=${chosenDomain};expires=${expires};SameSite=None;Secure;`
}

export const removeCookie = (name: string, path = '/', domain = '.socialcurator.com') => {
  const chosenDomain = chooseDomain(domain)
  const expires = new Date(1971, 1, 1).toString()
  document.cookie = `${name}=;path=${path};domain=${chosenDomain};expires=${expires};SameSite=None;Secure;`
}
