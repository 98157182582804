import { times } from 'lodash'
import styled from 'styled-components'

type StyledProps = {
  gap: number;
  columns?: number;
  inLayout?: boolean;
}

const StyledGrid = styled.div<StyledProps>`
  display: grid;
  align-content: flex-start;
  grid-gap: ${({ gap }) => gap}px;
  ${({ inLayout }) => inLayout && `
    margin: 32px 0;
    &:last-child {
      margin-bottom: 0;
    }
  `}

  grid-template-columns: 1fr;

  @media (min-width: ${({ theme }) => theme.breakpoints.l}px) {
    ${({ columns }) => columns && `
      grid-template-columns: ${times(columns).map(() => ` 1fr `).join('')};
    `}
  }

  .row-header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background: ${({ theme }) => theme.colors.background};
    box-shadow: 0 0 5px 0px #00000024;
    border-radius: 3px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray[600]};

    &:not(:first-child) {
      margin-top: 32px;
    }
  }
`

export default StyledGrid
