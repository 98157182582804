import styled from 'styled-components'
import { Link } from 'react-router-dom'

const StyledGalleryCollectionsItem = styled(Link)`
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  transition: 0.2s;
  border-radius: 6px;
  overflow: hidden;

  .collection-item-img {
    height: 45px;
    width: 45px;
    border-radius: 3px;
    flex-shrink: 0;
    background-color: ${({ theme }) => theme.colors.gray[100]};
    position: relative;
    margin-right: 10px;

    img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 3px;
    }

    .empty-icon {
      color: ${({ theme }) => theme.colors.gray[300]};
      height: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  
    &::after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      content: "";
      border: 1px solid #0000000f;
      border-radius: 3px;
      pointer-events: none;
    }
  }

  .collection-item-details {
    span {
      display: block;
    }

    .collection-item-title {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.gray[600]};
      display: -webkit-box;
      -webkit-line-clamp: 1;
      overflow: hidden;
      -webkit-box-orient: vertical;
    }

    .collection-item-count {
      font-weight: 600;
      font-size: 14px;
      margin-top: 2px;
      color: ${({ theme }) => theme.colors.gray[500]};
    }
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.gray[50]};
  }
`

export default StyledGalleryCollectionsItem
