
export type NotificationState = {
  data: Notification[];
  zIndex: number;
}

export type NotificationType = 'default' | 'success' | 'error'
export type Notification = {
  message: string;
  type: NotificationType;
  interval?: number;
  expire?: number;
  key: string;
}

export const NOTIFICATION_ADD = 'NOTIFICATION_ADD'
export type NotificationAddAction = {
  type: typeof NOTIFICATION_ADD;
  payload: Notification;
}

export const NOTIFICATION_REMOVE = 'NOTIFICATION_REMOVE'
export type NotificationRemoveAction = {
  type: typeof NOTIFICATION_REMOVE;
  payload: string;
}

export const NOTIFICATION_REMOVE_ALL = 'NOTIFICATION_REMOVE_ALL'
export type NotificationRemoveAllAction = {
  type: typeof NOTIFICATION_REMOVE_ALL;
}

export type NotificationActions =
  NotificationAddAction | NotificationRemoveAction | NotificationRemoveAllAction
