import React, { FC } from 'react'
import { StyledIcon } from './index'

type Props = {
  color?: string;
  size: number;
  className?: string;
}

const IconIntercomOutline: FC<Props> = ({ color, size, className }) => {
  return (
    <StyledIcon color={color} size={size} className={className}>
      <svg role="img" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ overflow: 'visible', marginTop: '-6px', marginLeft: '-7px' }}>
        <path d="M19.394 24.08l-.189-.08H9a2 2 0 01-2-2V9a2 2 0 012-2h14a2 2 0 012 2v17.483l-5.606-2.402z"  stroke="currentcolor" strokeWidth={2}></path>
        <path d="M11 18s1.75 1.5 5 1.5 5-1.5 5-1.5" strokeLinecap="round" stroke="currentcolor" strokeWidth={2}></path>
      </svg>
    </StyledIcon>
  )
}

export default IconIntercomOutline
