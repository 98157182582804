import { combineReducers } from 'redux'
import { issueFormReducer as issueForm } from './issue-form/reducer'
import { shoppingCartReducer as shoppingCart } from './shopping-cart/reducer'
import { galleryFormReducer as galleryForm } from './gallery-form/reducer'
import { notificationReducer as notification } from './notifications/reducer'
import { galleryReducer as gallery } from './gallery/reducer'
import { actionReducer as dailyActions } from './daily-actions/reducer'
import { dailyActionFormReducer as dailyActionForm } from './daily-actions-form/reducer'
import { CommunityEventFormReducer as communityEventForm } from './community-form/reducer'
import { captionLibraryReducer as captions } from './caption-library/reducer'
import { captionLibraryReducer as issueList } from './issue-list/reducer'
import { draftReducer as draft } from './draft-drawer/reducer'
import { pricingReducer as pricing } from './pricing/reducer'
import { affiliateTrackReducer as affiliate } from './affiliate-track/reducer'
import { draftsReducer as draftsList } from './drafts-list/reducer'
import { challengeReducer as challenge } from './challenge/reducer'

export const rootReducer = combineReducers({
  issueForm,
  notification,
  shoppingCart,
  dailyActions,
  dailyActionForm,
  communityEventForm,
  galleryForm,
  gallery,
  captions,
  issueList,
  draft,
  draftsList,
  pricing,
  affiliate,
  challenge
})

export type AppState = ReturnType<typeof rootReducer>
