import styled, { keyframes, css } from 'styled-components'
import { theme } from '../utils/theme'

const likeAnim = keyframes`
  0% {
    transform: scale(0.3);
    fill: ${theme.colors.red[600]};
    color: ${theme.colors.red[600]};
  }
  60% {
    transform: scale(1.2);
    fill: ${theme.colors.red[600]};
    color: ${theme.colors.red[600]};
  }
  80% {
    transform: scale(0.9);
    fill: ${theme.colors.red[600]};
    color: ${theme.colors.red[600]};
  }
  100% {
    transform: scale(1);
    fill: ${theme.colors.red[600]};
    color: ${theme.colors.red[600]};
  }
`

type StyledProps = {
  isLiked?: boolean;
}

const StyledGalleryPhotoItemActions = styled.div<StyledProps>`
  position: absolute;
  pointer-events: none;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  z-index: 1;

  .actions-row {
    display: flex;
    align-items: center;
    right: 12px;
    top: 12px;
    position: absolute;

    .new-label {
      font-weight: 600;
      align-items: center;
      font-size: 14px;
      line-height: 24px;
      flex-shrink: 0;
      height: 28px;
      padding: 0 8px;
      user-select: none;
      pointer-events: none;
      border-radius: 6px;
      display: flex;
      letter-spacing: 0.02rem;
      justify-content: center;
      overflow: hidden;
      background-color: ${({ theme }) => theme.colors.green[500]};
      color: ${({ theme }) => theme.colors.background};
      box-shadow: inset 0 0 0 1px #00000021;
    }

    button {
      margin-left: 6px;
    }
  }

  button {
    height: 34px;
    display: flex;
    position: absolute;
    align-items: center;
    border: none;
    box-shadow: unset;
    background-color: transparent;
    padding: 0;
    transition: 0.2s;
    pointer-events: all;
    user-select: none;
    border-radius: 6px;

    &:hover {
      cursor: pointer;
    }
  }

  .admin-btn {
    height: 28px;
    width: 28px;
    border-radius: 6px;
    display: flex;
    position: absolute;
    top: 12px;
    left: 12px;
    align-items: center;
    justify-content: center;
    user-select: none;
    border: 0;
    padding: 0;
    transition: background-color 0.2s;
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.gray[600]};
    box-shadow: 0 0 0 1px #00000021;

    svg {
      height: 20px;
    }

    &:hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.gray[200]};
    }
  }

  .use-btn,
  .delete-btn {
    padding: 0 10px;
    left: 12px;
    bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    user-select: none;
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.gray[600]};
    box-shadow: 0 0 0 1px #00000021;

    span {
      position: relative;
      top: 1px;
    }

    svg {
      height: 18px;
      margin-right: 8px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.gray[200]};
    }
  }

  .delete-btn {
    top: 12px;
    opacity: 0;
    left: unset;
    bottom: unset;
    border: 0;
    right: 12px;
    height: 34px;
    width: 34px;
    padding: 0;
    border-radius: 6px;
    color: ${({ theme }) => theme.colors.background};
    background-color: ${({ theme }) => theme.colors.foreground}80;

    svg {
      height: 26px;
      margin: 0;
      filter: ${({ isLiked }) => (isLiked ? 'none' : 'drop-shadow(0 0 1px #0000009c)')};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.foreground}a4;
    }
  }

  .fav-btn {
    width: 34px;
    right: 12px;
    bottom: 12px;
    justify-content: center;
    color: ${({ theme }) => theme.colors.background};

    svg {
      height: 26px;
      filter: ${({ isLiked }) => (isLiked ? 'none' : 'drop-shadow(0 0 1px #0000009c)')};

      ${({ isLiked }) =>
    isLiked &&
        css`
          animation: ${likeAnim} forwards 0.2s ease-in-out;
        `}

      path {
        stroke-width: 1.7px;
      }
    }

    &:hover {
      background-color: ${({ isLiked, theme }) =>
    (isLiked ? `${theme.colors.red[600]}20` : `${theme.colors.background}50`)};
    }
  }
`

export default StyledGalleryPhotoItemActions
