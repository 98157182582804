import React, { FC, useEffect } from 'react'
import { IssueModalGallery, StyledIssueModalItem } from '.'
import { IssueVideo } from '..'
import { ExtraSetFindMany_extraSetFindMany } from '../../../graphql/ExtraSet/__generated__/ExtraSetFindMany'
import useSegment from '../../utils/useSegment'
import { GetIssueBySlug_issueFindOne } from '../../../graphql/Issue/__generated__/GetIssueBySlug'

type Props = {
  issue: GetIssueBySlug_issueFindOne;
  extra: ExtraSetFindMany_extraSetFindMany;
  setFullScreen: (isFullScreen: boolean) => void;
  isFullScreen: boolean;
}

const IssueModalExtra: FC<Props> = ({
  issue, extra, setFullScreen, isFullScreen
}) => {
  const { track } = useSegment()

  useEffect(() => {
    track('Viewed Extra', {
      id: extra._id,
      title: extra.title,
      url: extra.thumbnail?.fileHttpLink,
      index: extra.index,
      issue_id: issue._id,
      issue_title: issue.title,
      from: 'issue'
    })
  }, [])

  return (
    <StyledIssueModalItem itemType="extra">
      <div className="issue-modal-grid">
        <div className="issue-modal-grid-item">
          {extra.contentType === 'video' && extra.videoLink && (
            <div className="issue-modal-player">
              <IssueVideo standalone videoUrl={extra.videoLink} />
            </div>
          )}
          {extra.contentType === 'file' && extra.file?.fileHttpLink && (
            <div className="issue-modal-iframe">
              <iframe src={extra.file?.fileHttpLink} />
            </div>
          )}
          {extra.contentType === 'image' && (
            <IssueModalGallery
              setFullScreen={setFullScreen}
              isFullScreen={isFullScreen}
              modalType="extra"
              data={extra}
              issue={issue}
            />
          )}
        </div>
      </div>
    </StyledIssueModalItem>
  )
}

export default IssueModalExtra
