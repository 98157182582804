import styled from 'styled-components'

const StyledTagManager = styled.div`
  z-index: 100000;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background: white;

  a {
    text-decoration: underline;
    color: blue;
    font-size: 25px;
    font-style: italic;
    text-align: center;
    display: block;
  }

  img {
    display: block;
    max-width: 800px;
  }
`

export default StyledTagManager
