import React, { FC, ReactNode } from 'react'
import classNames from 'classnames'
import { StyledRow } from '.'

type Props = {
  columns?: number;
  className?: string;
  children?: ReactNode;
  isFlex?: boolean;
  isSticky?: boolean;
}

const Row: FC<Props> = ({
  columns = 1, className, children, isFlex, isSticky
}) => {
  return (
    <StyledRow
      isFlex={isFlex}
      isSticky={isSticky}
      className={classNames('row', className)}
      columns={columns}
    >
      {children}
    </StyledRow>
  )
}

export default Row
