import styled from 'styled-components'

const StyledStoryModal = styled.div`
  max-width: 1200px;
  width: 100vw;

  .modal-set-download-button {
    display: none;
  }

  .modal-heading {
    display: flex;
    padding: 36px;
    justify-content: space-between;
  }

  .modal-sub-title {
    font-weight: 600;
    display: block;
    font-size: 14px;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.colors.gray[500]};
    margin-bottom: 6px;
    text-transform: uppercase;
  }

  .modal-title {
    display: block;
    font-size: 24px;
    font-weight: 700;
  }

  .close-modal-button {
    display: none;
  }

  @media (max-width: ${({ theme }) => theme.size.sm}px) {
    .close-modal-button {
      display: flex;
      margin: 0;
    }

    .modal {
      &.issue {
        .title-block {
          padding: 20px;
        }
      }
    }
  }

  .modal-grid {
    display: grid;
    grid-template-columns: ${({ itemType }) => (itemType !== 'extra' ? 'auto 430px' : '100%')};
    grid-gap: 2rem;
    margin: 0px 36px 36px 36px;
  }

  .modal-grid-item {
    display: grid;
    grid-gap: 2rem;
    align-items: start;
    align-self: start;
    display: grid;
    overflow-y: auto;
  }

  .modal-grid-item:first-child {
    display: block;
    height: 100%;
  }

  .grid-box {
    border: 1px solid ${({ theme }) => theme.colors.gray[200]};
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
  }

  .grid-box-header {
    padding: 0 30px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 75px;

    button {
      height: 26px;
      width: 26px;
      background: rgb(244, 244, 244);
      border: 0px;
      padding: 0px;
      color: rgb(196, 196, 196);
      display: flex;
      align-items: center;
      justify-content: center;

      :not(:last-child) {
        margin-right: 10px;
      }

      svg {
        height: 18px;
        width: 18px;
      }

      &:hover {
        color: rgb(121, 121, 120);
      }
    }
  }

  .grid-box-header-title {
    font-weight: 700;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.gray[800]};
  }

  .grid-box-content {
    padding: 30px;
    max-height: 250px;
    box-sizing: border-box;
    overflow-y: auto;
  }

  .grid-box-content * {
    margin: 0;
    line-height: 23px;
    overflow-wrap: break-word;
  }

  .grid-box-content p:not(:last-child) {
    margin-bottom: 1rem;
  }

  .grid-box-content::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray[500]};
    border-radius: 50rem;
    border: 2px solid #ebebeb;
  }

  .grid-box-content::-webkit-scrollbar {
    width: 10px;
    background: #ebebeb;
    border-radius: 50rem;
  }

  @media (max-width: ${({ theme }) => theme.size.sm}px) {
    .modal-grid {
      grid-template-columns: 100%;
      margin: 0px 20px 20px 20px;
    }

    .grid-box-content {
      max-height: unset;
      padding: 13px;
    }

    .grid-box-header {
      padding: 0 13px;
      height: 50px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xs}px) {
    .modal-set-download-button {
      display: flex;
    }
  }
`

export default StyledStoryModal
