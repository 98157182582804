import styled from 'styled-components'

type StyleProps = {
  type: string;
  size?: number;
}

const StyledSkeletonLoader = styled.div<StyleProps>`
  ${({ type, theme }) =>
    type === 'issue-list-item' &&
    `
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 54px;
    row-gap: 20px;
    margin-bottom: 58px;

    div:first-child {
      span {
        display: block;
        border-radius: 3px;
        animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

        &:nth-of-type(1n) {
          height: 30px;
        }

        &:nth-of-type(2n) {
          height: 20px;
          margin-top: 8px;
        }

        &:nth-of-type(3n) {
          height: 50px;
          margin-top: 23px;
        }
      }
    }

    div:last-child {
      padding-top: 56.25%;
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
      border-radius: 8px;
      grid-row: 1;
    }

    @media (min-width: ${theme.size.sm}px) {
      grid-template-columns: auto 350px;

      div:last-child {
        grid-row: unset;
      }
    }
  `}

  ${({ type }) =>
    type === 'caption-list-item' &&
    `
    padding-top: 56.25%;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    border-radius: 10px;
  `}

  ${({ type }) =>
    type === 'collection-list-item' &&
    `
    height: 67px !important;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    border-radius: 6px;
  `}

  ${({ type }) =>
    type === 'story-list-item' &&
    `
    padding-top: 177%;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    border-radius: 3px;
  `}

  ${({ type, size }) =>
    type === 'square-list-item' &&
    `
    ${!size ? `padding-top: 100%;` : ''}
    ${
  size
    ? `
      width: ${size}px;
      height: ${size}px;
    `
    : ''
}
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    border-radius: 3px;
  `}

  ${({ theme, type }) =>
    type === 'lesson' &&
    `
    margin-top: 1rem;

    & > div:nth-of-type(1) {
      padding-top: 56.25%;
      border-radius: 3px;
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    }

    & > div:nth-of-type(2) {
      justify-content: space-between;
      display: flex;
      margin: 25px 0;

      & > div:nth-of-type(1) {
        height: 46px;
        max-width: 300px;
        width: 100%;
        border-radius: 3px;
        animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
      }

      & > div:nth-of-type(2) {
        height: 46px;
        width: 192px;
        border-radius: 3px;
        animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
      }
    }

    & > div:nth-of-type(3) { 
      height: 1px;
      width: 100%;
      animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
      margin-bottom: 25px;
    }

    & > div:nth-of-type(4) {
      display: grid;
      grid-gap: 25px;
      grid-template-columns: minmax(0, 1fr);

      & > div:nth-of-type(1) {
        height: 200px;
        max-width: 650px;
        border-radius: 3px;
        animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
      }
     
      & > div:nth-of-type(2) {
        height: 46px;
        width: 215px;
        border-radius: 3px;
        animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
      }
    }

    @media (min-width: ${theme.breakpoints.m}px) {
      & > div:nth-of-type(4) {
        grid-gap: 70px;
        grid-template-columns: minmax(0, 1fr) 215px;
      }
    }
  `}

  @keyframes pulse {
    0% {
      background: ${({ theme }) => theme.colors.gray[100]};
    }
    50% {
      background: ${({ theme }) => theme.colors.gray[200]};
    }
    100% {
      background: ${({ theme }) => theme.colors.gray[100]};
    }
  }
`

export default StyledSkeletonLoader
