import styled from 'styled-components'

type StyledProps = {
  hasIcon: boolean;
  hasValue: boolean;
  hasInlineLabel: boolean;
  isMulti?: boolean;
  isError?: boolean;
}

const StyledLineInput = styled.div<StyledProps>`
  .input-container {
    position: relative;
    display: flex;
    align-items: center;

    .input-container-border-group {
      position: absolute;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      top: 0;
      bottom: 0;
      pointer-events: none;

      .input-container-border {
        border-color: ${({ theme }) => theme.colors.gray[300]};
        border-width: 1px;
        border-style: solid;
        transition: border-color 0.2s;

        &.left {
          width: 12px;
          border-right: 0;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }

        &.label {
          flex: 0 0 auto;
          width: auto;
          max-width: calc(100% - 12px * 2);
          display: flex;
          align-items: center;
          border-top-width: ${({ hasValue }) => (hasValue ? '0' : '1')}px;
          border-right: 0;
          border-left: 0;

          label {
            color: ${({ theme }) => theme.colors.gray[300]};
            font-weight: 500;
            font-size: 14px;
            user-select: none;
            position: relative;
            padding: 0 5px;
            transition: 0.2s;

            ${({ hasValue }) =>
    hasValue &&
              `
              color: ${({ theme }) => theme.colors.gray[300]};
              font-weight: 600;
              letter-spacing: 0.01rem;
              top: -20px;
              font-size: 13px;
            `}
          }
        }

        &.right {
          width: 100%;
          border-left: 0;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }
    }

    .input-icon {
      position: absolute;
      height: 20px;
      width: 20px;
      left: 9px;
      color: ${({ theme }) => theme.colors.gray[300]};
      pointer-events: none;
    }

    input,
    textarea {
      color: ${({ theme }) => theme.colors.gray[800]};
      font-weight: 500;
      font-size: 14px;
      padding: ${({ hasIcon }) => (hasIcon ? '0 15px 0 40px' : '0 15px')};
      border: none;
      width: 100%;
      letter-spacing: 0.02rem;
      height: 40px;
      background: ${({ theme, hasInlineLabel }) =>
    (hasInlineLabel ? 'transparent' : theme.colors.background)};
      border-radius: 3px;
      outline: none;
      transition: border-color 0.2s;

      &::-webkit-calendar-picker-indicator {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        padding: 0;
        margin: 0;
        cursor: pointer;
        background: none;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-text-fill-color: ${({ theme }) => theme.colors.blue[800]};
        box-shadow: 0 0 0px 1000px ${({ theme }) => theme.colors.blue[100]} inset;
        font-family: ${({ theme }) => theme.fonts.nunito};
        font-weight: 500;
        font-size: 14px;
        transition: background-color 5000s ease-in-out 0s;

        & + .input-container-border-group .input-container-border {
          border-color: ${({ theme }) => theme.colors.blue[600]} !important;
        }
      }

      &:-webkit-autofill::first-line {
        font-family: ${({ theme }) => theme.fonts.nunito};
        font-weight: 500;
        font-size: 14px;
        color: ${({ theme }) => theme.colors.blue[700]};
      }

      & + .input-container-border-group .input-container-border {
        ${({ isError }) =>
    isError &&
          `
          border-color: #B93619 !important;
        `}

        label {
          ${({ isError, hasValue }) =>
    isError &&
            hasValue &&
            `
            color: #B93619 !important;
          `}
        }
      }

      &:hover,
      &:focus {
        & + .input-container-border-group .input-container-border {
          border-color: ${({ theme }) => theme.colors.gray[500]};

          &.label label {
            ${({ theme, hasValue }) =>
    hasValue &&
              `
              color: ${theme.colors.gray[500]};
            `}
          }
        }
      }

      &::placeholder {
        color: ${({ theme }) => theme.colors.gray[300]};
      }

      &:disabled {
        cursor: not-allowed;
        background: ${({ theme }) => theme.colors.gray[100]};
        color: ${({ theme }) => theme.colors.gray[500]};
        border-color: transparent;
      }
    }

    textarea {
      padding: 15px;
      min-height: 100px;
      resize: none;
    }

    .clear-btn {
      z-index: 1;
      position: absolute;
      right: 9px;
      height: 22px;
      width: 22px;
      border-radius: 50%;
      color: ${({ theme }) => theme.colors.gray[300]};

      &:hover {
        color: ${({ theme }) => theme.colors.gray[500]};
      }

      svg {
        height: 20px;
      }
    }
  }
`

export default StyledLineInput
