import React, { FC, createRef, useEffect, useState } from 'react'
import classNames from 'classnames'
import Select, { components } from 'react-select'
import Creatable from 'react-select/creatable'
import { ChevronDownIcon, XIcon } from '@heroicons/react/outline'
import { StyledDropdownSelect } from './Dropdown.Styled'
import { StyledDropdown } from '.'

type Props = {
  placeholder?: string;
  type?: 'border' | 'no-border';
  options: { value: string; label: string }[];
  value?: string | string[] | null | { value: string; label: string }[];
  className?: string;
  name?: string;
  id?: string;
  isDisabled?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  isSelectable?: boolean;
  isCreatable?: boolean;
  isMulti?: boolean;
  isColor?: boolean;
  onChange?: (param?: any) => void;
}

const Dropdown: FC<Props> = ({
  placeholder,
  type = 'border',
  options,
  value,
  className,
  name,
  id,
  isDisabled,
  isClearable = true,
  isSearchable = false,
  isSelectable = true,
  isCreatable,
  isMulti = false,
  isColor,
  onChange
}) => {
  const dropdownRef = createRef<HTMLDivElement>()

  const getValueAsArray = () => {
    if (Array.isArray(value)) {
      return options.filter((option) => (value as string[])?.includes(option.value))
    }
    return options.filter((option) => value === option.value)
  }

  const [currVal, setCurrVal] = useState<{ value: string; label: string }[] | null>(
    getValueAsArray() || null
  )

  const handleChange = (selected: any) => {
    if (isSelectable) {
      setCurrVal(selected)
    }
    if (onChange) {
      onChange(selected || '')
    }
  }

  useEffect(() => {
    if (!value) {
      return setCurrVal(null)
    }

    setCurrVal(getValueAsArray())
  }, [value])

  return (
    <StyledDropdown id={id} ref={dropdownRef} className={classNames('dropdown', className)}>
      {!isCreatable && (
        <Select
          value={currVal}
          name={name}
          placeholder={placeholder}
          options={options}
          isClearable={isClearable}
          isSearchable={isSearchable}
          components={{ Option, ClearIndicator, DropdownIndicator }}
          styles={StyledDropdownSelect()}
          onChange={handleChange}
          isCreatable={isCreatable}
          isMulti={isMulti}
          isColor={isColor}
          borderType={isDisabled ? 'no-border' : type}
          isDisabled={isDisabled}
        />
      )}
      {isCreatable && currVal && (
        <Creatable
          value={currVal[0] || null}
          name={name}
          placeholder={placeholder}
          options={options}
          isClearable={isClearable}
          components={{ Option, ClearIndicator, DropdownIndicator }}
          styles={StyledDropdownSelect()}
          onChange={handleChange}
          borderType={type}
          isDisabled={isDisabled}
        />
      )}
    </StyledDropdown>
  )
}

const Option = ({
  isSelected, value, label, ...props
}: any, context) => {
  return (
    <components.Option {...props} {...context}>
      {props.selectProps.isColor && (
        <div
          className="color-bubble"
          style={{
            background:
              value === 'multi'
                ? 'linear-gradient(0deg, #ff0000, #007eff, #00ffc9, #ffd400, #ff0000)'
                : value
          }}
        />
      )}
      {label}
    </components.Option>
  )
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon />
    </components.DropdownIndicator>
  )
}

const ClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator {...props}>
      <XIcon />
    </components.ClearIndicator>
  )
}

export default Dropdown
