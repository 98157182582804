import { SearchUserDrafts_searchUserDrafts_docs } from '../../graphql/UserDraft/__generated__/SearchUserDrafts'
import { CAPTION_CATEGORY_LIST } from '../../types'

export type DraftSort = 'created' | 'modified' | 'custom' | 'uploads'

export type DraftsState = {
  drafts: SearchUserDrafts_searchUserDrafts_docs[];
  search: string;
  categories: typeof CAPTION_CATEGORY_LIST[number][];
  hideUsed: boolean;
  onlyLiked: boolean;
  sort: DraftSort;
  page: number;
  loading: boolean;
}

export const DRAFTS_SET_DRAFTS = 'DRAFTS_SET_DRAFTS'
export type DraftsSetDrafts = {
  type: typeof DRAFTS_SET_DRAFTS;
  payload: SearchUserDrafts_searchUserDrafts_docs[];
}

export const DRAFTS_ADD_DRAFTS = 'DRAFTS_ADD_DRAFT'
export type DraftsAddDraft = {
  type: typeof DRAFTS_ADD_DRAFTS;
  payload: SearchUserDrafts_searchUserDrafts_docs[];
}

export const DRAFTS_DELETE_DRAFT = 'DRAFTS_DELETE_DRAFT'
export type DraftsDeleteDraft = {
  type: typeof DRAFTS_DELETE_DRAFT;
  payload: string;
}

export const DRAFTS_SET_SEARCH = 'DRAFTS_SET_SEARCH'
export type DraftsSetSearch = {
  type: typeof DRAFTS_SET_SEARCH;
  payload: string;
}

export const DRAFTS_HIDE_USED = 'DRAFTS_HIDE_USED'
export type DraftsHideUsed = {
  type: typeof DRAFTS_HIDE_USED;
  payload: boolean;
}

export const DRAFTS_ONLY_LIKED = 'DRAFTS_ONLY_LIKED'
export type DraftsOnlyLiked = {
  type: typeof DRAFTS_ONLY_LIKED;
  payload: boolean;
}

export const DRAFTS_SET_SORT = 'DRAFTS_SET_SORT'
export type DraftsSetSort = {
  type: typeof DRAFTS_SET_SORT;
  payload: DraftSort;
}

export const DRAFTS_SET_PAGE = 'DRAFTS_SET_PAGE'
export type DraftsSetPage = {
  type: typeof DRAFTS_SET_PAGE;
  payload: number;
}

export const DRAFTS_SET_CATEGORIES = 'DRAFTS_SET_CATEGORIES'
export type DraftsSetCategories = {
  type: typeof DRAFTS_SET_CATEGORIES;
  payload: typeof CAPTION_CATEGORY_LIST[number];
}

export const DRAFTS_CLEAR_CATEGORIES = 'DRAFTS_CLEAR_CATEGORIES'
export type DraftsClearCategories = {
  type: typeof DRAFTS_CLEAR_CATEGORIES;
}

export const DRAFTS_LOAD_SEARCH = 'DRAFTS_LOAD_SEARCH'
export type DraftsLoadSearch = {
  type: typeof DRAFTS_LOAD_SEARCH;
  payload: any;
}

export type DraftsActions =
  | DraftsAddDraft
  | DraftsSetDrafts
  | DraftsDeleteDraft
  | DraftsSetSearch
  | DraftsHideUsed
  | DraftsOnlyLiked
  | DraftsSetSort
  | DraftsSetPage
  | DraftsSetCategories
  | DraftsClearCategories
  | DraftsLoadSearch
