import React, { FC, ReactNode } from 'react'
import classNames from 'classnames'
import styled from 'styled-components'

type Props = {
  noPadding?: boolean;
  noHeight?: boolean;
  className?: string;
  maxWidth?: number;
  isGray?: boolean;
  children?: ReactNode;
}

const WidthController: FC<Props> = ({
  noPadding,
  noHeight,
  className,
  maxWidth,
  isGray,
  children
}) => {
  return (
    <StyledWidthController
      className={classNames('width-controller-container', className)}
      noPadding={noPadding}
      noHeight={noHeight}
      maxWidth={maxWidth}
      isGray={isGray}
    >
      <div className="width-controller">{children}</div>
    </StyledWidthController>
  )
}

export default WidthController

const StyledWidthController = styled.div<Props>`
  min-height: ${({ noHeight }) => (noHeight ? 0 : 'calc(100vh - 50px)')};
  padding: ${({ noPadding }) => (noPadding ? '0' : '60px 0')};
  ${({ isGray, theme }) => isGray && `background-color: ${theme.colors.gray[100]};`}

  .width-controller {
    max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '1144px')};
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
`
