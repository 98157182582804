import styled from 'styled-components'

const StyledDraftDrawer = styled.div`
  min-height: 100vh;
  min-height: fill-available;
  min-height: -webkit-fill-available;
  overscroll-behavior: none;
  width: 100vw;
  display: grid;
  z-index: ${({ theme }) => theme.elevations.override};
  position: relative;
  grid-template-rows: minmax(0, 1fr) auto;

  *::-webkit-scrollbar {
    display: none;
  }

  .draft-editor-img-error {
    display: grid;
    grid-template-columns: 24px minmax(0, 1fr);
    grid-gap: 12px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
    margin: 20px;
    background-color: ${({ theme }) => theme.colors.red[100]};
    color: ${({ theme }) => theme.colors.red[600]};
    padding: 12px;
    border-radius: 6px;
  }

  .draft-drawer-top {
    display: grid;
    overflow-y: auto;
    grid-template-rows: auto auto minmax(auto, 1fr) auto;
    overscroll-behavior: none;
  }

  .draft-drawer-actions {
    display: flex;
    padding: 20px;
    display: grid;
    grid-template-columns: 125px 1fr;
    grid-gap: 10px;
    border-top: 1px solid ${({ theme }) => theme.colors.gray[300]};

    .btn {
      height: 50px;
      width: 100%;
      padding: 0;
      font-size: 1rem;
      border-radius: 6px;
      color: ${({ theme }) => theme.colors.gray[600]};

      &.save-btn {
        background-color: ${({ theme }) => theme.colors.green[500]};
        color: ${({ theme }) => theme.colors.background};

        .beta-flag {
          padding: 4px 5px 3px 5px;
          color: ${({ theme }) => theme.colors.green[500]};
          background-color: ${({ theme }) => theme.colors.green[100]};
          border-radius: 3px;
          margin-left: 8px;
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 0.05rem;
        }
      }

      &:disabled {
        background-color: ${({ theme }) => theme.colors.gray[100]};
        color: ${({ theme }) => theme.colors.gray[300]};
        cursor: not-allowed;
        border-color: transparent;

        .beta-flag {
          color: ${({ theme }) => theme.colors.gray[400]};
          background-color: ${({ theme }) => theme.colors.background};
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => `${theme.breakpoints.s}px`}) {
    width: 400px;
  }
`

export default StyledDraftDrawer
