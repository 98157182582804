import React, { FC, cloneElement, isValidElement, ReactNode } from 'react'
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { StyledLabelWrapper } from '.'

type Props = {
  label?: string;
  tip?: string;
  errorMessage?: string;
  errorIsInline?: boolean;
  isValid?: boolean;
  className?: string;
  children?: ReactNode | ReactNode[];
}

const LabelWrapper: FC<Props> = ({
  label,
  tip,
  errorMessage,
  errorIsInline,
  isValid,
  className,
  children
}) => {
  return (
    <StyledLabelWrapper className={classNames(className, 'label-wrapper')}>
      {label && (
        <div className="input-heading">
          <div className="input-label">{label}</div>
          {errorIsInline && errorMessage && (
            <div className="input-message input-error">
              <ExclamationCircleIcon />
              {errorMessage}
            </div>
          )}
          {!errorMessage && isValid && (
            <div className="input-message input-valid">
              <CheckCircleIcon />
            </div>
          )}
        </div>
      )}
      {/* If the wrapped element is a valid JSX element, then clone it and set isError on that element to if there is an error message or not */}
      {isValidElement(children) && cloneElement(children, { isError: !!errorMessage })}
      {/* If the wrapped element is not a valid JSX element, then display the element */}
      {!isValidElement(children) && children}
      {!errorIsInline && !errorMessage && tip && (
        <div className="input-tip">
          <span>{tip}</span>
        </div>
      )}
      {!errorIsInline && errorMessage && (
        <div className="input-message input-error lower">
          <ExclamationCircleIcon />
          {errorMessage}
        </div>
      )}
    </StyledLabelWrapper>
  )
}

export default LabelWrapper
