import styled from 'styled-components'

const StyledDashboardTile = styled.div`
  margin-top: 30px;

  .dashboard-tile-heading {
    display: flex;
    align-items: end;
    justify-content: space-between;
    margin-bottom: 14px;

    .dashboard-tile-title {
      font-size: 17px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.gray[700]};
    }

    a {
      font-size: 15px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.gray[500]};

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.s}px) {
    margin-top: 45px;
  }
`

export default StyledDashboardTile
