import styled from 'styled-components'

const StyledRecentDraftsModal = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 20px 20px 0 0;
  position: relative;
  box-sizing: border-box;
  max-height: 80vh;
  overflow-y: auto;
  padding: 0 0 calc(max(env(safe-area-inset-bottom), 10px) + 25px) 0;

  .empty-state {
    padding: 65px 0;
  }

  .recent-drafts-header {
    padding: 18px 25px;
    position: sticky;
    top: 0;
    width: 100%;
    text-align: center;
    font-weight: 600;
    z-index: ${({ theme }) => theme.elevations.fixed};
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.gray[800]};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[300]};
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  @media (min-width: ${({ theme }) => `${theme.breakpoints.s}px`}) {
    border-radius: 20px;
    width: 400px;
    overflow: unset;
    padding: 0;

    ul {
      max-height: 500px;
      overflow-y: auto;
    }
  }
`

export default StyledRecentDraftsModal
