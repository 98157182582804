import React, { FC, lazy, useContext } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import ROUTES from './utils/routes'
import { PrivateRoute } from './PrivateRoute'

import { AppLayout } from './AppLayout'
import { AdminIssueHeader } from './Admin/AdminIssueHeader'
import { Intercom } from './Intercom'
import { FourOhFour, FiveHundred, NotLoggedIn } from './Errors'
import { AsciiArt } from './AsciiArt'
import { SplitContext } from './utils/SplitContext'
import { LeadForm } from './LeadForm'
import { MissingInfoModal } from './Modals/MissingInfoModal'
import { AccountBilling, AccountSettings, SubscriptionSelectPage, PaymentPage } from './Account'
import { UpgradeCTAModal, PlatformPreviewModal, ExpiredCTAModal } from './Modals'
import { Milk } from './Milk'
import { Logout } from './User'
import { AboutPage } from './AboutPage'
import { IssueContainer as IssueView } from './IssueView'
import { IssueListView } from './IssueListView'
import { Dashboard } from './Dashboard'
import { CaptionLibrary } from './CaptionLibraryView'
import { DraftsView } from './DraftsView'
import { CourseLibraryView } from './CourseLibraryView'
import { LessonView } from './LessonView'
import { CoursePreviewView } from './CoursePreviewView'
import { CheckoutView } from './CheckoutView'
import { ReceiptView } from './ReceiptView'
import { RegisterView } from './RegisterView'
import { SignInView } from './SignInView'
import { ForgotPasswordModal } from './Modals/ForgotPasswordModal'
import { ResetPasswordView } from './ResetPasswordView'
import { CourseRegisterView } from './CourseRegisterView'
import { OnboardingView } from './InformationalOnboarding'
import { AffiliateTrack } from './Affiliates/AffiliateTrack'
import { AffiliateDashboard } from './Affiliates/AffiliateDashboard'
import { TagManager } from './TagManager'
import { StoriesView } from './StoriesView'
import { Challenge } from './Challenge'
import { NewPostTour } from './tours'
import { CommunityView } from './CommunityView'

const AdminLayout = lazy(() => import('./Admin/AdminLayout/AdminLayout'))
const Gallery = lazy(() => import('./GalleryView/GalleryView'))

const AppRouter: FC = () => {
  const {
    isReady,
    isEnabled,
    treatments: {}
  } = useContext(SplitContext)

  return (
    <Router>
      <Intercom />
      <Milk />
      <AsciiArt />
      <MissingInfoModal />
      <ExpiredCTAModal />
      <UpgradeCTAModal />
      <PlatformPreviewModal />
      <OnboardingView />
      <TagManager />
      <Challenge />

      <Switch>
        <Route
          path="/admin/login"
          component={SignInView}
        />
        <PrivateRoute
          userRole={['admin']}
          path="/admin/:path?"
          isLazyLoaded
          component={AdminLayout}
        />

        

        {/* <Route path={ROUTES.affiliate} component={AffiliateTrack} exact />

        <Route path={ROUTES.user.signup} component={RegisterView} exact />
        <Route path={ROUTES.user.signupCourse} component={CourseRegisterView} />
        <Route path={[ROUTES.user.resetPassword, ROUTES.user.login]}>
          <Route path={ROUTES.user.resetPassword} component={ForgotPasswordModal} />
          <SignInView />
        </Route>
        <Route path={ROUTES.user.confirmCode} exact component={ResetPasswordView} /> */}

        <Route path={ROUTES.leads.form} component={LeadForm} />

        <Route path='*' component={() => {
          window.location.href = `https://app.socialcurator.com/returning?redirect=${encodeURIComponent(window.location.href.replace('hello.', 'app.'))}&old=true`
          return null
        }}/>

        {/* <Route path={ROUTES.user.logout} component={Logout} />

        <Route path={ROUTES.errors.fiveHundred} component={FiveHundred} />
        <Route path={ROUTES.errors.notFound} component={FourOhFour} />
        <Route path={ROUTES.errors.notLoggedIn} component={NotLoggedIn} />

        <PrivateRoute
          userRole={['admin', 'basic', 'course']}
          path={ROUTES.dashboard.community}
          component={CommunityView}
        />

        <Route path="*">
          <AppLayout>
            <NewPostTour>
              <Switch>
                <PrivateRoute
                  userRole={['admin', 'basic']}
                  path={ROUTES.dashboard.home}
                  exact
                  component={Dashboard}
                />
                <PrivateRoute
                  userRole={['admin', 'basic']}
                  path={ROUTES.captions}
                  component={CaptionLibrary}
                  exact
                />
                <PrivateRoute
                  userRole={['admin', 'basic']}
                  path={[ROUTES.stories.story, ROUTES.stories.list]}
                  component={StoriesView}
                  exact
                />
                <PrivateRoute
                  userRole={['admin', 'basic']}
                  path={[
                    ROUTES.gallery.main,
                    ROUTES.gallery.favorites,
                    ROUTES.gallery.collectionPage,
                    ROUTES.gallery.collections,
                    ...ROUTES.gallery.photoPage
                  ]}
                  component={Gallery}
                  isLazyLoaded
                  exact
                />
                <PrivateRoute
                  userRole={['admin', 'basic']}
                  exact
                  path={ROUTES.dashboard.drafts}
                  component={DraftsView}
                />
                <PrivateRoute
                  userRole={['admin', 'basic']}
                  exact
                  path={ROUTES.dashboard.issues}
                  component={IssueListView}
                />
                <PrivateRoute
                  userRole={['admin', 'basic', 'course']}
                  path={ROUTES.dashboard.courses}
                  component={CourseLibraryView}
                  exact
                />
                <PrivateRoute
                  userRole={['admin', 'basic', 'course']}
                  path={ROUTES.checkout}
                  component={CheckoutView}
                  exact
                />
                <PrivateRoute
                  userRole={['admin', 'basic', 'course']}
                  path={ROUTES.receipt}
                  component={ReceiptView}
                />
                <PrivateRoute
                  userRole={['admin', 'basic', 'course']}
                  path={ROUTES.user.settings.profile}
                  exact
                  component={AccountSettings}
                />
                <PrivateRoute
                  userRole={['admin', 'basic', 'course']}
                  path={ROUTES.user.settings.billing}
                  exact
                  component={AccountBilling}
                />
                <PrivateRoute
                  userRole={['admin', 'basic', 'course']}
                  path={ROUTES.user.settings.plan}
                  exact
                  component={SubscriptionSelectPage}
                />
                <PrivateRoute
                  userRole={['admin', 'basic', 'course']}
                  path={ROUTES.user.settings.payment}
                  exact
                  component={PaymentPage}
                />
                <PrivateRoute
                  userRole={['admin', 'basic', 'course']}
                  path={ROUTES.affiliates.dashboard}
                  component={AffiliateDashboard}
                />

                <Route path={ROUTES.issues.view}>
                  <>
                    <PrivateRoute
                      userRole={['admin', 'basic', 'course']}
                      path={ROUTES.issues.view}
                      component={AdminIssueHeader}
                    />
                    <PrivateRoute
                      userRole={['admin', 'basic', 'course']}
                      path={ROUTES.issues.view}
                      component={IssueView}
                    />
                  </>
                </Route>
                <Route
                  userRole={['admin', 'basic', 'course']}
                  path={ROUTES.courses.preview}
                  component={CoursePreviewView}
                />
                <PrivateRoute
                  userRole={['admin', 'basic', 'course']}
                  path={ROUTES.courses.lesson}
                  component={LessonView}
                />

                <Route path={ROUTES.dashboard.aboutJasmine} component={AboutPage} />

                <Route path="*">
                  <Redirect to={ROUTES.errors.notFound} />
                </Route>
              </Switch>
            </NewPostTour>
          </AppLayout>
        </Route> */}
      </Switch>
    </Router>
  )
}

export default AppRouter
