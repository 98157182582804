export const throttleFn = (func, limit) => {
  let lastFunc
  let lastRan
  return function() {
    // @ts-ignore
    const context = this
    const args = arguments
    if (!lastRan) {
      func.apply(context, args)
      lastRan = Date.now()
    } else {
      clearTimeout(lastFunc)
      lastFunc = setTimeout(function() {
        if ((Date.now() - lastRan) >= limit) {
          func.apply(context, args)
          lastRan = Date.now()
        }
      }, limit - (Date.now() - lastRan))
    }
  }
}

export const loadStateRedux = () => {
  try {
    const data = localStorage.getItem('social-curator-state')
    if (data === null) {
      return undefined
    }
    return JSON.parse(data)
  } catch (e) {
    return undefined
  }
}

export const setStateRedux = (data) => {
  try {
    const obj = JSON.stringify(data)
    localStorage.setItem('social-curator-state', obj)
  } catch (e) {
    console.error(e)
  }
}
