import { useEffect } from 'react'


export default function useClickOutsideComponent(ref: any, handler: Function) {
  useEffect(() => {
    const listener = (event) => {
      const outside = !ref.current || event.target === ref.current || ref.current.contains(event.target)
      if (!outside) {
        handler(event)
      }
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}
