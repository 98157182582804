import './IssueVideo.css'
import React, { FC, useEffect, useState, useRef } from 'react'
import { v4 as uuid } from 'uuid'
import useSegment from '../utils/useSegment'
import { StyledIssueVideo } from '.'

type Props = {
  videoUrl: string;
  standalone?: boolean;
  noControls?: boolean;
}

const controls = [
  'play-large',
  'play',
  'fast-forward',
  'progress',
  'current-time',
  'duration',
  'mute',
  'captions',
  'settings',
  'pip',
  'airplay',
  'fullscreen'
]

const IssueVideo: FC<Props> = ({ videoUrl, noControls }) => {
  const { track } = useSegment()
  // Generates random ID to initialize player
  const playerID = `sc-${uuid().split('-')[0]}`

  const [hasBeenInteracted, setInteraction] = useState(false)
  const [player, setPlayer] = useState<any>()

  const videoRef = useRef<HTMLDivElement>(null)

  const handleVideoPlay = () => {
    if (!hasBeenInteracted) {
      track('Watched Action Plan Video')
      setInteraction(true)
    }
  }

  const getControlConfig = () => {
    if (noControls) {
      return ['play-large']
    }

    return controls
  }

  // Updates the player's source when needed
  useEffect(() => {
    if (player) {
      player.source = {
        type: 'video',
        sources: [
          {
            src: videoUrl,
            provider: 'vimeo'
          }
        ]
      }
    }
  }, [player, videoUrl])

  useEffect(() => {
    const { Plyr } = window as any

    console.log(Plyr)

    const player = new Plyr(`#${playerID}`, {
      controls: getControlConfig(),
      speed: {
        selected: 1,
        options: [0.5, 1, 1.5, 2]
      },
      vimeo: { controls: 0 },
      fullscreen: { iosNative: true }
    })

    player.on('play', () => {
      handleVideoPlay()
    })

    setPlayer(player)
  }, [])

  return (
    <StyledIssueVideo className="video-player" ref={videoRef}>
      <div id={playerID} className="plyr">
        <iframe src={`${videoUrl}?controls=0&texttrack=false`} allowFullScreen allow="autoplay" />
      </div>
    </StyledIssueVideo>
  )
}

export default IssueVideo
