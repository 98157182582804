import React, { FC, useEffect, useState, createRef } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useQuery } from '@apollo/client'
import ROUTES from '../utils/routes'
import {
  StyledIssueView, IssueHeader, IssueTabs, IssuePdf, IssueGrid, IssueFooter
} from '.'
import { ToTopButton } from '../System'
import {
  IssueContentList as ContentList,
  IssueContentPagination as ContentPagination
} from './IssueContent'
import { IssueModal } from './IssueModal'
import { WidthController } from '../Grid'
import { GetIssueBySlug_issueFindOne } from '../../graphql/Issue/__generated__/GetIssueBySlug'
import { getIssueAssetsQuery } from '../../graphql/Issue/getIssueAssets'
import { GetIssueAssetsQuery } from '../../graphql/Issue/__generated__/GetIssueAssetsQuery'

type Props = {
  issue: GetIssueBySlug_issueFindOne;
}

const IssueView: FC<Props> = ({ issue }) => {
  const [currTab, setCurrTab] = useState(issue.actionPlanPdf ? 'action-plan' : 'content')
  const { data } = useQuery<GetIssueAssetsQuery>(getIssueAssetsQuery, { variables: { issue: issue._id } })

  // References to HTML elements on the page
  const headerRef = createRef<HTMLDivElement>()
  const headerActionsRef = createRef<HTMLDivElement>()
  const storiesRef = createRef<HTMLDivElement>()
  const photosRef = createRef<HTMLDivElement>()
  const captionsRef = createRef<HTMLDivElement>()
  const extrasRef = createRef<HTMLDivElement>()

  const scrollTo = (location) => {
    const headerSize = 0
    const marginSize = 32
    let scrollVal = 0

    if (location === 'stories') {
      scrollVal = storiesRef.current!.offsetTop - headerSize - marginSize
    }
    else if (location === 'photos') {
      scrollVal = photosRef.current!.offsetTop - headerSize - marginSize
    }
    else if (location === 'captions') {
      scrollVal = captionsRef.current!.offsetTop - headerSize - marginSize
    }
    else if (location === 'extras') {
      scrollVal = extrasRef.current!.offsetTop - headerSize - marginSize
    }

    window.scrollTo({ top: scrollVal, behavior: 'smooth' })
  }

  // Calculates the desired overlap between the header backdrop and heading
  useEffect(() => {
    if (headerRef.current && headerActionsRef.current) {
      const issueHeading = headerRef.current
      const headerActions = headerActionsRef.current
      headerActions.style.paddingBottom = `${issueHeading!.clientHeight / 2}px`
      issueHeading.style.marginTop = `calc(${-1 * (issueHeading!.clientHeight / 2)}px)`
    }
  }, [headerRef, headerActionsRef])

  return (
    <StyledIssueView id="issue-view">
      <Helmet title={issue.title} />
      <Switch>
        <Route path={ROUTES.issues.modal} render={() => <IssueModal isOpen issue={issue} />} />
      </Switch>
      <IssueHeader reference={headerActionsRef} issue={issue} />
      <WidthController noPadding>
        <div ref={headerRef} className="issue-header" id="issue-heading">
          <h1>{issue?.title}</h1>
        </div>
        <IssueGrid issue={issue} />
        <IssueTabs
          setCurrTab={setCurrTab}
          currTab={currTab}
          issue={issue}
          missing={{
            noStories: !data?.storySetFindMany?.length,
            noCaptions: !data?.captionSetFindMany?.length,
            noExtras: !data?.extraSetFindMany?.length,
            noActionPlan: !issue.actionPlanPdf
          }}
          scrollTo={scrollTo}
        />
        {currTab === 'content' && (
          <div>
            {!!data?.storySetFindMany?.length && (
              <ContentList
                reference={storiesRef}
                issue={issue}
                records={data.storySetFindMany}
                contentType="stories"
              />
            )}
            {!!data?.captionSetFindMany?.length && (
              <>
                <ContentList
                  reference={photosRef}
                  issue={issue}
                  records={data.captionSetFindMany}
                  contentType="photos"
                />
                <ContentPagination reference={captionsRef} issue={issue} contentType="captions" />
              </>
            )}
            {!!data?.extraSetFindMany?.length && (
              <ContentList
                reference={extrasRef}
                issue={issue}
                records={data.extraSetFindMany}
                contentType="extras"
              />
            )}
          </div>
        )}
        {issue.actionPlanPdf && headerRef && currTab === 'action-plan' && (
          <div>
            <IssuePdf issueRef={headerRef} pdf={issue.actionPlanPdf.fileHttpLink || ''} />
          </div>
        )}
        <ToTopButton />
      </WidthController>
      <IssueFooter issue={issue} />
    </StyledIssueView>
  )
}

export default IssueView
