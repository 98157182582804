import React, { FC, useState, useEffect } from 'react'
import { StyledTestimonials, UserBubble, TestimonialType } from '.'

type Props = {
  testimonials: TestimonialType[];
}

const Testimonials: FC<Props> = ({ testimonials }) => {
  const [currTestimonial, setCurrTestimonial] = useState(0)
  const [intervalId, setIntervalId] = useState<number>()

  const gotToTestimonial = (index: number) => {
    setCurrTestimonial(index)
    clearInterval(intervalId)
  }

  const handleSwitch = () => {
    let testimonialIndex = 0

    return window.setInterval(() => {
      if (testimonialIndex + 1 === testimonials.length) {
        setCurrTestimonial(0)
        testimonialIndex = 0
        return
      }

      setCurrTestimonial(testimonialIndex + 1)
      testimonialIndex++
    }, 6000)
  }

  useEffect(() => {
    const interval = handleSwitch()
    setIntervalId(interval)

    return () => clearInterval(interval)
  }, [testimonials.length])

  return (
    <StyledTestimonials>
      <div className="user-bubbles">
        {testimonials.map((testimonial, index) => {
          return (
            <UserBubble
              user={testimonial.user}
              isActive={currTestimonial === index}
              onClick={() => gotToTestimonial(index)}
              key={index}
            />
          )
        })}
        <div className="testimonial-body">
          <div className="testimonial-review">{testimonials[currTestimonial].review}</div>
          <span className="testimonial-user">
            <b>{testimonials[currTestimonial].user.name}</b> -{' '}
            {testimonials[currTestimonial].user.occupation}
          </span>
        </div>
      </div>
    </StyledTestimonials>
  )
}

export default Testimonials
