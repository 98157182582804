import { gql } from '@apollo/client'

export const getSocialProfiles = gql`
  query GetSocialProfiles($accessToken: String!) {
    getSocialProfiles(accessToken: $accessToken) {
      type
      username
      avatarUrl
      profileId
      pageId
      accessToken
      pageAccessToken
      status
      isBusiness
    }
  }
`
