import React, { FC, useState, useEffect } from 'react'
import firebase from 'firebase/app'
import { random } from 'lodash'
import { TrashIcon } from '@heroicons/react/outline'
import { FileObjectType } from '../../../types'
import { StyledMultiUploadInput, UploadItemType } from '.'
import { ImageInput } from '../ImageInput'
import { LineInput } from '../LineInput'
import { Button } from '../Button'
import { handleSlug } from '../../utils/handleSlug'

type Props = {
  value: UploadItemType[];
  onChange: (value: UploadItemType[]) => void;
}

const MultiUploadInput: FC<Props> = ({ value, onChange }) => {
  const storage = firebase.storage()
  const [files, setFiles] = useState<(UploadItemType & { id: string })[]>([])

  const loadFiles = () => {
    return setFiles(
      value.map((file) => {
        return {
          label: file.label,
          file: file.file,
          id: `${handleSlug(file.label)}-${random(0, 999)}`
        }
      })
    )
  }

  const addFile = () => {
    setFiles(
      files.concat([
        {
          label: 'Untitled Item',
          id: `untitled-item-${random(0, 999)}`
        }
      ])
    )
  }

  const updateFile = (id: string, newLabel: string, newFile?: FileObjectType) => {
    const updatedFiles = files.map((file) => {
      if (file.id !== id) {
        return file
      }

      const label = newLabel || file.label

      return {
        label,
        file: newFile || file.file,
        id: `${handleSlug(label)}-${random(0, 999)}`
      }
    })

    setFiles(updatedFiles)
  }

  const deleteFile = async (id: string, path?: string) => {
    try {
      setFiles(files.filter((file) => file.id !== id))

      if (path) {
        await storage
          .ref(path)
          .delete()
          .catch(console.error)
      }
    }
    catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (!files.length && value) {
      loadFiles()
    }
  }, [value])

  useEffect(() => {
    const formattedFiles = files.map((file) => ({
      label: file.label,
      file: { fullPath: file.file?.fullPath || '', fileHttpLink: file.file?.fileHttpLink || '' }
    }))
    return onChange(formattedFiles)
  }, [JSON.stringify(files)])

  return (
    <StyledMultiUploadInput>
      {files.map((file, index) => {
        return (
          <div className="file-bullet" key={index}>
            <div className="file-bullet-input">
              <LineInput
                value={file.label}
                placeholder="File Label"
                onChange={(e) => updateFile(file.id, e.target.value)}
              />
              <Button
                className="delete-btn"
                theme="outline"
                type="large"
                onClick={() => deleteFile(file.id, file.file?.fullPath)}
                isIcon
              >
                <TrashIcon />
              </Button>
            </div>

            <ImageInput
              file={file.file}
              dir="extra"
              onUpdate={(e) => updateFile(file.id, '', e || undefined)}
            />
          </div>
        )
      })}
      {files?.length < 6 && (
        <Button className="add-btn" theme="outline" onClick={() => addFile()}>
          Add File
        </Button>
      )}
    </StyledMultiUploadInput>
  )
}

export default MultiUploadInput
