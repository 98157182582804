import { useMemo, useState } from 'react'
import firebase from 'firebase/app'
import moment from 'moment'
import surveys from '../utils/refinerSurveys'
import { SubInfoType } from '../../types'
import usePricing from '../../redux/pricing/usePricing'

const useAccountSubscription = () => {
  const startSubscription = firebase.functions().httpsCallable('startSubscription')
  const getSubscription = firebase.functions().httpsCallable('getSubscription')
  const updateSubscription = firebase.functions().httpsCallable('updateSubscription')
  const cancelSubscription = firebase.functions().httpsCallable('cancelSubscription')
  const getProrationPreview = firebase.functions().httpsCallable('getProrationPreview')
  const reactivateSubscription = firebase.functions().httpsCallable('reactivateSubscription')

  const { actions: { getPriceData } } = usePricing()

  const [loadingSubscription, setLoadingSubscription] = useState(false)
  const [subscriptionInfo, setSubscriptionInfo] = useState<SubInfoType | null>(null)

  const loadSubscriptionInfo = async () => {
    try {
      setLoadingSubscription(true)
      const { data } = await getSubscription({})
      const plan = getPriceData('subscription', data.plan.id)!

      if (!data) {
        setLoadingSubscription(false)
        return
      }

      if (data.forever) {
        setSubscriptionInfo(data)
        setLoadingSubscription(false)
        return
      }

      setSubscriptionInfo({
        id: data.id,
        start: data.current_period_start,
        end: data.current_period_end,
        plan,
        interval: data?.plan?.interval,
        price: data.plan.amount / 100,
        cancelAt: data.cancel_at || null,
        renews: !data.cancel_at_period_end,
        status: data.status
      })
      setLoadingSubscription(false)
    }
    catch (err) {
      setLoadingSubscription(false)
    }
  }

  const startUserSubscription = async (subscriptionInterval: string, couponID?: string) => {
    const subscription = await startSubscription({ subscriptionInterval, couponID })

    if (!subscription) {
      throw new Error('Error creating subscription.')
    }
  }

  const upgradeUserSubscription = async (
    subscriptionInterval: string,
    couponID?: string,
    cost?: number
  ) => {
    if (!subscriptionInfo) {
      return await startUserSubscription(subscriptionInterval, couponID)
    }

    await updateSubscription({
      interval: subscriptionInterval,
      subscriptionId: subscriptionInfo?.id,
      couponID,
      cost
    })
  }

  const cancelUserSubscription = async () => {
    await cancelSubscription({})

    if (subscriptionInfo && subscriptionInfo.end) {
      window._refiner('showForm', surveys.cancellationSurvey)
    }
  }

  const reactivateUserSubscription = async () => {
    await reactivateSubscription({})
  }

  const getProration = async (newSubscriptionInterval: string) => {
    const amountDue = await getProrationPreview({
      subscriptionInterval: newSubscriptionInterval,
      subscriptionId: subscriptionInfo?.id
    })

    return amountDue.data
  }

  const daysFromToday = (endMoment) => {
    const today = moment()
    return endMoment.diff(today, 'days')
  }

  const periodEndsInDays: string = useMemo(() => {
    if (!subscriptionInfo || !subscriptionInfo.end) {
      return
    }

    const endsEndMoment = moment.unix(subscriptionInfo.end)

    return daysFromToday(endsEndMoment)
  }, [subscriptionInfo])

  const periodStartedInDays: number = useMemo(() => {
    if (!subscriptionInfo || !subscriptionInfo.start) {
      return
    }

    const startMoment = moment.unix(subscriptionInfo.start)

    return daysFromToday(startMoment)
  }, [subscriptionInfo])

  return {
    subscriptionInfo,
    loadingSubscription,
    periodEndsInDays,
    periodStartedInDays,
    actions: {
      loadSubscriptionInfo,
      startUserSubscription,
      upgradeUserSubscription,
      cancelUserSubscription,
      reactivateUserSubscription,
      getProration
    }
  }
}

export default useAccountSubscription
