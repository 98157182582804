import {
  ChallengeState,
  ChallengeActions,
  CHALLENGE_CHANGE_USER_DATA,
  CHALLENGE_SET_DATA
} from './types'

const initialState: ChallengeState = { currentChallenge: null, userChallenge: null, loading: true }

export const challengeReducer = (
  state = initialState,
  action: ChallengeActions
): ChallengeState => {
  switch (action.type) {
    case CHALLENGE_SET_DATA:
      return { ...action.payload, loading: false }
    case CHALLENGE_CHANGE_USER_DATA:
      return {
        ...state,
        userChallenge: action.payload
      }
  }

  return state
}
