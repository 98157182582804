import styled from 'styled-components'

const StyledPayPalConnectModal = styled.div`
  grid-gap: 20px;
  display: grid;
  max-width: 380px;
  width: 100vw;
  padding: 32px;

  .spacer {
    display: flex;

    .icon {
      height: 36px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      margin-right: 24px;
      flex-shrink: 0;
      background-color: ${({ theme }) => theme.colors.green[50]};
      color: ${({ theme }) => theme.colors.green[400]};

      svg {
        height: 24px;
      }
    }

    .details {
      span,
      a {
        display: block;
        font-size: 14px;
      }

      .title {
        font-size: 20px;
        line-height: 23px;
        letter-spacing: -0.01rem;
        color: ${({ theme }) => theme.colors.gray[900]};
        font-family: ${({ theme }) => theme.fonts.lexend};
      }

      .description {
        margin-top: 1rem;
        line-height: 22px;
        color: ${({ theme }) => theme.colors.gray[500]};
      }

      a {
        margin-top: 1rem;
        text-decoration: underline;
        font-weight: 600;
      }
    }
  }

  .link-figure {
    border-radius: 10px;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    background-color: ${({ theme }) => theme.colors.gray[100]};

    .link-icon {
      height: 24px;
      margin: 0 1.5rem;
      color: ${({ theme }) => theme.colors.gray[500]};
    }

    img,
    svg {
      height: 29px;
    }
  }

  .btn {
    width: 100%;
    border-radius: 50rem;
    background-color: #0070ba;
    height: 48px;

    &:hover {
      background-color: #003087;
    }
  }
`

export default StyledPayPalConnectModal
