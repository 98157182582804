import React, { FC, useEffect, useState } from 'react'
import firebase from 'firebase/app'
import { ScrollRow } from '../System'
import { StyledCommunity, CommunityEventType, CommunityItem } from '.'
import { SkeletonLoader } from '../SkeletonLoader'

const Community: FC = () => {
  const eventsDB = firebase.firestore().collection('community_events')

  const [loading, setLoading] = useState(true)
  const [communityEvents, setCommunityEvents] = useState<CommunityEventType[]>([])

  const loadCommunity = async () => {
    // Query events
    const { docs: eventResult } = await eventsDB
      .orderBy('endTime', 'asc')
      .where('endTime', '>=', firebase.firestore.Timestamp.now())
      .limit(9)
      .get()

    const events = eventResult.map((event) => ({
      id: event.id,
      ...event.data()
    })) as CommunityEventType[]

    const filteredEvents = events.filter((event) => !event.isFeatured)

    // Query featured events
    const { docs: featuredEventResult } = await eventsDB
      .where('isFeatured', '==', true)
      .limit(1)
      .get()

    if (!featuredEventResult) {
      setCommunityEvents(filteredEvents)
      setLoading(false)
      return
    }

    const featuredEvent = {
      id: featuredEventResult[0].id,
      ...featuredEventResult[0].data()
    } as CommunityEventType

    setCommunityEvents([featuredEvent].concat(filteredEvents))
    setLoading(false)
  }

  useEffect(() => {
    loadCommunity()
  }, [])

  return (
    <StyledCommunity>
      <ScrollRow itemsToShow={4}>
        {!loading &&
          communityEvents.map((event, index) => {
            return <CommunityItem isFeatured={event.isFeatured} key={index} event={event} />
          })}
        {loading && <SkeletonLoader quantity={4} type="square-list-item" />}
      </ScrollRow>
    </StyledCommunity>
  )
}

export default Community
