import React, { FC, useContext } from 'react'
import { Link } from 'react-router-dom'
import { ChevronLeftIcon } from '@heroicons/react/outline'
import ROUTES from '../utils/routes'
import { userStore } from '../utils/UserContext'
import { Button } from '../System'
import { StyledIssueHeader } from '.'
import { GetIssueBySlug_issueFindOne } from '../../graphql/Issue/__generated__/GetIssueBySlug'

type Props = {
  reference: any;
  issue: GetIssueBySlug_issueFindOne;
}

const IssueHeader: FC<Props> = ({ issue, reference }) => {
  const { user } = useContext(userStore)

  return (
    <StyledIssueHeader isAdmin={user?.role === 'admin'}>
      <div ref={reference} className="back-btn-container">
        <Link className="back-btn" to={ROUTES.dashboard.issues}>
          <Button type="large" theme="outline" isIcon>
            <ChevronLeftIcon />
          </Button>
        </Link>
      </div>

      <div className="issue-backdrop">
        {issue?.headerImg?.fileHttpLink && (
          <img src={issue.headerImg.fileHttpLink} alt={issue.title} />
        )}
      </div>
    </StyledIssueHeader>
  )
}

export default IssueHeader
