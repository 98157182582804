import styled from 'styled-components'

type StyledProps = {
  isActive: boolean;
}

const StyledIssueHighlight = styled.div<StyledProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  height: 28px;
  color: ${({ theme }) => theme.colors.gray[600]};
  padding-right: 36px;

  button {
    width: 35px;
    height: 35px;
    background: transparent;
    color: ${({ theme }) => theme.colors.gray[600]};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 50%;

    svg {
      height: 22px;
    }
  }

  ${({ isActive }) =>
    !isActive && `
    svg, button {
      opacity: 0.6; 
      pointer-events: none;
    }
  `}

  button:last-child {
    margin-right: -8px;
  }
`
export default StyledIssueHighlight
