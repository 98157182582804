import React, { FC } from 'react'
import { ActionDialog } from '../../System'

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const QuickDraftClearModal: FC<Props> = ({ isOpen, onClose, onConfirm }) => {
  const handleConfirm = () => {
    onConfirm()
    onClose()
  }

  return (
    <ActionDialog
      type="warning"
      isOpen={isOpen}
      title="You're draft is not saved."
      body="Are you sure you want to close this draft?"
      confirmButtonText="Discard Changes"
      onClose={() => onClose()}
      onConfirm={() => handleConfirm()}
    />
  )
}

export default QuickDraftClearModal
