export const MONTH = 'Month'
export const YEAR = 'Year'
export const FOREVER = 'Forever'
export const TRIAL = 'Trial'
export const CHALLENGE = 'Challenge'

export const monthPrice = '59-monthly'
export const annualPrice = '599-yearly'
export const foreverPrice = '1500-forever'
export const trialPrice = '0-trial'
export const issuePrice = 'issue'
export const coursePrice = 'course'
export const course187Price = 'price_1MQwmTDxhYjWFELLTBxNto2o'
export const course197Price = 'price_1MQxYKDxhYjWFELLHGixlnYk'
export const courseFirstTimePrice = 'course-first-payment'
export const courseSecondTimePrice = 'course-2nd-payment'

export const affiliateDiscountMonth = 'affiliate-discount-month'
export const affiliateDiscountYear = 'affiliate-discount-year'

export const stripeIntervalToSC = {
  forever: FOREVER,
  year: YEAR,
  month: MONTH,
  trial: TRIAL,
  week: '',
  day: ''
}
