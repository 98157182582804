import React, { FC } from 'react'
import { ChevronUpIcon } from '@heroicons/react/outline'
import { StyledToTopButton, useShowToTopButton } from '.'
import useDraft from '../../../redux/draft-drawer/useDraft'

const ToTopButton: FC = () => {
  const [revealScrollTop, scrollToPositon] = useShowToTopButton(false)
  const { isOpened: draftOpened } = useDraft()

  return (
    <StyledToTopButton isRevealed={revealScrollTop} draftOpened={draftOpened} onClick={() => scrollToPositon(0)}>
      <ChevronUpIcon />
    </StyledToTopButton>
  )
}

export default ToTopButton
