import React, {
  FC, useContext, MouseEvent, useState, useEffect
} from 'react'
import moment from 'moment'
import { HeartIcon, CheckIcon, XIcon, TrashIcon } from '@heroicons/react/outline'
import { ExclamationCircleIcon, PhotographIcon } from '@heroicons/react/solid'
import { StyledDraftItem } from '.'
import { linkToSizedImage, SizeData } from '../../IssueEntityImage/helpers'
import { userStore } from '../../utils/UserContext'
import { useDraftActions } from '../useDraftActions'
import { DeletionConfirmation, Avatar, Spinner } from '../../System'
import useDraftsList from '../../../redux/drafts-list/useDraftsList'
import { SearchUserDrafts_searchUserDrafts_docs } from '../../../graphql/UserDraft/__generated__/SearchUserDrafts'
import { useDraftError } from '../../utils/useDraftError'

type Props = {
  draft: SearchUserDrafts_searchUserDrafts_docs;
  isCollapsed?: boolean;
  isActive?: boolean;
  [x: string]: any;
}

const DraftItem: FC<Props> = ({
  draft, isCollapsed, isActive, ...rest
}) => {
  const { errors } = useDraftError(draft)
  const [image, setImage] = useState('')
  const [isLoading, setLoading] = useState(true)
  const { user } = useContext(userStore)
  const { actions: { removeDraft } } = useDraftsList()
  const { toggleDraftLike, toggleDraftUsed, deleteDraft } = useDraftActions()
  const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false)
  const [isLiked, setIsLiked] = useState(draft.isLiked)
  const [isUsed, setIsUsed] = useState(draft.isUsed)
  const [loadingLike, setLoadingLike] = useState(false)
  const THUMBNAIL_SIZE: SizeData = { height: 800 }
  const pathToBucket = draft.userUpload ? 'user-images' : 'images'
  const emptyDraft = !draft.imageUrl && !draft.captionText?.length

  const handleDelete = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setShowDeletionConfirmation(true)
  }

  const handleLike = async (e: MouseEvent<HTMLButtonElement>) => {
    setLoadingLike(true)
    e.preventDefault()
    e.stopPropagation()
    setIsLiked(!isLiked)
    await toggleDraftLike(draft._id)
    setLoadingLike(false)
  }

  const handleUsed = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsUsed(!isUsed)
    await toggleDraftUsed(draft._id)
  }

  useEffect(() => {
    setIsUsed(draft.isUsed)
  }, [draft.isUsed])

  useEffect(() => {
    setIsLiked(draft.isLiked)
  }, [draft.isLiked])

  useEffect(() => {
    setLoading(true)
    setImage(linkToSizedImage(draft.imageUrl, THUMBNAIL_SIZE, pathToBucket))
  }, [draft.imageUrl])

  return (
    <>
      <StyledDraftItem
        isLiked={!!isLiked}
        isCollapsed={isCollapsed}
        isActive={isActive}
        data-template-id={draft.template}
        className="scroll-item"
        isLoading={isLoading}
        {...rest}
      >
        <div className="draft-image">
          {isLoading && !emptyDraft && <Spinner />}
          {draft.imageUrl && (
            <img
              src={image}
              alt={draft.title || 'Untitled Draft'}
              onLoad={() => setLoading(false)}
            />
          )}
          {!draft.imageUrl && !!draft.captionText?.length && (
            <div className="draft-caption">
              <span>{draft.captionText}</span>
            </div>
          )}
          {emptyDraft && <PhotographIcon className="empty-icon" />}
          {!!errors?.length && (
            <div className="draft-error-icon">
              <ExclamationCircleIcon />
            </div>
          )}
          <button disabled={loadingLike} className="fav-btn" onClick={(e) => handleLike(e)}>
            <HeartIcon />
          </button>
          <button className="delete-btn" onClick={(e) => handleDelete(e)}>
            <TrashIcon />
          </button>
        </div>
        <div className="draft-info-container">
          {!!draft.title && draft.title !== 'Untitled Draft' && (
            <span className="draft-title">{draft.title}</span>
          )}
          <div className="draft-info">
            {!isUsed && (
              <div className="draft-date">
                <span>
                  {draft.postDate
                    ? moment(draft.postDate).format('MMM D [at] h:mmA')
                    : 'Unscheduled'}
                </span>
              </div>
            )}
            {isUsed && (
              <button className="draft-used-btn" onClick={(e) => handleUsed(e)}>
                <CheckIcon className="hide-hover" />
                <span className="hide-hover">Used</span>
                <XIcon aria-hidden className="show-hover" />
                <span aria-hidden className="show-hover">
                  Reset
                </span>
              </button>
            )}
            {!!draft.socialProfiles?.length && (
              <ul className="draft-social-profiles">
                {draft.socialProfiles.map((socialProfile) => {
                  const profile = user?.socialProfiles?.find(
                    (profile) => profile._id === socialProfile
                  )

                  if (!profile) {
                    return
                  }

                  return (
                    <li className="draft-social-profile" key={profile._id}>
                      <Avatar
                        name={profile.username}
                        imageUrl={profile.avatarUrl || ''}
                        type={profile.avatarUrl ? 'fade' : 'gray'}
                      />
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        </div>
      </StyledDraftItem>
      <DeletionConfirmation
        isOpen={showDeletionConfirmation}
        itemToDelete="draft"
        onConfirm={() => {
          deleteDraft(draft._id, draft.fullPath || '')
          removeDraft(draft._id)
        }}
        hideDeletionConfirmation={() => setShowDeletionConfirmation(false)}
      />
    </>
  )
}

export default DraftItem
