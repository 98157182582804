import React, { FC, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import useSegment from '../../utils/useSegment'
import { ScrollRow } from '../../System'
import { StyledGalleryPhotoSimilar, GalleryPhotoSimilarItem } from '.'
import { SkeletonLoader } from '../../SkeletonLoader'
import { searchGalleryQuery } from '../../../graphql/GalleryImage/searchGallery'
import { SearchGalleryQuery } from '../../../graphql/GalleryImage/__generated__/SearchGalleryQuery'
import { GalleryImageFindOneQuery_galleryImageFindOne } from '../../../graphql/GalleryImage/__generated__/GalleryImageFindOneQuery'

type Props = {
  photo: GalleryImageFindOneQuery_galleryImageFindOne;
}

const SIMILAR_PHOTOS_LENGTH = 8

const GalleryPhotoSimilar: FC<Props> = ({ photo }) => {
  const tags = photo.tags || [{}]

  const { track } = useSegment()
  const { loading, data } = useQuery<SearchGalleryQuery>(searchGalleryQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      search: tags[0].label, // TODO: bring back -> photo.tags?.map((tag) => tag.label).join(' ') || '',
      sort: 'popular',
      categories: [],
      style: [],
      colors: [],
      items: SIMILAR_PHOTOS_LENGTH,
      page: 0
    }
  })

  // prevent recommending the very same image
  const filteredData = useMemo(() => {
    return data?.searchGallery?.docs?.filter((doc) => doc._id !== photo._id) || []
  }, [data])

  const trackSimilarImageClick = (similarPhotoId: string) => {
    track('Clicked Similar Photo ', {
      original_photo_url: `${window.location.host}/gallery/${photo._id}`,
      similar_photo_url: `${window.location.host}/gallery/${similarPhotoId}`
    })
  }

  return (
    <StyledGalleryPhotoSimilar>
      <span className="title">Similar Photos</span>
      <ScrollRow itemsToShow={6} customWidth>
        {loading && <SkeletonLoader type="square-list-item" quantity={5} size={100} />}
        {!loading &&
          filteredData.map((photo) => (
            <GalleryPhotoSimilarItem
              photo={photo}
              onClick={() => trackSimilarImageClick(photo._id)}
              key={photo._id}
            />
          ))}
      </ScrollRow>
    </StyledGalleryPhotoSimilar>
  )
}

export default GalleryPhotoSimilar
