import styled from 'styled-components'

type StyledProps = {
  isCopied: boolean;
}

const StyledCopyButton = styled.button<StyledProps>`
  width: 26px;
  height: 26px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.gray[100]};
  color: ${({ theme }) => theme.colors.gray[300]};
  border: none;
  transition: background-color 0.2s, color 0.2s;
  display: grid;

  i {
    font-size: 17px;
  }

  svg {
    height: 20px;
    margin: 0 auto;
  }

  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.gray[500]};
  }

  ${({ isCopied, theme }) =>
    isCopied &&
    `
    color: ${theme.colors.gray[500]};

    span {
      font-size: 8px;
      font-weight: 700;
      margin-top: -6px;
    }

    svg {
      width: 13px;
      margin-top: -3px;
    }

    i {
      font-size: 13px;
    }
  `}
`

export default StyledCopyButton
