import { gql } from '@apollo/client'

export const getIssueAssetsQuery = gql`
  query GetIssueAssetsQuery($issue: String!) {
    storySetFindMany(filter: { issue: $issue }, sort: INDEX_ASC) {
      _id
      title
      thumbnail
      index
    }

    captionSetFindMany(filter: { issue: $issue, showInIssue: true }, sort: INDEX_ASC) {
      _id
      category
      title
      thumbnail
      records {
        _id
        fullPath
        imageUrl
        index
        captionSet
        migrated
        updatedAt
        createdAt
      }
    }

    extraSetFindMany(filter: { issue: $issue }, sort: INDEX_ASC) {
      _id
      fileType
      contentType
      image {
        fileHttpLink
      }
      title
      thumbnail {
        fileHttpLink
      }
    }
  }
`
