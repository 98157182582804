import styled from 'styled-components'

const StyledRecentDraftListItem = styled.li`
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  align-items: center;
  justify-content: space-between;
  padding: 10px 25px;
  box-sizing: border-box;
  border-bottom: 1px solid transparent;

  .btn {
    margin-right: -14px;
    padding: 0 10px;
    border-radius: 6px;
    height: 35px;
  }

  .recent-draft-list-item-details-container {
    display: flex;
    align-items: center;

    .recent-draft-list-item-img {
      height: 48px;
      width: 48px;
      border-radius: 4px;
      position: relative;
      margin-right: 16px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }) => theme.colors.gray[100]};
      color: ${({ theme }) => theme.colors.gray[400]};

      svg {
        height: 32px;

        path {
          stroke-width: 1.5px;
        }
      }

      ::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        border-radius: 4px;
        box-shadow: inset 0 0 0 1px #0000001a;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        border-radius: 4px;
        object-fit: cover;
      }
    }

    .recent-draft-list-item-details {
      .recent-draft-list-item-title {
        font-weight: 600;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: ${({ theme }) => theme.colors.gray[800]};
      }

      .recent-draft-list-item-caption {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-top: 2px;
        font-size: 15px;
        color: ${({ theme }) => theme.colors.gray[600]};

        .empty {
          color: ${({ theme }) => theme.colors.gray[400]};
        }
      }
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.gray[100]};
  }
`

export default StyledRecentDraftListItem
