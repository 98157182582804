import React, { FC, ReactNode } from 'react'
import { StyledAdminPageLayout } from '.'

type Props = {
  children?: ReactNode;
}

const AdminPageLayout: FC<Props> = ({ children }) => {
  return <StyledAdminPageLayout>{children}</StyledAdminPageLayout>
}

export default AdminPageLayout
