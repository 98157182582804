import styled from 'styled-components'

const StyledChallengeOptIn = styled.div<{ joined: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 6px;
  cursor: ${({ joined }) => (joined ? 'pointer' : 'default')};
  width: 100%;
  padding: 20px;
  margin-top: 30px;
  background-color: ${({ theme }) => theme.colors.green[50]};

  span {
    display: block;
  }

  .challenge-title {
    font-weight: 600;
    font-size: 18px;
    color: ${({ theme }) => theme.colors.green[600]};
  }

  a {
    display: flex;
    width: fit-content;
    text-decoration: none;
    align-items: center;
    font-weight: 600;
    font-size: 1rem;
    margin-top: 12px;
    color: ${({ theme }) => theme.colors.green[500]};

    svg {
      height: 18px;
      width: 18px;
      margin-left: 6px;

      path {
        stroke-width: 2.2px;
      }
    }

    &:hover {
      text-decoration: underline;
    }
  }

  button {
    display: flex;
    width: 100%;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    border: 0;
    flex-shrink: 0;
    padding: 0 1rem;
    border-radius: 6px;
    height: 45px;
    font-weight: 600;
    transition: 0.2s;
    color: ${({ theme }) => theme.colors.background};
    background-color: ${({ theme }) => theme.colors.green[500]};

    span {
      position: relative;
      top: 1px;
    }

    svg {
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }

    ${({ joined }) =>
    !joined &&
      `
      &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.green[600]};
      }
    `}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.s}px) {
    flex-direction: row;
    align-items: center;
    margin-top: 45px;

    button {
      margin-top: 0px;
      width: fit-content;
    }
  }
`

export default StyledChallengeOptIn
