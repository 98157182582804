import styled from 'styled-components'

const StyledCaptions = styled.div`
  position: relative;

  .skeleton-item {
    height: 235px !important;
  }
`

export default StyledCaptions
