import styled from 'styled-components'

type StyledProps = {
  isCollections: boolean;
}

const StyledGalleryFilters = styled.div<StyledProps>`
  margin-bottom: 20px;

  .page-header-top {
    display: grid;
    grid-gap: 10px;
    ${({ isCollections }) =>
    !isCollections &&
      `
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      `}

    ${({ isCollections }) =>
    isCollections &&
      `
        grid-template-columns: 50%;

        .icon-btn {
          width: 48px;
        }
        
        .page-header-search-input {
          width: 100%;
        }
    `}

    .page-header-search-input {
      width: 100%;
      grid-column: 1/3;

      .input-icon {
        left: 11px;
        height: 23px;
        width: 23px;

        svg {
          path {
            stroke-width: 1.75px;
          }
        }
      }

      .input-container-border-group {
        .input-container-border {
          &.left {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }

          &.right {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }
      }

      input {
        height: 48px;
        font-size: 1rem;
        padding: 0 20px 0 45px;
      }
    }

    .page-header-action {
      height: 48px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      transition: 0.2s;
      background-color: ${({ theme }) => theme.colors.background};
      border: 1px solid ${({ theme }) => theme.colors.gray[300]};

      * {
        font-size: 1rem;
        font-family: ${({ theme }) => theme.fonts.nunito};
      }

      &.dropdown {
        select {
          height: 48px;
          border: 0;
          width: 100%;
          outline: none;
          background-color: transparent;
          color: ${({ theme }) => theme.colors.gray[600]};
          padding: 0 60px 0 14px;
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          text-overflow: '';

          &:hover {
            cursor: pointer;
          }
        }

        svg {
          top: 50%;
          right: 12px;
          position: absolute;
          transform: translateY(-50%);
          height: 20px;
          pointer-events: none;
          color: ${({ theme }) => theme.colors.gray[300]};

          &.clear-icon {
            pointer-events: unset;
          }
        }
      }

      &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.gray[100]};
      }
    }

    .page-header-btn {
      border-radius: 6px;
      display: flex;
      align-items: center;
      height: 48px;
      justify-content: center;
      font-weight: 600;
      user-select: none;
      border: none;
      transition: 0.2s;
      text-decoration: none;
      padding: 0 14px;
      background-color: ${({ theme }) => theme.colors.gray[100]};
      color: ${({ theme }) => theme.colors.gray[600]};

      span {
        position: relative;
        top: 1px;
      }

      svg {
        height: 18px;
      }

      &:not(.icon-btn) svg {
        margin-right: 10px;
      }

      &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.gray[200]};
      }

      &.new-btn {
        background-color: ${({ theme }) => theme.colors.green[50]};
        color: ${({ theme }) => theme.colors.green[500]};

        &:hover {
          background-color: ${({ theme }) => theme.colors.green[100]}a4;
        }
      }
    }
  }

  .page-header-categories {
    margin-top: 10px;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: auto minmax(0, 1fr);
  }

  @media (min-width: ${({ theme }) => `${theme.breakpoints.m}px`}) {
    margin-bottom: 40px;

    .page-header-top {
      ${({ isCollections }) =>
    !isCollections &&
        `
        grid-template-columns: minmax(0, 1fr) auto auto auto auto;
      `}
      ${({ isCollections }) =>
    isCollections &&
        `
        grid-template-columns: auto minmax(0, 1fr) auto auto;
      `}

      .page-header-search-input {
        grid-column: unset;
      }
    }
  }
`

export default StyledGalleryFilters
