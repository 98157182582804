import styled from 'styled-components'

const StyledEmojiPicker = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  z-index: ${({ theme }) => theme.elevations.modal};
  animation: jump-in 0.3s ease-in-out;

  .emoji-mart {
    font-family: ${({ theme }) => theme.fonts.nunito};
    color: ${({ theme }) => theme.colors.gray[700]};
    border: 1px solid ${({ theme }) => theme.colors.gray[300]};
    box-shadow: 0 1px 3px 0px #00000029;
    
    .emoji-mart-bar {
      border-color: ${({ theme }) => theme.colors.gray[300]};

      &:last-child {
        background: ${({ theme }) => theme.colors.gray[100]};
      }
    }

    .emoji-mart-anchor {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      cursor: pointer;
      padding-bottom: 2px;

      .emoji-mart-anchor-icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .emoji-mart-preview {
      height: 60px;

      .emoji-mart-preview-emoji {
        .emoji-mart-emoji {
          padding: 0;

          span {
            height: 32px !important;
            width: 32px !important;
          }
        }
      }

      .emoji-mart-preview-emoticons {
        display: none;
      }

      .emoji-mart-preview-data {
        left: 54px;

        & * {
          line-height: 14px;
        }

        .emoji-mart-preview-name {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        
        .emoji-mart-preview-shortnames {
          display: flex;
          align-items: center;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            right: 0;
            height: 100%;
            width: 30px;
            background: linear-gradient(90deg, #f4f4f400, #F4F4F4);
          }
        }
      }
    }

    .emoji-mart-category {
      .emoji-mart-emoji {
        cursor: pointer;

        span {
          pointer-events: none;
        }

        &:hover:before {
          border-radius: 3px;
          cursor: pointer;
        }
      }
    }

    .emoji-mart-category-label {
      span {
        font-weight: 700;
        padding: 10px 6px 5px 6px;
        font-size: 13px;
      }
    }

    .emoji-mart-search {
      margin-top: 12px;
      padding: 0 12px;
      margin-bottom: 6px;

      input {
        border-radius: 3px;
        padding: 7px 6px 6px 28px;

        &:focus {
          border-color: ${({ theme }) => theme.colors.blue[400]};
          box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.blue[100]};
        }
      }

      .emoji-mart-search-icon {
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        left: 14px;
        pointer-events: none;

        svg {
          height: 15px;
          width: 15px;
        }
      }
    }
  }

  @keyframes jump-in {
    0% {
      transform: scale(0.99);
    }
    50% {
      transform: scale(1.01);
    }
    100% {
      transform: scale(1);
    }
  }
`

export default StyledEmojiPicker
