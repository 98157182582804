import React, {
  FC, useContext, useState, useEffect, useMemo
} from 'react'
import { DownloadIcon, HeartIcon, PlusIcon } from '@heroicons/react/outline'
import { Link, useHistory } from 'react-router-dom'
import { capitalize } from 'lodash'
import { useApolloClient } from '@apollo/client'
import ROUTES from '../../utils/routes'
import useDraft from '../../../redux/draft-drawer/useDraft'
import useGallery from '../../../redux/gallery/useGallery'
import { userStore } from '../../utils/UserContext'
import { SizeData, linkToSizedImage } from '../../IssueEntityImage/helpers'
import { IssueImage } from '..'
import { FlexGroup, useWindowSize } from '../../Grid'
import { Button } from '../../System'
import { StyledIssueModalGallery } from '.'
import useSegment from '../../utils/useSegment'
import { GetIssueBySlug_issueFindOne } from '../../../graphql/Issue/__generated__/GetIssueBySlug'
import { ExtraSetFindMany_extraSetFindMany } from '../../../graphql/ExtraSet/__generated__/ExtraSetFindMany'
import {
  CaptionSetFindMany_captionSetFindMany,
  CaptionSetFindMany_captionSetFindMany_records
} from '../../../graphql/CaptionSet/__generated__/CaptionSetFindMany'
import {
  StorySetFindMany_storySetFindMany,
  StorySetFindMany_storySetFindMany_records
} from '../../../graphql/StorySet/__generated__/StorySetFindMany'
import { useZipAndDownload } from '../../utils/useZipAndDownload'
import { galleryImagesLikeFindOneQuery } from '../../../graphql/GalleryImagesLikes/galleryImagesLikeFindOne'
import { GalleryImagesLikeFindOne } from '../../../graphql/GalleryImagesLikes/__generated__/GalleryImagesLikeFindOne'

type StoryOrCaption =
  | CaptionSetFindMany_captionSetFindMany_records
  | StorySetFindMany_storySetFindMany_records

type IssueGalleryImageType = StoryOrCaption | ExtraSetFindMany_extraSetFindMany | null

type Props = {
  modalType: 'story' | 'photo' | 'extra';
  data:
    | Omit<ExtraSetFindMany_extraSetFindMany, '__typename'>
    | Omit<CaptionSetFindMany_captionSetFindMany, '__typename'>
    | Omit<StorySetFindMany_storySetFindMany, '__typename'>;
  issue?: GetIssueBySlug_issueFindOne;
  setFullScreen: (isFullScreen: boolean) => void;
  isFullScreen: boolean;
  canvaLink?: string | null;
}

const IssueModalGallery: FC<Props> = ({
  modalType,
  data,
  issue,
  setFullScreen,
  isFullScreen,
  canvaLink
}) => {
  const { track } = useSegment()
  const { actions: { setDraftImage } } = useDraft()
  const { user } = useContext(userStore)
  const {
    downloadLoading,
    actions: { downloadZipFromFiles }
  } = useZipAndDownload()

  const windowSize = useWindowSize()
  const history = useHistory()
  const apollo = useApolloClient()
  const showExtraDownload = useMemo(() => windowSize === 'sm' || windowSize === 'xs', [windowSize])
  const [galleryImages, setGalleryImages] = useState<IssueGalleryImageType[]>([])
  const [galleryFocus, setGalleryFocus] = useState<IssueGalleryImageType | null>(null)
  const [isLiked, setIsLiked] = useState(false)
  const THUMBNAIL_SIZE: SizeData = { height: 100 }
  const GALLERY_SIZE: SizeData = { height: 1000 }
  const { actions: { handleToggleLike } } = useGallery()

  const handleDownload = async (e) => {
    e.stopPropagation()

    switch (modalType) {
      case 'story':
        return track('Downloaded Story', {
          id: data._id,
          title: data.title,
          url: data.thumbnail,
          index: data.index,
          issue_id: issue?._id,
          issue_title: issue?.title,
          from: issue ? 'issue' : 'stories'
        })
      case 'photo':
        const photo = data as CaptionSetFindMany_captionSetFindMany
        const formattedPhotos = photo.records!.map((record) => {
          return {
            fileHttpLink: record!.imageUrl!,
            fullPath: record!.fullPath!
          }
        })
        await downloadZipFromFiles(formattedPhotos, 'photo')
      default:
        return track(`Downloaded ${capitalize(modalType)}`, {
          id: data._id,
          title: data.title,
          url: data.thumbnail,
          issue_id: issue?._id,
          issue_title: issue?.title,
          from: issue ? 'issue' : 'stories'
        })
    }
  }

  const handleAddToQuickDraft = () => {
    setDraftImage((galleryFocus as StoryOrCaption).imageUrl!, galleryFocus?._id!)
    history.push({ pathname: ROUTES.issues.viewBySlug(issue!.slug) })
    track('Added Photo to Quick Draft', {
      id: galleryFocus?._id,
      url: (galleryFocus as StoryOrCaption).imageUrl,
      issue_id: issue?._id,
      issue_title: issue?.title,
      from: issue ? 'issue' : 'stories'
    })
  }

  const trackCanvaLink = () => {
    track('Edited Story In Canva', {
      id: data._id,
      title: data.title,
      url: data.thumbnail,
      index: data.index,
      issue_id: issue?._id,
      issue_title: issue?.title,
      from: issue ? 'issue' : 'stories'
    })
  }

  useEffect(() => {
    const handleGet = async () => {
      const { data: galleryImageLike } = await apollo.query<GalleryImagesLikeFindOne>({
        query: galleryImagesLikeFindOneQuery,
        fetchPolicy: 'no-cache',
        variables: { user: user?.id, image: galleryFocus?._id }
      })
      setIsLiked(!!galleryImageLike.galleryImagesLikeFindOne)
    }

    if (galleryFocus && modalType == 'photo' && user) {
      handleGet()
    }
  }, [galleryFocus])

  useEffect(() => {
    if (!data._id) {
      return
    }

    if (modalType === 'extra') {
      setGalleryFocus(data as ExtraSetFindMany_extraSetFindMany)
    }
    else {
      const galleryImages =
        (data as CaptionSetFindMany_captionSetFindMany | StorySetFindMany_storySetFindMany)
          .records || []
      setGalleryFocus(galleryImages[0])
      setGalleryImages(galleryImages)
    }
  }, [data])

  const toggleFullscreen = () => {
    setFullScreen(!isFullScreen)
  }

  const handleLike = () => {
    if (galleryFocus?._id) {
      setIsLiked(!isLiked)
      handleToggleLike(galleryFocus._id, isLiked)
    }
  }

  return (
    <StyledIssueModalGallery modalType={modalType}>
      {isFullScreen && (
        <div className="issue-modal-fullscreen" onClick={() => toggleFullscreen()}>
          <IssueImage
            url={
              modalType === 'extra'
                ? (galleryFocus as ExtraSetFindMany_extraSetFindMany)?.image?.fileHttpLink
                : (galleryFocus as CaptionSetFindMany_captionSetFindMany_records)?.imageUrl
            }
            size={GALLERY_SIZE}
          />
        </div>
      )}
      <div className="issue-modal-gallery-sizer">
        <div>
          <div className="issue-modal-gallery-view" onClick={() => toggleFullscreen()}>
            <IssueImage
              url={
                modalType === 'extra'
                  ? (galleryFocus as ExtraSetFindMany_extraSetFindMany)?.image?.fileHttpLink
                  : (galleryFocus as StoryOrCaption)?.imageUrl
              }
              size={GALLERY_SIZE}
            />
          </div>
          {(modalType !== 'extra' || showExtraDownload) && (
            <div className="issue-modal-gallery-action-bar">
              {modalType !== 'story' && (
                <button className="use-btn" onClick={() => handleAddToQuickDraft()}>
                  <PlusIcon />
                  <span>USE</span>
                </button>
              )}
              <FlexGroup>
                {canvaLink && (
                  <Button
                    iconPos="left"
                    icon={<i className="canva-icon" />}
                    externalURL={canvaLink}
                    target="_blank"
                    theme="outline"
                    ariaLabel="Open template in Canva"
                    onClick={() => trackCanvaLink()}
                  >
                    Edit in canva
                  </Button>
                )}
                {modalType === 'photo' &&
                  (galleryFocus as CaptionSetFindMany_captionSetFindMany_records)?.migrated ==
                    true &&
                  user && (
                  <>
                    <Link to={ROUTES.gallery.buildPhotoPage(galleryFocus?._id || '')}>
                      <Button theme="outline">View in Gallery</Button>
                    </Link>
                    <Button
                      className={`icon-button ${isLiked && 'liked'}`}
                      theme="outline"
                      type="large"
                      isIcon
                      onClick={handleLike}
                    >
                      <HeartIcon />
                    </Button>
                  </>
                )}
                {galleryFocus && modalType === 'photo' && (
                  <Button
                    onClick={(e) => handleDownload(e)}
                    ariaLabel="Download Image"
                    theme="outline"
                    type="large"
                    isIcon
                    className="icon-button download-btn"
                    isLoading={downloadLoading}
                    isDisabled={downloadLoading}
                  >
                    <DownloadIcon />
                  </Button>
                )}
                {galleryFocus && modalType !== 'photo' && (
                  <Button
                    externalURL={
                      modalType === 'story'
                        ? linkToSizedImage((galleryFocus as StoryOrCaption)!.imageUrl, {})
                        : linkToSizedImage(
                            (galleryFocus as ExtraSetFindMany_extraSetFindMany)!.image
                              ?.fileHttpLink,
                            {}
                        )
                    }
                    onClick={(e) => handleDownload(e)}
                    ariaLabel="Download Image"
                    theme="outline"
                    type="large"
                    isIcon
                    className="icon-button download-btn"
                  >
                    <DownloadIcon />
                  </Button>
                )}
              </FlexGroup>
            </div>
          )}
        </div>
        {galleryImages.length > 1 && (
          <div className="issue-modal-gallery-select-grid-container">
            <div className="issue-modal-gallery-select-grid">
              {galleryImages.map((data, index) => (
                <div
                  key={index}
                  onClick={() => setGalleryFocus(data)}
                  className="issue-modal-gallery-select"
                >
                  <input
                    type="radio"
                    name="gallery-select"
                    defaultChecked={data?.index == galleryFocus!.index}
                  />
                  <div className="issue-modal-gallery-select-button">
                    <IssueImage
                      url={(data as StoryOrCaption).imageUrl}
                      size={THUMBNAIL_SIZE}
                      title={data?._id}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </StyledIssueModalGallery>
  )
}

export default IssueModalGallery
