import { gql } from '@apollo/client'

export const captionSetFindMany = gql`
  query CaptionSetFindMany($issue: String!) {
    captionSetFindMany(filter: { issue: $issue }, sort: INDEX_ASC) {
      _id
      title
      caption
      category
      photoIdeas
      thumbnail
      index
      canvaLink
      records {
        _id
        fullPath
        imageUrl
        index
        migrated
      }
    }
  }
`
