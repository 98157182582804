import React, {
  createRef,
  forwardRef,
  ReactNode,
  useEffect,
  useState,
  useRef,
  RefObject
} from 'react'
import classNames from 'classnames'
import { EyeIcon, EyeOffIcon, XIcon } from '@heroicons/react/outline'
import { Button } from '..'
import { StyledLineInput } from '.'
import useDebounce from '../../utils/useDebounce'

type Props = {
  inlineLabel?: string;
  name: string;
  value: string | number;
  placeholder?: string;
  type?: 'text' | 'email' | 'password' | 'number' | 'url' | 'datetime-local';
  maxLength?: number;
  ariaLabel?: string;
  className?: string;
  debounce?: number;
  icon?: ReactNode;
  overrideInput?: boolean;
  isMulti?: boolean;
  isError?: boolean;
  isDisabled?: boolean;
  clearInput?: () => void;
  [x: string]: any;
}

const LineInput = forwardRef<HTMLInputElement | HTMLTextAreaElement, Props>((refProps, ref) => {
  const {
    inlineLabel,
    name,
    value,
    placeholder,
    type,
    maxLength,
    ariaLabel,
    className,
    debounce,
    icon,
    overrideInput,
    isMulti,
    isError,
    isDisabled,
    clearInput,
    onChange,
    ...props
  } = refProps

  const [inputVisible, setInputVisible] = useState(type !== 'password')
  const [inputVal, setInputVal] = useState(value)
  const debouncedInput = useDebounce(inputVal, debounce)
  const isFirstMount = useRef(true)

  const inputRef = (ref as RefObject<HTMLInputElement>) || createRef<HTMLInputElement>()
  const textareaRef = (ref as RefObject<HTMLTextAreaElement>) || createRef<HTMLTextAreaElement>()

  // Returns the input type based on if the type is password
  // and the input visibility toggle is enabled
  const getInputType = () => {
    if (type === 'password' && inputVisible) {
      return 'text'
    }

    return type || 'text'
  }

  const handleChange = (e: any) => {
    // This is to handle the debounced input coming as a string
    setInputVal(e.target ? e.target.value : '')

    if (onChange && !debounce) {
      onChange(e)
    }
  }

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false
      return
    }
    if (debounce) {
      onChange && onChange(debouncedInput)
    }
  }, [debouncedInput])

  useEffect(() => {
    setInputVal(value)
  }, [value])

  useEffect(() => {
    if (!textareaRef.current) {
      return
    }

    textareaRef.current.style.height = '0px'
    const { scrollHeight } = textareaRef.current
    textareaRef.current.style.height = `${scrollHeight}px`
  }, [textareaRef, value])

  return (
    <StyledLineInput
      hasIcon={!!icon}
      hasValue={!!inputVal}
      hasInlineLabel={!!inlineLabel}
      isMulti={isMulti}
      isError={isError}
      className={classNames('input', className)}
    >
      <div className="input-container">
        {icon && <div className="input-icon">{icon}</div>}
        {!isMulti && (
          <input
            id={name}
            name={name}
            value={overrideInput ? value : inputVal || ''}
            placeholder={inlineLabel ? '' : placeholder}
            type={getInputType()}
            maxLength={maxLength}
            aria-label={ariaLabel}
            lang="en"
            disabled={isDisabled}
            onChange={(e) => handleChange(e)}
            ref={(ref as RefObject<HTMLInputElement>) || inputRef}
            {...props}
          />
        )}
        {isMulti && (
          <textarea
            id={name}
            name={name}
            defaultValue={overrideInput ? value : inputVal || ''}
            ref={textareaRef}
            placeholder={inlineLabel ? '' : placeholder}
            maxLength={maxLength}
            aria-label={ariaLabel}
            lang="en"
            disabled={isDisabled}
            onChange={(e) => handleChange(e)}
            {...props}
          />
        )}
        <div className="input-container-border-group">
          <div className="input-container-border left" />
          <div className="input-container-border label">
            {inlineLabel && <label htmlFor={name}>{inlineLabel}</label>}
          </div>
          <div className="input-container-border right" />
        </div>
        {type === 'password' && (
          <Button
            theme="ghost"
            className="clear-btn"
            isIcon
            tabIndex="-1"
            onClick={() => setInputVisible(!inputVisible)}
          >
            {!inputVisible ? <EyeIcon /> : <EyeOffIcon />}
          </Button>
        )}
        {clearInput && value !== '' && (
          <Button theme="ghost" className="clear-btn" isIcon onClick={() => clearInput()}>
            <XIcon />
          </Button>
        )}
      </div>
    </StyledLineInput>
  )
})

export default LineInput
