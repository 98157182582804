import styled, { css, keyframes } from 'styled-components'
import { theme } from '../../utils/theme'

type StyledProps = {
  imageLoaded?: boolean;
  isLiked?: boolean;
}

const likeAnim = keyframes`
  0% {
    transform: scale(0.3);
    fill: ${theme.colors.red[600]};
    color: ${theme.colors.red[600]};
  }
  60% {
    transform: scale(1.2);
    fill: ${theme.colors.red[600]};
    color: ${theme.colors.red[600]};
  }
  80% {
    transform: scale(0.9);
    fill: ${theme.colors.red[600]};
    color: ${theme.colors.red[600]};
  }
  100% {
    transform: scale(1);
    fill: ${theme.colors.red[600]};
    color: ${theme.colors.red[600]};
  }
`

const StyledGalleryPhotoModal = styled.div<StyledProps>`
  width: 100vw;
  padding: 20px;

  .photo-view {
    height: 200px;
    margin-bottom: 2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      object-fit: contain;
      width: 100%;
      height: 100%;
      display: ${({ imageLoaded }) => (imageLoaded ? 'flex' : 'none')};
    }
  }

  .photo-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    button,
    a {
      height: 34px;
      display: flex;
      align-items: center;
      border: none;
      background-color: transparent;
      padding: 0;
      transition: 0.2s;
      border-radius: 6px;

      &:hover {
        cursor: pointer;
      }
    }

    .use-btn {
      padding: 0 10px;
      font-weight: 600;
      user-select: none;
      background-color: ${({ theme }) => theme.colors.gray[100]};
      color: ${({ theme }) => theme.colors.gray[600]};

      span {
        position: relative;
        top: 1px;
      }

      svg {
        height: 18px;
        margin-right: 8px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[200]};
      }
    }

    .fav-btn {
      svg {
        ${({ isLiked }) =>
    isLiked &&
          css`
            animation: ${likeAnim} forwards 0.2s ease-in-out;
          `}
      }

      &:hover {
        background-color: ${({ isLiked, theme }) =>
    (isLiked ? theme.colors.red[100] : theme.colors.gray[100])};
      }
    }

    .photo-actions-group {
      display: flex;
      align-items: center;

      button,
      a {
        width: 34px;
        text-decoration: none;
        justify-content: center;
        color: ${({ theme }) => theme.colors.gray[400]};
        margin-left: 4px;

        svg {
          height: 26px;

          path {
            stroke-width: 1.7px;
          }
        }

        &:hover {
          background-color: ${({ theme }) => theme.colors.gray[100]};
        }
      }
    }
  }

  .photo-tags {
    margin-bottom: 2rem;
  }

  .scroll-row {
    button {
      text-transform: capitalize;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    width: 800px;

    .photo-view {
      height: 500px;
    }
  }
`

export default StyledGalleryPhotoModal
