import styled from 'styled-components'

type StyledProps = {
  maxWidth?: number;
}

const StyledPageLayout = styled.div<StyledProps>`
  .page {
    padding: 20px 20px 4rem 20px;
    max-width: ${({ maxWidth }) => maxWidth || 1800}px;
    margin: 0 auto;
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    .page {
      padding: 40px 40px 4rem 40px;
    }
  }
`

export default StyledPageLayout
