import { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import firebase from 'firebase/app'
import useSegment from '../../components/utils/useSegment'
import { useGenerateDailyAction } from '../../components/DailyAction'
import { AppState } from '../root-reducer'
import { ActionsState, UserDailyActionType } from './types'
import {
  actionSetAction,
  actionToggleComplete,
  actionClearAction,
  actionResetIndex
} from './actions'
import { userStore } from '../../components/utils/UserContext'
import { DailyActionType } from '../../types'
import useActionsList from './useActionsList'

const useDailyAction = () => {
  const { activeAction } = useSelector<AppState, ActionsState>((state) => state.dailyActions)

  const {
    id,
    description,
    category,
    caption,
    url,
    label,
    length,
    index,
    loadedAt,
    isUrl,
    isCompleted
  } = activeAction

  const { actions: { generateActions } } = useGenerateDailyAction()
  const {
    actionsList,
    currentIndex,
    actions: { toggleCompleteAction, toggleCompleteAll, generateDailyActionsList }
  } = useActionsList()

  const dispatch = useDispatch()

  const { user } = useContext(userStore)
  const { track } = useSegment()
  const actionsDB = firebase.firestore().collection('daily_actions')
  const userActionsDB = firebase.firestore().collection('user_daily_actions')

  // Replaces the current action with the designated action
  const setAction = async (data: any) => {
    const { actionId, isCompleted } = data

    const loadedAction = await actionsDB.doc(actionId).get()
    const syncTimeLoaded = firebase.firestore.Timestamp.now()

    dispatch(
      actionSetAction({
        id: loadedAction.id,
        isCompleted: isCompleted || false,
        index: currentIndex,
        loadedAt: syncTimeLoaded,
        ...loadedAction.data()
      } as DailyActionType)
    )

    // Update loadedAt in db
    const userActionRef = await userActionsDB
      .where('userId', '==', user?.id)
      .where('actionId', '==', loadedAction.id)
      .orderBy('loadedAt', 'desc')
      .limit(1)
      .get()

    if (!userActionRef.docs.length) {
      return
    }

    // Update loadedAt in db
    const userAction = {
      ...userActionRef.docs[0].data(),
      id: userActionRef.docs[0].id
    } as DailyActionType

    await userActionsDB.doc(userAction.id).update({ loadedAt: syncTimeLoaded })
  }

  // Marks this action as completed or uncompleted
  const setToggleCompleteAction = async () => {
    // Update action in state
    dispatch(actionToggleComplete())
    toggleCompleteAction(currentIndex)

    // Check if all actions have been completed
    toggleCompleteAll()

    // Update action in db
    const userActionRef = await userActionsDB
      .where('userId', '==', user?.id)
      .where('actionId', '==', id)
      .orderBy('loadedAt', 'desc')
      .limit(1)
      .get()

    const toggledAction = {
      ...userActionRef.docs[0].data(),
      id: userActionRef.docs[0].id
    } as Partial<UserDailyActionType>

    // If isComplete is null, then we are toggling the action as complete
    const toggleComplete = toggledAction.completedAt === null
    const now = firebase.firestore.Timestamp.now()

    await userActionsDB.doc(toggledAction.id).update({ completedAt: toggleComplete ? now : null })

    track(`${toggleComplete ? 'Completed Action' : 'Uncompleted Action'}`, {
      description,
      description_character_count: description.length,
      id,
      category,
      length,
      loaded_at: loadedAt,
      index,
      is_url: isUrl
    })
  }

  const generateDailyAction = async (isReset = false) => {
    if (!id) {
      // If there isn't a current action, check against the user's past actions.
      // If the user has done an action before, load that and its completion status
      // as the current action.
      const { docs } = await userActionsDB
        .where('userId', '==', user?.id)
        .orderBy('loadedAt', 'desc')
        .limit(1)
        .get()

      if (docs.length) {
        const mostRecentAction = docs[0].data()

        setAction({
          actionId: mostRecentAction.actionId,
          syncTimeLoaded: mostRecentAction.loadedAt,
          isCompleted: !!mostRecentAction.completedAt,
          completedIndex: mostRecentAction.completedIndex
        })
        return
      }
    }

    const randomActions = (await generateActions()) as DailyActionType[]
    let randomAction = randomActions[0]
    const syncTimeLoaded = firebase.firestore.Timestamp.now()

    if (!randomAction) {
      const fallBackActionRequest = await actionsDB
        .where(firebase.firestore.FieldPath.documentId(), '>=', actionsDB.doc().id)
        .get()
      randomAction = {
        id: fallBackActionRequest.docs[0].id,
        ...fallBackActionRequest.docs[0].data()
      } as DailyActionType

      setAction({
        actionId: randomAction.id,
        syncTimeLoaded
      })
    }
    else {
      setAction({
        actionId: randomAction?.id,
        syncTimeLoaded
      })
    }

    const actionIndex = !isReset ? index + 1 : 1

    await userActionsDB.add({
      completedAt: null,
      loadedAt: syncTimeLoaded,
      userId: user?.id,
      actionId: randomAction.id,
      completedIndex: actionIndex,
      category: randomAction.category
    })

    track('Loaded Action', {
      description: randomAction.description,
      description_character_count: randomAction.description.length,
      id: randomAction.id,
      category: randomAction.category,
      length: randomAction.length,
      loaded_at: syncTimeLoaded,
      index: actionIndex,
      is_url: randomAction.isUrl
    })
  }

  // Clears the action
  const clearAction = async () => {
    dispatch(actionClearAction())
  }

  const resetIndex = async () => {
    dispatch(actionResetIndex())
  }

  // Updates redux store if there's been a change to the user's
  // current action which is not already updated in the store
  const checkForActionUpdates = async () => {
    const userActionRef = await userActionsDB
      .where('userId', '==', user?.id)
      .orderBy('loadedAt', 'desc')
      .limit(1)
      .get()

    if (!userActionRef.docs.length) {
      return
    }

    const mostRecentAction = userActionRef.docs[0].data()
    const actionIsCompleted = !!mostRecentAction.completedAt

    // If the user's most recent action is NOT the same as the action in the store
    // OR if the action in the store has a different completion status than the DB
    // OR the action in the store has a different loaded index than the DB,
    // replace the store's action with the DB's action
    if (
      mostRecentAction.actionId !== id ||
      actionIsCompleted !== isCompleted ||
      mostRecentAction.completedIndex !== index
    ) {
      setAction({
        actionId: mostRecentAction.actionId,
        isCompleted: actionIsCompleted,
        syncTimeLoaded: mostRecentAction.loadedAt,
        completedIndex: mostRecentAction.completedIndex
      })
    }
  }

  useEffect(() => {
    checkForActionUpdates()
  }, [])

  // Set current action to daily action with current index
  useEffect(() => {
    if (actionsList?.length > 0) {
      const currentAction = actionsList[currentIndex]
      setAction({ actionId: currentAction.actionId, isCompleted: currentAction.completedAt!! })
    }
  }, [actionsList, currentIndex])

  return {
    id,
    description,
    category,
    caption,
    url,
    label,
    length,
    index,
    isUrl,
    isCompleted,
    loadedAt,
    actions: {
      setAction,
      setToggleCompleteAction,
      generateDailyAction,
      clearAction,
      resetIndex
    }
  }
}

export default useDailyAction
