import UserSue from './Images/sue.jpg'
import UserAmanda from './Images/amanda-gyetvay.jpeg'
import UserAnna from './Images/anna-kolesnikova.jpeg'
import UserYary from './Images/yary-novo.jpeg'
import UserJolene from './Images/jolene-abercromby.jpeg'
import UserKaty from './Images/katy.jpeg'
import UserChrissi from './Images/chrissi.jpg'
import UserLisa from './Images/lisa.jpg'
import UserRo from './Images/ro.jpeg'
import UserMichele from './Images/michele.jpeg'
import UserSherry from './Images/sherry.png'
import UserTammy from './Images/tammy.jpeg'

export { default as Testimonials } from './Testimonials'
export { default as StyledTestimonials } from './Testimonials.Styled'

export { default as UserBubble } from './UserBubble'
export { default as StyledUserBubble } from './UserBubble.Styled'

export type TestimonialType = {
  review: string;
  user: {
    name: string;
    occupation: string;
    image: string;
  };
}

export const COURSE_TESTIMONIALS: TestimonialType[] = [
  {
    review: 'Since S.O.S., I\'ve been making at least 1 sale a DAY.',
    user: {
      name: 'Amanda Gyetvay',
      occupation: 'Pet Supply Subscription Box Owner',
      image: UserAmanda
    }
  },
  {
    review:
      'With S.O.S., I went from being intimidated and spinning my wheels to having clear step-by-step tools and feeling confident that I can apply them to grow presence for my business, attract the right audience, and start selling.',
    user: {
      name: 'Anna Kolesnikova',
      occupation: 'Health & Wellness Coach',
      image: UserAnna
    }
  },
  {
    review:
      'S.O.S. Selling on Social is definitely the best investment I\'ve made for my business in a long time. I finished the entire course with a crystal clear vision of how to build my sales strategy, who I\'m selling to, and when is the right time for me to offer my services.',
    user: {
      name: 'Yary Novo',
      occupation: 'Travel Expert',
      image: UserYary
    }
  },
  {
    review: 'In the last 14 days I\'ve had a 30.5% increase in engagement.',
    user: {
      name: 'Jolene Abercromby',
      occupation: 'Holistic Nutritionist',
      image: UserJolene
    }
  },
  {
    review:
      'I feel more confident in speaking to my ideal customer and creating content that speaks directly to them, subtly leads them to my product, without being a sleazy saleswoman! I am getting 175% more engagement on my posts now, with people saving, commenting and DMing me.',
    user: {
      name: 'Katie Kurn',
      occupation: 'Manifestation Coach',
      image: UserKaty
    }
  }
]

export const TESTIMONIALS: TestimonialType[] = [
  {
    review:
      'Within 3 days of having Social Curator, I made a 30 day social media plan, including 16 reels that I made in 2 hours while my kids napped. I’ve gotten two new wedding inquiries and 5 new followers (& I wasn’t even bad at social media before?!)!!!',
    user: {
      name: 'Michele',
      occupation: 'Photographer',
      image: UserMichele
    }
  },
  {
    review:
      'My Instagram is up by over 1,000%, and a new follower (an ideal client) even messaged to say she finds me inspiring!',
    user: {
      name: 'Katy',
      occupation: 'Life Coach',
      image: UserKaty
    }
  },
  {
    review:
      'I received a solid buyer referral from another broker on Instagram. I got the buyers under contract and we closed in a span of about 4 weeks. Social Media generated a sale of $750k! My highest overall sale to date!',
    user: {
      name: 'Sherry',
      occupation: 'Realtor',
      image: UserSherry
    }
  },
  {
    review: 'My engagement has grown by 500% since I started Social Curator.',
    user: {
      name: 'Tammy',
      occupation: 'Christian Life Coach & Podcast Host',
      image: UserTammy
    }
  },
  {
    review:
      'Once I joined Social Curator, I went all in as I completed the daily challenge. From there, I used the captions and the templates and was able to tailor everything to me and my business pretty seamlessly.',
    user: {
      name: 'Sue',
      occupation: 'Marketing Strategist',
      image: UserSue
    }
  }
]

export const CHALLENGE_TESTIMONIALS: TestimonialType[] = [
  {
    review:
      'OMG!!! Since the challenge started, I’ve gone from a 3% engagement rate to 83.93%!! Thank you for this challenge! It’s been so fun and easy and building the habit to post daily is going to propel my business!',
    user: {
      name: 'Chrissi',
      occupation: 'Jewelry Boutique Owner & Artist',
      image: UserChrissi
    }
  },
  {
    review:
      'The challenge this week has been more than I actually expected. I’ve stayed consistent and have seen a huge jump in my engagement, have some new followers (who aren’t bots!), and have been incredibly inspired.',
    user: {
      name: 'Lisa',
      occupation: 'Cookbook Author',
      image: UserLisa
    }
  },
  {
    review:
      'My engagement was 3.6% before the challenge… as of yesterday... drumroll... it’s 7%!!! That’s a 94% increase! I almost PASSED OUT!',
    user: {
      name: 'Ro',
      occupation: 'Wellness Coach',
      image: UserRo
    }
  }
]
