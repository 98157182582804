import {
  CHALLENGE_SET_DATA,
  CHALLENGE_CHANGE_USER_DATA,
  ChallengeActions,
  ChallengeState
} from './types'
import { GetUserChallenges_getUserChallenges_userChallenge } from '../../graphql/Challenge/__generated__/GetUserChallenges'

export const ChallengeSetDataAction = (
  payload: Omit<ChallengeState, 'loading'>
): ChallengeActions => ({
  type: CHALLENGE_SET_DATA,
  payload
})

export const ChallengeChangeUserDataAction = (
  payload: GetUserChallenges_getUserChallenges_userChallenge
): ChallengeActions => ({
  type: CHALLENGE_CHANGE_USER_DATA,
  payload
})
