import styled from 'styled-components'

type StyledProps = {
  size: 'small' | 'large';
  themeType?: 'light' | 'dark' | 'blend' | 'brand';
  isBlock?: boolean;
}

const StyledSpinner = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isBlock }) =>
    isBlock &&
    `
    width: 100%;
    height: 200px;
  `}

  svg {
    animation: loading 0.5s linear infinite;
    fill: ${({ themeType, theme }) => themeType === 'light' && theme.colors.background}
      ${({ themeType, theme }) => themeType === 'dark' && theme.colors.foreground}
      ${({ themeType }) => themeType === 'blend' && '#00000030'}
      ${({ themeType, theme }) => themeType === 'brand' && theme.colors.green[500]};
    height: ${({ size }) => (size === 'large' ? 44 : 20)}px;
    width: ${({ size }) => (size === 'large' ? 44 : 20)}px;
  }

  @keyframes loading {
    to {
      transform: rotate(360deg);
    }
  }
`

export default StyledSpinner
