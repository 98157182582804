import { gql } from '@apollo/client'

export const galleryCollectionUpdateByIdQuery = gql`
  mutation GalleryCollectionUpdateById($_id: String!, $name: String!) {
    galleryCollectionUpdateById(_id: $_id, record: { name: $name }) {
      record {
        _id
        name
        index
        createdAt
        user
      }
    }
  }
`
