import { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import firebase from 'firebase/app'
import useSegment from '../../components/utils/useSegment'
import { useGenerateDailyAction } from '../../components/DailyAction'
import { AppState } from '../root-reducer'
import { ActionsState, UserDailyActionType } from './types'
import {
  actionsSetActions,
  actionsToggleCompleted,
  actionsSetCompletedAll,
  actionsSetCurrentIndex
} from './actions'
import { userStore } from '../../components/utils/UserContext'
import { DailyActionType } from '../../types'
import { isToday } from '../../components/utils'

const useActionsList = () => {
  const { actionsList, isCompletedAll, currentIndex } = useSelector<AppState, ActionsState>(
    (state) => state.dailyActions
  )
  const { user } = useContext(userStore)
  const { track } = useSegment()
  const dispatch = useDispatch()

  const { actions: { generateActions } } = useGenerateDailyAction()
  const actionsDB = firebase.firestore().collection('daily_actions')
  const userActionsDB = firebase.firestore().collection('user_daily_actions')

  const checkIfCompletedAll = (actions: UserDailyActionType[]) => {
    return actions.filter((action) => !action.completedAt).length === 0
  }

  // Load most recent action from db and return if it was today
  const checkActionsLoadedToday = async () => {
    const userActionRef = await userActionsDB
      .where('userId', '==', user?.id)
      .orderBy('loadedAt', 'desc')
      .limit(1)
      .get()

    if (!userActionRef.docs[0]) {
      return false
    }

    const mostRecentAction = userActionRef.docs[0].data() as UserDailyActionType
    const { loadedAt } = mostRecentAction

    return isToday(loadedAt)
  }

  // Sets the last 3 daily actions to state
  const loadRecentDailyActions = async () => {
    // Load 3 most recent actions from db
    const dailyActions: UserDailyActionType[] = []
    const syncTimeLoaded = firebase.firestore.Timestamp.now()

    const userActionRef = await userActionsDB
      .where('userId', '==', user?.id)
      .orderBy('loadedAt', 'desc')
      .limit(3)
      .get()

    // Sort result by index (ascending) to preserve original order
    const userActionArray = userActionRef.docs.sort((a, b) => a.data().index - b.data().index)

    // If less than three actions were found in db, generate new actions
    if (userActionArray.length < 3) {
      await generateDailyActionsList()
      return
    }

    // If the user has previous actions from within the same day, load them and
    // their completion status as the current actions.
    for (const userAction of userActionArray) {
      const dailyAction = {
        ...userAction.data(),
        id: userAction.id,
        loadedAt: syncTimeLoaded
      } as UserDailyActionType
      dailyActions.push(dailyAction)
    }

    // Set actions in state
    dispatch(
      actionsSetActions({
        actionsList: dailyActions,
        currentIndex: 0,
        isCompletedAll: checkIfCompletedAll(dailyActions)
      })
    )

    // Update loadedAt in db
    for (const dailyAction of dailyActions) {
      const userActionRef = await userActionsDB
        .where('userId', '==', user?.id)
        .where('actionId', '==', dailyAction.actionId)
        .orderBy('loadedAt', 'desc')
        .limit(1)
        .get()

      const userAction = {
        ...userActionRef.docs[0].data(),
        id: userActionRef.docs[0].id
      } as DailyActionType

      await userActionsDB.doc(userAction.id).update({ loadedAt: syncTimeLoaded })
    }
  }

  // Generates three new random daily actions and sets it as daily actions in state
  const generateDailyActionsList = async () => {
    // Generate three daily actions
    const dailyActions: UserDailyActionType[] = []
    const syncTimeLoaded = firebase.firestore.Timestamp.now()
    const randomActions = (await generateActions()) as DailyActionType[]

    for (let i = 0; i < 3; i++) {
      let randomAction = randomActions[i]

      if (!randomAction) {
        const fallBackActionRequest = await actionsDB
          .where(firebase.firestore.FieldPath.documentId(), '>=', actionsDB.doc().id)
          .get()
        randomAction = {
          id: fallBackActionRequest.docs[0].id,
          ...fallBackActionRequest.docs[0].data()
        } as DailyActionType
      }

      const userAction = {
        completedAt: null,
        loadedAt: syncTimeLoaded,
        userId: user?.id,
        actionId: randomAction.id,
        index: i + 1, // adding one to keep it consistent with existing analytics
        category: randomAction.category
      }

      dailyActions.push(userAction)
      await userActionsDB.add(userAction)

      track('Loaded Action', {
        description: randomAction.description,
        description_character_count: randomAction.description.length,
        id: randomAction.id,
        category: randomAction.category,
        length: randomAction.length,
        loaded_at: syncTimeLoaded,
        index: i + 1, // adding one to keep it consistent with existing analytics
        is_url: randomAction.isUrl
      })
    }

    // Set generated actions as daily actions list
    dispatch(
      actionsSetActions({
        actionsList: dailyActions,
        currentIndex: 0,
        isCompletedAll: false
      })
    )

    // Set action to first action in list
    await setActionsCurrentIndex(0)
  }

  const setActionsCurrentIndex = async (index: number) => {
    dispatch(actionsSetCurrentIndex(index))
  }

  // set daily action to next action
  const loadNextAction = async () => {
    await setActionsCurrentIndex(currentIndex + 1)
  }

  // set daily action to prev action
  const loadPreviousAction = async () => {
    await setActionsCurrentIndex(currentIndex - 1)
  }

  const toggleCompleteAction = async (index: number) => {
    dispatch(actionsToggleCompleted(index))
  }

  // Set isCompleteAll to true when all three actions are completed
  const toggleCompleteAll = async (isCompleteAll = true) => {
    for (const action of actionsList) {
      if (!action.completedAt) {
        isCompleteAll = false
      }
    }
    dispatch(actionsSetCompletedAll(isCompleteAll))
  }

  return {
    actionsList,
    currentIndex,
    isCompletedAll,
    actions: {
      checkActionsLoadedToday,
      loadRecentDailyActions,
      generateDailyActionsList,
      loadNextAction,
      loadPreviousAction,
      toggleCompleteAction,
      toggleCompleteAll
    }
  }
}

export default useActionsList
