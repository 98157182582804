import React, { FC, createRef, ReactNode } from 'react'
import {
  ExclamationCircleIcon,
  ExclamationIcon,
  InformationCircleIcon
} from '@heroicons/react/solid'
import { Dialog } from '../..'
import { StyledActionDialog } from '.'

type Props = {
  type?: 'default' | 'warning' | 'error';
  title: string;
  body?: string | ReactNode;
  cancelButtonText?: string;
  confirmButtonText?: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ActionDialog: FC<Props> = ({
  type = 'default',
  title,
  body,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  onConfirm,
  ...rest
}) => {
  const dialogRef = createRef<HTMLDivElement>()

  const getIcon = () => {
    switch (type) {
      case 'error':
        return <ExclamationCircleIcon />
      case 'warning':
        return <ExclamationIcon />
      default:
        return <InformationCircleIcon />
    }
  }

  const handleConfirm = () => {
    // Extends the Dialog component's close method
    (dialogRef.current as any)?.close()
    onConfirm()
  }

  return (
    <Dialog ref={dialogRef} {...rest}>
      <StyledActionDialog type={type}>
        <div className="dialog-header">
          <div className="dialog-message-icon">{getIcon()}</div>
          <span className="dialog-title">{title}</span>
        </div>
        <div className="dialog-message-container">
          {body && <span className="dialog-body">{body}</span>}
        </div>
        <div className="dialog-actions">
          <button className="cancel-btn" onClick={() => (dialogRef.current as any)?.close()}>
            <span>{cancelButtonText}</span>
          </button>
          <button className="confirm-btn" onClick={() => handleConfirm()}>
            <span>{confirmButtonText}</span>
          </button>
        </div>
      </StyledActionDialog>
    </Dialog>
  )
}

export default ActionDialog
