import { GalleryFeedPhotoType, GalleryFeedPhotoStatusType } from '../../types'
import { linkToSizedImage } from '../IssueEntityImage/helpers'
import { theme } from '../utils/theme'
import { SearchGalleryQuery_searchGallery_docs } from '../../graphql/GalleryImage/__generated__/SearchGalleryQuery'

export { default as GalleryView } from './GalleryView'
export { default as StyledGalleryView } from './GalleryView.Styled'

export { default as GalleryPhotoItem } from './GalleryPhotoItem'
export { default as StyledGalleryPhotoItem } from './GalleryPhotoItem.Styled'

export { default as GalleryPhotoItemActions } from './GalleryPhotoItemActions'
export { default as StyledGalleryPhotoItemActions } from './GalleryPhotoItemActions.Styled'

export type FeedTab = 'main' | 'favorites' | string | null

export const GALLERY_SORT_BY = [
  {
    value: 'latest',
    label: 'New'
  },
  {
    value: 'popular',
    label: 'Recommended'
  }
]

export const GALLERY_CUSTOM_SORT_BY = [
  {
    value: 'latest',
    label: 'Recently Added'
  },
  {
    value: 'custom',
    label: 'Custom'
  }
]

const showIsNewBadge = (
  galleryPhotoItem: SearchGalleryQuery_searchGallery_docs
): GalleryFeedPhotoStatusType => {
  if (!galleryPhotoItem?.uploadDate) {
    return ''
  }
  const secondsDiff = Date.now() - new Date(galleryPhotoItem.uploadDate).valueOf()
  const showNew = 86400 * 10 * 1000 // 10 days
  const isNew = secondsDiff < showNew
  return isNew ? 'new' : ''
}

export const transformData = (
  galleryPhotoItem: SearchGalleryQuery_searchGallery_docs
): GalleryFeedPhotoType => {
  const imageHeight = galleryPhotoItem.file.imgSize?.height as number
  const imageWidth = galleryPhotoItem.file.imgSize?.width as number
  const imageUrl = galleryPhotoItem.file.fileHttpLink

  const image350w = `${linkToSizedImage(imageUrl, { width: 350 })}`
  const image500w = `${linkToSizedImage(imageUrl, { width: 500 })}`
  const image650w = `${linkToSizedImage(imageUrl, { width: 650 })}`

  const srcset = [`${image650w} 650w`, `${image500w} 500w`, `${image350w} 350w`].join(', ')
  const sizes = [
    `(min-width: ${theme.size.sm}px) calc(.333 * (90vw - 8px))`,
    `(min-width: ${theme.size.md}px) calc(.333 * (1144px - 8px))`,
    `100vw`
  ].join(', ')

  return {
    ...galleryPhotoItem,
    src: image500w,
    srcset,
    sizes,
    width: imageWidth,
    height: imageHeight,
    status: showIsNewBadge(galleryPhotoItem)
  }
}

export const parseUrlPathToTab = (path: string, parseTo: 'tab' | 'backUrl') => {
  /**
   * path could be one of
   * - /gallery/favorites/image_id
   * - /gallery/collections/collection_id/image_id
   * - /gallery/image_id
   */

  const favorites = /\/gallery\/favorites\/?\w*$/.test(path)
  const collection = path.match(/\/gallery\/collections\/([^/]+)/) || []
  const mainFeed = /\/gallery\/?\w*$/.test(path) && !favorites && !collection[1]

  let tab = 'main'
  let backUrl = '/gallery'
  if (mainFeed) {
    tab = 'main'
    backUrl = '/gallery'
  }
  else if (favorites) {
    tab = 'favorites'
    backUrl = '/gallery/favorites'
  }
  else if (collection[1]) {
    tab = collection[1] || 'main'
    backUrl = `/gallery/collections/${collection[1]}`
  }

  return parseTo === 'tab' ? tab : backUrl
}
