import React from 'react'
import { Provider } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { ThemeProvider } from 'styled-components'
import { ApolloProvider } from '@apollo/client'
import { MetaHelmet } from './MetaHelmet'
import { UserPovider } from './utils/UserContext'
import { TooltipProvider } from './ToolTip/TooltipContext'
import { GlobalStyles } from './utils/global'
import { theme } from './utils/theme'
import AppRouter from './AppRouter'
import store from '../redux/store'
import { Notifications } from './Notifications'
import { SplitProvider } from './utils/SplitContext'
import { AlgoliaProvider } from './Algolia'
import { apolloClient } from './utils/apolloConfig'
import './utils/sentryConfig'

export default function App() {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_ELEMENTS_KEY as string)

  return (
    <Provider store={store}>
      <MetaHelmet />
      <ThemeProvider theme={theme}>
        <AlgoliaProvider>
          <ApolloProvider client={apolloClient}>
            <UserPovider>
              <SplitProvider>
                <Elements
                  stripe={stripePromise}
                  options={{
                    fonts: [
                      {
                        cssSrc:
                          'https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;600;800'
                      }
                    ]
                  }}
                >
                  <GlobalStyles />
                  <Notifications />
                  <TooltipProvider>
                    <AppRouter />
                  </TooltipProvider>
                </Elements>
              </SplitProvider>
            </UserPovider>
          </ApolloProvider>
        </AlgoliaProvider>
      </ThemeProvider>
    </Provider>
  )
}
