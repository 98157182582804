import styled from 'styled-components'

export const StyledFromLayout = styled.div`
  width: 100%;
`

export const StyledFormBody = styled.div<{
  topSpace?: boolean;
}>`
  margin-top: ${({ topSpace }) => (topSpace ? '40px' : '0')};
  background: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  .header {
    width: 100%;
    margin: 30px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};
    background: ${({ theme }) => theme.colors.background};
    display: flex;
    justify-content: space-between;
  }
  .full-page {
    width: 100%;
    padding: 0 30px 40px;
  }
  .inputs {
    margin: 10px 30px 20px 15px;
  }
  .form {
    width: 100%;
    padding: 0 30px;
  }
  .form-item {
    margin: 40px 0;
    &.two-col {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .col:nth-child(1) {
        width: 50%;
        padding-right: 15px;
      }
      .col:nth-child(2) {
        width: 50%;
        padding-left: 15px;
      }
    }
    &:first-of-type {
      margin-top: 20px;
    }
  }
  .row-checkboxes {
    max-width: 400px;
  }
  .space-between,
  .justify-right {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10px 30px 30px;
    justify-content: space-between;
  }
  .subscription-section {
    .statuses {
      display: flex;
      margin: 10px 30px 0 30px;
    }
    div {
      margin-right: 50px;
    }
    h4 {
      font-size: 16px;
      font-weight: 500;
      text-transform: none;
      margin: 0;
    }
    .stripe-input {
      margin-left: 5px;
      width: 100%;
    }
  }
  .justify-right {
    justify-content: flex-end;
  }
  .hidden {
    display: none !important;
  }
  .active-span {
    font-weight: bold;
  }
  .extra-section {
    margin: 0 27px 15px 27px;
  }

  @media (min-width: ${({ theme }) => `${theme.size.sm}px`}) {
    flex-direction: row;
    .form {
      width: 50%;
    }
  }
`

export const StyledFormTabs = styled.div`
  margin: 25px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};
  width: 100%;
  @media (max-width: ${({ theme }) => `${theme.size.sm}px`}) {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .tabs-block {
    margin: 0 20px;
    display: flex;
    flex-wrap: nowrap;
  }

  .link {
    cursor: pointer;
    padding: 18px 25px;
    white-space: nowrap;
    @media (max-width: ${({ theme }) => `${theme.size.sm}px`}) {
      padding: 12px 14px;
    }
    &.active {
      margin-bottom: -1px;
      border-bottom: 3px solid ${({ theme }) => theme.colors.red[600]};
    }
  }
`

export const StyledFormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .loginAsUser,
  .preview {
    font-weight: bold;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
`
