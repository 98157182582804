import { CartItemType, CartItemsType } from '../../types'

export type CartState = {
  cartItems: CartItemType[];
  purchasedItems?: CartItemType[];
  paymentLoading: boolean;
  paymentError: null | string;
  paymentSuccess: boolean;
}

export const SHOPPING_CART_ADD_ITEM = 'SHOPPING_CART_ADD_ITEM'
export type CartAddIssueAction = {
  type: typeof SHOPPING_CART_ADD_ITEM;
  payload: CartItemType;
}

export const SHOPPING_CART_UPDATE_PRICING = 'SHOPPING_CART_UPDATE_PRICING'
export type CartUpdatePricingAction = {
  type: typeof SHOPPING_CART_UPDATE_PRICING;
  payload: {
    id: string;
    priceId: string;
  };
}

export const SHOPPING_CART_REMOVE_ITEM = 'SHOPPING_CART_REMOVE_ITEM'
export type CartRemoveIssueAction = {
  type: typeof SHOPPING_CART_REMOVE_ITEM;
  payload: {
    id: string;
    type: CartItemsType;
  };
}

export const SHOPPING_CART_START_PAYMENT = 'SHOPPING_CART_START_PAYMENT'
export type CartStartPaymentAction = {
  type: typeof SHOPPING_CART_START_PAYMENT;
}

export const SHOPPING_CART_SUCCESS_PAYMENT = 'SHOPPING_CART_SUCCESS_PAYMENT'
export type CartSuccessPaymentAction = {
  type: typeof SHOPPING_CART_SUCCESS_PAYMENT;
}

export const SHOPPING_CART_FAIL_PAYMENT = 'SHOPPING_CART_FAIL_PAYMENT'
export type CartFailPaymentAction = {
  type: typeof SHOPPING_CART_FAIL_PAYMENT;
  payload: string;
}

export const SHOPPING_CART_ABORT_PAYMENT = 'SHOPPING_CART_ABORT_PAYMENT'
export type CartAbortPaymentAction = {
  type: typeof SHOPPING_CART_ABORT_PAYMENT;
}

export type CartActions =
  | CartAddIssueAction
  | CartRemoveIssueAction
  | CartStartPaymentAction
  | CartSuccessPaymentAction
  | CartFailPaymentAction
  | CartAbortPaymentAction
  | CartUpdatePricingAction
