import styled from 'styled-components'

type StyledProps = {
  $loading: boolean;
}

const StyledGalleryPhotoSimilarItem = styled.div<StyledProps>`
  height: 100px;
  ${({ $loading }) => $loading && 'width: 100px;'}
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  position: relative;
  border-radius: 3px;

  img {
    height: 100%;
    border-radius: 3px;
  }

  &::after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    content: '';
    border: 1px solid #0000000f;
    border-radius: 3px;
    pointer-events: none;
  }

  @keyframes pulse {
    0% {
      background: ${({ theme }) => theme.colors.gray[100]};
    }
    50% {
      background: ${({ theme }) => theme.colors.gray[200]};
    }
    100% {
      background: ${({ theme }) => theme.colors.gray[100]};
    }
  }
`

export default StyledGalleryPhotoSimilarItem
