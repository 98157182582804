import { gql } from '@apollo/client'

export const searchStorySets = gql`
  query SearchStorySets($storySet: String, $items: Int = 10, $page: Int!) {
    searchStorySets(storySet: $storySet, items: $items, page: $page) {
      docs {
        _id
        title
        thumbnail
        index
        description
        canvaLink
        issue
        createdAt
        updatedAt
        stories {
          _id
          fullPath
          imageUrl
          index
        }
      }
      totalDocs
    }
  }
`
