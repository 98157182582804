import styled from 'styled-components'

type StyledProps = {
  type: 'error' | 'warning' | 'success';
}

const StyledFormMessage = styled.div<StyledProps>`
  background: ${({ theme }) => theme.colors.gray[100]};
  border-radius: 3px;
  margin-top: 32px;
  padding: 1rem;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: -10px;

  ${({ theme, type }) =>
    type === 'error' &&
    `
    background: ${theme.colors.red[100]};
    color: ${theme.colors.red[600]};
  `}

  ${({ theme, type }) =>
    type === 'warning' &&
    `
    background: ${theme.colors.amber[100]};
    color: ${theme.colors.amber[700]};
  `}

  ${({ theme, type }) =>
    type === 'success' &&
    `
    background: ${theme.colors.green[100]};
    color: ${theme.colors.green[400]};
  `}
  
  a {
    font-weight: 700;
    color: inherit;
    text-decoration: underline;
  }
`

export default StyledFormMessage
