import React, { FC, ReactNode, isValidElement, cloneElement } from 'react'
import { Control, FieldValues } from 'react-hook-form'
import { StyledController } from '.'

type Props = {
  name: string;
  control: Control<FieldValues>;
  component: ReactNode;
  rules?: Record<string, any>;
  [x: string]: any;
}

const Controller: FC<Props> = ({
  name, control, component, rules, ...rest
}) => {
  if (!isValidElement(component)) {
    return <></>
  }

  return (
    <StyledController
      name={name}
      control={control}
      rules={rules}
      render={({ onChange, onBlur, value }: any) =>
        cloneElement(component, {
          name,
          onBlur: (e) => {
            onBlur(e.target ? e.target.value : e)
          },
          onChange: (e) => {
            onChange(e.target ? e.target.value : e)
          },
          value,
          ...rest
        })}
    />
  )
}

export default Controller
