import styled from 'styled-components'

type StyledProps = {
  isFeatured?: boolean;
}

const StyledCommunityItem = styled.a<StyledProps>`
  border-radius: 6px;
  position: relative;
  overflow: hidden;

  .event-featured {
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    align-items: center;
    height: 34px;
    z-index: 1;
    padding: 0 12px 0 10px;
    border-radius: 50rem;
    user-select: none;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px) saturate(1.6);
    color: ${({ theme }) => theme.colors.background}de;
    font-weight: 700;
    letter-spacing: 0.08rem;
    font-size: 13px;

    svg {
      height: 20px;
      margin-right: 6px;
      color: ${({ theme }) => theme.colors.amber[300]};
    }
  }

  .event-thumbnail {
    padding-top: 50%;
    position: relative;
    border-radius: 6px;
    user-select: none;
    pointer-events: none;
    transition: 0.2s;
    background-color: ${({ theme }) => theme.colors.gray[100]};

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      object-fit: cover;
    }
  }

  .event-date {
    display: grid;
    justify-items: center;
    grid-gap: 2px;
    text-align: center;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.background};
    box-shadow: 0px 0px 0px 1px rgba(17, 24, 39, 0.1);
    border-radius: 3px;
    position: absolute;
    user-select: none;
    pointer-events: none;
    top: 12px;
    left: 12px;
    padding: 8px 0;
    width: 50px;

    .event-day {
      font-weight: 400;
      font-size: 18px;
      letter-spacing: -0.02rem;
      color: ${({ theme }) => theme.colors.gray[700]};
    }

    .event-month {
      font-weight: 700;
      font-size: 10px;
      position: relative;
      left: 1px;
      letter-spacing: 0.08rem;
      color: ${({ theme }) => theme.colors.gray[400]};
    }
  }

  .event-details {
    position: absolute;
    user-select: none;
    pointer-events: none;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 25% 12px 12px 12px;
    background: linear-gradient(rgba(0, 0, 0, 0), rgb(0 0 0 / 40%), rgb(0 0 0 / 70%));
    color: ${({ theme }) => theme.colors.background};

    .event-title {
      display: block;
      font-weight: 700;
      font-size: 15px;
      line-height: 17px;
      max-width: 215px;
      letter-spacing: -0.01rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      -webkit-box-orient: vertical;
    }

    .event-date-time {
      display: block;
      font-weight: 600;
      font-size: 14px;
      margin-top: 6px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      opacity: 0.75;
      letter-spacing: -0.01rem;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    z-index: 2;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  }

  &:hover {
    .event-thumbnail {
      transform: scale(1.1);
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    .event-thumbnail {
      padding-top: 100%;
    }
  }
`

export default StyledCommunityItem
