import { DailyActionType } from '../../types'
import {
  ActionsActions,
  ActionsState,
  ACTION_SET_ACTION,
  ACTION_TOGGLE_COMPLETE,
  ACTION_CLEAR_ACTION,
  ACTION_RESET_INDEX,
  ACTIONS_SET_ACTIONS,
  ACTIONS_TOGGLE_COMPLETED,
  ACTIONS_SET_COMPLETED_ALL,
  ACTIONS_SET_INDEX
} from './types'

export const actionSetAction = (payload: DailyActionType): ActionsActions => ({
  type: ACTION_SET_ACTION,
  payload
})

export const actionToggleComplete = () => ({ type: ACTION_TOGGLE_COMPLETE })

export const actionClearAction = () => ({ type: ACTION_CLEAR_ACTION })

export const actionResetIndex = () => ({ type: ACTION_RESET_INDEX })

export const actionsSetActions = (payload: Partial<ActionsState>): ActionsActions => ({
  type: ACTIONS_SET_ACTIONS,
  payload
})

export const actionsSetCurrentIndex = (payload: number): ActionsActions => ({
  type: ACTIONS_SET_INDEX,
  payload
})

export const actionsToggleCompleted = (payload: number): ActionsActions => ({
  type: ACTIONS_TOGGLE_COMPLETED,
  payload
})

export const actionsSetCompletedAll = (payload: boolean): ActionsActions => ({
  type: ACTIONS_SET_COMPLETED_ALL,
  payload
})
