import styled from 'styled-components'

const StyledGalleryCollectionsTile = styled.div`
  margin-bottom: 20px;
  user-select: none;

  .feature-hint {
    height: 67px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 12px;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.gray[100]};
    color: ${({ theme }) => theme.colors.gray[700]};

    .feature-hint-details-container {
      display: flex;
      align-items: center;

      .feature-hint-icon {
        height: 43px;
        width: 43px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 14px;
        flex-shrink: 0;
        box-shadow: 0 0 0 1px #00000021;
        background-color: ${({ theme }) => theme.colors.background};
        color: ${({ theme }) => theme.colors.gray[600]};

        svg {
          height: 26px;

          path {
            stroke-width: 1.7px;
          }
        }
      }

      .feature-hint-details {
        span {
          display: block;
        }

        .feature-hint-title {
          font-weight: 600;
          color: ${({ theme }) => theme.colors.gray[700]};
        }

        .feature-hint-description {
          font-size: 15px;
          margin-top: 3px;
          color: ${({ theme }) => theme.colors.gray[600]};
        }
      }
    }

    button {
      border-radius: 6px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      user-select: none;
      border: none;
      transition: 0.2s;
      padding: 0 14px;
      background-color: ${({ theme }) => theme.colors.background};
      color: ${({ theme }) => theme.colors.gray[600]};
      border: 1px solid  ${({ theme }) => theme.colors.gray[300]};

      span {
        position: relative;
        top: 1px;
      }

      svg {
        height: 18px;
        margin-right: 10px;
      }

      &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.gray[100]};
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    margin-bottom: 45px;
  }
`

export default StyledGalleryCollectionsTile
