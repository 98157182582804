import classNames from 'classnames'
import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { StyledAdminPageNav } from '.'

type Props = {
  title?: string;
  links: {
    label: string;
    value: string;
    isActive?: boolean;
    onClick?: (param?: any) => void;
  }[];
  noLink?: boolean;
}

const AdminPageNav: FC<Props> = ({ title, links, noLink }) => {
  const handleClick = (onClick) => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <StyledAdminPageNav>
      {title && <div className="nav-header">{title}</div>}
      <div className="nav-links">
        {!noLink &&
          links.map((link, index) => {
            return (
              <NavLink to={link.value} key={index} activeClassName="active">
                {link.label}
              </NavLink>
            )
          })}
        {noLink &&
          links.map((link, index) => {
            return (
              <a
                onClick={() => handleClick(link.onClick)}
                key={index}
                className={classNames(link.isActive && 'active')}
              >
                {link.label}
              </a>
            )
          })}
      </div>
    </StyledAdminPageNav>
  )
}

export default AdminPageNav
