import React, { FC, useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useApolloClient } from '@apollo/client'
import ROUTES from '../../utils/routes'
import { InputDialog } from '../../System'
import { galleryCollectionCreateOneMutation } from '../../../graphql/GalleryCollection/galleryCollectionCreateOne'
import { userStore } from '../../utils/UserContext'
import { GalleryCollectionCreateOne } from '../../../graphql/GalleryCollection/__generated__/GalleryCollectionCreateOne'
import { useGalleryFeed } from '../../GalleryView/GalleryFeed'
import { SearchGalleryCollections_searchGalleryCollections_docs } from '../../../graphql/GalleryCollection/__generated__/SearchGalleryCollections'

type Props = {
  isOpen: boolean;
  handleClose: Function;
  dontRedirect?: boolean;
}

const NewCollectionModal: FC<Props> = ({ isOpen, handleClose, dontRedirect }) => {
  const history = useHistory()
  const apollo = useApolloClient()
  const { user } = useContext(userStore)
  const {
    collections,
    actions: { addCollections }
  } = useGalleryFeed()

  const [collectionName, setCollectionName] = useState('')

  const handleCreate = async () => {
    const { data } = await apollo.mutate<GalleryCollectionCreateOne>({
      mutation: galleryCollectionCreateOneMutation,
      fetchPolicy: 'no-cache',
      variables: {
        name: collectionName,
        index: collections.length,
        user: user?.id
      }
    })

    const newDoc = {
      ...data?.galleryCollectionCreateOne?.record,
      countImages: 0
    } as SearchGalleryCollections_searchGalleryCollections_docs

    addCollections([...collections, newDoc])
    handleClose(newDoc._id) // pass back the new collection ID

    if (!dontRedirect) {
      history.push(
        ROUTES.gallery.buildCollectionPage(data?.galleryCollectionCreateOne?.record?._id || '')
      )
    }
  }

  useEffect(() => {
    setCollectionName('')
  }, [isOpen])

  return (
    <InputDialog
      value={collectionName}
      title="Create New Collection"
      confirmButtonText="Save"
      isOpen={isOpen}
      onClose={() => handleClose()}
      onChange={(value) => setCollectionName(value)}
      onConfirm={() => handleCreate()}
    />
  )
}

export default NewCollectionModal
