import styled from 'styled-components'
import { times } from 'lodash'

type StyledProps = {
  columnLength: number;
  columnSpacing?: string;
}

const StyledSortableRow = styled.tr<StyledProps>`
  display: grid;
  grid-template-columns: ${({ columnLength, columnSpacing }) => columnSpacing ? columnSpacing : times(columnLength).map(() => `${100 / columnLength}% `)};
  text-align: left;
  width: 100%;
  min-height: 40px;
  align-items: center;
  border-radius: 3px;
  font-size: 14px;
  background: white;
  position: relative;

  svg {
    height: 20px;
  }

  td {
    color: ${({ theme }) => theme.colors.gray[600]};
    font-weight: 600;
    padding: 1rem;

    a {
      color: ${({ theme }) => theme.colors.gray[800]};
      font-weight: 600;

      &:hover {
        cursor: pointer;
        color: #ED6B6B;
        text-decoration: underline;
      }
    }
  }

  .drag-icon {
    position: absolute;
    left: 1rem;
    color: ${({ theme }) => theme.colors.gray[300]};
  }

  td:first-of-type {
    padding-left: 3.5rem;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};
  }

  &.dragging {
    box-shadow: 0 0 0 1px #00000012, 0 0 15px 0px #00000017;
    z-index: ${({ theme }) => theme.elevations.sticky};
  }
`

export default StyledSortableRow
