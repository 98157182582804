import styled from 'styled-components'

type StyledProps = {
  styleType: 'large' | 'medium' | 'small';
  styleTheme: 'solid' | 'outline' | 'ghost';
  href?: string;
  hasIcon: boolean;
  iconPos?: 'left' | 'right';
  isDisabled?: boolean;
  isIcon?: boolean;
}

const StyledButton = styled.button<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  font-size: 15px;
  border-radius: 3px;
  font-weight: 400;
  font-family: ${({ theme }) => theme.fonts.lexend};
  flex-shrink: 0;
  width: fit-content;
  transition: background-color 0.2s, color 0.2s;
  -webkit-appearance: none !important;
  
  ${({ isIcon }) => (isIcon ? 'flex-shrink: 0;' : '')}

  ${({ styleType, isIcon }) =>
    styleType === 'large' &&
    `
    height: ${isIcon ? '40px' : '48px'};
    ${isIcon ? 'width: 40px;' : ''}
    letter-spacing: 0.08rem;
    text-transform: uppercase;
    padding: ${isIcon ? '0' : '0 30px'};

    svg {
      height: ${isIcon ? '24' : '20'}px;
    }
  `}

  ${({ styleType, isIcon }) =>
    styleType === 'medium' &&
    `
    height: ${isIcon ? '35px' : '40px'};
    ${isIcon ? 'width: 35px;' : ''}
    letter-spacing: 0.08rem;
    text-transform: uppercase;
    padding: ${isIcon ? '0' : '0 25px'};

    svg {
      height: ${isIcon ? '22' : '20'}px;
    }
  `}

  ${({ styleType, isIcon }) =>
    styleType === 'small' &&
    `
    height: ${isIcon ? '26px' : '40px'};
    ${isIcon ? 'width: 26px;' : ''}
    padding: ${isIcon ? '0' : '0 20px'};

    
    svg {
      height: ${isIcon ? '20' : '20'}px;
    }
  `}

  ${({ styleTheme, theme }) =>
    styleTheme === 'solid' &&
    `
    color: ${theme.colors.background};
    background-color: ${theme.colors.gray[900]};

    &:hover {
      background-color: ${theme.colors.gray[700]};
    }
  `}

  ${({ styleTheme, theme }) =>
    styleTheme === 'outline' &&
    `
    color: ${theme.colors.gray[900]};
    border-color: ${theme.colors.gray[300]};
    background-color: ${theme.colors.background};

    &:hover {
      background-color: ${theme.colors.gray[100]};
    }
  `}

  ${({ styleTheme, theme }) =>
    styleTheme === 'ghost' &&
    `
    color: ${theme.colors.gray[900]};
    background-color: transparent;

    &:hover {
      color: ${theme.colors.gray[600]};
    }
  `}

  svg {
    ${({ iconPos }) => iconPos === 'left' && 'margin-right: 10px; margin-left: -8px;'};
    ${({ iconPos }) => iconPos === 'right' && 'margin-left: 10px; margin-right: -8px;'};
  }

  &:hover {
    cursor: pointer;
  }

  ${({ isDisabled, theme }) =>
    isDisabled &&
    `
    cursor: not-allowed;
    background: ${theme.colors.gray[100]};
    color: ${theme.colors.gray[300]};
    border-color: transparent;
    pointer-events: none;
    user-select: none;

    &:hover {
      background: ${theme.colors.gray[100]};
      color: ${theme.colors.gray[300]};
      border-color: transparent;
      cursor: not-allowed;
    }
  `}
`

export default StyledButton
