import { useLayoutEffect, useRef } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const useDisableScroll = (lock?: boolean) => {
  const refBlock = useRef<any>()

  useLayoutEffect(() => {
    if (!refBlock.current) {
      return
    }
    if (lock) {
      refBlock.current.focus()
      disableBodyScroll(refBlock.current)
    }
    else {
      enableBodyScroll(refBlock.current)
    }
    return () => {
      if (refBlock.current) {
        enableBodyScroll(refBlock.current)
      }
    }
  }, [lock])

  return refBlock
}

export default useDisableScroll
