import * as Sentry from '@sentry/react'
import { useContext } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import ROUTES from '../../components/utils/routes'
import useSegment from '../../components/utils/useSegment'
import { userStore } from '../../components/utils/UserContext'
import { parseImageData } from './trackingData'
import { GalleryImageFindOneQuery_galleryImageFindOne } from '../../graphql/GalleryImage/__generated__/GalleryImageFindOneQuery'
import {
  SearchGalleryQuery_searchGallery_docs,
  SearchGalleryQuery
} from '../../graphql/GalleryImage/__generated__/SearchGalleryQuery'
import { galleryImagesLikeRemoveManyMutation } from '../../graphql/GalleryImagesLikes/galleryImagesLikesRemoveMany'
import { galleryImagesLikeAndIncrementMutation } from '../../graphql/GalleryImagesLikes/galleryImagesLikeAndIncrementMutation'
import useGalleryFeed from './useGalleryFeed'
import { searchGalleryQuery } from '../../graphql/GalleryImage/searchGallery'
import { galleryImagesDownloadIncrementMutation } from '../../graphql/GalleryImage/galleryImagePopularity'

const useGallery = () => {
  const {
    activeCollection,
    data,
    activeTab,
    search,
    sortBy,
    color,
    orientation,
    category,
    style,
    actions: { addImages }
  } = useGalleryFeed()

  const apollo = useApolloClient()
  const { user } = useContext(userStore)
  const { track } = useSegment()
  const match = useRouteMatch()
  const isFavoritesPage = useRouteMatch(ROUTES.gallery.favorites)?.isExact

  const trackUnlike = (imageId: string) => {
    track('Unfavorited Image', {
      removed_from: activeCollection ? 'collection' : activeTab,
      in_modal: match.path === ROUTES.gallery.photoPage[0],
      original_photo_url: `${window.location.host}/gallery/${imageId}`,
      ...parseImageData(imageId, data)
    })
  }

  const trackLike = (imageId: string) => {
    track('Favorited Image', {
      added_from: activeCollection ? 'collection' : activeTab,
      in_modal: match.path === ROUTES.gallery.photoPage[0],
      original_photo_url: `${window.location.host}/gallery/${imageId}`,
      sorted_by: sortBy,
      style_filter: style,
      color_filter: color,
      category_filter: category,
      orientation_filter: orientation,
      search,
      ...parseImageData(imageId, data)
    })
  }

  /**
   * Hide an image when it's unliked on the favorites page
   */
  const handleUnlikeInFavorites = (photoId: string) => {
    if (isFavoritesPage) {
      addImages(data.filter((image) => image._id !== photoId))
    }
  }

  const handleView = async (photo: GalleryImageFindOneQuery_galleryImageFindOne) => {
    track('Viewed Gallery Image', {
      viewed_from: activeCollection ? 'collection' : activeTab,
      original_photo_url: `${window.location.host}/gallery/${photo._id}`,
      sorted_by: sortBy,
      style_filter: style,
      color_filter: color,
      category_filter: category,
      orientation_filter: orientation,
      search,
      ...parseImageData(photo._id, data)
    })
  }

  const handleDownload = async (
    photo: GalleryImageFindOneQuery_galleryImageFindOne | SearchGalleryQuery_searchGallery_docs
  ) => {
    try {
      await apollo.mutate({
        mutation: galleryImagesDownloadIncrementMutation,
        variables: { _id: photo._id }
      })

      track('Downloaded Gallery Image', {
        downloaded_from: activeCollection ? 'collection' : activeTab,
        in_modal: match.path === ROUTES.gallery.photoPage[0],
        original_photo_url: `${window.location.host}/gallery/${photo._id}`,
        sorted_by: sortBy,
        style_filter: style,
        color_filter: color,
        category_filter: category,
        orientation_filter: orientation,
        search,
        ...parseImageData(photo._id, data)
      })

      track('Downloaded Photo', {
        id: photo._id,
        url: photo.file.fileHttpLink,
        from: 'gallery'
      })
    }
    catch (err) {
      console.error(err)
      Sentry.captureException(err)
    }
  }

  const handleToggleLike = async (photoId: string, isLiked: boolean) => {
    try {
      if (!user?.id) {
        return
      }

      // If a like already exists, DISLIKE the photo
      if (isLiked) {
        handleUnlikeInFavorites(photoId)
        await apollo.mutate({
          mutation: galleryImagesLikeRemoveManyMutation,
          variables: { image: photoId, user: user.id }
        })
        trackUnlike(photoId)
        return isLiked
      }

      // If a like does not already exist, LIKE the photo
      trackLike(photoId)
      const { data: search } = await apollo.query<SearchGalleryQuery>({
        query: searchGalleryQuery,
        fetchPolicy: 'no-cache',
        variables: {
          search: '',
          sort: 'latest',
          categories: [],
          style: [],
          colors: [],
          items: 1,
          collection: null,
          liked: true,
          page: 0
        }
      })
      await apollo.mutate({
        mutation: galleryImagesLikeAndIncrementMutation,
        variables: {
          imageId: photoId,
          record: {
            image: photoId,
            user: user.id,
            index: search.searchGallery?.totalDocs || 0
          }
        }
      })

      return !isLiked
    }
    catch (err) {
      Sentry.captureException(err)
      console.error(err)
    }
  }

  return {
    actions: {
      handleDownload,
      handleView,
      handleToggleLike
    }
  }
}

export default useGallery
