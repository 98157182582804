import React, { FC, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import parser from 'query-string'
import moment from 'moment-timezone'
import ROUTES from '../../utils/routes'
import { Logo } from '../../AppLayout/Header'
import { Heading, Button } from '../../System'
import { Testimonials, TESTIMONIALS, CHALLENGE_TESTIMONIALS } from './Testimonials'
import { WidthController } from '../../Grid'
import { StyledMarketing } from '.'
import { AppState } from '../../../redux/root-reducer'
import { ChallengeState } from '../../../redux/challenge/types'
import { CHALLENGE } from '../../utils/stripePrices'

const Marketing: FC = () => {
  const [isMobileVisible, setIsMobileVisible] = useState(true)
  const { currentChallenge } = useSelector<AppState, ChallengeState>((state) => state.challenge)
  const { search } = useLocation()
  const { plan } = parser.parse(search)

  const showChallengeCopy = plan === CHALLENGE && currentChallenge

  return (
    <StyledMarketing
      isMobileVisible={isMobileVisible}
      className="auth-marketing"
      background="linear-gradient(135deg, rgba(249, 225, 180, 0.4) 2.88%, rgba(182, 224, 245, 0.4) 100%)"
    >
      <WidthController noHeight noPadding maxWidth={560}>
        <div className="text-container">
          <Logo />
          {!showChallengeCopy && (
            <Heading>Simple Resources to Market Your Business on Social Media</Heading>
          )}
          {showChallengeCopy && (
            <>
              <Heading>{currentChallenge?.name}</Heading>
              <p className="challenge-description">{currentChallenge?.description}</p>
              <p className="challenge-deadline">
                ⏰ Register by:{' '}
                {moment(currentChallenge?.joinBy)
                  .tz('America/Los_Angeles')
                  .format('MMMM D, YYYY [at] hA zz')}
              </p>
            </>
          )}
          <Testimonials testimonials={showChallengeCopy ? CHALLENGE_TESTIMONIALS : TESTIMONIALS} />

          <div className="button-prompt">
            <Button type="small" className="scroll-btn" onClick={() => setIsMobileVisible(false)}>
              Sign Up
            </Button>
            <span className="auth-prompt">
              Already have an account? <Link to={ROUTES.user.login}>Sign In</Link>
            </span>
          </div>
        </div>
      </WidthController>
    </StyledMarketing>
  )
}

export default Marketing
