import React, {
  FC, useCallback, useEffect, useRef, useState
} from 'react'
import * as Sentry from '@sentry/react'
import { PhotographIcon, SwitchHorizontalIcon } from '@heroicons/react/outline'
import { useApolloClient } from '@apollo/client'
import { Button } from '../../System'
import { genSizedImage } from '../../IssueEntityImage/helpers'
import { StyledGalleryCollectionItemPreview } from '.'
import { searchGalleryQuery } from '../../../graphql/GalleryImage/searchGallery'
import { SearchGalleryQuery } from '../../../graphql/GalleryImage/__generated__/SearchGalleryQuery'

type Props = {
  preview: string | null;
  collectionId: string;
  editMode: boolean;
}

const GalleryCollectionItemPreview: FC<Props> = ({ preview, collectionId, editMode }: Props) => {
  const timer = useRef<any>(null)
  const indexPreview = useRef(0)
  const apollo = useApolloClient()

  const [currentPreview, setCurrentPreview] = useState(preview)

  const previews = useRef<string[] | null>(null)
  const loadPreviews = async () => {
    try {
      const { data } = await apollo.query<SearchGalleryQuery>({
        query: searchGalleryQuery,
        fetchPolicy: 'no-cache',
        variables: {
          search: '',
          orientation: null,
          sort: 'latest',
          categories: [],
          style: [],
          colors: [],
          items: 10,
          collection: collectionId,
          liked: null,
          page: 0
        }
      })

      const imageFiles = data.searchGallery?.docs?.map((doc) => doc.file) || []
      const sizedPreviews = imageFiles.map((imageFile) => {
        const { url: sizedPreviewUrl } = genSizedImage({
          url: imageFile.fileHttpLink || '',
          width: imageFile.imgSize?.width as number,
          height: imageFile.imgSize?.height as number,
          maxSize: 400
        })
        return sizedPreviewUrl
      })
      previews.current = sizedPreviews
    }
    catch (err) {
      Sentry.captureException(err)
      console.log(err)
    }
  }

  const flipImages = () => {
    if (!previews.current?.length) {
      return
    }
    if (previews.current?.length <= indexPreview.current) {
      indexPreview.current = 0
    }
    setCurrentPreview(previews.current[indexPreview.current])
    indexPreview.current++
  }

  const startPreview = useCallback(() => {
    if (editMode) {
      return
    }
    if (timer.current) {
      clearInterval(timer.current)
    }
    if (!previews.current) {
      loadPreviews()
    }
    timer.current = setInterval(flipImages, 700)
  }, [flipImages, editMode])

  const clearTimer = useCallback(() => {
    if (timer.current) {
      clearInterval(timer.current)
    }
  }, [])

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearInterval(timer.current)
      }
    }
  }, [])

  return (
    <StyledGalleryCollectionItemPreview>
      {currentPreview && (
        <img onMouseOver={startPreview} onMouseOut={clearTimer} src={currentPreview} alt="" />
      )}
      {!currentPreview && !editMode && <PhotographIcon className="no-image-icon" />}
      {editMode && (
        <div className="reorder-prompt">
          <Button theme="outline" isIcon>
            <SwitchHorizontalIcon />
          </Button>
          <span>Drag to reorder</span>
        </div>
      )}
    </StyledGalleryCollectionItemPreview>
  )
}

export default GalleryCollectionItemPreview
