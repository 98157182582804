import React, { FC } from 'react'
import { StyledNotifications, useNotifications, Notification } from '.'

const Notifications: FC = () => {
  const { data, removeNotification, zIndex } = useNotifications()

  if (!data || !data.length) {
    return null
  }

  return (
    <StyledNotifications className="notifications">
      <div className="body">
        {data.map((item, index) => (
          <Notification
            key={item.key}
            type={item.type}
            zIndex={zIndex}
            index={index}
            message={item.message}
            onRemove={() => removeNotification(item.key)}
          />
        ))}
      </div>
    </StyledNotifications>
  )
}

export default Notifications
