import styled from 'styled-components'

const StyledDeletionConfirmation = styled.dialog`
  all: unset;
  position: fixed;
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: ${({ theme }) => theme.elevations.override}99;
  top: 0;
  left: 0px;
  right: 0;
  bottom: 0;
  height: 100%;
  padding: 1rem;
  box-sizing: border-box;

  .modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: ${({ theme }) => theme.elevations.backdrop};
    background-color: rgba(10, 13, 23, 0.85);
  }

  .modal {
    padding: 32px;
    display: grid;
    grid-gap: 32px;
    z-index: ${({ theme }) => theme.elevations.modal};
    background-color: ${({ theme }) => theme.colors.background};
    border: 1px solid ${({ theme }) => theme.colors.gray[400]};
    box-shadow: 0px 8px 16px 2px rgba(97, 97, 97, 0.1);
    border-radius: 10px;
    outline: none;

    b {
      font-family: ${({ theme }) => theme.fonts.lexend};
      font-size: 17px;
      color: ${({ theme }) => theme.colors.gray[900]};
      display: block;
      margin-bottom: 8px;
    }

    span {
      font-family: ${({ theme }) => theme.fonts.nunito};
      color: ${({ theme }) => theme.colors.gray[600]};
      display: block;
    }

    .modal-actions {
      display: grid;
      grid-gap: 1rem;

      .btn {
        height: 45px;
        font-family: ${({ theme }) => theme.fonts.lexend};
        font-weight: 500;
        padding: 0 22px;
        font-size: 14px;
        letter-spacing: 0.0835rem;
      }

      .cancel-btn {
        flex-shrink: 0;
        width: 100%;
        color: ${({ theme }) => theme.colors.gray[400]};
        background-color: ${({ theme }) => theme.colors.gray[100]};

        &:hover {
          background-color: ${({ theme }) => theme.colors.gray[200]};
        }
      }

      .confirm-btn {
        width: 100%;
        color: ${({ theme }) => theme.colors.background};
        background-color: ${({ theme }) => theme.colors.green[500]};

        &:hover {
          background-color: ${({ theme }) => theme.colors.red[600]};
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    // padding-left: 70px;

    .modal {
      .modal-actions {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
        grid-gap: 1rem;
      }
    }
  }
`

export default StyledDeletionConfirmation
