import React, { FC, useState } from 'react'
import { HeartIcon, PlusIcon } from '@heroicons/react/outline'
import useGallery from '../../redux/gallery/useGallery'
import useDraft from '../../redux/draft-drawer/useDraft'
import { linkToSizedImage } from '../IssueEntityImage/helpers'
import { StyledPhotoPreviewItem } from '.'
import useSegment from '../utils/useSegment'
import { SearchGalleryQuery_searchGallery_docs } from '../../graphql/GalleryImage/__generated__/SearchGalleryQuery'

type Props = {
  photo: SearchGalleryQuery_searchGallery_docs;
  onClick: (photo: SearchGalleryQuery_searchGallery_docs) => void;
  [x: string]: any;
}

const PhotoPreviewItem: FC<Props> = ({ photo, onClick, ...props }) => {
  const { actions: { handleDownload, handleToggleLike } } = useGallery()
  const { track } = useSegment()
  const { actions: { setDraftImage } } = useDraft()

  const [isLiked, setIsLiked] = useState(!!photo.liked)
  const photoId = photo?._id

  const likeImage = async () => {
    setIsLiked(!isLiked)
    await handleToggleLike(photo._id, !!photo.liked)
  }

  const handleAddToDraft = () => {
    setDraftImage(photo.file?.fileHttpLink || '', photoId)
    track('Added Photo to Quick Draft', {
      id: photoId,
      url: photo.file?.fileHttpLink,
      from: 'dashboard'
    })
  }

  return (
    <StyledPhotoPreviewItem isLiked={isLiked} {...props} className="photo-preview-item scroll-item">
      <div className="photo-preview-image" onClick={() => onClick(photo)}>
        <img
          src={linkToSizedImage(photo.file?.fileHttpLink, { width: 400 })}
          role="presentation"
          alt=""
        />
      </div>
      <button className="use-btn" onClick={() => handleAddToDraft()}>
        <PlusIcon />
        <span>USE</span>
      </button>
      <button className="fav-btn" onClick={() => likeImage()}>
        <HeartIcon />
      </button>
      <div className="actions-row">
        <span className="new-label">NEW</span>
      </div>
    </StyledPhotoPreviewItem>
  )
}

export default PhotoPreviewItem
