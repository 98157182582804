import styled from 'styled-components'

type StyledProps = {
  isError?: boolean;
  isDisabled?: boolean;
}

const StyledConnectionsSelectItem = styled.label<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  margin: -4px;
  padding: 4px;
  border-radius: 4px;
  transition: 0.2s;

  .share-btn {
    margin-right: -11px;
    margin-left: 8px;
    color: ${({ theme }) => theme.colors.gray[400]};
  }

  button {
    padding: 0 12px;
    height: 36px;
    border-radius: 6px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.gray[300]};
    color: ${({ theme }) => theme.colors.gray[500]};
    transition: 0.2s;
    background-color: ${({ theme }) => theme.colors.background};

    svg {
      height: 20px;
      flex-shrink: 0;
    }

    &:hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.gray[200]};
    }

    &.delete-btn {
      svg {
        margin: 0 -4px;
      }
    }

    &.expired-btn {
      border: 1px solid ${({ theme }) => theme.colors.red[600]};
      color: ${({ theme }) => theme.colors.red[600]};

      &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.red[100]};
      }
    }
  }

  .connections-select-details-container {
    display: flex;
    align-items: center;

    .connections-select-avatar {
      position: relative;
      margin-right: 10px;

      .error-icon {
        position: absolute;
        bottom: -2px;
        right: -2px;
        width: 20px;
        height: 20px;
        background-color: ${({ theme }) => theme.colors.background};
        border-radius: 50%;
        color: ${({ theme }) => theme.colors.red[700]};

        svg {
          height: 20px;
        }
      }
    }

    .connections-select-details {
      .connections-select-detail-name {
        font-weight: 600;
        color: ${({ theme }) => theme.colors.gray[600]};
      }

      .connections-select-detail-social {
        display: flex;
        align-items: center;
        margin-top: 2px;
        text-transform: capitalize;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.gray[500]};
        font-size: 14px;

        svg {
          margin-right: 4px;
        }

        .exclamation-icon {
          height: 16px;
        }

        .connections-select-error {
          text-transform: none;
          display: flex;
          align-items: center;
          font-weight: 600;
          color: ${({ theme }) => theme.colors.red[700]};
        }
      }
    }
  }

  .connections-select-actions {
    display: flex;
    align-items: center;

    button:not(:last-child) {
      margin-right: 8px;
    }
  }

  .toggle {
    .toggle-box {
      height: 25px;
      width: 50px;

      input {
        &:checked {
          & + .toggle-indicator {
            background: ${({ theme }) => theme.colors.background};
            left: 29px;
          }
        }
      }

      .toggle-indicator {
        height: 17px;
        width: 17px;
      }
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[200]}90;
  }

  ${({ isError, isDisabled, theme }) =>
    !isError &&
    isDisabled &&
    `
    .toggle {
      .toggle-box {
        .toggle-indicator {
          background: ${({ theme }) => theme.colors.background};
        }
      }
    }

    .connections-select-details-container {
      .connections-select-avatar {
        .avatar {
          opacity: 0.3;
          filter: grayscale(1);
        }
      }

      .connections-select-details {
        .connections-select-detail-name {
          color: ${theme.colors.gray[400]};
        }
  
        .connections-select-detail-social {
          color: ${theme.colors.gray[400]};
        }
      }
    }

    &:hover {
      cursor: auto;
      background-color: transparent;
    }
  `}
`

export default StyledConnectionsSelectItem
