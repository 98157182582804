import { CAPTION_CATEGORY_LIST } from '../../types'
import { UserDraftFindById_userDraftFindById } from '../../graphql/UserDraft/__generated__/UserDraftFindById'

export type DraftImage = {
  imageUrl: string;
  userUpload?: boolean;
  imageId?: string;
  fullPath?: string;
}

export type DraftShareModalType = {
  show: boolean;
  isRescheduling?: boolean;
}

export type DraftCharacterCount = 'instagram' | 'facebook' | 'linkedin' | 'youtube' | 'pinterest'
export type DraftCategoryType = typeof CAPTION_CATEGORY_LIST[number] | 'Uncategorized' | null

export type DraftState = {
  _id?: string;
  title?: string;
  captionText?: string;
  imageUrl?: string;
  userUpload?: boolean;
  imageId?: string;
  imageDimensions?: {
    x: number;
    y: number;
  };
  fullPath?: string;
  category?: DraftCategoryType;
  isLiked?: boolean;
  isUsed?: boolean;
  template?: string;
  challenge?: string;
  postDate?: Date;
  createdAt?: Date;
  lastModified?: Date;
  characterCountType?: DraftCharacterCount;
  isSaved: boolean;
  isOpened: boolean;
  shareModal?: DraftShareModalType;
  index?: number;
  isNew?: boolean;
  socialProfiles?: string[];
}

export const DRAFT_SET_DRAFT = 'DRAFT_SET_DRAFT'
export type DraftSetDraft = {
  type: typeof DRAFT_SET_DRAFT;
  payload: UserDraftFindById_userDraftFindById;
}

export const DRAFT_CLEAR_DRAFT = 'DRAFT_CLEAR_DRAFT'
export type DraftClearDraft = {
  type: typeof DRAFT_CLEAR_DRAFT;
  payload: string;
}

export const DRAFT_SET_TITLE = 'DRAFT_SET_TITLE'
export type DraftSetTitle = {
  type: typeof DRAFT_SET_TITLE;
  payload: string;
}

export const DRAFT_SET_IMAGE = 'DRAFT_SET_IMAGE'
export type DraftSetImage = {
  type: typeof DRAFT_SET_IMAGE;
  payload: DraftImage;
}

export const DRAFT_CLEAR_IMAGE = 'DRAFT_CLEAR_IMAGE'
export type DraftClearImage = {
  type: typeof DRAFT_CLEAR_IMAGE;
}

export const DRAFT_SET_CAPTION = 'DRAFT_SET_CAPTION'
export type DraftSetCaption = {
  type: typeof DRAFT_SET_CAPTION;
  payload: string;
}

export const DRAFT_SET_CATEGORY = 'DRAFT_SET_CATEGORY'
export type DraftSetCategory = {
  type: typeof DRAFT_SET_CATEGORY;
  payload: DraftCategoryType;
}

export const DRAFT_SET_DATE = 'DRAFT_SET_DATE'
export type DraftSetDate = {
  type: typeof DRAFT_SET_DATE;
  payload: Date;
}

export const DRAFT_CLEAR_DATE = 'DRAFT_CLEAR_DATE'
export type DraftClearDate = {
  type: typeof DRAFT_CLEAR_DATE;
}

export const DRAFT_SET_CHARACTER_COUNT_TYPE = 'DRAFT_SET_CHARACTER_COUNT_TYPE'
export type DraftSetCharacterCountType = {
  type: typeof DRAFT_SET_CHARACTER_COUNT_TYPE;
  payload: DraftCharacterCount;
}

export const DRAFT_SET_SAVED = 'DRAFT_SET_SAVED'
export type DraftSetSaved = {
  type: typeof DRAFT_SET_SAVED;
  payload: boolean;
}

export const DRAFT_SET_OPENED = 'DRAFT_SET_OPENED'
export type DraftSetOpened = {
  type: typeof DRAFT_SET_OPENED;
  payload: boolean;
}

export const DRAFT_SET_SOCIAL_PROFILES = 'DRAFT_SET_SOCIAL_PROFILES'
export type DraftSetSocialProfiles = {
  type: typeof DRAFT_SET_SOCIAL_PROFILES;
  payload: string[];
}

export const DRAFT_SET_IMAGE_DIMENSIONS = 'DRAFT_SET_IMAGE_DIMENSIONS'
export type DraftSetImageDimensions = {
  type: typeof DRAFT_SET_IMAGE_DIMENSIONS;
  payload: { x: number; y: number };
}

export const DRAFT_CLEAR_IMAGE_DIMENSIONS = 'DRAFT_CLEAR_IMAGE_DIMENSIONS'
export type DraftSetClearDimensions = {
  type: typeof DRAFT_CLEAR_IMAGE_DIMENSIONS;
}

export const DRAFT_SET_SHOW_SHARE_MODAL = 'DRAFT_SET_SHOW_SHARE_MODAL'
export type DraftSetShowShareModal = {
  type: typeof DRAFT_SET_SHOW_SHARE_MODAL;
  payload: DraftShareModalType;
}

export type DraftActions =
  | DraftSetDraft
  | DraftClearDraft
  | DraftSetTitle
  | DraftSetImage
  | DraftClearImage
  | DraftSetCaption
  | DraftSetCategory
  | DraftSetDate
  | DraftClearDate
  | DraftSetCharacterCountType
  | DraftSetSaved
  | DraftSetOpened
  | DraftSetSocialProfiles
  | DraftSetImageDimensions
  | DraftSetClearDimensions
  | DraftSetShowShareModal
