import styled from 'styled-components'

type StyledProps = {
  buttonType: string;
}

const StyledIssuePaginationButton = styled.button<StyledProps>`
  border-radius: 50rem;
  cursor: pointer;

  ${({ buttonType, theme }) =>
    (buttonType === 'arrow'
      ? `
    width: 50px;
    height: 50px;
    display: flex;
    padding: 0;
    border: 1px solid transparent;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.gray[800]};
    background: ${theme.colors.background};
    box-shadow: 0px 4px 8px rgba(97, 97, 97, 0.18), 0px 2px 8px rgba(97, 97, 97, 0.18);

    :hover {
      background: ${theme.colors.gray[200]};
    }

    @media (max-width: ${theme.size.sm}px) {
      width: 40px;
      height: 40px;
      
      span {
        font-size: 20px;
      }
    }
  `
      : buttonType === 'bubble'
        ? `
    width: 10px;
    height: 10px;
    padding: 0;
    border: 1px solid transparent;
    background: ${theme.colors.gray[200]};

    :hover {
      background: ${theme.colors.gray[300]};
    }
  `
        : buttonType === 'sort' &&
        `
    height: 35px;
    padding: 0 1rem;
    background: ${theme.colors.background};
    border: 2px solid ${theme.colors.gray[300]};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    
    span {
      color: ${theme.colors.gray[500]};
      display: block;
      align-items: center;
    }

    i {
      font-size: 19px;
      line-height: 0;
      position: relative;
      margin-right: 6px;
      top: 3px;
    }

    :hover {
      background: ${theme.colors.cream[100]};
    }
  `)}
`
export default StyledIssuePaginationButton
