import {
  DraftState,
  DraftActions,
  DRAFT_SET_DRAFT,
  DRAFT_CLEAR_DRAFT,
  DRAFT_SET_TITLE,
  DRAFT_SET_IMAGE,
  DRAFT_CLEAR_IMAGE,
  DRAFT_SET_CAPTION,
  DRAFT_SET_CHARACTER_COUNT_TYPE,
  DRAFT_SET_SAVED,
  DRAFT_SET_OPENED,
  DRAFT_SET_CATEGORY,
  DRAFT_SET_DATE,
  DRAFT_CLEAR_DATE,
  DRAFT_SET_SOCIAL_PROFILES,
  DRAFT_SET_IMAGE_DIMENSIONS,
  DRAFT_CLEAR_IMAGE_DIMENSIONS,
  DRAFT_SET_SHOW_SHARE_MODAL
} from './types'

const initialState: DraftState = {
  _id: '',
  title: 'Untitled Draft',
  captionText: '',
  imageUrl: '',
  userUpload: false,
  characterCountType: 'instagram',
  isUsed: false,
  isSaved: true,
  isOpened: false,
  isNew: true,
  shareModal: { show: false },
  socialProfiles: []
}

export const draftReducer = (state = initialState, action: DraftActions): DraftState => {
  switch (action.type) {
    case DRAFT_SET_DRAFT:
      return {
        ...state,
        _id: action.payload._id,
        title: action.payload.title || undefined,
        imageUrl: action.payload.imageUrl || undefined,
        userUpload: action.payload.userUpload || undefined,
        imageId: action.payload.imageId || undefined,
        fullPath: action.payload.fullPath || undefined,
        category: action.payload?.category as any,
        isLiked: action.payload.isLiked || undefined,
        isUsed: action.payload.isUsed || undefined,
        postDate: action.payload.postDate,
        captionText: action.payload.captionText || undefined,
        createdAt: action.payload.createdAt,
        lastModified: action.payload.lastModified,
        index: action.payload.index || undefined,
        socialProfiles: (action?.payload?.socialProfiles as string[]) || [],
        imageDimensions: action.payload?.imageDimensions as any,
        isSaved: true,
        isNew: false
      }
    case DRAFT_CLEAR_DRAFT:
      return {
        ...state,
        _id: action.payload,
        title: 'Untitled Draft',
        imageUrl: '',
        userUpload: false,
        imageId: '',
        fullPath: '',
        category: 'Uncategorized',
        isLiked: undefined,
        isUsed: undefined,
        postDate: undefined,
        captionText: '',
        createdAt: undefined,
        lastModified: undefined,
        index: undefined,
        socialProfiles: undefined,
        imageDimensions: undefined,
        isSaved: true,
        isNew: true
      }
    case DRAFT_SET_TITLE:
      return {
        ...state,
        title: action.payload,
        isSaved: false
      }
    case DRAFT_SET_IMAGE:
      return {
        ...state,
        imageUrl: action.payload.imageUrl,
        userUpload: !!action.payload.fullPath,
        imageId: action.payload.imageId,
        fullPath: action.payload.fullPath,
        isSaved: false
      }
    case DRAFT_CLEAR_IMAGE:
      return {
        ...state,
        imageUrl: '',
        userUpload: false,
        imageId: '',
        fullPath: '',
        isSaved: false
      }
    case DRAFT_SET_CAPTION:
      return {
        ...state,
        captionText: action.payload,
        isSaved: false
      }
    case DRAFT_SET_CATEGORY:
      return {
        ...state,
        category: action.payload,
        isSaved: false
      }
    case DRAFT_SET_DATE:
      return {
        ...state,
        postDate: action.payload,
        isSaved: false
      }
    case DRAFT_CLEAR_DATE:
      return {
        ...state,
        postDate: undefined,
        isSaved: false
      }
    case DRAFT_SET_CHARACTER_COUNT_TYPE:
      return {
        ...state,
        characterCountType: action.payload
      }
    case DRAFT_SET_SAVED:
      return {
        ...state,
        isSaved: action.payload
      }
    case DRAFT_SET_OPENED:
      return {
        ...state,
        isOpened: action.payload
      }
    case DRAFT_SET_SOCIAL_PROFILES:
      return {
        ...state,
        socialProfiles: action.payload
      }
    case DRAFT_SET_IMAGE_DIMENSIONS:
      return {
        ...state,
        imageDimensions: action.payload
      }
    case DRAFT_CLEAR_IMAGE_DIMENSIONS:
      return {
        ...state,
        imageDimensions: undefined
      }
    case DRAFT_SET_SHOW_SHARE_MODAL:
      return {
        ...state,
        shareModal: action.payload
      }
    default:
      const x: never = action
  }
  return state
}
