import styled from 'styled-components'

type StyledProps = {
  width?: number;
  padding?: number;
  customWidth?: boolean;
}

const StyledScrollRow = styled.div<StyledProps>`
  position: relative;
  width: 100%;

  .scroll-container {
    scroll-snap-type: x mandatory;
    display: flex;
    width: 100%;
    overflow-x: auto;

    .scroll-item, .skeleton-item {
      ${({ width, customWidth }) => !customWidth && width && `width: ${width}px`};
      scroll-snap-align: start;
      flex-shrink: 0;
      
      &:not(:last-child) {
        margin-right: ${({ padding }) => padding ? `${padding}px` : '1rem'};
      }
    }

    .skeleton-item {
      ${({ customWidth, width }) => !customWidth && width && `height: ${width}px`};
      padding: 0;
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }
`

export default StyledScrollRow
