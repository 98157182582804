import styled from 'styled-components'

type Props = {
  iconPickerMenuPos: { top: number; left: number };
}

const StyledIconPicker = styled.div<Props>`
  .icon-picker-menu {
    position: absolute;
    top: ${({ iconPickerMenuPos }) => iconPickerMenuPos.top}px;
    left: ${({ iconPickerMenuPos }) => iconPickerMenuPos.left}px;
    padding: 8px;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    background-color: ${({ theme }) => theme.colors.background};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    z-index: ${({ theme }) => theme.elevations.tooltip};
    grid-gap: 2px;

    .icon-pick-btn {
      background-color: ${({ theme }) => theme.colors.gray[100]};
      color: ${({ theme }) => theme.colors.gray[500]};

      svg {
        height: 24px;
      }

      &:hover {
        color: ${({ theme }) => theme.colors.gray[600]};
        background-color: ${({ theme }) => theme.colors.gray[200]};
      }

      &.active {
        background-color: ${({ theme }) => theme.colors.red[100]};
        color: ${({ theme }) => theme.colors.green[500]};
      }
    }
  }

  .icon-picker {
    height: 40px;
    width: 40px;
    border: 1px solid ${({ theme }) => theme.colors.gray[300]};
    flex-shrink: 0;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.gray[500]};

    svg {
      height: 24px;
    }
  }
`

export default StyledIconPicker
