/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import throttle from 'lodash/throttle'
import { theme } from '../utils/theme'

export default function useWindowSize() {
  const isClient = typeof window === 'object'
  function getSize(): string {
    if (window.innerWidth < theme.size.xs) {
      return 'xs'
    } if (window.innerWidth < theme.size.sm) {
      return 'sm'
    } if (window.innerWidth < theme.size.md) {
      return 'md'
    }
    return 'lg'
  }
  const [windowSize, setWindowSize] = useState(getSize)
  useEffect(() => {
    if (!isClient) {
      return
    }
    const handleResize = throttle(() => {
      setWindowSize(getSize())
    }, 333)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}
