import React, {
  FC, useState, useEffect, useContext, useMemo
} from 'react'
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/solid'
import firebase from 'firebase/app'
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  StyledOnboardingView,
  QuestionBox,
  ProgressBar,
  OnboardingQuestion,
  onboardingInitialState,
  OnboardingState
} from '.'
import { Button } from '../System'
import questions from './questions'
import { useNotifications } from '../Notifications'
import { userStore } from '../utils/UserContext'
import { SplitContext } from '../utils/SplitContext'
import useSegment from '../utils/useSegment'
import { AppState } from '../../redux/root-reducer'
import { ChallengeState } from '../../redux/challenge/types'

const OnboardingView: FC = () => {
  const { user, loaded } = useContext(userStore)
  const updateLoggedInUser = firebase.functions().httpsCallable('updateLoggedInUser')
  const { currentChallenge, userChallenge } = useSelector<AppState, ChallengeState>(
    (state) => state.challenge
  )
  const { addNotification } = useNotifications()
  const { track } = useSegment()
  const { state } = useLocation() as any
  const history = useHistory()

  const [processing, setProcessing] = useState(false)
  const [show, setShow] = useState(false)
  const [pageNum, setPageNum] = useState<null | number>(null)
  const [formState, setFormState] = useState<OnboardingState>(onboardingInitialState)

  // we are designating the final "thank you" page's
  // page number as the length of the questions array, so
  // it will always be displayed after the final question.
  const finalPageNum = questions.length

  const isFirstPage = useMemo(() => pageNum === null, [pageNum])
  const isLastPage = useMemo(() => pageNum === finalPageNum, [pageNum, finalPageNum])

  const {
    isReady,
    isEnabled,
    treatments: { onboarding }
  } = useContext(SplitContext)

  const onboardingEnabled = isReady && isEnabled(onboarding)

  const findExperience = (computedScore) => {
    if (computedScore > 12) {
      return 'advanced'
    }

    if (computedScore > 9) {
      return 'intermediate'
    }

    return 'beginner'
  }

  const findScore = (questions: OnboardingQuestion[]): number => {
    const keys = ['postFrequency', 'customerFrequency', 'confidenceLevel']
    let score = 0

    keys.forEach((key) => {
      // loop through questions
      questions.forEach((question) => {
        question.answers.forEach((ans) => {
          // when the answer's value is found, add to score
          if (formState[key] === ans.value) {
            score += ans.score || 0
          }
        })
      })
    })
    return score
  }

  const handleSubmit = async () => {
    setProcessing(true)

    try {
      const computedScore = findScore(questions)

      const experienceLevel = findExperience(computedScore)

      await updateLoggedInUser({ onboarding: formState, experience: experienceLevel })

      track('User Completed Onboarding', {
        product: formState.product,
        industry: formState.industry,
        post_frequency: formState.postFrequency,
        customer_frequency: formState.customerFrequency,
        confidence_level: formState.confidenceLevel,
        primary_platform: formState.primaryPlatform,
        preferred_feature: formState.preferredFeature,
        custom_industry: formState.customIndustry,
        experience: experienceLevel
      })

      addNotification({
        message: 'Successfully completed onboarding.',
        type: 'success',
        interval: 5000
      })

      if (state?.redirect) {
        window.location.replace(state.redirect)
        return
      }
    }
    catch (err) {
      addNotification({
        message: err.message,
        type: 'error',
        interval: 5000
      })
    }

    // if we're in a challenge, we need to redirect to the course
    if (!!userChallenge && currentChallenge?.redirect) {
      history.push(currentChallenge?.redirect)
    }

    setShow(false)
    setProcessing(false)
  }

  const changeState = (key: string, value: string) => {
    setFormState({
      ...formState,
      [key]: value
    })
  }

  const handleNextQuestion = () => {
    if (isFirstPage) {
      setPageNum(0)
      return
    }

    if (!isLastPage) {
      setPageNum(pageNum! + 1)
      return
    }

    handleSubmit()
  }

  const handlePrevQuestion = () => {
    if (pageNum === 0) {
      setPageNum(null)
      return
    }
    setPageNum(pageNum! - 1)
  }

  const handleButtonText = (pageNum) => {
    switch (pageNum) {
      case null:
        return 'Start'
      case finalPageNum:
        return 'Let\'s get started'
      default:
        return 'Continue'
    }
  }

  const currentQuestionData = useMemo(() => {
    if (!isFirstPage && !isLastPage) {
      const currentQuestion = questions[pageNum!]
      const currentQuestionId = currentQuestion.id
      const currentQuestionData: string = formState[currentQuestionId]

      // if 'Other' is chosen, base currentQuestionData on customIndustry property
      if (currentQuestionData === 'Other') {
        return formState.customIndustry
      }

      return currentQuestionData
    }
  }, [pageNum, formState])

  useEffect(() => {
    if (loaded && user && !user?.trial) {
      setShow(!user.onboarding)
    }
  }, [loaded])

  if (!show || !onboardingEnabled) {
    return null
  }

  return (
    <StyledOnboardingView>
      <div className="onboarding-wrap">
        <div className="question-wrap">
          <div className="question-header">
            {isFirstPage ? (
              <>
                <h1 className="header">Welcome to Social Curator</h1>
                <h3 className="subheader">
                  Before you get started, help us get to know you so we can serve you best!{' '}
                </h3>
              </>
            ) : (
              <>
                <button className="back-button" onClick={() => handlePrevQuestion()}>
                  <ArrowLeftIcon />
                </button>
                <ProgressBar progress={(pageNum! / 7) * 100} />
              </>
            )}
          </div>

          {!isFirstPage && !isLastPage && (
            <QuestionBox
              question={questions[pageNum!]}
              formState={formState}
              setFormState={changeState}
            />
          )}

          {isLastPage && <h3 className="thank-you">Thank you!</h3>}
        </div>
        <Button
          type="large"
          theme="solid"
          className="onboarding-button"
          icon={<ArrowRightIcon />}
          iconPos="right"
          isDisabled={!isFirstPage && !isLastPage && !currentQuestionData}
          isLoading={processing}
          onClick={() => handleNextQuestion()}
        >
          {handleButtonText(pageNum)}
        </Button>
      </div>
    </StyledOnboardingView>
  )
}

export default OnboardingView
