import React, { FC, useEffect, useContext, useState } from 'react'
import { useHistory, useLocation, matchPath } from 'react-router'
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/solid'
import { userStore } from '../../utils/UserContext'
import ROUTES from '../../utils/routes'
import { Button, Dialog } from '../../System'
import StyledPlatformPreviewModal from './PlatformPreviewModal.Styled'
import { CloseButton } from '../../System/CloseButton'
import { allowedPages } from '../../PrivateRoute'
import { VideoEmbed } from '../../VideoEmbed'
import usePricing from '../../../redux/pricing/usePricing'
import { monthPrice } from '../../utils/stripePrices'

// Platform Preview Modals
// This shows up for inactive users who try to navigate any of the main tabs on the side bar.
const PlatformPreviewModal: FC = () => {
  const [showModal, setShowModal] = useState(false)
  const location: { state: { from: string }; pathname: string } = useLocation()
  const history = useHistory()
  const { user } = useContext(userStore)
  const { pricing } = usePricing()

  const monthlyPrice = pricing.find((price) => price.id === monthPrice)
  const isExpiredTrialUser = user?.hasTrialed && user?.stripeSubscriptionStatus === 'Inactive'

  useEffect(() => {
    if (user?.stripeSubscriptionStatus === 'Inactive') {
      const landedOnAllowedPage = allowedPages.some((page) => {
        const match = matchPath(location.pathname, {
          path: page as string,
          strict: false
        })

        return match
      })

      if (!landedOnAllowedPage) {
        setShowModal(true)
      }
    }
  }, [location.pathname])

  return (
    <Dialog isOpen={showModal} onClose={() => setShowModal(false)}>
      <StyledPlatformPreviewModal>
        <div className="main-column">
          <div className="modal-header">
            <div className="modal-header-main">
              <h3 className="modal-title">
                {isExpiredTrialUser
                  ? `Hey ${user?.displayName}, it looks like your free trial expired.`
                  : `Hey ${user?.displayName}, it looks like  you don’t have a subscription to Social Curator`}{' '}
              </h3>
              <CloseButton onClick={() => setShowModal(false)} className="mobile-close-button" />
            </div>
            <h4 className="modal-subtitle">But don't worry, we can fix that!</h4>
          </div>
          <div className="modal-content">
            <div className="modal-list">
              <h4>Join today and you'll get,</h4>
              <ul>
                <li className="features">
                  <CheckCircleIcon /> Daily bite-sized Actions
                </li>
                <li className="features">
                  <CheckCircleIcon /> Group coaching and masterclasses
                </li>
                <li className="features">
                  <CheckCircleIcon /> A library of 3000+ customizable caption templates
                </li>
                <li className="features">
                  <CheckCircleIcon /> Thousands of Lifestyle photos
                </li>
                <li className="features">
                  <CheckCircleIcon /> 200+ Instagram Story Templates customizable in Canva to fit
                  your brand
                </li>
                <li className="features">
                  <CheckCircleIcon /> Over 50+ step-by-step marketing Action Plans that will help
                  you take your business to the next level
                </li>
              </ul>
            </div>
          </div>
          <div className="modal-footer">
            <div className="info-bubble">
              <InformationCircleIcon />
              <p>
                Get Access to Social Curator Today for just $
                {monthlyPrice?.sale?.valueAfterDiscount || monthlyPrice?.price}
              </p>
            </div>
            <Button
              onClick={() => {
                history.push(ROUTES.user.settings.plan)
                setShowModal(false)
              }}
            >
              Upgrade
            </Button>
          </div>
        </div>
        <div className="media-column">
          <div className="media-header">
            <img src="/logo.png" className="logo" />
            <CloseButton onClick={() => setShowModal(false)} className="desktop-close-button" />
          </div>
          <div className="video-wrap">
            <VideoEmbed
              url="https://player.vimeo.com/video/721114941?h=dae895b7ec&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              title="SC Demo"
            />
          </div>
        </div>
      </StyledPlatformPreviewModal>
    </Dialog>
  )
}

export default PlatformPreviewModal
