import React, { FC, useMemo } from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import { CombinedUser } from '../../User'
import { AdminTable } from '../AdminTable'
import { Grid } from '../../System'
import { UserCourseTableRow } from '.'
import { courseFindMany } from '../../../graphql/Courses/courseFindMany'
import { CourseFindMany } from '../../../graphql/Courses/__generated__/CourseFindMany'

type Props = {
  user: CombinedUser;
}

const UserCoursesTable: FC<Props> = ({ user }: Props) => {
  const { loading, data, refetch } = useQuery<CourseFindMany>(courseFindMany, {
    fetchPolicy: 'no-cache',
    variables: {
      page: 0,
      items: 10,
      userId: user.id
    }
  })

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: '_id',
        Cell: ({
          cell: { value },
          row: { original: { title, isOwned } }
        }: any) => <UserCourseTableRow id={value} title={title} user={user} isPurchased={isOwned} />
      }
    ],
    [data?.courseFindMany?.docs]
  )

  return (
    <Grid gap={16} inLayout>
      <StyledTable>
        <AdminTable
          countDocs={data?.courseFindMany?.totalDocs || 0}
          columns={tableColumns}
          data={data?.courseFindMany?.docs || []}
          fetchData={refetch}
          loading={loading}
          pageCount={data?.courseFindMany?.totalPages}
        />
      </StyledTable>
    </Grid>
  )
}

const StyledTable = styled.div`
  .title-cell {
    display: flex;
    .title-label {
      margin-left: 20px;
    }
  }
`

export default UserCoursesTable
