import styled from 'styled-components'

const StyledIssueGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 4rem;
  padding: 0 4rem;
  margin: 2rem 0;

  .issue-grid-item {
    height: fit-content;
  }

  .issue-about-container {
    margin-top: -1rem;
  }

  .issue-grid .issue-about-container *:not(button) {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.gray[800]};
    margin: 0;
  }

  .issue-grid p + p {
    margin-top: 1rem !important;
  }
  
  .issue-action-bar {
    background-color: ${({ theme }) => theme.colors.gray[100]};
    border-radius: 10px;
    padding: 0 36px;
    height: 65px;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .issue-action-bar-title {
    font-weight: 700;
    font-size: 15px;
    color: ${({ theme }) => theme.colors.gray[600]};
  }

  .issue-action-bar button {
    width: 40px;
    height: 23px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    padding: 0;
    align-items: center;
    background: transparent;
    color: ${({ theme }) => theme.colors.gray[600]};
  }

  .issue-action-bar-title-btn {
    width: fit-content !important;
  }

  .issue-action-bar button.last-child {
    margin-right: -13px;
  }

  .issue-about-container button {
    margin: 0 auto;
    position: relative;
    width: 100%;
    color: ${({ theme }) => theme.colors.gray[300]};
    font-size: 13px;
    background: transparent;
  }

  @media (max-width: ${({ theme }) => theme.size.sm}px) {
    padding: 0 1rem;

    .issue-grid {
      padding: 0;
      grid-template-columns: 100%;
      grid-auto-flow: dense;
      margin: 1rem 0;
      grid-gap: 3rem;
    }

    .issue-highlights-title {
      font-size: 0;
    }

    #issue-about-content {
      box-shadow: 0px 8px 16px rgb(97 97 97 / 10%);
      padding-bottom: 19px;
    }

    .issue-header {
      padding: 2rem 1rem;
    }

    .issue-about-container {
      grid-row: 2;
    }

    .issue-about-container button {
      font-weight: 600;
    }
  }

    
`
export default StyledIssueGrid
