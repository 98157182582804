import { FC, useEffect } from 'react'
import { starWars, rickAndMorty, whatAreYouDoing } from './art'

const AsciiArt: FC = () => {
  const ascii = [starWars, rickAndMorty, whatAreYouDoing]
  const index = Math.floor(Math.random() * ascii.length)

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      console.log(ascii[index])
    }
  }, [])

  return null
}

export default AsciiArt
