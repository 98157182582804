import styled from 'styled-components'

const StyledDeleteUserModal = styled.div`
  max-width: 375px;
  width: 100vw;
  padding: 28px;

  .dialog-header {
    display: flex;
    align-items: center;

    .dialog-message-icon {
      flex-shrink: 0;
      margin-right: 12px;
      height: 28px;
      width: 28px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }) => theme.colors.red[200]};
      color: ${({ theme }) => theme.colors.red[900]};

      svg {
        height: 22px;
      }
    }

    .dialog-title {
      font-weight: 700;
      font-size: 17px;
      color: ${({ theme }) => theme.colors.red[900]};
    }
  }

  .dialog-message-container {
    margin-top: 12px;

    .dialog-body {
      line-height: 22px;
      margin-top: 3px;
      color: ${({ theme }) => theme.colors.gray[600]};

      p:not(:first-child) {
        margin-top: 10px;
      }

      a {
        font-weight: 600;
        text-decoration: underline;
        border-radius: 2px;
        transition: 0.2s;
        color: ${({ theme }) => theme.colors.blue[700]};

        &:hover {
          box-shadow: 0 0 0 3px #e0f2fe;
          background-color: ${({ theme }) => theme.colors.blue[100]};
        }
      }
    }
  }

  .dialog-actions {
    margin-top: 28px;
    display: flex;

    button {
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: transparent;
      padding: 0 18px;
      transition: 0.2s;
      border-radius: 6px;
      user-select: none;
      font-weight: 600;
      background-color: ${({ theme }) => theme.colors.gray[100]};
      color: ${({ theme }) => theme.colors.gray[600]};

      span {
        position: relative;
        top: 1px;
      }

      &:not(:last-child) {
        margin-right: 16px;
        flex-shrink: 0;
      }

      &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.gray[200]};
      }
    }

    .confirm-btn {
      width: 100%;
      background-color: ${({ theme }) => theme.colors.red[700]};
      color: ${({ theme }) => theme.colors.background};
      transition: background-color 200ms linear;

      &:disabled {
        background-color: ${({ theme }) => theme.colors.red[300]};
        color: ${({ theme }) => theme.colors.background};
      }

      span {
        position: relative;
        top: 1px;
      }

      svg {
        height: 18px;
        margin-right: 8px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.red[800]};
      }
    }
  }
`

export default StyledDeleteUserModal
