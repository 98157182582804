import React, {
  FC, useContext, useState, createRef, useMemo
} from 'react'
import * as Sentry from '@sentry/react'
import { useMutation } from '@apollo/client'
import {
  HeartIcon,
  HashtagIcon,
  PlusIcon,
  CheckIcon,
  XIcon,
  CogIcon
} from '@heroicons/react/outline'
import { useHistory } from 'react-router-dom'
import useClickOutsideComponent from '../utils/useClickOutsideComponent'
import RenderSlate from '../Slate/RenderSlate'
import useDraft from '../../redux/draft-drawer/useDraft'
import StyledLibraryCaption from './LibraryCaption.Styled'
import { useWindowSize } from '../Grid'
import { CaptionSetType } from '../../types'
import { getPlainText } from '../Slate/slate'
import { upsertUserCaptionMutation } from '../../graphql/UserCaption/upsertUserCaption'
import { useNotifications } from '../Notifications'
import { userStore } from '../utils/UserContext'
import ROUTES from '../utils/routes'
import useSegment from '../utils/useSegment'
import useCaptions from '../../redux/caption-library/useCaptions'
import { SearchCaptions_captionSetSearch_docs } from '../../graphql/CaptionSet/__generated__/SearchCaptions'
import { DraftCategoryType } from '../../redux/draft-drawer/types'

type Props = {
  caption: SearchCaptions_captionSetSearch_docs;
  isPreview?: boolean;
  [x: string]: any;
}

const LibraryCaption: FC<Props> = ({ caption, isPreview, ...rest }) => {
  const [upsertUserCaption] = useMutation(upsertUserCaptionMutation)
  const { addNotification } = useNotifications()
  const { track } = useSegment()
  const windowSize = useWindowSize()
  const {
    search, categories, hideUsed, onlyFavorites, sort
  } = useCaptions()
  const { actions: { setDraftCaption, setDraftCategory } } = useDraft()
  const { user } = useContext(userStore)
  const history = useHistory()
  const canReveal = useMemo(() => windowSize === 'sm' || windowSize === 'xs', [windowSize])
  const [revealed, setRevealed] = useState(false)
  const [liked, setLiked] = useState(!!caption?.userCaption?.liked)
  const [used, setUsed] = useState(!!caption?.userCaption?.used)

  const captionRef = createRef<HTMLDivElement>()

  useClickOutsideComponent(captionRef, () => {
    setRevealed(false)
  })

  const handleLike = async (e: any, isLiked: boolean) => {
    e.stopPropagation()
    setLiked(isLiked)
    await updateUserCaptionHandler({ liked: isLiked })

    if (isLiked) {
      track('Favorited Caption', {
        favorited_from: 'library',

        id: caption._id,
        issue_id: caption.issue,
        issue_title: caption.issueTitle,
        title: caption.title,
        caption: caption.captionPlainText,
        category: caption.category,

        search,
        sorted_by: sort,
        liked_filter: onlyFavorites,
        used_filter: hideUsed,
        category_filter: categories
      })
    }
  }

  const handleUsed = async (isUsed: boolean, addedToDraft = false) => {
    setUsed(isUsed)
    await updateUserCaptionHandler({ used: isUsed })

    if (isUsed) {
      track('Marked Caption Used', {
        used_from: 'library',
        added_to_draft: addedToDraft,

        id: caption._id,
        issue_id: caption.issue,
        issue_title: caption.issueTitle,
        title: caption.title,
        caption: caption.captionPlainText,
        category: caption.category,

        search,
        sorted_by: sort,
        liked_filter: onlyFavorites,
        used_filter: hideUsed,
        category_filter: categories
      })
    }
  }

  const handleSendToDrafts = async () => {
    setDraftCaption(getPlainText({ children: JSON.parse(caption.caption) }))
    setDraftCategory((caption.category || null) as DraftCategoryType)
    await handleUsed(true, true)
  }

  const updateUserCaptionHandler = async (record: Partial<CaptionSetType['userCaption']>) => {
    try {
      await upsertUserCaption({
        variables: {
          record: {
            liked: caption.userCaption?.liked || false,
            used: caption.userCaption?.used || false,
            ...record,
            user: user?.id,
            captionSetId: caption._id
          }
        }
      })
    }
    catch (err) {
      addNotification({ message: err.message, type: 'error', interval: 10000 })
      Sentry.captureException(err)
    }
  }

  return (
    <StyledLibraryCaption
      ref={captionRef}
      onClick={() => setRevealed(!revealed)}
      revealed={revealed}
      canReveal={canReveal}
      liked={liked}
      used={used}
      isPreview={isPreview}
      data-tour-id="caption"
      {...rest}
    >
      <div className="caption-header">
        <span className="caption-title">{caption.title}</span>
        <div className="caption-header-group">
          {caption.category && !isPreview && (
            <div className="caption-category">
              <HashtagIcon />
              {caption.category}
            </div>
          )}
          {used && (
            <button className="used-btn" onClick={() => handleUsed(!used)}>
              <CheckIcon className="check-icon" />
              <XIcon className="x-icon" />
            </button>
          )}
          {user?.role === 'admin' && (
            <button
              className="admin-btn"
              onClick={() =>
                history.push(
                  ROUTES.admin.issues.editCaptionById(caption.issue, caption._id as string)
                )}
            >
              <CogIcon />
            </button>
          )}
        </div>
      </div>
      <RenderSlate className="caption-content" html={caption.caption} />
      <div className="caption-actions">
        <button
          data-tour-id="caption-use-btn"
          className="use-btn"
          onClick={() => handleSendToDrafts()}
        >
          <PlusIcon />
          <span>USE</span>
        </button>
        <button className="fav-btn" onClick={(e) => handleLike(e, !liked)}>
          <HeartIcon />
        </button>
      </div>
    </StyledLibraryCaption>
  )
}

export default LibraryCaption
