import React, {
  FC, useState, useEffect, useMemo, useContext
} from 'react'
import { CheckIcon, ExternalLinkIcon } from '@heroicons/react/outline'
import { useReward } from 'react-rewards'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { StyledDailyAction, DummyAction } from '.'
import useDailyAction from '../../redux/daily-actions/useDailyAction'
import useActionsList from '../../redux/daily-actions/useActionsList'
import { getCategoryInfo } from './getCategoryInfo'
import { SplitContext } from '../utils/SplitContext'

const DailyAction: FC = () => {
  const {
    description,
    category,
    url,
    isCompleted,
    actions: { setToggleCompleteAction, clearAction }
  } = useDailyAction()

  const {
    isCompletedAll,
    currentIndex,
    actions: {
      checkActionsLoadedToday,
      loadRecentDailyActions,
      generateDailyActionsList,
      loadNextAction,
      loadPreviousAction,
      toggleCompleteAll
    }
  } = useActionsList()

  const {
    isReady,
    isEnabled,
    treatments: { resetDailyAction }
  } = useContext(SplitContext)

  const canResetActions = useMemo(() => {
    return isReady && isEnabled(resetDailyAction)
  }, [isReady, isEnabled])

  const { reward } = useReward('reward', 'confetti', {
    angle: -90,
    startVelocity: 18,
    spread: 220,
    elementCount: 150
  })

  const [loading, setLoading] = useState(true)
  const [loadingToggle, setLoadingToggle] = useState(false)

  // Load daily actions from db
  const loadDailyActions = async () => {
    setLoading(true)
    await clearAction()
    await loadRecentDailyActions()
    setLoading(false)
  }

  // Clear actions and generate new ones
  const restartDailyActions = async () => {
    setLoading(true)
    await clearAction()
    await generateDailyActionsList()
    setLoading(false)
  }

  // Complete daily action
  const toggleCompleteAction = async () => {
    setLoadingToggle(true)
    await setToggleCompleteAction()

    if (currentIndex <= 1 && !isCompleted) {
      await loadNextAction()
    }

    setLoadingToggle(false)
  }

  // Resets the daily action if admin holds `ctrl + shift + Q`
  const handleKeyDown = (e: any) => {
    if (!canResetActions) {
      return
    }

    if (e.key === 'Q' && e.ctrlKey && e.shiftKey) {
      restartDailyActions()
    }
  }

  const initDailyActions = async () => {
    // Check user's most recent actions to see when they were last loaded
    const isLoadedToday = await checkActionsLoadedToday()

    // If last action was NOT loaded today, restart daily actions
    if (!isLoadedToday) {
      restartDailyActions()
      return
    }

    // If actions were loaded today, load and set actions from db
    loadDailyActions()
  }

  useEffect(() => {
    initDailyActions()
  }, [])

  useEffect(() => {
    if (!loading && isCompletedAll) {
      reward()
    }
  }, [loading, isCompletedAll])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [canResetActions])

  return (
    <StyledDailyAction step={currentIndex + 1} isCompleted={isCompleted}>
      {loading && <DummyAction />}
      {!loading && !isCompletedAll && (
        <>
          <div className="daily-action-left">
            <div className="daily-action-icon-group">
              <div className="daily-action-icon">{getCategoryInfo(category).icon}</div>
              <div className="daily-action-step">
                <div />
                <div />
                <div />
              </div>
            </div>
            <div className="daily-action-info-container">
              <div className="daily-action-info">
                <span className="daily-action-category">
                  {getCategoryInfo(category).label.toUpperCase()}
                </span>
                <button
                  disabled={currentIndex === 0}
                  className="daily-action-pagination-btn"
                  onClick={() => loadPreviousAction()}
                >
                  <ChevronLeftIcon />
                </button>
                <button
                  disabled={currentIndex > 1}
                  className="daily-action-pagination-btn"
                  onClick={() => loadNextAction()}
                >
                  <ChevronRightIcon />
                </button>
              </div>
              <span className="daily-action-text">
                <span>{description}</span>
                {url && (
                  <a href={url} rel="noopener noreferrer" target="_blank">
                    Go to Content <ExternalLinkIcon />
                  </a>
                )}
              </span>
            </div>
          </div>
          <button
            disabled={loadingToggle}
            className="complete-btn"
            onClick={() => toggleCompleteAction()}
          >
            <CheckIcon />
          </button>
        </>
      )}

      {!loading && isCompletedAll && (
        <>
          <div className="action-completed-left">
            <button className="back-btn" onClick={() => toggleCompleteAll(false)}>
              <ChevronLeftIcon />
            </button>
            <span className="celebration-text">You crushed it! You've finished all 3 actions.</span>
          </div>
          <span className="confetti-icon">🎉</span>
        </>
      )}
      <div className="reward" id="reward" />
    </StyledDailyAction>
  )
}

export default DailyAction
