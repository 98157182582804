import React, { FC, useState, useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { useApolloClient } from '@apollo/client'
import { PlusIcon, FolderOpenIcon } from '@heroicons/react/outline'
import { DashboardTile } from '../../../Dashboard'
import { StyledGalleryCollectionsTile, GalleryCollectionsItem } from '.'
import ROUTES from '../../../utils/routes'
import { ScrollRow } from '../../../System'
import { SkeletonLoader } from '../../../SkeletonLoader'
import {
  SearchGalleryCollections,
  SearchGalleryCollections_searchGalleryCollections_docs
} from '../../../../graphql/GalleryCollection/__generated__/SearchGalleryCollections'
import { searchGalleryCollectionsQuery } from '../../../../graphql/GalleryCollection/searchGalleryCollections'
import { NewCollectionModal } from '../../../Modals'

const GalleryCollectionsTile: FC = () => {
  const apollo = useApolloClient()
  const [loading, setLoading] = useState(true)
  const [showCreateCollectionModal, setShowCreateCollectionModal] = useState(false)
  const [collections, setCollections] = useState<
    SearchGalleryCollections_searchGalleryCollections_docs[]
  >([])

  const loadCollections = async () => {
    try {
      setLoading(true)

      const { data } = await apollo.query<SearchGalleryCollections>({
        query: searchGalleryCollectionsQuery,
        fetchPolicy: 'no-cache',
        variables: { page: 0, items: 9, search: '' }
      })

      setCollections(data.searchGalleryCollections?.docs || [])
    }
    catch (err) {
      console.error(err)
      Sentry.captureException(err)
    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadCollections()
  }, [])

  return (
    <>
      <StyledGalleryCollectionsTile>
        <DashboardTile title="Your Collections" url={{ path: ROUTES.gallery.collections }}>
          {!loading && !collections.length && (
            <div className="feature-hint">
              <div className="feature-hint-details-container">
                <div className="feature-hint-icon">
                  <FolderOpenIcon />
                </div>
                <div className="feature-hint-details">
                  <span className="feature-hint-title">Stay Organized, Strategically</span>
                  <span className="feature-hint-description">
                    Create collections of your favorite gallery images.
                  </span>
                </div>
              </div>
              <button
                className="feature-hint-action-btn"
                onClick={() => setShowCreateCollectionModal(true)}
              >
                <PlusIcon />
                Create Collection
              </button>
            </div>
          )}

          {(!!collections.length || loading) && (
            <ScrollRow itemsToShow={5}>
              {!loading &&
                collections.map((collection) => {
                  return <GalleryCollectionsItem collection={collection} key={collection._id} />
                })}
              {loading && <SkeletonLoader type="collection-list-item" quantity={9} />}
            </ScrollRow>
          )}
        </DashboardTile>
      </StyledGalleryCollectionsTile>
      <NewCollectionModal
        isOpen={showCreateCollectionModal}
        handleClose={() => setShowCreateCollectionModal(false)}
      />
    </>
  )
}

export default GalleryCollectionsTile
