import styled from 'styled-components'

const StyledAdminBreadcrumbs = styled.div`
  color: ${({ theme }) => theme.colors.gray[600]};
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
  overflow-x: scroll;
  margin-right: 2rem;

  &::-webkit-scrollbar {
    display: none;
  }

  a {
    letter-spacing: -.1px;
    color: inherit;
    font-weight: 500;
    &:hover {
      color: ${({ theme }) => theme.colors.gray[700]};
    }
  }

  span {
    padding: 0 8px;
  }

  p {
    display: inline-block;
    margin-block-start: 0;
    margin-block-end: 0;
  }
`

export default StyledAdminBreadcrumbs
