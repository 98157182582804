import React, { FC, useEffect, useState, RefObject } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { StyledIssuePdf } from '.'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

type Props = {
  issueRef: RefObject<HTMLDivElement>;
  pdf: string;
  preview?: boolean;
}

const IssuePdf: FC<Props> = ({ pdf, issueRef, preview }) => {
  const [numPages, setNumPages] = useState<number | null>(null)
  const [pageWidth, setPageWidth] = useState<number>()

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(preview ? 2 : numPages)
  }

  const pdfSizer = () => {
    if (issueRef.current) {
      const windowWidth = issueRef.current.getBoundingClientRect().width
      setPageWidth(windowWidth)
    }
  }

  // Function that resizes the pdf pages on window resize. The timeout is to prevent
  // spamming the PDF resize function. It will only call the pdfSizer function if the
  // user stops resizing the window (100 ms).
  let resizeTimeout
  const resizeDone = () => {
    clearTimeout(resizeTimeout)
    resizeTimeout = setTimeout(pdfSizer, 100)
  }
  useEffect(() => {
    window.addEventListener('resize', resizeDone)
    pdfSizer()
    return () => window.removeEventListener('resize', resizeDone)
  }, [])

  return (
    <StyledIssuePdf>
      <Document externalLinkTarget="_blank" file={pdf} fillWidth onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} width={pageWidth} />
        ))}
      </Document>
    </StyledIssuePdf>
  )
}

export default IssuePdf
