import styled from 'styled-components'

const StyledIssuePhoto = styled.div`
  .image {
    width: 100%;
    padding-top: 100%;
    position: relative;
    transition: 0.2s;

    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    button {
      height: 34px;
      display: flex;
      position: absolute;
      align-items: center;
      border: none;
      background-color: transparent;
      padding: 0;
      transition: 0.2s;
      pointer-events: all;
      user-select: none;
      border-radius: 6px;

      &:hover {
        cursor: pointer;
      }
    }

    .use-btn {
      padding: 0 10px;
      left: 12px;
      bottom: 12px;
      z-index: 1;
      font-weight: 600;
      user-select: none;
      background-color: ${({ theme }) => theme.colors.background};
      color: ${({ theme }) => theme.colors.gray[600]};
      box-shadow: 0 0 0 1px #00000021;

      span {
        position: relative;
        top: 1px;
      }

      svg {
        height: 18px;
        margin-right: 8px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[200]};
      }
    }
  }

  .details {
    margin-top: 1rem;

    .photo-title {
      display: block;
      margin-top: 4px;
      font-size: 15px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.gray[600]};
    }

    .photo-category {
      font-weight: 600;
      display: block;
      font-size: 15px;
      color: ${({ theme }) => theme.colors.gray[800]};
    }
  }

  :hover {
    cursor: pointer;

    .image {
      box-shadow: 0px 8px 16px 2px rgb(97 97 97 / 10%);
      transform: translateY(-5px);
    }
  }

  @media (max-width: ${({ theme }) => theme.size.sm}px) {
    .photo-actions {
      opacity: 1 !important;
    }
  }
`
export default StyledIssuePhoto
