import React, { useMemo } from 'react'
import styled from 'styled-components'
import * as Sentry from '@sentry/react'
import { serialize, serializePlainText } from './slate'

type Props = {
  html: string | undefined | null;
  fallbackText?: string;
  plainText?: boolean;
  maxLength?: number;
  noLinks?: boolean;
  [rest: string]: any;
}

const RenderSlate = (args: Props) => {
  const fallbackPlaceholder = 'Loading...'
  const {
    html,
    noLinks,
    fallbackText = fallbackPlaceholder,
    maxLength,
    plainText = false,
    ...rest
  } = args

  const parseAndSerialize = (data) => {
    if (!data) {
      return ''
    }

    try {
      if (plainText) {
        return serializePlainText({ children: JSON.parse(data) })
      }
      return serialize({ children: JSON.parse(data) }, { noLinks })
    }
    catch (err) {
      Sentry.captureException(err)
      return [{ type: 'paragraph', children: [{ text: fallbackText }] }]
    }
  }

  const memoizedHTML = useMemo(() => {
    let data = parseAndSerialize(html)
    if (typeof maxLength === 'number' && typeof data === 'string') {
      data = data.trim()
      return `${data.substring(0, maxLength)}${data.length > maxLength ? '…' : ''}`
    }
    return data
  }, [html])

  return <StyledSlateBlock dangerouslySetInnerHTML={{ __html: memoizedHTML }} {...rest} />
}

const StyledSlateBlock = styled.div`
  img {
    max-width: 100%;
    max-height: 75vh;
    vertical-align: middle;
  }
  a {
    color: ${({ theme }) => theme.colors.blue[300]};
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.blue[600]};
    }
  }
`

export default RenderSlate
