import styled from 'styled-components'

type StyledProps = {
  isActive?: boolean;
}

const StyledQuestionBox = styled.div<StyledProps>`
  width: 100%;
  margin-top: 44px;

  .subtitle {
    font-family: ${({ theme }) => theme.fonts.nunito};
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.gray[400]};
  }

  .answers-wrap,
  .bubble-wrap {
    margin: 70px 0;
  }

  .bubble {
    font-family: ${({ theme }) => theme.fonts.nunito};
    font-size: 16px;
    font-weight: 600;

    border: 1px solid #c4c4c4;
    padding: 20px 17px;
    margin: 16px 0 0 0;
  }

  .bubble-check {
    margin-right: 15px;
    position: relative;
  }

  .answer-box {
    color: ${({ theme }) => theme.colors.gray[700]};
    text-align: center;

    margin: 26px 20px;
    padding: 27px;
    background: #ffffff;

    border: 1px solid ${({ theme }) => theme.colors.gray[300]};
    box-sizing: border-box;
    border-radius: 10px;

    cursor: pointer;
  }

  .answer-box.active {
    color: ${({ theme }) => theme.colors.green[300]};
    border: 1px solid ${({ theme }) => theme.colors.green[300]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.s}px) {
    display: flex;
    align-items: center;
    flex-direction: column;

    .statement-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 385px;
    }

    .bubble-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }

    .bubble {
      margin: 12px;
    }

    .bubble-wrap,
    .answers-wrap {
      margin: 60px 10vh;
    }

    .answers-wrap {
      width: 385px;
    }
  }
`

export default StyledQuestionBox
