import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { rootReducer, AppState } from './root-reducer'
import { middleware } from './middleware'
import { loadStateRedux, setStateRedux, throttleFn } from '../components/utils/redux.persist-state'

const preloadedState = loadStateRedux()

const store = createStore(
  rootReducer,
  preloadedState,
  composeWithDevTools(applyMiddleware(...middleware))
)

store.subscribe(
  throttleFn(() => {
    const {
      issueForm, galleryForm, shoppingCart, draft, pricing
    }: AppState = store.getState()
    // keep state only of these stores
    setStateRedux({
      issueForm,
      galleryForm,
      shoppingCart,
      draft,
      pricing
    })
  }, 200)
)

export default store
