import React, {
  FC, useEffect, useCallback, useMemo, useState
} from 'react'
import isHotkey from 'is-hotkey'
import { createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { Slate, Editable, withReact } from 'slate-react'
import { withHtml, Element, Leaf } from '../../Slate/slate.htmlPaste'
import { Toolbar, HOTKEYS, toggleMark } from '../../Slate/slate.toolbar'
import { StyledRichInput } from '.'

type Props = {
  value?: any;
  placeholder?: any;
  onChange?: Function;
}

const DEFAULT_SLATE_TEXT = [
  {
    type: 'paragraph',
    children: [{ text: '' }]
  }
]

const RichInput: FC<Props> = ({ value, placeholder, onChange = () => {} }) => {
  const renderElement = useCallback((props) => <Element {...props} />, [])
  const renderLeaf = useCallback((props) => <Leaf {...props} />, [])
  const editor = useMemo(() => withHtml(withReact(withHistory(createEditor()))), [])
  const [richValue, setRichValue] = useState(DEFAULT_SLATE_TEXT)

  const handleValue = async () => {
    try {
      if (!value) {
        return
      }

      setRichValue(JSON.parse(value))
    }
    catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    handleValue()
  }, [value])

  return (
    <StyledRichInput>
      <Slate
        editor={editor}
        value={richValue}
        onChange={(newValue: any) => {
          setRichValue(newValue)
          onChange(JSON.stringify(newValue))
        }}
      >
        <Toolbar />
        <Editable
          className="input-field"
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder || 'Enter some rich text…'}
          spellCheck
          onKeyDown={(event: any) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault()
                const mark = HOTKEYS[hotkey]
                toggleMark(editor, mark)
              }
            }
          }}
        />
      </Slate>
    </StyledRichInput>
  )
}

export default RichInput
