import { isProduction } from './firebase'

const user = {
  login: '/user/login',
  signup: '/user/signup',
  signupCourse: '/course/signup',
  logout: '/user/logout',
  resetPassword: '/user/password-reset',
  confirmCode: '/user',
  settings: {
    profile: '/user/profile',
    billing: '/user/billing',
    plan: '/user/plan',
    payment: '/user/payment'
  }
}

const Dashboard = {
  home: '/',
  issues: '/action-plans',
  shop: '/shop',
  aboutJasmine: '/about-jasmine',
  drafts: '/drafts',
  courses: '/courses',
  community: '/community'
}

const Affiliates = {
  dashboard: '/affiliate',
  links: '/affiliate/links',
  works: '/affiliate/how-it-works',
  worksSubscription: '/affiliate/how-it-works/subscription',
  worksCourse: '/affiliate/how-it-works/course',
  worksChallenge: '/affiliate/how-it-works/challenge',
  buildDetailPage: (detail: string) => `/affiliate/how-it-works/${detail}`
}

const Courses = {
  preview: '/courses/:courseSlug/preview',
  previewBySlug: (courseSlug: string) => `/courses/${courseSlug}/preview`,
  lesson: '/courses/:courseSlug/:lessonSlug',
  lessonBySlug: (courseSlug: string, lessonSlug: string) => `/courses/${courseSlug}/${lessonSlug}`
}

const UserGallery = {
  main: '/gallery',
  favorites: '/gallery/favorites',
  newCollection: '/gallery/collections?showModal=true',
  collections: '/gallery/collections',
  collectionPage: '/gallery/collections/:collectionId',
  photoPage: [
    '/gallery/:entryId',
    '/gallery/favorites/:entryId',
    '/gallery/collections/:collectionId/:entryId'
  ],
  buildPhotoPage: (entryId: string) => `/gallery/${entryId}`,
  buildFavoritesPhotoPage: (entryId: string) => `/gallery/favorites/${entryId}`,
  buildCollectionPage: (entryId: string) => `/gallery/collections/${entryId}`,
  schedule: '/gallery/schedule'
}

const Issues = {
  view: '/(action-plans|issues)/:slug',
  viewBySlug: (slug: string) => `/action-plans/${slug}`,
  storiesPage: '/(action-plans|issues)/:slug/stories/:storyId',
  captionsPage: '/(action-plans|issues)/:slug/captions/:captionId',
  extrasPage: '/(action-plans|issues)/:slug/extras/:extraId',
  buildRecordPage: (
    id: string,
    slug: string,
    recordType: 'story' | 'photo' | 'extra' | 'captions' | 'extras' | 'stories'
  ) => {
    return `/action-plans/${slug}/${recordType}/${id}`
  },
  modal: '/(action-plans|issues)/:slug/:recordType/:recordId'
}

const AdminDailyActions = {
  list: `/admin/daily-actions`,
  edit: `/admin/daily-actions/edit-action/:actionId`,
  editById: (id: string) => {
    return `/admin/daily-actions/edit-action/${id}`
  }
}

const AdminCommunity = {
  list: `/admin/community`,
  edit: `/admin/community/edit-event/:eventId`,
  editById: (id: string) => {
    return `/admin/community/edit-event/${id}`
  }
}

const AdminCourses = {
  list: '/admin/courses',
  edit: '/admin/courses/:courseId',
  tab: '/admin/courses/:courseId/:tab',
  editById: (id: string, tab?: string) => {
    if (!tab) {
      return `/admin/courses/${id}/overview`
    }
    return `/admin/courses/${id}/${tab}`
  }
}

const AdminLessons = {
  list: '/admin/courses/:courseId/lessons',
  edit: '/admin/courses/:courseId/lessons/:lessonId',
  editById: (courseId: string, lessonId: string) => {
    return `/admin/courses/${courseId}/lessons/${lessonId}`
  }
}

const AdminIssues = (path = 'action-plans') => ({
  list: `/admin/${path}`,
  edit: `/admin/${path}/edit-action-plan/:issueId/:tab`,
  editById: (id: string, tab?: string) => {
    return `/admin/${path}/edit-action-plan/${id}/${tab || ''}`
  },
  editByIdMain: (id: string) => {
    return `/admin/${path}/edit-action-plan/${id}/main`
  },
  stories: `/admin/${path}/edit-action-plan/:issueId/story/:storyId`,
  editStoryById: (issueId: string, storyId: string) => {
    return `/admin/${path}/edit-action-plan/${issueId}/story/${storyId}`
  },
  captions: `/admin/${path}/edit-action-plan/:issueId/caption/:captionId`,
  editCaptionById: (issueId: string | null, captionId: string) => {
    if (!issueId) {
      return `/admin/captions/${captionId}`
    }
    return `/admin/${path}/edit-action-plan/${issueId}/caption/${captionId}`
  },
  extras: `/admin/${path}/edit-action-plan/:issueId/extra/:extraId`,
  editExtraById: (issueId: string, extraId: string) => {
    return `/admin/${path}/edit-action-plan/${issueId}/extra/${extraId}`
  },
  videos: `/admin/${path}/edit-video/:issueId/video/:videoId`,
  editVideoById: (issueId: string, videoId: string) => {
    return `/admin/${path}/edit-video/${issueId}/video/${videoId}`
  },
  preview: `/admin/${path}/edit-video/:issueId/preview/`
})

const AdminUser = {
  list: '/admin/users',
  profile: '/admin/users/edit-user/:userId/:tab',
  profileById: (userId: string) => `/admin/users/edit-user/${userId}`
}

const Gallery = {
  mainRoute: '/admin/gallery',
  main: ['/admin/gallery/:entryId?', '/admin/gallery/show/:entryId?'],
  entryById: (entryId: string) => (entryId ? `/admin/gallery/${entryId}` : '/admin/gallery'),
  showById: (entryId: string) => `/admin/gallery/show/${entryId}`
}

const CaptionMigrator = { main: '/admin/caption-image-migrator' }

const AdminLeadCapture = {
  overview: '/admin/lead-forms',
  edit: '/admin/lead-forms/:formId',
  editById: (id?: string) => `/admin/lead-forms/${id}`
}

const Leads = {
  form: '/forms/:formId',
  formById: (id: string) => `/forms/${id}`
}

const Admin = {
  dashboard: '/admin',
  users: AdminUser,
  issues: AdminIssues('action-plans'),
  captions: `/admin/captions/:captionId`,
  actions: AdminDailyActions,
  community: AdminCommunity,
  courses: AdminCourses,
  lessons: AdminLessons,
  leads: AdminLeadCapture,
  gallery: Gallery,
  captionMigrator: CaptionMigrator
}

enum Errors {
  fiveHundred = '/errors/500',
  notFound = '/errors/404',
  notLoggedIn = '/no-subscription'
}

const ExternalUrls = {
  switchToBusinessAccountHelpArticle:
    'https://help.socialcurator.com/en/articles/6333355-connecting-your-social-media-accounts-and-enabling-auto-posting-using-the-social-curator-platform#:~:text=I%20don%E2%80%99t%20have%20an%20Instagram%20Business%20Account%20%2D%20can%20I%20switch%20to%20one%3F',
  waitListUrl: 'https://socialcurator.com/waitlist/',
  challengeWaitlistUrl: 'https://www.socialcurator.com/5daywaitlist',
  termsOfServiceUrl: 'https://socialcurator.com/terms-of-service/',
  affiliateTermsOfServiceUrl: 'https://socialcurator.com/affiliate-terms',
  cancellationPolicyUrl: 'https://socialcurator.com/cancel/',
  facebookEventsUrl: 'https://www.facebook.com/groups/socialcuratorswithjasminestar/events',
  facebookCommunityUrl: 'https://www.facebook.com/groups/socialcuratorswithjasminestar/',
  communityEventsUrl: 'https://hello.community.socialcurator.com/c/live-events-0af99d/?sort=asc',
  helpCenterUrl: 'https://help.socialcurator.com/en/',
  aboutJasmineUrl: 'https://www.socialcurator.com/about-jasmine',
  usageAgreementUrl: 'https://www.socialcurator.com/usage-agreement',
  privacyPolicyUrl: 'https://www.socialcurator.com/privacy-policy',
  pricingUrl: 'https://www.socialcurator.com/pricing',
  payPalConnect: `https://www.${
    !isProduction ? 'sandbox.' : ''
  }paypal.com/connect/?flowEntry=static&client_id=${
    process.env.REACT_APP_PAYPAL_CLIENT_ID
  }&response_type=code&scope=openid https://uri.paypal.com/services/paypalattributes&redirect_uri=${encodeURIComponent(
    `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' : ''}${
      window.location.port
    }`
  )}/affiliate`,
  socialLearnWhy:
    'https://help.socialcurator.com/en/articles/6332528-connecting-your-social-media-accounts-and-enabling-auto-posting-using-the-social-curator-app',
  payPalSignInUrl: 'https://www.paypal.com/signin',
  affiliateProgramUrl: 'https://www.socialcurator.com/affiliate',
  supportMailing: 'mailto:help@socialcurator.com'
}

const Stories = {
  list: '/stories',
  story: '/stories/:_id',
  storyById: (id: string) => `/stories/${id}`
}

export const defaultPageTitle =
  'Social Curator | Fresh content to keep your followers hungry for more'

const ROUTES = {
  user,
  gallery: UserGallery,
  dashboard: Dashboard,
  issues: Issues,
  stories: Stories,
  captions: '/captions',
  checkout: '/checkout',
  receipt: '/checkout/receipt',
  affiliate: '/a/:affiliateId',
  affiliates: Affiliates,
  admin: Admin,
  courses: Courses,
  leads: Leads,
  external: ExternalUrls,
  errors: Errors
}

export default ROUTES
