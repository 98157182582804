import {
  GalleryActions,
  GalleryState,
  GALLERY_IMAGE_DATA,
  GALLERY_COLLECTION_DATA,
  GALLERY_SET_ACTIVE_COLLECTION,
  GALLERY_SET_ACTIVE_TAB,
  GALLERY_SET_COUNT_DOCS,
  GALLERY_SET_EDIT_MODE,
  GALLERY_CATEGORY_CHANGE,
  GALLERY_COLOR_CHANGE,
  GALLERY_ORIENTATION_CHANGE,
  GALLERY_SEARCH_CHANGE,
  GALLERY_SORT_BY_CHANGE,
  GALLERY_STYLE_CHANGE,
  GALLERY_CLEAR_FILTERS,
  GALLERY_LOAD_SEARCH,
  GALLERY_SET_PAGE
} from './types'

const initialState: GalleryState = {
  activeCollection: null,
  data: [],
  collections: [],
  activeTab: null,
  countDocs: 0,
  page: 0,
  editMode: false,
  search: '',
  sortBy: 'popular',
  color: [],
  orientation: null,
  category: [],
  style: [],
  loading: true
}

export const galleryReducer = (state = initialState, action: GalleryActions): GalleryState => {
  switch (action.type) {
    case GALLERY_IMAGE_DATA:
      return {
        ...state,
        loading: false,
        data: action.payload
      }
    case GALLERY_COLLECTION_DATA:
      return {
        ...state,
        collections: action.payload
      }
    case GALLERY_SET_ACTIVE_COLLECTION:
      return {
        ...state,
        activeCollection: action.payload
      }
    case GALLERY_SET_ACTIVE_TAB:
      return {
        ...state,
        data: [],
        page: 0,
        loading: true,
        activeTab: action.payload
      }
    case GALLERY_SET_COUNT_DOCS:
      return {
        ...state,
        countDocs: action.payload
      }
    case GALLERY_SET_PAGE:
      return {
        ...state,
        loading: true,
        page: action.payload
      }
    case GALLERY_SET_EDIT_MODE:
      return {
        ...state,
        editMode: action.payload
      }

    case GALLERY_SEARCH_CHANGE:
      return {
        ...state,
        data: [],
        loading: true,
        page: 0,
        search: action.payload
      }
    case GALLERY_SORT_BY_CHANGE:
      return {
        ...state,
        data: [],
        loading: true,
        page: 0,
        sortBy: action.payload
      }
    case GALLERY_COLOR_CHANGE:
      return {
        ...state,
        data: [],
        loading: true,
        page: 0,
        color: [...action.payload]
      }
    case GALLERY_ORIENTATION_CHANGE:
      return {
        ...state,
        data: [],
        loading: true,
        page: 0,
        orientation: action.payload
      }
    case GALLERY_CATEGORY_CHANGE:
      return {
        ...state,
        data: [],
        loading: true,
        page: 0,
        category: [...action.payload]
      }
    case GALLERY_STYLE_CHANGE:
      return {
        ...state,
        data: [],
        loading: true,
        page: 0,
        style: action.payload
      }
    case GALLERY_CLEAR_FILTERS:
      return {
        ...state,
        data: [],
        loading: true,
        page: 0,
        style: [],
        color: [],
        category: [],
        orientation: null
      }
    case GALLERY_LOAD_SEARCH:
      return {
        ...state,
        data: [],
        loading: true,
        page: 0,
        ...action.payload
      }
    default:
      return state
  }
}
