import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { userStore } from './UserContext'

type DraftInputType = {
  _id?: string;
  socialProfiles?: (string | null)[] | null;
  postDate?: Date;
}

export const useDraftError = (draft: DraftInputType) => {
  const { user } = useContext(userStore)
  const [errors, setErrors] = useState<string[]>([])

  const checkDraftErrors = () => {
    const draftErrors: string[] = []

    if (!draft.postDate || moment(draft.postDate).isBefore(moment())) {
      return setErrors([])
    }

    // Check if a social account is inactive
    const inactiveSocialProfiles = user?.socialProfiles?.filter(
      (profile) => draft.socialProfiles?.includes(profile._id) && profile.status !== 'active'
    )

    if (inactiveSocialProfiles?.length) {
      inactiveSocialProfiles.map((profile) => {
        draftErrors.push(
          `${profile.type === 'instagram' ? '@' : ''}${profile.username} needs to be reconnected.`
        )
      })
    }

    setErrors(draftErrors)
  }

  useEffect(() => {
    checkDraftErrors()
  }, [draft._id, draft.postDate, user?.socialProfiles])

  return { errors }
}
