import {
  GetUserChallenges_getUserChallenges,
  GetUserChallenges_getUserChallenges_userChallenge
} from '../../graphql/Challenge/__generated__/GetUserChallenges'

export type ChallengeState = {
  currentChallenge: Omit<GetUserChallenges_getUserChallenges, 'userChallenge'> | null;
  userChallenge: GetUserChallenges_getUserChallenges_userChallenge | null;
  loading: boolean;
}

export const CHALLENGE_SET_DATA = 'CHALLENGE_SET_DATA'
export type ChallengeSetDataAction = {
  type: typeof CHALLENGE_SET_DATA;
  payload: Omit<ChallengeState, 'loading'>;
}

export const CHALLENGE_CHANGE_USER_DATA = 'CHALLENGE_CHANGE_USER_DATA'
export type ChallengeChangeUserDataAction = {
  type: typeof CHALLENGE_CHANGE_USER_DATA;
  payload: GetUserChallenges_getUserChallenges_userChallenge;
}

export type ChallengeActions = ChallengeSetDataAction | ChallengeChangeUserDataAction
