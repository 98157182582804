import {
  PRICING_ADD_ITEM, 
  PricingActions,
  PricingType
} from './types'

export const pricingAddAction = (payload: PricingType[]): PricingActions => ({
  type: PRICING_ADD_ITEM,
  payload
})
