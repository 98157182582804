import { gql } from '@apollo/client'

export const UserChallengeUpdateByIdMutation = gql`
  mutation UserChallengeUpdateById(
    $_id: String!
    $completed: Boolean
    $completedExtraDrafts: Boolean
  ) {
    userChallengeUpdateById(
      _id: $_id
      record: { completed: $completed, completedExtraDrafts: $completedExtraDrafts }
    ) {
      record {
        _id
        user
        challenge
        completed
        completedExtraDrafts
        grantedExtraDrafts
        updatedAt
        joined
      }
    }
  }
`
