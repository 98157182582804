import styled from 'styled-components'

type StyledProps = {
  currIndex?: number;
}

const StyledSteps = styled.ol<StyledProps>`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  li {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray[600]};

    .step-bubble {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      background: ${({ theme }) => theme.colors.gray[100]};
      border-radius: 50%;
      color: ${({ theme }) => theme.colors.gray[500]};
      font-weight: 600;
      margin-right: 10px;
    }

    &:nth-of-type(${({ currIndex }) => currIndex}) {
      .step-bubble {
        background: #ED6B6B;
        color: ${({ theme }) => theme.colors.background};
      }
    }
  }
`

export default StyledSteps
