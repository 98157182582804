import styled from 'styled-components'

type StyledProps = {
  pageLength: number;
}

const StyledIssueContentPagination = styled.div<StyledProps>`
  margin: 3rem 0;

  h2 {
    font-size: 24px;
    text-transform: capitalize;
    font-weight: 600;
  }

  .content-pagination-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 59px;
  }

  .content-pagination-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    column-gap: 18px;
    row-gap: 36px;
    margin-bottom: calc(-50px ${({ pageLength }) => (pageLength > 1 ? `- 2rem` : '+ 25px')});
  }

  .content-pagination-bubbles {
    display: grid;
    grid-template-columns: repeat(${({ pageLength }) => pageLength}, 1fr);
    justify-content: center;
    justify-items: center;
    grid-gap: 10px;
    margin-top: 1rem;
    place-items: center;
    width: fit-content;
    margin: 2rem auto 0 auto;
    position: relative;
    z-index: 1;
  }

  .content-pagination-bubbles button.active {
    background: ${({ theme }) => theme.colors.foreground};
  }

  .content-pagination-categories {
    margin-bottom: 1rem;
  }

  .issue-pagination-button {
    position: sticky;
    bottom: 50%;
    top: unset;
  }

  .issue-pagination-button.next {
    transform: translate(50%, -50%);
    margin-left: auto !important;
  }

  .issue-pagination-button.prev {
    transform: translate(-50%, -50%);
  }

  @media (max-width: ${({ theme }) => theme.size.sm}px) {
    .content-pagination-grid {
      grid-gap: 2rem 1rem;
      grid-template-columns: 100%;
    }

    .caption-title {
      max-width: 90%;
    }

    .issue-pagination-button.next,
    .issue-pagination-button.prev {
      transform: translate(0%, -50%);
    }

    .content-pagination-bubbles {
      position: sticky;
      bottom: calc(2rem + 32px);
    }
  }
`
export default StyledIssueContentPagination
