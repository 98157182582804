import React, { FC, useContext, useEffect, createRef } from 'react'
import { tooltipStore } from './TooltipContext'
import { StyledToolTip } from '.'
import { useWindowSize } from '../Grid'

const ToolTip: FC = () => {
  const {
    tooltip, child, isShown, setTooltip
  } = useContext(tooltipStore)
  const tooltipRef = createRef<HTMLDivElement>()
  const windowSize = useWindowSize()

  // Get's the tooltips width and height and passes
  // it to the tooltip provider for positioning
  useEffect(() => {
    if (!tooltipRef.current) {
      return
    }

    const tooltipDiv = tooltipRef.current

    if (tooltip.width !== tooltipDiv.getBoundingClientRect().width) {
      setTooltip({
        tooltip: {
          ...tooltip,
          width: tooltipDiv.getBoundingClientRect().width,
          height: tooltipDiv.getBoundingClientRect().height
        },
        child
      })
    }
  }, [tooltipRef.current, child])

  if (windowSize === 'xs') {
    return null
  }

  return (
    <StyledToolTip
      tooltip={{
        tooltip,
        child,
        isShown
      }}
    >
      <div ref={tooltipRef} className="tooltip">
        {tooltip.label}
      </div>
    </StyledToolTip>
  )
}

export default ToolTip
