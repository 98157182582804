import styled from 'styled-components'

const StyledLeadForm = styled.div`
  padding: 20px;

  .form-error {
    margin-bottom: 30px;
  }

  form {
    margin: 0 auto;
    display: grid;
    grid-row-gap: 30px;
    width: 100%;

    .submit-button {
      margin: 0 auto;
      display: block;
    }
  }

  .message {
    text-align: center;

    a {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.blue[300]};
    }
  }
`

export default StyledLeadForm
