import { gql } from '@apollo/client'

export const galleryCollectionCreateOneMutation = gql`
  mutation GalleryCollectionCreateOne($name: String!, $index: Float!, $user: String!) {
    galleryCollectionCreateOne(record: { name: $name, index: $index, user: $user }) {
      record {
        _id
        name
        index
        user
        createdAt
      }
    }
  }
`
