const zIndex = {
  dropdown: 1000,
  drawer: 1010,
  sticky: 1020,
  fixed: 1030,
  modalBackdrop: 1049,
  modal: 1050,
  notifications: 2040,
  popover: 2050,
  tooltip: 2060
}

const colors = {
  gray: {
    50: '#FAFAFA',
    100: '#F4F4F4',
    200: '#EBEBEB',
    300: '#C4C4C4',
    400: '#9E9E9E',
    500: '#797978',
    600: '#616161',
    650: '#6B7280',
    700: '#2E3039',
    800: '#232323',
    900: '#0A0D17'
  },
  cream: {
    50: '#FCF9F4',
    100: '#FAF5ED',
    200: '#F1E8D9',
    300: '#E4DCD2'
  },
  red: {
    50: '#fef2f2',
    100: '#fee2e2',
    200: '#fecaca',
    300: '#fca5a5',
    400: '#f87171',
    500: '#ef4444',
    600: '#dc2626',
    700: '#b91c1c',
    800: '#991b1b',
    900: '#7f1d1d'
  },
  blue: {
    50: '#f0f9ff',
    100: '#e0f2fe',
    200: '#bae6fd',
    300: '#7dd3fc',
    400: '#38bdf8',
    500: '#0ea5e9',
    600: '#0284c7',
    700: '#0369a1',
    800: '#075985',
    900: '#0c4a6e'
  },
  green: {
    50: '#E1F6F3',
    100: '#B8E3DC',
    200: '#7BC8BA',
    300: '#3FAE9A',
    400: '#309482',
    500: '#1F6E5F',
    600: '#104F43'
  },
  amber: {
    50: '#fffbeb',
    100: '#fef3c7',
    200: '#fde68a',
    300: '#fcd34d',
    400: '#fbbf24',
    500: '#f59e0b',
    600: '#d97706',
    700: '#b45309',
    800: '#92400e',
    900: '#78350f'
  },
  background: '#FFFFFF',
  foreground: '#000000'
}

const fonts = {
  manrope: '\'Manrope\', sans-serif',
  nunito: '\'Nunito\', sans-serif',
  lexend: '\'Lexend\', sans-serif',
  montserrat: '\'Montserrat\', sans-serif',
  playfairDisplay: '\'Playfair Display\', serif'
}

const breakpoints = {
  xs: 640,
  s: 768,
  m: 1024,
  l: 1280,
  ml: 1400,
  xl: 1824
}

const elevations = {
  base: 0,
  dropdown: 10,
  sticky: 20,
  fixed: 30,
  backdrop: 40,
  modal: 5000,
  drawer: 60,
  notification: 70,
  tooltip: 80,
  override: 9999
}

export const theme = {
  size: {
    xs: 550,
    sm: 1024,
    md: 1224,
    lg: 1824
  },
  fontSize: {
    xs: '.6em',
    sm: '.95em',
    md: '1em'
  },
  hSize: {
    h1: '2.1em',
    h2: '1.9em',
    h3: '1.5em',
    h4: '1.2em',
    h5: '1em'
  },
  buttonPadding: '.6em 1.2em',
  hideBodyOverflow: false,
  zIndex,

  colors,
  fonts,
  breakpoints,
  elevations
}

export type Theme = typeof theme
