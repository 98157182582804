export { default as IssueContainer } from './Issue'

export { default as IssueView } from './IssueView'
export { default as StyledIssueView } from './IssueView.Styled'

export { default as IssueGrid } from './IssueGrid'
export { default as StyledIssueGrid } from './IssueGrid.Styled'

export { default as IssueHeader } from './IssueHeader'
export { default as StyledIssueHeader } from './IssueHeader.Styled'

export { default as IssueVideo } from './IssueVideo'
export { default as StyledIssueVideo } from './IssueVideo.Styled'

export { default as IssueTabs } from './IssueTabs'
export { default as StyledIssueTabs } from './IssueTabs.Styled'

export { default as IssueAbout } from './IssueAbout'
export { default as StyledIssueAbout } from './IssueAbout.Styled'

export { default as IssueFooter } from './IssueFooter'
export { default as StyledIssueFooter } from './IssueFooter.Styled'

export { default as IssuePaginationButton } from './IssuePaginationButton'
export { default as StyledIssuePaginationButton } from './IssuePaginationButton.Styled'

export { default as IssueHighlights } from './IssueHighlights'
export { default as StyledIssueHighlights } from './IssueHighlights.Styled'

export { default as IssueHighlight } from './IssueHighlight'
export { default as StyledIssueHighlight } from './IssueHighlight.Styled'

export { default as IssuePdf } from './IssuePdf'
export { default as StyledIssuePdf } from './IssuePdf.Styled'

export { default as IssueImage } from './IssueImage'
export { default as StyledIssueImage } from './IssueImage.Styled'

// Types

export type LocationProps = {
  hash: string;
  pathname: string;
  search: string;
  state?: any;
}

export const CAPTION_CATEGORY_LIST = [
  'Behind the Scenes',
  'My Why',
  'Personal Insight',
  'Encouragement',
  'About Me',
  'Benefits',
  'Showcase Product/Service',
  'Holiday'
] as const
