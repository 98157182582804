import { useContext, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { v4 as uuid } from 'uuid'
import { useDispatch } from 'react-redux'
import { useNotifications } from '../Notifications'
import useSegment from '../utils/useSegment'
import useFileUpload from '../utils/useFileUpload'
import { userStore } from '../utils/UserContext'
import useDraftsList from '../../redux/drafts-list/useDraftsList'
import useExtraDraftGranting from '../utils/useExtraDraftGranting'
import useDraft from '../../redux/draft-drawer/useDraft'
import { DraftImage } from '../../redux/draft-drawer/types'
import { conditionalStage } from '../utils'
import { SearchUserDrafts } from '../../graphql/UserDraft/__generated__/SearchUserDrafts'
import { searchUserDraftsQuery } from '../../graphql/UserDraft/searchUserDrafts'
import { userDraftFindByIdQuery } from '../../graphql/UserDraft/userDraftFindById'
import {
  UserDraftFindById,
  UserDraftFindById_userDraftFindById
} from '../../graphql/UserDraft/__generated__/UserDraftFindById'
import { UserDraftInput } from '../../../__generated__/globalTypes'
import { UpsertUserDraft } from '../../graphql/UserDraft/__generated__/UpsertUserDraft'
import { upsertUserDraftMutation } from '../../graphql/UserDraft/upsertUserDraft'
import { userDraftRemoveByIdMutation } from '../../graphql/UserDraft/userDraftRemoveById'
import { draftSetDraft } from '../../redux/draft-drawer/actions'

export const useDraftActions = () => {
  const apollo = useApolloClient()
  const dispatch = useDispatch()
  const { handleChallengeUsage } = useExtraDraftGranting()
  const [loadingSave, setLoadingSave] = useState(false)
  const { user } = useContext(userStore)
  const {
    _id,
    socialProfiles,
    imageDimensions,
    title,
    captionText,
    imageUrl,
    imageId,
    fullPath,
    userUpload,
    category,
    postDate,
    index,
    actions: { clearAndCloseDraft, setDraftSaved }
  } = useDraft()
  const {
    previewImage,
    processing,
    progress,
    actions: {
      handlePreviewImage, uploadFiles, deleteFile, setPreviewImage
    }
  } = useFileUpload()
  const { addNotification } = useNotifications()
  const { track } = useSegment()
  const {
    actions: {
      addDrafts, updateDraft, likeDraft, draftUsed
    }
  } = useDraftsList()

  // Returns true if the draft cannot be saved
  const checkDraftDisabled = () => {
    if (!previewImage?.url && !imageUrl && !captionText?.length && title === 'Untitled Draft') {
      return true
    }

    return false
  }

  // Handles behavior after draft has been saved
  const handleSaveCleanup = (shouldClose: boolean, draft: UserDraftFindById_userDraftFindById) => {
    if (!shouldClose) {
      setDraftSaved(true)
      setPreviewImage(undefined)
      dispatch(draftSetDraft(draft))
      return
    }

    clearAndCloseDraft()
    addNotification({ type: 'success', message: 'Draft Saved' })
  }

  // A wrapper for draft saving that handles image uploading
  const handleSaveDraft = async (
    shouldClose = true,
    params?: { draftObject: any; merge?: boolean }
  ) => {
    if (checkDraftDisabled()) {
      return addNotification({ type: 'error', message: 'Draft is empty' })
    }

    // if user is uploading an image, save image to storage first
    try {
      setLoadingSave(true)

      if (previewImage) {
        const imageData = await uploadFiles(previewImage?.file)
        const { imageUrl: uploadedImageUrl, fullPath: uploadedFullPath } = imageData[0] // only one image is uploaded

        const draft = await saveDraft(params, {
          imageUrl: uploadedImageUrl,
          imageId: undefined,
          fullPath: uploadedFullPath
        })

        handleSaveCleanup(shouldClose, draft)

        return draft
      }

      const draft = await saveDraft(params)
      handleSaveCleanup(shouldClose, draft)

      return draft
    }
    catch (err) {
      addNotification({
        message: `${err}`,
        type: 'error',
        interval: 5000
      })
    }
  }

  // Update or create a single draft
  const saveDraft = async (
    params?: { draftObject: any; merge?: boolean },
    imageObject?: DraftImage
  ) => {
    setLoadingSave(true)

    const currentDraft = {
      _id: _id === 'new' ? uuid() : _id,
      title: title || null,
      captionText,
      imageUrl: imageObject?.imageUrl || imageUrl || null,
      userUpload: userUpload || !!imageObject?.fullPath || null,
      imageId: imageObject?.imageId || imageId || null,
      fullPath: imageObject?.fullPath || fullPath || null,
      postDate: postDate || null,
      imageDimensions: {
        x: imageDimensions?.x || null,
        y: imageDimensions?.y || null
      },
      category: category || null,
      socialProfiles: socialProfiles || null,
      user: user?.id!,
      index
    }

    const draft = params
      ? {
        ...conditionalStage(!!params.merge, currentDraft),
        ...params.draftObject
      }
      : currentDraft

    const existingDraft = await getDraft(draft._id as string)

    if (typeof draft.index !== 'number') {
      const draftAmount = await getDraftAmount()
      draft.index = draftAmount + 1
    }

    // If saving a new draft, add draft to state
    if (!existingDraft) {
      addDrafts([draft] as any)
    }

    // If updating an existing draft, update draft in state
    if (existingDraft) {
      updateDraft(draft as any)

      // Delete photo from storage if removed from existing draft
      // or if it's replaced with a new uploaded photo
      const userDeletedPhoto = existingDraft.userUpload && draft.fullPath !== existingDraft.fullPath
      const userUpdatedPhoto =
        existingDraft.userUpload &&
        (currentDraft.userUpload || draft.userUpload) &&
        existingDraft.fullPath !== draft.fullPath

      if (userDeletedPhoto || userUpdatedPhoto) {
        await deleteFile(existingDraft.fullPath!)

        track(`${userDeletedPhoto ? 'Deleted' : 'Updated'} Uploaded Photo`, {
          id: existingDraft._id,
          title: existingDraft.title,
          category: existingDraft.category,
          post_date: existingDraft.postDate,
          caption: existingDraft.captionText,
          image_url: existingDraft.imageUrl,
          user_upload: existingDraft.userUpload,
          image_id: existingDraft.imageId,
          full_path: existingDraft.fullPath,
          character_count: existingDraft.captionText?.length,
          is_liked: existingDraft.isLiked,
          is_used: existingDraft.isUsed,
          connected_socials: !!existingDraft?.socialProfiles?.length,
          template: existingDraft.template,
          challenge: existingDraft.challenge
        })
      }
    }

    await apollo.mutate<UpsertUserDraft>({
      mutation: upsertUserDraftMutation,
      variables: { record: draft },
      fetchPolicy: 'no-cache'
    })

    // Send proper saved draft event
    track(existingDraft ? 'Edited Draft' : 'Created Draft', {
      id: draft._id,
      title: draft.title,
      category: draft.category,
      post_date: draft.postDate,
      caption: draft.captionText,
      image_url: draft.imageUrl,
      user_upload: draft.userUpload,
      image_id: draft.imageId,
      full_path: draft.fullPath,
      character_count: draft.captionText?.length,
      is_liked: existingDraft?.isLiked,
      is_used: existingDraft?.isUsed,
      connected_socials: !!draft?.socialProfiles?.length,
      template: existingDraft?.template,
      challenge: existingDraft?.challenge
    })

    setLoadingSave(false)

    return draft
  }

  const getDraft = async (_id: string) => {
    if (!_id) {
      return null
    }

    const { data } = await apollo.query<UserDraftFindById>({
      query: userDraftFindByIdQuery,
      variables: { _id },
      fetchPolicy: 'no-cache'
    })

    return data?.userDraftFindById
  }

  const getDraftAmount = async () => {
    const { data } = await apollo.query<SearchUserDrafts>({
      query: searchUserDraftsQuery,
      fetchPolicy: 'no-cache',
      variables: {
        page: 0,
        search: '',
        categories: [],
        sort: 'custom',
        items: 1
      }
    })

    return data?.searchUserDrafts?.totalDocs || 0
  }

  // Handle saving multiple drafts when a user bulk uploads their images
  const saveMultiDrafts = async (images) => {
    const successMessage =
      images.length > 1 ? 'Images Uploaded Successfully' : 'Image Uploaded Successfully'

    // upload images to bucket
    const imageData = await uploadFiles(images)

    if (!imageData) {
      return
    }

    const draftAmount = await getDraftAmount()

    // create a draft for each image and return response
    const saveDraftPromises = imageData.map(async (image, index) => {
      const draft: UserDraftInput = {
        _id: uuid(), // generate new id for new draft
        title: 'Untitled Draft',
        captionText: '',
        imageUrl: image.imageUrl,
        userUpload: true,
        fullPath: image.fullPath,
        user: user?.id as string,
        index: draftAmount + index,
        imageId: null,
        isLiked: false,
        isUsed: false
      }

      return await saveDraft({ draftObject: draft })
    })

    addNotification({ type: 'success', message: successMessage })

    return Promise.all(saveDraftPromises)
  }

  const deleteDraft = async (_id: string, fullPath?: string) => {
    const draft = await getDraft(_id)

    if (!draft) {
      return
    }

    try {
      // delete draft
      await apollo.mutate({
        mutation: userDraftRemoveByIdMutation,
        fetchPolicy: 'no-cache',
        variables: { _id }
      })

      track('Deleted Draft', {
        id: draft._id,
        title: draft.title,
        category: draft.category,
        post_date: draft.postDate,
        caption: draft.captionText,
        image_url: draft.imageUrl,
        user_upload: draft.userUpload,
        image_id: draft.imageId,
        full_path: draft.fullPath,
        character_count: draft.captionText?.length,
        is_liked: draft.isLiked,
        is_used: draft.isUsed,
        connected_socials: !!draft?.socialProfiles?.length,
        template: draft.template,
        challenge: draft.challenge
      })

      // delete user uploaded image, if any
      if (fullPath) {
        await deleteFile(fullPath)

        track('Deleted Uploaded Photo')
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const toggleDraftLike = async (_id: string) => {
    likeDraft(_id)

    const draft = await getDraft(_id)

    if (!draft) {
      return
    }

    await apollo.mutate<UpsertUserDraft>({
      mutation: upsertUserDraftMutation,
      variables: { record: { _id, isLiked: !draft?.isLiked, user: user?.id } },
      fetchPolicy: 'no-cache'
    })

    if (!draft?.isLiked) {
      track('Liked Draft', {
        id: draft._id,
        title: draft.title,
        category: draft.category,
        post_date: draft.postDate,
        caption: draft.captionText,
        image_url: draft.imageUrl,
        user_upload: draft.userUpload,
        image_id: draft.imageId,
        full_path: draft.fullPath,
        character_count: draft.captionText?.length,
        is_used: draft.isUsed,
        connected_socials: !!draft?.socialProfiles?.length,
        template: draft.template,
        challenge: draft.challenge
      })
    }
  }

  const toggleDraftUsed = async (_id: string) => {
    draftUsed(_id)

    const draft = await getDraft(_id)

    if (!draft) {
      return
    }

    await apollo.mutate<UpsertUserDraft>({
      mutation: upsertUserDraftMutation,
      variables: { record: { _id, isUsed: !draft.isUsed, user: user?.id } },
      fetchPolicy: 'no-cache'
    })

    if (!draft.isUsed) {
      await handleChallengeUsage(draft)

      track('Used Draft', {
        id: draft._id,
        title: draft.title,
        category: draft.category,
        caption: draft.captionText,
        post_date: draft.postDate,
        image_url: draft.imageUrl,
        user_upload: draft.userUpload,
        image_id: draft.imageId,
        full_path: draft.fullPath,
        character_count: draft.captionText?.length,
        is_liked: draft.isLiked,
        connected_socials: !!draft?.socialProfiles?.length,
        template: draft.template,
        challenge: draft.challenge
      })
    }
  }

  return {
    previewImage,
    processing,
    progress,
    loadingSave,
    handleSaveDraft,
    saveMultiDrafts,
    toggleDraftLike,
    toggleDraftUsed,
    deleteDraft,
    checkDraftDisabled,
    handlePreviewImage,
    setPreviewImage
  }
}
