export type AffiliateTrackState = {
  affiliate?: string;
}

export const AFFILIATE_TRACK = 'AFFILIATE_TRACK'
export type AffiliateTrackAction = {
  type: typeof AFFILIATE_TRACK;
  payload: string;
}

export type AffiliateTrackActions = AffiliateTrackAction
