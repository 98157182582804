import styled from 'styled-components'

const StyledIssueVideo = styled.div`
  position: relative;
  padding-top: 56.25%;
  border-radius: 3px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.gray[200]};
`

export default StyledIssueVideo
