import { GalleryFeedPhotoType } from '../../types'
import { SearchGalleryCollections_searchGalleryCollections_docs } from '../../graphql/GalleryCollection/__generated__/SearchGalleryCollections'
import { GalleryCollectionFindById_galleryCollectionFindById } from '../../graphql/GalleryCollection/__generated__/GalleryCollectionFindById'

export type GalleryState = {
  activeCollection: GalleryCollectionFindById_galleryCollectionFindById | null;
  collections: SearchGalleryCollections_searchGalleryCollections_docs[];
  data: GalleryFeedPhotoType[];
  activeTab: string | null;
  countDocs: number;
  page: number;
  editMode: boolean;
  search: string;
  sortBy: string | null;
  color: string[];
  orientation: string | null;
  category: string[];
  style: string[];
  loading: boolean;
}

export const GALLERY_SET_EDIT_MODE = 'GALLERY_SET_EDIT_MODE'
export type GallerySetEditModeAction = {
  type: typeof GALLERY_SET_EDIT_MODE;
  payload: boolean;
}

export const GALLERY_SET_COUNT_DOCS = 'GALLERY_SET_COUNT_DOCS'
export type GallerySetCountDocsAction = {
  type: typeof GALLERY_SET_COUNT_DOCS;
  payload: number;
}

export const GALLERY_SET_PAGE = 'GALLERY_SET_PAGE'
export type GallerySetPageAction = {
  type: typeof GALLERY_SET_PAGE;
  payload: number;
}

export const GALLERY_SET_ACTIVE_TAB = 'GALLERY_SET_ACTIVE_TAB'
export type GallerySetActiveTabAction = {
  type: typeof GALLERY_SET_ACTIVE_TAB;
  payload: string;
}

export const GALLERY_SET_ACTIVE_COLLECTION = 'GALLERY_SET_ACTIVE_COLLECTION'
export type GallerySetActiveCollectionAction = {
  type: typeof GALLERY_SET_ACTIVE_COLLECTION;
  payload: GalleryState['activeCollection'];
}

export const GALLERY_IMAGE_DATA = 'GALLERY_IMAGE_DATA'
export type GalleryImageDataAction = {
  type: typeof GALLERY_IMAGE_DATA;
  payload: GalleryState['data'];
}

export const GALLERY_COLLECTION_DATA = 'GALLERY_COLLECTION_DATA'
export type GalleryCollectionDataAction = {
  type: typeof GALLERY_COLLECTION_DATA;
  payload: GalleryState['collections'];
}

export const GALLERY_SEARCH_CHANGE = 'GALLERY_SEARCH_CHANGE'
export type GallerySearchChangeAction = {
  type: typeof GALLERY_SEARCH_CHANGE;
  payload: GalleryState['search'];
}

export const GALLERY_CLEAR_FILTERS = 'GALLERY_CLEAR_FILTERS'
export type GalleryClearFiltersAction = {
  type: typeof GALLERY_CLEAR_FILTERS;
}

export const GALLERY_SORT_BY_CHANGE = 'GALLERY_SORT_BY_CHANGE'
export type GallerySortByChangeAction = {
  type: typeof GALLERY_SORT_BY_CHANGE;
  payload: GalleryState['sortBy'];
}

export const GALLERY_COLOR_CHANGE = 'GALLERY_COLOR_CHANGE'
export type GalleryColorChangeAction = {
  type: typeof GALLERY_COLOR_CHANGE;
  payload: GalleryState['color'];
}

export const GALLERY_ORIENTATION_CHANGE = 'GALLERY_ORIENTATION_CHANGE'
export type GalleryOrientationChangeAction = {
  type: typeof GALLERY_ORIENTATION_CHANGE;
  payload: GalleryState['orientation'];
}

export const GALLERY_CATEGORY_CHANGE = 'GALLERY_CATEGORY_CHANGE'
export type GalleryCategoryChangeAction = {
  type: typeof GALLERY_CATEGORY_CHANGE;
  payload: GalleryState['category'];
}

export const GALLERY_STYLE_CHANGE = 'GALLERY_STYLE_CHANGE'
export type GalleryStyleChangeAction = {
  type: typeof GALLERY_STYLE_CHANGE;
  payload: GalleryState['style'];
}

export const GALLERY_LOAD_SEARCH = 'GALLERY_LOAD_SEARCH'
export type GalleryLoadSearch = {
  type: typeof GALLERY_LOAD_SEARCH;
  payload: any;
}

export type GalleryActions =
  | GalleryImageDataAction
  | GalleryCollectionDataAction
  | GallerySetActiveCollectionAction
  | GallerySetActiveTabAction
  | GallerySetCountDocsAction
  | GallerySetEditModeAction
  | GallerySearchChangeAction
  | GallerySortByChangeAction
  | GalleryColorChangeAction
  | GalleryOrientationChangeAction
  | GalleryCategoryChangeAction
  | GalleryStyleChangeAction
  | GalleryClearFiltersAction
  | GalleryLoadSearch
  | GallerySetPageAction
