import styled, { keyframes, css } from 'styled-components'
import { theme } from '../../utils/theme'

const likeAnim = keyframes`
  0% {
    transform: scale(0.3);
    fill: ${theme.colors.red[600]};
    color: ${theme.colors.red[600]};
  }
  60% {
    transform: scale(1.2);
    fill: ${theme.colors.red[600]};
    color: ${theme.colors.red[600]};
  }
  80% {
    transform: scale(0.9);
    fill: ${theme.colors.red[600]};
    color: ${theme.colors.red[600]};
  }
  100% {
    transform: scale(1);
    fill: ${theme.colors.red[600]};
    color: ${theme.colors.red[600]};
  }
`

type StyledProps = {
  isLiked?: boolean;
  isCollapsed?: boolean;
  isActive?: boolean;
  isLoading?: boolean;
}

const StyledDraftItem = styled.div<StyledProps>`
  border-radius: 6px;
  position: relative;
  height: fit-content;
  overflow: hidden;
  transition: box-shadow 0.2s ease-in-out;
  background-color: ${({ theme }) => theme.colors.background};

  .draft-image {
    padding-top: 100%;
    position: relative;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.gray[100]};

    .draft-error-icon {
      height: 34px;
      width: 34px;
      color: ${({ theme }) => theme.colors.background};
      background-color: ${({ theme }) => theme.colors.red[600]};
      box-shadow: inset 0 0 0 1px #0000001a;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 12px;
      top: 12px;
      position: absolute;
      transition: 0.2s;
      user-select: none;
      border-radius: 6px;

      svg {
        height: 24px;
      }
    }

    .draft-caption {
      padding: 1rem;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      user-select: none;
      font-size: 1.1rem;
      line-height: 28px;
      font-weight: 300;
      overflow: hidden;
      background-color: ${({ theme }) => theme.colors.background};
      color: ${({ theme }) => theme.colors.gray[700]};

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 40px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background: linear-gradient(0deg, rgb(0 0 0 / 10%), rgba(0, 0, 0, 0));
      }
    }

    .empty-icon {
      color: ${({ theme }) => theme.colors.gray[300]};
      height: 40%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      object-fit: cover;
      pointer-events: none;
      user-select: none;
      ${({ isLoading }) => isLoading && 'display: none;'}
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 1px;
      bottom: 0;
      width: calc(100% - 2px);
      height: 100%;
      pointer-events: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .spinner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }

    button {
      height: 34px;
      width: 34px;
      display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'flex')};
      position: absolute;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.colors.background};
      border: none;
      background-color: transparent;
      padding: 0;
      transition: 0.2s;
      pointer-events: all;
      user-select: none;
      border-radius: 6px;

      svg {
        height: 26px;
        filter: ${({ isLiked }) => (isLiked ? 'none' : 'drop-shadow(0 0 1px #0000009c)')};
      }

      &:hover {
        cursor: pointer;
      }
    }

    .delete-btn {
      right: 12px;
      top: 12px;
      background-color: ${({ theme }) => theme.colors.foreground}80;

      &:hover {
        background-color: ${({ theme }) => theme.colors.foreground}a4;
      }
    }

    .fav-btn {
      right: 12px;
      bottom: 12px;

      svg {
        ${({ isLiked }) =>
    isLiked &&
          css`
            animation: ${likeAnim} forwards 0.2s ease-in-out;
          `}
        path {
          stroke-width: 1.7px;
        }
      }

      &:hover {
        background-color: ${({ isLiked, theme }) =>
    (isLiked ? `${theme.colors.red[600]}20` : `${theme.colors.background}50`)};
      }
    }
  }

  .draft-info-container {
    padding: 14px;
    display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};

    .draft-title {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.gray[700]};
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .draft-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 6px 0 2px 0;

      .draft-date,
      .draft-used-btn {
        white-space: nowrap;
        flex-shrink: 0;
        font-size: 14px;
        font-weight: 600;
        height: 26px;
        border-radius: 6px;
        padding: 0 8px;
        display: flex;
        align-items: center;
        border: 0;
        color: ${({ theme }) => theme.colors.gray[500]};
        background-color: ${({ theme }) => theme.colors.gray[100]};

        svg {
          height: 20px;
          width: 20px;
          margin-left: -2px;
          margin-right: 6px;
        }
      }

      .draft-used-btn {
        transition: 0.2s;

        .show-hover {
          display: none;
        }

        &:hover {
          cursor: pointer;
          background-color: ${({ theme }) => theme.colors.gray[200]};

          .hide-hover {
            display: none;
          }

          .show-hover {
            display: flex;
          }
        }
      }

      .draft-social-profiles {
        display: flex;
        align-items: center;
        margin: 0 0 0 12px;
        padding: 0;
        list-style-type: none;

        .draft-social-profile {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          position: relative;
          box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.background};

          .avatar {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }

          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
          }

          &:not(:last-child) {
            margin-right: -8px;
          }
        }
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    pointer-events: none;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  }

  &:hover {
    cursor: pointer;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.s}px) {
    .draft-image {
      .delete-btn {
        opacity: 0;
      }
    }

    &:hover {
      .draft-image {
        .delete-btn {
          opacity: 1;
        }
      }
    }
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
    box-shadow: 0 0 0 3px ${theme.colors.background}, 0 0 0 6px ${theme.colors.green[200]};
  `}
`

export default StyledDraftItem
