import React, { FC, useState, useEffect } from 'react'
import { PlusIcon } from '@heroicons/react/outline'
import { useApolloClient } from '@apollo/client'
import { StyledDraftsTile } from '.'
import { DraftItem } from '../../DraftsView'
import useDraft from '../../../redux/draft-drawer/useDraft'
import { QuickDraftOverwriteModal } from '../../Modals'
import { ScrollRow } from '../../System'
import { SkeletonLoader } from '../../SkeletonLoader'
import useSegment from '../../utils/useSegment'
import {
  SearchUserDrafts,
  SearchUserDrafts_searchUserDrafts_docs
} from '../../../graphql/UserDraft/__generated__/SearchUserDrafts'
import { searchUserDraftsQuery } from '../../../graphql/UserDraft/searchUserDrafts'

const DraftsTile: FC = () => {
  const {
    isSaved,
    isClear,
    actions: { setDraft, openDraft }
  } = useDraft()
  const { track } = useSegment()
  const apollo = useApolloClient()
  const [drafts, setDrafts] = useState<SearchUserDrafts_searchUserDrafts_docs[]>([])
  const [loading, setLoading] = useState(true)
  const [showSwitchModal, setShowSwitchModal] = useState(false)
  const [switchDraftId, setSwitchDraftId] = useState('')

  const handleSwitch = (draftId: string) => {
    if (isSaved) {
      setDraft(draftId)
      openDraft()
      return
    }
    setSwitchDraftId(draftId)
    setShowSwitchModal(true)
  }

  const overwriteDraft = () => {
    setDraft(switchDraftId)
    openDraft()
  }

  const openDraftDrawer = () => {
    track('Opened Draft Drawer', { button: 'drafts-tile', from: window.location.pathname })
    openDraft()
  }

  const loadDrafts = async () => {
    try {
      const { data } = await apollo.query<SearchUserDrafts>({
        query: searchUserDraftsQuery,
        fetchPolicy: 'no-cache',
        variables: {
          page: 0,
          search: '',
          sort: 'modified',
          items: 8,
          categories: []
        }
      })

      setDrafts(data.searchUserDrafts.docs || [])
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setDrafts([])
    loadDrafts()
  }, [])

  useEffect(() => {
    if (isClear) {
      loadDrafts()
    }
  }, [isClear])

  return (
    <>
      <StyledDraftsTile>
        {(loading || !!drafts.length) && (
          <div className="draft-title-empty-state scroll-item">
            <div className="draft-title-empty-state-text-container">
              <span className="draft-title-empty-state-title">Market Your Business</span>
              <span className="draft-title-empty-state-body">
                Create & schedule
                <br />a new post.
              </span>
              <button className="create-draft-btn" onClick={() => openDraftDrawer()}>
                New Post <PlusIcon />
              </button>
            </div>
          </div>
        )}
        {(loading || !!drafts.length) && (
          <ScrollRow itemsToShow={3}>
            {!loading &&
              drafts.map((draft) => (
                <DraftItem draft={draft} key={draft._id} onClick={() => handleSwitch(draft._id)} />
              ))}
            {loading && <SkeletonLoader quantity={4} type="square-list-item" />}
          </ScrollRow>
        )}
        {!loading && !drafts.length && (
          <div className="draft-title-empty-state full-width">
            <div className="draft-title-empty-state-text-container">
              <span className="draft-title-empty-state-title">
                Time to Plan and Schedule Your Posts
              </span>
              <span className="draft-title-empty-state-body">
                More followers and sales are just one post away...let's get started!
              </span>
              <button className="create-draft-btn" onClick={() => openDraftDrawer()}>
                New Post <PlusIcon />
              </button>
            </div>
          </div>
        )}
      </StyledDraftsTile>
      <QuickDraftOverwriteModal
        isOpen={showSwitchModal}
        onClose={() => setShowSwitchModal(false)}
        onConfirm={() => overwriteDraft()}
      />
    </>
  )
}

export default DraftsTile
