import styled from 'styled-components'

type StyledProps = {
  isActive?: boolean;
}

const StyledUserBubble = styled.button<StyledProps>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  border: none;

  img {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    object-fit: cover;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    opacity: ${({ isActive }) => (isActive ? 1 : 0.2)};
    transition: opacity 0.2s;
  }

  &:not(:last-child) {
    margin-right: 20px;
  }

  &::after {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    transition: box-shadow 0.2s;
    box-shadow: inset 0 0 0 1px #0000000f;
    ${({ isActive, theme }) =>
    isActive &&
      `
      box-shadow: inset 0 0 0 4px #FFFFFF, 0 0 0 3px ${theme.colors.green[500]}, 0 0 0 7px ${theme.colors.green[100]};
    `}
  }

  &:hover {
    cursor: pointer;

    img {
      opacity: ${({ isActive }) => (isActive ? 1 : 0.4)};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    width: 60px;
    height: 60px;
  }
`

export default StyledUserBubble
