import styled from 'styled-components'

const StyledIssueTabs = styled.div`
  display: flex;
  justify-content: space-between;
  overflow-x: hidden;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};

  .tab-list.hidden {
    display: none;
  }

  .tab-list {
    margin-right: 15px;
  }

  .dropdown-placeholder {
    border: none;
    min-width: 100%;
  }

  .select-dropdown div div {
    border: 0;
  }

  .dropdown-list {
    border-top: 1px solid ${({ theme }) => theme.colors.gray[200]};
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    padding-top: 9px;
    width: max-content;
    padding-right: 21px;
  }

  .scroll-button {
    padding: 0 20px;
  }
  
  .download-button {
    text-transform: none;
  }

  @media (max-width: ${({ theme }) => theme.size.sm}px) {
    display: grid;
    border-bottom: none;

    .right-bar {
      margin-top: 10px;
      display: block;
    }

    .scroll-button {
      padding: 0 8px;
    }
  }

  @media (max-width: ${({ theme }) => theme.size.xs}px) {
    .scroll-button {
      padding: 0 4px;
      width: fit-content;
    }

    .tab-list {
      width: calc(100vw - 2rem);
      margin: 0;
    }

    .tab-list div {
      width: 100%;
    }
  }
`
export default StyledIssueTabs
