import styled from 'styled-components'

const StyledMissingInfoModal = styled.div`
  .modal {
    max-width: 600px;

    p {
      margin-top: 0;
      line-height: 130%;
    }

    button {
      display: block;
      margin: 10px auto 0 auto;
    }
  }
`

export default StyledMissingInfoModal
