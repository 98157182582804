import React, {
  FC, createRef, useEffect, useState, useMemo, ReactNode
} from 'react'
import { throttle } from 'lodash'
import { PaginationButton } from '..'
import { StyledScrollRow } from '.'
import { useWindowSize } from '../../utils'

type Props = {
  itemsToShow?: number;
  customWidth?: boolean;
  children?: JSX.Element[] | ReactNode;
}

const ScrollRow: FC<Props> = ({ itemsToShow = 3, customWidth, children }) => {
  const { breakpoints } = useWindowSize()

  const determineElementsToShow = () => {
    if (breakpoints.includes('l')) {
      return itemsToShow
    }
    if (breakpoints.includes('m')) {
      return 3
    }
    if (breakpoints.includes('s')) {
      return 2
    }
    return 1
  }

  const showNum = useMemo(() => determineElementsToShow(), [breakpoints])
  const [scrolledToStart, setScrolledToStart] = useState(false)
  const [scrollOffset, setScrollOffset] = useState(0)
  const [scrollWidth, setScrollWidth] = useState(0)
  const [elementWidth, setElementWidth] = useState(0)

  const scrollRef = createRef<HTMLDivElement>()
  const parentRef = createRef<HTMLDivElement>()
  const padding = 12

  // Handles the size of the scroll row's children
  // (ensuring only the correct amount of elements are shown at a time)
  const handleSize = throttle(() => {
    if (scrollRef.current !== null) {
      const scrollContainer = scrollRef.current
      const containerWidth = scrollContainer.getBoundingClientRect().width
      setElementWidth(Math.floor((containerWidth - padding * (showNum - 1)) / showNum))
      setScrollWidth(scrollContainer.scrollWidth - scrollContainer.clientWidth)
    }
  }, 10)

  // Updates the amount the user has scrolled through the row on scroll
  const handleScroll = throttle(() => {
    if (scrollRef.current) {
      setScrollOffset(scrollRef.current.scrollLeft)
    }
  }, 333)

  // Handles left or right pagination of the row
  const scrollDirection = (direction: 'left' | 'right') => {
    if (!scrollRef.current) {
      return
    }

    if (direction === 'left') {
      return scrollRef.current.scroll({
        left: scrollOffset - scrollRef.current.getBoundingClientRect().width,
        behavior: 'smooth'
      })
    }

    return scrollRef.current.scroll({
      left: scrollOffset + scrollRef.current.getBoundingClientRect().width,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    handleSize()

    if (!parentRef.current || !scrollRef.current) {
      return
    }

    const resizeObserver = new ResizeObserver(() => {
      handleSize()
    })

    resizeObserver.observe(parentRef.current)
    scrollRef.current?.addEventListener('scroll', handleScroll)

    return () => {
      resizeObserver.disconnect()
      scrollRef.current?.removeEventListener('scroll', handleScroll)
    }
  }, [parentRef, scrollRef])

  // Ensures that the row is scrolled to the left
  // every time it is loaded
  useEffect(() => {
    if (!scrollRef.current || !children || scrolledToStart) {
      return
    }

    scrollRef.current.scrollTo({ left: 0 })

    setScrolledToStart(true)
  }, [children])

  useEffect(() => {
    setScrolledToStart(false)
  }, [])

  return (
    <StyledScrollRow
      ref={parentRef}
      customWidth={customWidth}
      width={elementWidth}
      padding={padding}
    >
      <div className="scroll-container" ref={scrollRef}>
        {children}
      </div>
      {scrollOffset > 0 && (
        <PaginationButton onClick={() => scrollDirection('left')} direction="left" />
      )}
      {scrollOffset !== scrollWidth && (
        <PaginationButton onClick={() => scrollDirection('right')} direction="right" />
      )}
    </StyledScrollRow>
  )
}

export default ScrollRow
