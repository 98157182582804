export type SelectOption = {
  value: string;
  label: string;
}

const labelsByValues = (defaultValue: string[] | string, values: SelectOption[]): string => {
  const matched = values
    .filter((item) => {
      if (Array.isArray(defaultValue)) {
        return defaultValue.includes(item.value)
      }
      return defaultValue === item.value
    })
    .map((item) => item.label)
  return matched.join(', ') || '—'
}

export const STYLE_VALUES: SelectOption[] = [
  {
    label: 'Light & Airy',
    value: 'light_airy'
  },
  {
    label: 'Dark & Moody',
    value: 'dark_moody'
  },
  {
    label: 'Vibrant',
    value: 'vibrant'
  }
]
export const STYLE_LABELS = (keys: string[]) => {
  return labelsByValues(keys, STYLE_VALUES)
}

export const SEASON_VALUES: SelectOption[] = [
  {
    value: 'spring',
    label: 'Spring'
  },
  {
    value: 'summer',
    label: 'Summer'
  },
  {
    value: 'fall',
    label: 'Fall'
  },
  {
    value: 'winter',
    label: 'Winter'
  },
  {
    value: 'all_seasons',
    label: 'All Seasons'
  },
  {
    value: 'holiday',
    label: 'Holiday'
  }
]
export const SEASON_LABELS = (keys: string[]) => {
  return labelsByValues(keys, SEASON_VALUES)
}

export const ORIENTATION_VALUES: SelectOption[] = [
  {
    value: 'vertical',
    label: 'Vertical'
  },
  {
    value: 'horizontal',
    label: 'Horizontal'
  }
]
export const ORIENTATION_LABELS = (key: string) => {
  return labelsByValues(key, ORIENTATION_VALUES)
}

export const CATEGORY_VALUES: SelectOption[] = [
  {
    value: 'animals',
    label: 'Animals'
  },
  {
    value: 'architecture',
    label: 'Architecture'
  },
  {
    value: 'celebration',
    label: 'Celebration'
  },
  {
    value: 'desk_office',
    label: 'Desk + Office'
  },
  {
    value: 'fabric_textures',
    label: 'Fabric + Textures'
  },
  {
    value: 'fashion',
    label: 'Fashion'
  },
  {
    value: 'flatlay',
    label: 'Flatlay'
  },
  {
    value: 'flowers_plants',
    label: 'Flowers + Plants'
  },
  {
    value: 'food_drink',
    label: 'Food + Drink'
  },
  {
    value: 'health_wellness',
    label: 'Health + Wellness'
  },
  {
    value: 'home_interior',
    label: 'Home Interior'
  },
  {
    value: 'nature',
    label: 'Nature'
  },
  {
    value: 'negative_space',
    label: 'Negative Space'
  },
  {
    value: 'outdoor_recreation',
    label: 'Outdoor Recreation'
  },
  {
    value: 'people',
    label: 'People'
  },
  {
    value: 'personal_care',
    label: 'Personal Care'
  },
  {
    value: 'phones_screens',
    label: 'Phones + Screens'
  },
  {
    value: 'quotes_signs',
    label: 'Quotes + Signs'
  },
  {
    value: 'stationary',
    label: 'Stationery'
  },
  {
    value: 'travel',
    label: 'Travel'
  }
]

export const CATEGORY_LABELS = (keys: string[]) => {
  return labelsByValues(keys, CATEGORY_VALUES)
}

export const SELECT_ALL_VALUE: SelectOption = {
  value: '*',
  label: 'Select All'
}

export const GALLERY_COLORS: SelectOption[] = [
  {
    value: '_ffffff',
    label: 'White'
  },
  {
    value: '_EBB7BE',
    label: 'Pink'
  },
  {
    value: '_C2152C',
    label: 'Red'
  },
  {
    value: '_F2AE7C',
    label: 'Orange'
  },
  {
    value: '_EBE291',
    label: 'Yellow'
  },
  {
    value: '_EDD5A6',
    label: 'Beige'
  },
  {
    value: '_864B15',
    label: 'Brown'
  },
  {
    value: '_83C996',
    label: 'Light Green'
  },
  {
    value: '_126F10',
    label: 'Green'
  },
  {
    value: '_C9E1F3',
    label: 'Light Blue'
  },
  {
    value: '_2F5CCF',
    label: 'Blue'
  },
  {
    value: '_B698DC',
    label: 'Purple'
  },
  {
    value: '_B1B1B1',
    label: 'Grey'
  },
  {
    value: '_000000',
    label: 'Black'
  },
  {
    value: 'multi',
    label: 'Multi'
  }
]
