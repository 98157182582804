/* eslint-disable no-useless-escape */
export const starWars = `
_______   ______   .______                                      
|   ____| /  __  \\  |   _  \\                                     
|  |__   |  |  |  | |  |_)  |                                    
|   __|  |  |  |  | |      /                                     
|  |     |  \`--\`  | |  |\\  \\----.                                
|__|      \______/  | _| \`._____|                                
 __        ______     ______    __  ___  __  .__   __.   _______ 
|  |      /  __  \\   /  __  \\  |  |/  / |  | |  \\ |  |  /  _____|
|  |     |  |  |  | |  |  |  | |  \`  /  |  | |   \\|  | |  |  __  
|  |     |  |  |  | |  |  |  | |    <   |  | |  . \`  | |  | |_ | 
|  \`----.|  \`--\`  | |  \`--\`  | |  .  \\  |  | |  |\\   | |  |__| | 
|_______| \\______/   \\______/  |__|\\__\\ |__| |__| \\__|  \\______| 
     ___      .______      _______                               
    /   \\     |   _  \    |   ____|                              
   /  ^  \\    |  |_)  |   |  |__                                 
  /  /_\\  \\   |      /    |   __|                                
 /  _____  \\  |  |\\  \\----|  |____                               
/__/     \\__\\ | _| \`._____|_______|                              
____    ____  ______    __    __                                 
\\   \\  /   / /  __  \  |  |  |  |                                
 \\   \\/   / |  |  |  | |  |  |  |                                
  \\_    _/  |  |  |  | |  |  |  |                                
    |  |    |  \`--\`  | |  \`--\`  |                                
 __ |__|   __\\______/ __\\______/   _______.                      
|  |      /  __  \\   /  _____|    /       |                      
|  |     |  |  |  | |  |  __     |   (----\`                      
|  |     |  |  |  | |  | |_ |     \\   \\                          
|  \`----.|  \`--\`  | |  |__| | .----)   |                         
|_______| \\______/   \\______| |_______/                          
.___________.__    __   _______                                  
|           |  |  |  | |   ____|                                 
\`---|  |----|  |__|  | |  |__                                    
    |  |    |   __   | |   __|                                   
    |  |    |  |  |  | |  |____                                  
    |__|    |__|  |__| |_______|                                 
.__   __.   ______   .___________.                               
|  \\ |  |  /  __  \\  |           |                               
|   \\|  | |  |  |  | \`---|  |----\`                               
|  . \`  | |  |  |  |     |  |                                    
|  |\\   | |  \`--\`  |     |  |                                    
|__| \\__|  \\______/      |__|                                    
     ___      .______      _______                               
    /   \\     |   _  \\    |   ____|                              
   /  ^  \\    |  |_)  |   |  |__                                 
  /  /_\\  \\   |      /    |   __|                                
 /  _____  \\  |  |\\  \\----|  |____                               
/__/     \\__\\ | _| \`._____|_______|                              
.___________.__    __   _______     _______. _______             
|           |  |  |  | |   ____|   /       ||   ____|            
\`---|  |----|  |__|  | |  |__     |   (----\`|  |__               
    |  |    |   __   | |   __|     \\   \\    |   __|              
    |  |    |  |  |  | |  |____.----)   |   |  |____             
    |__|    |__|  |__| |_______|_______/    |_______|
`

export const rickAndMorty = `
██████╗ ███████╗                                      
██╔══██╗██╔════╝                                      
██████╔╝█████╗                                        
██╔══██╗██╔══╝                                        
██████╔╝███████╗                                      
╚═════╝ ╚══════╝                                      
                                                      
 ██████╗  ██████╗  ██████╗ ██████╗                    
██╔════╝ ██╔═══██╗██╔═══██╗██╔══██╗                   
██║  ███╗██║   ██║██║   ██║██║  ██║                   
██║   ██║██║   ██║██║   ██║██║  ██║                   
╚██████╔╝╚██████╔╝╚██████╔╝██████╔╝▄█╗                
 ╚═════╝  ╚═════╝  ╚═════╝ ╚═════╝ ╚═╝                
                                                      
███╗   ███╗ ██████╗ ██████╗ ████████╗██╗   ██╗        
████╗ ████║██╔═══██╗██╔══██╗╚══██╔══╝╚██╗ ██╔╝        
██╔████╔██║██║   ██║██████╔╝   ██║    ╚████╔╝         
██║╚██╔╝██║██║   ██║██╔══██╗   ██║     ╚██╔╝          
██║ ╚═╝ ██║╚██████╔╝██║  ██║   ██║      ██║██╗        
╚═╝     ╚═╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝      ╚═╝╚═╝        
                                                      
██████╗ ███████╗                                      
██╔══██╗██╔════╝                                      
██████╔╝█████╗                                        
██╔══██╗██╔══╝                                        
██████╔╝███████╗                                      
╚═════╝ ╚══════╝                                      
                                                      
██████╗ ███████╗████████╗████████╗███████╗██████╗     
██╔══██╗██╔════╝╚══██╔══╝╚══██╔══╝██╔════╝██╔══██╗    
██████╔╝█████╗     ██║      ██║   █████╗  ██████╔╝    
██╔══██╗██╔══╝     ██║      ██║   ██╔══╝  ██╔══██╗    
██████╔╝███████╗   ██║      ██║   ███████╗██║  ██║    
╚═════╝ ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚═╝  ╚═╝    
                                                      
████████╗██╗  ██╗ █████╗ ███╗   ██╗                   
╚══██╔══╝██║  ██║██╔══██╗████╗  ██║                   
   ██║   ███████║███████║██╔██╗ ██║                   
   ██║   ██╔══██║██╔══██║██║╚██╗██║                   
   ██║   ██║  ██║██║  ██║██║ ╚████║                   
   ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝                   
                                                      
███╗   ███╗███████╗                                   
████╗ ████║██╔════╝                                   
██╔████╔██║█████╗                                     
██║╚██╔╝██║██╔══╝                                     
██║ ╚═╝ ██║███████╗██╗                                
╚═╝     ╚═╝╚══════╝╚═╝                                
                                                      
          ██████╗ ██╗ ██████╗██╗  ██╗                 
          ██╔══██╗██║██╔════╝██║ ██╔╝                 
█████╗    ██████╔╝██║██║     █████╔╝                  
╚════╝    ██╔══██╗██║██║     ██╔═██╗                  
          ██║  ██║██║╚██████╗██║  ██╗                 
          ╚═╝  ╚═╝╚═╝ ╚═════╝╚═╝  ╚═╝
`

export const whatAreYouDoing = `Hey! Just what do you think you're doing here??`
