import React, { FC } from 'react'
import { StyledGalleryCollectionsItem } from '.'
import { PhotographIcon } from '@heroicons/react/solid'
import ROUTES from '../../../utils/routes'
import { SearchGalleryCollections_searchGalleryCollections_docs } from '../../../../graphql/GalleryCollection/__generated__/SearchGalleryCollections'

type Props = {
  collection: SearchGalleryCollections_searchGalleryCollections_docs;
}

const GalleryCollectionsItem: FC<Props> = ({ collection }) => {
  return (
    <StyledGalleryCollectionsItem to={ROUTES.gallery.buildCollectionPage(collection._id)} className="scroll-item">
      <div className="collection-item-img">
        {collection.defaultPreview?.fileHttpLink && (
          <img src={collection.defaultPreview.fileHttpLink} alt={collection.name}/>
        )}
        {!collection.defaultPreview?.fileHttpLink && (
        <PhotographIcon className="empty-icon"/>)}
      </div>
      <div className="collection-item-details">
        <span className="collection-item-title">{collection.name}</span>
        <span className="collection-item-count">{collection.countImages} Image{collection.countImages !== 1 && 's'}</span>
      </div>
    </StyledGalleryCollectionsItem>
  )
}

export default GalleryCollectionsItem
