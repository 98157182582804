import styled from 'styled-components'

const StyledIssueView = styled.div`
  width: 100%;

  .issue-header {
    padding: 2rem 4rem;
    background: ${({ theme }) => theme.colors.background};
    position: relative;
    z-index: 1;
    box-shadow: 0px 8px 16px 2px rgba(97, 97, 97, 0.1);
    border-radius: 20px;
  }

  .issue-header h1 {
    font-family: ${({ theme }) => theme.fonts.montserrat};
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: ${({ theme }) => theme.colors.gray[800]};
    margin: 0;
  }

  .issue-header h2 {
    font-family: ${({ theme }) => theme.fonts.montserrat};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: ${({ theme }) => theme.colors.gray[500]};
    margin-bottom: 15px;
  }

  .issue-preview-title {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: space-between;
    column-gap: 100px;
    row-gap: 16px;

    button {
      place-self: center;
    }
  }

  .issue-preview-pdf {
    position: relative;
  }

  .issue-video-preview {
    position: relative;
    padding-top: 56.25%;
    user-select: none;

    .video-player {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
      user-select: none;
    }
  }

  .pricing-details {
    margin-right: 1rem;
  }

  .issue-action-bar.preview button,
  .preview .issue-action-bar-title {
    color: ${({ theme }) => theme.colors.gray[300]} !important;
    pointer-events: none;
  }

  @media (min-width: ${({ theme }) => theme.size.sm}px) {
    .issue-preview-title {
      display: flex;
      justify-content: space-between;
    }
  }

  @media (max-width: ${({ theme }) => theme.size.sm}px) {
    .issue-header {
      padding: 2rem;

      h1 {
        font-size: 24px;
        line-height: 33px;
      }
    }
  }
`
export default StyledIssueView
