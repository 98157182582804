import styled from 'styled-components'

const StyledDraftsTile = styled.div`
  position: relative;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: minmax(0, 1fr);

  .draft-title-empty-state {
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.gray[100]};
    min-height: 150px;
    padding: 2rem;
    display: none;
    text-align: center;
    align-items: center;
    justify-content: center;

    .draft-title-empty-state-text-container {
      display: grid;
      text-align: center;
      align-items: center;
      justify-items: center;
      justify-content: center;

      span {
        display: block;
      }

      .draft-title-empty-state-title {
        font-size: 18px;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.gray[700]};
      }

      .draft-title-empty-state-body {
        margin-top: 10px;
        max-width: 250px;
        color: ${({ theme }) => theme.colors.gray[600]};
      }

      .create-draft-btn {
        display: flex;
        margin-top: 2rem;
        align-items: center;
        border: 0;
        flex-shrink: 0;
        padding: 0 1rem;
        border-radius: 6px;
        height: 45px;
        font-weight: 600;
        transition: 0.2s;
        color: ${({ theme }) => theme.colors.gray[600]};
        background-color: ${({ theme }) => theme.colors.background};

        svg {
          height: 20px;
          width: 20px;
          margin-left: 10px;
        }

        &:hover {
          cursor: pointer;
          background-color: ${({ theme }) => theme.colors.gray[300]}50;
        }
      }
    }

    &.full-width {
      width: 100%;
      grid-column: 1 / 3;
      display: grid;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    grid-template-columns: 250px minmax(0, 1fr);

    .draft-title-empty-state {
      display: grid;
    }
  }
`

export default StyledDraftsTile
