import React, { FC, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { InputDialog } from '../../System'
import { galleryCollectionUpdateByIdQuery } from '../../../graphql/GalleryCollection/galleryCollectionUpdateById'
import { GalleryCollectionUpdateById } from '../../../graphql/GalleryCollection/__generated__/GalleryCollectionUpdateById'
import { gallerySetActiveCollectionAction } from '../../../redux/gallery/actions'

type Props = {
  isOpen: boolean;
  collectionId: string;
  collectionName: string;
  handleClose: () => void;
}

const EditCollectionModal: FC<Props> = ({
  isOpen, collectionId, collectionName, handleClose
}) => {
  const apollo = useApolloClient()
  const dispatch = useDispatch()
  const [name, setName] = useState(collectionName)

  const handleSubmitClick = async () => {
    const { data } = await apollo.mutate<GalleryCollectionUpdateById>({
      mutation: galleryCollectionUpdateByIdQuery,
      variables: { _id: collectionId, name }
    })

    dispatch(gallerySetActiveCollectionAction(data?.galleryCollectionUpdateById?.record || null))
    handleClose()
  }

  return (
    <InputDialog
      value={name}
      title="Rename Your Collection"
      confirmButtonText="Rename"
      isOpen={isOpen}
      onClose={() => handleClose()}
      onChange={(value) => setName(value)}
      onConfirm={() => handleSubmitClick()}
    />
  )
}

export default EditCollectionModal
