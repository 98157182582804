import React, { FC } from 'react'
import { Spinner } from '../../../System'
import { StyledAdminLoader } from '.'

const AdminLoader: FC = () => {
  return (
    <StyledAdminLoader>
      <Spinner />
    </StyledAdminLoader>
  )
}

export default AdminLoader
