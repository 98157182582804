import React, { FC, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useApolloClient } from '@apollo/client'
import { search as searchQuery } from '../../graphql/CaptionSet/captionSearchQuery'
import { ScrollRow } from '../System'
import { StyledCaptions } from '.'
import { SkeletonLoader } from '../SkeletonLoader'
import {
  SearchCaptions,
  SearchCaptions_captionSetSearch_docs
} from '../../graphql/CaptionSet/__generated__/SearchCaptions'
import LibraryCaption from '../CaptionLibraryView/LibraryCaption'

const Captions: FC = () => {
  const apollo = useApolloClient()
  const [loading, setLoading] = useState(true)
  const [captions, setCaptions] = useState<SearchCaptions_captionSetSearch_docs[]>([])

  const loadCaptions = async () => {
    try {
      const { data } = await apollo.query<SearchCaptions>({
        query: searchQuery,
        variables: {
          page: 0,
          search: '',
          liked: false,
          categories: [],
          sort: 'suggested',
          hideUsed: true
        }
      })

      setCaptions(data.captionSetSearch?.docs || [])
      setLoading(false)

      // This removes the captions row from the dashboard if there
      // are no captions
      if (data.captionSetSearch?.docs?.length === 0) {
        // onEmpty()
      }
    }
    catch (err) {
      Sentry.captureException(err)
      console.error(err)
    }
  }

  useEffect(() => {
    loadCaptions()
  }, [])

  return (
    <StyledCaptions>
      <ScrollRow itemsToShow={2}>
        {!loading &&
          captions.slice(0, 8).map((caption, index) => {
            return <LibraryCaption className="scroll-item" key={index} caption={caption} />
          })}
        {loading && <SkeletonLoader quantity={3} type="caption-list-item" />}
      </ScrollRow>
    </StyledCaptions>
  )
}

export default Captions
