import styled from 'styled-components'

const StyledSignInView = styled.div`
  display: grid;

  .auth-marketing {
    display: none;
  }

  .auth-information {
    display: block !important;
    padding: 156px 0;
    font-family: ${({ theme }) => theme.fonts.nunito};

    h1 {
      font-weight: 600;
      font-size: 30px;
      line-height: 37px;
      font-family: ${({ theme }) => theme.fonts.lexend};
      letter-spacing: -0.2px;
      color: ${({ theme }) => theme.colors.gray[700]};
    }

    .input-label {
      font-family: ${({ theme }) => theme.fonts.lexend};
      color: ${({ theme }) => theme.colors.gray[600]};
      font-weight: 400;
      font-size: 16px;
    }

    .input-container {
      input {
        font-family: ${({ theme }) => theme.fonts.nunito};
        font-weight: 500;
        font-size: 15px;
      }

      svg {
        path {
          stroke-width: 1.5px;
        }
      }
    }

    .continue-btn {
      width: 100%;
      font-weight: 400;
      font-size: 1rem;
      letter-spacing: 3px;
      font-family: ${({ theme }) => theme.fonts.lexend};
    }

    .auth-prompt {
      margin-top: 14px;
      display: block;
      text-align: center;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.gray[600]};

      a {
        font-weight: 700;
        font-family: ${({ theme }) => theme.fonts.nunito};
        color: ${({ theme }) => theme.colors.green[500]};
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

    .auth-marketing {
      display: block;
    }
  }
`

export default StyledSignInView
