import {
  ISSUE_FORM_UPDATE_FIELD,
  ISSUE_FORM_UPDATE_STATE,
  IssueFormActions,
  IssueFormState,
  ISSUE_FORM_START_LOADING,
  ISSUE_FORM_SUCCESS,
  ISSUE_FORM_ERROR
} from './types'
import { GetIssueById_issueFindById } from '../../graphql/Issue/__generated__/GetIssueById'

const initialState: IssueFormState = {
  form: {
    // initialize with these values
    date: new Date().toISOString()
  } as GetIssueById_issueFindById,
  loading: true,
  success: false,
  error: false,
  errorMessage: null,
  successMessage: null
}

export const issueFormReducer = (
  state = initialState,
  action: IssueFormActions
): IssueFormState => {
  switch (action.type) {
    case ISSUE_FORM_UPDATE_FIELD:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.field]: action.payload.value,
          _id: action.meta.issueId || ''
        }
      }
    case ISSUE_FORM_UPDATE_STATE:
      if (state.form._id && action.payload._id && state.form._id !== action.payload._id) {
        return {
          ...state,
          loading: false,
          form: {
            ...initialState.form,
            ...action.payload
          }
        }
      }
      return {
        ...state,
        loading: false,
        form: {
          ...state.form,
          ...action.payload
        }
      }
    case ISSUE_FORM_START_LOADING:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
        error: false,
        success: false,
        loading: true
      }
    case ISSUE_FORM_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        successMessage: action.payload,
        error: false,
        success: true,
        loading: false
      }
    case ISSUE_FORM_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: null,
        error: true,
        success: false,
        loading: false
      }
    default:
      // eslint-disable-next-line no-case-declarations,@typescript-eslint/no-unused-vars
      const x: never = action
  }
  return state
}
