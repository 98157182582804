import styled from 'styled-components'

const StyledMultiUploadInput = styled.div`
  display: grid;
  grid-gap: 10px;

  .file-bullet {
    padding: 1rem;
    background: ${({ theme }) => theme.colors.gray[50]};
    border-radius: 3px;
    display: grid;
    grid-gap: 10px;

    .file-bullet-input {
      display: flex;
      align-items: center;

      .input {
        width: 100%;
      }

      .delete-btn {
        margin-left: 10px;
      }
    }
  }

  .add-btn:not(:first-child) {
    margin-top: 6px;
  }
`

export default StyledMultiUploadInput
