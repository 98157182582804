import styled from 'styled-components'

const StyledGalleryFeed = styled.div`
  .gallery-feed {
    margin: 0 -16px 0 0;
  }  
  
  div {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    .gallery-feed {
      margin: 0 -16px 0 0;
    }
  }
`

export default StyledGalleryFeed
