import jszip from 'jszip'
import path from 'path'
import { useState } from 'react'
import { random } from 'lodash'
import { FileObjectType } from '../../types'
import { handleSlug } from './handleSlug'

export const useZipAndDownload = () => {
  const [downloadLoading, setDownloadLoading] = useState(false)

  // Zips an array of files and downloads it to the user's device
  const downloadZipFromFiles = async (files: FileObjectType[], fileType: string) => {
    setDownloadLoading(true)
    const zip = new jszip()
    let index = 0

    // Download all the files
    const promises = files.map(async ({ fullPath, fileHttpLink }) => {
      const file = await fetch(fileHttpLink)

      return {
        file,
        fullPath
      }
    })

    const responses = await Promise.all(promises)

    // Loop through each file, getting its buffer and appending it to the zip file
    for (const { file, fullPath } of responses) {
      const buffer = await file.arrayBuffer()
      const extension = path.extname(fullPath)
      zip.file(`${fileType} #${index + 1}${extension}`, buffer, { binary: true })
      index++
    }

    // Get the base64 of the zip
    const zipBase64 = await zip.generateAsync({ type: 'base64' })
    const url = `data:application/zip;base64,${zipBase64}`

    // Initiate the download of the zip file
    const link = document.createElement('a')
    link.href = url
    link.download = `sc-${handleSlug(fileType)}-${random(99, 999)}.zip`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    setDownloadLoading(false)
  }

  return {
    downloadLoading,
    actions: { downloadZipFromFiles }
  }
}
