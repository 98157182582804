import styled from 'styled-components'

const StyledIssueHighlights = styled.div`
  margin-top: 1rem;
  width: 50%;
  margin-left: auto;

  .issue-highlights-title {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.gray[600]};
    
    margin-bottom: 1rem;
    display: block;
  }

  .issue-highlights-grid {
    display: grid;
  }
`
export default StyledIssueHighlights
