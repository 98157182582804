import React, {
  FC,
  useEffect,
  useState,
  createRef,
  KeyboardEvent,
  FocusEvent,
  RefObject
} from 'react'
import Calendar from 'react-calendar'
import moment from 'moment'
import { ChevronLeftIcon, ChevronRightIcon, ClockIcon } from '@heroicons/react/outline'
import { createPortal } from 'react-dom'
import { StyledDateTimeInput, TimeSelectValues } from '.'
import useClickOutsideComponent from '../../utils/useClickOutsideComponent'

type Props = {
  value?: Date;
  buttonRef: RefObject<HTMLButtonElement>;
  onChange: (date: Date) => void;
  onClose: () => void;
  onClear: () => void;
}

const DateTimeInput: FC<Props> = ({
  value, buttonRef, onChange, onClose, onClear
}) => {
  const [timeInputValue, setTimeInputValue] = useState('')
  const [activeDate, setActiveDate] = useState(value || new Date())
  const [hasError, setHasError] = useState(false)
  const [showTimeDropdown, setShowTimeDropdown] = useState(false)
  const [meridiem, setMeridiem] = useState<'am' | 'pm'>('am')
  const pickerRef = createRef<HTMLDivElement>()
  const timepickerRef = createRef<HTMLInputElement>()
  const timeInputContainerRef = createRef<HTMLDivElement>()
  const timeDropdownRef = createRef<HTMLDivElement>()

  useClickOutsideComponent(pickerRef, () => onClose())

  // Positions the time select dropdown
  const positionTimeDropdown = () => {
    if (
      !buttonRef.current ||
      !pickerRef.current ||
      !timeDropdownRef.current ||
      !timeInputContainerRef.current
    ) {
      return
    }

    const input = pickerRef.current
    const button = buttonRef.current
    const timeInput = timeInputContainerRef.current
    const timeDropdown = timeDropdownRef.current

    const PADDING = 8

    const buttonY = button.getBoundingClientRect().y
    const buttonX = button.getBoundingClientRect().x

    const inputHeight = input.getBoundingClientRect().height

    input.style.top = `${Math.max(buttonY - inputHeight - PADDING, PADDING)}px`
    input.style.left = `${buttonX}px`

    const timeInputY = timeInput.getBoundingClientRect().y
    const timeInputX = timeInput.getBoundingClientRect().x
    const timeInputHeight = timeInput.getBoundingClientRect().height

    timeDropdown.style.top = `${timeInputY + timeInputHeight + PADDING}px`
    timeDropdown.style.left = `${timeInputX}px`
  }

  // Parses the many different ways of typing
  // a timestamp into a formatted string
  const parseTime = (input: string) => {
    if (input.toLowerCase().includes('p')) {
      setMeridiem('pm')
    }

    if (input.toLowerCase().includes('a')) {
      setMeridiem('am')
    }

    const timeString = input.replace(/\D/g, '').trim()

    if (timeString.length === 1) {
      return `${parseInt(timeString)}:00`
    }

    if (timeString.length === 2) {
      const stringSplit = timeString.split('')
      const firstNum = parseInt(stringSplit[0])
      const secondNum = parseInt(stringSplit[1])
      const combined = parseInt(`${stringSplit[0]}${stringSplit[1]}`)

      if (combined > 12 && secondNum > 5) {
        return
      }

      if (combined > 12) {
        return `${firstNum}:${secondNum}0`
      }

      return `${combined}:00`
    }

    if (timeString.length === 3) {
      const stringSplit = timeString.split('')
      const hour = parseInt(stringSplit[0])
      const minute = parseInt(`${stringSplit[1]}${stringSplit[2]}`)

      if (minute > 59) {
        return
      }

      return `${hour}:${minute.toString().padStart(2, '0')}`
    }

    if (timeString.length === 4) {
      const stringSplit = timeString.split('')
      const hour = parseInt(`${stringSplit[0]}${stringSplit[1]}`)
      const minute = parseInt(`${stringSplit[2]}${stringSplit[3]}`)

      if (hour > 24 || minute > 59) {
        return
      }

      if (hour > 12) {
        setMeridiem('pm')
        return `${hour - 12}:${minute.toString().padStart(2, '0')}`
      }

      return `${hour}:${minute.toString().padStart(2, '0')}`
    }
  }

  const handleTimeDropdownSelect = (value: string) => {
    const parsedTime = parseTime(value)

    setHasError(false)
    setShowTimeDropdown(false)
    setTimeInputValue(parsedTime || '')
  }

  const handleTimeBlur = (e: FocusEvent<HTMLInputElement>) => {
    setShowTimeDropdown(false)

    const parsedTime = parseTime(e.currentTarget.value)
    setTimeInputValue(parsedTime || timeInputValue)

    if (!parsedTime) {
      return setHasError(true)
    }

    setHasError(false)
  }

  const handleTimeFocus = (e: FocusEvent<HTMLInputElement>) => {
    setShowTimeDropdown(true)
    e.target.select()
  }

  // Removes the time dropdown on Enter
  const handleTimeKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') {
      return
    }

    e.currentTarget.blur()
  }

  // Turns a string into a JS date
  const getFormattedDate = () => {
    const timeInput = timepickerRef.current

    if (!timeInput) {
      return
    }

    const formattedDate = activeDate
    const timeSplit = timeInput.value.split(':')
    const hourNum = parseInt(timeSplit[0])
    const scaledHour = hourNum === 12 ? 0 : hourNum
    const hour = meridiem === 'am' ? scaledHour : 12 + scaledHour
    const minute = timeSplit[1]

    formattedDate.setMilliseconds(0)
    formattedDate.setSeconds(0)
    formattedDate.setHours(hour)
    formattedDate.setMinutes(parseInt(minute))

    return formattedDate
  }

  const handleDone = () => {
    const formattedDate = getFormattedDate()

    if (!formattedDate) {
      return
    }

    onChange(formattedDate)
    onClose()
  }

  const handleClear = () => {
    onClose()
    onClear()
  }

  useEffect(() => {
    if (
      !buttonRef.current ||
      !pickerRef.current ||
      !timeDropdownRef.current ||
      !timeInputContainerRef.current
    ) {
      return
    }

    positionTimeDropdown()

    document.addEventListener('scroll', () => positionTimeDropdown(), true)

    return () => {
      document.removeEventListener('scroll', () => positionTimeDropdown(), true)
    }
  }, [buttonRef.current, pickerRef.current, timeDropdownRef.current, timeInputContainerRef.current])

  useEffect(() => {
    if (!timepickerRef.current || !value) {
      return
    }

    const hour = value.getHours()
    const hourString = (hour || 12).toString().padStart(2, '0')
    const minute = value
      .getMinutes()
      .toString()
      .padStart(2, '0')

    const parsedTime = parseTime(`${hourString}${minute}`)
    setTimeInputValue(parsedTime || timeInputValue)

    setMeridiem(hour > 11 ? 'pm' : 'am')
    setActiveDate(value)

    if (!parsedTime) {
      return setHasError(true)
    }

    setHasError(false)
  }, [value, timepickerRef.current])

  const Element = (
    <StyledDateTimeInput ref={pickerRef} hasError={hasError} showTimeDropdown={showTimeDropdown}>
      <Calendar
        next2Label={null}
        prev2Label={null}
        nextLabel={<ChevronRightIcon />}
        prevLabel={<ChevronLeftIcon />}
        formatShortWeekday={(locale, date) => moment(date).format('dd')}
        maxDetail="month"
        minDetail="month"
        value={activeDate}
        onChange={(e: Date) => setActiveDate(e)}
      />
      <div className="time-input-container">
        <div className="time-input" ref={timeInputContainerRef}>
          <ClockIcon />
          <input
            type="text"
            name="time"
            ref={timepickerRef}
            value={timeInputValue}
            autoComplete="off"
            onFocus={(e) => handleTimeFocus(e)}
            onBlur={(e) => handleTimeBlur(e)}
            onKeyDown={(e) => handleTimeKeyDown(e)}
            onChange={(e) => setTimeInputValue(e.target.value)}
          />
        </div>
        <div className="time-meridiem-select">
          <label className="time-meridiem-select-item">
            <input
              type="radio"
              value="AM"
              checked={meridiem === 'am'}
              name="meridiem"
              onChange={(e) => e.target.checked && setMeridiem('am')}
            />
            <div className="time-meridiem-select-item-box">
              <span>AM</span>
            </div>
          </label>
          <label className="time-meridiem-select-item">
            <input
              type="radio"
              value="PM"
              checked={meridiem === 'pm'}
              name="meridiem"
              onChange={(e) => e.target.checked && setMeridiem('pm')}
            />
            <div className="time-meridiem-select-item-box">
              <span>PM</span>
            </div>
          </label>
        </div>
      </div>
      <div className="date-time-input-divider" />
      <div className="date-time-input-actions">
        <button className="clear-btn" onClick={() => handleClear()}>
          <span>Clear</span>
        </button>
        <button
          disabled={hasError || !timeInputValue.trim()}
          className="done-btn"
          onClick={() => handleDone()}
        >
          <span>Done</span>
        </button>
      </div>
      <div ref={timeDropdownRef} className="time-dropdown">
        <TimeSelectValues
          value={timeInputValue}
          meridiem={meridiem}
          onSelect={(value) => handleTimeDropdownSelect(value)}
        />
      </div>
    </StyledDateTimeInput>
  )

  return createPortal(Element, document.getElementById('modal-root') as HTMLElement)
}

export default DateTimeInput
