import { useEffect, useState } from 'react'
import throttle from 'lodash/throttle'

const useWindowHeight = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  useEffect(() => {
    const setSize = throttle(() => {
      setWindowHeight(window.innerHeight)
    }, 100)
    setSize()
    window.addEventListener('resize', setSize)
    return () => window.removeEventListener('resize', setSize)
  }, [])

  return windowHeight
}

export default useWindowHeight
