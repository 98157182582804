import { gql } from '@apollo/client'

export const searchUserDraftsQuery = gql`
  query SearchUserDrafts(
    $search: String!
    $categories: [String!]!
    $liked: Boolean
    $hideUsed: Boolean
    $sort: String!
    $page: Int
    $items: Int!
  ) {
    searchUserDrafts(
      search: $search
      categories: $categories
      liked: $liked
      hideUsed: $hideUsed
      sort: $sort
      page: $page
      items: $items
    ) {
      totalDocs
      page
      hasNextPage
      hasPrevPage
      nextPage
      prevPage
      docs {
        _id
        title
        captionText
        category
        imageUrl
        userUpload
        socialProfiles
        imageId
        fullPath
        template
        challenge
        user
        postStatus
        imageDimensions {
          x
          y
        }
        postDate
        isLiked
        isUsed
        index
        lastModified
        createdAt
      }
    }
  }
`
