import React, { FC, ReactNode } from 'react'
import { FormMessageType } from '../../../types'
import { StyledForm, FormMessage } from '.'

type Props = {
  message?: FormMessageType;
  children?: ReactNode;
  onSubmit?: (e?: any) => void;
}

const Form: FC<Props> = ({ message, children, onSubmit }) => {
  const handleSubmit = (e: any) => {
    if (onSubmit) {
      onSubmit(e)
    }
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      {message && message.message && <FormMessage type={message.type} message={message.message} />}
      {children}
    </StyledForm>
  )
}

export default Form
