import React, { Fragment, FC, useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import firebase from 'firebase/app'
import { useApolloClient } from '@apollo/client'
import { StyledAdminBreadcrumbs, Breadcrumb, DynamicBreadcrumb, BreadcrumbLink } from '.'
import ROUTES from '../../utils/routes'
import { getIssueByIdQuery } from '../../../graphql/Issue/getIssueById'
import { GetIssueById } from '../../../graphql/Issue/__generated__/GetIssueById'

type Props = {
  links?: Breadcrumb[];
}
const AdminBreadcrumbs: FC<Props> = ({ links }: Props) => {
  const { pathname } = useLocation()

  const refActions = firebase.firestore().collection(`daily_actions`)
  const refEvents = firebase.firestore().collection(`community_events`)
  const refUsers = firebase.firestore().collection(`users`)

  const apollo = useApolloClient()

  const commonLinks = [
    { url: ROUTES.admin.dashboard, label: 'Main', path: 'admin' },
    { url: ROUTES.admin.issues.list, label: 'Action Plans', path: 'issues' },
    { url: ROUTES.admin.gallery.mainRoute, label: 'Gallery', path: 'gallery' },
    { url: ROUTES.admin.users.list, label: 'Users list', path: 'users' },
    { url: ROUTES.admin.leads.overview, label: 'Lead Capture', path: 'lead-forms' },
    {
      url: ROUTES.admin.captionMigrator.main,
      label: 'Caption Migrator',
      path: 'caption-image-migrator'
    },
    {
      url: ROUTES.admin.actions.list,
      label: 'Daily Actions',
      path: 'daily-actions'
    },
    {
      url: ROUTES.admin.community.list,
      label: 'Community',
      path: 'community'
    }
  ]

  const getActionById = async (id) => {
    try {
      const doc = await refActions.doc(id).get()
      if (doc.exists) {
        const description = doc.data()?.description || 'No Description'
        return description
      }
    }
    catch (err) {
      Sentry.captureException(err)
      console.error(err)
    }
    return 'New Action'
  }

  const getEventById = async (id) => {
    try {
      const doc = await refEvents.doc(id).get()
      if (doc.exists) {
        const title = doc.data()?.title || 'No Title'
        return title
      }
    }
    catch (err) {
      Sentry.captureException(err)
      console.error(err)
    }
    return 'New Event'
  }

  const getIssueById = async (id) => {
    try {
      const { data } = await apollo.query<GetIssueById>({
        query: getIssueByIdQuery,
        fetchPolicy: 'no-cache',
        variables: { id }
      })
      const issueData = data.issueFindById

      return issueData ? issueData.title : 'No Title'
    }
    catch (err) {
      Sentry.captureException(err)
      console.error(err)
    }
    return 'New Action Plan'
  }

  const getUserById = async (id) => {
    try {
      const doc = await refUsers.doc(id).get()
      if (doc.exists) {
        return { ...doc.data() }.displayName || 'No Name'
      }
    }
    catch (err) {
      Sentry.captureException(err)
      console.error(err)
    }
    return 'New User'
  }

  const truncate = (str, maxLength) => {
    return str.length > maxLength ? `${str.substr(0, maxLength - 1)}…` : str
  }

  const handleDynamicBreadcrumb = async (
    breadcrumbData: DynamicBreadcrumb
  ): Promise<BreadcrumbLink> => {
    const { entryId, type } = breadcrumbData.dynamic
    if (type === 'issue') {
      const label = await getIssueById(entryId)
      if (label) {
        return {
          url: ROUTES.admin.issues.editById(entryId, 'main'),
          label: truncate(label, 30),
          isText: breadcrumbData.isText
        }
      }
    }
    else if (type === 'user') {
      const label = await getUserById(entryId)
      if (label) {
        return {
          url: `${ROUTES.admin.users.profileById(entryId)}/account`,
          label: truncate(label, 30),
          isText: breadcrumbData.isText
        }
      }
    }
    else if (type === 'event') {
      const label = await getEventById(entryId)
      if (label) {
        return {
          url: `${ROUTES.admin.community.editById(entryId)}`,
          label: truncate(label, 30),
          isText: breadcrumbData.isText
        }
      }
    }
    else if (type === 'action') {
      const label = await getActionById(entryId)
      if (label) {
        return {
          url: `${ROUTES.admin.actions.editById(entryId)}`,
          label: truncate(label, 30),
          isText: breadcrumbData.isText
        }
      }
    }
    throw new Error(`Unknown type (${type}) for dynamic breadcrumb`)
  }

  const getBasicPathsAsLinks = () => {
    const path = pathname || ''
    const matchedPaths = path
      .split('/')
      .filter(Boolean)
      .slice(0, 2)
    // we get only first two paths
    return matchedPaths.reduce((matchedPathsAsLinks, currentPath) => {
      const findLink = commonLinks.find((item) => item.path === currentPath)
      if (findLink) {
        matchedPathsAsLinks.push(findLink)
      }
      return matchedPathsAsLinks
    }, [] as any[])
  }

  const [readyLinks, setReadyLinks] = useState<BreadcrumbLink[]>([])
  const computeLinks = async () => {
    const arrayOfLinks: BreadcrumbLink[] = getBasicPathsAsLinks()
    if (!Array.isArray(links)) {
      setReadyLinks(arrayOfLinks)
      return
    }
    for (let i = 0; i < links.length; i++) {
      const currentLink = links[i] as any
      if (currentLink.label && !currentLink.url) {
        // is a plain text
        arrayOfLinks.push({ label: currentLink.label })
      }
      else if (currentLink.url && currentLink.label) {
        // simple static breadcrumb
        arrayOfLinks.push({ ...currentLink })
      }
      else if (currentLink.dynamic) {
        // dynamic breadcrumb
        // eslint-disable-next-line no-await-in-loop
        const breadcrumbData = await handleDynamicBreadcrumb(currentLink)
        arrayOfLinks.push(breadcrumbData)
      }
      else {
        throw new Error('Unknown type of breadcrumb')
      }
    }
    setReadyLinks(arrayOfLinks)
  }

  useEffect(() => {
    computeLinks()
  }, [links, pathname])

  return (
    <StyledAdminBreadcrumbs>
      {readyLinks.map((item, index) => {
        const separator = readyLinks.length !== index + 1 && <span>/</span>
        if (!item.url || item.isText) {
          return (
            <Fragment key={item.label}>
              <p>{item.label}</p>
              {separator}
            </Fragment>
          )
        }
        return (
          <Fragment key={item.url}>
            <NavLink to={item.url}>{item.label}</NavLink>
            {separator}
          </Fragment>
        )
      })}
    </StyledAdminBreadcrumbs>
  )
}

export default AdminBreadcrumbs
