import styled from 'styled-components'

const StyledAdminPageForm = styled.div`
  padding: 32px;
  background: white;
  border-radius: 3px;
  box-shadow: 0 0 7px #00000014;

  .row-justify {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .row-emphasis-first {
    grid-template-columns: auto 200px;

    &.three {
      grid-template-columns: auto 300px;
    }

    &.four {
      grid-template-columns: auto 400px;
    }

    &.five {
      grid-template-columns: auto 400px;
    }
  }

  .toggle-mobile-icon {
    display: none;
  }

  @media (max-width: ${({ theme }) => `${theme.size.sm}px`}) {
    .mobile-toggle {
      grid-gap: 10px;

      .toggle-mobile-icon {
        cursor: pointer;
        display: flex;
        justify-self: end;
        align-self: center;
        grid-column: 5 / span 1;
        height: 46px;
        width: 46px;
        border: 1px solid ${({ theme }) => theme.colors.gray[200]};
        color: ${({ theme }) => theme.colors.gray[600]};
        background: ${({ theme }) => theme.colors.background};
        align-items: center;
        justify-content: center;
      }

      .dropdown {
        display: none;
        justify-self: center;
        align-self: center;
        grid-row: 2;
        grid-column: 1 / span 5;
        &.show-filters-mobile {
          display: flex;
        }
      }
    }
  }
`

export default StyledAdminPageForm
