import { gql } from '@apollo/client'

export const userDraftFindByIdQuery = gql`
  query UserDraftFindById($_id: String!) {
    userDraftFindById(_id: $_id) {
      _id
      title
      captionText
      imageUrl
      userUpload
      socialProfiles
      imageId
      fullPath
      template
      challenge
      user
      postStatus
      imageDimensions {
        x
        y
      }
      category
      postDate
      isLiked
      isUsed
      index
      lastModified
      createdAt
    }
  }
`
