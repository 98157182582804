import styled from 'styled-components'

const StyledConnectionsSelect = styled.div`
  display: grid;
  grid-gap: 6px;

  .connections-empty-state {
    padding: 20px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.gray[500]};
    background-color: transparent;
    border-radius: 6px;
    text-align: center;
    font-size: 15px;
    border: 1px dashed ${({ theme }) => theme.colors.gray[300]};
  }
`

export default StyledConnectionsSelect
