import styled from 'styled-components'

type StyledProps = {
  type: 'light' | 'dark' | 'gray' | 'fade' | 'brand';
  size: 'large' | 'small';
}

const StyledAvatar = styled.div<StyledProps>`
  height: ${({ size }) => (size === 'large' ? '63' : '40')}px;
  width: ${({ size }) => (size === 'large' ? '63' : '40')}px;
  background-color: ${({ theme, type }) => type === 'light' && theme.colors.background}
    ${({ theme, type }) => type === 'dark' && theme.colors.foreground}
    ${({ theme, type }) => type === 'gray' && theme.colors.gray[100]}
    ${({ type }) => type === 'fade' && '#0000000A'} ${({ type }) => type === 'brand' && '#FFE2E2'};
  color: ${({ theme, type }) => type === 'light' && theme.colors.gray[900]}
    ${({ theme, type }) => type === 'dark' && theme.colors.background}
    ${({ theme, type }) => type === 'gray' && theme.colors.gray[500]}
    ${({ type }) => type === 'fade' && 'rgba(0, 0, 0, 0.4)'}
    ${({ type }) => type === 'brand' && '#ED6B6B'};
  position: relative;
  flex-shrink: 0;
  font-weight: 600;
  font-size: ${({ size }) => (size === 'large' ? '24' : '18')}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  letter-spacing: 0.03rem;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px #0000001a;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
`

export default StyledAvatar
