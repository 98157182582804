import { RegisterOptions } from 'react-hook-form'

export const loginValidation: { [x: string]: RegisterOptions } = {
  emailAddress: {
    required: {
      value: true,
      message: 'This field is required'
    },
    pattern: {
      // Matches email addresses
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Invalid email address'
    }
  },
  password: {
    required: {
      value: true,
      message: 'This field is required'
    },
    minLength: {
      value: 6,
      message: 'Must be at least 6 characters long'
    }
  }
}