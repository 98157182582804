import {
  coursePrice,
  issuePrice,
  monthPrice,
  annualPrice,
  trialPrice,
  foreverPrice,
  MONTH,
  YEAR,
  FOREVER,
  TRIAL,
  course187Price,
  course197Price,
  courseFirstTimePrice
} from '../../components/utils/stripePrices'

export const appPrices = [
  {
    id: coursePrice,
    interval: '',
    type: 'course'
  },
  {
    id: courseFirstTimePrice,
    interval: '',
    type: 'course'
  },
  {
    id: course187Price,
    interval: '',
    type: 'course'
  },
  {
    id: course197Price,
    interval: '',
    type: 'course'
  },
  {
    id: issuePrice,
    interval: '',
    type: 'issue'
  },
  {
    id: monthPrice,
    interval: MONTH,
    type: 'subscription'
  },
  {
    id: annualPrice,
    interval: YEAR,
    type: 'subscription'
  },
  {
    id: trialPrice,
    interval: TRIAL,
    type: 'subscription'
  },
  {
    id: foreverPrice,
    interval: FOREVER,
    type: 'subscription'
  }
]
