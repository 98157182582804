import { arrayMoveImmutable } from 'array-move'
import { DocumentNode } from '@apollo/client'
import { apolloClient } from './apolloConfig'

export type OnSortEnd = {
  oldIndex: number;
  newIndex: number | null;
}

export type BatchData = {
  _id: string;
  index: number;
}

type EntityItem = {
  _id: string;
  index: number;
}

export type SortParams = {
  indexData: OnSortEnd;
  items: EntityItem[];
}

export const batchUpdateIndexes = async (batchItems: BatchData[], mutation: DocumentNode) => {
  await apolloClient.mutate({
    mutation,
    fetchPolicy: 'no-cache',
    variables: { records: batchItems }
  })
}

export const updateSortPosition = (
  indexData: OnSortEnd,
  entityItems: EntityItem[],
  mutation: DocumentNode
): any[] => {
  let { newIndex, oldIndex } = indexData
  let items = [...entityItems] // copy array

  if (newIndex === oldIndex) {
    return [...items]
  }

  if (typeof newIndex !== 'number') {
    // this item has been removed. This will make it so that
    // everything bigger than or equal to the old index gets updated
    newIndex = items.length
    items = items.filter((item) => item.index !== oldIndex)
  }
  else {
    items = arrayMoveImmutable(items, oldIndex, newIndex)
  }

  const lowestIndex = Math.min(newIndex, oldIndex)
  const highestIndex = Math.max(newIndex, oldIndex)
  const batchItems: BatchData[] = []

  const sorted = items.map((item, index) => {
    if (index >= lowestIndex && index <= highestIndex) {
      batchItems.push({
        _id: item._id,
        index
      })
    }
    // solves the edge case where an index is off
    // likely due to deleted collections
    else if (item.index !== index) {
      batchItems.push({
        _id: item._id,
        index
      })
    }
    return {
      ...item,
      index
    }
  })

  batchUpdateIndexes(batchItems, mutation)
  return sorted
}
