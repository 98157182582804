import styled from 'styled-components'

type StyledProps = {
  modalType?: string;
}

const StyledIssueModalGallery = styled.div<StyledProps>`
  height: 100%;

  .issue-modal-fullscreen {
    position: absolute;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    z-index: ${({ theme }) => theme.zIndex.fixed};
    background: ${({ theme }) => theme.colors.background};
    cursor: zoom-out;
    border-radius: 3px;
    user-select: none;
  }

  .issue-modal-gallery-view {
    height: 400px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: zoom-in;
    user-select: none;
  }

  .issue-modal-gallery-sizer {
    display: grid;
    height: 100%;
    align-content: space-between;
  }

  .issue-modal-gallery-action-bar {
    display: flex;
    justify-content: ${({ modalType }) => (modalType === 'story' ? 'flex-end' : 'space-between')};
    padding: 2rem 1rem 1rem 1rem;
    align-items: center;
    box-sizing: border-box;

    button,
    a {
      &:not(:first-child) {
        margin-left: 6px;
      }
    }

    .use-btn {
      padding: 0 10px;
      font-weight: 600;
      user-select: none;
      height: 34px;
      display: flex;
      align-items: center;
      border: none;
      background-color: transparent;
      transition: 0.2s;
      border-radius: 6px;
      background-color: ${({ theme }) => theme.colors.gray[100]};
      color: ${({ theme }) => theme.colors.gray[600]};

      span {
        position: relative;
        top: 1px;
      }

      svg {
        height: 18px;
        margin-right: 8px;
      }

      &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.gray[200]};
      }
    }
  }

  .issue-modal-gallery-action-bar .liked svg {
    color: ${({ theme }) => theme.colors.red[600]};
    fill: ${({ theme }) => theme.colors.red[600]};
  }

  .issue-modal-gallery-view button + button {
    margin-left: 10px;
  }

  .issue-modal-gallery-view a + button {
    margin-left: 10px;
  }

  .issue-modal-gallery-view button:last-child {
    margin-right: 0px;
  }

  .canva-icon {
    height: 24px;
    width: 24px;
    background: url(/images/canva-logo.png);
    display: block;
    border-radius: 3px;
    background-size: cover;
    margin-left: -2px;
    margin-right: 10px;
  }

  .issue-modal-gallery-select-grid-container {
    overflow-x: auto;
  }

  .issue-modal-gallery-select-grid {
    display: flex;
    overflow-x: auto;
    max-width: 682px;
  }

  .issue-modal-gallery-select {
    position: relative;
    flex-shrink: 0;
    width: 70px;
    height: 110px;
  }

  .issue-modal-gallery-select:last-child {
    border-right: none;
  }

  .issue-modal-gallery-select:hover {
    background-color: ${({ theme }) => theme.colors.gray[200]};
  }

  .issue-modal-gallery-select input:checked + .issue-modal-gallery-select-button img {
    opacity: 1;
  }

  .issue-modal-gallery-select-grid::-webkit-scrollbar {
    height: 8px;
    background: ${({ theme }) => theme.colors.gray[200]};
    border-radius: 0 0 2px 2px;
  }

  .issue-modal-gallery-select-grid::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.gray[300]};
  }

  .issue-modal-gallery-select input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    height: 100%;
    z-index: 1;
    cursor: pointer;
  }

  .issue-modal-gallery-select-button {
    position: absolute;
    left: 5px;
    right: 0;
    top: 5px;
    bottom: 0;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    overflow: hidden;
  }

  .issue-modal-gallery-select-button img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    pointer-events: none;
    opacity: 0.5;
  }
`
export default StyledIssueModalGallery
