import { SearchIssues_searchIssue_docs } from '../../graphql/Issue/__generated__/SearchIssues'

export type IssueListState = {
  issues: SearchIssues_searchIssue_docs[];
  loading: boolean;
  search: string;
  platform: string;
  categories: string[];
  type?: string;
  page: number;
}

export const ISSUES_SEARCH_CHANGE = 'ISSUES_SEARCH_CHANGE'
export type IssuesSearchChangeAction = {
  type: typeof ISSUES_SEARCH_CHANGE;
  payload: string;
}

export const ISSUES_CLEAR_AND_SET_CATEGORY = 'ISSUES_CLEAR_AND_SET_CATEGORY'
export type IssuesClearAndSetCategoryChangeAction = {
  type: typeof ISSUES_CLEAR_AND_SET_CATEGORY;
  payload: string;
}

export const ISSUES_CATEGORY_CHANGE = 'ISSUES_CATEGORY_CHANGE'
export type IssuesCategoryChangeAction = {
  type: typeof ISSUES_CATEGORY_CHANGE;
  payload: string;
}

export const ISSUES_PLATFORM_CHANGE = 'ISSUES_PLATFORM_CHANGE'
export type IssuesPlatformChangeAction = {
  type: typeof ISSUES_PLATFORM_CHANGE;
  payload: string;
}

export const ISSUES_PAGE_CHANGE = 'ISSUES_PAGE_CHANGE'
export type IssuesPageChanged = {
  type: typeof ISSUES_PAGE_CHANGE;
  payload: number;
}

export const SET_ISSUES = 'SET_ISSUES'
export type SetIssues = {
  type: typeof SET_ISSUES;
  payload: SearchIssues_searchIssue_docs[];
}

export const ISSUES_LOAD_SEARCH = 'ISSUES_LOAD_SEARCH'
export type IssuesLoadSearch = {
  type: typeof ISSUES_LOAD_SEARCH;
  payload: any;
}

export type IssueActions =
  | IssuesSearchChangeAction
  | IssuesCategoryChangeAction
  | IssuesPlatformChangeAction
  | IssuesClearAndSetCategoryChangeAction
  | IssuesPageChanged
  | SetIssues
  | IssuesLoadSearch
