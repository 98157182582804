import React, { FC, useContext } from 'react'
import ROUTES from '../../utils/routes'
import { Link } from 'react-router-dom'
import { userStore } from '../../utils/UserContext'
import { ReactComponent as LogoSVG } from '../../icons/logo-black.svg'
import { ReactComponent as LogoBreakdownSVG } from '../../icons/logo-breakdown.svg'

type Props = {
  breakdown?: boolean;
}

const Logo: FC<Props> = ({ breakdown }) => {
  const { user, loaded } = useContext(userStore)
  const isRegistered = loaded && user

  return (
    <div className="logo Logo">
      {isRegistered && (
        <Link to={ROUTES.dashboard.home}>
          {breakdown && <LogoBreakdownSVG />}
          {!breakdown && <LogoSVG />}
        </Link>
      )}
      {!isRegistered && (
        <a href="https://socialcurator.com">
          {breakdown && <LogoBreakdownSVG />}
          {!breakdown && <LogoSVG />}
        </a>
      )}
    </div>
  )
}

export default Logo
