import React, { FC, useState, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Gallery from 'react-photo-gallery'
import { useHistory, useRouteMatch } from 'react-router'
import { SortEndHandler } from 'react-sortable-hoc'
import useGalleryFeed from '../../../redux/gallery/useGalleryFeed'
import { Spinner } from '../../System'
import { shouldCancelStart } from '../GalleryCollections'
import { GalleryPhotoItem } from '..'
import { StyledGalleryFeed, GalleryFeedSortable } from '.'
import { EmptyState } from '../../EmptyState'
import ROUTES from '../../utils/routes'
import { updateSortPosition } from '../../utils/sortingHelpers'
import { reorderGalleryImageLikesMutation } from '../../../graphql/GalleryImagesLikes/reorderGalleryImageLikes'
import { reorderGalleryCollectionImagesMutation } from '../../../graphql/GalleryCollectionsImage/reorderGalleryCollectionImages'
import { GalleryCollectionsTile } from '../GalleryCollections/GalleryCollectionsTile'
import useSegment from '../../utils/useSegment'
import { theme } from '../../utils/theme'

const GalleryFeed: FC = () => {
  const {
    data,
    page,
    countDocs,
    activeTab,
    activeCollection,
    loading,
    actions: { changePage, addImages }
  } = useGalleryFeed()

  const history = useHistory()
  const isIndividualCollection = useRouteMatch(ROUTES.gallery.collectionPage)
  const isCollections = useRouteMatch(ROUTES.gallery.collections) || isIndividualCollection
  const [isDragging, setIsDragging] = useState(false)
  const [galleryColumns, setGalleryColumns] = useState(4)
  const { track } = useSegment()

  const endDragging: SortEndHandler = (indexData) => {
    const isReorderingFavorites = activeTab === 'favorites'

    const updatedArray = updateSortPosition(
      indexData,
      data.map((image) => (isReorderingFavorites ? image.liked : image.collectionImage)) as any[],
      isReorderingFavorites
        ? reorderGalleryImageLikesMutation
        : reorderGalleryCollectionImagesMutation
    )

    // join collectionImage/liked object with parent gallery image for displaying
    const newOrder = updatedArray.map((likeOrCollectionImage) => {
      const fullImage = data.find((image) => image._id === likeOrCollectionImage.image) || {}
      const key = isReorderingFavorites ? 'liked' : 'collectionImage'
      return {
        ...fullImage,
        [key]: likeOrCollectionImage
      }
    })

    addImages(newOrder as any)
    setIsDragging(false)

    track('Reordered Gallery Feed', { feed_type: isReorderingFavorites ? 'favorites' : 'collection' })
  }

  const getGalleryColumns = () => {
    const windowWidth = window.innerWidth

    if (windowWidth >= theme.breakpoints.l) {
      return setGalleryColumns(4)
    }

    if (windowWidth >= theme.breakpoints.m) {
      return setGalleryColumns(3)
    }

    setGalleryColumns(1)
  }

  useEffect(() => {
    getGalleryColumns()
    window.addEventListener('resize', getGalleryColumns)

    return () => {
      window.removeEventListener('resize', getGalleryColumns)
    }
  }, [])

  return (
    <StyledGalleryFeed>
      {!isCollections && <GalleryCollectionsTile />}
      {data && data.length > 0 && (
        <InfiniteScroll
          scrollThreshold={0.7}
          dataLength={data.length}
          next={() => changePage(page + 1)}
          hasMore={countDocs > data.length}
          loader={<Spinner isBlock />}
          className="gallery-feed"
        >
          {isCollections && (
            <GalleryFeedSortable
              lockOffset="0%"
              lockToContainerEdges
              axis="xy"
              isDragging={isDragging}
              lockAxis="xy"
              data={data}
              distance={2}
              columns={galleryColumns}
              onSortStart={() => setIsDragging(true)}
              onSortEnd={endDragging}
              shouldCancelStart={shouldCancelStart}
            />
          )}
          {!isCollections && (
            <Gallery
              direction="column"
              photos={data}
              margin={8}
              columns={galleryColumns}
              renderImage={GalleryPhotoItem as any}
            />
          )}
        </InfiniteScroll>
      )}

      {data.length === 0 && activeCollection?._id && !loading && (
        <EmptyState
          title="No Photos!"
          suggestion="Add pictures from the gallery into your collection"
          height={300}
          action={{
            label: 'Go Back To Gallery',
            function: () => history.push(ROUTES.gallery.main)
          }}
        />
      )}

      {data.length === 0 && !activeCollection?._id && !loading && (
        <EmptyState
          title="No Photos!"
          suggestion="Try searching for a different keyword or change your filters."
          height={300}
        />
      )}
    </StyledGalleryFeed>
  )
}

export default GalleryFeed
