import { isProduction } from './firebase'

const productionSurveys = {
  cancellationSurvey: '50850290-51f3-11eb-9a82-29ed4bff3a51',
  firstBetaGallerySurvey: '8f9cc1f0-6b1d-11eb-b546-3bc7f23368fe',
  secondBetaGallerySurvey: '6bce6d90-6b1b-11eb-8a9d-c9e5448f5f89'
}
const stagingSurveys = {
  cancellationSurvey: 'df875f70-4ac1-11eb-b6fc-6b1b24f0459d',
  firstBetaGallerySurvey: '658da5e0-5048-11eb-aeaf-8b5dc740f707',
  secondBetaGallerySurvey: '6ab72ff0-506e-11eb-a26e-7d551fedf903'
}

const surveys = isProduction ? productionSurveys : stagingSurveys

export default surveys
