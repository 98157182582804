import React from 'react'
import { LinkIcon, PlayIcon, BookOpenIcon, DocumentTextIcon, MenuAlt2Icon, PhotographIcon, DocumentDuplicateIcon, ClockIcon, VolumeUpIcon, DeviceMobileIcon } from '@heroicons/react/outline'

export { default as IconPicker } from './IconPicker'
export { default as StyledIconPicker } from './IconPicker.Styled'

export const ICONS = [
  { name: 'link', icon: <LinkIcon/> },
  { name: 'play', icon: <PlayIcon/> },
  { name: 'book-open', icon: <BookOpenIcon/> },
  { name: 'document-text', icon: <DocumentTextIcon/> },
  { name: 'menu-alt-2', icon: <MenuAlt2Icon/> },
  { name: 'device-mobile-icon', icon: <DeviceMobileIcon/> },
  { name: 'photograph-icon', icon: <PhotographIcon/> },
  { name: 'document-duplicate', icon: <DocumentDuplicateIcon/> },
  { name: 'clock', icon: <ClockIcon/> },
  { name: 'volume-up', icon: <VolumeUpIcon/> }
]