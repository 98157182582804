import styled from 'styled-components'

const StyledPlatformPreviewModal = styled.div`
  width: 100vw;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;

  .main-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3rem 2rem;
  }

  .modal-header-main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .modal-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.5px;
    font-family: ${({ theme }) => theme.fonts.lexend};
    color: ${({ theme }) => theme.colors.gray[700]};
    text-transform: uppercase;
  }

  .modal-subtitle {
    font-family: ${({ theme }) => theme.fonts.nunito};
    font-weight: 500;
    margin-top: 16px;
  }

  .modal-content {
    overflow-y: auto;
    max-height: 100%;
    box-sizing: border-box;
    height: fit-content;
    margin: 20px 0;
  }

  .modal-list {
    h4 {
      font-family: ${({ theme }) => theme.fonts.nunito};
      font-weight: 500;
      color: ${({ theme }) => theme.colors.gray[500]};
      font-size: 16px;
    }

    ul {
      padding-left: 0;
      list-style: none;
      margin-top: 10px;
    }

    svg {
      width: 20px;
      height: 20px;
      flex: none;
      margin-right: 10px;
    }

    li {
      font-family: ${({ theme }) => theme.fonts.lexend};
      font-weight: 400;
      color: ${({ theme }) => theme.colors.gray[700]};
      font-size: 20px;
      padding-bottom: 10px;

      display: flex;
      align-items: flex-start;
    }

    li.features {
      font-family: ${({ theme }) => theme.fonts.nunito};
      font-size: 15px;
      line-height: 22px;

      i {
        margin-left: 4px;
      }
    }
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .info-bubble {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      background-color: #dde2e5;
      border-radius: 10px;
      padding: 15px;
      margin-bottom: 20px;

      svg {
        width: 22px;
        height: 22px;
        flex: none;
        margin-right: 12px;
      }

      p {
        font-family: ${({ theme }) => theme.fonts.nunito};
        color: ${({ theme }) => theme.colors.gray[700]};
        font-size: 16px;
        line-height: 24px;
      }
    }

    .btn {
      &:not(:first-child) {
        margin-left: 1rem;
      }
    }
  }

  .media-column {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.gray[200]};

    .media-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 3rem 2rem 1rem 2rem;

      .logo {
        width: 184px;
        height: auto;
      }
    }

    .video-wrap {
      display: flex;
      align-items: center;
      padding: 15px;
      height: 100%;

      & > div {
        border-radius: 10px;
      }
    }
  }

  .mobile-close-button {
    display: flex;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    display: grid;
    grid-template-columns: 50% 50%;

    .modal-content {
      margin: 30px 0;
    }

    .media-column {
      display: flex;
    }

    .mobile-close-button {
      display: none;
    }

    .modal-footer {
      align-items: flex-end;
    }
  }
`

export default StyledPlatformPreviewModal
