import { XIcon } from '@heroicons/react/outline'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import React, { FC, useState } from 'react'
import { StyledConnectionsSelectItem } from '.'
import { SocialProfileFindMany_socialProfileFindMany } from '../../../../graphql/SocialProfile/__generated__/SocialProfileFindMany'
import { IconFacebookSolid, IconInstagramOutline } from '../../../Icon/Brands'
import { useSocialConnection } from '../../../utils'
import ROUTES from '../../../utils/routes'
import { Avatar } from '../../Avatar'
import { DeletionConfirmation } from '../../DeletionConfirmation'
import { Toggle } from '../../Toggle'

type Props = {
  profile: Omit<SocialProfileFindMany_socialProfileFindMany, '__typename' | '_id'> & {
    isBusiness?: boolean | null;
    _id?: string | null;
  };
  isSelected?: boolean;
  isDisabled?: boolean;
  canDelete?: boolean;
  onChange?: (selected: boolean) => void;
  onReconnect?: (type: 'instagram' | 'facebook') => void;
  [x: string]: any;
}

const ConnectionsSelectItem: FC<Props> = ({
  profile,
  isSelected,
  isDisabled,
  canDelete,
  onChange,
  onReconnect,
  ...rest
}) => {
  const { actions: { promptDisconnectSocialAccount } } = useSocialConnection()
  const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false)
  const isBusiness = profile.type === 'facebook' || !!profile.isBusiness || !!profile._id

  return (
    <>
      <StyledConnectionsSelectItem
        isError={profile.status !== 'active'}
        isDisabled={isDisabled}
        {...rest}
      >
        <div className="connections-select-details-container">
          <div className="connections-select-avatar">
            <Avatar name={profile.username} imageUrl={profile.avatarUrl || ''} />
            {(profile.status !== 'active' || !isBusiness) && (
              <div className="error-icon">
                <ExclamationCircleIcon />
              </div>
            )}
          </div>
          <div className="connections-select-details">
            <span className="connections-select-detail-name">{profile.username}</span>
            <div className="connections-select-detail-social">
              {!isBusiness && profile.status === 'active' && (
                <a
                  className="connections-select-error"
                  href={ROUTES.external.switchToBusinessAccountHelpArticle}
                  target="_blank"
                  rel="noreferrer"
                >
                  Must be a business account
                </a>
              )}
              {isBusiness && profile.status === 'active' && (
                <>
                  {profile.type === 'instagram' && <IconInstagramOutline size={16} />}
                  {profile.type === 'facebook' && <IconFacebookSolid size={16} />}
                  <span>{profile.type}</span>
                </>
              )}
              {profile.status === 'expired' && (
                <span className="connections-select-error">Expired Token</span>
              )}
            </div>
          </div>
        </div>

        <div className="connections-select-actions">
          {onChange && profile.status === 'active' && (
            <Toggle
              name={profile.profileId || ''}
              checked={isSelected}
              disabled={isDisabled || profile.status !== 'active'}
              onChange={(e) => onChange(e.target.checked)}
            />
          )}
          {onReconnect && profile.status === 'expired' && (
            <button className="expired-btn" onClick={() => onReconnect(profile.type as any)}>
              Reconnect
            </button>
          )}
          {canDelete && (
            <button className="delete-btn" onClick={() => setShowDeletionConfirmation(true)}>
              {profile.status === 'active' ? 'Disconnect' : <XIcon />}
            </button>
          )}
        </div>
      </StyledConnectionsSelectItem>
      <DeletionConfirmation
        isOpen={showDeletionConfirmation}
        itemToDelete="social account"
        onConfirm={async () => await promptDisconnectSocialAccount(profile as any)}
        hideDeletionConfirmation={() => setShowDeletionConfirmation(false)}
      />
    </>
  )
}

export default ConnectionsSelectItem
