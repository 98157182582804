import React, { FC, useState, useEffect, createRef } from 'react'
import { DocumentTextIcon, PlayIcon } from '@heroicons/react/outline'
import { Button } from '../System'
import { FlexGroup } from '../Grid'
import { ToolTipWrapper } from '../ToolTip'
import { StyledIssueGrid, IssueAbout, IssueVideo, IssueHighlights } from '.'
import { GetIssueBySlug_issueFindOne } from '../../graphql/Issue/__generated__/GetIssueBySlug'
import useSegment from '../utils/useSegment'

type Props = {
  issue: GetIssueBySlug_issueFindOne;
}

const IssueGrid: FC<Props> = ({ issue }) => {
  const { track } = useSegment()

  const [videoUrl, setVideoUrl] = useState(issue.actionPlanVideo)
  const [aboutHeight, setAboutHeight] = useState(400)
  const [isRevealed, setRevealed] = useState(false)
  const [textHeight, setTextHeight] = useState<number>()

  const aboutRef = createRef<HTMLDivElement>()
  const descriptionRef = createRef<HTMLDivElement>()

  // Reveals / un-reveals the issue description text
  const revealAbout = () => {
    setAboutHeight(isRevealed ? aboutHeight : 10000)
    setRevealed(!isRevealed)
  }

  // Switches the action plan video with a highlight video or vice versa
  const switchVideo = (videoUrl = '') => {
    setVideoUrl(videoUrl === '' ? issue.actionPlanVideo : videoUrl)
  }

  // Bottom two functions are to mitigate the CORB issue with cross loading from a different domain after page load
  useEffect(() => {
    const issueAboutContent = aboutRef.current
    const issueDescription = descriptionRef.current

    setTimeout(() => {
      const revealButtonHeight = 35
      const additionalSpacing = 65
      const issueAboutDescriptionHeight = issueDescription!.getBoundingClientRect().height
      const calcHeight =
        issueAboutContent!.getBoundingClientRect().height - revealButtonHeight + additionalSpacing

      setTextHeight(issueAboutDescriptionHeight)
      setAboutHeight(calcHeight)

      if (issueAboutDescriptionHeight < calcHeight) {
        setRevealed(true)
      }
    }, 1000)
  }, [])

  return (
    <StyledIssueGrid>
      <div className="issue-grid-item issue-about-container">
        <IssueAbout
          reference={descriptionRef}
          content={issue.about || ''}
          height={aboutHeight}
          isRevealed={isRevealed}
        />
        {(textHeight == undefined || textHeight > aboutHeight) && (
          <Button onClick={() => revealAbout()} theme="ghost" type="small">
            {isRevealed ? 'Read Less' : 'Read More'}
          </Button>
        )}
      </div>
      <div ref={aboutRef} className="issue-grid-item">
        {videoUrl && <IssueVideo videoUrl={videoUrl || ''} />}
        {issue.actionPlanPdf && (
          <div className="issue-action-bar">
            <Button
              theme="ghost"
              type="small"
              className="issue-action-bar-title-btn"
              onClick={() => switchVideo()}
            >
              <span className="issue-action-bar-title">Action Plan</span>
            </Button>
            <FlexGroup>
              {issue.actionPlanPdf && (
                <ToolTipWrapper label="PDF">
                  <Button
                    theme="ghost"
                    isIcon
                    onClick={() => {
                      track('Viewed Action Plan PDF')
                      window.open(issue.actionPlanPdf?.fileHttpLink || '')
                    }}
                  >
                    <DocumentTextIcon />
                  </Button>
                </ToolTipWrapper>
              )}
              <ToolTipWrapper label="Video">
                <Button theme="ghost" isIcon onClick={() => switchVideo()} className="last-child">
                  <PlayIcon />
                </Button>
              </ToolTipWrapper>
            </FlexGroup>
          </div>
        )}
        <IssueHighlights switchVideo={switchVideo} issue={issue} />
      </div>
    </StyledIssueGrid>
  )
}

export default IssueGrid
