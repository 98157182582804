import React, { FC, useMemo } from 'react'
import classNames from 'classnames'
import { PhotographIcon } from '@heroicons/react/outline'
import { linkToSizedImage } from '../../IssueEntityImage/helpers'
import { Checkbox } from '../../System'
import { StyledCollectionListItem } from '.'
import { SearchGalleryCollections_searchGalleryCollections_docs } from '../../../graphql/GalleryCollection/__generated__/SearchGalleryCollections'

type Props = {
  isSelected: boolean;
  entryId: string;
  collection: SearchGalleryCollections_searchGalleryCollections_docs;
  toggleCollectionFn: (val: string, isSelected: boolean) => void;
}

const CollectionListItem: FC<Props> = ({
  collection, entryId, isSelected, toggleCollectionFn
}) => {
  const handleSelectItem = (e: any) => {
    e.stopPropagation()
    toggleCollectionFn(collection._id, !isSelected)
  }

  const imageUrl = useMemo(() => {
    if (collection.defaultPreview?.fileHttpLink) {
      return linkToSizedImage(collection.defaultPreview?.fileHttpLink, { width: 150 })
    }
    return null
  }, [collection, entryId, isSelected])

  return (
    <StyledCollectionListItem
      className={classNames('collection-list-item', { isSelected: 'selected' })}
      onClick={handleSelectItem}
    >
      <div className="collection-details-container">
        <div className="collection-images">
          <div className="collection-image-fade" />
          <div className="main-image-container">
            {imageUrl && <img src={imageUrl} className="main-image" />}
            {!imageUrl && <PhotographIcon />}
          </div>
        </div>
        <div className="collection-details">
          <span className="collection-title">{collection.name}</span>
          <span className="collection-photo-count">
            {collection.countImages || 'No'} Photo{collection.countImages > 1 && 's'}
          </span>
        </div>
      </div>
      <div className="collection-checkbox">
        <Checkbox name={collection._id} isChecked={isSelected} />
      </div>
    </StyledCollectionListItem>
  )
}

export default CollectionListItem
