import React, { FC } from 'react'
import { ReactComponent as SpinnerLarge } from './spinnerLarge.svg'
import { ReactComponent as SpinnerSmall } from './spinnerSmall.svg'
import { StyledSpinner } from '.'

type Props = {
  size?: 'small' | 'large';
  theme?: 'light' | 'dark' | 'blend' | 'brand';
  isBlock?: boolean;
}

const Spinner: FC<Props> = ({ size = 'large', theme = 'blend', isBlock }) => {
  return (
    <StyledSpinner size={size} themeType={theme} isBlock={isBlock} className="spinner">
      {size === 'large' ? (
        <SpinnerLarge className="spinner-icon" />
      ) : (
        <SpinnerSmall className="spinner-icon" />
      )}
    </StyledSpinner>
  )
}

export default Spinner
