import styled from 'styled-components'

type StyledProps = {
  isClear: boolean;
}

const StyledDraftDrawerButton = styled.button<StyledProps>`
  width: 80px;
  height: 80px;
  position: fixed;
  right: 18px;
  bottom: 18px;
  cursor: pointer;
  border: none;
  display: grid;
  font-family: ${({ theme }) => theme.fonts.lexend};
  font-size: 12px;
  align-content: center;
  grid-gap: 3px;
  animation: qd-btn-pop-in 0.6s ease-in-out;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.background};
  background-color: ${({ theme }) => theme.colors.green[500]};
  z-index: 1050;
  box-shadow: inset 0 0 0 1px #0000000d, 0 2px 3px 0px #00000038;
  border-radius: 50%;

  svg {
    height: 34px;

    path {
      stroke-width: 1.5px;
    }
  }

  .qd-tooltip {
    position: absolute;
    bottom: calc(100% + 1rem);
    display: grid;
    grid-gap: 1rem;
    background-color: ${({ theme }) => theme.colors.gray[900]};
    color: ${({ theme }) => theme.colors.background};
    font-family: ${({ theme }) => theme.fonts.nunito};
    text-align: left;
    padding: 1rem 2rem 1rem 1rem;
    right: 0;
    width: 210px;
    border-radius: 3px;
    font-weight: 600;
    font-size: 1rem;
    cursor: auto;
    justify-items: start;

    a {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.background};
      font-family: ${({ theme }) => theme.fonts.nunito};
      text-decoration: underline;
      margin-left: auto;

      svg {
        height: 18px;
        margin-left: 6px;

        path {
          stroke-width: 2px;
        }
      }
    }

    .close-btn {
      position: absolute;
      color: ${({ theme }) => theme.colors.gray[500]};
      right: 0;
      top: 0;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      height: 10px;
      width: 10px;
      transform: rotate(45deg);
      background: ${({ theme }) => theme.colors.gray[900]};
      border-bottom-right-radius: 2px;
      right: calc(40px - 5px);
    }
  }

  @keyframes qd-btn-pop-in {
    0% {
      transform: scale(0);
    }
    70% {
      transform: scale(1.04);
    }
    100% {
      transform: scale(1);
    }
  }
`

export default StyledDraftDrawerButton
