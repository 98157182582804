import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import ROUTES from '../../utils/routes'
import { StyledGalleryPhotoSimilarItem } from '.'
import { linkToSizedImage } from '../../IssueEntityImage/helpers'
import { SearchGalleryQuery_searchGallery_docs } from '../../../graphql/GalleryImage/__generated__/SearchGalleryQuery'

type Props = {
  photo: SearchGalleryQuery_searchGallery_docs;
  onClick: () => void;
}

const GalleryPhotoSimilarItem: FC<Props> = ({ photo, onClick }) => {
  const [loading, setLoading] = useState(true)

  return (
    <StyledGalleryPhotoSimilarItem
      $loading={loading}
      onClick={() => onClick()}
      className="scroll-item"
    >
      <Link
        to={{
          pathname: ROUTES.gallery.buildPhotoPage(photo._id),
          state: { photo }
        }}
      >
        <img
          onLoad={() => setLoading(false)}
          src={linkToSizedImage(photo.file!.fileHttpLink, { width: 200 })}
          loading="lazy"
        />
      </Link>
    </StyledGalleryPhotoSimilarItem>
  )
}

export default GalleryPhotoSimilarItem
