import React, { FC, ReactNode } from 'react'
import { StyledSwitchOption } from '.'

export type SwitchOptionType = {
  label: string | ReactNode;
  value: string;
}

type Props = {
  option: SwitchOptionType;
  isActive: boolean;
  onClick: () => void;
}

const SwitchOption: FC<Props> = ({ option, isActive, onClick }) => {
  return (
    <StyledSwitchOption isActive={isActive} onClick={onClick}>
      {option.label}
    </StyledSwitchOption>
  )
}

export default SwitchOption
