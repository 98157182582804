import {
  PricingState,
  PricingActions,
  PRICING_ADD_ITEM,
  PricingType
} from './types'

const initialState: PricingState = []

export const pricingReducer = (state = initialState, action: PricingActions): PricingState => {
  switch (action.type) {
    case PRICING_ADD_ITEM:
      return state = action.payload
    default:
      return state
  }
}
