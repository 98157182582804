import React, { FC, ReactNode } from 'react'
import { StyledFormMessage } from '.'

type Props = {
  message: string | ReactNode;
  type: 'error' | 'warning' | 'success';
}

const FormMessage: FC<Props> = ({ message, type }) => {
  return (
    <StyledFormMessage className="form-message" type={type}>
      {message}
    </StyledFormMessage>
  )
}

export default FormMessage
