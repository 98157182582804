import React, { FC } from 'react'
import { DownloadIcon, XCircleIcon, XIcon } from '@heroicons/react/outline'
import useWindowHeight from '../../utils/useWindowHeight'
import AdminPopupMenu from '../../Admin/AdminComponents/AdminPopupMenu'
import { FlexGroup } from '../../Grid'
import { Button, Dialog } from '../../System'
import { ModalProps, StyledIssueModalContent } from '.'

const IssueModalContent: FC<ModalProps> = (props: ModalProps) => {
  const {
    title,
    subTitle,
    onClose,
    show,
    children,
    titleButtons,
    footerButtons,
    recordType,
    downloadUrl,
    extraType,
    modalType = 'user'
  } = props

  const windowHeight = useWindowHeight()

  return (
    <Dialog noOverflow={false} isOpen={show} onClose={() => onClose(null)}>
      <StyledIssueModalContent windowHeight={windowHeight} modalType={modalType}>
        <div className={`actions ${modalType}`}>
          {modalType === 'admin-page' ? (
            <div className="icons-actions">
              {titleButtons && <AdminPopupMenu iconSize={16}>{titleButtons}</AdminPopupMenu>}
              <XIcon onClick={(e) => onClose(e)} />
            </div>
          ) : (
            modalType !== 'issue' && <XCircleIcon onClick={(e) => onClose(e)} />
          )}
        </div>
        <div className={`title-block ${modalType}`}>
          {modalType === 'issue' ? (
            <div className="issue-modal-heading">
              <div>
                <span className="issue-modal-sub-title">{subTitle}</span>
                <span className="issue-modal-title">{title}</span>
              </div>
              <FlexGroup>
                {downloadUrl && extraType !== 'file' && extraType !== 'video' && (
                  <Button
                    theme="ghost"
                    className="modal-set-download-button"
                    iconPos="right"
                    icon={<DownloadIcon />}
                    externalURL={downloadUrl}
                    ariaLabel="Download templates"
                  >
                    <span>
                      Download
                      {recordType === 'story' && ' templates'}
                      {recordType === 'photo' && ' photos'}
                    </span>
                  </Button>
                )}
                <Button
                  className="close-modal-button"
                  theme="ghost"
                  type="large"
                  isIcon
                  onClick={(e) => onClose(e)}
                >
                  <XIcon />
                </Button>
              </FlexGroup>
            </div>
          ) : (
            <>
              <h4 className={modalType}>{title}</h4>
              {subTitle && <h3>{subTitle}</h3>}
            </>
          )}
        </div>
        <div className={`content ${modalType}`}>{children}</div>
        {footerButtons && <div className={`footer-actions ${modalType}`}>{footerButtons}</div>}
      </StyledIssueModalContent>
    </Dialog>
  )
}

export default IssueModalContent
