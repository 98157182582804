import React, { FC, useCallback, useState } from 'react'
import * as Sentry from '@sentry/react'
import { Link } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import ROUTES from '../../utils/routes'
import { DraggingDimensionsType } from '../../../types'
import { linkToSizedImage } from '../../IssueEntityImage/helpers'
import { Button, DeletionConfirmation } from '../../System'
import { StyledGalleryCollectionItem, GalleryCollectionItemPreview } from '.'
import { SearchGalleryCollections_searchGalleryCollections_docs } from '../../../graphql/GalleryCollection/__generated__/SearchGalleryCollections'
import { useGalleryFeed } from '../GalleryFeed'
import useSegment from '../../utils/useSegment'
import { galleryCollectionRemoveByIdMutation } from '../../../graphql/GalleryCollection/galleryCollectionRemoveById'
import { updateSortPosition } from '../../utils/sortingHelpers'
import { reorderGalleryCollectionMutation } from '../../../graphql/GalleryCollection/reorderCollection'

type Props = {
  collection: SearchGalleryCollections_searchGalleryCollections_docs;
  dragging: DraggingDimensionsType | null;
}

const GalleryCollectionItem: FC<Props> = ({ collection, dragging }) => {
  const apollo = useApolloClient()
  const {
    collections,
    editMode,
    actions: { addCollections }
  } = useGalleryFeed()
  const { track } = useSegment()
  const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false)

  const hideCollection = () => {
    addCollections(collections.filter((col) => col._id !== collection._id))
  }

  const deleteCollection = async () => {
    try {
      const collectionId = collection._id

      hideCollection()
      track('Deleted Collection')

      await apollo.mutate({
        mutation: galleryCollectionRemoveByIdMutation,
        variables: { _id: collectionId }
      })
      updateSortPosition(
        { newIndex: null, oldIndex: collection.index },
        collections,
        reorderGalleryCollectionMutation
      )
    }
    catch (err) {
      Sentry.captureException(err)
      console.error(err)
    }
  }

  const preventLinkClick = useCallback(
    (event) => {
      if (editMode) {
        event.preventDefault()
      }
    },
    [editMode]
  )

  return (
    <>
      <DeletionConfirmation
        isOpen={showDeletionConfirmation}
        itemToDelete="collection"
        onConfirm={() => deleteCollection()}
        hideDeletionConfirmation={() => setShowDeletionConfirmation(false)}
      />
      <Link
        to={ROUTES.gallery.buildCollectionPage(collection._id)}
        onClick={(e) => preventLinkClick(e)}
      >
        <StyledGalleryCollectionItem dragging={dragging}>
          <div className="collection-preview">
            <GalleryCollectionItemPreview
              editMode={editMode}
              collectionId={collection._id}
              preview={linkToSizedImage(collection.defaultPreview?.fileHttpLink, { width: 400 })}
            />
            {!editMode && (
              <Button
                onClick={(e) => {
                  e.preventDefault()
                  setShowDeletionConfirmation(true)
                }}
                className="delete-btn"
                type="small"
              >
                Delete
              </Button>
            )}
          </div>
          <div className="collection-details">
            <span className="collection-name">{collection.name}</span>
            <span className="collection-photo-count">
              {collection.countImages} Photo{collection.countImages !== 1 && 's'}
            </span>
          </div>
          {editMode && <div className="dragging-block" />}
        </StyledGalleryCollectionItem>
      </Link>
    </>
  )
}

export default GalleryCollectionItem
