import React, { FC, useEffect, useState } from 'react'
import { linkToSizedImage, SizeData } from '../IssueEntityImage/helpers'
import { Spinner } from '../System'
import { StyledIssueImage } from '.'

type Props = {
  url: string | undefined | null;
  title?: string;
  size: SizeData;
}

const IssueImage: FC<Props> = ({ url, title, size }) => {
  const [image, setImage] = useState('')
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    setImage(linkToSizedImage(url, size))
  }, [url])

  return (
    <StyledIssueImage isLoading={isLoading}>
      {isLoading && <Spinner />}
      <img src={image} alt={title} onLoad={() => setLoading(false)} />
    </StyledIssueImage>
  )
}

export default IssueImage
