import { gql } from '@apollo/client'

export const getUserChallengesQuery = gql`
  query GetUserChallenges {
    getUserChallenges(currentlyOngoing: true) {
      _id
      name
      description
      start
      end
      joinBy
      learnMore
      redirect
      initialDrafts
      extraDrafts
      userChallenge {
        _id
        user
        challenge
        grantedExtraDrafts
        completedExtraDrafts
        completed
        updatedAt
        joined
      }
    }
  }
`
