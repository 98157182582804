import React, { FC, useEffect, useState } from 'react'
import { random } from 'lodash'
import { Button } from '..'
import { StyledBulletList, ListItem, BulletType } from '.'
import { handleSlug } from '../../utils/handleSlug'

type Props = {
  value: string[] | BulletType[];
  isIcon?: boolean;
  onChange: (value: string[] | BulletType[]) => void;
}

const BulletList: FC<Props> = ({ value, isIcon, onChange }) => {
  const hasIcons = isIcon || (value[0] && (value[0] as any).label)
  const [bullets, setBullets] = useState<{ label: string; icon?: string; id: string }[]>([])

  const loadBullets = () => {
    if (hasIcons) {
      return setBullets(
        (value as BulletType[]).map((bullet) => {
          return {
            ...bullet,
            id: `${handleSlug(bullet.label)}-${random(0, 999)}`
          }
        })
      )
    }

    return setBullets(
      (value as string[]).map((bullet) => {
        return {
          label: bullet,
          id: `${handleSlug(bullet)}-${random(0, 999)}`
        }
      })
    )
  }

  const addBullet = () => {
    setBullets(
      bullets?.concat([
        {
          label: 'Untitled Bullet',
          icon: '',
          id: `untitled-bullet-${random(0, 999)}`
        }
      ])
    )
  }

  const changeBullet = (id: string, value: string, icon?: string) => {
    const updatedBullets = bullets?.map((bullet) => {
      if (bullet.id !== id) {
        return bullet
      }

      return {
        label: value,
        icon: icon || bullet.icon,
        id: `${handleSlug(value)}-${random(0, 999)}`
      }
    })

    setBullets(updatedBullets)
  }

  const deleteBullet = (id: string) => {
    setBullets(bullets!.filter((bullet) => bullet.id !== id))
  }

  useEffect(() => {
    if (!bullets.length) {
      loadBullets()
    }
  }, [value])

  useEffect(() => {
    if (hasIcons) {
      const formattedBullets = bullets.map((bullet) => ({ label: bullet.label, icon: bullet.icon }))
      return onChange(formattedBullets as BulletType[])
    }

    return onChange(bullets?.map((bullet) => bullet.label) || [])
  }, [JSON.stringify(bullets)])

  return (
    <StyledBulletList>
      {bullets &&
        bullets.map((item, index) => {
          return (
            <ListItem
              content={item.label}
              icon={item.icon || hasIcons}
              onChange={(value, icon) => changeBullet(item.id, value, icon)}
              onDelete={() => deleteBullet(item.id)}
              key={index}
            />
          )
        })}
      {bullets?.length < 6 && (
        <Button className="add-btn" theme="outline" onClick={() => addBullet()}>
          Add Bullet
        </Button>
      )}
    </StyledBulletList>
  )
}

export default BulletList
