import React, { FC, useEffect } from 'react'
import { PlusIcon } from '@heroicons/react/outline'
import { useHistory } from 'react-router-dom'
import useDraft from '../../../redux/draft-drawer/useDraft'
import RenderSlate from '../../Slate/RenderSlate'
import { serializePlainText } from '../../Slate/slate'
import { IssueModalGallery, StyledIssueModalItem } from '.'
import useSegment from '../../utils/useSegment'
import { CaptionSetFindMany_captionSetFindMany } from '../../../graphql/CaptionSet/__generated__/CaptionSetFindMany'
import { GetIssueBySlug_issueFindOne } from '../../../graphql/Issue/__generated__/GetIssueBySlug'
import ROUTES from '../../utils/routes'

type Props = {
  issue: GetIssueBySlug_issueFindOne;
  photo: CaptionSetFindMany_captionSetFindMany;
  setFullScreen: (isFullScreen: boolean) => void;
  isFullScreen: boolean;
}

const IssueModalPhoto: FC<Props> = ({
  issue, photo, setFullScreen, isFullScreen
}) => {
  const { actions: { setDraftCaption, setDraftCategory } } = useDraft()
  const { track } = useSegment()
  const history = useHistory()

  const handleAddToQuickDraft = () => {
    setDraftCaption(serializePlainText({ children: JSON.parse(photo.caption) }))
    setDraftCategory(photo.category as any)
    track('Added Caption to Quick Draft', {
      caption: photo.caption,
      category: photo.category,
      issue_id: issue._id,
      issue_title: issue.title,
      from: 'issue'
    })
    history.push({ pathname: ROUTES.issues.viewBySlug(issue!.slug) })
  }

  useEffect(() => {
    track('Viewed Photo', {
      id: photo._id,
      title: photo.title,
      url: photo.thumbnail,
      issue_id: issue._id,
      issue_title: issue.title,
      from: 'issue'
    })
  }, [])

  return (
    <StyledIssueModalItem itemType="photo">
      <div className="issue-modal-grid">
        <div className="issue-modal-grid-item">
          <IssueModalGallery
            setFullScreen={setFullScreen}
            isFullScreen={isFullScreen}
            modalType="photo"
            issue={issue}
            data={photo}
            canvaLink={photo.canvaLink}
          />
        </div>
        <div className="issue-modal-grid-item">
          <div className="grid-box">
            <div className="grid-box-header">
              <span className="grid-box-header-title">Related Caption</span>
              <button className="use-btn" onClick={() => handleAddToQuickDraft()}>
                <PlusIcon />
                <span>USE</span>
              </button>
            </div>
            <div className="grid-box-content">
              <RenderSlate html={photo.caption} />
            </div>
          </div>
          <div className="grid-box">
            <div className="grid-box-header">
              <span className="grid-box-header-title">Visual Ideas</span>
            </div>
            <div className="grid-box-content">
              <RenderSlate html={photo.photoIdeas} />
            </div>
          </div>
        </div>
      </div>
    </StyledIssueModalItem>
  )
}

export default IssueModalPhoto
