import styled from 'styled-components'
import React from 'react'
import classNames from 'classnames'
import { useRouteMatch } from 'react-router-dom'
import ROUTES from '../utils/routes'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const ContainerStyled = styled.div`
  margin-top: 20px;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 200px;
  &.full-width {
    margin-bottom: 100px;
  }
  @media (max-width: ${({ theme }) => theme.size.sm}px) {
    width: 100%;
    margin-top: 0;
  }
  @media (min-width: ${({ theme }) => theme.size.sm}px) {
    width: ${({ theme }) => `${theme.size.md - 80}px`};
    &.full-width {
      width: 96%;
    }
  }
`

const Container = ({ children }: { children: React.ReactNode }) => {
  const fullWidth = Boolean(useRouteMatch(ROUTES.gallery.main))

  return (
    <Wrapper>
      <ContainerStyled className={classNames({ 'full-width': fullWidth })}>
        {children}
      </ContainerStyled>
    </Wrapper>
  )
}

export default Container
