import styled from 'styled-components'

const StyledAdminTable = styled.div`
  position: relative;
  display: block;
  max-width: 100%;

  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  .loading-spinner {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${({ theme }) => theme.zIndex.fixed};
    background: rgba(255, 255, 255, 0.6);
  }

  table {
    width: 100%;
    background: ${({ theme }) => theme.colors.background};
    border-spacing: 0;
   
    th, td {
      font-size: 14px;
      margin: 0;
      padding: 8px 0;
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray[300]};
      width: 1%;
      padding-right: 2rem;
      font-weight: 500;
      line-height: 20px;

      &.collapse {
        width: 0.0000000001%;
      }
      &:last-child {
        border-right: 0;
      }
    }
    tr {
     &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    thead {
      th {
        font-weight: 600;
        font-size: 15px;
        color: ${({ theme }) => theme.colors.gray[500]};
        text-align: left;
      }
    }
  }
  
  .footer-table {
    margin: 12px 0;
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas: 'per-page count-data paginator-block';
    grid-gap: 12px;

    .per-page {
      grid-area: per-page;
      align-self: center;
      min-width: 230px;
    }

    .count-data {
      grid-area: count-data;
      color: ${({ theme }) => theme.colors.gray[600]};
      align-self: center;
      justify-self: end;
    }

    .paginator-block {
      grid-area: paginator-block;
      justify-self: end;
      align-self: center;
    }

    @media (max-width: ${({ theme }) => `${theme.size.sm}px`}) {
      grid-template-columns: auto;
      grid-template-areas: 'per-page' 'paginator-block' 'count-data';
      .count-data, .per-page, .paginator-block {
        justify-self: center;
      }
    }
  }
  
  
  .pagination {

    ul, li {
      display: inline-block;
    }

    li {
      cursor: pointer;
      display: inline-block;
      padding: 0;
      border: 1px solid ${({ theme }) => theme.colors.gray[300]};
      width: 34px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      height: 34px;

      &:not(:last-child) {
        margin-right: 10px;
      }

      &.active {
        border: 1px solid ${({ theme }) => theme.colors.gray[200]};
        background: ${({ theme }) => theme.colors.gray[200]};
      }

      &.previous, &.next {
        border: none;
        color: ${({ theme }) => theme.colors.gray[500]};
      }

      &.disabled {
        cursor: default;
        opacity: .5;
      }

      a {
        display: inline-block;
        padding: 8px 10px;
      }
    }
  }

  .tr-body {
    .act-div-button {
      margin-left: 20px;
      visibility: hidden;

      .btn {
        border-radius: 50%;
      }
    }

    &:hover {
      background: ${({ theme }) => theme.colors.gray[50]};
      .act-div-button {
        visibility: visible!important;
      }
    }
  }

  .loading-td {
    text-align: center;
    color: ${({ theme }) => theme.colors.gray[400]};
    padding: 90px 0;
  }

  ul {
    -webkit-padding-start: 0;
    -moz-padding-start: 0;
  }
`

export default StyledAdminTable
