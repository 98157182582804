import React, { FC, useContext, useState, useEffect } from 'react'
import { StyledTagManager } from '.'
import useDisableScroll from '../Modal/useDisableScroll'
import tagManager from './tagManager.png'
import { userStore } from '../utils/UserContext'
import { SplitContext } from '../utils/SplitContext'

const TagManager: FC = () => {
  const { user } = useContext(userStore)
  const refBlock = useDisableScroll(true)
  const {
    isReady,
    isEnabled,
    treatments: { gtmTag }
  } = useContext(SplitContext)

  const [manualHide, setManualHide] = useState(false)

  const show = isReady && isEnabled(gtmTag)

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'F' && e.shiftKey && (e.metaKey || e.ctrlKey)) {
        setManualHide((prev) => !prev)
      }
    })
  }, [])

  if (user?.role !== 'admin' || !show || manualHide) {
    return null
  }

  return (
    <StyledTagManager ref={refBlock}>
      <div>
        <img src={tagManager} />
        <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">
          &gt;&gt;Click here to learn more about the legal case&lt;&lt;
        </a>
      </div>
    </StyledTagManager>
  )
}

export default TagManager
