import React, { createContext, useState } from 'react'
import { TooltipType, ToolTip } from '.'

const initialState: TooltipType & {
  setTooltip: (param: TooltipType) => void;
  hideTooltip: () => void;
} = {
  tooltip: {
    width: 0,
    height: 0,
    pos: 'top',
    offset: 10,
    label: ''
  },
  child: {
    width: 0,
    height: 0,
    left: 0,
    top: 0
  },
  isShown: false,
  setTooltip: () => {},
  hideTooltip: () => {}
}

const tooltipStore = createContext(initialState)
const { Provider } = tooltipStore

// Handles when and where to show the tooltip
const TooltipProvider = ({ children }) => {
  const [isShown, setIsShown] = useState(false)
  const [tooltip, setTooltipData] = useState<TooltipType>(initialState)

  // Sets the tooltips data
  const setTooltip = (payload: TooltipType) => {
    setTooltipData({
      tooltip: {
        width: payload.tooltip.width,
        height: payload.tooltip.height,
        pos: payload.tooltip.pos,
        offset: payload.tooltip.offset,
        label: payload.tooltip.label
      },
      child: {
        width: payload.child.width,
        height: payload.child.height,
        left: payload.child.left,
        top: payload.child.top
      }
    })
    setIsShown(true)
  }

  // Hides the tooltip
  const hideTooltip = () => {
    setIsShown(false)
  }

  return (
    <Provider
      value={{
        tooltip: tooltip.tooltip,
        child: tooltip.child,
        isShown,
        setTooltip,
        hideTooltip
      }}
    >
      {children}
      <ToolTip />
    </Provider>
  )
}

export { tooltipStore, TooltipProvider, Provider }
