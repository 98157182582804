import React, { FC, ReactNode } from 'react'
import { StyledAdminPageForm } from '.'

type Props = {
  children?: ReactNode;
}

const AdminPageForm: FC<Props> = ({ children }) => {
  return <StyledAdminPageForm>{children}</StyledAdminPageForm>
}

export default AdminPageForm
