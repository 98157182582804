import styled from 'styled-components'

type StyledProps = {
  isActive?: boolean;
  to?: string;
  exact?: boolean;
  activeClassName?: string;
}

const StyledTab = styled.button<StyledProps>`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-size: 17px;
  color: ${({ theme, isActive }) => isActive ? theme.colors.gray[900] : theme.colors.gray[400]};
  cursor: pointer;
  border: none;
  background: transparent;
  font-weight: ${({ isActive }) => isActive ? '700' : '500'};
  position: relative;
  padding-bottom: 11px;
  border-radius: 3px;
  margin-right: 8px;
  transition: background 0.2s, color 0.2s;
  -webkit-appearance: none !important;

  &:hover {
    &::after {
      background: ${({ theme, isActive }) => isActive ? theme.colors.gray[900] : theme.colors.gray[300]};
    }
  }

  &::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    background: ${({ theme, isActive }) => isActive ? theme.colors.gray[900] : 'transparent'};
    border-radius: 50rem;
    content: "";
    transition: 0.2s;
  }

  &.active {
    color: ${({ theme }) => theme.colors.gray[900]};
    font-weight: 700;

    &::after {
      background: ${({ theme }) => theme.colors.gray[900]};
    }
  }

  svg {
    height: 20px;
    margin-left: 10px;
  }

  .tab-icon {
    height: 22px;
    width: 22px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin: 0;
      height: 22px;
    }
  }
`

export default StyledTab
