import styled from 'styled-components'

const StyledDashboard = styled.div`
  padding: 0 0 45px 0;

  .dashboard-header-container {
    min-height: 175px;
    padding: 2rem 0 calc(38px + 2rem) 0;
    display: grid;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.gray[100]};

    .dashboard-header {
      display: flex;
      justify-content: space-between;
      align-items: end;

      h1,
      h2 {
        margin: 0;
      }

      h2 {
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 0.06rem;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.gray[500]};
      }

      h1 {
        font-size: 30px;
        font-weight: 600;
        margin-top: 6px;
        margin-bottom: -3px;
        letter-spacing: -0.03rem;
        color: ${({ theme }) => theme.colors.gray[800]};
      }

      .new-post-btn {
        display: none;
        align-items: center;
        border: 0;
        flex-shrink: 0;
        padding: 0 1rem;
        border-radius: 6px;
        height: 45px;
        font-weight: 600;
        transition: 0.2s;
        color: ${({ theme }) => theme.colors.gray[600]};
        background-color: ${({ theme }) => theme.colors.gray[200]};

        span {
          position: relative;
          top: 1px;
        }

        svg {
          height: 20px;
          width: 20px;
          margin-left: 10px;
        }

        &:hover {
          cursor: pointer;
          background-color: ${({ theme }) => theme.colors.gray[300]}50;
        }
      }
    }
  }

  .dashboard-group-item {
    &:first-child {
      margin-top: 45px;
    }

    .dashboard-group-item-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 15px 0;

      h2 {
        font-weight: 600;
        font-size: 17px;
        text-transform: none;
      }

      a {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.01rem;
        text-transform: capitalize;
        color: ${({ theme }) => theme.colors.green[500]};
        text-decoration: none;
        margin-left: auto;

        &:hover {
          color: ${({ theme }) => theme.colors.red[600]};
        }
      }
    }
  }

  .dashboard-group-grid {
    hr {
      display: flex;
    }

    .dashboard-group-item {
      margin: 0;
    }
  }

  hr {
    margin: 45px 0;
    height: 1px;
    border: 0;
    background: ${({ theme }) => theme.colors.gray[200]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xs}px) {
    .dashboard-header-container {
      .dashboard-header {
        .new-post-btn {
          display: flex;
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    .dashboard-group-grid {
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
      display: grid;

      hr {
        display: none;
      }
    }

    .width-controller {
      padding: 0 45px;
    }
  }
`

export default StyledDashboard
