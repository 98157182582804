import React, { FC } from 'react'
import { CheckIcon } from '@heroicons/react/outline'
import { StyledShareNowSlide } from '.'
import useDraft from '../../../../../redux/draft-drawer/useDraft'
import { SocialProfileFindMany_socialProfileFindMany } from '../../../../../graphql/SocialProfile/__generated__/SocialProfileFindMany'

type Props = {
  selectedProfiles?: SocialProfileFindMany_socialProfileFindMany[];
  isPosted?: boolean;
}

const ShareNowSlide: FC<Props> = ({ selectedProfiles, isPosted }) => {
  const { title } = useDraft()

  const getProfileListString = () => {
    if (selectedProfiles?.length === 1) {
      return <mark>{selectedProfiles[0].username}</mark>
    }

    return (
      <>
        {selectedProfiles?.map((profile, index) => {
          if (index === selectedProfiles.length - 1) {
            return (
              <>
                and <mark>{profile.username}</mark>
              </>
            )
          }

          if (index === selectedProfiles.length - 2) {
            return (
              <>
                <mark>{profile.username}</mark>{' '}
              </>
            )
          }

          return (
            <>
              <mark>{profile.username}</mark>,{' '}
            </>
          )
        })}
      </>
    )
  }

  return (
    <StyledShareNowSlide className="share-now">
      <div className="share-now-prompt">
        <div className="share-now-icon">
          <span className="icon">🚀</span>
          {isPosted && <CheckIcon className="check-icon" />}
        </div>
        <span>
          Are you sure you want to post <mark>{title || 'this draft'}</mark> to{' '}
          {getProfileListString()} now?
        </span>
      </div>
    </StyledShareNowSlide>
  )
}

export default ShareNowSlide
