import { useState, useEffect, useRef } from 'react'

const useDebounce = (value, timeout) => {
  const [state, setState] = useState(value)
  const timerRef = useRef<any>()

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(() => setState(value), timeout)
    return () => clearTimeout(timerRef.current)
  }, [value, timeout])

  return state
}

export default useDebounce
