export const getImageDimensions = async (imageUrl: string) => {
  const image: HTMLImageElement = await new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = reject
    img.src = imageUrl
  })

  return {
    x: image.width,
    y: image.height
  }
}
