import React, { FC, useState } from 'react'
import { MuseVideoType } from '../../../types'
import { Button, LineInput, Spinner } from '..'
import { StyledMuseTextInput } from '.'
import moment from 'moment'
import { TrashIcon } from '@heroicons/react/outline'

type Props = {
  value: MuseVideoType;
  onChange: (videoData: MuseVideoType) => void;
}

const MuseTextInput: FC<Props> = ({ value, onChange }) => {
  const [videoId, setVideoId] = useState('')
  const [loading, setLoading] = useState(false)
  const apiKey = process.env.REACT_APP_MUSE_KEY!

  const updateVisibility = async (fid: string) => {
    const url = `https://muse.ai/api/files/set/${fid}`
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Key: apiKey
      },
      body: JSON.stringify({
        visibility: 'unlisted'
      })
    }

    await fetch(url, config)
  }

  const saveVideo = async () => {
    try {
      setLoading(true)

      if (!videoId) {
        return
      }
  
      const url = `https://muse.ai/api/files/videos/${videoId}`
      const config = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Key: apiKey
        }
      }
  
      const request = await fetch(url, config)
      const data = (await request.json()) as MuseVideoType
  
      if (!data) {
        return
      }
  
      if (data.visibility === 'hidden') {
        await updateVisibility(data.fid!)
      }
  
      onChange({
        fid: data.fid,
        svid: data.svid,
        duration: data.duration
      })
    }
    catch (err) {
      console.error(err)
    }
    finally {
      setLoading(false)
      setVideoId('')
    }
  }

  const removeVideo = () => {
    onChange({
      fid: null,
      svid: null,
      duration: 0
    })
  }

  return (
    <StyledMuseTextInput>
      {(!value || !value.fid) && !loading && (
        <div className="upload-interface">
          <LineInput value={videoId} type="text" placeholder="Muse video ID" onChange={(e) => setVideoId(e.target.value.replace('https://muse.ai/e/', '').trim())} />
          <Button theme="outline" isDisabled={!videoId} onClick={() => saveVideo()}>Add</Button>
        </div>
      )}
      {loading && <Spinner />}
      {value && value.fid && !loading && (
        <div className="file-interface">
          <div className="file-details">
            <span>
              <b>Muse.AI Video</b>
            </span>
            <span>
              <code>{value.svid}</code> • {moment.utc(value.duration! * 1000).format('HH:mm:ss')}
            </span>
          </div>
          <div className="upload-actions">
            <Button theme="outline" type="large" isIcon onClick={() => removeVideo()}>
              <TrashIcon />
            </Button>
          </div>
        </div>
      )}
    </StyledMuseTextInput>
  )
}

export default MuseTextInput
