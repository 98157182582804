import styled, { ThemeContext } from 'styled-components'

const StyledIssueStory = styled.div`
  .image {
    width: 100%;
    padding-top: 178%;
    position: relative;
    transition: 0.2s;

    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .btn {
      z-index: 1;
      position: absolute;
      bottom: 10px;
      right: 10px;
      margin: 0 !important;
      opacity: 0;
      transition: opacity 0.2s;
      background: #c1c1c187;
      color: ${({ theme }) => theme.colors.foreground};
      height: 35px;
      width: 35px;
      padding: 0;
      border-radius: 3px;
      margin: 0;
      border: transparent;
    }
  }

  .details {
    margin-top: 1rem;

    .story-title {
      display: block;
      margin-top: 4px;
      font-size: 15px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.gray[600]};
    }

    .story-index {
      font-weight: 600;
      display: block;
      font-size: 15px;
      color: ${({ theme }) => theme.colors.gray[800]};
    }
  }

  .story-size {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 1;
    background: rgba(0, 0, 0, 0.31);
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 11px;
    color: ${({ theme }) => theme.colors.background};
    padding: 0 5px;
    border-radius: 50rem;

    svg {
      height: 14px;
      margin-right: 4px;
      opacity: 0.7;
    }
  }

  :hover {
    cursor: pointer;

    .image {
      box-shadow: 0px 8px 16px 2px rgb(97 97 97 / 10%);
      transform: translateY(-5px);
    }

    .btn {
      opacity: 1 !important;
    }
  }
`
export default StyledIssueStory
