import styled from 'styled-components'

const StyledImageInput = styled.div`
  height: 75px;
  background: ${({ theme }) => theme.colors.gray[50]};
  border-radius: 3px;
  border: 1px dashed ${({ theme }) => theme.colors.gray[300]};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  text-align: center;
  position: relative;

  .upload-drop {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.gray[600]};

    &.no-file-yet {
      cursor: pointer;
    }
  }

  .upload-info-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .upload-container {
      display: flex;
      align-items: center;

      .upload-preview {
        position: relative;
        border-radius: 3px;
        overflow: hidden;
        margin-right: 10px;
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        background: ${({ theme }) => theme.colors.background};
        color: ${({ theme }) => theme.colors.green[300]};
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          height: 22px;
        }

        &::after {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 3px;
          box-shadow: inset 0 0 0 1px #00000026;
        }
    
        img {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    
      .upload-info {
        text-align: left;
        display: grid;
        grid-gap: 3px;
        
        .upload-message {
          font-weight: 600;
        }
        
        .upload-filename {
          font-size: 14px;
          color: ${({ theme }) => theme.colors.gray[500]};
          word-break: break-all;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          display: -webkit-box;
        }
      }
    }
        
    .upload-actions {
      display: flex;
      align-items: center;
      grid-gap: 8px;
      margin-left: 1rem;
    }
  }
`

export default StyledImageInput
