import { gql } from '@apollo/client'

export const galleryImagesLikeAndIncrementMutation = gql`
  mutation GalleryImagesLikeAndIncrement(
    $record: CreateOneGalleryImagesLikeInput!
    $imageId: String!
  ) {
    galleryImagesLikeCreateOne(record: $record) {
      record {
        _id
        index
        image
        user
        dateLike
      }
    }
    galleryImagePopularity(_id: $imageId, type: favorites)
  }
`
