import React, { FC } from 'react'
import { XIcon } from '@heroicons/react/outline'
import { IconPicker } from '../IconPicker'
import { Button, LineInput } from '..'
import { StyledListItem } from '.'

type Props = {
  content: string;
  icon?: string;
  onChange: (value: string, icon?: string) => void;
  onDelete: () => void;
}

const ListItem: FC<Props> = ({
  content, icon, onChange, onDelete
}) => {
  return (
    <StyledListItem>
      {icon && <IconPicker value={icon} onChange={(value: string) => onChange(content, value)} />}
      <LineInput value={content} onChange={(e) => onChange(e.target.value)} />
      <Button onClick={() => onDelete()} theme="outline" type="large" isIcon>
        <XIcon />
      </Button>
    </StyledListItem>
  )
}

export default ListItem
