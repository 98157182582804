import React, { FC } from 'react'
import moment from 'moment'
import { StarIcon } from '@heroicons/react/solid'
import { linkToSizedImage } from '../IssueEntityImage/helpers'
import { StyledCommunityItem, CommunityEventType } from '.'
import useSegment from '../utils/useSegment'

type Props = {
  event: CommunityEventType;
  isFeatured?: boolean;
}

const CommunityItem: FC<Props> = ({ event, isFeatured }) => {
  const { track } = useSegment()

  // Track when a community event is clicked
  const trackEventClick = () => {
    track('Clicked Community Event', {
      id: event.id,
      name: event.title,
      date: event.startTime.toDate()
    })
  }

  return (
    <StyledCommunityItem
      href={event.url}
      target="_blank"
      rel="noreferrer noopener"
      className="scroll-item"
      isFeatured={isFeatured}
      onClick={() => trackEventClick()}
    >
      {isFeatured && (
        <div className="event-featured">
          <StarIcon />
          <span>FEATURED</span>
        </div>
      )}
      <div className="event-thumbnail">
        {event.thumbnail && (
          <img
            src={linkToSizedImage(event.thumbnail.fileHttpLink, { width: 500 })}
            alt={event.title}
          />
        )}
      </div>
      <div className="event-date">
        <span className="event-day">{moment(event.startTime.toDate()).format('D')}</span>
        <span className="event-month">
          {moment(event.startTime.toDate())
            .format('MMM')
            .toUpperCase()}
        </span>
      </div>
      <div className="event-details">
        <span className="event-title">{event.title}</span>
        <span className="event-date-time">
          {moment(event.startTime.toDate()).format('ddd, MMM D [at] h:mma')}
        </span>
      </div>
    </StyledCommunityItem>
  )
}

export default CommunityItem
