import React, { FC, useContext, useEffect, useState } from 'react'
import { PlusIcon } from '@heroicons/react/outline'
import { StyledConnectionsModal, ConnectionsSelectModal } from '.'
import { filterSocialProfiles, useSocialConnection } from '../../utils'
import { userStore } from '../../utils/UserContext'
import { ConnectionsSelectItem } from '../../System/ConnectionsSelect'
import { Button, ResponsiveDrawer, Spinner } from '../../System'
import { IconFacebookSolid, IconInstagramOutline } from '../../Icon/Brands'

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
}

const ConnectionsModal: FC<Props> = ({ isOpen, onClose }) => {
  const {
    connectionType,
    loadingConnect,
    loadingLogin,
    availableProfiles,
    selectedProfiles,
    actions: {
      loginToSocial,
      toggleProfile,
      connectSocialAccounts,
      setAvailableProfiles,
      setSelectedProfiles
    }
  } = useSocialConnection()
  const { user } = useContext(userStore)
  const [socialProfiles, setSocialProfiles] = useState(filterSocialProfiles(user?.socialProfiles))
  const TOTAL_CONNECTION_LIMIT = 6

  useEffect(() => {
    setSocialProfiles(filterSocialProfiles(user?.socialProfiles))
  }, [user?.socialProfiles])

  useEffect(() => {
    setAvailableProfiles([])
    setSelectedProfiles([])
  }, [isOpen])

  const ConnectionsModalContent = (
    <StyledConnectionsModal inModal={isOpen !== undefined && !!onClose}>
      {!availableProfiles.length && !loadingLogin && (
        <div className="profile-selection main-list">
          {isOpen !== undefined && !!onClose && (
            <div className="profile-selection-header">
              <h1>Connected Accounts</h1>
              <span>
                {socialProfiles.length}/{TOTAL_CONNECTION_LIMIT}
              </span>
            </div>
          )}
          {!socialProfiles?.length && (
            <div className="profile-empty-state">
              <span>You have no connected social accounts</span>
            </div>
          )}
          {!!socialProfiles?.length && (
            <div className="profile-selection-list">
              {socialProfiles.map((profile) => (
                <ConnectionsSelectItem
                  profile={profile}
                  key={profile._id}
                  isDisabled={profile.status !== 'active'}
                  onReconnect={(type) => loginToSocial(type)}
                  canDelete
                />
              ))}
            </div>
          )}
          {isOpen !== undefined && !!onClose && (
            <>
              <div className="profile-selection-header">
                <h1>Add Accounts</h1>
              </div>
              <div className="add-connections-list">
                {socialProfiles.length >= TOTAL_CONNECTION_LIMIT && (
                  <div className="profile-empty-state">
                    <span>You have reached your connection limit</span>
                  </div>
                )}
                {socialProfiles.length < TOTAL_CONNECTION_LIMIT && (
                  <>
                    <Button
                      theme="ghost"
                      type="small"
                      className="add-connection-btn"
                      onClick={() => loginToSocial('facebook')}
                    >
                      <div className="add-connection-btn-details-container">
                        <IconFacebookSolid size={22} />
                        <div className="add-connection-btn-details">
                          <span className="add-connection-btn-detail-title">Facebook</span>
                          <span className="add-connection-btn-detail-subtitle">
                            Connect your business account
                          </span>
                        </div>
                      </div>
                      <PlusIcon className="plus-icon" />
                    </Button>
                    <Button
                      theme="ghost"
                      type="small"
                      className="add-connection-btn"
                      onClick={() => loginToSocial('instagram')}
                    >
                      <div className="add-connection-btn-details-container">
                        <IconInstagramOutline size={22} />
                        <div className="add-connection-btn-details">
                          <span className="add-connection-btn-detail-title">Instagram</span>
                          <span className="add-connection-btn-detail-subtitle">
                            Connect your business account
                          </span>
                        </div>
                      </div>
                      <PlusIcon className="plus-icon" />
                    </Button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      )}
      {loadingLogin && (
        <div className="loading-pane">
          <Spinner />
        </div>
      )}
    </StyledConnectionsModal>
  )

  return (
    <>
      {isOpen !== undefined && !!onClose && (
        <ResponsiveDrawer
          isOpen={(!availableProfiles.length || loadingLogin) && isOpen}
          onClose={() => onClose()}
        >
          {ConnectionsModalContent}
        </ResponsiveDrawer>
      )}
      {!isOpen && !onClose && ConnectionsModalContent}
      <ConnectionsSelectModal
        isOpen={!!availableProfiles.length && !loadingLogin}
        connectionType={connectionType}
        selectedProfiles={selectedProfiles}
        availableProfiles={availableProfiles}
        isLoading={loadingConnect}
        onToggle={(id) => toggleProfile(id)}
        onClose={() => {
          setSelectedProfiles([])
          setAvailableProfiles([])
        }}
        onConfirm={() => connectSocialAccounts()}
      />
    </>
  )
}

export default ConnectionsModal
