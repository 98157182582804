import { PhotographIcon } from '@heroicons/react/outline'
import React, { FC, useState } from 'react'
import { StyledRecentDraftListItem } from '.'
import useDraft from '../../../../redux/draft-drawer/useDraft'
import { Button } from '../../../System'
import { QuickDraftOverwriteModal } from '../../QuickDraftOverwriteModal'
import { SearchUserDrafts_searchUserDrafts_docs } from '../../../../graphql/UserDraft/__generated__/SearchUserDrafts'

type Props = {
  draft: SearchUserDrafts_searchUserDrafts_docs;
}

const RecentDraftListItem: FC<Props> = ({ draft }) => {
  const {
    isSaved,
    actions: { setDraft }
  } = useDraft()
  const [showSwitchModal, setShowSwitchModal] = useState(false)

  const handleSwitch = () => {
    if (isSaved) {
      switchDraft()
      return
    }
    setShowSwitchModal(true)
  }

  const switchDraft = () => {
    setDraft(draft._id)
  }

  return (
    <>
      <StyledRecentDraftListItem onClick={() => handleSwitch()}>
        <div className="recent-draft-list-item-details-container">
          <div className="recent-draft-list-item-img">
            {draft.imageUrl && <img src={draft.imageUrl} alt={draft.title || 'Untitled Draft'} />}
            {!draft.imageUrl && <PhotographIcon />}
          </div>
          <div className="recent-draft-list-item-details">
            <span className="recent-draft-list-item-title">{draft.title}</span>
            <span className="recent-draft-list-item-caption">
              {draft.captionText || <span className="empty">No Caption</span>}
            </span>
          </div>
        </div>
        <Button theme="ghost">OPEN</Button>
      </StyledRecentDraftListItem>
      <QuickDraftOverwriteModal
        isOpen={showSwitchModal}
        onClose={() => setShowSwitchModal(false)}
        onConfirm={() => switchDraft()}
      />
    </>
  )
}

export default RecentDraftListItem
