import React, { forwardRef, ReactNode } from 'react'
import classNames from 'classnames'
import { Spinner } from '..'
import { StyledButton } from '.'

type Props = {
  type?: 'large' | 'medium' | 'small';
  theme?: 'solid' | 'outline' | 'ghost';
  action?: 'submit' | 'button';
  children?: ReactNode;
  ariaLabel?: string;
  className?: string;
  externalURL?: string;
  icon?: ReactNode;
  iconPos?: 'left' | 'right';
  isLoading?: boolean;
  isDisabled?: boolean;
  isIcon?: boolean;
  onClick?: (e?: any) => void;
  [x: string]: any;
}

const Button = forwardRef<HTMLButtonElement, Props>((refProps, ref) => {
  const {
    type = 'medium',
    theme = 'solid',
    action = 'button',
    children,
    ariaLabel,
    className,
    externalURL,
    icon,
    iconPos,
    isLoading,
    isDisabled,
    isIcon,
    onClick,
    ...props
  } = refProps

  const handleClick = (e) => {
    if (onClick && !isDisabled) {
      onClick(e)
    }
  }

  return (
    <StyledButton
      type={action}
      styleType={type}
      styleTheme={theme}
      aria-label={ariaLabel}
      hasIcon={!!icon}
      iconPos={iconPos}
      isDisabled={isDisabled}
      isIcon={isIcon}
      as={externalURL ? 'a' : 'button'}
      href={externalURL || ''}
      className={classNames('btn', className)}
      onClick={(e) => handleClick(e)}
      ref={ref}
      {...props}
    >
      {icon && iconPos !== 'right' && !isLoading && <div className="btn-icon">{icon}</div>}
      {!isLoading && children}
      {icon && iconPos === 'right' && !isLoading && <div className="btn-icon">{icon}</div>}
      {isLoading && <Spinner size="small" theme={theme === 'solid' ? 'light' : 'dark'} />}
    </StyledButton>
  )
})

export default Button
