import React, { FC, createRef, useState } from 'react'
import moment from 'moment'
import { CalendarIcon } from '@heroicons/react/outline'
import useDraft from '../../../../../redux/draft-drawer/useDraft'
import { DateTimeInput } from '../../../../System'
import { StyledScheduleSlide } from '.'

type Props = {
  postDate?: Date;
  setPostDate: (date: Date) => void;
}

const ScheduleSlide: FC<Props> = ({ postDate, setPostDate }) => {
  const dateButtonRef = createRef<HTMLButtonElement>()
  const { actions: { clearDraftDate } } = useDraft()
  const [showDatePicker, setShowDatePicker] = useState(false)

  return (
    <>
      <StyledScheduleSlide className="schedule-date-picker">
        <h1>When Do You Want to Auto-Post?</h1>
        <div className="date-picker-container">
          <button
            onClick={() => setShowDatePicker(true)}
            ref={dateButtonRef}
            className="date-picker"
          >
            <CalendarIcon />
            <span>
              {postDate && moment(postDate).format('MMMM D [at] h:mmA')}
              {!postDate && 'Pick a Date & Time'}
            </span>
          </button>
        </div>
      </StyledScheduleSlide>
      {showDatePicker && (
        <DateTimeInput
          buttonRef={dateButtonRef}
          value={typeof postDate === 'string' ? new Date(postDate) : postDate}
          onChange={(date) => setPostDate(date)}
          onClear={() => clearDraftDate()}
          onClose={() => setShowDatePicker(false)}
        />
      )}
    </>
  )
}

export default ScheduleSlide
