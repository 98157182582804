/* eslint-disable @typescript-eslint/camelcase */
import { FC, useEffect, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import firebase from 'firebase/app'
import { userStore } from '../utils/UserContext'
import useSegment from '../utils/useSegment'
import { isInBlacklist } from '.'

declare global {
  interface Window {
    Intercom: any;
    intercomSettings: any;
    dataLayer: any;
    analytics: any;
    _refiner: any;
  }
}

const Intercom: FC = () => {
  const { pathname } = useLocation()
  const { user, loaded } = useContext(userStore)
  const { page } = useSegment()

  const [hmac, setHmac] = useState<string | undefined>()

  const generateUserVerification = firebase.functions().httpsCallable('generateUserVerification')

  const generateHmac = async () => {
    try {
      if (!hmac && user && user.stripeCustomerId) {
        const userCode = await generateUserVerification()
        setHmac(userCode.data)
      }
    }
    catch (err) {
      Sentry.captureException(err)
    }
  }

  useEffect(() => {
    if (!user || isInBlacklist(pathname)) {
      window.Intercom('shutdown')
      return
    }

    page()
  }, [loaded, pathname, user])

  useEffect(() => {
    if (!user || hmac) {
      return
    }

    generateHmac()
  }, [user])

  useEffect(() => {
    if (!user || !hmac) {
      return
    }

    window.Intercom('update', {
      name: user?.displayName,
      email: user?.email,
      user_id: user?.stripeCustomerId,
      user_hash: hmac,
      affiliate: !!user.becameAffiliate,
      FB_URL: user?.facebookURL,
      IG_URL: user?.instagramURL,
      trial: user?.trial,
      has_trialed: user?.hasTrialed,
      experience: user?.experience,
      product: user?.onboarding?.product,
      industry: user?.onboarding?.industry,
      custom_industry: user?.onboarding?.customIndustry,
      post_frequency: user?.onboarding?.postFrequency,
      customer_frequency: user?.onboarding?.customerFrequency,
      confidence_level: user?.onboarding?.confidenceLevel,
      primary_platform: user?.onboarding?.primaryPlatform,
      preferred_feature: user?.onboarding?.preferredFeature,
      whale_user: user?.isWhale,
      last_request_at: new Date().getTime() / 1000,
      user_created_at: user?.createdAt?.seconds,
      connected_profiles_amount: user?.socialProfiles?.length
    })
  }, [hmac, user])

  return null
}

export default Intercom
