import styled from 'styled-components'

const StyledIssueExtra = styled.div`
  .image {
    width: 100%;
    padding-top: 100%;
    position: relative;
    transition: 0.2s;

    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  :hover {
    cursor: pointer;

    .image {
      box-shadow: 0px 8px 16px 2px rgb(97 97 97 / 10%);
      transform: translateY(-5px);
    }
  }
`
export default StyledIssueExtra
