import React, { ReactNode } from 'react'
import classNames from 'classnames'
import { SortableContainer } from 'react-sortable-hoc'
import { StyledTable, SortableRow } from '.'

type Props = {
  headings: string[];
  rows: (string | ReactNode)[][];
  footer?: ReactNode | string;
  columnSpacing?: string;
  className?: string;
  draggable?: boolean;
  responsive?: boolean;
}

const Table = SortableContainer(
  ({
    headings, rows, footer, columnSpacing, draggable, responsive, className
  }: Props) => {
    return (
      <StyledTable
        className={classNames('table', className)}
        columnSpacing={columnSpacing}
        columnLength={headings.length}
        responsive={responsive}
      >
        <thead>
          <tr>
            {headings.map((heading, index) => {
              return <th key={index}>{heading}</th>
            })}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => {
            if (draggable) {
              return (
                <SortableRow
                  columnSpacing={columnSpacing}
                  columnLength={headings.length}
                  index={index}
                >
                  {row.map((rowData, index) => {
                    return <td key={index}>{rowData}</td>
                  })}
                </SortableRow>
              )
            }
            return (
              <tr key={index}>
                {row.map((rowData, index) => {
                  return <td key={index}>{rowData}</td>
                })}
              </tr>
            )
          })}
        </tbody>
        {footer && <tfoot>{footer}</tfoot>}
      </StyledTable>
    )
  }
)

export default Table
