import React, { FC, ReactNode } from 'react'
import { CheckIcon } from '@heroicons/react/outline'
import { StyledBubble } from '.'

type Props = {
  label?: string;
  children?: ReactNode;
  noMargin?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  emoji?: {
    label: string;
    symbol: string;
  } | null;
  [x: string]: any;
}

const Bubble: FC<Props> = ({
  label,
  children,
  noMargin,
  isActive,
  isDisabled,
  emoji,
  ...props
}) => {
  return (
    <StyledBubble noMargin={noMargin} isActive={isActive} disabled={isDisabled} {...props}>
      {isActive && (
        <div className="bubble-check">
          <CheckIcon />
        </div>
      )}
      <div className="bubble-content">
        {emoji && (
          <span
            className="emoji"
            role="img"
            aria-label={emoji.label || ''}
            aria-hidden={emoji.label ? 'false' : 'true'}
          >
            {emoji.symbol}
          </span>
        )}
        {label?.replace('_', ' ')}
        {children}
      </div>
    </StyledBubble>
  )
}

export default Bubble
