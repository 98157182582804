import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { PlusIcon } from '@heroicons/react/outline'
import ROUTES from '../../utils/routes'
import useDraft from '../../../redux/draft-drawer/useDraft'
import { linkToSizedImage, SizeData } from '../../IssueEntityImage/helpers'
import { GetIssueBySlug_issueFindOne } from '../../../graphql/Issue/__generated__/GetIssueBySlug'
import { GetIssueAssetsQuery_captionSetFindMany } from '../../../graphql/Issue/__generated__/GetIssueAssetsQuery'
import useSegment from '../../utils/useSegment'
import { StyledIssuePhoto } from '.'

type Props = {
  photo: GetIssueAssetsQuery_captionSetFindMany;
  issue: GetIssueBySlug_issueFindOne;
}

const IssuePhoto: FC<Props> = ({ photo, issue }) => {
  const THUMBNAIL_SIZE: SizeData = { height: 350 }
  const history = useHistory()
  const { track } = useSegment()
  const { actions: { setDraftImage } } = useDraft()

  const sendPhotoToDraft = (e: any) => {
    e.stopPropagation()
    setDraftImage(photo?.thumbnail || '', photo._id)
    track('Added Photo to Quick Draft', {
      id: photo._id,
      title: photo.title,
      url: photo.thumbnail,
      issue_id: issue._id,
      issue_title: issue.title,
      from: 'issue'
    })
  }

  return (
    <StyledIssuePhoto
      onClick={() => history.push(ROUTES.issues.buildRecordPage(photo._id, issue.slug, 'photo'))}
    >
      <div className="image">
        <button className="use-btn" onClick={(e) => sendPhotoToDraft(e)}>
          <PlusIcon />
          <span>USE</span>
        </button>
        <img
          src={linkToSizedImage(photo.thumbnail || '', THUMBNAIL_SIZE)}
          alt={photo.title || ''}
        />
      </div>
      <div className="details">
        <span className="photo-category">{photo.category}</span>
        <span className="photo-title">{photo.title}</span>
      </div>
    </StyledIssuePhoto>
  )
}

export default IssuePhoto
