import { gql } from '@apollo/client'

export const galleryCollectionFindByIdQuery = gql`
  query GalleryCollectionFindById($_id: String!) {
    galleryCollectionFindById(_id: $_id) {
      _id
      name
      index
      user
      createdAt
    }
  }
`
