import styled, { keyframes } from 'styled-components'

type StyledProps = {
  noMargin?: boolean;
  isActive?: boolean;
}

const checkAnim = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const StyledBubble = styled.button<StyledProps>`
  height: 36px;
  padding: ${({ isActive }) => (isActive ? '0 18px 0 40px' : '0 18px')};
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  margin: ${({ noMargin }) => (noMargin ? '0' : '0px 8px 8px 0px')};
  font-weight: 600;
  position: relative;
  font-size: 15px;
  border: 0;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.green[500] : theme.colors.gray[500])};
  background: ${({ theme, isActive }) =>
    (isActive ? theme.colors.green[50] : theme.colors.gray[100])};
  border-radius: 50rem;
  transition: background 0.2s, color 0.2s, padding 0.2s;
  vertical-align: middle;
  outline: none;

  .bubble-content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;

    .emoji {
      margin-right: 5px;
      margin-left: 5px;
    }

    svg {
      height: 20px;
    }
  }

  .bubble-check {
    width: 20px;
    display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 10px;

    svg {
      height: 20px;
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      animation: ${checkAnim} 15s linear forwards;
    }
  }

  &:hover {
    cursor: pointer;
    background: ${({ theme, isActive }) =>
    (isActive ? `${theme.colors.green[100]}a4` : theme.colors.gray[200])};
  }

  &:focus-visible {
    border: 1px solid ${({ theme }) => theme.colors.green[300]};
  }
`

export default StyledBubble
