import { gql } from '@apollo/client'

export const upsertUserDraftMutation = gql`
  mutation UpsertUserDraft($record: UserDraftInput) {
    upsertUserDraft(record: $record) {
      _id
      title
      captionText
      imageUrl
      userUpload
      socialProfiles
      imageId
      fullPath
      template
      challenge
      user
      postStatus
      imageDimensions {
        x
        y
      }
      category
      postDate
      isLiked
      isUsed
      index
      lastModified
      createdAt
    }
  }
`
