import styled from 'styled-components'

const StyledDraftDrawerScheduleBanner = styled.div`
  padding: 20px;
  box-shadow: 0 1px rgb(0 0 0 / 15%);
  position: relative;
  z-index: 1;
  display: grid;

  .banner-header {
    display: flex;
    align-items: center;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.green[500]};

    .banner-header-icon {
      height: 28px;
      width: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }) => theme.colors.green[50]};
      color: ${({ theme }) => theme.colors.green[500]};
      border-radius: 50%;
      margin-right: 12px;
      flex-shrink: 0;

      svg {
        height: 16px;
      }
    }
  }

  .banner-actions {
    margin-top: 14px;
    display: flex;

    button {
      padding: 0 18px;
      font-weight: 600;
      user-select: none;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      transition: 0.2s;
      border-radius: 6px;
      min-width: 75px;
      background-color: ${({ theme }) => theme.colors.gray[100]};
      color: ${({ theme }) => theme.colors.gray[600]};

      span {
        position: relative;
        top: 1px;
      }

      svg:not(.spinner-icon) {
        height: 20px;
      }

      &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.gray[200]};
      }

      &.reschedule-btn {
        width: 100%;
        margin-left: 14px;
        border: 1px solid ${({ theme }) => theme.colors.gray[300]};
        background-color: ${({ theme }) => theme.colors.background};

        &:hover {
          background-color: ${({ theme }) => theme.colors.gray[100]};
        }

        &:disabled {
          cursor: not-allowed;
          border-color: transparent;
          color: ${({ theme }) => theme.colors.gray[400]};
          background-color: ${({ theme }) => theme.colors.gray[100]};
        }
      }
    }
  }
`

export default StyledDraftDrawerScheduleBanner
