import styled from 'styled-components'

type StyledProps = {
  type: 'h1' | 'h2' | 'h3';
}

const StyledHeading = styled.div<StyledProps>`
  margin: 0;
  
  ${({ type, theme }) =>
    type === 'h1' &&
    `
    font-family: ${theme.fonts.lexend};
    font-weight: 400;
    font-size: 32px;
    letter-spacing: -0.25px;
    line-height: 46px;

    @media (min-width: ${theme.breakpoints.m}px) {
      font-size: 40px;
    }
  `}

  ${({ type, theme }) =>
    type === 'h2' &&
    `
    font-family: ${theme.fonts.lexend};
    font-weight: 700;
    font-size: 32px;
    letter-spacing: 0.1px;
    line-height: 45px;
  `}

  ${({ type, theme }) =>
    type === 'h3' &&
    `
    font-family: ${theme.fonts.lexend};
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.1px;
    line-height: 32px;
  `}
`

export default StyledHeading
