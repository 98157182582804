/* eslint-disable @typescript-eslint/camelcase */
import React, { FC, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { parse } from 'query-string'
import firebase from 'firebase/app'
import { StyledLeadForm } from '.'
import { Form as FormType } from '../Admin/AdminLeadFormPage'
import {
  Button, Checkbox, Form, LabelWrapper, LineInput
} from '../System'
import ROUTES from '../utils/routes'
import useScript from '../utils/useScript'
import useSegment from '../utils/useSegment'

const LeadForm: FC = () => {
  const { search } = useLocation()
  const queries = parse(search)
  const { formId } = useParams() as any
  useScript(
    true,
    'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.5.14/iframeResizer.contentWindow.min.js'
  )

  const { track, page } = useSegment()
  const [form, setForm] = useState<undefined | FormType | null>()
  const [formError, setFormError] = useState('')
  const [processing, setProcessing] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const addLead = firebase.functions().httpsCallable('addLead')
  const getFormQuery = firebase.functions().httpsCallable('getForm')

  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [phone, setPhone] = useState('')
  const [phoneOptIn, setPhoneOptIn] = useState(false)
  const [optIn, setOptIn] = useState(true)

  const getForm = async () => {
    try {
      const formResponse = await getFormQuery({ formId })

      if (!formResponse.data) {
        throw new Error('No form found.')
      }

      setForm(formResponse.data)
    }
    catch {
      setForm(null)
    }
  }

  useEffect(() => {
    getForm()
  }, [])

  const validateName = (fullName: string) => {
    const firstAndLast = fullName.split(' ')
    const firstName = firstAndLast[0]
    const lastName = firstAndLast[1]
    const emptyLastName = !lastName || lastName.trim().length === 0
    const emptyFirstName = !firstName || firstName.trim().length === 0

    if (form?.isZoom && emptyLastName) {
      setNameError('Please provide your full name.')
    }
    if (!form?.isZoom && emptyFirstName) {
      setNameError('Please provide your name.')
    }
  }

  const handleName = (fullName) => {
    setNameError('')
    setName(fullName)
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()

    if (processing) {
      return
    }

    setProcessing(true)
    setFormError('')

    try {
      const formResponse = await addLead({
        name,
        email: email.toLowerCase().trim(),
        optIn,
        formId,
        phone
      })

      window.analytics.identify(formResponse.data?.leadId)
      page()
      track(
        'Filled Lead Form',
        {
          form_id: formId,
          form_name: form?.name,
          opt_in: optIn,
          webinar_url: formResponse.data?.joinUrl,
          ...queries
        },
        {
          integrations: {
            All: true,
            ActiveCampaign: false
          }
        }
      )

      if (formResponse.data?.redirectUrl) {
        window.parent.postMessage({ source: 'app', url: formResponse.data?.redirectUrl }, '*')
        return
      }

      setSubmitted(true)
    }
    catch (err) {
      if (err.message === 'invalid-email') {
        setEmailError('Invalid Email')
        setProcessing(false)
        return
      }

      setFormError(err.message)
    }
    setProcessing(false)
  }

  if (submitted) {
    return (
      <StyledLeadForm>
        <div className="message">
          <h1>Thank you!</h1>
          <p>We&apos;ll be reaching out to you shortly!</p>
        </div>
      </StyledLeadForm>
    )
  }

  if (form === null) {
    return (
      <StyledLeadForm>
        <div className="message">
          <h1>Well this is awkward.</h1>
          <p>
            We can&apos;t find this page. Please feel free to visit our{' '}
            <a href={ROUTES.external.waitListUrl} target="_blank" rel="noopener noreferrer">
              waitlist
            </a>
            .
          </p>
        </div>
      </StyledLeadForm>
    )
  }

  return (
    <StyledLeadForm>
      <Form
        onSubmit={(e) => onSubmit(e)}
        message={{
          message: formError,
          type: 'error'
        }}
      >
        {form?.fields.name && (
          <LabelWrapper errorMessage={nameError}>
            <LineInput
              inlineLabel={form.isZoom ? 'Full Name' : 'Name'}
              required
              onChange={(e) => handleName(e.target.value)}
              onBlur={(e) => validateName(e.target.value)}
            />
          </LabelWrapper>
        )}
        {form?.fields.email && (
          <LabelWrapper errorMessage={emailError}>
            <LineInput
              inlineLabel="Email"
              type="email"
              required
              onChange={(e) => {
                setEmail(e.target.value)
                setEmailError('')
              }}
            />
          </LabelWrapper>
        )}

        {phoneOptIn && (
          <LabelWrapper>
            <LineInput
              inlineLabel="Phone"
              type="tel"
              required
              onChange={(e) => setPhone(e.target.value)}
            />
          </LabelWrapper>
        )}

        {form?.fields.phone && (
          <Checkbox
            name="receive-text-reminder"
            isChecked={phoneOptIn}
            onChange={(e) => setPhoneOptIn(e.target.checked)}
            label="Receive a text reminder."
          />
        )}

        {form?.fields?.optIn && (
          <Checkbox
            name="receive-new-events"
            isChecked={optIn}
            onChange={(e) => setOptIn(e.target.checked)}
            label="Receive new events and offers from Social Curator."
          />
        )}
        <Button className="submit-button" isLoading={processing} action="submit">
          {processing ? 'Loading...' : 'Submit'}
        </Button>
      </Form>
    </StyledLeadForm>
  )
}

export default LeadForm
