import { FileObject } from '../Admin/AdminHelperUpload'

export { default as Dashboard } from './Dashboard'
export { default as StyledDashboard } from './Dashboard.Styled'

export { default as Photos } from './Photos'
export { default as StyledPhotos } from './Photos.Styled'

export { default as PhotoPreviewItem } from './PhotoPreviewItem'
export { default as StyledPhotoPreviewItem } from './PhotoPreviewItem.Styled'

export { default as Captions } from './Captions'
export { default as StyledCaptions } from './Captions.Styled'

export { default as Community } from './Community'
export { default as StyledCommunity } from './Community.Styled'

export { default as CommunityItem } from './CommunityItem'
export { default as StyledCommunityItem } from './CommunityItem.Styled'

export { DashboardTile } from './DashboardTile'
export { DraftsTile } from './DraftsTile'

export const ACTION_CATEGORY_LIST = [
  'Post',
  'Comment',
  'Connection Content',
  'Content Ideas',
  'DMs',
  'Hashtags',
  'Live',
  'Miscellaneous',
  'Reels',
  'Stories (No Poll)',
  'Stories (Poll)',
  'Supporting Resource'
]

export type CommunityEventType = {
  id: string;
  title: string;
  thumbnail: FileObject | undefined;
  url: string;
  startTime: firebase.firestore.Timestamp & { _seconds: number; _nanoseconds: number };
  endTime: firebase.firestore.Timestamp & { _seconds: number; _nanoseconds: number };
  isFeatured?: boolean;
}
