import styled from 'styled-components'

type StyledProps = {
  color?: string;
  size: number;
}

const StyledIcon = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: ${({ color }) => color || 'inherit'};
    height: ${({ size }) => size}px;
  }
`

export default StyledIcon
