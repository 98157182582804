import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../root-reducer'
import { CaptionLibraryState } from './types'
import {
  captionsCategoryChangeAction,
  captionsFavoriteChange,
  captionsSearchChangeAction,
  captionsSortChangeAction,
  captionsUsedChanged,
  captionPageChanged,
  setCaptions,
  captionsLoadSearch
} from './actions'
import { SearchCaptions_captionSetSearch_docs } from '../../graphql/CaptionSet/__generated__/SearchCaptions'
import { formatQuery } from '../../components/utils'

const useCaptions = () => {
  const {
    captions,
    categories,
    hideUsed,
    loading,
    onlyFavorites,
    search,
    sort,
    page
  } = useSelector<AppState, CaptionLibraryState>((state) => state.captions)
  const dispatch = useDispatch()

  const searchCaptions = (payload: string) => {
    dispatch(captionsSearchChangeAction(formatQuery(payload)))
  }

  const loadSearch = (payload: any) => {
    dispatch(captionsLoadSearch(payload))
  }

  const changeFavorites = (payload: boolean) => {
    dispatch(captionsFavoriteChange(payload))
  }

  const changeHideUsed = (payload: boolean) => {
    dispatch(captionsUsedChanged(payload))
  }

  const changeCategories = (payload: string) => {
    dispatch(captionsCategoryChangeAction(payload))
  }

  const changePage = (payload: number) => {
    dispatch(captionPageChanged(payload))
  }

  const changeSort = (payload: string) => {
    dispatch(captionsSortChangeAction(payload))
  }

  const addCaptions = (payload: SearchCaptions_captionSetSearch_docs[]) => {
    dispatch(setCaptions(payload))
  }

  return {
    actions: {
      searchCaptions,
      changeFavorites,
      changeHideUsed,
      changeCategories,
      changeSort,
      changePage,
      addCaptions,
      loadSearch
    },
    captions,
    categories,
    hideUsed,
    loading,
    onlyFavorites,
    search,
    sort,
    page
  }
}

export default useCaptions
