import styled from 'styled-components'
import { times } from 'lodash'

type Props = {
  columnLength: number;
  columnSpacing?: string;
  responsive?: boolean;
}

const StyledTable = styled.table<Props>`
  width: 100%;

  thead {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};
    width: 100%;
    display: block;

    tr {
      background: transparent;

      th {
        font-weight: 500;
        padding: 0 1rem;
        font-size: 14px;
        color: ${({ theme }) => theme.colors.gray[300]};
      }
    }
  }

  tbody {
    display: grid;
    position: relative;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};

    tr:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};
    }

    tr:hover {
      background: ${({ theme }) => theme.colors.gray[50]};
    }
  }

  thead,
  tbody {
    tr {
      display: grid;
      grid-template-columns: ${({ columnLength, columnSpacing }) =>
    columnSpacing || times(columnLength).map(() => `${100 / columnLength}% `)};
      text-align: left;
      width: 100%;
      min-height: 40px;
      align-items: center;
      border-radius: 3px;
      font-size: 14px;
      cursor: default;
      transition: background 0.2s top 0.2s;

      td {
        color: ${({ theme }) => theme.colors.gray[600]};
        font-weight: 600;
        padding: 1rem;

        a {
          color: ${({ theme }) => theme.colors.gray[800]};
          font-weight: 600;
          display: flex;
          align-items: center;

          &:hover {
            cursor: pointer;
            color: ${({ theme }) => theme.colors.green[500]};
            text-decoration: underline;
          }
        }

        .delete-btn {
          color: ${({ theme }) => theme.colors.gray[400]};
          border-color: ${({ theme }) => theme.colors.gray[200]};
          background: ${({ theme }) => theme.colors.gray[50]};
          margin-right: 1rem;

          &:hover {
            color: ${({ theme }) => theme.colors.gray[500]};
          }
        }

        .edit-btn {
          text-decoration: none !important;
        }
      }
    }
  }

  .flex-row {
    display: flex;
    align-items: center;
  }

  tfoot {
    padding: 12px 1rem;
    display: flex;
    font-weight: 500;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray[300]};
    align-items: center;
  }

  ${({ responsive, theme }) =>
    responsive &&
    `
    overflow-x: auto;
    width: 100%;
    display: block;

    tbody, thead {
      width: max-content;

      tr {
        grid-template-columns: 1fr 1fr 1fr 1fr;

        td, th {
          min-width: 170px;
          white-space: nowrap;
        }
      } 
    }

    @media (min-width: ${theme.breakpoints.s}px) {
      tbody, thead {
        width: 100%;
      }
    }
  `}
`

export default StyledTable
