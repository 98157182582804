import { useState, useEffect } from 'react'
import throttle from 'lodash/throttle'
import { theme } from './theme'

const useWindowSize = () => {
  const isClient = typeof window === 'object'
  const [windowSize, setWindowSize] = useState(0)
  const [windowBreakpoints, setWindowBreakpoints] = useState<string[]>([])

  // Constructs an array of the current breakpoints the window width satisfies
  const getWindowBreakpoints = () => {
    const windowWidth = window.innerWidth
    const breakpoints = ['xs']

    if (windowWidth >= theme.breakpoints.xl) {
      breakpoints.push('xl')
    }
    if (windowWidth >= theme.breakpoints.l) {
      breakpoints.push('l')
    }
    if (windowWidth >= theme.breakpoints.m) {
      breakpoints.push('m')
    }
    if (windowWidth >= theme.breakpoints.s) {
      breakpoints.push('s')
    }

    setWindowBreakpoints(breakpoints)
  }

  useEffect(() => {
    if (!isClient) {
      return
    }

    setWindowSize(window.innerWidth)
    getWindowBreakpoints()

    const handleResize = throttle(() => {
      getWindowBreakpoints()
      setWindowSize(window.innerWidth)
    }, 333)

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    width: windowSize,
    breakpoints: windowBreakpoints
  }
}

export default useWindowSize
