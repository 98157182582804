import styled from 'styled-components'

const StyledRichInput = styled.div`
  position: relative;
  border: 1px solid #c4c4c4;
  border-radius: 3px;

  .material-icons {
    font-size: 21px !important;
  }

  .toolbar {
    background: #f4f4f4;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  .input-field {
    letter-spacing: 0.02rem;
    color: ${({ theme }) => theme.colors.gray[800]};
    font-weight: 500;
    font-size: 14px;
    padding: 15px;

    span {
      font-weight: 500;
    }

    p {
      margin: 0;
    }
  }
`

export default StyledRichInput
