import {
  CartState,
  CartActions,
  SHOPPING_CART_ADD_ITEM,
  SHOPPING_CART_REMOVE_ITEM,
  SHOPPING_CART_START_PAYMENT,
  SHOPPING_CART_SUCCESS_PAYMENT,
  SHOPPING_CART_FAIL_PAYMENT,
  SHOPPING_CART_ABORT_PAYMENT,
  SHOPPING_CART_UPDATE_PRICING
} from './types'

const initialState: CartState = {
  cartItems: [],
  paymentLoading: false,
  paymentSuccess: false,
  paymentError: null
}

export const shoppingCartReducer = (state = initialState, action: CartActions): CartState => {
  switch (action.type) {
    case SHOPPING_CART_ADD_ITEM:
      return {
        ...state,
        cartItems: [
          ...state.cartItems.filter((item) => item.itemData._id !== action.payload.itemData._id),
          action.payload
        ]
      }
    case SHOPPING_CART_REMOVE_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter((item) => item.itemData._id !== action.payload.id)
      }
    case SHOPPING_CART_UPDATE_PRICING:
      return {
        ...state,
        cartItems: state.cartItems.map((item) => {
          if (item.itemData._id === action.payload.id) {
            return {
              ...item,
              priceId: action.payload.priceId
            }
          }
          return item
        })
      }
    case SHOPPING_CART_START_PAYMENT:
      return {
        ...state,
        paymentLoading: true,
        paymentSuccess: false,
        paymentError: null
      }
    case SHOPPING_CART_SUCCESS_PAYMENT:
      return {
        ...state,
        purchasedItems: state.cartItems,
        paymentLoading: false,
        paymentSuccess: true,
        paymentError: null,
        cartItems: []
      }
    case SHOPPING_CART_FAIL_PAYMENT:
      return {
        ...state,
        paymentLoading: false,
        paymentSuccess: false,
        paymentError: action.payload
      }
    case SHOPPING_CART_ABORT_PAYMENT:
      return {
        ...state,
        paymentLoading: false,
        paymentSuccess: false,
        paymentError: null
      }
    default:
      // eslint-disable-next-line no-case-declarations,@typescript-eslint/no-unused-vars
      const x: never = action
  }
  return state
}
