import { DotsHorizontalIcon } from '@heroicons/react/outline'
import React, {
  forwardRef, MouseEvent, useRef, useState, ReactNode
} from 'react'
import styled, { css } from 'styled-components'
import useClickOutsideComponent from '../../utils/useClickOutsideComponent'

type Position = 'left bottom' | 'top right'
type Props = {
  customIcon?: ReactNode;
  minWidth?: number;
  iconSize?: number;
  darkShadow?: boolean;
  children: ReactNode;
  hideOnAnyClick?: boolean;
  position?: Position;
  customOpen?: boolean;
  setCustomOpen?: (arg: any) => void;
  [props: string]: any;
}

const AdminPopupMenu = forwardRef<any, Props>((refProps, ref) => {
  const {
    minWidth = 180,
    iconSize = 18,
    children,
    darkShadow = false,
    hideOnAnyClick = false,
    position = 'left bottom',
    customIcon,
    customOpen,
    setCustomOpen,
    ...props
  } = refProps
  const refBlock = useRef(null)
  const [open, setOpen] = useState(false)
  useClickOutsideComponent(refBlock, () => {
    if (setCustomOpen) {
      setCustomOpen(false)
    }
    else {
      setOpen(false)
    }
  })

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (!setCustomOpen) {
      e.stopPropagation()
      setOpen((prev) => !prev)
    }
  }

  const handleClickBody = () => {
    if (hideOnAnyClick) {
      if (setCustomOpen) {
        setCustomOpen(false)
      }
      else {
        setOpen(false)
      }
    }
  }

  return (
    <StyledAdminEllipsisActions
      darkShadow={darkShadow}
      minWidth={minWidth}
      position={position}
      show={customOpen ?? open}
      {...props}
    >
      <div className="icon" tabIndex={0} role="button" onClick={handleClick}>
        {customIcon || <DotsHorizontalIcon ref={ref} /> }
      </div>
      <div className="content" ref={refBlock} onClick={handleClickBody}>
        {children}
      </div>
    </StyledAdminEllipsisActions>
  )
})

const StyledLinks = css`
  .action-div {
    background: none;
    border: none;
    padding: 8px 2px;
    color: ${({ theme }) => theme.colors.gray[800]};
    &.danger {
      color: ${({ theme }) => theme.colors.green[500]};
    }
    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.gray[500]};
    }
  }
`

const LeftBottom = css`
  right: 0;
  top: calc(100% + 3px);
`

const TopRight = css`
  left: 100%;
  bottom: calc(100% + 3px);
`

const StyledAdminEllipsisActions = styled.div<{
  show: boolean;
  minWidth: number;
  darkShadow: boolean;
  position?: Position;
}>`
  display: inline-block;
  position: relative;
  .content {
    cursor: default;
    visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
    padding: 14px;
    min-width: ${({ minWidth }) => `${minWidth}px`};
    position: absolute;
    z-index: ${({ theme }) => theme.zIndex.dropdown};
    ${({ position }) => (position === 'top right' ? TopRight : LeftBottom)};
    background: ${({ theme }) => theme.colors.background};
    box-shadow: ${({ darkShadow }) => (darkShadow ? `0 3px 9px rgba(0, 0, 0, 0.23), 0 2px 2px rgba(0, 0, 0, 0.25)` : `0 2px 8px rgba(0, 0, 0, 0.13), 0 1px 1px rgba(0, 0, 0, 0.18)`)};
  }
  .icon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ${StyledLinks};
`

export default AdminPopupMenu
