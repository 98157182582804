import React, { FC, useEffect, useState, ReactNode } from 'react'
import classNames from 'classnames'
import { DuplicateIcon } from '@heroicons/react/outline'
import { serializePlainText } from '../../Slate/slate'
import { StyledCopyButton } from '.'

type Props = {
  copyContent: string | undefined;
  plaintext?: boolean;
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
}

const CopyButton: FC<Props> = ({
  copyContent, plaintext, className, children, onClick
}) => {
  const [isCopied, setCopied] = useState(false)

  // Copies the desired text to the user's clipboard
  const copy = (copyContent) => {
    navigator.clipboard.writeText(
      plaintext ? copyContent : serializePlainText({ children: JSON.parse(copyContent) })
    )
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000)

    if (onClick) {
      onClick()
    }
  }

  useEffect(() => {
    setCopied(false)
  }, [copyContent])

  return (
    <StyledCopyButton
      isCopied={isCopied}
      onClick={() => copy(copyContent)}
      className={classNames('copy-btn', className)}
      type="button"
    >
      <DuplicateIcon />
      {isCopied && <span>copied</span>}
      {children}
    </StyledCopyButton>
  )
}

export default CopyButton
