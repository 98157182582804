import styled, { keyframes, css } from 'styled-components'

type StyledProps = {
  step: number;
  isCompleted: boolean;
}

const checkAnim = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const StyledDailyAction = styled.div<StyledProps>`
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: start;
  user-select: none;
  min-height: 76px;
  position: relative;
  margin-top: calc(-76px / 2);
  overflow: hidden;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 1rem;
  box-shadow: 0px 0px 0px 1px rgba(17, 24, 39, 0.1), 0px 2px 4px rgba(17, 24, 39, 0.1);

  .reward {
    position: absolute;
    top: -250px;
    left: 50%;
    pointer-events: none;
    transform: translateX(-50%);
  }

  button {
    transition: 0.2s;

    &:hover {
      cursor: pointer;
    }
  }

  .daily-action-left {
    display: flex;

    .daily-action-icon-group {
      display: grid;
      justify-items: center;
      align-content: start;
      grid-gap: 8px;
      margin-right: 1rem;
      flex-shrink: 0;

      .daily-action-icon {
        height: 38px;
        width: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.green[50]};
        color: ${({ theme }) => theme.colors.green[500]};

        svg {
          height: 24px;
          width: 24px;

          path {
            stroke-width: 1.7px;
          }
        }
      }

      .daily-action-step {
        display: flex;

        div {
          height: 6px;
          width: 6px;
          transition: 0.2s;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors.gray[200]};

          &:nth-of-type(${({ step }) => step}) {
            background-color: ${({ theme }) => theme.colors.gray[300]};
          }

          &:not(:last-child) {
            margin-right: 5px;
          }
        }
      }
    }

    .daily-action-info-container {
      margin-top: -4px;

      .daily-action-info {
        display: flex;
        align-items: center;

        .daily-action-category {
          font-weight: 700;
          font-size: 13px;
          letter-spacing: 0.05rem;
          margin-right: 4px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-align: left;
          overflow: hidden;
          color: ${({ theme }) => theme.colors.gray[500]};
        }

        button {
          width: 26px;
          height: 26px;
          padding: 0;
          border-radius: 6px;
          display: flex;
          align-items: center;
          margin-top: -2px;
          background-color: transparent;
          justify-content: center;
          color: ${({ theme }) => theme.colors.gray[500]};
          border: 0;

          svg {
            height: 20px;
            width: 20px;
          }

          &:not(:last-child) {
            margin-right: 0px;
          }

          &:disabled {
            color: ${({ theme }) => theme.colors.gray[300]};

            &:hover:disabled {
              cursor: not-allowed;
            }
          }

          &:hover:not(:disabled) {
            background-color: ${({ theme }) => theme.colors.gray[100]};
          }
        }
      }

      .daily-action-text {
        font-size: 17px;
        line-height: 23px;
        color: ${({ theme }) => theme.colors.gray[700]};

        span {
          margin-right: 7px;
        }

        a {
          display: inline-flex;
          align-items: center;
          font-weight: 600;
          font-size: 1rem;
          color: ${({ theme }) => theme.colors.gray[500]};

          svg {
            height: 18px;
            width: 18px;
            margin-left: 6px;

            path {
              stroke-width: 2.2px;
            }
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .complete-btn {
    margin-left: 1rem;
    height: 52px;
    width: 52px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background-color: ${({ theme, isCompleted }) =>
    (isCompleted ? theme.colors.green[500] : 'transparent')};
    transition: 0.2s;
    border: 1px solid
      ${({ theme, isCompleted }) =>
    (isCompleted ? theme.colors.green[500] : theme.colors.gray[300])};
    color: ${({ theme, isCompleted }) =>
    (isCompleted ? theme.colors.background : theme.colors.gray[300])};

    ${({ isCompleted }) =>
    !isCompleted &&
      `
      &:active {
        transform: scale(0.85);
      }
    `}

    svg {
      height: 26px;
      width: 26px;

      ${({ isCompleted }) =>
    isCompleted &&
        css`
          stroke-dasharray: 1000;
          stroke-dashoffset: 1000;
          animation: ${checkAnim} 15s linear forwards;
        `}

      path {
        stroke-width: 1.7px;
      }
    }

    &:hover {
      background-color: ${({ theme, isCompleted }) =>
    (isCompleted ? theme.colors.green[600] : theme.colors.gray[100])};
    }
  }

  .action-completed-left {
    height: 52px;
    display: flex;
    align-items: center;

    .back-btn {
      height: 36px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      border: 0;
      margin-right: 12px;
      color: ${({ theme }) => theme.colors.gray[500]};
      background-color: ${({ theme }) => theme.colors.gray[100]};

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[200]};
      }
    }

    .celebration-text {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.gray[700]};
    }
  }

  .confetti-icon {
    align-self: center;
    font-size: 32px;
    display: flex;
    align-items: center;
    position: relative;
    top: 2px;
  }
`

export default StyledDailyAction
