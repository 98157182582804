import React, { FC, ReactNode } from 'react'
import { SortableContainer, SortableContainerProps } from 'react-sortable-hoc'
import { SortableRow } from '.'

type WrapperProps = SortableContainerProps & {
  rows: ReactNode[];
  className?: string;
  isDisabled?: boolean;
}

type SortableProps = {
  children: ReactNode;
}

const Sortable = SortableContainer(({ children }: SortableProps) => {
  return <div className="sortable-container">{children}</div>
})

const SortableWrapper: FC<WrapperProps> = ({
  className, rows, isDisabled, ...rest
}) => {
  return (
    <div className={className}>
      <Sortable {...rest}>
        {rows.map((content, index) => {
          return (
            <SortableRow key={index} index={index} disabled={isDisabled}>
              {content}
            </SortableRow>
          )
        })}
      </Sortable>
    </div>
  )
}

export default SortableWrapper
