import React, { FC, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { shuffle } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { ScrollRow } from '../System'
import { StyledPhotos, PhotoPreviewItem } from '.'
import { SkeletonLoader } from '../SkeletonLoader'
import {
  SearchGalleryQuery,
  SearchGalleryQuery_searchGallery_docs
} from '../../graphql/GalleryImage/__generated__/SearchGalleryQuery'
import { searchGalleryQuery } from '../../graphql/GalleryImage/searchGallery'

const Photos: FC = () => {
  const apollo = useApolloClient()
  const [loading, setLoading] = useState(true)
  const [previewImage, setPreviewImage] = useState<SearchGalleryQuery_searchGallery_docs>()
  const [photoList, setPhotoList] = useState<SearchGalleryQuery_searchGallery_docs[]>([])

  // Loads 9 horizontal gallery photos from Algolia
  const getGalleryPhotos = async () => {
    try {
      const { data } = await apollo.query<SearchGalleryQuery>({
        query: searchGalleryQuery,
        variables: {
          search: '',
          orientation: 'horizontal',
          sort: 'popular',
          categories: [],
          style: [],
          colors: [],
          items: 9,
          page: 0
        }
      })

      const docs = data.searchGallery?.docs
      setPhotoList(shuffle(docs))
    }
    catch (err) {
      Sentry.captureException(err)
      console.error(err)
    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getGalleryPhotos()
  }, [])

  return (
    <StyledPhotos>
      <ScrollRow>
        {(!loading &&
          photoList.slice(0, 9).map((photo, index) => {
            return (
              <PhotoPreviewItem
                onClick={(photo) => setPreviewImage(photo)}
                key={index}
                photo={photo}
              />
            )
          })) ||
          []}
        {loading && <SkeletonLoader quantity={3} type="square-list-item" />}
      </ScrollRow>
    </StyledPhotos>
  )
}

export default Photos
