import { useApolloClient } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { useContext } from 'react'
import { AppState } from '../../redux/root-reducer'
import { ChallengeState } from '../../redux/challenge/types'
import { ChallengeExtraDrafts } from '../../graphql/Challenge/__generated__/ChallengeExtraDrafts'
import { challengeExtraDraftsMutation } from '../../graphql/Challenge/challengeExtraDrafts'
import { ChallengeChangeUserDataAction } from '../../redux/challenge/actions'
import useSegment from './useSegment'
import { GetUserChallenges_getUserChallenges_userChallenge } from '../../graphql/Challenge/__generated__/GetUserChallenges'
import { UserChallengeUpdateById } from '../../graphql/Challenge/__generated__/UserChallengeUpdateById'
import { UserChallengeUpdateByIdMutation } from '../../graphql/Challenge/userChallengeUpdateById'
import { UserDraftFindById_userDraftFindById } from '../../graphql/UserDraft/__generated__/UserDraftFindById'
import { userDraftFindManyQuery } from '../../graphql/UserDraft/userDraftFindMany'
import { UserDraftFindMany } from '../../graphql/UserDraft/__generated__/UserDraftFindMany'
import { userStore } from './UserContext'

const useExtraDraftGranting = () => {
  const apollo = useApolloClient()
  const { user } = useContext(userStore)
  const dispatch = useDispatch()
  const { track } = useSegment()

  const { currentChallenge, userChallenge } = useSelector<AppState, ChallengeState>(
    (state) => state.challenge
  )

  const usedChallengeDrafts = async () => {
    const { data } = await apollo.query<UserDraftFindMany>({
      query: userDraftFindManyQuery,
      variables: { challenge: userChallenge?.challenge, isUsed: true, user: user?.id },
      fetchPolicy: 'no-cache'
    })

    return data?.userDraftFindMany?.length || 0
  }

  const grantDrafts = async (userChallenge: GetUserChallenges_getUserChallenges_userChallenge) => {
    await apollo.mutate<ChallengeExtraDrafts>({
      mutation: challengeExtraDraftsMutation,
      variables: { challenge: userChallenge.challenge }
    })
  }

  const handleChallengeUsage = async (justUsedDraft: UserDraftFindById_userDraftFindById) => {
    const notChallengeDraft = !justUsedDraft?.challenge
    const challengeCompleted = userChallenge?.completed
    const completedExtraDrafts = userChallenge?.completedExtraDrafts
    const differentChallenge = justUsedDraft?.challenge !== userChallenge?.challenge

    if (!userChallenge || notChallengeDraft || differentChallenge) {
      return
    }

    const { initialDrafts, extraDrafts } = currentChallenge!

    const now = moment()
    const started = moment(userChallenge.joined)
    const diff = started.diff(now, 'days')
    const usedDrafts = await usedChallengeDrafts()
    const usedInitialDrafts = usedDrafts >= initialDrafts
    const finishedEarly = diff <= 5 && usedInitialDrafts
    const outsideChallengeWindow = diff >= 14

    if (outsideChallengeWindow) {
      return
    }

    if (usedInitialDrafts && !challengeCompleted) {
      track('Completed Challenge', {
        completed_in: diff,
        challenge: currentChallenge?._id,
        name: currentChallenge?.name
      })
      await apollo.mutate<UserChallengeUpdateById>({
        mutation: UserChallengeUpdateByIdMutation,
        variables: { _id: userChallenge._id, completed: true }
      })

      dispatch(ChallengeChangeUserDataAction({ ...userChallenge, completed: true }))
    }

    if (finishedEarly && !challengeCompleted) {
      await grantDrafts(userChallenge)
      window.Intercom('trackEvent', 'Received Extra Challenge Drafts', {
        challenge: currentChallenge?._id,
        name: currentChallenge?.name,
        amount: currentChallenge?.extraDrafts
      })

      dispatch(
        ChallengeChangeUserDataAction({
          ...userChallenge,
          completed: true,
          grantedExtraDrafts: true
        })
      )
    }

    const totalChallengeDrafts = initialDrafts + extraDrafts
    if (
      extraDrafts > 0 &&
      userChallenge.grantedExtraDrafts &&
      !completedExtraDrafts &&
      challengeCompleted &&
      usedDrafts >= totalChallengeDrafts
    ) {
      track('Completed Extra Challenge Drafts', {
        challenge: currentChallenge?._id,
        name: currentChallenge?.name,
        completed_in: diff
      })
      await apollo.mutate<UserChallengeUpdateById>({
        mutation: UserChallengeUpdateByIdMutation,
        variables: { _id: userChallenge._id, completedExtraDrafts: true }
      })

      dispatch(ChallengeChangeUserDataAction({ ...userChallenge, completedExtraDrafts: true }))
    }
  }

  return { handleChallengeUsage }
}

export default useExtraDraftGranting
