import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { StyledIssueFooter } from '.'
import RenderSlate from '../Slate/RenderSlate'
import rectangle1 from './footer-rectangle-1.svg'
import rectangle2 from './footer-rectangle-2.svg'
import { WidthController } from '../Grid'
import { GetIssueBySlug_issueFindOne } from '../../graphql/Issue/__generated__/GetIssueBySlug'
import useSegment from '../utils/useSegment'
import ROUTES from '../utils/routes'

type Props = {
  issue: GetIssueBySlug_issueFindOne;
}

const IssueFooter: FC<Props> = ({ issue }) => {
  const { track } = useSegment()

  const fallBackText = [
    {
      type: 'paragraph',
      children: [
        {
          text:
            'Ready to take what you\'ve learned and put it into ACTION?! Ready to connect with other business owners and have ACCOUNTABILITY?! Find it all (and more) inside the Facebook Community, where we do the work together!'
        }
      ]
    }
  ]

  const trackCommunityClick = () => {
    track('Clicked Join Community Button', {
      issue_id: issue._id,
      issue_title: issue.title
    })
  }

  return (
    <StyledIssueFooter>
      <div className="issue-footer">
        <div className="issue-footer-decor">
          <img src={rectangle1} alt="" role="presentation" />
          <img src={rectangle2} alt="" role="presentation" />
        </div>
        <WidthController noHeight noPadding>
          <div className="issue-footer-grid">
            <div className="issue-footer-grid-item">
              <span className="issue-footer-title">Join the conversation</span>
              <span className="issue-footer-body">
                <RenderSlate html={issue?.facebookText || JSON.stringify(fallBackText)} />
              </span>
            </div>
            <div className="issue-footer-grid-item">
              <Link
                className="issue-footer-link"
                onClick={() => trackCommunityClick()}
                to={ROUTES.dashboard.community}
                aria-label="Join the community"
                target="_blank"
                rel="noreferrer"
              >
                JOIN
              </Link>
            </div>
          </div>
        </WidthController>
      </div>
    </StyledIssueFooter>
  )
}

export default IssueFooter
