export const handleSlug = (title: string) => {
  const formattedSlug = title
    .trim()
    .replace(/\s+/g, ' ')
    .replace(/[^a-z0-9\s]+/gi, '')
    .trim()
    .replace(/\s/g, '-')
    .toLowerCase()

  return formattedSlug
}
