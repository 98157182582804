import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { DownloadIcon } from '@heroicons/react/outline'
import { DuplicateIcon } from '@heroicons/react/solid'
import { useQuery } from '@apollo/client'
import ROUTES from '../../utils/routes'
import { linkToSizedImage, SizeData } from '../../IssueEntityImage/helpers'
import { Button } from '../../System'
import { ToolTipWrapper } from '../../ToolTip'
import { StyledIssueStory } from '.'
import { GetIssueBySlug_issueFindOne } from '../../../graphql/Issue/__generated__/GetIssueBySlug'
import { GetIssueAssetsQuery_storySetFindMany } from '../../../graphql/Issue/__generated__/GetIssueAssetsQuery'
import useSegment from '../../utils/useSegment'
import { storyFindMany } from '../../../graphql/Story/storyFindMany'
import { StoryFindMany } from '../../../graphql/Story/__generated__/StoryFindMany'

type Props = {
  story: GetIssueAssetsQuery_storySetFindMany;
  issue: GetIssueBySlug_issueFindOne;
}

const IssueStory: FC<Props> = ({ story, issue }) => {
  const history = useHistory()
  const { track } = useSegment()
  const THUMBNAIL_SIZE: SizeData = { height: 350 }

  const { data } = useQuery<StoryFindMany>(storyFindMany, { variables: { storySet: story._id } })

  const handleStoryDownload = (e) => {
    e.stopPropagation()
    track('Downloaded Story', {
      id: story._id,
      title: story.title,
      url: story.thumbnail,
      index: story.index,
      issue_id: issue._id,
      issue_title: issue.title,
      from: 'issue'
    })
  }

  return (
    <StyledIssueStory
      onClick={() => history.push(ROUTES.issues.buildRecordPage(story._id, issue.slug, 'story'))}
    >
      <div className="image">
        <ToolTipWrapper label="Download">
          <Button
            onClick={(e) => handleStoryDownload(e)}
            externalURL={linkToSizedImage(story.thumbnail || '', {})}
            className="photo-draft-btn"
            theme="ghost"
            isIcon
          >
            <DownloadIcon />
          </Button>
        </ToolTipWrapper>
        <div className="story-size">
          <DuplicateIcon /> {data?.storyFindMany?.length || null}
        </div>
        <img
          src={linkToSizedImage(story.thumbnail || '', THUMBNAIL_SIZE)}
          alt={story.title || ''}
        />
      </div>
      <div className="details">
        <span className="story-index">STORY SET #{story.index! + 1}</span>
        <span className="story-title">{story.title}</span>
      </div>
    </StyledIssueStory>
  )
}

export default IssueStory
