import styled from 'styled-components'

type StyledProps = {
  view: string;
}

const StyledDraftsView = styled.div<StyledProps>`
  .draft-list {
    margin-top: 20px;

    .infinite-scroll-component {
      margin: -7px;
    }

    .draft-grid-container {
      padding: 7px;
    }

    .draft-grid,
    .draft-grid-container > div {
      display: grid;
      grid-gap: ${({ view }) => (view === 'list' ? '20px' : '6px')};
      align-items: start;
      grid-template-columns: ${({ view }) =>
    (view === 'list'
      ? 'repeat(auto-fill, minmax(280px, 1fr))'
      : 'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)')};
    }
  }

  // .draft-list {
  //   margin-top: 40px;

  //   .draft-grid,
  //   .draft-grid-container > div {
  //     grid-gap: ${({ view }) => (view === 'list' ? '12px' : '6px')};
  //     align-items: start;
  //     grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  //   }
  // }
`

export default StyledDraftsView
