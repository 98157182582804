import { gql } from '@apollo/client'

export const sendDraftNow = gql`
  mutation SendDraftNow($draft: String!, $profiles: [String]!) {
    sendDraftNow(draft: $draft, profiles: $profiles) {
      failures
      status
    }
  }
`
