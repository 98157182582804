import lodash from 'lodash'
import {
  ISSUES_CATEGORY_CHANGE,
  ISSUES_LOAD_SEARCH,
  ISSUES_PAGE_CHANGE,
  ISSUES_PLATFORM_CHANGE,
  ISSUES_SEARCH_CHANGE,
  SET_ISSUES,
  IssueActions,
  IssueListState,
  ISSUES_CLEAR_AND_SET_CATEGORY
} from './types'

const initialState: IssueListState = {
  issues: [],
  categories: [],
  loading: true,
  search: '',
  page: 0,
  type: 'default',
  platform: ''
}

export const captionLibraryReducer = (
  state = initialState,
  action: IssueActions
): IssueListState => {
  switch (action.type) {
    case ISSUES_SEARCH_CHANGE:
      return {
        ...state,
        search: action.payload,
        issues: [],
        page: 0,
        loading: true
      }

    case ISSUES_CATEGORY_CHANGE:
      return {
        ...state,
        // remove categories if they exist in the array, add them if they don't
        categories: lodash.xor(state.categories, [action.payload]),
        issues: [],
        page: 0,
        loading: true
      }

    case ISSUES_PLATFORM_CHANGE:
      return {
        ...state,
        platform: action.payload,
        issues: [],
        page: 0,
        loading: true
      }

    case ISSUES_PAGE_CHANGE:
      return {
        ...state,
        page: action.payload,
        loading: true
      }

    case SET_ISSUES:
      return {
        ...state,
        issues: [...state.issues, ...action.payload],
        loading: false
      }

    case ISSUES_LOAD_SEARCH:
      return {
        ...state,
        ...action.payload,
        issues: [],
        loading: true
      }

    case ISSUES_CLEAR_AND_SET_CATEGORY:
      return {
        issues: [],
        categories: [action.payload],
        loading: false,
        search: '',
        page: 0,
        type: 'default',
        platform: ''
      }

    default:
      return state
  }
}
