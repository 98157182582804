import styled from 'styled-components'

const StyledListItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .input {
    width: 100%;

    &:not(:first-child) {
      margin-left: 10px;
    }
  }

  > .btn {
    margin-left: 1rem;
  }
`

export default StyledListItem
