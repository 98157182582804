import React, { FC, useContext, useState, useEffect } from 'react'
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/solid'
import { useHistory } from 'react-router'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { Button, Dialog } from '../../System'
import { userStore } from '../../utils/UserContext'
import ROUTES from '../../utils/routes'
import useUsageData from '../../Account/useUsageData'
import useAccountSubscription from '../../Account/useAccountSubscription'
import { StyledUpgradeCTAModal } from '.'
import { CloseButton } from '../../System/CloseButton'
import { AppState } from '../../../redux/root-reducer'
import { ChallengeState } from '../../../redux/challenge/types'

// Upgrade CTA Modal
// This shows up at a set frequency (24 hours since last shown) when a Trial user is navigating their trial account
const UpgradeCTAModal: FC = () => {
  const { userChallenge } = useSelector<AppState, ChallengeState>((state) => state.challenge)
  const [showModal, setShowModal] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)
  const history = useHistory()
  const { user } = useContext(userStore)
  const currentDate = moment()
  const isTrialUser = user?.trial && user?.stripeSubscriptionStatus === 'Active'

  const {
    periodEndsInDays,
    actions: { loadSubscriptionInfo }
  } = useAccountSubscription()

  const { usageData, completedTasks, getAllUsageData } = useUsageData()

  const updateDateAndShowModal = () => {
    // save current date into local storage
    localStorage.setItem('trialCTAModalDate', currentDate.valueOf().toString())
    setShowModal(true)
  }

  const compareDates = () => {
    const trialCTAModalDate = localStorage.getItem('trialCTAModalDate')

    // If the user signed up today, don't show the modal
    const userRegisteredDate = user?.createdAt?.seconds || 0
    const registeredToday = moment.utc(userRegisteredDate * 1000).isSame(new Date(), 'day')

    if (registeredToday) {
      return
    }

    // if no date saved into local storage
    if (!trialCTAModalDate) {
      updateDateAndShowModal()
      return
    }

    // check if modal has been shown in the past 24 hours
    const dateModalLastShown = moment(Number(trialCTAModalDate))
    const difference = currentDate.diff(dateModalLastShown, 'days')

    // if not, show modal
    if (difference >= 1) {
      updateDateAndShowModal()
    }
  }

  const handleLoadData = async () => {
    await loadSubscriptionInfo()
    await getAllUsageData()
    setDataLoaded(true)
  }

  // compare dates if active trial user
  useEffect(() => {
    if (isTrialUser) {
      compareDates()
    }
  }, [user])

  // load user usage data if we are showing the modal
  useEffect(() => {
    if (showModal) {
      handleLoadData()
    }
  }, [showModal])

  if (!dataLoaded || userChallenge) {
    return null
  }

  return (
    <Dialog isOpen={showModal} onClose={() => setShowModal(false)}>
      <StyledUpgradeCTAModal smaller={completedTasks}>
        <div className="main-column">
          <div className="modal-header">
            <div className="modal-header-main">
              <h3 className="modal-title">
                {`You have ${periodEndsInDays} days left on your free trial`}
              </h3>
              <CloseButton onClick={() => setShowModal(false)} className="mobile-close-button" />
            </div>
            {completedTasks && (
              <h4 className="modal-subtitle">"But don't worry, we can fix that!"</h4>
            )}
          </div>
          <div className="modal-content">
            {completedTasks && usageData ? (
              <div className="modal-list">
                <h4>So far you have</h4>
                <ul>
                  {usageData.draftCount > 0 && <li>{usageData.draftCount} Drafts</li>}
                  {usageData.imageCount > 0 && <li>{usageData.imageCount} Favorited Images</li>}
                  {usageData.captionCount > 0 && (
                    <li>{usageData.captionCount} Favorited Captions</li>
                  )}
                </ul>
              </div>
            ) : (
              <div className="modal-list">
                <h4>Join today and you get,</h4>
                <ul>
                  <li className="features">
                    <CheckCircleIcon />{' '}
                    <p>
                      Daily bite-sized Actions <i>(to boost your engagement on social media)</i>
                    </p>
                  </li>
                  <li className="features">
                    <CheckCircleIcon />{' '}
                    <p>
                      Group coaching and masterclasses <i>(with Jasmine Star and special guests)</i>
                    </p>
                  </li>
                  <li className="features">
                    <CheckCircleIcon /> <p>A library of 3000+ customizable caption templates</p>
                  </li>
                  <li className="features">
                    <CheckCircleIcon />{' '}
                    <p>
                      Thousands of Lifestyle photos{' '}
                      <i>(so you always have scroll-stopping photos)</i>
                    </p>
                  </li>
                  <li className="features">
                    <CheckCircleIcon />{' '}
                    <p>200+ Instagram Story Templates customizable in Canva to fit your brand</p>
                  </li>
                  <li className="features">
                    <CheckCircleIcon /> Over 50+ step-by-step marketing Action Plans that will help
                    you take your business to the next level
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <div className="info-bubble">
              <InformationCircleIcon />
              <p>
                {completedTasks
                  ? 'Upgrade now to save all your work and continue your business growth with Social Curator.'
                  : 'Upgrade now to keep your Social Curator resources and get access to our buzzing Community.'}
              </p>
            </div>
            <Button
              onClick={() => {
                history.push(ROUTES.user.settings.plan)
                setShowModal(false)
              }}
            >
              Upgrade
            </Button>
          </div>
        </div>
      </StyledUpgradeCTAModal>
    </Dialog>
  )
}

export default UpgradeCTAModal
