//default index file created by jakeCLI 1.0 12:31:07 GMT-0700 (Pacific Daylight Time)
import { matchPath } from 'react-router-dom'
import ROUTES from '../utils/routes'

export { default as Intercom } from './Intercom'

const blacklist = [ROUTES.leads.form, ROUTES.user.logout]

export const isInBlacklist = (pathname: string) => {
  return blacklist.some((route) => {
    return matchPath(pathname, {
      path: route,
      exact: true,
      strict: false
    })
  })
}
