import { XIcon } from '@heroicons/react/solid'
import React, { FC } from 'react'
import { StyledCloseButton } from '.'

type Props = {
  onClick?: (e) => void;
  className?: string;
}

const CloseButton: FC<Props> = ({ onClick, className }) => {
  return (
    <StyledCloseButton>
      <button onClick={onClick} className={`close-button ${className || ''}`}>
        <XIcon className="x-icon" />
      </button>
    </StyledCloseButton>
  )
}

export default CloseButton
