import { SearchCaptions_captionSetSearch_docs } from '../../graphql/CaptionSet/__generated__/SearchCaptions'
import {
  CAPTIONS_CATEGORY_CHANGE,
  CAPTIONS_FAVORITE_CHANGE,
  CAPTIONS_SEARCH_CHANGE,
  CAPTIONS_SORT_CHANGE,
  CAPTIONS_USED_CHANGE,
  CAPTIONS_PAGE_CHANGE,
  SET_CAPTIONS,
  CAPTIONS_LOAD_SEARCH,
  CaptionActions
} from './types'

export const captionsSearchChangeAction = (payload: string): CaptionActions => ({
  type: CAPTIONS_SEARCH_CHANGE,
  payload
})

export const captionsCategoryChangeAction = (payload: string): CaptionActions => ({
  type: CAPTIONS_CATEGORY_CHANGE,
  payload
})

export const captionsSortChangeAction = (payload: string): CaptionActions => ({
  type: CAPTIONS_SORT_CHANGE,
  payload
})

export const captionsFavoriteChange = (payload: boolean): CaptionActions => ({
  type: CAPTIONS_FAVORITE_CHANGE,
  payload
})

export const captionsUsedChanged = (payload: boolean): CaptionActions => ({
  type: CAPTIONS_USED_CHANGE,
  payload
})

export const captionPageChanged = (payload: number): CaptionActions => ({
  type: CAPTIONS_PAGE_CHANGE,
  payload
})

export const setCaptions = (payload: SearchCaptions_captionSetSearch_docs[]): CaptionActions => ({
  type: SET_CAPTIONS,
  payload
})

export const captionsLoadSearch = (payload: any): CaptionActions => ({
  type: CAPTIONS_LOAD_SEARCH,
  payload
})
