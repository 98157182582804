import styled from 'styled-components'

type StyledProps = {
  inModal?: boolean;
}

const StyledConnectionsModal = styled.div<StyledProps>`
  position: relative;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;

  .loading-pane {
    height: 250px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner {
      svg {
        height: 50px;
        width: 50px;
        fill: ${({ theme }) => theme.colors.gray[300]};
      }
    }
  }

  .profile-selection {
    width: 100%;

    .connect-btn {
      height: 50px;
      width: 100%;
      font-size: 1rem;
      border-radius: 6px;
      color: ${({ theme }) => theme.colors.gray[600]};

      &:disabled {
        border-color: ${({ theme }) => theme.colors.gray[100]};
        background-color: ${({ theme }) => theme.colors.gray[100]};
        color: ${({ theme }) => theme.colors.gray[300]};
        cursor: not-allowed;
      }
    }

    .profile-selection-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      color: ${({ theme }) => theme.colors.gray[400]};
      font-size: 15px;
      font-weight: 500;
      letter-spacing: -0.01rem;

      h1 {
        font-weight: 600;
        font-size: 1.05rem;
        margin: 0;
        letter-spacing: -0.005rem;
        color: ${({ theme }) => theme.colors.gray[800]};
      }
    }

    .profile-empty-state {
      padding: 20px;
      color: ${({ theme }) => theme.colors.gray[400]};
      border-radius: 6px;
      text-align: center;
      font-size: 14px;
      border: 1px dashed ${({ theme }) => theme.colors.gray[300]};
    }

    .profile-selection-list {
      display: grid;
      grid-gap: 12px;
      align-content: start;
    }

    .btn {
      .spinner {
        svg {
          fill: ${({ theme }) => theme.colors.gray[300]};
        }
      }
    }

    .unavailable-channels-prompt {
      margin-top: 14px;
      display: block;
      text-align: center;
      color: ${({ theme }) => theme.colors.gray[500]};
      font-size: 15px;
      font-weight: 500;
      letter-spacing: -0.005rem;

      a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.green[500]};
      }
    }

    .add-connections-list {
      display: grid;
      grid-gap: 12px;
      align-content: start;
      padding: 0 25px;
      margin: 0 -25px 50px -25px;

      .add-connection-btn {
        background-color: ${({ theme }) => theme.colors.gray[100]};
        justify-content: space-between;
        text-align: left;
        padding: 0 12px;
        height: 50px;
        width: 100%;
        border-radius: 6px;

        .add-connection-btn-details-container {
          display: flex;
          align-items: center;

          svg {
            margin-right: 12px;
            color: ${({ theme }) => theme.colors.gray[600]};
          }

          .add-connection-btn-details {
            display: grid;

            .add-connection-btn-detail-title {
              font-weight: 500;
              color: ${({ theme }) => theme.colors.gray[700]};
              font-size: 15px;
            }

            .add-connection-btn-detail-subtitle {
              font-size: 13px;
              color: ${({ theme }) => theme.colors.gray[500]};
            }
          }
        }

        .plus-icon {
          height: 22px;
          color: ${({ theme }) => theme.colors.gray[500]};

          path {
            stroke-width: 1.75px;
          }
        }

        &:disabled {
          background-color: ${({ theme }) => theme.colors.gray[100]};

          .add-connection-btn-details-container {
            svg {
              color: ${({ theme }) => theme.colors.gray[300]};
            }

            .add-connection-btn-details {
              .add-connection-btn-detail-title {
                color: ${({ theme }) => theme.colors.gray[300]};
              }

              .add-connection-btn-detail-subtitle {
                color: ${({ theme }) => theme.colors.gray[300]};
              }
            }
          }

          .plus-icon {
            color: ${({ theme }) => theme.colors.gray[300]};
          }
        }
      }
    }

    &.main-list {
      .profile-selection-header:not(:first-child) {
        margin-top: 25px;
        padding-top: 25px;
        border-top: 1px solid ${({ theme }) => theme.colors.gray[300]};
      }
    }
  }

  ${({ inModal, theme }) =>
    inModal &&
    `
    max-height: 95vh;
    overflow-y: auto;
    background-color: ${theme.colors.background};
    border-radius: 20px 20px 0 0;
    padding: 25px 25px calc(max(env(safe-area-inset-bottom), 10px) + 25px) 25px;

    .profile-selection {
      .profile-selection-list {
        padding: 0 25px;
        margin: 0 -25px 0 -25px;
      }
      
      .profile-empty-state {
        margin-bottom: 25px;
      }
    }



    @media (min-width: ${`${theme.breakpoints.s}px`}) {
      border-radius: 20px;
      padding: 25px;
      max-width: 400px;
      width: 100vw;
  
      .profile-selection {
        .add-connections-list {
          margin: 0 -25px 0 -25px;
        }
      }
    }
  `}
`

export default StyledConnectionsModal
