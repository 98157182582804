import React, {
  FC, RefObject, useEffect, useState, useMemo
} from 'react'
import * as Sentry from '@sentry/react'
import { times } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { search as searchIssueCaptions } from '../../../graphql/CaptionSet/captionSearchQuery'
import { useWindowSize } from '../../Grid'
import { CAPTION_CATEGORY_LIST, IssuePaginationButton } from '..'
import { StyledIssueContentPagination } from '.'
import { Bubble, PaginationButton } from '../../System'
import LibraryCaption from '../../CaptionLibraryView/LibraryCaption'
import {
  SearchCaptions_captionSetSearch_docs,
  SearchCaptions
} from '../../../graphql/CaptionSet/__generated__/SearchCaptions'
import { GetIssueBySlug_issueFindOne } from '../../../graphql/Issue/__generated__/GetIssueBySlug'
import useSegment from '../../utils/useSegment'

type Props = {
  contentType: 'captions';
  reference?: RefObject<HTMLDivElement>;
  issue: GetIssueBySlug_issueFindOne;
}

const IssueContentPagination: FC<Props> = ({ reference, contentType, issue }) => {
  const { track } = useSegment()
  const apollo = useApolloClient()
  const windowSize = useWindowSize()

  const itemsPerPage = useMemo(() => (windowSize === 'sm' || windowSize === 'xs' ? 6 : 4), [
    windowSize
  ])

  const [categoryList, setCategoryList] = useState(CAPTION_CATEGORY_LIST)
  const [currPage, setCurrPage] = useState(0)
  const [captions, setCaptions] = useState<SearchCaptions_captionSetSearch_docs[]>([])
  const [filteredCaptions, setFilteredCaptions] = useState<SearchCaptions_captionSetSearch_docs[]>(
    []
  )
  const [noData, setNoData] = useState(false)
  const [filters, setFilters] = useState<string[]>([])
  const [pageLength, setPageLength] = useState(0)

  const loadCaptions = async () => {
    try {
      const { data } = await apollo.query<SearchCaptions>({
        query: searchIssueCaptions,
        variables: {
          issue: issue._id,
          items: 50,
          sort: 'index',
          categories: filters,
          page: 0
        }
      })

      const captions = data.captionSetSearch?.docs

      if (!captions?.length) {
        setNoData(true)
        return
      }

      // Extract the caption categories
      const categorySet = new Set(captions.map((caption) => caption.category))
      const categoriesInUse = Array.from(categorySet.keys())

      setCategoryList(categoriesInUse as any)
      setCaptions(captions)
      setFilteredCaptions(captions)
      setPageLength(Math.ceil(captions.length / itemsPerPage))
    }
    catch (err) {
      Sentry.captureException(err)
      console.error(err)
    }
  }

  const changePage = (action: string | number) => {
    if (action == 'next' && currPage < pageLength - 1) setCurrPage(currPage + 1)
    else if (action == 'prev' && currPage > 0) setCurrPage(currPage - 1)
    else setCurrPage(action as number)
  }

  // Filters the captions. If all the filters are removed, simply display all captions
  const filterCaptions = (filter: string) => {
    // If the filters list does not already include the filter, set currFilters
    // equal to the old filters + the new one. If it does include the filter, remove
    // the filter from the list of filters
    const currFilters = !filters.includes(filter)
      ? [...filters, filter]
      : filters.filter((filterName) => filterName !== filter)

    // Track filters applied by the user
    track('Filtered Captions', {
      issue_id: issue._id,
      issue_title: issue.title,
      selected_filters: currFilters,
      from: 'issue'
    })

    // Remove the captions from the filtered list that don't have a category within currFilters
    const filteredCaptions = captions.filter(
      (caption) => caption.category && currFilters.includes(caption.category)
    )

    // If there are no filtered captions (all the filters were removed), set captionsResult to
    // all the filters, if there are caption(s), set captionsResult to the filtered captions
    const captionsResult = filteredCaptions.length ? filteredCaptions : captions

    setFilteredCaptions(captionsResult)
    setPageLength(Math.ceil(captionsResult.length / itemsPerPage))
    setFilters(currFilters)
    setCurrPage(0)
  }

  useEffect(() => {
    loadCaptions()
  }, [issue._id])

  if (noData) {
    return null
  }

  return (
    <StyledIssueContentPagination pageLength={pageLength}>
      <div className="content-pagination-heading" ref={reference}>
        <h2>Recommended {contentType}</h2>
      </div>
      <div className="content-pagination-categories">
        {categoryList.map((category, index) => (
          <Bubble
            key={index}
            label={category}
            onClick={() => filterCaptions(category)}
            isActive={filters.includes(category)}
          />
        ))}
      </div>
      <div className="content-pagination-grid">
        {filteredCaptions.map((caption, index) => {
          if (index >= itemsPerPage * currPage + itemsPerPage || index < itemsPerPage * currPage) {
            return
          }
          return <LibraryCaption key={caption._id} caption={caption} />
        })}
        {currPage > 0 ? (
          <PaginationButton
            className="issue-pagination-button prev"
            direction="left"
            onClick={() => changePage('prev')}
          />
        ) : (
          <span />
        )}
        {currPage < pageLength - 1 && (
          <PaginationButton
            className="issue-pagination-button next"
            direction="right"
            onClick={() => changePage('next')}
          />
        )}
      </div>
      {pageLength > 1 && (
        <div className="content-pagination-bubbles">
          {times(pageLength, (i) => {
            return (
              <IssuePaginationButton
                key={i}
                buttonType="bubble"
                className={i === currPage ? 'active' : ''}
                onClick={() => changePage(i)}
              />
            )
          })}
        </div>
      )}
    </StyledIssueContentPagination>
  )
}

export default IssueContentPagination
