import React, { FC, ReactNode } from 'react'
import { StyledIssuePaginationButton } from '.'

type Props = {
  className?: string;
  onClick?: () => void;
  buttonType: string;
  children?: ReactNode;
}

const IssuePaginationButton: FC<Props> = ({
  className, onClick, buttonType, children
}) => {
  return (
    <StyledIssuePaginationButton className={className} onClick={onClick} buttonType={buttonType}>
      {children}
    </StyledIssuePaginationButton>
  )
}

export default IssuePaginationButton
