import { useState, useEffect } from 'react'
import { throttle } from 'lodash'

const useShowToTopButton = (initialValue: boolean) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    const handleScroll = throttle(() => {
      const scrollOffset = window.pageYOffset
      const scrollBumpOffset = 200 // How many pixels before the end sould it stop
      const pageHeight = window.innerHeight
      const scrollHeight = document.documentElement.scrollHeight - pageHeight
      
      setValue(scrollOffset > pageHeight && scrollOffset !<= scrollHeight - scrollBumpOffset)
    }, 333)

    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleScroll)
    }
  }, [])

  const scrollToPositon = (position: number) => {
    window.scrollTo({ top: position, left: 0, behavior: 'smooth' });
  }

  return [value, scrollToPositon] as const
}

export default useShowToTopButton
