import { gql } from '@apollo/client'

export const storySetFindMany = gql`
  query StorySetFindMany($issue: String!) {
    storySetFindMany(filter: { issue: $issue }, sort: INDEX_ASC) {
      _id
      title
      canvaLink
      description
      index
      thumbnail
      records {
        _id
        fullPath
        imageUrl
        index
      }
    }
  }
`
