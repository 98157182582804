/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext } from 'react'
import * as Sentry from '@sentry/react'
import useUser from './useUser'
import { CombinedUser } from '../User'

type InitialState = {
  user: CombinedUser | null;
  loaded: boolean;
  logout: () => Promise<void>;
  refresh: () => Promise<void>;
}

const initialState: InitialState = {
  user: null,
  loaded: false,
  logout: () => Promise.resolve(),
  refresh: () => Promise.resolve()
}

const userStore = createContext(initialState)
const { Provider } = userStore

const UserPovider = ({ children }) => {
  const {
    user, loaded, logout, refresh
  } = useUser()

  if (user) {
    Sentry.setUser({ email: user?.email, id: user?.id })
  }
  else {
    Sentry.setUser(null)
  }

  return (
    <Provider
      value={{
        user,
        loaded,
        logout,
        refresh
      }}
    >
      {children}
    </Provider>
  )
}

export { userStore, UserPovider, Provider }
