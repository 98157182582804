import React, { FC, useState, useEffect, useContext } from 'react'
import { DownloadIcon } from '@heroicons/react/outline'
import { useApolloClient } from '@apollo/client'
import { StyledDraftShareModal } from '.'
import { Spinner, ResponsiveDrawer } from '../../System'
import useDraft from '../../../redux/draft-drawer/useDraft'
import useSegment from '../../utils/useSegment'
import { handleSlug } from '../../utils/handleSlug'
import { useNotifications } from '../../Notifications'
import { sendDraftNow } from '../../../graphql/UserDraft/sendDraftNow'
import { ScheduleSlide, SocialSelectSlide, ShareNowSlide } from './slides'
import { UpsertUserDraft } from '../../../graphql/UserDraft/__generated__/UpsertUserDraft'
import { upsertUserDraftMutation } from '../../../graphql/UserDraft/upsertUserDraft'
import { userStore } from '../../utils/UserContext'
import useDraftsList from '../../../redux/drafts-list/useDraftsList'
import { UserDraftFindById_userDraftFindById } from '../../../graphql/UserDraft/__generated__/UserDraftFindById'
import { SocialProfileFindMany_socialProfileFindMany } from '../../../graphql/SocialProfile/__generated__/SocialProfileFindMany'

type Props = {
  isOpen?: boolean;
  profiles?: SocialProfileFindMany_socialProfileFindMany[];
  scheduledDate?: Date;
  onClose: () => void;
  onSave: (
    shouldClose?: boolean,
    params?:
      | {
          draftObject: any;
          merge?: boolean | undefined;
        }
      | undefined
  ) => Promise<UserDraftFindById_userDraftFindById>;
}

const DraftShareModal: FC<Props> = ({
  isOpen, profiles, scheduledDate, onClose, onSave
}) => {
  const apollo = useApolloClient()
  const { track } = useSegment()
  const { user } = useContext(userStore)
  const { addNotification } = useNotifications()
  const { actions: { draftUsed } } = useDraftsList()
  const {
    _id,
    title,
    captionText,
    imageUrl,
    imageId,
    fullPath,
    userUpload,
    category,
    challenge,
    template,
    shareModal,
    isLiked,
    isUsed,
    socialProfiles,
    postDate: draftDate,
    actions: { setDraftDate, setDraftSocialProfiles, clearAndCloseDraft }
  } = useDraft()
  const CLOSE_TIMEOUT = 2000
  const [postDate, setPostDate] = useState(scheduledDate || draftDate)
  const [stage, setStage] = useState<'select' | 'schedule' | 'post'>('select')
  const [selectedProfiles, setSelectedProfiles] = useState<
    SocialProfileFindMany_socialProfileFindMany[] | undefined
  >(profiles?.length ? profiles : [])
  const [loadingPost, setLoadingPost] = useState(false)
  const [loadingSchedule, setLoadingSchedule] = useState(false)
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [isPosted, setIsPosted] = useState(false)

  // Downloads the draft's image & copies the draft's caption
  const downloadDraft = async () => {
    setLoadingDownload(true)

    await onSave(false)

    await apollo.mutate<UpsertUserDraft>({
      mutation: upsertUserDraftMutation,
      variables: { record: { _id, user: user?.id, isUsed: true } },
      fetchPolicy: 'no-cache'
    })

    navigator.clipboard.writeText(captionText || '')

    if (!imageUrl) {
      addNotification({
        message: 'Copied caption to clipboard',
        type: 'success'
      })
      return
    }

    const image = await fetch(imageUrl)
    const blob = await image.blob()
    const objectUrl = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = objectUrl
    link.download = `${handleSlug(title || 'untitled')}.png`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    addNotification({
      message: 'Downloaded image & copied caption to clipboard',
      type: 'success'
    })

    draftUsed(_id || '')

    track('Downloaded Draft', {
      _id,
      title,
      category,
      post_date: postDate,
      caption: captionText,
      image_url: imageUrl,
      user_upload: userUpload,
      image_id: imageId,
      full_path: fullPath,
      character_count: captionText?.length,
      is_liked: isLiked,
      is_used: isUsed,
      template,
      challenge,
      connected_socials: socialProfiles?.length
    })

    setLoadingDownload(false)
  }

  const scheduleDraft = async () => {
    if (!postDate) {
      return
    }

    setLoadingSchedule(true)

    const formattedProfiles = selectedProfiles?.map((profile) => profile._id!)
    setDraftDate(postDate)
    setDraftSocialProfiles(formattedProfiles)

    await onSave(true, {
      draftObject: {
        postDate,
        socialProfiles: formattedProfiles
      },
      merge: true
    })

    onClose()
    setLoadingSchedule(false)
  }

  // Publishes this draft directly to the selected social profile
  const handlePost = async () => {
    if (!selectedProfiles?.length) {
      return
    }

    setLoadingPost(true)

    const { _id } = await onSave(false)

    const { data } = await apollo.mutate({
      mutation: sendDraftNow,
      variables: { draft: _id, profiles: selectedProfiles.map((profile) => profile._id) }
    })

    setLoadingPost(false)

    const failures = data?.sendDraftNow?.failures || []

    if (failures.length) {
      return failures.map((failure: string) =>
        addNotification({
          message: failure,
          type: 'error',
          interval: 60000
        }))
    }

    setIsPosted(true)

    selectedProfiles.map((profile) => {
      track('Shared Draft', {
        _id,
        title,
        category,
        postDate,
        caption: captionText,
        imageUrl,
        userUpload,
        imageId,
        fullPath,
        characterCount: captionText?.length,
        to: profile.type,
        method: 'share-modal',
        template,
        challenge,
        platform: 'web'
      })
    })

    // Wait for the animation to finish and give it an extra second
    setTimeout(() => {
      onClose()
      clearAndCloseDraft()
    }, CLOSE_TIMEOUT)
  }

  useEffect(() => {
    setIsPosted(false)
  }, [selectedProfiles])

  useEffect(() => {
    setIsPosted(false)
    setSelectedProfiles([])
    setStage('select')
  }, [isOpen])

  useEffect(() => {
    setPostDate(draftDate)
  }, [draftDate])

  useEffect(() => {
    if (!shareModal?.isRescheduling) {
      return
    }

    setStage('schedule')
  }, [shareModal?.isRescheduling])

  useEffect(() => {
    if (!shareModal?.isRescheduling) {
      return
    }

    setSelectedProfiles(profiles)
  }, [profiles, shareModal?.isRescheduling])

  return (
    <>
      <ResponsiveDrawer isOpen={isOpen || false} onClose={() => onClose()}>
        <StyledDraftShareModal stage={stage} isPosted={isPosted}>
          <div className="share-modal-content">
            <SocialSelectSlide
              selectedProfiles={selectedProfiles}
              setSelectedProfiles={(profiles) => setSelectedProfiles(profiles)}
            />
            <ScheduleSlide postDate={postDate} setPostDate={(date) => setPostDate(date)} />
            <ShareNowSlide selectedProfiles={selectedProfiles} isPosted={isPosted} />
          </div>
          <div className="share-modal-actions">
            {stage === 'select' && (
              <>
                <button onClick={() => onClose()}>
                  <span>Cancel</span>
                </button>
                <div className="share-modal-action-group">
                  <button
                    disabled={loadingDownload}
                    className="download-btn"
                    onClick={() => downloadDraft()}
                  >
                    {!loadingDownload && <DownloadIcon />}
                    {loadingDownload && <Spinner size="small" />}
                  </button>
                  <button className="schedule-btn" onClick={() => setStage('schedule')}>
                    <span>{scheduledDate ? 'Reschedule' : 'Schedule'}</span>
                    <div className="beta-flag">BETA</div>
                  </button>
                  <button
                    disabled={!selectedProfiles?.length}
                    className="publish-btn"
                    onClick={() => setStage('post')}
                  >
                    <span>Post Now</span>
                  </button>
                </div>
              </>
            )}
            {stage === 'schedule' && (
              <>
                <button disabled={loadingPost || isPosted} onClick={() => setStage('select')}>
                  <span>Back</span>
                </button>
                <button
                  disabled={!postDate}
                  className="publish-btn"
                  onClick={() => scheduleDraft()}
                >
                  {!loadingSchedule && (
                    <span>
                      {scheduledDate ? 'Reschedule' : 'Schedule'}{' '}
                      {(selectedProfiles?.length || 0) > 1 && `(${selectedProfiles?.length})`}
                    </span>
                  )}
                  {loadingSchedule && <Spinner theme="light" size="small" />}
                </button>
              </>
            )}
            {stage === 'post' && (
              <>
                <button disabled={loadingPost || isPosted} onClick={() => setStage('select')}>
                  <span>No</span>
                </button>
                <button
                  disabled={loadingPost || isPosted}
                  className="publish-btn"
                  onClick={() => handlePost()}
                >
                  {!loadingPost && <span>Post Now</span>}
                  {loadingPost && <Spinner size="small" />}
                </button>
              </>
            )}
          </div>
        </StyledDraftShareModal>
      </ResponsiveDrawer>
    </>
  )
}

export default DraftShareModal
