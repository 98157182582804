import { DailyActionType } from '../../types'

export type DailyActionFormState = {
  form: DailyActionType;
  loading: boolean;
  success: boolean;
  error: boolean;
  errorMessage: string | null;
  successMessage: string | null;
}

export type UpdateFieldPayloadType = {
  field: keyof DailyActionType;
  value: any;
}
export const DAILY_ACTION_FORM_UPDATE_FIELD = 'DAILY_ACTION_FORM_UPDATE_FIELD'
export type DailyActionFormUpdateFieldAction = {
  type: typeof DAILY_ACTION_FORM_UPDATE_FIELD;
  payload: UpdateFieldPayloadType;
  meta: {
    actionId?: string;
  };
}

export const DAILY_ACTION_FORM_UPDATE_STATE = 'DAILY_ACTION_FORM_UPDATE_STATE'
export type DailyActionFormUpdateStateAction = {
  type: typeof DAILY_ACTION_FORM_UPDATE_STATE;
  payload: Partial<DailyActionType>;
}

export const DAILY_ACTION_FORM_START_LOADING = 'DAILY_ACTION_FORM_START_LOADING'
export type DailyActionFormStartLoadingAction = {
  type: typeof DAILY_ACTION_FORM_START_LOADING;
}

export const DAILY_ACTION_FORM_SUCCESS = 'DAILY_ACTION_FORM_SUCCESS'
export type DailyActionFormSuccessAction = {
  type: typeof DAILY_ACTION_FORM_SUCCESS;
  payload: string;
}

export const DAILY_ACTION_FORM_ERROR = 'DAILY_ACTION_FORM_ERROR'
export type DailyActionFormErrorAction = {
  type: typeof DAILY_ACTION_FORM_ERROR;
  payload: string;
}

export type DailyActionFormActions =
  | DailyActionFormUpdateFieldAction
  | DailyActionFormUpdateStateAction
  | DailyActionFormStartLoadingAction
  | DailyActionFormSuccessAction
  | DailyActionFormErrorAction
