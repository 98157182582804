import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { useApolloClient } from '@apollo/client'
import { gallerySetActiveCollectionAction } from '../../../redux/gallery/actions'
import { parseUrlPathToTab } from '..'
import { galleryCollectionFindByIdQuery } from '../../../graphql/GalleryCollection/galleryCollectionFindById'
import { GalleryCollectionFindById } from '../../../graphql/GalleryCollection/__generated__/GalleryCollectionFindById'
import { AppState } from '../../../redux/root-reducer'
import { GalleryState } from '../../../redux/gallery/types'

const useGalleryCollectionInfo = () => {
  const dispatch = useDispatch()
  const apollo = useApolloClient()

  const { pathname: currentPath } = useLocation()
  const { activeTab } = useSelector<AppState, GalleryState>((state) => state.gallery)

  const getCollectionInfo = async (id) => {
    try {
      const { data } = await apollo.query<GalleryCollectionFindById>({
        query: galleryCollectionFindByIdQuery,
        fetchPolicy: 'no-cache',
        variables: { _id: id }
      })
      dispatch(gallerySetActiveCollectionAction(data.galleryCollectionFindById))
    }
    catch (err) {
      Sentry.captureException(err)
      console.error(err)
    }
  }

  useEffect(() => {
    const galleryTab = parseUrlPathToTab(currentPath, 'tab')

    // don't needlessly update redux state
    if (activeTab === galleryTab) {
      return
    }

    if (!['main', 'favorites'].includes(galleryTab) && galleryTab) {
      getCollectionInfo(galleryTab)
    }
    else {
      dispatch(gallerySetActiveCollectionAction(null))
    }
  }, [currentPath])

  return { getCollectionInfo }
}

export default useGalleryCollectionInfo
