import React, { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ROUTES from '../../utils/routes'
import EditUserRoleAccount from './EditUserRoleAccount'
import { CombinedUser } from '../../User'
import { Tab, TabBar } from '../../System'
import { AdminPageForm } from '../AdminComponents'
import { UserEditTabs, UserCoursesTable } from '.'

type Props = {
  user: CombinedUser;
  change: ({ fieldName, value }) => void;
}

const EditUserRole: FC<Props> = ({ user, change }: Props) => {
  const { tab }: any = useParams()
  const history = useHistory()
  const [activeTab, setActiveTab] = useState<UserEditTabs>((tab as UserEditTabs) ?? 'account')

  useEffect(() => {
    if (user?.id) {
      history.push(`${ROUTES.admin.users.profileById(user?.id)}/${activeTab}`)
    }
  }, [activeTab, user])

  return (
    <AdminPageForm>
      <TabBar>
        <Tab
          label="Account"
          isActive={activeTab === 'account'}
          onClick={() => setActiveTab('account')}
        />
        <Tab
          label="Courses"
          isActive={activeTab === 'user-courses'}
          onClick={() => setActiveTab('user-courses')}
        />
      </TabBar>
      {activeTab === 'account' && <EditUserRoleAccount user={user} change={change} />}
      {activeTab === 'user-courses' && <UserCoursesTable user={user} />}
    </AdminPageForm>
  )
}

export default EditUserRole
