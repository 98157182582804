import { useContext, useState } from 'react'
import { compact, xor } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { getSocialProfilesOfType } from '.'
import { userStore } from './UserContext'
import { useNotifications } from '../Notifications'
import {
  GetSocialProfiles,
  GetSocialProfiles_getSocialProfiles
} from '../../graphql/SocialProfile/__generated__/GetSocialProfiles'
import { SocialProfileFindMany_socialProfileFindMany } from '../../graphql/SocialProfile/__generated__/SocialProfileFindMany'
import { getSocialProfiles } from '../../graphql/SocialProfile/getSocialProfiles'
import { socialProfileRemoveByIdMutation } from '../../graphql/SocialProfile/socialProfileRemoveById'
import { connectSocialProfilesMutation } from '../../graphql/SocialProfile/connectSocialProfiles'

//  ------------------------------------------------------------
//  |  This script handles the connecting your                 |
//  |  social accounts. This same file (save minor, web-only   |
//  |  changes) is also in /app.                               |
//  ------------------------------------------------------------

type SantizedSocialProfileType = Omit<GetSocialProfiles_getSocialProfiles, '__typename'>

export const useSocialConnection = () => {
  const apollo = useApolloClient()
  const { addNotification } = useNotifications()
  const { user, refresh } = useContext(userStore)
  const [loadingConnect, setLoadingConnect] = useState(false)
  const [loadingLogin, setLoadingLogin] = useState(false)
  const [connectionType, setConnectionType] = useState<string>()
  const [availableProfiles, setAvailableProfiles] = useState<SantizedSocialProfileType[]>([])
  const [selectedProfiles, setSelectedProfiles] = useState<SantizedSocialProfileType[]>([])

  const loginToSocial = async (type: 'facebook' | 'instagram' = 'facebook') => {
    try {
      setLoadingLogin(true)
      const { accessToken, userID }: fb.AuthResponse = await new Promise((resolve) => {
        window.FB.login(
          ({ authResponse }) => {
            resolve(authResponse)
          },
          {
            scope:
              'public_profile,pages_show_list,pages_manage_posts,instagram_basic,instagram_content_publish',
            return_scopes: true,
            enable_profile_selector: true
          }
        )
      })

      if (!accessToken) {
        return addNotification({
          message: 'Sorry, something went wrong. Please try again.',
          type: 'error'
        })
      }

      const { data } = await apollo.query<GetSocialProfiles>({
        fetchPolicy: 'no-cache',
        query: getSocialProfiles,
        variables: { accessToken }
      })

      setAvailableProfiles(data.getSocialProfiles.filter((profile) => profile.type === type))
      setSelectedProfiles([])
      setConnectionType(type)
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setLoadingLogin(false)
    }
  }

  // Prompts removal of the designated social account
  const promptDisconnectSocialAccount = async (
    profile?: Omit<SocialProfileFindMany_socialProfileFindMany, '__typename'>
  ) => {
    if (!profile) {
      return
    }

    disconnectSocialAccount(profile)
  }

  // Disconnects the social account
  const disconnectSocialAccount = async (
    profile: Omit<SocialProfileFindMany_socialProfileFindMany, '__typename'>
  ) => {
    try {
      await apollo.mutate({
        mutation: socialProfileRemoveByIdMutation,
        variables: { _id: profile._id }
      })
      await refresh()
      addNotification({
        message: `${profile.username} was successfully disconnected!`,
        type: 'success'
      })
    }
    catch (err) {
      alert(JSON.stringify(err))
      addNotification({
        message: `There was an error disconnecting that account`,
        type: 'error'
      })
    }
    finally {
      setLoadingConnect(false)
    }
  }

  // Connects the social account
  const connectSocialAccounts = async () => {
    try {
      setLoadingConnect(true)

      // Fixed edge-case where Instagram profile had defined _id or isBusiness
      const formattedProfiles = compact(
        selectedProfiles.map((profile) => {
          if (profile.isBusiness !== false && !!profile?.accessToken?.token) {
            return {
              ...profile,
              _id: undefined,
              isBusiness: undefined
            }
          }
        })
      )

      await apollo.mutate({
        mutation: connectSocialProfilesMutation,
        variables: { profiles: formattedProfiles }
      })
      await refresh()
      addNotification({
        message: `You\'ve successfully connected your social account${
          selectedProfiles.length > 1 ? 's' : ''
        }`,
        type: 'success'
      })
      setAvailableProfiles([])
      setSelectedProfiles([])
      setConnectionType(undefined)
    }
    catch (err) {
      alert(JSON.stringify(err))
    }
    finally {
      setLoadingConnect(false)
    }
  }

  // Toggle's a profile as selected
  const toggleProfile = (id: string) => {
    const profile = availableProfiles.find((profile) => profile.profileId === id)!
    setSelectedProfiles(xor(selectedProfiles, [profile]))
  }

  return {
    connectionType,
    loadingConnect,
    loadingLogin,
    selectedProfiles,
    availableProfiles,
    actions: {
      loginToSocial,
      toggleProfile,
      connectSocialAccounts,
      promptDisconnectSocialAccount,
      disconnectSocialAccount,
      setSelectedProfiles,
      setAvailableProfiles
    }
  }
}
