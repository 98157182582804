import styled from 'styled-components'

const StyledMuseTextInput = styled.div`
  background: ${({ theme }) => theme.colors.gray[50]};
  border-radius: 3px;
  border: 1px dashed ${({ theme }) => theme.colors.gray[300]};
  height: 77px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .upload-interface {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 0 1rem;
    display: flex;
    align-items: center;

    .input {
      width: 100%;
      margin-right: 10px;
    }
  }

  .file-interface {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .file-details {
      display: grid;
      grid-gap: 8px;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: -0.02rem;
      color: ${({ theme }) => theme.colors.gray[500]};

      b {
        color: ${({ theme }) => theme.colors.gray[800]};
        font-size: 1rem;
        font-weight: 600;
      }

      code {
        background: #0000000a;
        padding: 2px 6px;
        border-radius: 3px;
        font-weight: 600;
        color: #0000006b;
      }
    }

    .upload-actions {
      display: flex;
      align-items: center;

      .change-btn {
        position: relative;
        
        input {
          position: absolute;
          top: 0;
          left: 0
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          font-size: 0;
          z-index: 1;
          cursor: pointer;
          opacity: 0;
        }
      }

      .btn:not(:first-child) {
        margin-left: 8px;
      }
    }
  }
`

export default StyledMuseTextInput
