import styled from 'styled-components'

const StyledCollectionListItem = styled.div`
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;

  .collection-images {
    position: relative;
    background: ${({ theme }) => theme.colors.gray[200]};
    height: 50px;
    width: 75px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;

    .collection-image-fade {
      background: ${({ theme }) => theme.colors.gray[100]};
      height: 50px;
      width: 65px;
      position: absolute;
      bottom: 5px;
      border-radius: 3px;

      &::after {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        content: '';
        border: 1px solid #0000000f;
        border-radius: 3px;
        pointer-events: none;
      }
    }

    .main-image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      border-radius: 3px;
      background: ${({ theme }) => theme.colors.gray[200]};
      color: ${({ theme }) => theme.colors.gray[300]};

      svg {
        height: 24px;
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 3px;
      }

      &::after {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        content: '';
        border: 1px solid #0000000f;
        border-radius: 3px;
        pointer-events: none;
      }
    }
  }

  .collection-details-container {
    display: flex;
    align-items: center;
  }

  .collection-details {
    display: grid;
    grid-gap: 6px;
  }

  .collection-title {
    font-weight: 600;
    font-size: 15px;
    color: ${({ theme }) => theme.colors.gray[900]};

    em {
      font-style: normal;
      background: ${({ theme }) => theme.colors.green[50]};
      color: ${({ theme }) => theme.colors.green[300]};
      border-radius: 3px;
      padding: 0 3px;
    }
  }

  .collection-photo-count {
    font-weight: 500;
    font-size: 14px;
    color: #616161;
  }
`

export default StyledCollectionListItem
