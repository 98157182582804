import styled, { keyframes } from 'styled-components'

type StyledProps = {
  hasError?: boolean;
  showTimeDropdown?: boolean;
}

const popInAnim = keyframes`
  from {
    opacity: 0;
    transform: translateY(6px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const StyledDateTimeInput = styled.div<StyledProps>`
  position: fixed;
  top: 100px;
  left: 100px;
  background-color: ${({ theme }) => theme.colors.background};
  animation: ${popInAnim} 0.15s ease-in-out;
  z-index: 99999999999999;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 250px;
  user-select: none;

  .time-dropdown {
    position: fixed;
    top: 100px;
    left: 100px;
    z-index: 99999999999999;
    width: 200px;
    transition: 0.2s;
    visibility: ${({ showTimeDropdown }) => (showTimeDropdown ? 'visible' : 'hidden')};
    opacity: ${({ showTimeDropdown }) => (showTimeDropdown ? 1 : 0)};
    background-color: ${({ theme }) => theme.colors.background};
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: grid;
      grid-gap: 2px;
      padding: 4px;
      height: 200px;
      overflow-y: auto;

      li {
        padding: 0 12px;
        height: 34px;
        display: flex;
        align-items: center;
        border-radius: 6px;
        font-weight: 600;
        font-size: 15px;
        position: relative;
        overflow: hidden;
        transition: 0.2s;
        color: ${({ theme }) => theme.colors.gray[600]};

        input {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;

          &:hover {
            cursor: pointer;
          }
        }

        &.active {
          background-color: ${({ theme }) => theme.colors.gray[200]};

          &:hover {
            background-color: ${({ theme }) => theme.colors.gray[200]};
          }
        }

        &:hover {
          cursor: pointer;
          background-color: ${({ theme }) => theme.colors.gray[100]};
        }
      }
    }
  }

  button {
    &:hover {
      cursor: pointer;
    }
  }

  .react-calendar {
    padding: 8px 12px;

    .react-calendar__navigation {
      position: relative;
      height: 38px;
      display: flex;
      justify-content: end;
      align-items: center;
      margin: 0 6px 6px 6px;

      button {
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        padding: 0;
        background-color: ${({ theme }) => theme.colors.background};
        color: ${({ theme }) => theme.colors.gray[400]};
        transition: 0.2s;

        svg {
          height: 20px;
        }

        &:hover {
          background-color: ${({ theme }) => theme.colors.gray[100]};
          color: ${({ theme }) => theme.colors.gray[500]};
        }
      }

      .react-calendar__navigation__arrow {
        width: 24px;
        height: 24px;

        &:last-child {
          margin-right: -7px;
        }
      }

      .react-calendar__navigation__label {
        position: absolute;
        height: 38px;
        left: 0;
        padding: 0 8px;
        margin: 0 -8px;
        pointer-events: none;
        color: ${({ theme }) => theme.colors.gray[600]};
        font-weight: 600;

        &:hover {
          background-color: ${({ theme }) => theme.colors.gray[100]};
          color: ${({ theme }) => theme.colors.gray[600]};
        }
      }
    }

    .react-calendar__viewContainer {
      .react-calendar__month-view {
        .react-calendar__month-view__weekdays {
          .react-calendar__month-view__weekdays__weekday {
            text-align: center;

            abbr {
              text-decoration: none;
              color: ${({ theme }) => theme.colors.gray[500]};
              font-weight: 600;
              font-size: 14px;
            }
          }
        }

        .react-calendar__month-view__days {
          display: grid !important;
          grid-gap: 2px;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
          justify-items: center;
          margin-top: 4px;

          .react-calendar__tile {
            height: 30px;
            width: 30px;
            font-weight: 600;
            font-size: 15px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            background-color: transparent;
            transition: background-color 0.2s;
            color: ${({ theme }) => theme.colors.gray[600]};

            &:hover {
              background-color: ${({ theme }) => theme.colors.gray[100]};
            }

            &.react-calendar__month-view__days__day--neighboringMonth {
              color: ${({ theme }) => theme.colors.gray[400]};

              &:hover {
                background-color: ${({ theme }) => theme.colors.background};
              }
            }

            &.react-calendar__tile--now {
              box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.gray[200]};
            }

            &.react-calendar__tile--active {
              color: ${({ theme }) => theme.colors.green[500]};
              background-color: ${({ theme }) => theme.colors.green[50]};
              box-shadow: none;

              &:hover {
                background-color: ${({ theme }) => theme.colors.green[50]};
              }
            }
          }
        }
      }
    }
  }

  .time-input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px 18px 18px;

    .time-input {
      height: 34px;
      position: relative;
      border-radius: 6px;
      overflow: hidden;
      display: flex;
      align-items: center;

      svg {
        position: absolute;
        height: 20px;
        pointer-events: none;
        left: 6px;
        color: ${({ hasError, theme }) =>
    (hasError ? theme.colors.red[400] : theme.colors.gray[400])};
      }

      input {
        height: 34px;
        border: 0;
        padding: 1px 0 0 30px;
        outline: none;
        transition: 0.2s;
        font-weight: 600;
        width: 100%;
        color: ${({ hasError, theme }) =>
    (hasError ? theme.colors.red[400] : theme.colors.gray[600])};
        font-size: 15px;
        background-color: ${({ hasError, theme }) =>
    (hasError ? theme.colors.red[50] : theme.colors.gray[100])};

        &:hover,
        &:focus {
          background-color: ${({ hasError, theme }) =>
    (hasError ? theme.colors.red[50] : theme.colors.gray[200])};
        }
      }
    }

    .time-meridiem-select {
      height: 34px;
      border-radius: 6px;
      display: flex;
      padding: 4px;
      background-color: ${({ theme }) => theme.colors.gray[100]};
      margin-left: 12px;

      .time-meridiem-select-item {
        position: relative;

        input {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          border-radius: 4px;

          &:checked {
            pointer-events: none;

            & + .time-meridiem-select-item-box {
              background-color: ${({ theme }) => theme.colors.background};
              color: ${({ theme }) => theme.colors.gray[600]};
              box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
            }
          }

          &:not(:checked):hover + .time-meridiem-select-item-box {
            background-color: ${({ theme }) => theme.colors.gray[200]};
          }

          &:hover {
            cursor: pointer;
          }
        }

        .time-meridiem-select-item-box {
          border-radius: 4px;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          padding: 4px 10px;
          font-size: 14px;
          font-weight: 700;
          transition: 0.2s;
          color: ${({ theme }) => theme.colors.gray[500]};
        }

        &:last-child {
          margin-left: 4px;
        }
      }
    }
  }

  .date-time-input-divider {
    height: 1px;
    border: 0;
    width: 100%;
    margin: 0;
    background-color: ${({ theme }) => theme.colors.gray[200]};
  }

  .date-time-input-actions {
    display: flex;
    padding: 18px;

    button {
      padding: 0 10px;
      font-weight: 600;
      user-select: none;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      padding: 0;
      width: 100%;
      text-align: center;
      transition: 0.2s;
      border-radius: 6px;
      background-color: ${({ theme }) => theme.colors.gray[100]};
      color: ${({ theme }) => theme.colors.gray[600]};

      span {
        position: relative;
        top: 1px;
      }

      svg {
        height: 18px;
        margin-right: 8px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[200]};
      }

      &:not(:last-child) {
        margin-right: 12px;
      }

      &:disabled {
        background-color: ${({ theme }) => theme.colors.gray[100]};
        color: ${({ theme }) => theme.colors.gray[400]};

        &:hover {
          cursor: not-allowed;
          background-color: ${({ theme }) => theme.colors.gray[100]};
        }
      }
    }

    .done-btn {
      background-color: ${({ theme }) => theme.colors.green[500]};
      color: ${({ theme }) => theme.colors.background};

      &:hover {
        background-color: ${({ theme }) => theme.colors.green[600]};
      }
    }
  }
`

export default StyledDateTimeInput
