import React, { FC, ReactNode } from 'react'
import SwipeableDrawer from '@bit/mui-org.material-ui.swipeable-drawer'
import { useWindowSize } from '../../utils'
import { Dialog } from '../Dialog'

type Props = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

// This component switches between a bottom-drawer and
// a modal depending on the viewport's width
const ResponsiveDrawer: FC<Props> = ({ children, isOpen, onClose }) => {
  const { breakpoints } = useWindowSize()

  if (!breakpoints.includes('s')) {
    return (
      <SwipeableDrawer
        open={isOpen}
        anchor="bottom"
        disableSwipeToOpen
        transitionDuration={400}
        SlideProps={{
          style: {
            background: 'transparent',
            borderRadius: '20px 20px 0 0'
          }
        }}
        onClose={() => onClose()}
        onOpen={() => {}}
        ModalProps={{ disableEnforceFocus: true }}
      >
        {children}
      </SwipeableDrawer>
    )
  }

  return (
    <Dialog isOpen={isOpen} onClose={() => onClose()}>
      {children}
    </Dialog>
  )
}

export default ResponsiveDrawer
