import { CommunityEventType } from '../../components/Dashboard'

export type CommunityEventFormState = {
  form: CommunityEventType;
  loading: boolean;
  success: boolean;
  error: boolean;
  errorMessage: string | null;
  successMessage: string | null;
}

export type UpdateFieldPayloadType = {
  field: keyof CommunityEventType;
  value: any;
}
export const COMMUNITY_EVENT_FORM_UPDATE_FIELD = 'COMMUNITY_EVENT_FORM_UPDATE_FIELD'
export type CommunityEventFormUpdateFieldAction = {
  type: typeof COMMUNITY_EVENT_FORM_UPDATE_FIELD;
  payload: UpdateFieldPayloadType;
  meta: {
    eventId?: string;
  };
}

export const COMMUNITY_EVENT_FORM_UPDATE_STATE = 'COMMUNITY_EVENT_FORM_UPDATE_STATE'
export type CommunityEventFormUpdateStateAction = {
  type: typeof COMMUNITY_EVENT_FORM_UPDATE_STATE;
  payload: Partial<CommunityEventType>;
}

export const COMMUNITY_EVENT_FORM_START_LOADING = 'COMMUNITY_EVENT_FORM_START_LOADING'
export type CommunityEventFormStartLoadingAction = {
  type: typeof COMMUNITY_EVENT_FORM_START_LOADING;
}

export const COMMUNITY_EVENT_FORM_SUCCESS = 'COMMUNITY_EVENT_FORM_SUCCESS'
export type CommunityEventFormSuccessAction = {
  type: typeof COMMUNITY_EVENT_FORM_SUCCESS;
  payload: string;
}

export const COMMUNITY_EVENT_FORM_ERROR = 'COMMUNITY_EVENT_FORM_ERROR'
export type CommunityEventFormErrorAction = {
  type: typeof COMMUNITY_EVENT_FORM_ERROR;
  payload: string;
}

export type CommunityEventFormActions =
  | CommunityEventFormUpdateFieldAction
  | CommunityEventFormUpdateStateAction
  | CommunityEventFormStartLoadingAction
  | CommunityEventFormSuccessAction
  | CommunityEventFormErrorAction
