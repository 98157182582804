import React, {
  FC, createRef, ReactNode, useEffect, useState
} from 'react'
import { throttle } from 'lodash'
import { StyledFadeRow } from '.'
import { PaginationButton } from '../PaginationButton'

type Props = {
  children?: ReactNode;
  inlineArrows?: boolean;
  hideArrows?: boolean;
}

const FadeRow: FC<Props> = ({ children, inlineArrows, hideArrows }) => {
  const scrollRef = createRef<HTMLDivElement>()
  const parentRef = createRef<HTMLDivElement>()
  const [scrollOffset, setScrollOffset] = useState(0)
  const [scrollWidth, setScrollWidth] = useState(0)

  const handleSize = throttle(() => {
    if (!scrollRef.current) {
      return
    }

    const scrollContainer = scrollRef.current
    setScrollWidth(scrollContainer.scrollWidth - scrollContainer.clientWidth)
  }, 333)

  const handleScroll = throttle(() => {
    if (scrollRef.current) {
      setScrollOffset(scrollRef.current.scrollLeft)
    }
  }, 333)

  // Handles left or right pagination of the row
  const scrollDirection = (direction: 'left' | 'right') => {
    if (!scrollRef.current) {
      return
    }

    const scrollDistance = 400

    if (direction === 'left') {
      return scrollRef.current.scroll({
        left: scrollOffset - scrollDistance,
        behavior: 'smooth'
      })
    }

    return scrollRef.current.scroll({
      left: scrollOffset + scrollDistance,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    handleSize()

    if (!parentRef.current || !scrollRef.current) {
      return
    }

    const resizeObserver = new ResizeObserver(() => {
      handleSize()
    })

    resizeObserver.observe(parentRef.current)
    scrollRef.current?.addEventListener('scroll', handleScroll)

    return () => {
      resizeObserver.disconnect()
      scrollRef.current?.removeEventListener('scroll', handleScroll)
    }
  }, [parentRef, scrollRef])

  return (
    <StyledFadeRow
      className="fade-row"
      inlineArrows={inlineArrows}
      showFadeLeft={scrollOffset > 0 && !hideArrows}
      showFadeRight={scrollOffset !== scrollWidth && !hideArrows}
      ref={parentRef}
    >
      <div className="fade l" />
      <div className="fade r" />
      <div ref={scrollRef} className="scroll-row">
        {children}
      </div>

      {scrollOffset > 0 && !hideArrows && (
        <PaginationButton
          onClick={() => scrollDirection('left')}
          direction="left"
          inlineArrow={inlineArrows}
        />
      )}
      {scrollOffset !== scrollWidth && !hideArrows && (
        <PaginationButton
          onClick={() => scrollDirection('right')}
          direction="right"
          inlineArrow={inlineArrows}
        />
      )}
    </StyledFadeRow>
  )
}

export default FadeRow
