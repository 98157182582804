import React, { FC, ReactNode } from 'react'
import { StyledAdminPageNavContainer } from '.'

type Props = {
  children?: ReactNode;
}

const AdminPageNavContainer: FC<Props> = ({ children }) => {
  return <StyledAdminPageNavContainer>{children}</StyledAdminPageNavContainer>
}

export default AdminPageNavContainer
