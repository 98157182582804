//default index file created by jakeCLI 1.0 12:57:53 GMT+0300 (Eastern European Summer Time)
export { default as AdminUserPage } from './AdminUserPage'
export { default as StyledAdminUserPage } from './AdminUserPage.Styled'
export { default as UserCourseTableRow } from './UserCourseTableRow'
export { default as UserCoursesTable } from './UserCoursesTable'

export type UserEditTabs = 'account' | 'user-courses'

const CURRENT_LOGGED_IN_USER = 'scUserDataObj'
export const setCurrentUserData = (data?: any) => {
  try {
    if (!data) {
      return localStorage.setItem(CURRENT_LOGGED_IN_USER, '')
    }
    const dataStr = typeof data === 'string' ? data : JSON.stringify(data)
    localStorage.setItem(CURRENT_LOGGED_IN_USER, dataStr)
  }
  catch (err) {
    console.error(err)
  }
}

export const getCurrentUserData = () => {
  try {
    const userObj = localStorage.getItem(CURRENT_LOGGED_IN_USER)
    if (userObj) {
      return JSON.parse(userObj)
    }
    return null
  }
  catch (err) {
    console.error(err)
    return null
  }
}
