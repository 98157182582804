import styled from 'styled-components'
import React, { FC, useEffect } from 'react'
import useLoading from '../utils/useLoading'

const LazyLoadComponent: FC = () => {
  const { loading, setLoading } = useLoading()
  useEffect(() => {
    setLoading(true, 500)
  }, [])

  if (!loading) {
    return null
  }
  return (// TODO replace with unified loading spinner
    <StyledLoading>loading...</StyledLoading>
  )
}

const StyledLoading = styled.div`
  display: grid;
  width: 100%;
  height: 300px;
  place-content: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray[500]};
  font-size: 1.4em;
`

export default LazyLoadComponent
