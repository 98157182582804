export type SizeData = {
  width?: number;
  height?: number;
}

const getPath = (url: string, pathToBucket?: string) => {
  const bucket = pathToBucket || 'images'
  const res = url.split('images%2F')
  if (!res || !res[1]) {
    return
  }
  const res2 = res[1].split('?')
  if (!res2 || !res2[0]) {
    return
  }
  return `${bucket}/${res2[0]}`
}

//localhost: http://localhost:5001/thofjuly-aeb65/us-central1/getImageFromPath
const formatFinalUrl = () => {
  const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID
  const regionId = process.env.REACT_APP_FIREBASE_REGION
  let returnString = `${window.location.protocol}//`
  returnString += window.location.hostname
  if (window.location.protocol === 'http:') {
    returnString += `:5001`
    returnString += `/${projectId}/${regionId}`
  }
  return `${returnString}/getImageFromPath`
}

export const linkToSizedImage = (
  url: string | undefined | null,
  sizeData: SizeData,
  pathToBucket?: string
) => {
  if (typeof url !== 'string') {
    return ''
  }
  const filePath = getPath(url, pathToBucket)
  if (!filePath) {
    return url
  }
  let returnPath = `${formatFinalUrl()}?imagePath=${filePath}`
  if (sizeData.height) {
    returnPath += `&height=${sizeData.height}`
  }
  if (sizeData.width) {
    returnPath += `&width=${sizeData.width}`
  }
  return returnPath
}

type SizedImageParams = {
  url: string; // original image
  height: number; // original image height
  width: number; // original image width
  maxSize: number; // max size of biggest side of resized image
}
type SizedImage = {
  url: string; // link to sized image
  height: number; // height of sized image
  width: number; // width of sized image
}
export const genSizedImage = ({
  url, height, width, maxSize
}: SizedImageParams): SizedImage => {
  if (maxSize % 50 !== 0 || maxSize > 1600) {
    throw new Error('maxSize value must be divisible by 50 and less then 1600')
  }

  const biggestSide = Math.max(height, width)
  if (biggestSide <= maxSize) {
    // image doesn't need to be sized
    return { url, height, width }
  }

  const heightIsGreater = height > width
  if (heightIsGreater) {
    const newUrl = linkToSizedImage(url, { height: maxSize })
    const newHeight = maxSize
    const newWidth = maxSize / (height / width)
    return {
      url: newUrl,
      height: Math.round(newHeight),
      width: newWidth
    }
  }
  const newUrl = linkToSizedImage(url, { width: maxSize })
  const newWidth = maxSize
  const newHeight = maxSize / (width / height)
  return {
    url: newUrl,
    height: Math.round(newHeight),
    width: newWidth
  }
}
