import React, { FC } from 'react'
import { SwitchOptionType } from './SwitchOption'
import { StyledSwitch, SwitchOption } from '.'

type Props = {
  value?: string;
  options: SwitchOptionType[];
  onChange: (param?: any) => void;
}

const Switch: FC<Props> = ({ value, options, onChange }) => {
  const handleChange = (val: string) => {
    if (onChange) {
      onChange(val)
    }
  }

  return (
    <StyledSwitch>
      {options.map((option, index) => {
        return (
          <SwitchOption
            option={option}
            key={index}
            isActive={value === option.value}
            onClick={() => handleChange(option.value)}
          />
        )
      })}
    </StyledSwitch>
  )
}

export default Switch
