import styled, { css, keyframes } from 'styled-components'

const checkAnim = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

type StyledProps = {
  isUsed?: boolean;
}

const StyledDraftDrawerHeader = styled.div<StyledProps>`
  padding: 20px 20px 20px 20px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.background};
  z-index: ${({ theme }) => theme.elevations.fixed};

  .draft-header-input-area {
    display: flex;
    align-items: center;

    .use-btn {
      height: 50px;
      width: 50px;
      border-radius: 6px;
      margin-left: 10px;
      flex-shrink: 0;
      transition: 0.2s;
      border: 1px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ isUsed, theme }) => (isUsed ? theme.colors.background : theme.colors.gray[400])};
      border-color: ${({ isUsed, theme }) =>
    (isUsed ? theme.colors.green[500] : theme.colors.gray[100])};
      background-color: ${({ isUsed, theme }) =>
    (isUsed ? theme.colors.green[500] : theme.colors.gray[100])};

      svg {
        height: 24px;
        width: 24px;

        path {
          stroke-width: 1.5px;
          ${({ isUsed }) =>
    isUsed &&
            css`
              stroke-dasharray: 1000;
              stroke-dashoffset: 1000;
              animation: ${checkAnim} 15s linear forwards;
            `}
        }
      }

      &:hover {
        cursor: pointer;
        background-color: ${({ isUsed, theme }) =>
    (isUsed ? theme.colors.green[600] : theme.colors.gray[200])};
      }
    }
  }

  .input {
    width: 100%;

    input {
      height: 50px;
      font-size: 1rem;
    }

    .input-container-border-group {
      .input-container-border {
        border-color: ${({ theme }) => theme.colors.gray[300]};

        &.left {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        &.right {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }

  .draft-header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: -10px -8px -10px -10px;
    padding-bottom: 20px;
    position: relative;

    .btn {
      color: ${({ theme }) => theme.colors.gray[500]};

      &.close-btn {
        width: fit-content;
        letter-spacing: unset;
        text-transform: unset;
        padding-left: 5px;

        svg {
          margin-left: 4px;
        }
      }

      &.recent-drafts-btn {
        padding: 0px 6px 0px 10px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 35px;
        border-radius: 6px;
        background-color: ${({ theme }) => theme.colors.gray[100]};
        color: ${({ theme }) => theme.colors.gray[600]};

        svg {
          margin-left: 10px;
          color: ${({ theme }) => theme.colors.gray[400]};
        }

        &:hover {
          background-color: ${({ theme }) => theme.colors.gray[200]};
        }
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[100]};
      }
    }
  }
`

export default StyledDraftDrawerHeader
