import styled from 'styled-components'

const StyledCloseButton = styled.div`
  .close-button {
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${({ theme }) => theme.colors.gray[100]};
    border: 1px solid ${({ theme }) => theme.colors.gray[200]};
    box-sizing: border-box;
    border-radius: 20px;
    min-height: 40px;
    min-width: 40px;
    margin-left: 25px;

    cursor: pointer;
  }

  svg.x-icon {
    width: 22px;
    height: auto;
    color: ${({ theme }) => theme.colors.gray[600]};
  }
`

export default StyledCloseButton
