import { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import { heicToJpeg } from '.'
import useSegment from './useSegment'
import { getImageDimensions } from '../DraftDrawer'

export type PreviewImage = {
  file: FileList;
  url: string;
  imageX: number;
  imageY: number;
}

// Size limit is in MB
const useFileUpload = (sizeLimit = 5) => {
  const { track } = useSegment()
  const [processing, setProcessing] = useState(false)
  const [progress, setProgress] = useState(0) // percentage out of 100
  const [previewImage, setPreviewImage] = useState<PreviewImage>()
  const storageRef = firebase.storage().ref()
  const validImageTypes = ['image/jpeg', 'image/png', 'image/heic', 'image/heif']

  // Takes a range of numbers and scales values down to fit another range
  // ex: (currentNum = 6, targetMin = 0, targetMax = 100, currentMin = 0, currentMax = 12) would return 50
  const mapBetween = (currentNum, targetMin, targetMax, currentMin, currentMax) => {
    return (
      ((targetMax - targetMin) * (currentNum - currentMin)) / (currentMax - currentMin) + targetMin
    )
  }

  // Create a URL for a selected image so a preview can be shown prior to db upload
  const handlePreviewImage = async (files: FileList | undefined) => {
    if (!files || files.length === 0) {
      setPreviewImage(undefined)
      return
    }

    const file = files[0]
    const heicType = 'image/heic' || 'image/heif'

    let jpegBlob

    // if image type is heic, convert to jpeg blob
    // so it can be displayed by the browser
    if (file.type === heicType) {
      jpegBlob = await heicToJpeg(file)
    }

    const objectUrl = URL.createObjectURL(jpegBlob || file)
    const imageDimensions = await getImageDimensions(objectUrl)

    setPreviewImage({
      file: files,
      url: objectUrl,
      imageX: imageDimensions.x,
      imageY: imageDimensions.y
    })
  }

  // Generate a name for file
  const handleFileName = (original: string) => {
    const random = Math.floor(Math.random() * 9999999)
    return `${random}-${original}`
  }

  // Convert heic files to jpeg blobs
  const handleHeicImage = async (file: File) => {
    const heicTypes = ['image/heic', 'image/heif']
    const isHeic = heicTypes.includes(file.type)

    if (isHeic) {
      return await heicToJpeg(file)
    }

    return file
  }

  // Upload a single file to storage
  const uploadToStorage = async (file: File, onUploadComplete?: Function) => {
    const uniqueFileName = handleFileName(file.name)
    const fileSizeMB = file.size / 1024 / 1024

    // Check file type and assign to folder in bucket
    const isImage = validImageTypes.includes(file.type)
    const bucketFolder = isImage ? 'user-images' : 'user-files'

    // Convert to blob if .heic image
    const processedFile = await handleHeicImage(file)

    // Upload file
    const fileRef = storageRef.child(`${bucketFolder}/${uniqueFileName}`)
    const uploadTask = await fileRef.put(processedFile)
    const uploadTaskRef = uploadTask.ref

    const { fullPath } = uploadTaskRef
    const url = await uploadTaskRef.getDownloadURL()

    if (isImage) {
      track('Uploaded Photo', {
        file_name: file.name,
        file_type: file.type,
        file_size: fileSizeMB
      })
    }

    if (onUploadComplete) {
      onUploadComplete()
    }

    return {
      imageUrl: url,
      fullPath
    }
  }

  // Upload one or more files
  const uploadFiles = async (files: FileList) => {
    if (!files || files.length === 0) {
      throw new Error('No files found.')
    }

    try {
      setProcessing(true)
      setProgress(5)

      // convert FileList into an array
      const filesArray = Array.from(files)

      // check if any of the files exceeed the size limit before uploading
      filesArray.forEach((file) => {
        const fileSize = file.size / 1024 / 1024 // MB
        if (fileSize > sizeLimit) {
          const isImage = validImageTypes.includes(file.type)
          throw `${isImage ? 'Image' : 'File'} is too large. Max file size: ${sizeLimit}MB`
        }
      })

      let filesUploadedCount = 0
      const uploadedFilePromises = filesArray.map((file) =>
        uploadToStorage(file, () => {
          // Callback function iterates number of files uploaded and updates state with new progress
          filesUploadedCount++
          setProgress(mapBetween(filesUploadedCount, 0, 100, 0, filesArray.length))
        }))

      return await Promise.all(uploadedFilePromises)
    }
    catch (err) {
      throw new Error(err)
    }
    finally {
      setProcessing(false)
      setTimeout(() => {
        setProgress(0)
      }, 3000)
    }
  }

  // Delete a single file
  const deleteFile = async (fullPath: string) => {
    try {
      await storageRef.child(fullPath).delete()
    }
    catch (err) {
      throw 'Error when deleting file.'
    }
  }

  useEffect(() => {
    return () => {
      if (previewImage) {
        URL.revokeObjectURL(previewImage.url)
      }
    }
  }, [previewImage])

  return {
    progress,
    previewImage,
    processing,
    actions: {
      setPreviewImage,
      handlePreviewImage,
      uploadFiles,
      deleteFile
    }
  }
}

export default useFileUpload
