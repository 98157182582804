import styled from 'styled-components'

type StyledProps = {
  isLoading?: boolean;
}

const StyledIssueImage = styled.div<StyledProps>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  filter: drop-shadow(0px 2px 4px #00000012);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: contain;
    ${({ isLoading }) => isLoading && 'display: none'}
  }
`
export default StyledIssueImage
