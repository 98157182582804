import React, { FC } from 'react'
import { ActionDialog } from '../../System'

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const QuickDraftOverwriteModal: FC<Props> = ({ isOpen, onClose, onConfirm }) => {
  const handleConfirm = () => {
    onConfirm()
    onClose()
  }

  return (
    <ActionDialog
      type="warning"
      isOpen={isOpen}
      title="You have unsaved changes."
      body="You will lose any unsaved changes if you open up a new draft. Are you sure you want to replace it?"
      confirmButtonText="Replace"
      onClose={() => onClose()}
      onConfirm={() => handleConfirm()}
    />
  )
}

export default QuickDraftOverwriteModal
