import firebase from 'firebase/app'
import {
  ActionsState,
  ActionsActions,
  ACTION_SET_ACTION,
  ACTION_TOGGLE_COMPLETE,
  ACTION_CLEAR_ACTION,
  ACTION_RESET_INDEX,
  ACTIONS_SET_ACTIONS,
  ACTIONS_SET_INDEX,
  ACTIONS_SET_COMPLETED_ALL,
  ACTIONS_TOGGLE_COMPLETED
} from './types'

const initialState: ActionsState = {
  actionsList: [],
  activeAction: {
    id: '',
    description: '',
    category: '',
    caption: '',
    url: '',
    label: '',
    length: '',
    index: 0,
    isUrl: false,
    isCompleted: false,
    loadedAt: firebase.firestore.Timestamp.now()
  },
  currentIndex: 0,
  isCompletedAll: false
}

export const actionReducer = (state = initialState, action: ActionsActions): ActionsState => {
  switch (action.type) {
    // Set active action
    case ACTION_SET_ACTION:
      return {
        ...state,
        activeAction: {
          id: action.payload.id,
          description: action.payload.description,
          category: action.payload.category,
          caption: action.payload.caption,
          url: action.payload.url,
          label: action.payload.label,
          length: action.payload.length,
          isUrl: action.payload.isUrl,
          loadedAt: action.payload.loadedAt,
          index: action.payload.index
            ? action.payload.index
            : state.activeAction.index !== undefined
              ? state.activeAction.index + 1
              : 0,
          isCompleted: action.payload.isCompleted || false
        }
      }
    // Toggle active action as complete
    case ACTION_TOGGLE_COMPLETE:
      const updatedAction = state.activeAction
      updatedAction.isCompleted = !state.activeAction.isCompleted
      return {
        ...state,
        activeAction: updatedAction
      }
    // Clear active action
    case ACTION_CLEAR_ACTION:
      return {
        ...state,
        activeAction: {
          id: '',
          description: '',
          category: '',
          caption: '',
          url: '',
          label: '',
          length: '',
          index: 0,
          isUrl: false,
          isCompleted: false,
          loadedAt: firebase.firestore.Timestamp.now()
        }
      }
    // Reset active action index
    case ACTION_RESET_INDEX:
      const resettedAction = state.activeAction
      resettedAction.index = 0
      return {
        ...state,
        activeAction: resettedAction
      }
    // Set list of daily actions
    case ACTIONS_SET_ACTIONS:
      return {
        ...state,
        actionsList: [...action.payload.actionsList!!],
        currentIndex: action.payload.currentIndex!!,
        isCompletedAll: action.payload.isCompletedAll!!
      }
    // Set index of active action
    case ACTIONS_SET_INDEX:
      return {
        ...state,
        currentIndex: action.payload
      }
    // Toggle action inside actions list as completed
    case ACTIONS_TOGGLE_COMPLETED:
      const { actionsList } = state
      const index = action.payload
      const toggledAction = actionsList[index]

      if (toggledAction.completedAt) {
        toggledAction.completedAt = null
      }
      else {
        toggledAction.completedAt = firebase.firestore.Timestamp.now()
      }

      actionsList[index] = toggledAction

      return {
        ...state,
        actionsList
      }
    // Set state for whether all daily actions have been completed
    case ACTIONS_SET_COMPLETED_ALL:
      return {
        ...state,
        isCompletedAll: action.payload
      }
    default:
      const x: never = action
  }
  return state
}
