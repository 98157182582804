import React, { FC, useContext, useEffect, useState } from 'react'
import { uniq } from 'lodash'
import { Button, ResponsiveDrawer, Spinner } from '../../System'
import { StyledConnectionsSelectModal } from '.'
import { GetSocialProfiles_getSocialProfiles } from '../../../graphql/SocialProfile/__generated__/GetSocialProfiles'
import { ConnectionsSelectItem } from '../../System/ConnectionsSelect'
import { userStore } from '../../utils/UserContext'
import ROUTES from '../../utils/routes'

type Props = {
  isOpen: boolean;
  connectionType?: string;
  selectedProfiles: Omit<GetSocialProfiles_getSocialProfiles, '__typename'>[];
  availableProfiles: Omit<GetSocialProfiles_getSocialProfiles, '__typename'>[];
  isLoading: boolean;
  onToggle: (id: string) => void;
  onConfirm: () => void;
  onClose: () => void;
}

const ConnectionsSelectModal: FC<Props> = ({
  isOpen,
  connectionType,
  selectedProfiles,
  availableProfiles,
  isLoading,
  onToggle,
  onConfirm,
  onClose
}) => {
  const SET_CONNECTION_LIMIT = 3
  const { user } = useContext(userStore)
  const [connectionTotal, setConnectionTotal] = useState(0)

  // Get number of currently connected social profiles of this type
  useEffect(() => {
    if (!connectionType) {
      return
    }

    if (!user?.socialProfiles?.length) {
      return setConnectionTotal(selectedProfiles.length)
    }

    const selectedIds = selectedProfiles.map((profile) => profile.profileId)
    const existingIds = user.socialProfiles
      .filter((profile) => {
        return profile.status !== 'disconnected' && profile.type === connectionType
      })
      .map((profile) => {
        return profile.profileId
      })

    setConnectionTotal(uniq([...selectedIds, ...existingIds]).length)
  }, [selectedProfiles, connectionType, user?.socialProfiles])

  return (
    <ResponsiveDrawer isOpen={isOpen} onClose={() => onClose()}>
      <StyledConnectionsSelectModal>
        <div className="profile-selection">
          <div className="profile-selection-header">
            <h1>Which channels would you like to add?</h1>
            <span>
              {connectionTotal}/{SET_CONNECTION_LIMIT}
            </span>
          </div>
          <div className="profile-selection-list">
            {availableProfiles.map((profile) => {
              const alreadyExists = !!user?.socialProfiles?.find(
                (p) => p.profileId === profile.profileId && p.status === 'active'
              )
              const isSelected = !!selectedProfiles.find(
                (selectedProfile) => selectedProfile.profileId === profile.profileId
              )
              const aboveLimit = selectedProfiles.length >= SET_CONNECTION_LIMIT
              const isNotInstagramBusiness = profile.type === 'instagram' && !profile.isBusiness

              return (
                <ConnectionsSelectItem
                  profile={profile}
                  isSelected={alreadyExists || isSelected}
                  isDisabled={
                    (aboveLimit && !isSelected) || alreadyExists || isNotInstagramBusiness
                  }
                  onChange={() => onToggle(profile.profileId)}
                  key={profile.profileId}
                />
              )
            })}
          </div>
          <Button
            theme="outline"
            type="small"
            className="connect-btn"
            disabled={!selectedProfiles.length}
            onClick={() => onConfirm()}
          >
            {!isLoading && `Connect Account${selectedProfiles.length > 1 ? 's' : ''}`}
            {isLoading && <Spinner size="small" />}
          </Button>
          <span className="unavailable-channels-prompt">
            Seeing unavailable channels?{' '}
            <a href={ROUTES.external.socialLearnWhy} target="_blank" rel="noopener noreferrer">
              Learn Why
            </a>
          </span>
        </div>
      </StyledConnectionsSelectModal>
    </ResponsiveDrawer>
  )
}

export default ConnectionsSelectModal
