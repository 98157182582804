import { gql } from '@apollo/client'

export const galleryImagesLikeFindOneQuery = gql`
  query GalleryImagesLikeFindOne($user: String!, $image: String!) {
    galleryImagesLikeFindOne(filter: { user: $user, image: $image }) {
      _id
      index
      image
      user
      dateLike
    }
  }
`
