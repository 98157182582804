import styled from 'styled-components'

const StyledBulletList = styled.div`
  display: grid;
  grid-gap: 10px;

  .add-btn:not(:first-child) {
    margin-top: 6px;
  }
`

export default StyledBulletList
