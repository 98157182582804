import { gql } from '@apollo/client'

export const searchGalleryQuery = gql`
  query SearchGalleryQuery(
    $search: String!
    $categories: [EnumGalleryImageCategory]!
    $colors: [EnumGalleryImageColors]!
    $style: [EnumGalleryImageStyle]!
    $orientation: EnumGalleryImageOrientation
    $page: Int!
    $items: Int!
    $sort: String!
    $liked: Boolean
    $collection: String
  ) {
    searchGallery(
      search: $search
      categories: $categories
      colors: $colors
      style: $style
      orientation: $orientation
      page: $page
      items: $items
      sort: $sort
      liked: $liked
      collection: $collection
    ) {
      totalDocs
      nextPage
      hasNextPage
      hasPrevPage
      totalPages
      page
      docs {
        _id
        category
        colors
        downloads
        favorites
        file {
          fileHttpLink
          fullPath
          imgSize {
            width
            height
          }
        }
        migrated
        orientation
        recommended
        season
        style
        tags
        updateDate
        uploadDate
        liked {
          _id
          user
          index
          image
          dateLike
        }
        collectionImage {
          _id
          index
          collectionId
          image
          dateAdd
        }
      }
    }
  }
`
