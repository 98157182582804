import React, { FC, useState } from 'react'
import moment from 'moment'
import { TrashIcon, CloudUploadIcon } from '@heroicons/react/outline'
import { MuseVideoType } from '../../../types'
import { Spinner } from '../Spinner'
import { Button } from '../Button'
import { StyledMuseInput } from '.'

type Props = {
  value: MuseVideoType;
  onChange: (videoData: MuseVideoType) => void;
}

const MuseInput: FC<Props> = ({ value, onChange }) => {
  const [loading, setLoading] = useState(false)
  const apiKey = process.env.REACT_APP_MUSE_KEY!

  const uploadVideo = async (files: FileList | null) => {
    if (!files?.length) {
      return
    }

    setLoading(true)

    const formData = new FormData()
    formData.append('file', files[0])
    formData.append('visibility', 'unlisted')

    const fetchConfig = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Key: apiKey
      },
      body: formData
    }

    const response = await fetch('https://muse.ai/api/files/upload', fetchConfig)
    const videoData = (await response.json()) as MuseVideoType

    onChange({
      fid: videoData.fid,
      svid: videoData.svid,
      duration: videoData.duration
    })
    setLoading(false)
  }

  const deleteVideo = async () => {
    const fetchConfig = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Key: apiKey
      }
    }

    setLoading(true)

    await fetch(`https://muse.ai/api/files/delete/${value.fid}`, fetchConfig)

    setLoading(false)
    onChange({
      fid: null,
      svid: null,
      duration: 0
    })
  }

  const changeVideo = async (files: FileList | null) => {
    if (!files?.length) {
      return
    }

    await deleteVideo()
    await uploadVideo(files)
  }

  return (
    <StyledMuseInput>
      {(!value || !value.fid) && !loading && (
        <div className="upload-interface">
          <input type="file" accept="video/mp4" onChange={(e) => uploadVideo(e.target.files)} />
          <Button iconPos="left" theme="outline">
            <CloudUploadIcon /> UPLOAD TO MUSE
          </Button>
        </div>
      )}
      {value && value.fid && !loading && (
        <div className="file-interface">
          <div className="file-details">
            <span>
              <b>Video Uploaded to Muse.AI</b>
            </span>
            <span>
              <code>{value.svid}</code> • {moment.utc(value.duration! * 1000).format('HH:mm:ss')}
            </span>
          </div>
          <div className="upload-actions">
            <Button onClick={() => deleteVideo()} theme="outline" type="large" isIcon>
              <TrashIcon />
            </Button>
            <Button className="change-btn" theme="outline">
              <input type="file" accept="video/mp4" onChange={(e) => changeVideo(e.target.files)} />
              CHANGE
            </Button>
          </div>
        </div>
      )}
      {loading && <Spinner />}
    </StyledMuseInput>
  )
}

export default MuseInput
