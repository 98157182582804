import lodash from 'lodash'
import {
  CAPTIONS_CATEGORY_CHANGE,
  CAPTIONS_FAVORITE_CHANGE,
  CAPTIONS_SEARCH_CHANGE,
  CAPTIONS_SORT_CHANGE,
  CAPTIONS_USED_CHANGE,
  CAPTIONS_PAGE_CHANGE,
  SET_CAPTIONS,
  CAPTIONS_LOAD_SEARCH,
  CaptionActions,
  CaptionLibraryState
} from './types'

const initialState: CaptionLibraryState = {
  captions: [],
  categories: [],
  hideUsed: false,
  loading: false,
  search: '',
  onlyFavorites: false,
  sort: 'newest',
  page: 0
}

export const captionLibraryReducer = (
  state = initialState,
  action: CaptionActions
): CaptionLibraryState => {
  switch (action.type) {
    case CAPTIONS_SEARCH_CHANGE:
      return {
        ...state,
        search: action.payload,
        captions: [],
        page: 0,
        loading: true
      }

    case CAPTIONS_CATEGORY_CHANGE:
      return {
        ...state,
        // remove categories if they exist in the array, add them if they don't
        categories: lodash.xor(state.categories, [action.payload]),
        captions: [],
        page: 0,
        loading: true
      }

    case CAPTIONS_FAVORITE_CHANGE:
      return {
        ...state,
        onlyFavorites: action.payload,
        captions: [],
        page: 0,
        loading: true
      }

    case CAPTIONS_SORT_CHANGE:
      return {
        ...state,
        sort: action.payload,
        captions: [],
        page: 0,
        loading: true
      }

    case CAPTIONS_USED_CHANGE:
      return {
        ...state,
        hideUsed: action.payload,
        captions: [],
        page: 0,
        loading: true
      }

    case CAPTIONS_PAGE_CHANGE:
      return {
        ...state,
        page: action.payload,
        loading: true
      }

    case SET_CAPTIONS:
      return {
        ...state,
        captions: [...state.captions, ...action.payload],
        loading: false
      }

    case CAPTIONS_LOAD_SEARCH:
      return {
        ...state,
        ...action.payload,
        captions: [],
        loading: true
      }

    default:
      return state
  }
}
