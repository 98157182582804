import React, { FC, RefObject } from 'react'
import { StyledIssueContentList, IssuePhoto, IssueStory, IssueExtra } from '.'
import {
  GetIssueAssetsQuery_captionSetFindMany,
  GetIssueAssetsQuery_extraSetFindMany,
  GetIssueAssetsQuery_storySetFindMany
} from '../../../graphql/Issue/__generated__/GetIssueAssetsQuery'
import { GetIssueBySlug_issueFindOne } from '../../../graphql/Issue/__generated__/GetIssueBySlug'

type RecordType =
  | GetIssueAssetsQuery_captionSetFindMany
  | GetIssueAssetsQuery_extraSetFindMany
  | GetIssueAssetsQuery_storySetFindMany

type Props = {
  contentType: 'extras' | 'stories' | 'photos';
  reference?: RefObject<HTMLDivElement>;
  issue: GetIssueBySlug_issueFindOne;
  records: RecordType[];
}

const IssueContentList: FC<Props> = ({
  reference, contentType, records, issue
}) => {
  return (
    <StyledIssueContentList>
      <div ref={reference} className="content-list-heading">
        <h2>Recommended {contentType}</h2>
      </div>
      <div className="content-list-grid">
        {contentType === 'photos' &&
          records.map((photo, index) => {
            return (
              <IssuePhoto
                key={index}
                issue={issue}
                photo={photo as GetIssueAssetsQuery_captionSetFindMany}
              />
            )
          })}
        {contentType === 'stories' &&
          records.map((story, index) => {
            return (
              <IssueStory
                key={index}
                issue={issue}
                story={story as GetIssueAssetsQuery_storySetFindMany}
              />
            )
          })}
        {contentType === 'extras' &&
          records.map((extra, index) => {
            return (
              <IssueExtra
                key={index}
                issue={issue}
                extra={extra as GetIssueAssetsQuery_extraSetFindMany}
              />
            )
          })}
      </div>
    </StyledIssueContentList>
  )
}

export default IssueContentList
