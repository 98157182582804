import styled from 'styled-components'

type StyledProps = {
  direction?: 'ltr' | 'rtl';
  isDisabled?: boolean;
}

const StyledRadio = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  ${({ direction }) => direction === 'rtl' && 'flex-direction: row-reverse;'}
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  width: fit-content;
  height: fit-content;
  flex-shrink: 0;

  .radio-box {
    height: 20px;
    width: 20px;
    position: relative;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    input {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      -webkit-appearance: none;
      margin: 0;
      padding: 0;
      cursor: pointer;
      transition: border-color 0.2s;
      z-index: 1;
      background: ${({ theme }) => theme.colors.background};
      border: 1px solid ${({ theme }) => theme.colors.gray[300]};
      border-radius: 50%;

      &:checked {
        background: ${({ theme }) => theme.colors.gray[900]};
        border-color: ${({ theme }) => theme.colors.gray[900]};
        box-shadow: inset 0 0 0 4px ${({ theme }) => theme.colors.background};
      }

      &:hover:not(:checked):not(:disabled) {
        border-color: ${({ theme }) => theme.colors.gray[500]};
      }

      &:hover:checked:not(:disabled) {
        border-color: ${({ theme }) => theme.colors.gray[700]};
        background: ${({ theme }) => theme.colors.gray[700]};
      }

      &:disabled {
        border-color: ${({ theme }) => theme.colors.gray[100]};
        background: ${({ theme }) => theme.colors.gray[100]};
      }
    }
  }

  label {
    cursor: pointer;
    line-height: 17px;
    padding: ${({ direction }) => (direction === 'rtl' ? `0 10px 0 0` : '0 0 0 10px')};
    user-select: none;
    color: ${({ theme }) => theme.colors.gray[700]};

    svg {
      height: 20px;
      vertical-align: sub;
      margin-left: 3px;
    }

    a {
      font-weight: 700;
      text-decoration: underline;
    }
  }

  & + .radio {
    margin-top: 10px;
  }
`

export default StyledRadio
