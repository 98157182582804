import styled from 'styled-components'

type StyledProps = {
  direction?: 'ltr' | 'rtl';
  isDisabled?: boolean;
}

const StyledToggle = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  ${({ direction }) => direction === 'rtl' && 'flex-direction: row-reverse;'}
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  width: fit-content;
  height: fit-content;
  flex-shrink: 0;

  .toggle-box {
    height: 20px;
    width: 40px;
    position: relative;
    border-radius: 50rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    input {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: ${({ theme }) => theme.colors.background};
      border: 1px solid ${({ theme }) => theme.colors.gray[300]};
      -webkit-appearance: none;
      margin: 0;
      padding: 0;
      cursor: pointer;
      transition: 0.2s;
      z-index: 1;
      border-radius: 50rem;

      &:checked {
        background: ${({ theme }) => theme.colors.green[500]};
        border-color: ${({ theme }) => theme.colors.green[500]};

        & + .toggle-indicator {
          background: ${({ theme }) => theme.colors.background};
          left: 24px;
        }
      }

      &:hover:not(:checked):not(:disabled) {
        border-color: ${({ theme }) => theme.colors.gray[500]};
      }

      &:disabled {
        border-color: ${({ theme }) => theme.colors.gray[100]};
        background: ${({ theme }) => theme.colors.gray[100]};

        & + svg {
          color: ${({ theme }) => theme.colors.gray[300]};
        }
      }
    }

    .toggle-indicator {
      position: absolute;
      height: 12px;
      width: 12px;
      background: ${({ theme }) => theme.colors.gray[300]};
      border-radius: 50%;
      left: 4px;
      z-index: 1;
      pointer-events: none;
      transition: left 0.2s;
    }
  }

  label {
    cursor: pointer;
    line-height: 17px;
    padding: ${({ direction }) => (direction === 'rtl' ? `0 10px 0 0` : '0 0 0 10px')};
    user-select: none;
    color: ${({ theme }) => theme.colors.gray[800]};

    a {
      font-weight: 700;
      text-decoration: underline;
    }
  }
`

export default StyledToggle
