import styled from 'styled-components'

const StyledQuestionMark = styled.button`
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: transparent;
  border: 0;
  cursor: help;
  position: relative;
  text-align: left;
  line-height: 14px;

  svg {
    height: 24px;
    color: ${({ theme }) => theme.colors.gray[300]};
  }

  &:hover {
    .qm-tooltip {
      visibility: visible;
      opacity: 1;
      transform: translateX(-50%) translateY(0px);
    }
  }

  .qm-tooltip {
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    z-index: ${({ theme }) => theme.elevations.tooltip};
    color: ${({ theme }) => theme.colors.gray[100]};
    background-color: ${({ theme }) => theme.colors.gray[700]};
    position: absolute;
    max-width: 200px;
    width: max-content;
    transform: translateX(-50%) translateY(3px);
    left: 50%;
    bottom: calc(100% + 8px);
    border-radius: 3px;
    padding: 6px 12px 10px 12px;
    box-shadow: inset 0 0 0 1px #00000061, 0 2px 3px 0px #0000006b;
    transition: 0.2s;

    span {
      font-size: 12px;
      font-family: ${({ theme }) => theme.fonts.nunito};
    }
  }
`

export default StyledQuestionMark
