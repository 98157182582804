import { gql } from '@apollo/client'

export const extraSetFindMany = gql`
  query ExtraSetFindMany($issue: String!) {
    extraSetFindMany(filter: { issue: $issue }, sort: INDEX_ASC) {
      _id
      description
      contentType
      fileType
      index
      title
      videoLink
      videoPassword
      thumbnail {
        fileHttpLink
        fullPath
      }
      image {
        fileHttpLink
        fullPath
      }
      file {
        fileHttpLink
        fullPath
      }
    }
  }
`
