import React, { FC, useContext, useState } from 'react'
import { xor } from 'lodash'
import { PlusIcon } from '@heroicons/react/outline'
import { StyledSocialSelectSlide } from '.'
import { userStore } from '../../../../utils/UserContext'
import { ConnectionsSelectItem } from '../../../../System/ConnectionsSelect'
import { SocialProfileFindMany_socialProfileFindMany } from '../../../../../graphql/SocialProfile/__generated__/SocialProfileFindMany'
import { ConnectionsModal, ConnectionsSelectModal } from '../../../ConnectionsModal'
import { filterSocialProfiles, useSocialConnection } from '../../../../utils'

type Props = {
  selectedProfiles?: SocialProfileFindMany_socialProfileFindMany[];
  setSelectedProfiles: (profiles: SocialProfileFindMany_socialProfileFindMany[]) => void;
}

const SocialSelectSlide: FC<Props> = ({ selectedProfiles, setSelectedProfiles }) => {
  const {
    loadingConnect,
    loadingLogin,
    availableProfiles,
    selectedProfiles: selectedConnectionProfiles,
    actions: {
      loginToSocial,
      toggleProfile: toggleConnectionProfile,
      connectSocialAccounts,
      setAvailableProfiles,
      setSelectedProfiles: setSelectedConnectionProfiles
    }
  } = useSocialConnection()

  const { user } = useContext(userStore)
  const [showConnectionsModal, setShowConnectionsModal] = useState(false)

  const toggleProfile = (profile: SocialProfileFindMany_socialProfileFindMany) => {
    setSelectedProfiles(xor(selectedProfiles, [profile]))
  }

  return (
    <>
      <StyledSocialSelectSlide className="social-selector">
        <h1>Where Do You Want to Post?</h1>
        <div className="social-list">
          {!!filterSocialProfiles(user?.socialProfiles).length &&
            filterSocialProfiles(user?.socialProfiles).map((profile) => {
              return (
                <ConnectionsSelectItem
                  profile={profile}
                  canDelete={profile.status !== 'active'}
                  isSelected={!!selectedProfiles?.find((p) => p._id === profile._id)}
                  key={profile._id}
                  onReconnect={(type) => loginToSocial(type)}
                  onChange={() => toggleProfile(profile)}
                />
              )
            })}
          {!filterSocialProfiles(user?.socialProfiles).length && (
            <button
              className="connections-empty-state"
              onClick={() => setShowConnectionsModal(true)}
            >
              <span>
                You have no connected social accounts.
                <br />
                <b>Click here</b> to connect an account.
              </span>
            </button>
          )}
          {!!user?.socialProfiles?.length && (
            <button className="add-connection-btn" onClick={() => setShowConnectionsModal(true)}>
              <PlusIcon />
              <span>Connect Social Account</span>
            </button>
          )}
        </div>
      </StyledSocialSelectSlide>
      <ConnectionsModal
        isOpen={showConnectionsModal}
        onClose={() => setShowConnectionsModal(false)}
      />
      <ConnectionsSelectModal
        isOpen={!!availableProfiles.length && !loadingLogin}
        selectedProfiles={selectedConnectionProfiles}
        availableProfiles={availableProfiles}
        isLoading={loadingConnect}
        onToggle={(id) => toggleConnectionProfile(id)}
        onClose={() => {
          setSelectedConnectionProfiles([])
          setAvailableProfiles([])
        }}
        onConfirm={() => connectSocialAccounts()}
      />
    </>
  )
}

export default SocialSelectSlide
