import styled from 'styled-components'

const StyledForm = styled.form`
  .form-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[300]};

    .form-title {
      font-size: 20px;
      font-weight: 800;
    }
  }
`

export default StyledForm
