import {
  GALLERY_FORM_ADD_IMAGE,
  GALLERY_FORM_ERROR_BY_ID,
  GALLERY_FORM_REMOVE_IMAGE_BY_ID,
  GALLERY_FORM_RESET,
  GALLERY_FORM_UPDATE_BY_ID,
  GalleryFormActions,
  GalleryFormState,
  GALLERY_FORM_SET_UPDATE,
  GALLERY_FORM_SUCCESS,
  GALLERY_FORM_LOADING,
  GALLERY_FORM_HIDE_ON_SUCCESS,
  GALLERY_FORM_STOP_LOADING
} from './types'

const initialState: GalleryFormState = {
  images: [],
  loading: false,
  success: false,
  errors: []
}

export const galleryFormReducer = (
  state = initialState,
  action: GalleryFormActions
): GalleryFormState => {
  switch (action.type) {
    case GALLERY_FORM_ADD_IMAGE:
      return {
        ...state,
        images: [...state.images, action.payload]
      }
    case GALLERY_FORM_REMOVE_IMAGE_BY_ID:
      return {
        ...state,
        images: state.images.filter((item) => item._id !== action.payload)
      }
    case GALLERY_FORM_UPDATE_BY_ID:
      return {
        ...state,
        images: state.images.map((item) => {
          if (item._id === action.meta.entryId) {
            return {
              ...item,
              ...action.payload
            }
          }
          return item
        })
      }
    case GALLERY_FORM_ERROR_BY_ID:
      return {
        ...state,
        errors: [
          ...state.errors,
          {
            error: action.payload,
            recordId: action.meta.entryId
          }
        ]
      }
    case GALLERY_FORM_RESET:
      return {
        ...state,
        images: []
      }
    case GALLERY_FORM_SET_UPDATE:
      return {
        ...state,
        images: [action.payload]
      }
    case GALLERY_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true
      }
    case GALLERY_FORM_LOADING:
      return {
        ...state,
        loading: true,
        success: false,
        errors: []
      }
    case GALLERY_FORM_HIDE_ON_SUCCESS:
      return {
        ...state,
        images: state.images.filter((item) => item._id !== action.payload.entryId)
      }
    case GALLERY_FORM_STOP_LOADING:
      return {
        ...state,
        loading: false,
        success: false
      }
    default:
      // eslint-disable-next-line no-case-declarations,@typescript-eslint/no-unused-vars
      const x: never = action
  }
  return state
}
