import React, { FC, FormEvent, createRef, InputHTMLAttributes } from 'react'
import { Dialog } from '../..'
import { StyledInputDialog } from '.'

type Props = {
  value: string;
  title: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  isOpen: boolean;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  onChange: (value: string) => void;
  onClose: () => void;
  onConfirm: () => void;
}

const InputDialog: FC<Props> = ({
  value,
  title,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Save',
  onChange,
  onConfirm,
  inputProps,
  ...rest
}) => {
  const dialogRef = createRef<HTMLDivElement>()

  const handleConfirm = (e: FormEvent) => {
    // Extends the Dialog component's close method
    const dialog = dialogRef.current as any

    e.preventDefault()
    dialog?.close()
    onConfirm()
  }

  return (
    <Dialog ref={dialogRef} {...rest}>
      <StyledInputDialog onSubmit={(e) => handleConfirm(e)}>
        <div className="dialog-header">
          <span className="dialog-title">{title}</span>
        </div>
        <div className="dialog-input-area">
          <input
            value={value}
            maxLength={120}
            autoFocus
            onChange={(e) => onChange(e.target.value)}
            {...inputProps}
          />
        </div>
        <div className="dialog-actions">
          <button
            className="cancel-btn"
            type="button"
            onClick={() => (dialogRef.current as any)?.close()}
          >
            <span>{cancelButtonText}</span>
          </button>
          <button disabled={!value.trim().length} className="confirm-btn" type="submit">
            <span>{confirmButtonText}</span>
          </button>
        </div>
      </StyledInputDialog>
    </Dialog>
  )
}

export default InputDialog
