import {
  Notification,
  NOTIFICATION_ADD,
  NOTIFICATION_REMOVE,
  NOTIFICATION_REMOVE_ALL,
  NotificationActions
} from './types'


export const notificationAddAction = (payload: Notification): NotificationActions => ({
  type: NOTIFICATION_ADD,
  payload,
})

export const notificationRemoveAction = (payload: string): NotificationActions => ({
  type: NOTIFICATION_REMOVE,
  payload,
})

export const notificationRemoveAllAction = (): NotificationActions => ({
  type: NOTIFICATION_REMOVE_ALL,
})
