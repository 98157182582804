/* eslint-disable @typescript-eslint/no-empty-function */
import * as Sentry from '@sentry/react'
import { useApolloClient } from '@apollo/client'
import { useEffect, useContext, useState, useRef } from 'react'
import isEmpty from 'lodash/isEmpty'
import firebase from 'firebase/app'
import { User, CombinedUser, FirebaseUser } from '../User'
import { setCurrentUserData, getCurrentUserData } from '../Admin/AdminUserPage'
import { setCookie, removeCookie } from './cookies'
import useSegment from './useSegment'
import { algoliaStore } from '../Algolia'
import { socialProfileFindMany } from '../../graphql/SocialProfile/socialProfileFindMany'
import { SocialProfileFindMany } from '../../graphql/SocialProfile/__generated__/SocialProfileFindMany'

const syncUserData = getCurrentUserData() || {}

const useUser = () => {
  const apollo = useApolloClient()

  const { cleanUp: algoliaCleanUp, forceUpdate: updateAlgoliaKeys } = useContext(algoliaStore)
  const { identify } = useSegment()

  const [user, setUser] = useState<CombinedUser | null>(syncUserData)
  const [loaded, setLoaded] = useState<boolean>(!isEmpty(syncUserData))

  const userListenerUnmount = useRef<Function>(() => {})

  const unsubscribeFromUserDoc = async () => {
    if (userListenerUnmount.current) {
      await userListenerUnmount.current()
    }
  }

  const logout = async () => {
    algoliaCleanUp()
    await unsubscribeFromUserDoc()
    setCurrentUserData('')
    removeCookie('userId')
    removeCookie('jwt')
    apollo.resetStore()
    window.Intercom('shutdown')
    return await firebase.auth().signOut()
  }

  const listenToUserDocChanges = (userProfile: FirebaseUser) => {
    try {
      return firebase
        .firestore()
        .collection('users')
        .doc(userProfile.uid)
        .onSnapshot(async (snap) => {
          const userObj = { id: snap.id, ...snap.data() } as User
          if (!snap.exists) {
            return setUser(null)
          }
          const tokenResult = await firebase.auth().currentUser?.getIdTokenResult()

          setCookie('userId', userProfile.uid)
          setCookie('jwt', tokenResult?.token || '')

          const userObject = {
            email: userProfile.email,
            displayName: userProfile.displayName,
            photoURL: userProfile.photoURL,
            ...userObj,
            socialProfiles: await getSocialProfiles(userObj.id)
          } as CombinedUser
          setUser(userObject)
          setCurrentUserData(userObject)
          setLoaded(true)
        })
    }
    catch (err) {
      Sentry.captureException(err)
    }
  }

  const getUserByEmail = async (email: string) => {
    const snap = await firebase
      .firestore()
      .collection('users')
      .where('email', '==', email)
      .get()
    const doc = snap.docs[0]
    if (!doc) {
      throw new Error('user not found')
    }
    const userObj = {
      id: doc.id,
      ...doc.data(),
      socialProfiles: await getSocialProfiles(doc.id)
    } as User
    setUser(userObj as CombinedUser)
    setLoaded(true)
  }

  const getSocialProfiles = async (user: string) => {
    const { data } = await apollo.query<SocialProfileFindMany>({
      fetchPolicy: 'no-cache',
      query: socialProfileFindMany,
      variables: { user }
    })

    return data.socialProfileFindMany
  }

  const refresh = async () => {
    const userProfile = firebase.auth().currentUser

    if (!userProfile) {
      return
    }

    const userDoc = await firebase
      .firestore()
      .collection('users')
      .doc(userProfile.uid)
      .get()

    const userData = userDoc.data()
    const { token } = await userProfile.getIdTokenResult()

    setCookie('userId', userDoc.id)
    setCookie('jwt', token)

    setUser({
      id: userDoc.id,
      email: userProfile.email,
      displayName: userProfile.displayName,
      photoURL: userProfile.photoURL,
      ...userData,
      socialProfiles: await getSocialProfiles(userDoc.id)
    } as CombinedUser)
    setLoaded(true)
  }

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(async (newUser) => {
      const isLoggedOut = newUser === null
      updateAlgoliaKeys(isLoggedOut)

      // if cached user is not equal to actual user or user ID is not cached
      const differentCachedUser = newUser?.email !== syncUserData?.email
      if (!syncUserData?.id || differentCachedUser) {
        setUser(null)
        setLoaded(false)
      }

      if (!newUser) {
        setLoaded(true)
        return setUser(null)
      }
      unsubscribeFromUserDoc()
      userListenerUnmount.current = listenToUserDocChanges(newUser as FirebaseUser) as Function
    })
  }, [])

  useEffect(() => {
    if (user) {
      identify({ id: user?.id, user: user as CombinedUser })
    }
  }, [user])

  return {
    user,
    loaded,
    logout,
    refresh
  }
}

export default useUser
