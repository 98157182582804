import React, { FC, useEffect, useState } from 'react'
import {
  AnswerView,
  OnboardingAnswer,
  OnboardingQuestion,
  OnboardingState,
  StyledQuestionBox
} from '.'
import { Bubble } from '../System'

type Props = {
  question: OnboardingQuestion;
  formState: OnboardingState;
  setFormState: (key: string, val: string) => void;
}

const QuestionBox: FC<Props> = ({ question, formState, setFormState }) => {
  const [textInput, setTextInput] = useState(false)

  useEffect(() => {
    setTextInput(formState[question.id] === 'Other')
  }, [question, formState])

  return (
    <StyledQuestionBox>
      <div className="statement-wrap">
        <p className="subtitle">{question.subtitle}</p>
        <AnswerView
          question={question}
          formState={formState}
          setFormState={setFormState}
          textInput={textInput}
        />
      </div>
      {question.type === 'bubble' && (
        <div className="bubble-wrap">
          {question.answers.map((answer: OnboardingAnswer) => (
            <>
              <Bubble
                key={answer.value}
                label={answer.text}
                emoji={answer?.emoji}
                onClick={() => {
                  setFormState(question.id, answer.value)
                }}
                isActive={formState[question.id] === answer.value}
                className="bubble"
              />
              <br />
            </>
          ))}
        </div>
      )}
      {question.type === 'box' && (
        <div className="answers-wrap">
          {question.answers.map((answer: OnboardingAnswer) => (
            <div
              key={answer.value}
              className={`answer-box ${formState[question.id] === answer.value ? 'active' : ''}`}
              onClick={() => {
                setFormState(question.id, answer.value)
              }}
            >
              <p>{answer.text}</p>
            </div>
          ))}
        </div>
      )}
    </StyledQuestionBox>
  )
}

export default QuestionBox
