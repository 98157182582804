import React, { FC, ReactNode } from 'react'
import classNames from 'classnames'
import { StyledHeading } from '.'

type Props = {
  type?: 'h1' | 'h2' | 'h3';
  className?: string;
  children?: ReactNode;
}

const Heading: FC<Props> = ({ type = 'h1', className, children }) => {
  return (
    <StyledHeading type={type} className={classNames(className)} as={type}>
      {children}
    </StyledHeading>
  )
}

export default Heading
