import React, { FC, createRef, useState, useEffect } from 'react'
import { throttle } from 'lodash'
import { XIcon, ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/outline'
import { Bubble } from '../Bubble'
import { Button } from '../Button'
import { StyledFilterGroup } from '.'

type Props = {
  filters: string[];
  activeFilters: string[];
  onFilter: (filter: string) => void;
  clearFilters?: () => void;
}

const FilterGroup: FC<Props> = ({
  filters, activeFilters, onFilter, clearFilters
}) => {
  const scrollRef = createRef<HTMLDivElement>()
  const parentRef = createRef<HTMLDivElement>()
  const safeArea = 10

  const [scrollOffset, setScrollOffset] = useState(0)
  const [scrollWidth, setScrollWidth] = useState(0)

  const handleSize = throttle(() => {
    if (!scrollRef.current) {
      return
    }

    const scrollContainer = scrollRef.current
    setScrollWidth(scrollContainer.scrollWidth - scrollContainer.clientWidth)
  }, 333)

  const handleScroll = throttle(() => {
    if (scrollRef.current) {
      setScrollOffset(scrollRef.current.scrollLeft)
    }
  }, 333)

  // Handles left or right pagination of the row
  const scrollDirection = (direction: 'left' | 'right') => {
    if (!scrollRef.current) {
      return
    }

    const scrollDistance = scrollRef.current.clientWidth / 2

    if (direction === 'left') {
      return scrollRef.current.scroll({
        left: scrollOffset - scrollDistance,
        behavior: 'smooth'
      })
    }

    return scrollRef.current.scroll({
      left: scrollOffset + scrollDistance,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    handleSize()

    if (!parentRef.current || !scrollRef.current) {
      return
    }

    const resizeObserver = new ResizeObserver(() => {
      handleSize()
    })

    resizeObserver.observe(parentRef.current)
    scrollRef.current?.addEventListener('scroll', handleScroll)

    return () => {
      resizeObserver.disconnect()
      scrollRef.current?.removeEventListener('scroll', handleScroll)
    }
  }, [scrollRef])

  return (
    <StyledFilterGroup
      className="filter-group"
      hasActiveFilters={activeFilters.length > 0}
      showFadeLeft={scrollOffset > 0}
      showFadeRight={scrollOffset < scrollWidth - safeArea}
      ref={parentRef}
    >
      {scrollOffset > 0 && (
        <div className="scroll-actions">
          <Button theme="outline" isIcon onClick={() => scrollDirection('left')}>
            <ChevronLeftIcon />
          </Button>
        </div>
      )}
      <div className="scroll-row" ref={scrollRef}>
        {filters.map((filter, index) => {
          return (
            <Bubble
              label={filter}
              noMargin
              key={index}
              className="filter-btn"
              isActive={activeFilters.includes(filter)}
              onClick={() => onFilter(filter)}
            />
          )
        })}
      </div>
      {(scrollOffset < scrollWidth - safeArea || activeFilters.length > 0) && (
        <div className="scroll-actions">
          {activeFilters.length > 0 && !!clearFilters && (
            <Button theme="ghost" isIcon onClick={() => clearFilters()}>
              <XIcon />
            </Button>
          )}
          {scrollOffset < scrollWidth - safeArea && (
            <Button theme="outline" isIcon onClick={() => scrollDirection('right')}>
              <ChevronRightIcon />
            </Button>
          )}
        </div>
      )}
    </StyledFilterGroup>
  )
}

export default FilterGroup
