import React, { FC, useState, useContext } from 'react'
import firebase from 'firebase/app'
import { useHistory } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import ROUTES from '../../utils/routes'
import { CombinedUser } from '../../User'
import { StyledDangerLink } from './AdminUserPage.Styled'
import { ReactComponent as StripeIcon } from '../../icons/admin/stripe.svg'
import {
  Checkbox, Dropdown, Grid, LabelWrapper, LineInput, Row, Button
} from '../../System'
import { FlexGroup } from '../../Grid'
import { StyledFormActions } from '../AdminComponents/AdminFormLayout'
import { userStore } from '../../utils/UserContext'
import DeleteUserModal from './DeleteUserModal'
import { deleteUserAccountMutation } from '../../../graphql/User/deleteUserAccount'
import { useNotifications } from '../../Notifications'

type Props = {
  user: CombinedUser;
  change: ({ fieldName, value }) => void;
}

const EditUserRoleAccount: FC<Props> = ({ user, change }: Props) => {
  const history = useHistory()
  const apollo = useApolloClient()
  const { refresh, logout } = useContext(userStore)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const isTrialUser = user?.stripeSubscriptionStatus === 'Active' && user?.trial
  const loginAsUser = firebase.functions().httpsCallable('loginAsUser')
  const { addNotification } = useNotifications()

  const handleLoginAsUser = async () => {
    if (!user?.id) {
      return
    }

    const { data } = await loginAsUser({ uid: user.id })

    window.location.href = `http://app.socialcurator.com/admin/user/login?token=${data}`

    // await logout()
    // await refresh()

    // await firebase.auth().signInWithCustomToken(token.data)
    // await refresh()
    // history.push(ROUTES.dashboard.home)
  }

  const handleClickStripe = () => {
    if (!user.stripeCustomerId) {
      return
    }
    window.open(`https://dashboard.stripe.com/customers/${user.stripeCustomerId}`)
  }

  const chooseSubscriptionInterval = (interval: string) => {
    switch (interval) {
      case 'Month':
        return 'Monthly'
      case 'Year':
        return 'Annual'
      default:
        return 'None'
    }
  }

  const handleDeleteUser = async () => {
    try {
      await apollo.mutate({
        mutation: deleteUserAccountMutation,
        fetchPolicy: 'no-cache',
        variables: { userId: user.id }
      })
      addNotification({ message: 'Successfully deleted user.', interval: 7000, type: 'success' })
      history.push(ROUTES.admin.users.list)
    }
    catch (err) {
      addNotification({ message: err.message, interval: 7000, type: 'error' })
    }
  }

  return (
    <Grid gap={16} inLayout>
      <div className="row-header">
        <span>Personal Information</span>
        <FlexGroup>
          <StyledDangerLink onClick={() => setShowDeleteModal(true)}>
            Delete Account
          </StyledDangerLink>
        </FlexGroup>
      </div>

      <Row columns={3}>
        <LabelWrapper label="Full Name">
          <LineInput
            value={user?.displayName}
            onChange={(e) => change({ fieldName: 'displayName', value: e.target.value })}
          />
        </LabelWrapper>
        <LabelWrapper label="Email">
          <LineInput
            value={user?.email}
            onChange={(e) => change({ fieldName: 'email', value: e.target.value })}
          />
        </LabelWrapper>
        <LabelWrapper label="Role">
          <Dropdown
            value={user?.role}
            options={[
              { label: 'Admin', value: 'admin' },
              { label: 'Basic User', value: 'basic' }
            ]}
            isClearable={false}
            onChange={(e) => change({ fieldName: '_role', value: e.value })}
          />
        </LabelWrapper>
      </Row>
      <Row columns={2}>
        <LabelWrapper label="Subscription Status">
          <LineInput value={user.stripeSubscriptionStatus} isDisabled />
        </LabelWrapper>
        <LabelWrapper label="Subscription Interval">
          <LineInput
            value={chooseSubscriptionInterval(user.subscriptionInterval as string)}
            isDisabled
          />
        </LabelWrapper>
      </Row>
      <Row columns={2}>
        <div>
          <Checkbox
            label="User is a Beta User"
            name="user-is-beta"
            isChecked={user?.beta}
            onChange={(e) => change({ fieldName: 'beta', value: e.target.checked })}
          />
          <br />
          <Checkbox
            label="User is a Whale"
            name="user-is-whale"
            isChecked={user?.isWhale}
            onChange={(e) => change({ fieldName: 'isWhale', value: e.target.checked })}
          />
          <br />
          <Checkbox
            label={isTrialUser ? 'User is a Trial User' : 'User previously trialed'}
            name="user-has-trialed"
            isChecked={user?.hasTrialed}
            isDisabled={isTrialUser}
            onChange={(e) => change({ fieldName: 'hasTrialed', value: e.target.checked })}
          />
        </div>
        {!user?.free && (
          <LabelWrapper label="Stripe Customer ID">
            <LineInput
              value={user?.stripeCustomerId}
              isDisabled
              onChange={(e) =>
                change({ fieldName: 'stripeCustomerId', value: e.target.value.trim() })}
            />
          </LabelWrapper>
        )}
      </Row>

      <div className="row-header">
        <span>External Accounts</span>
        <StyledFormActions>
          {user?.role !== 'admin' && (
            <a className="loginAsUser" onClick={handleLoginAsUser}>
              Log in as user (beta)
            </a>
          )}
        </StyledFormActions>
      </div>

      <Row columns={2} isFlex>
        <StripeIcon />
        {user?.stripeCustomerId && (
          <Button type="small" theme="outline" onClick={() => handleClickStripe()}>
            View Stripe Profile
          </Button>
        )}
        {!user?.stripeCustomerId && <>No customer found</>}
      </Row>

      <DeleteUserModal
        user={user}
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteUser}
      />
    </Grid>
  )
}

export default EditUserRoleAccount
