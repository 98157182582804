import { Moment } from 'moment'

export type SaleUserType = 'Year' | 'Month' | 'Forever' | 'Course' | 'Trial'

export type PricingType = {
  id: string;
  price: number;
  interval: string;
  sale?: {
    id: string;
    value: number;
    valueAfterDiscount: number;
    startDate?: Moment;
    endDate?: Moment;
    users?: SaleUserType[];
  };
}

export type StripePriceType = {
  id: string;
  active?: boolean;
  livemode: boolean;
  metadata: {
    [key: string]: string | number;
  };
  product?: string;
  unit_amount: number;
}

export type StripeCouponType = {
  id: string;
  active?: boolean;
  livemode: boolean;
  metadata: {
    [key: string]: string | number;
  };
  product?: string;
  amount_off: number;
}

export type PricingState = PricingType[]

export const PRICING_ADD_ITEM = 'PRICING_ADD_ITEM'
export type PricingAddAction = {
  type: typeof PRICING_ADD_ITEM;
  payload: PricingType[];
}

export type PricingActions = PricingAddAction
