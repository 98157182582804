import React, { FC, ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import { ToTopButton } from '..'
import { StyledPageLayout } from '.'

type Props = {
  pageTitle?: string;
  maxWidth?: number;
  children?: ReactNode;
}

const PageLayout: FC<Props> = ({ pageTitle, maxWidth, children }) => {
  return (
    <StyledPageLayout maxWidth={maxWidth}>
      <Helmet title={pageTitle} />
      <div className="page">{children}</div>
      <ToTopButton />
    </StyledPageLayout>
  )
}

export default PageLayout
