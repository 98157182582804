import React, {
  createContext, ReactNode, useEffect, useRef, useContext, useCallback, useState
} from 'react'
import * as Sentry from '@sentry/react'
import algoliasearch, { SearchClient } from 'algoliasearch/lite'
import {
  AlgoliaKeys, ContextInitialState, getFromLocalStorage,
  LS_KEY_ALGOLIA, AlgoliaStoreState, algoliaInitialState, AlgoliaInstances,
  ALGOLIA_NEEDS_UPDATE, ALGOLIA_CHECK_KEYS_INTERVAL, saveToLocalStorage,
  algoliaEmptyValue, allIndicesForUsers, ALGOLIA_PREFIX
} from '.'
import firebase from 'firebase/app'

const initialState: ContextInitialState = {
  isAlgoliaReady: false,
  getAlgoliaInstance: () => {
    throw new Error('Algolia is not ready yet')
  },
  cleanUp: null,
  forceUpdate: null,
}

const algoliaStore = createContext(initialState)
const { Provider } = algoliaStore

const AlgoliaProvider = ({ children }: { children: ReactNode }) => {
  
  const generateRestrictedKeys = firebase.functions().httpsCallable('generateRestrictedKeys')
  const isUpdating = useRef(false)
  const userIsLoggedOut = useRef(false)

  const algoliaInstances = useRef<AlgoliaInstances>(algoliaInitialState)
  const [valid, setValid] = useState(false)

  const calcExpiresIn = (expiresAt: number): number => {
    const now = Math.floor(Date.now() / 1000)
    return expiresAt - now
  }

  /**
   *  Make sure that cached api key contains all the needed indexes.
   *  If not, consider cached key as outdated and generate a new key
   */
  const validateAlgoliaKeys = (data: AlgoliaStoreState): boolean => {
    if (!data?.keys) {
      return false
    }
    const decodedKeys = Object.values(data.keys).map((key) => {
      const decodeBase64 = atob(key as string)
      const decodeUri = decodeURI(decodeBase64)
      return decodeUri
    }).join('')
    return allIndicesForUsers.every((index) => {
      return decodedKeys.includes(`${ALGOLIA_PREFIX}_${index}`)
    })
  }


  /**
   * Try to get algolia data from localstorage. If data exists and not expired,
   * we use it as an initial state value
   */
  const getKeysFromLS = (): AlgoliaStoreState => {
    const algoliaData = getFromLocalStorage(LS_KEY_ALGOLIA) as AlgoliaStoreState
    const isValid = validateAlgoliaKeys(algoliaData)
    if (!isValid || !algoliaData?.validUntil || !algoliaData.keys) {
      return algoliaEmptyValue
    }
    const { validUntil } = algoliaData
    const considerAsNotExpired = calcExpiresIn(validUntil) > 60
    if (considerAsNotExpired) {
      return algoliaData
    }
    return algoliaEmptyValue
  }

  const algoliaState = useRef<AlgoliaStoreState>(getKeysFromLS())


  const generateKeys = async () => {
    if (isUpdating.current || userIsLoggedOut.current) {
      return // avoid concurrent requests and don't get keys for guests
    }
    isUpdating.current = true
    try {
      const { data } = await generateRestrictedKeys() as {data: AlgoliaStoreState}
      const algoliaData = {
        keys: data.keys,
        validUntil: data.validUntil,
        isValid: true
      }
      setValid(true)
      algoliaState.current = algoliaData // set keys to local state
      algoliaInstances.current = algoliaInitialState
      // reset algoliaInstances because keys were refreshed
      saveToLocalStorage(LS_KEY_ALGOLIA, algoliaData)
      // save keys to local storage
    }
    catch (err) {
      const guestError = err.code === 'permission-denied'
      if (guestError) {
        userIsLoggedOut.current = true
      }
      Sentry.captureException(err)
    }
    finally {
      isUpdating.current = false
    }
  }

  const handleKeysUpdate = () => {
    const { isValid, validUntil } = algoliaState.current
    setValid(isValid)
    if (!isValid || !validUntil) { // we don't have keys at all
      generateKeys()
      return
    }
    const expiresIn = calcExpiresIn(validUntil)
    const keysAreExpired = expiresIn < ALGOLIA_NEEDS_UPDATE
    if (keysAreExpired) {
      generateKeys()
    }
  }

  useEffect(() => {
    const interval = setInterval(handleKeysUpdate, ALGOLIA_CHECK_KEYS_INTERVAL)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const getAlgoliaInstance = useCallback((algoliaInstanceKey: AlgoliaKeys): SearchClient => {
    const { isValid, keys } = algoliaState.current
    if (!isValid) {
      throw new Error(`
        Algolia keys are not loaded yet.
        Please use "isAlgoliaReady" as an indicator to tell whether Algolia is loaded.
      `)
    }
    if (!keys[algoliaInstanceKey]) {
      throw new Error(`
        ${algoliaInstanceKey} key is not available for the current user.
        This may happen when you try to access admin's key from user account.
      `)
    }
    
    const instance = algoliaInstances.current[algoliaInstanceKey]
    
    if (instance !== null) {
      return instance
    }
    const client = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID as string,
      keys[algoliaInstanceKey] as string
    )
    
    algoliaInstances.current[algoliaInstanceKey] = client
    return client
  }, [])

  /**
   * When user logs out we clean up the state
   */
  const cleanUp = () => {
    saveToLocalStorage(LS_KEY_ALGOLIA, algoliaEmptyValue) // clean up local storage
    algoliaState.current = algoliaEmptyValue
    algoliaInstances.current = algoliaInitialState
  }

  /**
   * We call this when user logs in or logs out
   */
  const forceUpdate = (isLoggedOut: boolean) => {
    userIsLoggedOut.current = isLoggedOut
    if (isLoggedOut) {
      algoliaState.current = algoliaEmptyValue
    }
    handleKeysUpdate()
  }

  return (
    <Provider
      value={{
        isAlgoliaReady: valid,
        getAlgoliaInstance,
        cleanUp,
        forceUpdate
      }}
    >
      {children}
    </Provider>
  )
}

export { algoliaStore, AlgoliaProvider }
