import styled from 'styled-components'

type StyledProps = {
  background: string;
  isMobileVisible: boolean;
}

const StyledMarketing = styled.div<StyledProps>`
  background: ${({ background }) => background};
  min-height: 100vh;
  padding: 2rem 12px;
  display: ${({ isMobileVisible }) => (isMobileVisible ? 'block' : 'none !important')};

  + .auth-information {
    display: ${({ isMobileVisible }) => (isMobileVisible ? 'none' : 'block !important')};
  }

  .logo {
    max-width: 190px;
  }

  h1 {
    font-size: 30px;
    line-height: 37px;
    font-family: ${({ theme }) => theme.fonts.lexend};
    font-weight: 500;
    margin-left: -3px;
    margin-top: 12px;
    letter-spacing: -1px;
    max-width: 460px;
    color: #1f2937;
  }

  h2 {
    font-family: ${({ theme }) => theme.fonts.lexend};
    font-size: 15px;
    letter-spacing: 0.1px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray[600]};
  }

  .button-prompt {
    margin-top: 26px;

    .scroll-btn {
      width: 100%;
      font-weight: 400;
      height: 50px;
      font-family: ${({ theme }) => theme.fonts.lexend};
    }

    .auth-prompt {
      margin-top: 14px;
      display: block;
      text-align: center;
      font-weight: 600;
      font-family: ${({ theme }) => theme.fonts.nunito};
      color: ${({ theme }) => theme.colors.gray[600]};

      a {
        font-weight: 700;
        font-family: ${({ theme }) => theme.fonts.nunito};
        color: ${({ theme }) => theme.colors.green[500]};
      }
    }
  }

  .challenge-description {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    font-family: ${({ theme }) => theme.fonts.lexend};
    max-width: 396px;
    margin: 24px 0 -30px 0;
  }

  .challenge-deadline {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    font-family: ${({ theme }) => theme.fonts.lexend};
    max-width: 396px;
    margin: 54px 0 -30px 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    display: block !important;
    padding: 156px 0;

    .logo {
      max-width: 260px;
    }

    h1 {
      font-size: 40px;
      line-height: 50px;
      margin-top: 25px;
    }

    + .auth-information {
      display: grid !important;
    }

    .button-prompt {
      display: none;
      margin-top: 60px;
    }

    .challenge-description {
      margin: 24px 0 10px 0;
    }

    .challenge-deadline {
      margin: 10px 0 10px 0;
    }
  }
`

export default StyledMarketing
