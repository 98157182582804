import styled from 'styled-components'

const StyledGalleryPhotoSimilar = styled.div`
  .title {
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 0.01rem;
    display: block;
    margin-bottom: 1rem;
  }
`

export default StyledGalleryPhotoSimilar
