import React, { FC } from 'react'
import classNames from 'classnames'
import { StyledToggle } from '.'

type Props = {
  label?: string;
  direction?: 'ltr' | 'rtl'; 
  // ^ Handles the direction of the toggle relative to text
  // ltr: Left to right (default), rtl: Right to left
  name: string;
  className?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  [x: string]: any;
}

const Toggle: FC<Props> = ({
  label,
  direction,
  name,
  className,
  isChecked,
  isDisabled,
  ...props
}) => {
  return (
    <StyledToggle
      direction={direction}
      isDisabled={isDisabled}
      className={classNames('toggle', className)}
    >
      <div className="toggle-box">
        <input
          name={name}
          disabled={isDisabled}
          id={name}
          type="checkbox"
          checked={isChecked}
          {...props}
        />
        <div className="toggle-indicator" />
      </div>
      {label && <label htmlFor={name}>{label}</label>}
    </StyledToggle>
  )
}

export default Toggle
