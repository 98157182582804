import styled, { keyframes, css } from 'styled-components'

type StyledProps = {
  stage: 'select' | 'schedule' | 'post';
  isPosted?: boolean;
}

const launchAnim = keyframes`
  0% {
    transform: translate(-54%,-53%)
  }
  8% {
    transform: translate(-46%,-43%);
  }
  16% {
    transform: translate(-50%,-50%);
  }
  100% {
    transform: translate(90%,-250%);
  }
`

const checkAnim = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const StyledDraftShareModal = styled.div<StyledProps>`
  overflow: hidden;
  user-select: none;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 20px 20px 0 0;
  padding: 0 0 calc(max(env(safe-area-inset-bottom), 10px) + 20px) 0;
  position: relative;
  box-sizing: border-box;
  max-height: 95vh;
  overflow-y: auto;

  h1 {
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    margin-bottom: 28px;
    color: ${({ theme }) => theme.colors.gray[700]};
  }

  .share-modal-content {
    height: 100%;
    position: relative;
    overflow: hidden;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    height: 300px;

    .social-selector {
      height: 300px;
      position: absolute;
      top: 0;
      left: ${({ stage }) => (stage === 'select' ? 0 : '-100%')};
      right: 0;
      bottom: 0;
      transition: 0.2s;
      overflow-y: auto;
      transition: 0.3s;
      padding: 20px;
      opacity: ${({ stage }) => (stage === 'select' ? 1 : 0)};
      width: 100%;
      height: 100%;

      .social-list {
        display: grid;
        align-content: start;
        grid-gap: 12px;

        .connections-empty-state {
          padding: 20px;
          cursor: pointer;
          color: ${({ theme }) => theme.colors.gray[500]};
          background-color: transparent;
          border-radius: 6px;
          text-align: center;
          font-size: 15px;
          border: 1px dashed ${({ theme }) => theme.colors.gray[300]};
        }

        .add-connection-btn {
          padding: 0 18px;
          font-weight: 600;
          user-select: none;
          height: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          transition: 0.2s;
          border-radius: 6px;
          min-width: 75px;
          background-color: ${({ theme }) => theme.colors.gray[100]};
          color: ${({ theme }) => theme.colors.gray[600]};

          span {
            position: relative;
            top: 1px;
          }

          svg {
            height: 20px;
            margin-right: 10px;
          }

          &:hover {
            cursor: pointer;
            background-color: ${({ theme }) => theme.colors.gray[200]};
          }
        }
      }
    }

    .schedule-date-picker {
      height: 300px;
      position: absolute;
      top: 0;
      left: ${({ stage }) => (stage === 'schedule' ? 0 : '100%')};
      right: 0;
      bottom: 0;
      transition: 0.2s;
      overflow-y: auto;
      transition: 0.3s;
      padding: 20px;
      opacity: ${({ stage }) => (stage === 'schedule' ? 1 : 0)};
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-rows: auto minmax(0, 1fr);

      .date-picker-container {
        align-self: center;
        margin-bottom: 28px;

        .date-picker {
          padding: 0 18px;
          font-weight: 600;
          user-select: none;
          height: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          transition: 0.2s;
          border-radius: 6px;
          width: 100%;
          background-color: ${({ theme }) => theme.colors.gray[100]};
          color: ${({ theme }) => theme.colors.gray[600]};

          span {
            position: relative;
            top: 1px;
          }

          svg {
            height: 20px;
            margin-right: 10px;
          }

          &:hover {
            cursor: pointer;
            background-color: ${({ theme }) => theme.colors.gray[200]};
          }
        }
      }
    }

    .share-now {
      height: 300px;
      position: absolute;
      transition: 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: ${({ stage }) => (stage === 'post' ? 1 : 0)};
      width: 100%;
      height: 100%;
      top: 0;
      left: ${({ stage }) => (stage === 'post' ? 0 : '100%')};
      bottom: 0;

      .share-now-prompt {
        display: grid;
        text-align: center;
        justify-items: center;
        max-width: 300px;

        .share-now-icon {
          height: 80px;
          width: 80px;
          position: relative;
          border-radius: 50%;
          overflow: hidden;
          margin-bottom: 20px;
          pointer-events: none;
          background-color: ${({ theme }) => theme.colors.green[50]};

          .avatar,
          img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }

          .icon {
            position: absolute;
            top: calc(50% + 2px);
            left: 50%;
            transition: 0.2s;
            transform: translate(-50%, -50%);
            font-size: 2.6rem;
            ${({ isPosted }) =>
    isPosted &&
              css`
                animation: 0.8s ${launchAnim} forwards ease-in-out;
              `}
          }

          .check-icon {
            position: absolute;
            top: calc(50% + 2px);
            left: 50%;
            transform: translate(-50%, -50%);
            height: 36px;
            color: ${({ theme }) => theme.colors.green[500]};

            path {
              ${({ isPosted }) =>
    isPosted &&
                css`
                  stroke-dasharray: 1000;
                  stroke-dashoffset: 1000;
                  animation: ${checkAnim} 15s linear forwards;
                `}
              animation-delay: 0.4s;
            }
          }
        }

        span {
          color: ${({ theme }) => theme.colors.gray[600]};
          font-weight: 600;
          line-height: 22px;

          mark {
            font-weight: 700;
            color: ${({ theme }) => theme.colors.gray[700]};
            background-color: transparent;
          }
        }
      }
    }
  }

  .share-modal-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid ${({ theme }) => theme.colors.gray[300]};
    padding: 1rem 20px;

    .share-modal-action-group {
      display: flex;
    }

    button {
      padding: 0 18px;
      font-weight: 600;
      user-select: none;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      transition: 0.2s;
      border-radius: 6px;
      min-width: 75px;
      background-color: ${({ theme }) => theme.colors.gray[100]};
      color: ${({ theme }) => theme.colors.gray[600]};

      span {
        position: relative;
        top: 1px;
      }

      svg:not(.spinner-icon) {
        height: 20px;
      }

      &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.gray[200]};
      }

      &.download-btn {
        background-color: ${({ theme }) => theme.colors.gray[100]};
        width: 40px;
        min-width: 40px;
        padding: 0;

        &:hover {
          background-color: ${({ theme }) => theme.colors.gray[200]};
        }

        &:disabled {
          cursor: not-allowed;
          color: ${({ theme }) => theme.colors.gray[400]};
          background-color: ${({ theme }) => theme.colors.gray[100]};
        }
      }

      &.schedule-btn {
        border: 1px solid ${({ theme }) => theme.colors.gray[300]};
        background-color: ${({ theme }) => theme.colors.background};
        padding: 0 10px;

        .beta-flag {
          padding: 4px 5px 3px 5px;
          color: ${({ theme }) => theme.colors.green[500]};
          background-color: ${({ theme }) => theme.colors.green[100]};
          border-radius: 3px;
          margin-left: 8px;
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 0.05rem;
        }

        &:hover {
          background-color: ${({ theme }) => theme.colors.gray[100]};
        }

        &:disabled {
          cursor: not-allowed;
          border-color: transparent;
          color: ${({ theme }) => theme.colors.gray[400]};
          background-color: ${({ theme }) => theme.colors.gray[100]};
        }
      }

      &.publish-btn {
        background-color: ${({ theme }) => theme.colors.green[500]};
        color: ${({ theme }) => theme.colors.background};
        min-width: 85px;

        &:hover {
          background-color: ${({ theme }) => theme.colors.green[600]};
        }

        &:disabled {
          cursor: not-allowed;
          color: ${({ theme }) => theme.colors.gray[400]};
          background-color: ${({ theme }) => theme.colors.gray[100]};
        }
      }

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  @media (min-width: ${({ theme }) => `${theme.breakpoints.s}px`}) {
    max-width: 500px;
    width: 100vw;
    padding: 0;
  }
`

export default StyledDraftShareModal
