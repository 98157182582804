import React, { FC, ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import { StyledTab } from '.'

type Props = {
  label: string | JSX.Element;
  icon?: ReactNode;
  isActive?: boolean;
  to?: string;
  isExact?: boolean;
  onClick?: () => void;
}

const Tab: FC<Props> = ({
  label, icon, isActive, to, isExact, onClick
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <StyledTab
      as={to ? NavLink : 'button'}
      to={to || ''}
      exact={isExact}
      activeClassName="active"
      className="tab"
      isActive={isActive}
      onClick={() => handleClick()}
    >
      {icon && <div className="tab-icon">{icon}</div>}
      {label}
    </StyledTab>
  )
}

export default Tab
