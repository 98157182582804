import styled from 'styled-components'

const StyledIssuePdf = styled.div`
  margin: 3rem 0;
  min-height: 50vh;
  
  .linkAnnotation {
    opacity: 0 !important;
  }
`
export default StyledIssuePdf
