import styled from 'styled-components'

type StyledProps = {
  showFadeLeft: boolean;
  showFadeRight: boolean;
}

const StyledTabBar = styled.div<StyledProps>`
  position: relative;

  .tab-bar {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow-y: auto;
  
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .scroll-indicator {
    position: absolute;
    height: 100%;
    width: 53px;
    z-index: 1;
    top: 0;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.gray[300]};

    svg {
      height: 24px;
    }

    &.left {
      left: 0;
      justify-content: left;
      background: linear-gradient(90deg, ${({ theme }) => theme.colors.background}, #ffffff00);
      display: ${({ showFadeLeft }) => showFadeLeft ? 'flex' : 'none'};
    }

    &.right {
      right: -20px;
      justify-content: end;
      background: linear-gradient(-90deg, ${({ theme }) => theme.colors.background}, #ffffff00);
      padding-right: 20px;
      width: 100px;
      display: ${({ showFadeRight }) => showFadeRight ? 'flex' : 'none'};
    }
  }
`

export default StyledTabBar
