import React, { FC } from 'react'
import { ActionDialog } from '..'

type Props = {
  isOpen: boolean;
  itemToDelete: string;
  onConfirm: () => void;
  hideDeletionConfirmation: () => void;
}

const DeletionConfirmation: FC<Props> = ({
  isOpen,
  itemToDelete,
  onConfirm,
  hideDeletionConfirmation
}) => {
  const startsWithVowel = itemToDelete.match('^[aieouAIEOU].*')

  const confirmDeletion = () => {
    onConfirm()
    hideDeletionConfirmation()
  }

  return (
    <ActionDialog
      type="error"
      isOpen={isOpen}
      title={`Delete a${startsWithVowel ? 'n' : ''} ${itemToDelete}`}
      body={`Are you sure you want to delete this ${itemToDelete}?`}
      confirmButtonText={`Delete ${itemToDelete}`}
      onClose={() => hideDeletionConfirmation()}
      onConfirm={() => confirmDeletion()}
    />
  )
}

export default DeletionConfirmation
