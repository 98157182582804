import { useForm as useReactHookForm, UseFormOptions } from 'react-hook-form'

const useForm = ({ ...fieldProps }: UseFormOptions) => {
  const {
    errors, watch, setValue, ...reactHookFormProps
  } = useReactHookForm({ ...fieldProps })
  const values = watch()

  // Custom function to return error as string from react hook form
  const getError = (name: string) => {
    if (errors[name]) {
      return errors[name].message
    }
    return ''
  }

  // Resets all the forms values

  const resetForm = () => {
    for (const [index] of Object.entries(values)) {
      setValue(index, undefined)
    }
  }

  // Sets the default values of the form from an object
  const setDefaults = (object?: Record<string, any>) => {
    if (!object) {
      return
    }

    for (const [key, value] of Object.entries(object)) {
      let formattedValue = ''

      // Removes __typename key
      if (value && typeof value === 'object') {
        const stringValue = JSON.stringify(value, (key, value) => {
          if (key === '__typename') {
            return
          }

          return value
        })
        formattedValue = JSON.parse(stringValue)
      }

      setValue(key, formattedValue || value)
    }
  }

  return {
    ...reactHookFormProps,
    errors,
    values,
    watch,
    resetForm,
    setValue,
    getError,
    setDefaults
  }
}

export default useForm
