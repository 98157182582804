import { useEffect } from 'react'

const useScript = (loadNow: boolean, url: string) => {
  useEffect(() => {
    if (!loadNow) {
      return
    }

    const script = document.createElement('script')

    script.src = url
    script.async = true
    script.defer = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [loadNow, url])
}

export default useScript
