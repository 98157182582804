import styled from 'styled-components'
import { theme } from '../../utils/theme'

const StyledDropdown = styled.div`
  width: 100%;
  min-width: 200px;

  .color-bubble {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin-right: 10px;
    box-shadow: inset 0 0 0 1px #00000029;
  }
`

export const StyledDropdownSelect = () => ({
  control: (base, state) => ({
    ...base,
    borderWidth: state.selectProps.withoutBorder ? 0 : '1.2px',
    borderColor: state.selectProps.borderType === 'border' ? theme.colors.gray[300] : 'transparent',
    cursor: 'pointer',
    boxShadow: 'none',
    width: '100%',
    minHeight: (state.selectProps.borderType === 'border' || state.isDisabled) ? '38px' : '30px',
    borderBottomRightRadius: state.menuIsOpen ? 0 : '3px',
    borderBottomLeftRadius: state.menuIsOpen ? 0 : '3px',
    borderTopRightRadius: '3px',
    borderTopLeftRadius: '3px',
    backgroundColor: state.isDisabled ? theme.colors.gray[100] : base.backgroundColor,

    ':hover': { 
      backgroundColor: state.selectProps.borderType === 'border' ? base.backgroundColor : theme.colors.gray[100],
      borderColor: state.selectProps.borderType === 'border' ? theme.colors.gray[300] : 'transparent',
    }
  }),
  placeholder: (base, state) => ({
    ...base,
    fontWeight: 600,
    fontSize: '14px',
    color: theme.colors.gray[500],
    lineHeight: 'normal',
    marginLeft: state.isMulti ? '10px' : '2px'
  }),
  singleValue: (base) => ({
    ...base,
    fontWeight: 600,
    fontSize: '14px',
    color: theme.colors.gray[500],
    lineHeight: 'normal',
    margin: 0,
    textTransform: 'capitalize'
  }),
  multiValue: (base) => ({
    ...base,
    height: '25px',
    borderRadius: '3px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 3px',
    background: theme.colors.gray[100],
    position: 'relative',
    textTransform: 'capitalize',
    transition: 'background 0.2s',

    ':hover': {
      background: theme.colors.gray[200]
    }
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: theme.colors.gray[600],
    fontWeight: '500',
    fontSize: '14px',
    padding: '3px',
    paddingLeft: '3px'
  }),
  multiValueRemove: (base) => ({
    ...base,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    background: 'transparent',
    fontSize: 0,
    color: 'transparent',
    opacity: 0
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  valueContainer: (base, state) => ({
    ...base,
    minHeight: (state.selectProps.borderType === 'border' || state.isDisabled) ? '37px' : '29px',
    padding: state.isMulti ? '2px 12px 2px 2px' : '2px 12px',
    justifyContent: state.selectProps.withoutBorder ? 'flex-end' : 'flex-start'
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    padding: '0 5px',
    height: (state.selectProps.borderType === 'border' || state.isDisabled) ? '37px' : '29px',
    backgroundColor: state.selectProps.borderType === 'border' ? theme.colors.background : 'transparent',
    borderRadius: '2px'
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    height: '27px',
    width: '27px',
    color: theme.colors.gray[300],
    borderRadius: '3px',
    display: 'flex',
    padding: '0',
    alignItems: 'center',
    justifyContent: 'center',
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',

    ':hover': { color: theme.colors.gray[500] },

    'svg': { height: '20px' }
  }),
  clearIndicator: (base, state) => ({
    ...base,
    height: '27px',
    width: '27px',
    color: theme.colors.gray[300],
    borderRadius: '3px',
    display: 'flex',
    padding: '0',
    alignItems: 'center',
    justifyContent: 'center',

    ':hover': { color: theme.colors.gray[500], background: theme.colors.gray[100] },

    'svg': { height: '20px' }
  }),
  menu: (base, state) => ( 
    {
    ...base,
    margin: '-1px 0 0 0',
    borderBottomLeftRadius: '3px',
    borderTopRightRadius: state.selectProps.borderType === 'border' ? '0px' : '3px',
    borderTopLeftRadius: state.selectProps.borderType === 'border' ? '0px': '3px',
    boxShadow: 'none',
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderBottom: '1px solid',
    borderTop: state.selectProps.borderType === 'border' ? '0' : '1px solid',
    borderColor: theme.colors.gray[300],
    zIndex: theme.zIndex.dropdown
  }),
  menuList: (base, state) => ({
    ...base,
    padding: state.selectProps.borderType === 'border' ? '0px 12px 6px 12px' : '6px 12px'
  }),
  option: (base, state) => ({
    ...base,
    fontWeight: 600,
    fontSize: '14px',
    marginBottom: '2px',
    color: theme.colors.gray[500],
    backgroundColor: state.isSelected ? theme.colors.gray[100] : 'transparent',
    borderRadius: '3px',
    boxSizing: 'border-box',
    padding: '10px 9px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',

    ':hover': {
      backgroundColor: theme.colors.gray[100],
      cursor: 'pointer'
    }
  }),
  input: (base, state) => ({
    ...base,
    fontSize: '14px',
    color: theme.colors.gray[500],

    'input': {
      fontWeight: 600
    }
  })
})

export default StyledDropdown
