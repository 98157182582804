import {
  COMMUNITY_EVENT_FORM_UPDATE_FIELD,
  COMMUNITY_EVENT_FORM_UPDATE_STATE,
  CommunityEventFormActions,
  CommunityEventFormState,
  COMMUNITY_EVENT_FORM_START_LOADING,
  COMMUNITY_EVENT_FORM_SUCCESS,
  COMMUNITY_EVENT_FORM_ERROR
} from './types'
import { CommunityEventType } from '../../components/Dashboard'

const initialState: CommunityEventFormState = {
  form: {} as CommunityEventType,
  loading: true,
  success: false,
  error: false,
  errorMessage: null,
  successMessage: null
}

export const CommunityEventFormReducer = (
  state = initialState,
  action: CommunityEventFormActions
): CommunityEventFormState => {
  switch (action.type) {
    case COMMUNITY_EVENT_FORM_UPDATE_FIELD:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.field]: action.payload.value,
          id: action.meta.eventId!
        }
      }
    case COMMUNITY_EVENT_FORM_UPDATE_STATE:
      if (state.form.id && action.payload.id && state.form.id !== action.payload.id) {
        return {
          ...state,
          loading: false,
          form: {
            ...initialState.form,
            ...action.payload
          }
        }
      }
      return {
        ...state,
        loading: false,
        form: {
          ...state.form,
          ...action.payload
        }
      }
    case COMMUNITY_EVENT_FORM_START_LOADING:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
        error: false,
        success: false,
        loading: true
      }
    case COMMUNITY_EVENT_FORM_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        successMessage: action.payload,
        error: false,
        success: true,
        loading: false
      }
    case COMMUNITY_EVENT_FORM_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: null,
        error: true,
        success: false,
        loading: false
      }
    default:
      const x: never = action
  }
  return state
}
