import styled from 'styled-components'

const StyledAdminPageNavContainer = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: 300px auto;
  grid-gap: 1rem;

  .width-controller-container {
    padding: 0;
  }
`

export default StyledAdminPageNavContainer
