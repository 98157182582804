import styled from 'styled-components'

const StyledMilk = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  overflow: hidden;

  .wave {
    width: 200vw;
    height: 200vw;
    border-radius: 30%;
    background: linear-gradient(45deg, #FAF5ED, #F1E8D9);
    animation: milk infinite 10s;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0%) rotate(0deg);
  }

  .wave-2 {
    background: linear-gradient(45deg, #F1E8D9, #FAF5ED);
    animation: milk infinite 6s;
  }

  @keyframes milk {
    0% {
      transform: translate(-50%, 0%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, 0%) rotate(360deg);
    }
  }
`

export default StyledMilk
