import { AffiliateTrackState, AffiliateTrackActions, AFFILIATE_TRACK } from './types'

const initialState: AffiliateTrackState = { affiliate: '' }

export const affiliateTrackReducer = (
  state = initialState,
  action: AffiliateTrackActions
): AffiliateTrackState => {
  switch (action.type) {
    case AFFILIATE_TRACK:
      return { affiliate: action.payload }
  }

  return state
}
