import styled from 'styled-components'

type StyledProps = {
  isAdmin: boolean;
}

const StyledIssueHeader = styled.div<StyledProps>`
  position: relative;

  .issue-backdrop {
    height: 240px;
    width: 100%;
    background: ${({ theme }) => theme.colors.gray[200]};
    position: relative;
  }

  .issue-backdrop img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    font-size: 0;
  }

  .back-btn-container {
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 1144px;
    margin: 0 auto;
    z-index: 1;
    height: 100%;
    display: flex;
    align-items: center;
    left: 0;
    right: 0;
    padding: 0 1rem;
  }

  .back-btn {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: block;

    .btn {
      border-radius: 50%;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.l}px) {
    .back-btn {
      left: -40px;
    }
  }
`
export default StyledIssueHeader
