import { GetIssueById_issueFindById } from '../../graphql/Issue/__generated__/GetIssueById'

export type IssueFormState = {
  form: GetIssueById_issueFindById;
  loading: boolean;
  success: boolean;
  error: boolean;
  errorMessage: string | null;
  successMessage: string | null;
}

export type UpdateFieldPayloadType = {
  field: keyof GetIssueById_issueFindById;
  value: any;
}
export const ISSUE_FORM_UPDATE_FIELD = 'ISSUE_FORM_UPDATE_FIELD'
export type IssueFormUpdateFieldAction = {
  type: typeof ISSUE_FORM_UPDATE_FIELD;
  payload: UpdateFieldPayloadType;
  meta: {
    issueId?: string;
  };
}

export const ISSUE_FORM_UPDATE_STATE = 'ISSUE_FORM_UPDATE_STATE'
export type IssueFormUpdateStateAction = {
  type: typeof ISSUE_FORM_UPDATE_STATE;
  payload: Partial<GetIssueById_issueFindById>;
}

export const ISSUE_FORM_START_LOADING = 'ISSUE_FORM_START_LOADING'
export type IssueFormStartLoadingAction = {
  type: typeof ISSUE_FORM_START_LOADING;
}

export const ISSUE_FORM_SUCCESS = 'ISSUE_FORM_SUCCESS'
export type IssueFormSuccessAction = {
  type: typeof ISSUE_FORM_SUCCESS;
  payload: string;
}

export const ISSUE_FORM_ERROR = 'ISSUE_FORM_ERROR'
export type IssueFormErrorAction = {
  type: typeof ISSUE_FORM_ERROR;
  payload: string;
}

export type IssueFormActions =
  | IssueFormUpdateFieldAction
  | IssueFormUpdateStateAction
  | IssueFormStartLoadingAction
  | IssueFormSuccessAction
  | IssueFormErrorAction
