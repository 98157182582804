import React, { FC, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { Toggle } from '../../System'
import { CombinedUser } from '../../User'
import { grantUserCourse } from '../../../graphql/UserCourse/grantUserCourse'
import { revokeUserCourse } from '../../../graphql/UserCourse/revokeUserCourse'

type Props = {
  id: string;
  title: string;
  user: CombinedUser;
  isPurchased: boolean;
}

const UserTableRow: FC<Props> = ({
  id, title, user, isPurchased
}) => {
  const apollo = useApolloClient()
  const [purchasedState, setPurchasedState] = useState(isPurchased)

  const toggleOwnership = async ({ checked }) => {
    setPurchasedState(checked)

    if (checked) {
      await apollo.mutate({
        mutation: grantUserCourse,
        variables: { user: user.id, course: id }
      })
      return
    }

    await apollo.mutate({
      mutation: revokeUserCourse,
      variables: { user: user.id, course: id }
    })
  }

  return (
    <div className="title-cell">
      <Toggle name={id} isChecked={purchasedState} onChange={(e) => toggleOwnership(e.target)} />
      <div className="title-label">{title}</div>
    </div>
  )
}

export default UserTableRow
