import React, { FC } from 'react'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XIcon
} from '@heroicons/react/outline'
import { NotificationType } from '../../redux/notifications/types'
import { Button } from '../System'
import { StyledNotification } from '.'

type Props = {
  type: NotificationType;
  message: string;
  zIndex?: number;
  index?: number;
  onRemove: () => void;
}

const Notification: FC<Props> = ({
  type, message, zIndex, index, onRemove
}) => {
  return (
    <StyledNotification type={type} zIndex={zIndex} index={index}>
      <div className="notification-symbol">
        {type === 'default' && <InformationCircleIcon />}
        {type === 'error' && <ExclamationCircleIcon />}
        {type === 'success' && <CheckCircleIcon />}
      </div>
      <span className="notification-message">{message}</span>
      <Button
        isIcon
        type="small"
        theme="outline"
        className="notification-close-btn"
        onClick={() => onRemove()}
      >
        <XIcon />
      </Button>
    </StyledNotification>
  )
}

export default Notification
