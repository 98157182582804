import React from 'react'
import styled from 'styled-components'

const StyledMenu = styled.div`
  /* these were used for the hovering toolbar. 
        if we use the hovering toolbar, these 
        will be needed to be added in */
  /* padding: 8px 7px 6px;
    position: absolute;
    z-index: 1;
    top: -10000px;
    left: -10000px;
    margin-top: -6px;
    opacity: 0;
    background-color: #222;
    border-radius: 4px;
    transition: opacity 0.75s; */

  & > * {
    display: inline-block;
  }

  & > * + * {
    margin-left: 4px;
  }
`

export const Menu = React.forwardRef(({ className, style, ...props }: any, ref) => (
  <StyledMenu {...props} ref={ref} style={style} className={className} />
))

export const Icon = React.forwardRef(({ className, ...props }: any, ref) => {
  const styles = {
    fontSize: '18px',
    verticalAlign: 'text-bottom'
  }
  return <span {...props} ref={ref} style={styles} className={`${className} material-icons`} />
})

export const Button = React.forwardRef(({ className, active, reversed, ...props }: any, ref) => {
  const styles = {
    cursor: 'pointer',
    color: reversed ? (active ? 'white' : '#aaa') : active ? 'black' : '#C4C4C4',
    height: '30px',
    width: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    borderRadius: '3px',
    backgroundColor: active ? 'white' : 'transparent',
    paddingTop: '2px',
    boxShadow: active ? '0px 3px 2px rgba(97, 97, 97, 0.03)' : 'none'
  }
  return <span {...props} ref={ref} style={styles} className={className} />
})

export const Toolbar = React.forwardRef(({ className, ...props }: any, ref) => {
  const styles = {
    position: 'relative',
    padding: '4px',
    height: '40px',
    borderBottom: '1px solid #c4c4c4',
    display: 'flex',
    alignItems: 'center'
  }

  return <Menu {...props} ref={ref} className={className} style={styles} />
})
