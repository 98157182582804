import styled from 'styled-components'
import React from 'react'

const FlexGroupStyled = styled.div`
  display: flex;
  align-items: center;
`

const FlexGroup = ({ className, children }: { className?: string; children?: React.ReactNode }) => {
  return <FlexGroupStyled className={className}>{children}</FlexGroupStyled>
}

export default FlexGroup
