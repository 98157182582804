import React, { FC, useState, useContext, useEffect } from 'react'
import { useLocation } from 'react-router'
import { StyledExpiredCTAModal } from '.'
import { Button, Dialog } from '../../System'
import { userStore } from '../../utils/UserContext'
import useUsageData from '../../Account/useUsageData'
import { CloseButton } from '../../System/CloseButton'

// Expired CTA modal
// This shows up for a user who logs into their expired trial account.
// The subscription page will be their landing page.
// If they close the modal, they see the subscription page.
const ExpiredCTAModal: FC = () => {
  const [showModal, setShowModal] = useState(false)
  const { user, loaded } = useContext(userStore)
  const { usageData, completedTasks, getAllUsageData } = useUsageData()
  const location: {
    state: { from: string; expiredTrialUser: boolean };
    pathname: string;
  } = useLocation()

  const handleLoadData = async () => {
    await getAllUsageData()
    setShowModal(true)
  }

  useEffect(() => {
    // load data and show modal when user logs in to their expired trial account
    if (location.state?.from === '/user/login' && location.state?.expiredTrialUser) {
      handleLoadData()
    }
  }, [location.state])

  if (!showModal || !loaded) {
    return null
  }

  return (
    <Dialog isOpen={showModal} onClose={() => setShowModal(false)}>
      <StyledExpiredCTAModal>
        <div className="main-column">
          <div className="modal-header">
            <div className="modal-header-main">
              <h3 className="modal-title">
                {`Hey ${user?.displayName}, it looks like your free trial is over.`}{' '}
              </h3>
              <CloseButton onClick={() => setShowModal(false)} />
            </div>
          </div>
          <div className="modal-content">
            <p className="modal-text">
              {completedTasks
                ? 'Become a subscriber to get access to our community and keep the resources you\'ve customized.'
                : 'Upgrade your account and get access to our Community and to all of your drafts, favorited images, and collections you made on Social Curator!'}
            </p>
            {completedTasks && usageData && (
              <div className="modal-list">
                <h4>So far you have</h4>
                <ul>
                  {usageData.draftCount > 0 && <li>{usageData.draftCount} Drafts</li>}
                  {usageData.imageCount > 0 && <li>{usageData.imageCount} Favorited Images</li>}
                  {usageData.captionCount > 0 && (
                    <li>{usageData.captionCount} Favorited Captions</li>
                  )}
                </ul>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <Button onClick={() => setShowModal(false)}>Got it</Button>
          </div>
        </div>
      </StyledExpiredCTAModal>
    </Dialog>
  )
}

export default ExpiredCTAModal
