import styled from 'styled-components'

type StyledProps = {
  columns: number;
  isFlex?: boolean;
  isSticky?: boolean;
}

const StyledRow = styled.div<StyledProps>`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(${({ columns }) => 170 / columns}px, 1fr));
  ${({ isFlex }) => isFlex && `
    display: flex;
    grid-template-columns: unset;
    align-items: center;
    justify-content: space-between;
  `}

  ${({ isSticky, theme }) => isSticky && `
    position: sticky;
    top: 80px;
    z-index: ${theme.elevations.dropdown};
    background: ${theme.colors.background};
    padding: 1rem 0;
    margin: -1rem 0;
  `}
`

export default StyledRow
