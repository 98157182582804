import convert from 'heic-convert'

// convert buffers from heic to jpeg
const convertBuffer = async (inputBuffer: Buffer) => {
  const outputBuffer = await convert({
    buffer: inputBuffer, // the HEIC file buffer
    format: 'JPEG', // output format
    quality: 1 // the jpeg compression quality, between 0 and 1
  })

  return outputBuffer
}

// converts .heic file into jpeg blob
export const heicToJpeg = async (file: File) => {
  // transforms the File into a stream, returns promise that resolves with an ArrayBuffer
  const abuffer = await file.arrayBuffer()
  // creates a Buffer that shares same allocated memory as abuffer
  const buffer = Buffer.from(abuffer)
  // converts buffer of .heic image into a .jpeg buffer
  const jpgBuffer = await convertBuffer(buffer)
  // creates new Blob instance of original file using .jpeg buffer
  const blob = new Blob([jpgBuffer], { type: 'image/jpeg' })

  return blob
}
