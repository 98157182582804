import React from 'react'
import { Transforms, Editor, Text } from 'slate'
import { jsx } from 'slate-hyperscript'
import { useSelected, useFocused } from 'slate-react'

const ELEMENT_TAGS = {
  A: el => ({ type: 'link', url: el.getAttribute('href') }),
  BLOCKQUOTE: () => ({ type: 'quote' }),
  H1: () => ({ type: 'heading-one' }),
  H2: () => ({ type: 'heading-two' }),
  H3: () => ({ type: 'heading-three' }),
  H4: () => ({ type: 'heading-four' }),
  H5: () => ({ type: 'heading-five' }),
  H6: () => ({ type: 'heading-six' }),
  IMG: el => ({ type: 'image', url: el.getAttribute('src') }),
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'numbered-list' }),
  P: () => ({ type: 'paragraph' }),
  PRE: () => ({ type: 'code' }),
  UL: () => ({ type: 'bulleted-list' })
}

const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
}

const matchElementAttrs = (el: HTMLElement) => {
  if (TEXT_TAGS[el.nodeName]) {
    return TEXT_TAGS[el.nodeName](el)
  }
  const isBold = ['700', 'bold'].includes(el.style.fontWeight)
  const isItalic = ['italic'].includes(el.style.fontStyle)
  const isUnderline = ['underline'].includes(el.style.textDecoration)

  const attrs = {
    ...(isBold ? TEXT_TAGS.STRONG() : undefined),
    ...(isItalic ? TEXT_TAGS.I() : undefined),
    ...(isUnderline ? TEXT_TAGS.U() : undefined),
  }
  return Object.keys(attrs).length ? attrs : undefined
}

const matchElementTag = (el: HTMLElement) => {
  if (ELEMENT_TAGS[el.nodeName]) {
    return ELEMENT_TAGS[el.nodeName](el)
  }

  const fontSizeNumber = Number(((el.style.fontSize || '').match(/\d+/) || [])[0])
  const isH1 = fontSizeNumber && fontSizeNumber >= 26
  const isH2 = fontSizeNumber && fontSizeNumber >= 16 && fontSizeNumber < 26

  if (isH1) {
    return ELEMENT_TAGS.H1()
  }
  if (isH2) {
    return ELEMENT_TAGS.H2()
  }
  return undefined
}

const deserialize = el => {
  if (el.nodeType === 3) {
    return el.textContent
  } if (el.nodeType !== 1) {
    return null
  }

  const { nodeName } = el
  let parent = el

  if (nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
    parent = el.childNodes[0]
  }

  const children = Array.from(parent.childNodes).map(deserialize).flat()

  if (nodeName === 'BODY') {
    return jsx('fragment', {}, children)
  }

  if (nodeName === 'BR') {
    return jsx('element', { type: 'paragraph' }, [{ text: '' }])
  }


  const elemTag = matchElementTag(el)
  if (elemTag) {
    return jsx('element', elemTag, children)
  }

  const elemAttrs = matchElementAttrs(el)
  if (elemAttrs) {
    return children.map((child) => jsx('text', elemAttrs, child))
  }

  return children
}

export const withHtml = editor => {
  const { insertData, isInline, isVoid } = editor

  // eslint-disable-next-line no-param-reassign
  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  }

  // eslint-disable-next-line no-param-reassign
  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element)
  }

  const wrapTopLevelInlineNodesInParagraphs = (_editor, fragment) => {
    let inlineNodes: any[] = []
    const newFragments: any[] = []

    const maybePushInlineNodeParagraph = () => {
      if (inlineNodes.length > 0) {
        newFragments.push(jsx('element', { type: 'paragraph' }, inlineNodes))
        inlineNodes = []
      }
    }

    fragment.forEach(node => {
      if (Text.isText(node) || Editor.isInline(_editor, node)) {
        inlineNodes.push(node)
      }
      else {
        maybePushInlineNodeParagraph()
        newFragments.push(node)
      }
    })
    maybePushInlineNodeParagraph()

    return newFragments
  }

  // eslint-disable-next-line no-param-reassign
  editor.insertData = data => {
    const html = data.getData('text/html')

    if (html) {
      const parsed = new DOMParser().parseFromString(html, 'text/html')
      const fragment = deserialize(parsed.body)
      let fragmentWithOnlyBlocks = fragment
      if (Array.isArray(fragment)) {
        fragmentWithOnlyBlocks = wrapTopLevelInlineNodesInParagraphs(
          editor,
          fragment
        )
      }
      Transforms.insertFragment(editor, fragmentWithOnlyBlocks)
      return
    }

    insertData(data)
  }

  return editor
}

export const Element = (props: any) => {
  const { attributes, children, element } = props

  switch (element.type) {
    case 'quote':
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'code':
      return (
        <pre>
          <code {...attributes}>{children}</code>
        </pre>
      )
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>
    case 'heading-four':
      return <h4 {...attributes}>{children}</h4>
    case 'heading-five':
      return <h5 {...attributes}>{children}</h5>
    case 'heading-six':
      return <h6 {...attributes}>{children}</h6>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    case 'link':
      return (
        <a href={element.url} {...attributes}>
          {children}
        </a>
      )
    case 'image':
      return <ImageElement {...props} />
    default:
      return <p {...attributes}>{children}</p>
  }
}

const ImageElement = ({ attributes, children, element }: any) => {
  const selected = useSelected()
  const focused = useFocused()

  const styles = {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '20em',
    boxShadow: selected && focused ? '0 0 0 2px blue' : 'none'
  }
  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <img src={element.url} style={styles} />
      </div>
      {children}
    </div>
  )
}

export const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.strikethrough) {
    children = <del>{children}</del>
  }

  return <span {...attributes}>{children}</span>
}
