import { gql } from '@apollo/client'

export const galleryCollectionsImageRemoveManyMutation = gql`
  mutation GalleryCollectionsImageRemoveMany(
    $collectionId: String!
    $image: String!
    $user: String!
  ) {
    galleryCollectionsImageRemoveMany(
      filter: { collectionId: $collectionId, image: $image, user: $user }
    ) {
      numAffected
    }
  }
`
