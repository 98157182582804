/* eslint-disable @typescript-eslint/camelcase */
import { CombinedUser } from '../User'

type IdentifyArgs = {
  id?: string;
  user?: CombinedUser;
  data?: any;
  options?: any;
}

const useSegment = () => {
  const identify = ({
    id, user, data, options
  }: IdentifyArgs) => {
    if (!id) {
      return
    }

    const fullName = user?.displayName || ''
    const firstAndLast = fullName.split(' ')

    const customerData = {
      name: user?.displayName,
      firstName: firstAndLast[0] || '',
      lastName: firstAndLast.slice(1).join(' ') || '',
      email: user?.email,
      facebook_url: user?.facebookURL,
      instagram_url: user?.instagramURL,
      free: user?.free || false,
      stripe_subscription_status: user?.stripeSubscriptionStatus,
      subscription_interval: user?.subscriptionInterval,
      stripe_customer_id: user?.stripeCustomerId,
      role: user?.role,
      phone: user?.phone,
      course: user?.course,
      beta: user?.beta || false,
      experience: user?.experience,
      product: user?.onboarding?.product,
      industry: user?.onboarding?.industry,
      custom_industry: user?.onboarding?.customIndustry,
      post_frequency: user?.onboarding?.postFrequency,
      customer_frequency: user?.onboarding?.customerFrequency,
      confidence_level: user?.onboarding?.confidenceLevel,
      primary_platform: user?.onboarding?.primaryPlatform,
      preferred_feature: user?.onboarding?.preferredFeature,
      is_whale: user?.isWhale || false,
      user_created_at: user?.createdAt?.seconds ? new Date(user.createdAt.seconds * 1000) : null,
      connected_profiles_amount: user?.socialProfiles?.length
    }

    window._refiner('identifyUser', {
      id,
      ...(data || customerData)
    })

    window.analytics.identify(id, data || customerData, options)
  }

  const track = (eventName: string, properties?: any, options?: any) => {
    window.analytics.track(eventName, { ...properties }, options)
    window.Intercom('trackEvent', eventName, { ...properties })
  }

  const page = () => {
    window.analytics.page()
  }

  return { identify, track, page }
}

export default useSegment
