import { useState, useRef, useEffect } from 'react'

const useLoading = () => {
  const [status, setStatus] = useState(false)
  const ref = useRef<any>(undefined)
  const setLoading = (data: boolean, ms = 500) => {
    if (data) {
      ref.current = setTimeout(() => {
        setStatus(true)
      }, ms)
    }
    else {
      setStatus(false)
      if (ref.current) {
        clearTimeout(ref.current)
      }
    }
  }
  useEffect(() => {
    return () => {
      setStatus(false)
      if (ref.current) {
        clearTimeout(ref.current)
      }
    }
  }, [])

  return {
    loading: status,
    setLoading
  }
}

export default useLoading
