import React, { useEffect, useMemo, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useTable, usePagination } from 'react-table'
import { Dropdown, Spinner } from '../../System'
import { StyledAdminTable } from '.'

type Props = {
  columns: any;
  data: any[];
  fetchData: (x: any) => any;
  loading: boolean;
  countDocs: number;
  pageCount: any;
  displayHeader?: boolean;
  onClickRow?: (rowData: any) => void;
  filtersChanged?: any;
}

const AdminTable = ({
  columns,
  data,
  fetchData,
  loading,
  countDocs,
  pageCount: controlledPageCount,
  filtersChanged,
  onClickRow,
  displayHeader = true
}: Props) => {
  const [ctrPageIndex, setCtrPageIndex] = useState(0)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      autoResetPage: true,
      pageCount: controlledPageCount,
      useControlledState: (state) =>
        useMemo(
          () => ({
            ...state,
            pageIndex: ctrPageIndex
          }),
          [state, ctrPageIndex, filtersChanged]
        )
    },
    usePagination
  )

  useEffect(() => {
    setCtrPageIndex(0)
  }, [filtersChanged])

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    fetchData({ page: pageIndex, items: pageSize })
  }, [fetchData, pageIndex, pageSize])

  const handlePageSize = (size: number) => {
    setPageSize(size)
    setCtrPageIndex(0)
  }

  const handleRowClick = (rowData: any) => {
    if (onClickRow) {
      onClickRow(rowData)
    }
  }

  return (
    <StyledAdminTable>
      <table {...getTableProps()}>
        {displayHeader && (
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        {loading && (!data || data.length === 0) && (
          <tbody>
            <tr>
              <td colSpan={10000}>
                <Spinner isBlock />
              </td>
            </tr>
          </tbody>
        )}
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                onClick={() => handleRowClick(row.original)}
                className="tr-body"
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
          <tr>
            <td colSpan={10000}>
              <div className="footer-table">
                <div className="per-page">
                  <Dropdown
                    type="no-border"
                    options={[
                      { label: 'Items per page: 10', value: '10' },
                      { label: 'Items per page: 30', value: '30' },
                      { label: 'Items per page: 50', value: '50' }
                    ]}
                    value={pageSize.toString()}
                    isClearable={false}
                    onChange={(e) => handlePageSize(Number(e.value))}
                  />
                </div>
                <div className="count-data">Total {countDocs}</div>

                <div className="paginator-block">
                  <ReactPaginate
                    forcePage={ctrPageIndex}
                    previousLabel="◀"
                    nextLabel="▶"
                    breakLabel="…"
                    breakClassName="break-me"
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={({ selected }) => setCtrPageIndex(selected)}
                    containerClassName="pagination"
                    subContainerClassName="pages pagination"
                    activeClassName="active"
                  />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </StyledAdminTable>
  )
}

export default AdminTable
