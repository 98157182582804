import styled from 'styled-components'

const StyledDraftDrawerImage = styled.div`
  height: 250px;
  background-color: ${({ theme }) => theme.colors.gray[50]};
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.15);
  position: relative;

  .delete-img-btn {
    opacity: 0;
    transition: 0.2s;
    position: absolute;
    top: 50%;
    left: 50%;
    user-select: none;
    transform: translate(-50%, -50%);
    z-index: 1;
    padding: 5px 8px;
    height: fit-content;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 2px 3px rgba(0, 0, 0, 0.05);
    background-color: ${({ theme }) => theme.colors.background};
    font-family: ${({ theme }) => theme.fonts.nunito};
    color: ${({ theme }) => theme.colors.gray[500]};
    font-weight: 600;

    svg {
      margin-right: 8px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.gray[100]};
    }

    &:active {
      background-color: ${({ theme }) => theme.colors.gray[200]};
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
    pointer-events: none;
  }

  .draft-drawer-image-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    user-select: none;
    transform: translate(-50%, -50%);

    a {
      display: contents;
    }

    .vertical-divider {
      margin: 0 6px;
      width: 1px;
      height: 20px;
      flex-shrink: 0;
      background-color: ${({ theme }) => theme.colors.gray[300]};
    }

    .btn {
      padding: 5px 8px;
      height: fit-content;
      font-family: ${({ theme }) => theme.fonts.nunito};
      color: ${({ theme }) => theme.colors.gray[500]};
      font-weight: 600;
      overflow: hidden;
      position: relative;

      input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        font-size: 0;
        cursor: pointer;
      }

      svg {
        margin-right: 8px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[100]};
      }

      &:active {
        background-color: ${({ theme }) => theme.colors.gray[300]}50;
      }
    }
  }

  &:hover {
    .delete-img-btn {
      opacity: 1;
    }
  }
`

export default StyledDraftDrawerImage
