import React, { FC, useEffect, useState } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { DownloadIcon, FolderAddIcon, HeartIcon, PlusIcon } from '@heroicons/react/outline'
import ROUTES from '../../utils/routes'
import useSegment from '../../utils/useSegment'
import useDraft from '../../../redux/draft-drawer/useDraft'
import useGallery from '../../../redux/gallery/useGallery'
import {
  Bubble, PaginationButton, Spinner, FadeRow, ScrollRow, Dialog
} from '../../System'
import { GalleryModalPhotoState } from '../../../types'
import { linkToSizedImage } from '../../IssueEntityImage/helpers'
import { parseUrlPathToTab } from '../../GalleryView'
import { SkeletonLoader } from '../../SkeletonLoader'
import { StyledGalleryPhotoModal, GalleryPhotoSimilar, useGalleryPhotoModal } from '.'
import { useGalleryFeed } from '../../GalleryView/GalleryFeed'
import { AddToCollectionModal } from '../AddToCollectionModal'

type Props = {
  isOpen: boolean;
}

const GalleryPhotoModal: FC<Props> = ({ isOpen }) => {
  const {
    showPhotoId,
    nextPhotoClick,
    prevPhotoClick,
    loadingPhoto,
    loadPhotoData,
    openedPhoto,
    cleanUp
  } = useGalleryPhotoModal()

  const { actions: { changeSearch } } = useGalleryFeed()

  const history = useHistory()
  const { track } = useSegment()
  const { activeCollection } = useGalleryFeed()
  const { actions: { handleDownload, handleToggleLike, handleView } } = useGallery()

  const { actions: { setDraftImage } } = useDraft()
  const { state }: GalleryModalPhotoState = useLocation()
  const { pathname: currentPath } = useLocation()

  const isFavoritesPage = useRouteMatch(ROUTES.gallery.favorites)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [isLiked, setIsLiked] = useState(!!openedPhoto?.liked)
  const [showAddToCollectionModal, setShowAddToCollectionModal] = useState(false)

  const goToTag = (label: string) => {
    changeSearch(label)
    history.push(ROUTES.gallery.main)
    track('Clicked Gallery Tag', {
      tag_name: label,
      original_photo_url: `${window.location.host}/gallery/${showPhotoId}`
    })
  }

  const handleOpenCollections = () => {
    if (showPhotoId) {
      setShowAddToCollectionModal(true)
    }
  }

  const likeImage = async () => {
    if (openedPhoto) {
      setIsLiked(!isLiked)
      await handleToggleLike(openedPhoto._id, !!openedPhoto.liked)
    }
  }

  const handleModalClose = () => {
    cleanUp()

    if (isFavoritesPage) {
      return history.push(ROUTES.gallery.favorites)
    }

    if (activeCollection) {
      return history.push(ROUTES.gallery.buildCollectionPage(activeCollection?._id))
    }

    return history.push(ROUTES.gallery.main)
  }

  const handleNextPhoto = () => {
    const photo = nextPhotoClick() // pass photo though state
    if (photo) {
      const galleryFeedUrl = parseUrlPathToTab(currentPath, 'backUrl')
      history.push(`${galleryFeedUrl}/${photo._id}`, { photo })
    }
  }

  const handlePrevPhoto = () => {
    const photo = prevPhotoClick() // pass photo though state
    if (photo) {
      const galleryFeedUrl = parseUrlPathToTab(currentPath, 'backUrl')
      history.push(`${galleryFeedUrl}/${photo._id}`, { photo })
    }
  }

  const handleAddToDraft = () => {
    setDraftImage(openedPhoto!.file!.fileHttpLink || '', showPhotoId)
    track('Added Photo to Quick Draft', {
      id: showPhotoId,
      url: openedPhoto!.file!.fileHttpLink,
      from: 'gallery'
    })
    handleModalClose()
  }

  useEffect(() => {
    setIsLiked(!!openedPhoto?.liked)
  }, [!!openedPhoto?.liked])

  useEffect(() => {
    if (showPhotoId) {
      loadPhotoData(state?.photo, showPhotoId)
    }
    setImageLoaded(false)
  }, [state?.photo, showPhotoId])

  useEffect(() => {
    if (openedPhoto) {
      handleView(openedPhoto)
    }
  }, [openedPhoto?._id])

  return (
    <>
      <AddToCollectionModal
        isOpen={showAddToCollectionModal}
        photo={state?.photo || (openedPhoto as any)}
        handleClose={() => setShowAddToCollectionModal(false)}
      />
      {!showAddToCollectionModal && (
        <Dialog noOverflow={false} isOpen={isOpen} onClose={() => handleModalClose()}>
          <StyledGalleryPhotoModal isLiked={isLiked} imageLoaded={imageLoaded && !loadingPhoto}>
            <div className="photo-view">
              <img
                src={linkToSizedImage(openedPhoto?.file?.fileHttpLink, { width: 1000 })}
                onLoad={() => setImageLoaded(true)}
              />
              {(!imageLoaded || loadingPhoto) && <Spinner />}
            </div>
            <div className="photo-actions">
              <button className="use-btn" onClick={() => handleAddToDraft()}>
                <PlusIcon />
                <span>USE</span>
              </button>

              <div className="photo-actions-group">
                <button className="fav-btn" onClick={() => likeImage()}>
                  <HeartIcon />
                </button>
                <button onClick={() => handleOpenCollections()}>
                  <FolderAddIcon />
                </button>
                <a
                  href={linkToSizedImage(openedPhoto?.file?.fileHttpLink, {})}
                  onClick={() => handleDownload(openedPhoto!)}
                >
                  <DownloadIcon />
                </a>
              </div>
            </div>

            {openedPhoto && openedPhoto.tags && (
              <div className="photo-tags">
                <FadeRow>
                  {openedPhoto?.tags.map((tag) => (
                    <Bubble label={tag.label} onClick={() => goToTag(tag.label)} key={tag.label} />
                  ))}
                </FadeRow>
              </div>
            )}

            <div className="photo-similar">
              {openedPhoto && <GalleryPhotoSimilar photo={openedPhoto} />}
              {!openedPhoto && (
                <ScrollRow itemsToShow={6} customWidth>
                  <SkeletonLoader type="square-list-item" quantity={5} size={100} />
                </ScrollRow>
              )}
            </div>
          </StyledGalleryPhotoModal>
          <PaginationButton inModal direction="left" onClick={() => handlePrevPhoto()} />
          <PaginationButton inModal direction="right" onClick={() => handleNextPhoto()} />
        </Dialog>
      )}
    </>
  )
}

export default GalleryPhotoModal
